// General fx

.shadow1(@tmp1, @tmp2) {
	background-color: @tmp1;
	border: 2px solid @tmp1;
	.shadow-inset(3px 4px 0 -3px, @tmp2, .7);
}

.shadow-remove () {
	-webkit-box-shadow: none;     // Android 2.3+, iOS 4.0.2-4.2
	box-shadow: none;     // Chrome 6+, Firefox 4+, IE 9
}

.hasicon { font-family: 'FontAwesome'; letter-spacing: 0 !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.elisaicon { font-family: "icons"; }
.stars (@tmp1, @tmp2) {
	&:before { .hasicon; float: none; display: inline-block; color: @tmp1; }
	&:after { .hasicon; float: none; display: inline-block; color: @tmp2; }
	&.stars-0:before { display: none; }
	&.stars-0:after { content: @ico-star@ico-star@ico-star@ico-star@ico-star; }
	&.stars-1:before { content: @ico-star; }
	&.stars-1:after { content: @ico-star@ico-star@ico-star@ico-star; }
	&.stars-2:before { content: @ico-star@ico-star; }
	&.stars-2:after { content: @ico-star@ico-star@ico-star; }
	&.stars-3:before { content: @ico-star@ico-star@ico-star; }
	&.stars-3:after { content: @ico-star@ico-star; }
	&.stars-4:before { content: @ico-star@ico-star@ico-star@ico-star; }
	&.stars-4:after { content: @ico-star; }
	&.stars-5:before { content: @ico-star@ico-star@ico-star@ico-star@ico-star; }
	&.stars-5:after { display: none; }
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.grayscale(@value: 1) {
	-webkit-filter: grayscale(@value);
	-moz-filter: grayscale(@value);
	-o-filter: grayscale(@value);
	-ms-filter: grayscale(@value);
	filter: grayscale(@value);
	//filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}

.display(@value) when (@value = flex) {
	display: -ms-flexbox; // IE10
	display: -webkit-flex; // Safari / iOS
}

.display(@value) when (@value = inline-flex) {
	display: -ms-inline-flexbox; // IE10
	display: -webkit-inline-flex; // Safari / iOS
}

.display(@value) {
	display: @value;
}

.flex(@value) {
	-ms-flex: @value;
	-webkit-flex: @value;
	flex: @value;
}
.flex-important(@value) {
	-ms-flex: @value !important;
	-webkit-flex: @value !important;
	flex: @value !important;
}

.flex-justify-content(@justifyContent) {
	.ms-flex-justify-content(@justifyContent); // IE10
	-webkit-justify-content: @justifyContent; // Safari / iOS
	justify-content: @justifyContent;
}

.flex-align-content(@alignContent) {
	.ms-flex-align-content(@alignContent); // IE10
	-webkit-align-content: @alignContent; // Safari / iOS
	align-content: @alignContent;
}

.flex-align-items(@alignItems) {
	.ms-flex-align-items(@alignItems); // IE10
	-webkit-align-items: @alignItems; // Safari / iOS
	align-items: @alignItems;
}

.flex-align-self(@alignSelf) {
	.ms-flex-align-self(@alignSelf); // IE10
	-webkit-align-self: @alignSelf; // Safari / iOS
	align-self: @alignSelf;
}

.flex-direction(@direction) {
	-ms-flex-direction: @direction; // IE10
	-webkit-flex-direction: @direction; // Safari / iOS
	flex-direction: @direction;
}

.flex-order(@order) {
	-ms-flex-order: @order; // IE10
	-webkit-order: @order; // Safari / iOS
	order: @order;
}

.flex-wrap(@wrap) {
	.ms-flex-wrap(@wrap); // IE10
	-webkit-flex-wrap: @wrap; // Safari / iOS
	flex-wrap: @wrap;
}
/*.animation(@name, @duration, @timing, @delay: 0, @direction) {
	-webkit-animation:@name @duration @timing @delay @direction; !* Safari 4+ *!
	-moz-animation:@name @duration @timing @delay @direction; !* Fx 5+ *!
	-o-animation:@name @duration @timing @delay @direction; !* Opera 12+ *!
	animation:@name @duration @timing @delay @direction; !* IE 10+, Fx 29+ *!
}*/
.animation(@arguments) {
	-webkit-animation:@arguments;
	-moz-animation:@arguments;
	-o-animation:@arguments;
	animation:@arguments;
}
.keyframes(@name,@rules) {

	@-webkit-keyframes @name {
		@rules();
	}

	@-moz-keyframes @name {
		@rules();
	}

	@-ms-keyframes @name {
		@rules();
	}

	@-o-keyframes @name {
		@rules();
	}

	@keyframes @name {
		@rules();
	}
}

/* These are the conditional mixins for the different syntax for IE10 Flexbox */
.ms-flex-justify-content(@justifyContent) when (@justifyContent = space-between) {
	-ms-flex-pack: justify;
}

.ms-flex-justify-content(@justifyContent) when (@justifyContent = space-around) {
	-ms-flex-pack: distribute;
}

.ms-flex-justify-content(@justifyContent) when (@justifyContent = flex-start) {
	-ms-flex-pack: start;
}

.ms-flex-justify-content(@justifyContent) when (@justifyContent = flex-end) {
	-ms-flex-pack: end;
}

.ms-flex-justify-content(@justifyContent) when (@justifyContent = center) {
	-ms-flex-pack: center;
}

.ms-flex-align-content(@alignContent) when (@alignContent = space-between) {
	-ms-flex-line-pack: justify;
}

.ms-flex-align-content(@alignContent) when (@alignContent = space-around) {
	-ms-flex-line-pack: distribute;
}

.ms-flex-align-content(@alignContent) when (@alignContent = flex-start) {
	-ms-flex-line-pack: start;
}

.ms-flex-align-content(@alignContent) when (@alignContent = flex-end) {
	-ms-flex-line-pack: end;
}

.ms-flex-align-content(@alignContent) when (@alignContent = center), (@alignContent = stretch) {
	-ms-flex-line-pack: @alignContent;
}

.ms-flex-align-items(@alignItems) when (@alignItems = flex-start) {
	-ms-flex-align: start;
}

.ms-flex-align-items(@alignItems) when (@alignItems = flex-end) {
	-ms-flex-align: end;
}

.ms-flex-align-items(@alignItems) when (@alignItems = center), (@alignItems = baseline), (@alignItems = stretch) {
	-ms-flex-align: @alignItems;
}

.ms-flex-align-self(@alignSelf) when (@alignSelf = flex-start) {
	-ms-flex-item-align: start;
}

.ms-flex-align-self(@alignSelf) when (@alignSelf = flex-end) {
	-ms-flex-item-align: end;
}

.ms-flex-align-self(@alignSelf) when (@alignSelf = auto), (@alignSelf = center), (@alignSelf = baseline), (@alignSelf = stretch) {
	-ms-flex-item-align: @alignSelf;
}

.ms-flex-wrap(@wrap) when (@wrap = nowrap) {
	-ms-flex-wrap: none;
}

.ms-flex-wrap(@wrap) when (@wrap = wrap), (@wrap = wrap-reverse) {
	-ms-flex-wrap: @wrap;
}

// link outline none
.link-outline-none {
	&,
	A {
		&:link,
		&:visited,
		&:hover,
		&:active,
		&:focus {
			-moz-outline-style: none;
			outline:none;
			outline:0;
			-moz-outline: 0;
			-webkit-outline: 0;
		}
	}
}
.appearance-border-radius {
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	-webkit-border-radius: 0;  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
	-moz-border-radius: 0;  /* Firefox 1-3.6 */
	border-radius: 0;  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}
.text-decoration-none {
	&,
	A {
		&:link,
		&:visited,
		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}
}
.padding-icons(@addpadding) {
	padding: @addpadding;
}
.padding(padding) {
	padding: @padding;
}
.margin(@margin) {
	padding: @margin;
}
.width-label-input(@labelwidth, @inputwidth) {
	LABEL, SPAN.label {
		width: @labelwidth;
	}
	div.items {
		width: @inputwidth;
	}
}
.text__disable--highlighting {
	-webkit-user-select: none; /* Webkit */
	-moz-user-select: none;    /* Firefox */
	-ms-user-select: none;     /* IE 10  */
	/* Currently not supported in Opera but will be soon */
	-o-user-select: none;
	user-select: none;
}



