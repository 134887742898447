.type-info-block {
	&.in2cols > LI {
		@media @media-mobile {
			width: 100%;
		}
	}
	&__grey {
		background-color: @color-gray07;
	}
	&__title {
		font-size: 30px;
		font-weight: bold;
		line-height: 1.27;
		color: @color01;
		margin: 35px 0;
		@media @media-tablet {
			margin-top: 0;
		}
		@media @media-mobile {
			margin-top: 0;
			font-size: 24px;
		}
	}
	&__text {
		font-size: 18px;
		line-height: 1.67;
		color: @color-gray01;
		margin-bottom: 45px;
		@media @media-mobile {
			font-size: 15px;
		}
		&--blue {
			color: @color-blue02;
		}
		/*&--blue {
			color: @color01;
		}
		&--blue-light {
			color: @color-blue02;
		}*/
	}
	&__list {
		list-style: none;
		li {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.28;
			color: @color-gray01;
			padding-left: 25px;
			@media @media-mobile {
				font-size: 15px;
				line-height: 1.53;
			}
			&:before {
				content: "\f00c";
				font-family: FontAwesome;
				font-size: 13px;
				color: @color03;
				font-weight: bold;
				top: calc(~"(0% + 3px)");
				line-height: 1;
				height: 10px;
			}
		}
		&--bullets {
			li {
				padding-left: 20px;
				&:before {
					content: "";
					top: 5px;
					left: 0;
					background: @color-blue02;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					display: inline-block;
				}
			}
		}
		&--size01 {
			LI {
				font-size: 15px;
				color: @color-gray08;
				font-weight: 400;
			}
		}
		&--sm {

		}
		&--md {
			> LI {
				&:before {
					top: calc(~"(0% + 5px)");
				}
				margin: 10px 0;
				@media @media-mobile {

				}
			}
		}
		&--lg {

		}
		@media @media-mobile {
			&.in2cols > LI { width: 100%; }
		}
	}
	&__image {
		width: 50%;
		@media @media-tablet {
			width: auto;
			text-align: center;
			float: none;
			padding: 0 20px;
			margin: 0 0 15px;
		}
		@media @media-mobile {
			width: auto;
			text-align: center;
			float: none;
			margin: 0 0 40px;
			padding: 0 20px;
		}
		img {
			width: 100%;
			@media @media-tablet {
				display: inline-block;
				width: 70%;
			}
			@media @media-mobile {
				display: inline-block;
				width: 100%;
			}
		}
		&--left {
			float: left;
			@media @media-tablet {
				float: left;
				margin-left: 0;
				text-align: left;
				padding-left: 0;
			}
			@media @media-mobile {
				float: left;
				margin-left: 0;
				text-align: left;
				padding-left: 0;
			}
			img {
				@media @media-tablet {
					width: 80%;
				}
				@media @media-mobile {
					width: 80%;
				}
			}
		}
		&--right {
			float: right;
			margin-right: -25px;
			@media @media-tablet {
				float: none;
				margin: 0;
			}
			@media @media-mobile {
				float: none;
				margin: 0;
			}
		}
		&--bottom {
			margin-bottom: -70px;
			position: relative;
			z-index: 1000;
			@media @media-tablet {
				margin: 0;
			}
			@media @media-mobile {
				margin: 0;
			}
		}
		&--top {
			margin-top: 50px;
			@media @media-wide {
				margin-top: -60px;
			}
		}
		&--wrapper {
			@media @media-tablet {
				text-align: center;
				margin-bottom: 35px;
			}
			@media @media-mobile {
				text-align: center;
				margin-bottom: 35px;
			}
			img {
				@media @media-tablet {
					display: inline-block;
					width: 60%;
				}
				@media @media-mobile {
					display: inline-block;
					width: 60%;
				}
			}
		}
	}
	&__column {
		&--right {
			float: right;
			margin-top: 75px;
			@media @media-tablet {
				margin-top: 65px;
			}
			@media @media-mobile {
				margin-top: 35px;
			}
		}
		&--left {
			margin-top: 0;
			padding-bottom: 60px;
			@media @media-wide {
				margin-top: 55px;
				padding-bottom: 0;
			}
			@media @media-mobile {
				padding-bottom: 50px;
			}
		}
	}
	&__content {
		display: inline-block;
		&--wrapper {
			text-align: center;
		}
	}
	&__icon {
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
		.tv-color {
			width: 140px;
			height: 140px;
			background: url("../../assets/img/components/svg/tv-color.svg") no-repeat;
			background-size: 100% 100%;
			display: block;
			@media @media-mobile {
				width: 80px;
				height: 80px;
			}
		}
		.tv-star {
			width: 140px;
			height: 140px;
			background: url("../../assets/img/components/svg/tv-star.svg") no-repeat;
			background-size: 100% 100%;
			display: block;
			@media @media-mobile {
				width: 80px;
				height: 80px;
			}
		}
	}
	&__message {
		display: inline-block;
		vertical-align: middle;
		width: calc(~"(80% - 165px)");
		text-align: left;
		@media @media-mobile {
			width: calc(~"(100% - 104px)");
		}
	}
	&__maailmad {
		padding: 80px 0 70px;
		@media @media-tablet {
			padding: 50px 0 70px;
		}
		@media @media-mobile {
			padding: 35px 0 50px;
		}
	}
	&__vaata {
		@media @media-tablet {
			padding: 0 0 50px;
		}
		@media @media-mobile {
			padding: 0 0 40px;
		}
		.type-info-block__text {
			margin-bottom: 20px;
		}
		.type-info-block__title {
			margin: 80px 0 35px;
			@media @media-tablet {
				margin-top: 0;
			}
			@media @media-mobile {
				margin-top: 0;
				font-size: 24px;
			}
		}
	}
	&__ekraanil {
		padding: 60px 0 0;
		.type-info-block__list {
			margin-bottom: 30px;
		}
		.type-info-block__title,
		.type-info-block__text {
			margin-bottom: 30px;
		}
		@media @media-mobile {
			padding: 50px 0 0;
		}
		.img01 {
			@media only screen and (max-width: 375px) {
				width: 48%;
			}
		}
	}
	&__videolaenutus {
		padding-bottom: 60px;
		.type-info-block__title,
		.type-info-block__text {
			margin-bottom: 30px;
		}
	}
	&__subscribe {
		padding: 38px 0;
		.type-info-block__text {
			margin: 0 0 10px;
			text-align: left;
		}
		&--link {
			text-align: left;
		}
		@media @media-mobile {
			padding: 32px 0;
		}
	}
	&__netflix {
		padding: 80px 0 70px;
		@media @media-tablet {
			padding: 50px 0 70px;
		}
		@media @media-mobile {
			padding: 40px 0 60px;
		}
	}
	&__multiple-screen {
		padding: 80px 0 125px;
		@media @media-tablet {
			padding: 50px 0 70px;
		}
		@media @media-mobile {
			padding: 40px 0 60px;
		}
	}
}