.type-order {
	&-toggler {
		&__collapse {
			font-family: @font-main;
			font-size: 15px;
			text-decoration: none;
			color: @color-gray01;
			padding: 10px 18px;
			display: block;
			font-weight: 700;
			&:before {
				content: "\f107";
				color: @color-gray01;
				font-family: 'FontAwesome';
				margin-right: 10px;
				font-size: 18px;
			}
			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
				color: @color-gray01;
			}
			&.type-order__toggler--collapse-open {
				border-bottom: 1px solid @color-gray06;
				&:before {
					content: "\f106";
				}
			}
		}
	}
	&__content {
		padding: 0 17px 30px;
	}
	&__modal {
		&--price {
			width: 60%;
			float: left;
			border-top: 1px solid transparent;
		}
		&--action {
			width: 40%;
			float: left;
			@media @media-tablet {
				width: 30%;
			}
			a.type-order__modal--submit {
				display: block;
				text-align: center;
			}
		}
		&--collapse {
			font-size: 15px;
			text-decoration: none;
			color: @color-gray01;
			padding: 14px 18px;
			display: block;
			&:before {
				content: "\f106";
				color: @color-gray01;
				font-family: 'FontAwesome';
				margin-right: 5px;
				font-size: 18px;
			}
			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
				color: @color-gray01;
			}
		}
	}
	&__overlay {
		pointer-events: none;
		background: transparent;
		display: none;
		&.open {
			background: url("../../assets/img/old/overlay.png");
			pointer-events: auto;
			.type-order__modal--price {
				border-top: 1px solid @color-gray06;
			}
			.type-order__modal--collapse {
				&:before {
					content: "\f107";
				}
			}
		}
	}
	&__blocks {
		-moz-column-count: 3;
		-moz-column-gap: 20px;
		-webkit-column-count: 3;
		-webkit-column-gap: 20px;
		column-count: 3;
		@media @media-tablet {
			-moz-column-count: 2;
			-moz-column-gap: 18px;
			-webkit-column-count: 2;
			-webkit-column-gap: 18px;
			column-count: 2;
		}
		@media @media-mobile {
			-moz-column-count: 1;
			-moz-column-gap: 0;
			-webkit-column-count: 1;
			-webkit-column-gap: 0;
			column-count: 1;
		}
		margin: 16px -8px;
	}
	&__block {
		border: 1px solid @color-gray11;
		margin-bottom: 20px;
		@media @media-tablet {
			margin-bottom: 18px;
		}
		&--wrapper {
			width: 33.33%;
			padding: 0 8px;
			margin: 0 0 16px 0;
			display: inline-block;
			@media @media-tablet {
				width: 48%;
			}
			@media @media-mobile {
				width: 100%;
			}
			/*&--sizer {
				width: 33.33%;
				padding: 0 16px;
				display: inline-block;
				@media @media-tablet {
					width: 48%;
				}
				@media @media-mobile {
					width: 100%;
				}
			}*/
		}
		&--selected {
			border: 2px solid @color03;
		}
		&--disabled {
			position: relative;
			&::before {
				content: "";
				display: block;
				background: rgba(255,255,255, 0.4);
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				cursor: default;
				position: absolute;
			}
		}
	}
	&__pad {
		padding: 0 8px;
		&--additional-services {
			padding: 0 10px;
		}
	}
	&__mrg {
		margin: 16px -8px;
		&--additional-services {
			margin: 16px -10px;
		}
	}
	&__small-blocks {
		.display(flex);
		.justify-content(space-between);
	}
	&__small-block {
		border: 1px solid @color-gray11;
		margin-bottom: 20px;
		padding: 25px;
		flex-basis: 23.5%;
		@media @media-tablet {
			margin-bottom: 18px;
		}
		&--selected {
			border: 1px solid @color03;
		}
		&--btn {
			a& {
				width: auto;
				max-width: 150px;
				text-align: center;
				display: block;
				margin: 0 auto;
				padding: 6px 24px;
			}
		}
		&--price {
			font-size: 15px;
			font-weight: bold;
			color: @color01;
			text-align: center;
			margin-bottom: 15px;
			margin-top: 15px;
		}
		&--time {
			font-size: 30px;
			font-weight: bold;
			text-align: center;
			color: @color-gray01;
			line-height: 1;
			span {
				font-size: 13px;
				font-weight: 400;
				vertical-align: top;
				line-height: 1;
				display: inline-block;
				margin-top: 3px;
			}
		}
	}
	.type-order__small-block--left {
		text-align: left;
	}
	&__image {
		img {
			width: 100%;
		}
	}
	&__logo {
		margin-top: 25px;
		height: 80px;
		position: relative;
		text-align: center;
		img {
			max-width: 80% !important;
			max-height: 80px;
			display: inline-block;
			vertical-align: middle;
		}
		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
	}
	&__tabs {
		margin-bottom: 30px;
	}
	&__name {
		font-size: 15px;
		font-weight: bold;
		color: @color-gray01;
		margin-top: 15px;
		margin-bottom: 3px;
	}
	&__price {
		font-size: 15px;
		font-weight: bold;
		color: @color-gray01;
		float: right;
		&--blue {
			color: @color-blue01;
		}
		&--itb {
			color: @color-gray01;
		}
		&--f-left {
			float: left;
		}
		&--orange {
			color: @color-orange01;
		}
		&--before {
			float: right;
			clear: both;
			color: @color-gray05;
			font-weight: 400;
			text-decoration: line-through;
			line-height: 1.1;
		}
		&__sufix {
			font-weight: 400;
			color: @color-gray03;
		}
	}
	&__text {
		font-size: 13px;
		line-height: 1.46;
		text-align: left;
		color: @color-gray12;
		/*opacity: 0.8;*/
		margin-top: 10px;
		&--complete {
			font-size: 13px;
			line-height: 1.46;
			text-align: left;
			color: @color-gray12;
			opacity: 0.8;
			display: none;
		}
		&--wrapper {
			position: relative;
			.longtext;
			min-height: 62px;
		}
	}
	&__del {
		color: @color-gray05;
		font-family: @font-main;
		font-weight: 400;
	}
	&__show-more {
		position: absolute;
		bottom: -20px;
		left: 0;
	}
	&__load-more {
		margin-top: 15px;
	}
	&__select-option {
		font-size: 14px;
		line-height: 1.33;
		color: @color-gray08;
		display: block;
		&:before {
			content: "\f00d";
			font-family: FontAwesome;
			font-size: 14px;
			color: #cccccc;
			margin-right: 10px;
			opacity: 1;
			transition: opacity 0.3s ease;
		}
		&:hover,
		&:focus,
		&:active {
			opacity: 0.6;
			color: @color-gray08;
			text-decoration: none;
			.type-order__price {
				opacity: 0.6;
			}
		}
		&+.type-order__select-option {
			margin-top: 5px;
		}
	}
	&__btn {
		a& {
			width: 150px;
			text-align: center;
			display: block;
			margin: 0 auto;
			/*padding: 6px 24px;*/
			padding: 10px 30px 9px;
			height: 40px;
			@media @media-mobile {
				width: 150px;
				padding: 10px 30px 9px;
			}
		}
		A.btn btn & {
			width: 150px;
			text-align: center;
			display: block;
			margin: 0 auto;
			/*padding: 6px 24px;*/
			padding: 10px 30px 9px;
			height: 40px;
			@media @media-mobile {
				width: 150px;

				padding: 10px 30px 9px;
			}
		}
		&--load-more {
			a& {
				width: 100%;
				text-align: center;
				display: block;
				margin: 0 auto;
			}
		}
		&--submit {
			width: 100%;
			text-align: center;
			display: block;
			margin: 10px auto 0;
		}
	}
	&__action {
		margin-top: 38px;
		&--logotypes {
			margin-top: 25px;
		}
	}
	&__actions {
		clear: both;
	}
	&__scroll,
	&__scroll-mobile {
		height: auto;
		overflow-y: auto;
		padding: 0 2px; // -ms-overflow-style: none;
		// overflow: -moz-scrollbars-none;
		// &::-webkit-scrollbar {
		//     width: 0px;
		//     background: transparent;
		// }
		&--fade-top {
			width: 100%;
			height: 35px;
			display: none;
			position: absolute;
			top: 0;
			background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			pointer-events: none;
		}
		&--fade-bottom {
			width: 100%;
			height: 35px;
			display: none;
			position: absolute;
			bottom: 0;
			background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			pointer-events: none;
		}
		&--wrapper {
			position: relative;
			height: auto;
			overflow: hidden;
			&.has-scrollbar {
				.type-order__scroll--fade-top,
				.type-order__scroll--fade-bottom,
				.type-order__scroll-mobile--fade-top,
				.type-order__scroll-mobile--fade-bottom {
					display: block;
				}
				.type-order__scroll {
					padding-bottom: 20px;
					width: calc(~"(100% + 17px)");
					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						width: calc(~"(100% + 12px)");
						padding-bottom: 50px;
					}
					@supports (-ms-accelerator: true) {
						width: calc(~"(100% + 12px)");
						padding-bottom: 50px;
					}
					@supports (-ms-ime-align: auto) {
						width: calc(~"(100% + 12px)");
						padding-bottom: 50px;
					}
				}
			}
		}
	}
	&__scroll-mobile {
		&::-webkit-scrollbar {
			width: 0;
			background: transparent;
		}
	}
}

.order-content {
	border-right: 1px solid @color-gray11;
	padding-right: 18px;
	@media @media-tablet {
		border-right: 0;
		/*padding-right: 15px;*/
		/*ask Kadri*/
		padding-right: 0;
	}
	@media @media-mobile {
		border-right: 0;
		/*padding-right: 15px;*/
		/*ask Kadri*/
		padding-right: 0;
	}
	&__title {
		margin-top: 10px;
		margin-bottom: 35px;
		&--margin-top {
			margin-top: 80px;
		}
	}
	&__info {
		font-size: 15px;
		line-height: 1.53;
		text-align: left;
		color: @color-gray01;
		margin-bottom: 30px;
		margin-top: -20px;
	}
	/*if possible cahnge to order-content--h100p*/
	&__h100p {
		height: 100%;
	}
}

.order-sidebar {
	@media @media-tablet {
		padding-top: 60px;
	}
	@media @media-mobile {
		padding-top: 60px;
	}
	&__title {
		margin: 0;
	}
}

.order__price {
	font-size: 40px;
	font-weight: bold;
	line-height: 0.75;
	color: @color-gray01;
	float: right;
	@media @media-tablet {
		margin-top: 15px;
	}
	@media @media-mobile {
		margin-top: 0;
		font-size: 30px;
		float: right;
	}
	&--info {
		font-size: 15px;
		line-height: 1.33;
		color: @color-gray08;
		float: left;
		margin-top: 8px;
	}
	&--wrapper {
		padding-top: 20px;
		margin-top: 20px;
		border-top: 1px solid @color-gray11;
		margin-bottom: 35px;
	}
	&--orange {
		color: @color-orange01;
	}
	&--before {
		float: right;
		clear: both;
		color: @color-gray05;
		font-weight: 400;
		text-decoration: line-through;
		margin-top: 5px;
	}
}
.mobile-sticky {
	display: none;
	@media @media-mobile {
		display: block;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-transform: translateZ(0);
	}
}

.order-modal__content {
	padding: 10px 20px;
	background: @color-white;
	overflow-y: auto;
	max-height: calc(~ "100vh - 50px");

	@media @media-tablet {
		width: 50%;
	}
}
.order {
	padding: 30px 0 60px;
	overflow: hidden;

	@media @media-notdesktop {
		padding: 0;
	}
	.order-package {
		margin: 15px 0;
		font-family: @font-main;
		&__heading {
			color: @color-gray01;
			font-weight: 700;
			font-size: 15px;
		}
		&__meta {
			color: @color-gray04;
			font-size: 15px;
			font-weight: 600;
			line-height: 1.53;
		}
		&-selected--inline {
			display: flex;
			justify-content: space-between;
			margin: 10px 0;
		}
		&-selected {
			margin: 10px 0;
			&__price {
				font-weight: 700;
				font-size: 15px;
				width: 120px;
				text-align: right;
				color: @color-gray01;
				span {
					font-weight: 400;
					color: @color-gray01;
				}
			}
			&__title {
				font-size: 14px;
				color: @color-gray01;
				padding-right: 10px;
				font-weight: 400;
			}
			&__tv, &__internet {
				margin-left: 20px;
			}
		}
	}

	.order__price--total {
		float: left;
		font-size: 15px;
		color: @color-gray01;
		font-weight: 400;
	}

	.order__price {
		font-size: 24px;
		display: inline-flex;
		vertical-align: top;
		float: right;

		span {
			font-size: 15px;
			font-weight: 400;
			color: @color-gray03;
		}
	}

	.order-sidebar-wrapper {
		position: relative;

		@media @media-notdesktop {
			background: @color-white;
		}

		@media @media-tablet {
			margin: 0 -5px;
			padding: 0 20px;
		}

		.order-sidebar {
			max-width: 475px;
			@media @media-notdesktop {
				max-width: 100%;
				padding-top: 20px;
			}
		}

		.order-sticky {
			@media @media-desktop {
				width: 225px;
				&.sticky-top {
					position: static;
					top: initial;

				}

				&.sticky-bottom {
					position: absolute;
					bottom: 60px;
					top: auto;
					&--sm {

					}
					&--zero {
						bottom: 0;
					}
				}

				&.sticky-fixed {
					position: fixed;
					top: 0;
				}
			}
			@media @media-wide {
				width: 275px;
			}
		}
	}
}

.display-modal-link {
	padding: 30px 0 15px;
	font-weight: 700;
	display: block;

	@media @media-desktop {
		display: none;
	}

	i {
		color: @color03;
		margin-right: 6px;
		font-size: 13px;
	}
}

.order__alert {
	padding: 60px 0;
}

