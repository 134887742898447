.SumoSelect {
    width: 100%;
}

.SumoSelect>.CaptionCont {
    border: none;
    border-bottom: solid 1px @color-gray05;
    border-radius: 0;
}

.SumoSelect>.CaptionCont>label>i {
    background-image: none;
    width: 20px;
    height: 20px;
    font-style: normal;
    opacity: 1;
    &:before {
        font-family: FontAwesome;
        content: "\f107";
        display: block;
        font-size: 18px;
        color: @color-gray01;
    }
}

.SumoSelect>.CaptionCont>span {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
}

.SumoSelect>.optWrapper {
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 0;
}

.SumoSelect.open>.optWrapper {
    top: 49px;
    display: block;
    border-radius: 0;
}

.SumoSelect>.optWrapper>.options li.opt {
    padding: 6px 6px;
    position: relative;
    border-bottom: none;
    &:before {
        display: none;
    }
}

.SumoSelect>.optWrapper>.options li.opt {
    padding: 10px 19px;
    margin: 0;
}

.SumoSelect>.optWrapper>.options>li.selected {
    border-left: 3px solid @color03;
    font-weight: 700;
    padding: 10px 19px 10px 16px;
}

.SumoSelect>.CaptionCont {
    border: none;
    border-bottom: solid 1px @color-gray05;
    border-radius: 0;
}

.SumoSelect:focus>.CaptionCont,
.SumoSelect:hover>.CaptionCont,
.SumoSelect.open>.CaptionCont {
    box-shadow: initial;
    border-color: transparent;
    border-bottom-color: @color-gray03;
}

.SumoSelect>.optWrapper>.options>li.opt:first-child {
    border-radius: 0;
}

.sumo__select {
    &--default {
        .SumoSelect>.CaptionCont {
            padding: 0 10px 13px 0;
            >span {
                font-size: 15px;
                color: @color-gray01;
            }
            >label>i {
                height: 35px;
            }
        }
        .SumoSelect>.optWrapper>.options li label {
            font-size: 15px;
            color: @color-gray01;
        }
        .SumoSelect.open>.optWrapper {
            top: 49px;
            display: block;
            border-radius: 0;
            @media @media-mobile {
                top: 42px;
            }
        }
        .SumoSelect>.optWrapper>.options li.opt {
            padding: 8px 19px;
            margin: 0;
        }
        .SumoSelect>.optWrapper>.options>li.selected {
            padding: 8px 19px 8px 16px;
        }
    }
    &--large {
        .SumoSelect>.CaptionCont {
            padding: 0 10px 20px 9px;
            >span {
                font-size: 18px;
                color: @color-gray01;
                @media @media-mobile {
                    font-size: 15px;
                }
            }
            >label>i {
                height: 35px;
            }
            @media @media-mobile {
                padding: 0 10px 13px 9px;
            }
        }
        .SumoSelect>.optWrapper>.options li label {
            font-size: 18px;
            color: @color-gray01;
            @media @media-mobile {
                font-size: 15px;
            }
        }
        .SumoSelect.open>.optWrapper {
            top: 49px;
            display: block;
            border-radius: 0;
            @media @media-mobile {
                top: 42px;
            }
        }
    }
    &--xsmall {
        .SumoSelect>.CaptionCont {
            padding: 4px 10px 4px 0;
            border-bottom: 0;
            >span {
                font-size: 14px;
                color: @color-gray01;
                @media @media-mobile {
                    font-size: 14px;
                }
            }
            >label>i {
                height: 20px;
                text-align: right;
                margin-right: 0;
            }
        }
        .open {
            .CaptionCont {
                span {
                    font-weight: 700;
                }
            }
        }
        .SumoSelect>.optWrapper>.options li label {
            font-size: 14px;
            color: @color-gray01;
            @media @media-mobile {
                font-size: 14px;
            }
        }
        .SumoSelect.open>.optWrapper {
            top: 35px;
            display: block;
            border-radius: 0;
            @media @media-mobile {
                top: 35px;
            }
        }
        .SumoSelect>.optWrapper>.options li.opt {
            padding: 8px 19px;
            margin: 0;
        }
        .SumoSelect>.optWrapper>.options>li.selected {
            padding: 8px 19px 8px 16px;
        }
    }
    &--bordered {
        .SumoSelect>.CaptionCont {
            border: solid 1px @color-gray05;
            padding: 12px 10px 13px 9px;
        }
        .SumoSelect>.CaptionCont>label {
            top: 15px;
        }
        .SumoSelect.open>.optWrapper {
            top: 50px;
        }
    }
}

.sumo__icon {
    .SumoSelect {
        >.CaptionCont {
            padding-left: 0;
            &:before {
                font-family: 'elisa-icons' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
}