@import "_buttons-links.less";
@import "_selects.less";
@import "_inputs.less";
@import "_sticky.less";
@import "_tooltips.less";


@import "_tabs.less";
@import "_tags.less";
@import "_msgs.less";
@import "_alerts.less";
@import "_badge.less";

@import "_banners.less";
@import "_animations.less";

@import "_nav-dropdown.less";
@import "_type-slider.less";
@import "_type-numbers.less";
@import "_sortable-list.less";

@import "components/tabs.less";
@import "components/title-with-action.less";
@import "components/type-personal-data.less";


@import "components/modal.less";

@import "_type-accordion.less";
@import "type-arguments.less";
@import "_type-slider.less";
@import "_type-heading.less";
@import "_type-overview.less";
@import "_type-numbers.less";

@import "_type-search-address.less";
@import "_toggler-switch.less";
@import "_type-info-block.less";
@import "_type-order.less";
@import "_type-package.less";
@import "_type-package-compare.less";
@import "_type-status-summary.less";

@import "_type-numbers-transfer.less";

@import "type-dl.less";
@import "global/global_styles.less";
@import "global/publicparam.less";
@import "global/gutters.less";
@import "dispatcher.less";

@import "global/expandable-table.less";
@import "global/status-tabs.less";


@import "_type-puk.less";

@import "progressbar.less";

@import "components/table_device.less";
@import "components/package-option.less";
@import "components/additional-services.less";


/*Animations*/
@import "_animations.less";
@import "mixins/_animation_mixins.less";


a:focus {
	outline: none;
}
body {
	color: @color-gray01;
	max-width: 1920px;
	margin: 0 auto; }
h1, h2, h3, h4, h5, h6 {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
h1 {
	color: @color01;
	font-size: 40px;
	line-height: 1.25;
	margin-top: 30px;

	@media @media-mobile {
		font-size: 35px;
		line-height: 1;
	}
}
h2, section.content h2, section h2, .content h2 {
	color: @color01;
	font-size: 30px;
	font-weight: 300;
	line-height: 1.4;

	@media @media-mobile {
		font-size: 24px;
		line-height: 1.4;
	}
}
h3 {
	color: @color01;
	font-size: 20px;
	line-height: 1.4;
	font-weight: 400;

	@media @media-mobile {
		font-size: 18px;
		line-height: 1.4;
	}
}
p, SECTION p {
	color: @color-gray01;
	font-size: 15px;
	line-height: 1.4;
	margin-top: 15px; /*_typografy.less:117 as general style */
}

.clear::before, .clear::after {
	height: 0;
}

SECTION::before, SECTION::after {
	content: '';
	display: table;
	clear: both;
	height: 0;
	width: 100%;
}

.font-13 {
	font-size: 13px;
}
.font-20 {
	font-size: 20px;
	line-height: 1.4;

	@media @media-mobile {
		font-size: 17px;
	}
}
.font-30 {
	font-size: 30px;
	line-height: 1.4;

	@media @media-mobile {
		font-size: 24px;
	}
	@media @media-tablet {
		font-size: 24px;
	}
}
.font-light {
	font-weight: 300;
}
.font-bold {
	font-weight: 700;
}
.color-blue {
	color: @color01;
}
.text-uppercase {
	text-transform: uppercase;
}
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}
/* vertical-align  */
.vertical-align-container {
	white-space: nowrap;
}
.vertical-align-middle {
	display: inline-block;
	vertical-align: middle;
	float: none;
	white-space: normal;
}
#block-elisa-breadcrumbs {
	.col00 {
		position: relative;
	}

	.breadcrumb {
		color: rgba(68, 68, 68, 0.8);
		font-family: "Open Sans", Arial, Helvetica, sans-serif;
		font-size: 13px;
		display: inline-block;
		line-height: 19px;
		margin: 8px 0 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;

		a {
			color: rgba(68, 68, 68, 0.8);

			&:hover {
				color: rgba(68, 68, 68, 1);
				text-decoration: none;
			}
		}

		span:last-child {
			font-weight: 600;
		}

		span:not(:first-child):before {
			font-family: FontAwesome;
			content: "\f105";
			font-weight: 400;
			margin: 0 2px 0 0;
			color: rgba(68, 68, 68, 0.8);
		}

		.breadcrumb-on-hero-image & {
			background-color: rgba(0, 0, 0, 0.5);
			display: inline-block;
			padding: 8px 15px;
			color: rgba(255, 255, 255, 0.6);
			margin: 0;
			position: absolute;
			z-index: 100;

			@media only screen and (min-width: 768px) and (max-width: 1200px) {
				max-width: calc(~"100% - 40px");
			}

			a {
				color: rgba(255, 255, 255, 0.6);

				&:hover {
					color: @color-white;
				}
			}

			span:last-child {
				color: @color-white;
			}

			span:before {
				color: rgba(255, 255, 255, 0.6);
			}

			@media @media-mobile {
				display: none;
			}
		}

		.toolbar-tray-open.breadcrumb-on-hero-image & {
			position: relative;
		}

		@media @media-mobile {
			display: none;
		}
	}
}

DIV.col.w50p DIV.block03 {
	&.offer-archived {
		opacity: 0.5;
	}

	DIV.txt {
		@media @media-mobile {
			border-right: none;
			border-bottom: 1px solid @color-gray07;
			padding-bottom: 2em;
		}

		.title {
			margin-bottom: 7px;
		}
	}

	DIV.img.col {
		@media @media-mobile {
			max-width: 100px;
		}
	}
}

DIV.block03 P.title A {
	&:hover {
		color: @color17;
	}
}

.single-row.is-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	> [class*='col-'] {

		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.row.is-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	> [class*='col-'] {

		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.justify-content-center {
	.justify-content(center);
}
.align-items-end {
	.align-items(flex-end);
}

#fakeselect-current DIV {
	min-width: 240px;
}


