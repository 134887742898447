@loaderZindex: 240;
@datepickerZindex: 230;
@theselectZindex: 220;
@modalZindex: 210;
@tooltipstickyZindex: 200;
@overlaymodalZindex: 190;
@overlaystickyZindex: 180;
@overlayZindex: 170;
@npsZindex: 160;
@chatbotZindex: 150;
@searchZindex: 140;
@stickyZindex: 130;
@backtoZindex: 120;
@tabs06Zindex: 110;
@usagemeterZindex: 100;
@tooltipZindex: 90;
@acZindex: 80;
@animEditableZindex: 70;
@scrollfixZindex: 60;


.zindex(@context, @value) {
	z-index: (@context + @value);
}
.zero-zindex {
	z-index: 0;
}
