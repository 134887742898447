
.alert-tooltip {
	font-size: 15px;
	font-weight: 400;
	font-family: @font-main;
	padding: 14px 15px 16px 15px;
	position: relative;
	margin-top: 16px;
	text-align: left;
	&:after {
		content: "";
		position: absolute;
		bottom: 99%;
		left: 40px;
		margin-left: -14px;
		border-width: 14px;
		border-style: solid;
		border-color: transparent transparent @color-alert10 transparent;
	}
	&--with-icon {
		padding: 13px 30px 16px 50px;
		font-size: 13px;
		line-height: 1.54;
		margin-top: 20px;
	}
	&--w282 {
		max-width: 282px;
	}
	&--w50p {
		width: 50%;
		@media @media-mobile {
			width: 100%;
		}
	}
	&__alert {
		&--error {
			background-color: @color-alert10;
			color: @color-gray01;
			&:after {
				border-color: transparent transparent @color-alert10 transparent;
			}
		}
		&--info {
			background-color: @color-blue03;
			color: @color-gray01;
			&:after {
				border-color: transparent transparent @color-blue03 transparent;
			}
		}
		&--info-alert {
			background: @color-alert06;
			&:after {
				border-color: transparent transparent @color-alert06 transparent;
			}
		}
	}
	&__icon {
		&--info {
			background-color: @color-white;
			position: absolute;
			top: 15px;
			left: 13px;
			width: 24px;
			height: 24px;
			border-radius: 24px;
			text-align: center;
			&:after {
				content: "\f129";
				font-family: "FontAwesome";
				color: @color-blue01;
				font-size: 18px;
				line-height: 24px;
			}
		}
	}
}