.tb-show { display: none; }
.framed {
	height: 100%;
	.tb-show { display: block; }
	.tb-hide { display: none; }
	BODY { height: 100%; }
	#box00 { height: 100%; }
	HEADER.main,
	FOOTER#main,
	.banner03 { display: none; }
	.tb_box02 {
		H1 { position: relative; left: 0; }
	}
	H1 { position: absolute; left: -10000px; }
	H2 {
		padding-right: 60px;
		@media @media-mobile { padding-right: 0; }
	}
	#content {
		max-width: 800px; max-height: 95%; margin: 2.5% auto; background: @color-white; padding: 20px 50px; position: relative; .box-sizing(border-box); overflow: auto;
		-webkit-overflow-scrolling: touch;
		overflow-y: auto;
		/*.safari& {
			overflow: scroll; overflow-y: scroll;
		}*/
		H1 { position: static; }
		@media @media-mobile {
			height: 100%; max-height: 100%;
			margin: 0; padding: 20px;
			.col00 { padding: 0; }
			.discrete {
				border: 0;
				margin: 0;
				.link-outline-none;
				font-size: 21px;
				text-indent: -1000px;
			}
			.prev {
				&:before {
					content: @ico-arrleft;
					font-family: icons;
					text-indent: 0 !important;
					float: left;
					font-size: 21px;
					color: @color01;
					display: block;
					padding-right: 10px;
					.link-outline-none;
				}
			}
			.next {
				&:after {
					content: @ico-arrright;
					font-family: icons;
					text-indent: 0 !important;
					float: right;
					font-size: 21px;
					color: @color01;
					display: block;
					padding-left: 20px;
					.link-outline-none;
				}
			}
		}
	}
	.col00 { width: auto !important; }
}