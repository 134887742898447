.type-package {
    font-family: @font-main;
    background: @color-white;
    .type-package__line {
        &--1 {
            margin: 0 30px;
            border-top: 1px solid @color-gray05;
            @media @media-mobile {
                margin: 0 20px;
            }
        }
        &--additional {
			&::before {
				border-top: 1px solid @color-gray06;
                margin: 0 15px;
                content: '';
                position: relative;
                width: auto;
                display: block;
			}
			&-half {
				&::before {
					margin-left: 130px;
				}
			}
            @media @media-notdesktop {
                &-half {
                    &::before {
                        margin: 0 65px;
                    }
                }
            }
        }
    }
    &__mrg {

    }
    &__separator {
        margin: 0 15px;
        border-top: 2px dashed @color-gray05;
        position: relative;
        text-align: center;
        height: 2px;
		&::before {

		}
        &::after {
            background: @color-white;
            content: "\f067";
            font-family: "FontAwesome";
            font-size: 18px;
            position: relative;
            top: -9px;
            text-align: center;
            color: @color-gray05;

			width: 24px;
			height: 24px;
			border-radius: 24px;
			text-align: center;
			padding: 6px;
			line-height: 18px;
        }
    }
    &__header {
        &--no-img{
            order: 1;
            width: 130px;
            .flex(0 0 130px);
            margin-right: 15px;
            @media @media-mobile {
                margin: 0 auto 20px;
                min-width: initial;
                width: 100%;
                .flex(0 0 100%);
            }
        }
    }
    &__title {
        font-family: @font-main;
        font-size: 20px;
        font-weight: 700;
        /*line-height: 1.92;*/
        line-height: 25px;//
        color: @color01;
        margin: 0;
        padding-bottom: 10px;//
        &.type-package__title--inactive {
            color: @color-gray01;
        }
		&--dark {
			color: @color-gray01;
		}
        &--itb {
            color: @color-gray01;
        }
		@media @media-notwide, @media-tablet {
			font-size: 15px;
		}
        @media @media-mobile {
            text-align: center;
            font-size: 15px;
        }
    }
    &__values {
        .display(flex); .justify-content(space-between); .box-sizing(border-box); width: 100%; .flex-direction(row); .flex-wrap(wrap);
    }
    &__subtitle {
        color: @color-gray03;
        margin-top: 0;
        @media @media-mobile {
            text-align: center;
            font-size: 14px;
            margin-top: 0;
        }
    }
    &__text {
        margin-top: 15px;
        font-size: 15px;
        line-height: 1.47;
        color: @color-gray01;
        text-align: left;
        .longtext;
        &--preferential {
            color: @color-orange01;
        }
    }
    &__meta {
        margin-top: 15px;
        font-size: 15px;
        line-height: 1.47;
        color: @color-gray01;
        text-align: left;
        @media @media-notdesktop {
            text-align: center;
        }
    }
    &__item {
        margin-bottom: 30px;
        position: relative;
        &--bordered {
            border: 1px solid @color-gray05;
        }
        &--active {
            border: 2px solid @color-blue02;
        }
        &:first-of-type {
            margin-top: 15px;
        }
    }
    &__badge {
        position: absolute;
        top: -20px;
        background-color: @color02;
        left: -1px;
        padding: 7px 16px;
        color: white;
        font-size: 15px;
        line-height: 1.07;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    &__info {
        padding: 30px 15px 30px 30px;
        .display(flex);
        &--pad20 {
            padding: 20px;
        }
        @media @media-mobile {
            padding: 20px;
            display: block;
        }
        @media @media-tablet {
            padding: 20px;
        }
    }
    &__image {
        order: 1;
        width: 115px;
        .flex(0 0 115px);
        margin-right: 30px;
        &.type-package__image--md {
            width: 85px;
            .flex(0 0 85px);
        }
		&--inactive {
			IMG { .grayscale(100%); }
		}
        @media @media-notwide, @media-tablet {
            width: 85px;
            .flex(0 0 85px);
        }
        @media @media-mobile {
            margin: 0 auto 20px;
            min-width: initial;
            width: 85px;
            .flex(0 0 85px);
            img {
                margin: auto;
            }
        }
    }
    &__caption {
        order: 2;
        width: 100%;
        @media @media-mobile {
            text-align: center;
        }
    }
    &__action {
        height: 100%;
        padding: 30px;
        @media @media-tablet {
            padding: 20px 20px 30px 30px;
        }
        @media @media-mobile {
            padding: 0 20px 30px;
        }
        &--wrapper {
            height: 100%;
            .display(flex);
            .flex-direction(column);
            border-left: 1px solid @color-gray06;
            padding-left: 30px;
            @media @media-tablet {
                padding: 0 20px 0 20px;
                border-left: 1px solid @color-gray06;
                .display(flex);
                border-top: none;
            }
            @media @media-mobile {
                padding-left: 0;
                border: none;
                display: block;
                padding-top: 0;
            }
            &-additional {
                border-left: none;
            }
        }
    }
    &__price {
        font-size: 25px;
        margin: 0;
        font-weight: 700;
        color: @color01;
        line-height: 1;
        /*remove*/
        &--dark {
            color: @color-gray01;
        }
        &--itb {
            color: @color-gray01;
        }
        &--gray {
            color: @color-gray04;
        }
        &--preferential {
            color: @color-orange01;
        }
        &--inactive {
            color: @color-gray01;
        }
        @media @media-notwide {
            font-size: 18px;
        }
        @media @media-tablet {
            font-size: 18px;
            float: none;
            margin-top: 8px;
        }
        @media @media-mobile {
            font-size: 18px;
            float: none;
            padding-bottom: 15px;
            text-align: center;
            margin-top: 8px;
        }
    }
    &__per {
        font-size: 15px;
        text-align: left;
        color: @color-gray03;
        vertical-align: top;
        font-weight: 400;
        line-height: 1.3;
        @media @media-mobile {
            line-height: 1;
        }
    }
    &__button {
        margin-top: auto;
        padding-top: 1em;
        @media @media-tablet {
            float: none;
            width: auto;
        }
        @media @media-mobile {
            margin-top: 20px;
        }
    }
    /*remove this when not in used*/
    &__speed {
        margin-top: 30px;
        @media @media-tablet{
            margin-right: -45px;
        }
        @media @media-desktop {
            margin-right: -30px;
        }

        &--3 {
            @media @media-desktop {
                display: table;
                width: calc(~"100% + 30px");

                .type-package__speed--item {
                    display: table-cell;
                    padding: 0 0 20px;
                    width: 33%;
                }
            }
        }
        &--4 {
            @media @media-notwide {
                .display(flex);
                .flex-wrap(wrap);
                .type-package__speed--item {
                    width: 50%;
                    margin: 0;
                    padding: 20px 0 0;
                    &:nth-of-type(-n+2) {
                        border-bottom: 1px solid @color-gray06;
                        padding: 0 0 20px;
                    }
                    &:nth-of-type(2) {
                        border-right: none;
                    }
                }
            }
            @media @media-wide {
                display: table;
                width: calc(~"100% + 30px");

                .type-package__speed--item {
                    display: table-cell;
                    padding: 0;
                    min-width: auto;
                }
            }
        }
        &--6 {
            @media @media-desktop {
                .display(flex);
                .flex-wrap(wrap);
                .type-package__speed--item {
                    width: 33%;
                    margin: 0;
                    padding: 20px 0 0;
                    &:nth-of-type(-n+3) {
                        border-bottom: 1px solid @color-gray06;
                        padding: 0 0 20px;
                    }
                    &:nth-of-type(3) {
                        border-right: none;
                    }
                }
            }
        }
        &--item {
            display: inline-block;
            margin-left: 30px;
            padding-right: 30px;
            text-align: center;
            padding-bottom: 20px;
            @media @media-desktop {
                &:not(:last-child) {
                    border-right: 1px solid @color-gray06;
                }
            }
            @media @media-notdesktop {
                display: block;
                border-top: 1px solid @color-gray06;
                margin: 0;
                text-align: left;
                padding: 10px 0;

                &:first-child {
                    border: none;
                }
            }
        }
        &--icon {
            text-align: center;
            .fa {
                color: @color03;
                font-size: 24px;
            }
            @media @media-notdesktop {
                display: inline-block;
                .fa {
                    position: relative;
                    top: 5px;
                }
            }
        }
        &--icon-inactive {
            I,.fa {
                color: @color-gray04;
            }
        }
        &--title {
            font-size: 14px;
            line-height: 1.36;
            text-align: center;
            color: @color-gray03;
            margin-right: 10px;
            @media @media-notdesktop {
                line-height: 50px;
            }
        }
        &--value {
            font-size: 22px;
            font-weight: 700;
            line-height: 35px;
            color: @color-gray01;
            &.type-package__speed--value--sm {
                font-size: 16px;
                line-height: 35px;
                @media @media-notdesktop {
                    font-size: 15px;
                    line-height: 1.2;
                }
            }
            @media @media-notdesktop {
                float: right;
                font-size: 18px;
                &.type-package__speed--value--var2 {
                    font-size: 18px;
                    text-align: right;
                    line-height: 32px;
                }
            }
        }
        &--mbit {
            vertical-align: text-top;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
            margin-left: 5px;
            display: inline-block;
            margin-top: -2px;
            @media @media-notdesktop {
                margin-top: -1px;
            }
        }
        &--eu {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
            @media @media-notdesktop {
                margin-top: -4px;
            }
        }

    }
    &__number {
        color: @color01;
        font-size: 30px;
        font-weight: 700;
        margin: 10px 0;
    }

    &__extras {
        padding: 30px 0 0;
        text-align: left;
        p {
            text-align: left;
        }

        ul {
            padding: 10px 0 0;

            li {
                font-size: 15px;
                padding: 0 20px 0 0;
                display: inline-block;

                &:before {
                    content: none;
                }

                [class^="icon-"] {
                    color: @color03;
                    font-size: 22px;
                    vertical-align: middle;
                    padding-right: 8px;
                }
            }
        }
    }
	&__number {
		color: @color01;
		font-size: 30px;
		font-weight: 700;
		margin: 10px 0;
	}
    .type-package__inactive {
        .type-package__image {
            IMG {
                .grayscale(100%);
            }
        }
        .type-package__price {
            color: @color-gray01;
        }
        .type-package__speed--icon {
            .fa {
                color: @color-gray04;
            }
        }
        .type-package__title {
            color: @color-gray01;
        }
    }
    .type-package__arrow {
        &--down {
            &::after {
                content: "\f103";
                font-family: "FontAwesome";
                margin-right: 5px;
                line-height: 1.05;
                text-align: center;
                display: block;
                color: @color-gray04;
                font-size: 30px;
            }
        }
    }
    &.type-package--sm {
        .type-package__info {
            padding: 20px 15px 20px 15px;
        }
        .type-package__item {
            margin-top: 20px;
            margin-bottom: 0;
        }
        .type-package__action {
            padding: 20px 15px;
            &--wrapper {
                padding-left: 15px;
                @media @media-mobile {
                    padding-left: 0;
                }
            }
            @media @media-notdesktop {
                padding: 15px 20px 15px 20px;
            }
        }
        .type-package__content {
            padding-bottom: 0;
            margin-top: 15px;
        }
        .type-package__image {
            width: 85px;
            .flex(0 0 85px);
        }
        .type-package__subtitle {
            text-align: left;
            font-size: 14px;
            margin-top: 0;
            @media @media-mobile {
                text-align: center;
            }
        }


        /*remove when publicparam is ready to use*/
		.type-package__speed {
			margin-top: 0;
			&--value {
				font-size: 16px;
				line-height: 1.56;
				@media @media-mobile {
					font-size: 15px;
					line-height: 1.2;
				}
			}
			&--item {
				padding-bottom: 0;
			}
			&--icon {
				@media @media-desktop {
					display: none;
				}
			}
		}
        .type-package__speed--value {
            font-size: 16px;
            line-height: 1.56;
            @media @media-mobile {
                font-size: 15px;
                line-height: 1.2;
            }
        }
        .type-package__title {
            font-size: 15px;
            @media @media-notdesktop {
                line-height: 2;
            }
        }
        .type-package__price {
            font-size: 18px;
            @media @media-notdesktop {

            }
        }
        &-break {
            .type-package__item {
                @media @media-tablet {
                    display: block;
                    border-top: 1px solid @color-gray06;
                    margin: 0;
                    margin-bottom: 15px;
                    text-align: left;
                    padding: 10px 0;
                }
            }
            .type-package__image {
                @media @media-tablet {
                    margin: 0 auto 20px;
                    min-width: initial;
                    width: 85px;
                    .flex(0 0 85px);
                    img {
                        margin: auto;
                    }
                }
            }
            .type-package__subtitle {
                @media @media-tablet {
                    text-align: center;
                }
            }
            .type-package__caption {
                @media @media-tablet {
                    text-align: center;
                }
            }
            .type-package__info {
                @media @media-tablet {
                    padding: 20px;
                    display: block;
                }
            }
            .type-package__action {
                &--wrapper {
                    @media @media-tablet {
                        padding-left: 0;
                        border: none;
                        display: block;
                        padding-top: 0;
                    }
                }
                @media @media-notdesktop {
                    padding: 0 20px 15px;
                }
            }
        }
    }
    &.type-package--noborder {
        .type-package__item {
            border: none;
        }
    }
    .type-package__inactive {
        .type-package__image {
            IMG {
                .grayscale(100%);
            }
        }
        .type-package__price {
            color: @color-gray01;
        }
        /*remove this when not in used*/
        .type-package__speed--icon {
            I,.fa {
                color: @color-gray04;
            }
        }
        /*publicparam has own inactive incase diff*/
        .publicparam--icon {
            I,.fa {
                color: @color-gray04;
            }
        }
        .type-package__title {
            color: @color-gray01;
        }
    }
    .type-package__nomargin {
        &--top {
            margin-top: 0;
        }
        &--bottom {
            margin-bottom: 0;
        }
    }
    .type-package__nopadding {
        &--bottom {
            padding-bottom: 0;
        }
    }
    .clearfix::before, .clearfix::after {
        content: normal;
    }
    //overwrite for tooltip
    //maybe global ?
    A.question {
        background: @color01;
        border: none;
        color: @color-white;

        &:hover {
            background: @color17;
            color: @color-white;
        }

        &:before {
            top: 2px;
        }

        &--gray {
            color: @color-white; background: @color-gray03;
            &:hover {
                color: @color-white; background: @color-gray03;
            }
        }
    }

    span.question.showtooltip {
        background: #0019af;
        color: #ffffff;
        display: inline-block;
        position: relative;
        cursor: pointer;
        line-height: 14px; //
        width: 14px;
        height: 14px;
        overflow: hidden;
        text-align: center;
        font-weight: bold;
        border-radius: 14px;
        margin: 0 auto;
        top: 2px;

        &:before {
            content: "?";
            display: inline-block;
            width: 10px;
            height: 10px;
            line-height: 14px; //
            font-size: 10px;
            text-align: center;
            position: absolute;
            top: 2px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .app-buttons {
        IMG.img01 {
            @media @media-mobile {
                display: inline-block;
                height: 40px;
            }
        }
    }
    &.type-package--additional {
        .type-package__caption {

        }
        .type-package__price {
            font-size: 15px;
            line-height: 1.47;
            @media @media-notdesktop {

            }
        }
        .type-package__header {
            @media @media-notdesktop {

            }
        }
        .type-package__content {
            @media @media-notdesktop {
                margin-top: 20px;
            }
        }
        .type-package__action {
            @media @media-notdesktop {
                padding: 0 20px 15px;
            }
        }
        .type-package__info--noimage {
            padding: 20px 20px 20px 130px;
            @media @media-notdesktop {
                padding: 20px;
            }
        }
    }
}



