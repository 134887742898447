.modal-devices {
    @media @media-mobile {
        height: 100vh;
        margin: -20px;
        padding:  40px 20px 20px;
    }

    .tb_controls #tb_close.close {
        top: 15px;
        right: 20px;

        &:before {
            color: @color-gray05;
        }

        &:hover {
            opacity: 0.5;
        }

        @media @media-mobile {
            position: fixed;
        }
    }

    h2.modal-block__title {
        font-weight: 700;

        @media @media-mobile {
            background-color: rgba(255,255,255,.9);
            font-size: 20px;
            position: fixed;
            top: 0;
            padding: 20px 0 10px;
            width: 100%;
        }
    }

    table.table-modal {
        margin-bottom: 30px;
        TH {
            border-top: none;
            background-color: transparent;
            vertical-align: bottom;
            word-break: normal;

            @media @media-mobile {
                width: 50%;
                vertical-align: middle;
            }

            img {
                display: block;
                margin-bottom: 10px;
            }
        }

        TD {
            border-left: none;
            word-break: normal;

            @media @media-mobile {
                width: 50%;
            }
        }
        TR:last-of-type {
            TD {
                border: none;
            }

        }
        TR {
            &:hover {
            }
            TD {
                background-color: white;
            }
            TD:hover {
                background-color: white;
            }
        }

        TD {
            width: 50%;
            @media @media-notmobile {
                &:first-child {
                    &:nth-last-child(3) {
                        width: 25%;
                        & ~ TD {
                            width: 37%;
                        }
                    }
                    &:nth-last-child(4) {
                        &,
                        & ~ TD {
                            width: 25%;
                        }
                    }
                }
            }
        }

        .desktop-hide {
            @media @media-notmobile {
                display: none !important;
            }
        }
    }
}
HTML.tb_open {
    @media @media-desktop {
        overflow-y: initial;
    }
}