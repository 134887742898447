.col {
	float: left; .box-sizing(border-box);
	&.f-right {
		float: right;
		& + .col01 { width: 25%; margin: 0; }
		& + .col02 { width: 50%; margin: 0; }
		& + .col03 { width: 75%; margin: 0; }
		& + .col04,
		.col03 & + .col02 { width: 66.6666%; margin: 0; }
		& + .col05,
		.col03 & + .col01 { width: 33.3333%; margin: 0; }
	}
	&.w20p {
		width: 19% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&.w25p {
		width: 24% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&.w30p {
		width: 29% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&.w33p {
		width: 30.6667% !important; margin: 0 0 0 3%;
		&:first-child { margin: 0 0 0 0; }
	}
	&.w33p2 {
		width: 33.3334% !important; margin: 0 0 0 3%; padding-left: 10px; .box-sizing(border-box);
		&:first-child { margin: 0 0 0 0; }
	}
	&.w66p2 {
		width: 65.666667% !important;
		margin: 0 0 0 1%;
	}
	&.w40p {
		width: 39% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&.w50p {
		width: 49% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
		& > H2 { font-size: 28px; margin: .5em 0 0 0 !important; }
		.equalheight & {
			.display(flex);

		}
	}
	&.w60p {
		width: 59% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&.w66p {
		width: 66.66667% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&.w70p {
		width: 69% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
		&> H2 { margin: .8em 0 0 0 !important; }
	}
	&.w75p {
		width: 74% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&.w80p {
		width: 79% !important; margin: 0 0 0 1%;
		&:first-child { margin: 0 1% 0 0; }
	}
	&-center {
		width: 735px; margin: auto;
		&.margt { margin-top: 1em; }
	}
	&-center02 {
		width: 930px; margin: auto; padding-bottom: 60px;
	}
	& > {
		H2, H3, H4, H5, H6 {
			&:first-child { margin: 0; }
		}
	}
	@media @media-tablet {
		&.tablet-nofloat { width: 100% !important; float: none !important; margin: 20px 0 0 0 !important; padding-left: 0 !important; padding-right: 0 !important;
			&:first-child { margin-top: 0 !important; }
		}
		&-center {width: 70%; }
		&-center02 {width: auto; }
		&.f-right {
			.col03 & + .col02 { width: 100%; margin: 0; }
		}
	}
	@media @media-mobile {
		&,
		&.w20p,
		&.w30p,
		&.w33p,
		&.w33p2,
		&.w66p2,
		&.w25p,
		&.w40p,
		&.w50p,
		&.w60p,
		&.w66p,
		&.w70p,
		&.w75p,
		&.w80p { width: auto !important; float: none; margin: 0 !important; }
		&-center, &-center02 { width: auto; }
		&-center02 { padding-bottom: 30px; }

		&.mobile-nofloat { float: none !important; }
	}
}
.col01 {
	width: 23%; margin: 0 0 0 2%;
	&:first-child { width: 25%; margin: 0; }
}
.col02 {
	width: 48%; margin: 0 0 0 2%;
	&:first-child { width: 50%; margin: 0; }
}
.col03 {
	width: 73%; margin: 0 0 0 2%;
	&:first-child { width: 75%; margin: 0; }
}
.col04,
.col03 .col02 {
	width: 64.6666%; margin: 0 0 0 2%;
	&:first-child{ width: 66.6666%; margin: 0; }
}
.col05,
.col03 .col01 {
	width: 31.3333%; margin: 0 0 0 2%;
	&:first-child{ width: 33.3333%; margin: 0; }
}
.col07 {
	width: 25%;
	margin: 0 0 0 4%;
}
.col08 {
	width: 67%;
	margin: 0 0 0 4%;
}

.col22 {
	width: 64.6666%; margin: 0 0 0 2%;
	&:first-child { width: 66.6666%; margin: 0; }
}
.col23 {
	width: 31.3333%; margin: 0 0 0 2%;
	&:first-child { width: 33.3333%; margin: 0; }
}

@media @media-tablet {
	.col01 {
		width: 31.3333%;
		&:first-child { width: 33.3333%; }
	}
	.col03 {
		width: 64.6666%;
		&:first-child { width: 66.6666%; }
	}
}

.col00 {
	width: 980px; margin: 0 auto;
	@media @media-mobile { width: auto; padding: 0 15px; }
	@media screen and (min-width: 760px) and (max-width: 1199px) { width: auto; padding: 0 20px; }
	@media @media-wide { width: 1200px; }
}

.equalheight {
	.display(flex);
	@media @media-mobile { display: block; }
	.separated-right-bottom {
		border-right: 1px solid @color-gray-lightest;
		@media @media-mobile {
			border-bottom: 1px solid @color-gray-lightest;
			border-right: 0;
		}
	}
}

#content {
	font-size: 14px; line-height: 1.64; .clearfix();
	&:last-child { padding-bottom: 30px; }
	IMG { max-width: 100%; height: auto; }
	&:focus { outline: none; }
}
*,
*:before,
*:after {
	.box-sizing(border-box);
}


