.numbers {
	list-style: none; margin: 0 -1%; padding: 0; font-family: @font-main;
	.display(flex);
	.flex-wrap(wrap);
	.box-sizing(border-box);
	&.numbers--disabled {
		position: relative;
		&::before {
			content: "";
			display: block;
			background: rgba(255,255,255, 0.4);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			cursor: default;
			position: absolute;
		}
	}
	.numbers__icon {
		order: 2;
		.align-self(flex-start);
		SPAN {
			font-family: @font-main;
			font-size: 16px;
			font-weight: 700;
		}
		&::before {
			color: @color-blue02;
			font-size: 16px;
			position: relative;
		}
		&--select {
			&::before {
				margin-right: 5px;
				content: "\f067";
				font-family: FontAwesome;

			}
		}
		&--selected {
			&::before {
				content: "\f00c";
				font-family: FontAwesome;
			}
		}
	}
	.numbers__item {
		width: 31.333333%; max-width: 31.333333%; margin: 10px 1%; float: left; padding: 0; overflow: hidden; .flex(0 0 auto); height: auto; min-height: 100%;
		background: @color-white;
		.box-sizing(border-box);
		&::before { display: none; }
		.link-outline-none;
	}
	.numbers__status {
		cursor: pointer; text-decoration: none; position: relative; padding: 15px 15px 15px 15px; width: 100%; height: 100%; min-height: 88px;
		background: @color-white; display: block; float: none; text-align: left; margin: 0 20px 0 0; .box-sizing(border-box); border: 1px solid @color-gray05;
		&:hover { border-color: @color-blue02; }
		&.numbers__status--selected {
			border: 1px solid @color-blue02; cursor: pointer;
			&:hover {
				cursor: default; border: 1px solid @color-blue02;
			}
			/*remove this if not one*/
			&::before {
				content: "\f00c";
				font-family: FontAwesome;
				font-size: 18px;
				color: @color-blue02;
				position: absolute;
				right: 6px;
				top: 0;
				background: none;
			}
		}
		&.numbers__status--disabled {
			&::before {
				content: "";
				display: block;
				background: rgba(255,255,255, 0.4);
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				cursor: default;
				position: absolute;
			}
			&:hover {
				cursor: default; border: 1px solid @color-gray05;
			}
		}
	}
	.numbers__header {
		font-family: @font-main; font-size: 15px; margin: 0; padding-bottom: 10px;
		P {
			margin: 0;
		}
		&.numbers__header--hasicon {
			.display(flex);
			.flex-wrap(nowrap);
			.box-sizing(border-box);
			.align-items(center);
			.justify-content(space-between);
		}
	}
	.numbers__meta {
		.display(flex);
		.flex-wrap(nowrap);
		.box-sizing(border-box);
		.align-items(center);
		.justify-content(space-between);
	}
	.numbers__text {
		font-family: @font-main; font-size: 15px; color: @color-gray01; margin: 0 10px 0 0;
		&--note {
			font-family: @font-main; font-size: 13px; color: @color-gray03; line-height: 1.54; margin: 0 10px 0 0;
		}
		&--warning {
			font-family: @font-main; font-size: 13px; color: @color-alert09;
		}
		&--price {
			font-family: @font-main; font-size: 15px; color: @color-gray01; margin: 0 10px 0 0; line-height: 1.4; font-weight: 700;
		}
	}
	.numbers__msg {
		position: relative;
		padding-left: 25px;
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			width: 18px; height: 18px; line-height: 18px; font-size: 10px;
			.rounded(50%);
			background: @color-gray-light;
			color: @color-white;
			content: "";
			text-align: center;
		}
		&--warning {
			&::before { font-family: FontAwesome; background: @color-alert06; content: "\f12a"; color: @color-alert07; }
		}
	}
	&.numbers__space-sm {
		margin: 0 -5px;
		.numbers__item {
			margin: 10px 5px;
			width: 32.333333%; max-width: 32.333333%;
		}
	}
	@media @media-tablet {
		.numbers__item {
			.flex(0 0 auto);
			width: 48%;
			max-width: 48%;
		}
		&.numbers__space-sm {
			.numbers__item {
				width: 48%;
				max-width: 48%;
			}
		}
	}
	@media @media-mobile {
		max-height: none; overflow: visible; margin: .5em 0 0;
		.numbers__item {
			height: auto; width: 100%; margin: .5em 0 0 0; max-width: 100%; min-height: 100%;
			.numbers__status {
				height: auto; padding: 10px 10px; margin-right: 0;
				SPAN:first-child:last-child { padding-bottom: 5px; }
			}
		}
		&.numbers__space-sm {
			.numbers__item {
				width: 100%;
				max-width: 100%;
			}
		}
	}
}