.type-dl {
	&__label {
		font-family: @font-main;
		font-size: 14px;
		font-weight: 600;
		color: @color-gray01;
		width: 38%;
		white-space: nowrap;
	}
	&__value {
		font-family: @font-main;
		font-size: 14px;
		font-weight: 400;
		color: @color-gray01;
		width: 45%;
	}
}