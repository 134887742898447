.status-tabs {
	margin: 1.5em 0 0 0;
	padding: 0;
	position: relative;
	width: 100%;
	/*ul list*/
	/*list-style: none;*/
	.link-outline-none;
	.display(flex);
	.flex-direction(row);
	.flex-wrap(nowrap);
	.box-sizing(border-box);
	/*fo full check*/
	overflow: hidden;

	.status-tabs__item {
		.flex(0 0 auto);
		width: auto;
		.display(flex);
		padding: 0;
		background: none;
		position: relative;
		border-top: 2px solid transparent;
		margin: 0 2px 0 0;
		&:last-child {
			margin: 0;
		}
		/*ul list*/
		/*&:before {
			display: none;
		}*/
	}
	.status-tabs__wrapper {
		background: @color-gray07;
		.box-sizing(border-box);
		.display(flex);
		.justify-content(center);
		.align-items(center);
		border-width: 2px;
		min-height: 100%;
		height: auto;
		border-style: solid none solid none;
		border-color: @color-gray05 transparent @color-blue02 transparent;
		text-decoration: none;
		cursor: pointer;
		padding: 15px 18px;
		.box-sizing(border-box);
		&:hover {
			border-color: @color-gray04 transparent @color-blue02 transparent;
		}
		.status-tabs__content {
			display: inline-block;
			margin: 0;
			line-height: 1.5;
			text-align: left;
			.status-tabs__text {
				font-family: @font-main;
				color: @color-gray03;
				font-size: 15px;
				font-weight: 400;
				&.status-tabs__text--popular {
					font-size: 13px;
					color: @color-orange01;
				}
			}
			/*.status-tabs__image {
				IMG {
					display: block;
					opacity: .5;
					margin: auto auto 10px;
					.grayscale(100%);
				}
			}*/
		}
		.status-tabs-icon__step {
			display: inline-block;
			width: 24px;
			height: 24px;
			background: @color-gray07;
			color: @color-gray01;
			border: 2px solid @color-gray06;
			.rounded(50%);
			text-align: center;
			font-family: @font-main;
			line-height: 21px;
			font-size: 13px;
			font-weight: 700;
			vertical-align: top;
			margin: 0 9px 0 0;
			&.status-tabs-icon__step--active {
				background: @color-white;
				color: @color-gray01;
				border: 2px solid @color-green01;
			}
			&.status-tabs-icon__step--confirmed {
				background: @color-white;
				color: @color-green03;
				border: 2px solid @color-green01;
				&::before {
					content: "\f00c";
					font-family: FontAwesome;
					font-size: 13px;
					color: @color-green03;
				}
			}
		}
		.status-tabs__icon--sm {
			@media @media-mobile {

			}
		}
		&.status-tabs__wrapper--active {
			background: @color-white;
			border-style: solid;
			border-color: @color-blue02 @color-blue02 @color-white;
			.status-tabs__content {
				.status-tabs__text {
					font-weight: 700;
					color: @color-gray01;
					&.status-tabs__text--popular {
						font-weight: 400;
						color: @color-orange01;
					}
				}
			}
			&::before {
				content: '';
				position: absolute;
				width: 2000px;
				right: 100%;
				bottom: 0;
				border-bottom: 2px solid @color-blue02;
			}
			&::after {
				content: '';
				position: absolute;
				border-bottom: 2px solid @color-blue02;
				width: 2000px;
				left: 100%;
				bottom: 0;
				@media @media-mobile {
					margin-left: 2px;
				}
			}
		}
	}
	&.status-tabs--disabled {
		opacity: 0.2;
	}
}