
HEADER.sub {
	background: darken(@color01,20%); padding: 5px 0;
	&.type02 { background: darken(@color-positive,1%); }
	&.type03 {
		background: @color13;
		P {
			color: @color-black;
			font-size: 15px;
			font-weight: bold;
			&.ico {
				&:before { position: absolute; left: 0; top: 0; font-size: 24px; }
			}
		}
		DIV.input-wrapper {
			margin: 0;
		}
		DIV.button-wrapper .btn {
			padding: 8px 15px;
		}
	}
	P {
		margin: 4px 0 4px 20px;
		color: @color-white;
		&:before {
			font-size: 17px;
		}
		&.ico {
			position: relative; padding-left: 30px;
			&:before { position: absolute; left: 0; top: 4px; }
		}
		&.mt0 { margin-top: 0; line-height: 1.2; }
		SPAN.small { display: block; font-size: 80%; color: fade(@color-white,60%); line-height: 1; }
	}
	A {
		&.link {
			color: @color-white !important; line-height: 30px; text-decoration: underline !important; padding: 0 0 0 25px;
		}
	}
	@media @media-notwide {
		.col00 { width: auto; padding: 0 20px;}
	}
	@media @media-mobile {
		padding-bottom: 10px;
		P.ico { float: none; }
	}
}

HEADER.main {
	width: 100%; position: relative;
	font-family: @font-heading;
	.ru & { font-family: @font-heading-ru; }
	DIV.a { background: @color01; padding: 0; }
	DIV.b { background: @color-white; padding: 27px 0; min-height: 87px; .box-sizing(border-box); border-bottom: 1px solid @color-gray-lightest;
		@media @media-desktop {
			&.pb00 {
				padding: 27px 0 0 0!important;
			}
			.mt05 {
				top: 5px!important;
			}
		}
	}
	@media @media-tablet {
		DIV.a { padding: 0; }
		DIV.b {
			padding: 15px 0 0; border-bottom: 1px solid @color-gray-lightest; height: auto; min-height: 0;
		}
	}
	@media @media-notwide {
		DIV.a,
		DIV.b {
			.col00 { width: auto; padding: 0 20px;}
		}
	}

	@media @media-mobile {
		DIV.a { padding: 0; }
		DIV.b {
			padding: 15px 0 0; min-height: 50px; height: auto;
			DIV#usertools01 {
				A.ico {
					&:first-child:last-child,
					&.user,
					&-user { position: relative; top: .30em; }
					&-private { margin-top: -.15em; }
				}
				SPAN { margin-right: 0; padding: 0; }
			}
		}
	}
}

#logo01 { float: left; height: 39px; width: 102px; position: relative; top: -5px;
	IMG { width: 100%; height: auto; }
	@media @media-tablet { height: 37px; width: 81px; top: 0; }
	@media @media-mobile { height: 35px; width: 58px; top: 0; }
}

/* 4.1 User tools */
DIV#usertools01 { float: right; margin: 17px 0 0 20px; font-weight: 300;
	font-family: @font-heading;
	.ru & { font-family: @font-heading-ru; }
	A {
		color: @color-white; float: left; white-space: nowrap; cursor: pointer;
		&:hover { text-decoration: none; }
		&.ico {
			border-right: 1px solid lighten(@color01,10%); border-color: fade(@color-white,30%);  margin: -.45em 0 0 0;
			&:first-child:last-child,
			&-user{
				border-right: none; line-height: 24px; padding-top: .15em; font-size: 18px;
				&:before { margin-top: -.15em; }
			}
			&-private {
				&:before { margin-top: -.10em; }
			}
			&:before { font-size: 23px; position: relative; top: .15em; }
			& + A { margin-left: 10px; }
			> SPAN {
				float: left; max-width: 190px; overflow: hidden; text-overflow: ellipsis; font-size: 18px; font-weight: bold; white-space: nowrap; line-height: 1.1; padding: 0 10px 0 0; margin: 0 0 0 0;
				SMALL { font-size: 13px; display: block; font-weight: normal; line-height: 1;}
			}
		}
		& + A {
			line-height: 17px;
			.ru & {
				line-height: 23px;
			}
		}
		&.mailbox {
			margin: -4px 20px 0 0;
			position: relative;
			width: 24px;
			height: 24px;
			&.ico-sms {
				&:before {
					font-size: 24px;
					line-height: 24px;
					width: 24px;
					height: 24px;
				}
			}
			SPAN.a {
				text-indent: 0;
				position: absolute;
				background: @color02;
				color: white;
				font-size: 10px;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				text-align: center;
				line-height: 15px;
				top: -3px;
				left: -7px;
				right:100%;
				font-weight: bold;
			}
		}
	}

	@media @media-tablet {
		margin: 8px 0 0 32px; font-weight: normal; font-size: 20px;
		A {
			color: @color01; font-size: 20px; line-height: 33px; /*margin: -4px 10px 0 0;*/  margin: 8px 0 0 0;
			ru & { margin: 10px 0 0 0; }
			&:before { width: 33px; height: 33px; }
			&.ico {
				&:before { position: relative; top: 0; width: auto; }
				&:first-child:last-child,
				&.user,
				&-user { position: relative; top: .30em; }
				> SPAN { border-color: @color01; position: relative; top: .2em;  }
				&-private {
					&:before { margin-top: 0.50em; }
				}
				&-business {
					&:before { margin-top: 0.58em; }
				}
				&-user {
					&:before { margin-top: 0.10em; }
				}
			}
			&.mailbox {
				margin: -4px 15px 0 0;
				&:hover {
					text-decoration: none;
					color: @color02;
				}
				&.ico-sms {
					width: 34px;
					height: 34px;
					&:before {
						font-size: 34px;
						line-height: 34px;
						width: 34px;
						height: 34px;
					}
				}
				SPAN.a {
					top: -6px;
					left: -15px;
					font-size: 16px;
					width: 25px;
					height: 25px;
					line-height: 25px;
					font-weight: bold;
				}
			}
		}

	}
	@media @media-mobile {
		text-indent: -10000px; margin: 2px 0 0 0;
		A:before { width: 23px; height: 26px; text-indent: 0; color: @color01; font-size: 20px; }
		A {
			&.ico {
				margin: -.45em 0 0 0.5em;
				float: right;
				&-user {
					&:before { margin-top: 0; top: 0; margin-right: 0; }
				}
				&-private {
					&:before { margin-top: 0.10em; top: 0; margin-right: 0; }
				}
				&-business {
					&:before { margin-top: 0.38em; top: 0; margin-right: 0; }
				}
			}
			&.mailbox {
				margin: 0 0 0 20px;
				width: 27px;
				height: 27px;
				float: left;
				&.ico-sms {
					&:before {
						font-size: 27px;
						line-height: 27px;
						width: 27px;
						height: 27px;
					}
					SPAN.a {
						top: -10px;
						left: -15px;
						font-size: 16px;
						width: 25px;
						height: 25px;
						line-height: 25px;
						font-weight: bold;
					}
				}
			}
		}
	}
}

/* 4.2 Mobile menu button */
#menubutton {
	display: none; cursor: pointer;
	&:hover { text-decoration: none;
		&:before { color: @color01; }
	}
	@media @media-notdesktop {
		display: block; float: left; text-indent: -10000px; margin: 0 0 0 30px;
		&:before  { content: @ico-menu; text-indent: 0; font-size: 26px; color: @color02; float: left; width: 40px; height: 35px; margin: 7px 20px 0 0; font-family: "icons"; }
	}
	@media @media-mobile {
		margin: 0 0 0 15px;
		&:before { width: 26px; height: 22px; margin: 4px 10px 0 0; }
	}
}

/* 4.3 Language selection */
UL#lang01 { float: right; margin: 11px 0 0 20px; padding: 0; list-style: none; font-size: 15px;
	font-family: @font-heading;
	.ru & { font-family: @font-heading-ru; }
	LI {
		float: left; padding: 0; margin: 0 10px 0 0;
		&:last-child { margin: 0; }
		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ li { margin: 0; }
		&:before { display: none; }
		&.active { display: none; }
	}
	A {
		color: @color-white; float: left; font-family: @font-heading-ru; padding-top: 2px; padding-bottom: 5px; line-height: 24px; background: no-repeat 0 5px; background-size: 28px 20px;
		&:hover { text-decoration: none; }
		&.ru { background-image: url(ico_ru.png); font-family: @font-heading-ru; }
		&.et { background-image: url(ico_et.png); }
	}
	DIV.b & {
		A { color: @color01; }
	}
	&.noico A { background: none !important; padding-left: 0; }
	@media @media-mobile {
		margin: 15px 0 0 0;
		A { line-height: 12px; background-position: 0 0; }
	}
	@media @media-mobile-narrow {
		A { font-size: 10px; margin-top: 2px; }
	}
}

/* 4.4 Cart */
DIV#cart01 {
	float: right; font-size: 16px; position: relative; top: 4px; text-align: right;
	font-family: @font-heading;
	.ru & { font-family: @font-heading-ru; }
	A.ico-cart {
		color: @color01; line-height: 33px; position: relative; cursor: pointer;  float: right; font-weight: bold; margin-top: -10px; margin-bottom: -3px;
		&.first-child:last-child { margin-top: 0; margin-bottom: 0; }
		&:hover { text-decoration: none; color: @color02; }
		&:before { width: 34px; height: 34px; font-size: 34px; margin-top: -.1em; font-weight: normal; }
		&.hasitems:before { margin-right: 10px; }
		SPAN.a { text-indent: 0; font-size: 16px; font-weight: bold; position: absolute; right: 100%; margin-right: -10px; top: -10px; background: @color02; border-radius: 50%; height: 25px; width: 25px; color: @color-white; line-height: 25px; text-align: center;  }
		SPAN.b { display: block; font-size: 13px; color: @color-gray-medium; line-height: 1; font-weight: normal; }
		&:first-child:last-child { margin-top: 1px; }
	}
	P {
		clear: right; /*white-space: nowrap;*/ white-space: normal;  margin: 0; font-size: 14px; color: @color-gray-medium;
		&.red { color: @color-negative; }
		SPAN.small { display: block; font-size: 11px; text-align: right; }
		&.note { word-break: break-word;  width: 110px; }
	}
	@media @media-mobile {
		text-indent: 10000px; position: relative; top: 2px; margin-top: 0;  width: 33px; margin-left: 10px;
		A.ico-cart {
			display: block; width: 33px; margin-top: 0; margin-bottom: 0;
			&:before { width: 33px; height: 26px; text-indent: 0; font-size: 30px; margin-right: 0; }
			SPAN.a { left: -10px; margin-right: 0; }
			&:hover { color: @color01; }
		}
		P {
			position: absolute; right: -77px; top: 32px; margin: 0; text-indent: 0; font-size: 11px;
			A.ask,
			SPAN.small { display: none; }
			.index & { display: none; }
		}
	}
	@media @media-tablet {
		top: 10px; width: auto;  height: 50px;
		A.ico-cart {
			padding-right: 60px; margin-top: 0; margin-bottom: 0;
			top: -.8em; font-size: 17px; font-weight: bold; float: left;
			&:before { font-size: 34px; margin-right: 3px; top: .3em; position: relative; }
			SPAN.a { top: 2px; }
			&:first-child:last-child {
				padding-right: 0; font-size: 20px; font-weight: normal; top: -.4em;
				&:before { top: .05em; }
			}
		}
		P {
			font-size: 12px; text-align: left; color: @color01; clear: left; position: relative; top: -1.8em; left: 45px;
			A.ask,
			SPAN.small { display: none; }
			&.note { width: auto; }
		}
	}
}

/* 4.5 Site tools */
DIV#sitetools {
	float: right;
	.not-logged {
		display: none !important;
	}
	.log01 {
		margin-top: 10px;
		float: right;
		padding-left: 15px;
		border-left: 1px solid @color-gray-medium-light;
	}
}
A.logout01 {
	float: right; color: @color-white; margin: 12px 0 0 20px;
	@media @media-mobile-narrow {
		font-size: 10px;
	}
	@media @media-mobile {
		margin: 12px 0 0 20px;
		font-size: 15px;
		&:hover {
			text-decoration: none;
		}
	}
}