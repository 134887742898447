/*think about list-groupe for width / cols*/
.sortable-list {
	.sortable-list__item {
		.box-sizing(border-box);
		background-color: @color-white;
		color: rgb(39,54,69);
		width: 100%;
		cursor: default;
		margin: 0 0 5px 0;
		border-radius: 0;
		padding: 0;
		/*line-height: 70px;*/
		font-size: 15px;
		font-weight: 400;
		height: auto;
		min-height: 100%;
		.text__disable--highlighting;
		.sortable-list-draggable {
			width: 100%;
			user-select: none;
			cursor: pointer;
			position: relative;
			z-index: 1;
			transition: transform 300ms, z-index 300ms;
			&:hover {
				-webkit-box-shadow: 0 2px 5px 0 rgba(39, 54, 69, 0.4);
				-moz-box-shadow: 0 2px 5px 0 rgba(39, 54, 69, 0.4);
				-ms-box-shadow: 0 2px 5px 0 rgba(39, 54, 69, 0.4);
				-o-box-shadow: 0 2px 5px 0 rgba(39, 54, 69, 0.4);
				box-shadow: 0 2px 5px 0 rgba(39, 54, 69, 0.4);
			}
			&.pressed {
				z-index: 2;
				.sortable-list-draggable__anim {
					-webkit-box-shadow: 0 4px 15px 0 rgba(39, 54, 69, 0.4);
					-moz-box-shadow: 0 4px 15px 0 rgba(39, 54, 69, 0.4);
					-ms-box-shadow: 0 4px 15px 0 rgba(39, 54, 69, 0.4);
					-o-box-shadow: 0 4px 15px 0 rgba(39, 54, 69, 0.4);
					box-shadow: 0 4px 15px 0 rgba(39, 54, 69, 0.4);
					transform: scale(1.1);
				}
			}
			.sortable-list-draggable__anim {
				width: 100%;
				background-color: rgb(240,241,245);
				box-shadow: none;
				transform: scale(1);
				transition: box-shadow 300ms, transform 300ms;
				padding: 0 0 9px 0;
			}

		}
		&:before {
			display: none;
		}
	}
	.sortable-list-content {
		.display(flex);
		.flex-wrap(nowrap);
		margin: 0;
		padding: 0;
		.align-items(center);
		.box-sizing(border-box);
		.flex-wrap(nowrap);
		&__fields {
			.flex(1 1 auto);
		}
		&__number {
			.flex(0 0 20px);
			.display(flex);
			.align-self(flex-start);
			.box-sizing(border-box);
			padding-top: 22px;

		}
		.sortable-list-content__text {
			font-size: 18px;
			font-family: @font-main;
			font-weight: 700;
			text-align: center;
			line-height: 1.56;
		}
	}
	.sortable-list-item {
		.display(flex);
		.flex-wrap(wrap);
		.link-outline-none;
		.box-sizing(border-box);
		.sortable-list-item__icon {
			font-size: 24px;
			color: @color-gray03;
			&.sortable-list__icon--drag {
				width: 100%;
				height: 100%;
				padding-top: 18px;
				text-align: center;
				padding-left: 20px;
				@media @media-notdesktop {
					padding-left: 0;
					padding-top: 0;
					text-align: left;
				}
			}
			&.sortable-list-item__icon--remove {
				.display(flex);
				.justify-content(flex-end);
				padding-top: 24px;
				@media @media-notdesktop {
					padding-top: 7px;
				}
			}
			&.sortable-list-item__icon--pad {

			}
		}
		.sortable-list-item__list {
			@media @media-desktop {
				&.col-md-auto { width: auto; }
			}
		}
		@media @media-notdesktop {
			padding: 17px 9px 14px;

			.sortable-list-item__order--md-1 {
				order: 1;
			}
			.sortable-list-item__order--md-2 {
				order: 2;
			}
			.sortable-list-item__order--md-3 {
				order: 3;
			}
			.sortable-list-item__order--md-4 {
				order: 4;
			}
		}
		.no-gutters-md > .col,
		.no-gutters-md > [class*="col-"] {
			@media @media-notdesktop {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
	.display(flex);
	.flex-wrap(wrap);
	.link-outline-none;
	.box-sizing(border-box);
	margin: 0;
	padding: 0;
	list-style: none;
	height: auto;
	min-height: 100%;
}