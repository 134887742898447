.simple-tooltip {
	position: relative;
	&.simple-tooltip__dispatcher {
		.tooltiptext__light {
			background: @color-white;
			border-color: @color-orange02;
		}
	}
	.tooltiptext {
		visibility: hidden;
		width: 260px;
		background-color: @color-gray02;
		color: #fff;
		padding: 15px;
		border-radius: 0;
		position: absolute;
		z-index: 1;
		opacity: 0;
		transition: opacity 0.3s;
		font-size: 13px;
		line-height: 1.46;
		font-weight: 400;
		text-align: left;
		&__light {
			background: @color-white;
			border-color: @color-orange02;
		}
		&__top {
			bottom: 125%;
			left: 50%;
			margin-left: -50%;
			&:after {
				content: "";
				position: absolute;
				top: 100%;
				left: 30%;
				margin-left: -14px;
				border-width: 14px;
				border-style: solid;
				border-color: @color-gray02 transparent transparent transparent;
			}
			&.tooltiptext__top--sm {
				bottom: 150%;
				left: 0;
				margin-left: -80%;
			}
		}
		&__right {
			left: 125%;
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 100%;
				margin-top: -14px;
				border-width: 14px;
				border-style: solid;
				border-color: transparent @color-gray02 transparent transparent;
			}
		}
		&__left {
			bottom: auto;
			right: 128%;
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 100%;
				margin-top: -14px;
				border-width: 14px;
				border-style: solid;
				border-color: transparent transparent transparent @color-gray02;
			}
		}
		&__bottom {
			top: 135%;
			left: 50%;
			margin-left: -50%;
			&:after {
				content: "";
				position: absolute;
				bottom: 100%;
				left: 30%;
				margin-left: -14px;
				border-width: 14px;
				border-style: solid;
				border-color: transparent transparent @color-gray02 transparent;
			}
		}
		&__sm {
			font-size: 12px;
			width: auto;
			max-width: 80px;
			text-align: center;
			&:after {
				margin-left: 0;
				border-width: 8px;
			}
		}
	}
	&:hover .tooltiptext {
		visibility: visible;
		opacity: 1;
	}
}