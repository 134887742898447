.type-status-summary {
	.type-status-summary__wrapper {
		border: 1px @color-gray06;
		border-style: solid;
	}
	.type-status-summary__header {
		background: @color-gray06;
		/*? koneliitumine*/
		&__bg {
			background: @color-gray07;
		}
		padding: 8px 10px 8px 14px;
	}
	.type-status-summary__items {
		border: 1px @color-gray06;
		border-style: solid none none;
		background: @color-white;
		padding: 11px 53px 11px 11px;
		position: relative;
		&.type-status-summary__items--no-icon {
			padding: 8px 10px 8px 14px;
		}
	}
	.type-status-summary__title {
		margin: 0;
		font-weight: 600;
		color: @color-gray01;
	}
	.type-status-summary__text {
		margin: 0;
		color: @color-gray01;
		font-weight: 400;

		/*i think its global stuff right*/
		&--note {
			color: @color-gray03
		}
		&--right {
			text-align: right;
		}
	}
	.type-status-summary__note {
		color: @color-gray03
	}

	.type-status-summary__content {
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(nowrap);
		.align-items(center);
	}
	.type-status-summary__label {
		.flex(0 1 auto);
		padding-right: 20px;
	}
	.type-status-summary__info {
		.flex(1 1 auto);
		.display(flex);
		.justify-content(flex-end);
		&.type-status-summary__info--hasicon {
			padding-left: 20px;
			position: relative;
		}
	}
	.type-status-summary__icon {
		display: inline-block;
		position: absolute;
		right: 10px;
		text-align: center;
		top: 0;
		min-height: auto;
		height: 100%;
	}
	.type-status-summary__label {

	}
	.type-status-summary__item {
		margin: 17px 0 0 0;
	}
}