.dispatcher {
	width: 100%;
	&.dispatcher--border {
		.dispatcher__item {
			border: none;
			.dispatcher__wrapper {
				border: 1px solid @color13;
			}
		}
	}
	&.dispatcher--wauto{
		width: auto;
		.dispatcher__item {
			width: 100%;
			border: none;
			height: auto;
			min-height: inherit;
			.subitem01 {
				.flex(0 0 auto);
				width: 100%;
				border: 1px solid @color13;
			}
		}
	}
	.dispatcher__wrapper {
		&.dispatcher__wrapper--full-width {
			width: 100%;
		}
		&.dispatcher__wrapper--pad {
			padding: 20px;
			&-sm {
				padding: 10px;
			}
		}
	}
	.dispatcher__item {
		.display(flex);
		.flex-wrap(wrap);
		.box-sizing(border-box);
		.flex-wrap(nowrap);
		width: 100%;
		height: 100%;
		min-height: 100%;
		border: 1px solid @color13;
		.dispatcher__body {

		}
		.dispatcher__header {
			.flex(0 0 auto);
			.dispatcher__icon {
				font-size: 18px;
				line-height: 1;
				color: @color-gray01;
				width: 65px;
				padding: 10px;
				background: @color-orange02;
				/*min-height: 22px;*/
				text-align: center;
				&::before {
					color: @color-gray01;
					line-height: 1;
				}
				&.dispatcher__icon--v-center {
					.display(flex);
					.align-items(center);
					height: 100%;
					width: 40px;
				}
				&.dispatcher__icon--with-arrows {
					/*&:after {
					content: "\f107";
					font-family: FontAwesome;
					margin-left: 5px;
					font-size: 20px;
					line-height: 1.05;
					vertical-align: bottom;
				}*/
				}
			}
		}
		&.dispatcher__item--column {
			.flex-direction(column);
			.dispatcher__body {
				width: 100%;
			}
			.dispatcher__header {
				width: 100%;
			}

		}
		&.dispatcher__item--row {
			.flex-direction(row);
			.dispatcher__body {
				width: auto;
			}
			.dispatcher__header {
				width: auto;
			}
		}
		.dispatcher__body {
			.display(flex);
			.align-items(center);
			.flex(1 1 auto);
			.flex-wrap(wrap);
			.justify-content(flex-start);
			.box-sizing(border-box);
			/*&.dispatcher__wrapper--center {
				.justify-content(center);
			}*/
			&.dispatcher__body--b {
				padding: 5px 0;
				.align-items(center);
				/*replace with element*/
				/*&.dispatcher__wrapper--separator {
					.item-sub {
						border-right: 1px solid @color-gray-medium-lightest;
					}
				}*/
				.item-sub {
					/*&.mt0 {
						margin-top: 0;
					}*/
					&.pad01 {
						padding: 0 5px;
					}
					text-align: left;
					padding: 0 15px;
					margin: 5px 0;
					P {
						margin: 0;
					}
				}
			}
			&.dispatcher__body--c {
				padding: 10px 0;
				.item-sub {
					.display(flex);
					.justify-content(space-between);
					.flex-wrap(wrap);
					padding: 0 15px;
					margin: 0;
					width: 100%;
					white-space: normal;
					text-align: left;
					P {
						margin: 0;
						font-size: 13px;
					}
					@media @media-mobile {
						padding: 0 10px;
					}
				}
			}
		}
	}
}