.hascard {
	#notsupported { display: none; }
	@media @media-tiny {
		#notsupported { display: block !important; }
		> #box00 { display: none; }
	}
	@media @media-notmobile {
		background: @color-gray-lightest;
	}
	#content {
		width: 100%; .flex-display(flex); flex-direction: column; -ms-flex-direction: column; align-items: center; justify-content: center; padding: 0;
		@media @media-notmobile {
			height: 100vh;
		}
	}
	#card {
		position: relative;
		width: 780px;
		height: auto;
		margin: 0 auto;
		background: @color-white;
		border: 1px solid @color-gray-light;
		padding: ceil(@gridunit*5.5) ceil(@gridunit*8) ceil(@gridunit*5.5) ceil(@gridunit*15);
		background-image: url('card/bg.jpg');
		background-repeat: no-repeat;
		.box-sizing(border-box);
		text-align: right;
		@media screen and (max-width: 800px) {
			width: 95%;
		}
		@media @media-tablet {
			background-size: 30%;
		}
		@media @media-mobile {
			width: 100%;
			height: auto;
			border: none;
			padding: ceil(@gridunit*9) ceil(@gridunit*2.5) ceil(@gridunit*2.5);
			background-size: 50%;
			overflow: hidden;
			min-height: 0 !important;
		}
		H1 { font-size: ceil(@base-font-size*2.9); color: @color01; line-height: 0.95; font-weight: 400; }
		H2 { color: @color-black; }
		P {
			margin-top: 1.5em; color: @color-gray-dark; width: 80%; float: right;
			@media @media-mobile { width: 100%; }
		}
		UL {
			&.lang {
				list-style: none; position: absolute; bottom: ceil(@gridunit*6.5); left: ceil(@gridunit*8);
				@media @media-mobile {
					bottom: auto; left: auto; top: ceil(@gridunit*3); right: ceil(@gridunit*2.5);
				}
				LI {
					float: left; padding: 0;
					&:before { display: none; }
					&:after { content: '|'; color: @color01; }
					&:last-child {
						&:after { display: none; }
						A { padding-right: 0; }
					}
					&:first-child {
						A { padding-left: 0; }
					}
					A {
						padding: 0 ceil(@gridunit*1.25);
					}
				}
			}
		}
	}
}