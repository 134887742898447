/*old*/
UL.msg {
	margin: 0; padding: 0; clear: both;
	& > LI {
		&.noico {
			padding: 15px;
			&:before { display: none; }
		}
		&.msg__info-alert {
			&:before { background: @color-msg-negative; content: "\f12a"; color: @color-msg-negative-text; }
		}
		&.msg__warning {
			&:before { background: @color-msg-warn; content: "\f12a"; color: @color-msg-warn-text; }
		}
		&.msg__ok {
			&:before { background: @color-msg-positive; content: "\f00c"; color: @color-msg-positive-text; }
			&.msg__ok--blue {
				&:before { background: @color-blue02; color: @color-white; }
			}
		}
		&.msg__info {
			&:before { background: @color-blue03; content: "\f129"; color: @color-blue01; }
		}
		&.msg__muted {
			&:before { background: @color-msg-muted; color: @color-msg-muted-text; }
		}
		&.msg__no-icon {
			padding: 15px 15px 15px 0;
			&:before { display: none; }
		}
		background: transparent; margin: 0; padding: 15px 15px 15px 40px; color: @color-black; font-size: 15px; overflow: hidden; position: relative; line-height: 1.33;
		&:before { text-align: center; line-height: 30px; background: @color-white; width: 30px; height: 30px; .rounded(50%); font-family: "FontAwesome"; font-weight: 400; position: absolute; top: 10px; left: 0; font-size: 18px; /*content: "\f129";*/ color:@color-msg-info-text; }
		[class^="ico"]:before,
		[class*=" ico"]:before { margin-right: 5px; }

	}
	.msg__text {
		color: @color-gray01; font-size: 15px; font-weight: 400; margin: 1em 0 0 0; text-align: left; line-height: 1.33;
		&:first-child {
			margin: 0;
		}
	}
	.msg__note  {
		color: @color-gray03;
		font-family: @font-main;
		font-size: 13px;
		font-weight: 400;
		display: block;
	}
	&__bg {
		margin: 16px 0;
		& > .msg__item {
			&.msg__info-alert {
				background: @color-msg-negative;
				&:before { content: "\f12a"; color: @color-msg-negative-text;  }
			}
			&.msg__warning {
				background: @color-msg-warn;
				&:before { content: "\f12a"; background: @white; color: @color-msg-warn-text;  }
			}
			&.msg__ok {
				background: @color-msg-positive;
				&:before { content: "\f00c"; color: @color-msg-positive-text; background: @color-white; }
			}
			&.msg__info {
				background: @color-blue03;
				&:before { content: "\f129"; color: @color-blue01; background: @color-white;  }
			}
			&.msg__muted {
				background: @color-msg-muted;
				&:before { color: @color-msg-muted-text; background: @color-white;  }
			}
			background: none; padding: 15px 15px 15px 58px;
			&:before { left: 13px; font-family: "FontAwesome"; }
		}
	}
}

/*new*/

.msg {
	margin: 0;
	padding: 0;
	clear: both;
	& > .msg__item {
		/*&.ico*/
		&.noico {
			padding: 15px;
			&:before {
				display: none;
			}
		}
		&.msg__info-alert {
			&:before {
				background: @color-msg-negative;
				content: "\f00d";
				color: @color-msg-negative-text;
			}
		}
		&.msg__warning {
			&:before {
				background: @color-msg-warn;
				content: "\f12a";
				color: @color-msg-warn-text;
			}
		}
		&.msg__ok {
			&:before {
				background: @color-msg-positive;
				content: "\f00c";
				color: @color-msg-positive-text;
			}
			&.msg__ok--blue {
				&:before { background: @color-blue02; color: @color-white; }
			}
		}
		&.msg__info {
			&:before {
				background: @color-msg-info;
				content: "\f129";
				color: @color-msg-info-text;
			}
		}
		&.msg__muted {
			&:before {
				background: @color-msg-muted;
				color: @color-msg-muted-text;
			}
		}
		background: transparent;
		margin: 0;
		padding: 15px 15px 15px 40px;
		color: @color-black;
		font-size: 15px;
		overflow: hidden;
		position: relative;
		line-height: 1.33;
		&:before {
			text-align: center;
			line-height: 30px;
			background: @color-white;
			width: 30px;
			height: 30px;
			.rounded(50%);
			font-family: "FontAwesome";
			font-weight: 400;
			position: absolute;
			top: 10px;
			left: 0;
			font-size: 18px;
			content: @ico-info-clear;
			color: @color-msg-info-text;
		}
		[class^="ico"]:before,
		[class*=" ico"]:before {
			margin-right: 5px;
		}
	}
	.msg__text {
		color: @color-gray01;
		font-size: 15px;
		font-weight: 400;
		margin: 0;
		text-align: left;
		line-height: 1.33;
	}
	.msg__title {
		color: @color-gray01;
		font-size: 18px;
		font-weight: 400;
		margin: 0;
		text-align: left;
		line-height: 1.47;
	}
	.msg__note {
		color: @color-gray03;
		font-family: @font-main;
		font-size: 13px;
		font-weight: 400;
		display: block;
	}
	&__bg {
		margin: 16px 0;
		& > .msg__item {
			&.msg__warning {
				background: @color-msg-warn;
				&:before {
					content: "\f12a";
					color: @color-msg-warn-text;
					background: @color-white;
				}
			}
			&.msg__info-alert {
				background: @color-msg-negative;
				&:before {
					content: "\f12a";
					background: @color-white;
					color: @color-msg-negative-text;
				}
			}
			&.msg__ok {
				background: @color-msg-positive;
				&:before {
					content: "\f00c";
					color: @color-msg-positive-text;
					background: @color-white;
				}
			}
			&.msg__info {
				background: @color-msg-info;
				&:before {
					content: "\f129";
					color: @color-msg-info-text;
					background: @color-white;
				}
			}
			&.msg__muted {
				background: @color-msg-muted;
				&:before {
					background: @color-white;
					color: @color-msg-muted-text;
				}
			}
			background: none;
			padding: 15px 15px 15px 58px;
			&:before {
				left: 13px;
				font-family: "FontAwesome";
			}
		}
	}
}