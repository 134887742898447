.type-numbers-transfer {
	&.type-numbers-transfer__bg {
		background: @color-gray07;
	}
	&.type-numbers-transfer__pad {
		&--popup {
			padding: 15px 35px 25px 35px;
			margin: 0 -35px 0 -35px;
		}
	}
	@media @media-mobile {
		&.type-numbers-transfer__pad {
			&--popup {
				padding: 15px 20px 25px 20px;
				margin: 0 -20px 0 -20px;
			}
		}
	}
}
.type-numbers-transfer-content {
	.type-numbers-transfer-content__item {

	}
	.type-numbers-transfer-content__wrapper {
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(nowrap);
		.align-items(center);
		padding: 10px 0;
	}
	.type-numbers-transfer-content__label {
		.flex(0 1 auto);
		.display(flex);
		.justify-content(flex-end);
	}
	.type-numbers-transfer-content__info {
		.flex(1 1 auto);
		padding-right: 20px;
		&.type-numbers-transfer-content__info--hasicon {
			padding-left: 20px;
			position: relative;
		}
	}
	.type-numbers-transfer-content__text {
		font-size: 15px; font-family: @font-main; color:@color-gray01; font-weight: 400; line-height: 1.4;
		margin: 0;
		&--note {
			margin: 0;
			color: @color-gray03
		}
		&--title {
			margin: 0;
			font-size: 18px;
		}
		&--right {
			text-align: right;
		}
	}
	.type-numbers-transfer-content__icon {
		position: absolute;
		left: 0;
		top: 0;
	}
	&.type-numbers-transfer-content__pad {
		&--md {
			padding: 1em 0 1em 0;
		}
		&--sm {

		}
		&--lg {

		}
	}
}
