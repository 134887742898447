//====================================
// INPUTS
//====================================
/*SELECT, INPUT.select {
	height: 50px;
}*/
INPUT[type="date"],
INPUT[type="datetime"],
INPUT[type="datetime-local"],
INPUT[type="email"],
INPUT[type="month"],
INPUT[type="number"],
INPUT[type="password"],
INPUT[type="search"],
INPUT[type="tel"],
INPUT[type="text"],
INPUT[type="time"],
INPUT[type="url"],
INPUT[type="week"],
INPUT:not([type]) {
	/*!important is temporary*/
	&:focus { border-color: @color-gray03; outline: none; }
	&.sm {
		padding: 8px 15px 7px; height: 40px; *height: auto; -moz-appearance: none; -webkit-appearance: none;  border-color: @color-gray05; min-width: 0;
	}
	&.md {
		/*remove important when you ready*/
		padding: 0 16px 0;
		/*padding: 8px 15px 7px!important;*/ height: 50px!important; *height: auto!important; -moz-appearance: none; -webkit-appearance: none; font-size: 15px; border-color: @color-gray05;  min-width: 0;
		&.error {
			INPUT {
				border: 1px solid @color-alert04;
				color: @color-gray01;
				background: @color-alert05;

			}
		}
		&:focus { border-color: @color-gray03; outline: none; }
		&::-webkit-input-placeholder { color: @color-gray04; font-weight: normal; font-style: italic; }
		&::-webkit-input-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
		&::-moz-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
		&:-ms-input-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
		&:-moz-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
	}
	&.lg {
		font-size: 18px;
	}
	&.pad {
		&--sm {
			padding: 8px 8px 7px
		}
	}
	&.disabled {
		background: @color-gray07;
	}
	&.input-w100p {
		width: 100%;
		/*moz and chrome conflict min-width*/
		min-width: 0;
		max-width: 100%;
		@media @media-mobile {
			width: 100%;
		}
	}
	&.input-w282 {
		width: 282px;
		@media @media-mobile {
			width: 240px;
		}
	}
	&.input-w293 {
		width: 293px;
		@media @media-mobile {
			width: 100%;
		}
	}
	&.input-w150 {
		width: 150px;
	}
	&.has-valitator {
		padding: 0 30px 0 16px;
	}
	&:focus { border-color: @color-gray03; outline: none; }
	&::-webkit-input-placeholder { color: @color-gray04; font-weight: normal; font-style: italic; }
	&::-webkit-input-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
	&::-moz-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
	&:-ms-input-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
	&:-moz-placeholder { color: @color-gray04; font-weight: normal; font-style: italic;}
}
//====================================
// SELECT-INPUT
//====================================
SELECT, INPUT.select {
	&.sm {
		height: 40px; max-height: 500px; min-height: 40px;
	}
	&.md {
		height: 50px!important; -moz-appearance: none; -webkit-appearance: none;
		padding: 5px 16px 4px; color: @color-gray01;
		font-size: 15px;
	}
	&.w90 {
		width: 90px;
		@media @media-mobile {
			width: 90px;
		}
	}
	&.lg {
		height: 65px;
	}
	&.select--secondary {
		border-style: none none solid none;
	}
}

INPUT.num-noscroll[type=number]::-webkit-inner-spin-button,
INPUT.num-noscroll[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: textfield;
	margin: 0;
}
input[type=number] {
	-moz-appearance:textfield;
}