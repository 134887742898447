ul.tabs01 {
    border-bottom: 2px solid @color03;
    margin: 30px 0 30px;
    li {
        &:first-child {
            margin: 0 0 0 -2px !important;
        }
        padding: 0 2px;
        top: 2px;
        a {
            border: none;
            border-top: 2px solid @color-gray05;
            span {
                color: @color-gray01;
                font-weight: 400;
                height: 48px;
            }
            &.active {
                border: 2px solid @color03;
                border-bottom: 2px solid @color-white;
                span {
                    color: @color-gray01;
                    font-weight: 700;
                }
            }
        }
    }
}

ul.tabs--small {
    li a span {
        height: 38px;
    }
}

UL.tabs01.tabs--mobile-select {
    @media @media-notdesktop {
        display: none;
    }
}

.tabs-select-wrap {
    .tabs-select {
        display: none;
    }
    @media @media-notdesktop {
        position: relative;
        max-width: 280px;
        margin: 20px 0 10px;
        .tabs-select {
            display: block;
            border: 1px solid @color-gray05;
            &.open {
                border: 1px solid @color-gray03;
            }
            .active-li {
                font-size: 15px;
                line-height: 48px;
                padding: 0 15px;
                &:after {
                    content: "\f107";
                    font-family: FontAwesome;
                    float: right;
                    font-size: 18px;
                }
            }
        }

        .tabs--mobile-select {
            border: none;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
            display: none;
            position: absolute;
            margin: 0;
            top: 50px;
            z-index: 100;
            &.open {
                display: block;
            }
            li {
                &:first-child:nth-last-child(n),
                &:first-child:nth-last-child(n) ~ li {
                    width: 100%;
                    border: none;
                    a {
                        border: none;
                        margin: 0;
                    }
                }
                padding: 0;
                top: 0;
                border: none;
                margin: 0 !important;
                &:first-child {
                    margin: 0 !important;
                }
                &:hover {
                    background: @color-gray07;
                }
                a {
                    background: @color-white;
                    border: none;
                    &:hover {
                        background-color: @color-gray07;
                    }
                    &.active {
                        border: none;
                        span {
                            border-left: 3px solid @color03;
                        }
                    }
                    span {
                        font-size: 15px;
                        text-align: left;
                        padding: 10px 15px;
                        height: auto;
                    }
                }
            }
        }
    }
}

.tabs-menu {
    @media @media-notdesktop {
        height: 50px;
        margin-top: 30px;
    }

    > li {
        padding: 0;
        &:before {
            display: none;
        }
        > a {
            font-weight: bold;
            text-align: center;
            list-style: none;
        }
    }
    &--f-left {
        > li {
            float: left;
        }
    }
    &__large {
        display: table;
        @media @media-desktop {
            width: 100%;
            max-width: 400px;
        }
        > li {
            //@media @media-desktop {
            display: table-cell;
            float: none;
            //}
            > a {
                color: @color-gray04;
                font-size: 18px;
                padding: 16px 10px;

                @media @media-desktop {
                    display: block;
                }
                @media @media-tablet {
                    padding: 16px 40px;
                }
                @media @media-mobile {
                    font-size: 15px;
                    padding: 16px 25px;
                }
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: @color-gray03;
                }
            }
            > a.active {
                color: @color-gray01;
                border-bottom: 2px solid @color03;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: @color-gray01;
                }
            }
        }
    }
    &__default {
        > li {
            > a {
                color: @color-gray04;
                font-size: 15px;
                padding: 8px 6px;
                display: block;
                margin-right: 30px;
                line-height: 1;
                @media @media-mobile {
                    font-size: 15px;
                }
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: @color-gray03;
                }
            }
            > a.active {
                color: @color-gray01;
                border-bottom: 2px solid @color03;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: @color-gray01;
                }
            }
            &:first-child {
                > a {
                    padding-left: 0;
                }
            }
            &:last-child {
                > a {
                    margin-right: 0;
                }
            }
        }
    }
}

.tabs {
    &__title {
        color: @color01;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.27;
        text-align: left;
        margin-top: 0;
        @media @media-tablet {
            font-size: 28px;
            margin-bottom: 30px;
        }
        @media @media-mobile {
            font-size: 28px;
            margin-bottom: 20px;
        }
    }
    &__info {
        font-size: 15px;
        line-height: 1.53;
        text-align: left;
        color: @color-gray01;
        margin-top: 45px;
        @media @media-tablet {
            margin-top: 50px;
            font-size: 18px;
            line-height: 1.61;
            color: @color-gray08;
        }
        @media @media-mobile {
            margin-top: 30px;
            font-size: 15px;
            line-height: 1.47;
            color: @color-gray08;
        }
    }
    &__price {
        font-size: 40px;
        font-weight: bold;
        line-height: 0.75;
        text-align: left;
        color: @color-gray01;
        margin-top: 40px;
        @media @media-tablet {
            margin-top: 15px;
        }
        @media @media-mobile {
            margin-top: 0;
            font-size: 30px;
            float: right;
        }
        &--info {
            font-size: 15px;
            line-height: 1.33;
            color: @color-gray08;
            @media @media-mobile {
                float: left;
            }
        }
    }
    &__per {
        font-size: 18px;
        line-height: 1;
        text-align: left;
        color: @color-gray04;
        vertical-align: top;
        font-weight: 400;
        @media @media-mobile {
            font-size: 14px;
        }
    }
}

.tabs-canals__modal {
    display: inline-flex;
    text-align: center;

    li {
        margin: 0;
        >a {
            color: @color-gray04;
            padding: 10px;
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }
        &.active {
            >a {
                color: @color-gray01;
                border-bottom: 2px solid @color03;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: @color-gray01;
                }
            }
        }
    }

}

.tabs-flex {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 16px -4px;

    @media @media-notdesktop {
        flex-direction: column;
        margin: 0;
    }

    > li {
        width: 100%;
        padding: 0;
        margin: 0 4px;

        @media @media-notdesktop {
            margin: 4px 0;
        }

        &:before {
            content: none;
        }

        a {
            height: 100%;
            &:hover {
                text-decoration: none;
                color: @color01;
            }
        }
    }
}

// owerwrites tabs styles
UL.tabs04 {
    border-bottom-color: @color03;
    A {
        background-color: @color-gray07;
        border-color: @color-gray05 transparent @color03;

        SPAN {
            color: @color-gray01;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
        &:hover {
            border-top-color: @color-gray04;
            border-bottom-color: @color03;
        }
    }
    A.active {
        border-color: @color03 @color03 #ffffff;
    }

    LI {
        //float: none;
        display: table-cell;
        border-left: 2px solid transparent;

        &:first-child {
            margin: 0 !important;
        }
    }
    &.in3cols, &.in2cols {
        li {
            margin: 0 1px !important;
        }
    }
    &.tabs04b {
        display: flex;
        li {
            min-width: auto;
            display: flex;
            align-items: stretch;
            @media @media-desktop {
                min-width: 25%;
                &:nth-last-child(n+4) {
                    &, & ~ li {
                        min-width: auto;
                    }
                }
            }
            //IE fallback
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                width: 25%;
                &:nth-last-child(n+4) {
                    &, & ~ li {
                        width: 20%;
                    }
                }
            }

            &.active {
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    margin-right: 4px !important;
                }
            }
            a {
                overflow: hidden;
            }
        }
        &:before {
            border-bottom: 2px solid @color03;
        }

        li.active a.active {
            border-bottom-color: @color-white;
        }
    }
}

UL.tabs04 LI:first-child:nth-last-child(2) A, UL.tabs04 LI:first-child:nth-last-child(3) A, UL.tabs04 LI:first-child:nth-last-child(4) A, UL.tabs04 LI:first-child:nth-last-child(2) ~ li A, UL.tabs04 LI:first-child:nth-last-child(3) ~ li A, UL.tabs04 LI:first-child:nth-last-child(4) ~ li A {
    @media @media-mobile {
        border-bottom-color: @color03;
    }

    span {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}



//.tabs-packages {
//    > li {
//        width: 100%;
//        padding: 0;
//        margin: 10px 4px;
//
//        @media @media-mobile {
//            margin: 4px 0;
//        }
//
//        &:before {
//            content: none;
//        }
//
//        a {
//            &:hover {
//                text-decoration: none;
//                color: @color01;
//            }
//        }
//    }
//}