@media print {
	* { text-shadow: none !important; color: #000 !important; background: transparent !important; box-shadow: none !important; }
	a, a:visited { text-decoration: none; }
	pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
	thead { display: table-header-group; }
	tr,img { page-break-inside: avoid; }
	img { max-width: 100% !important; }
	p, h2, h3 { orphans: 3; widows: 3; }
	h2, h3 { page-break-after: avoid; }
	select { background: #fff !important; }
	.table { td, th { background-color: #fff !important; }}
	.table { border-collapse: collapse !important; }
}
