/*gutters*/
.no-gutters  {
	&.single-row,
	&.row {
		margin-right: 0;
		margin-left: 0;
		& > .col,
		[class*='col-'] {
			padding-right: 0;
			padding-left: 0;
			@media @media-notdesktop {
			}
		}
	}
}

.no-gutters-md {
	&.single-row,
	&.row {
		margin-right: 0;
		margin-left: 0;
		& > .col,
		[class*='col-'] {
			@media @media-notdesktop {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}

.gutter-5  {
	&.single-row,
	&.row {
		margin-right: -5px;
		margin-left: -5px;
		& > .col,
		[class*='col-'] {
			padding-right: 5px;
			padding-left: 5px;
			@media @media-notdesktop {
			}
		}
	}
}

.gutter-10   {
	&.single-row,
	&.row{
		margin-right: -10px;
		margin-left: -10px;
		& > .col,
		[class*='col-'] {
			padding-right: 10px;
			padding-left: 10px;
			@media @media-notdesktop {
			}
		}
	}
}
.gutter-20  {
	&.single-row,
	&.row {
		margin-right: -20px;
		margin-left: -20px;
		& > .col,
		[class*='col-'] {
			padding-right: 20px;
			padding-left: 20px;
			@media @media-notdesktop {
			}
		}
	}
}

.gutter-30  {
	&.single-row,
	&.row {
		margin-right: -30px;
		margin-left: -30px;
		& > .col,
		[class*='col-'] {
			padding-right: 30px;
			padding-left: 30px;
			@media @media-notdesktop {
			}
		}
	}
}