.progressbar {
	.display(flex);
	margin: 2em 0 0 0;
	.flex-wrap(wrap);
	.box-sizing(border-box);
	.progressbar__item {
		.display(flex);
		.flex-order(1);
		border: 1px @color-gray05;
		background: @color-gray07;
		position: relative;
		z-index: 1;
		.justify-content(flex-start);
		.align-items(flex-start);
		.flex(1);
		width: 100%;
		min-height: 103px;
		max-height: 145px;
		border-style: solid;
		padding: 10px;
		border-right: 1px solid transparent;
		/*margin: -0.5px;
		padding: 10.5px;*/
		&.progressbar__item--first {
			padding-right: 110px;
			.justify-content(flex-end);
			border-style: solid none none solid;
			@media @media-tablet {
				padding-right: 40px;
			}
			@media @media-mobile {
				padding: 10px;
				.justify-content(flex-start);
			}
			@media-notdesktop {
				border-left: 4px solid transparent;
			}
		}
		&.progressbar__item--active {
			background: @color-white;
			.flex-order(1);
			border-style: solid none none solid;

			&.progressbar__item--active-last {
				border-style: solid solid none solid;

				/*here*/
				border-right: 1px solid @color-gray05;
				@media @media-notdesktop {
					border-bottom: 1px solid @color-gray05;
					border-right: none;
					border-left: 4px solid transparent;
				}
			}
			@media @media-notdesktop {
				border-left: none;
				min-height: 55px;
				border-left: 4px solid transparent;
			}
		}
		&.progressbar__item--confirmed {
			background: @color-white;
			border-bottom: 4px solid @color-green01;
			.flex-order(1);
			&:hover {
				cursor: pointer;
			}
			@media @media-desktop {
				&:hover {
					cursor: pointer;
					border-color: @color-gray03;
					z-index: 2;
					border-bottom: 4px solid @color-green01;
					/*here*/
					border-right: 1px solid @color-gray03;
					/*& +  progressbar__item {
						border-left: 1px solid @color-gray03;
					}*/
				}
			}
			@media @media-notdesktop {
				border-bottom: 1px @color-gray06;
				border-left: none;
				&:hover {
					/*border-bottom: 1px solid @color-gray05;*/
					border-right: none;
				}
			}
		}
		&.progressbar__item--last {
			.justify-content(flex-start);
			padding: 10px 13px;
			border-style: solid solid solid solid;
			margin-right: 1px;

			/*here*/
			border-right: 1px solid @color-gray05;
			@media @media-notdesktop {
				border-left: 4px solid transparent;

				/*border-bottom: 1px @color-gray06;*/
				border-style: solid none solid solid;
				min-height: 55px;
				border-bottom: 1px solid @color-gray05;
			}
		}
		&.progressbar__item--summary {
			background: @color-white;
			border: none;
			padding-top: 0;
			max-height: 100%;
			@media @media-notdesktop {
				padding: 10px;
			}
		}
		&:before {
			display: none;
		}
	}
	.progressbar__content {
		width: 100%;
		height: 100%;
		.progressbar__header {
			display: inline-block;
			width: calc(~"100% - 17px");
			padding-right: 10px;
			&.progressbar__header--hasedit {
				display: inline-block;
				width: calc(~"100% - 17px");
				padding-right: 10px;
			}
		}
		.progressbar__title {
			font-family: @font-main;
			font-weight: 400;
			position: relative;
			margin: 0;
			font-size: 13px;
			line-height: 1.54;
			color: @color-gray01;
			white-space: normal;
			@media @media-notdesktop {
				font-size: 13px;
			}
		}
		.progressbar__text {
			font-family: @font-main;
			font-weight: 400;
			position: relative;
			margin: 0;
			font-size: 13px;
			line-height: 1.54;
			white-space: normal;
			color: @color-gray01;
			@media @media-notdesktop {
				font-size: 13px;
			}
		}
		.progressbar__step {
			display: inline-block;
			width: 24px;
			height: 24px;
			background: @color-gray07;
			color: @color-gray01;
			border: 2px solid @color-gray06;
			.rounded(50%);
			text-align: center;
			font-family: @font-main;
			line-height: 21px;
			font-size: 13px;
			font-weight: 700;
			vertical-align: top;
			margin: 0 9px 0 0;
			&.progressbar__step--active {
				background: @color-white;
				color: @color-gray01;
				border: 2px solid @color-green01;
			}
			&.progressbar__step--confirmed {
				background: @color-white;
				color: @color-green03;
				border: 2px solid @color-green01;
				&::before {
					content: "\f00c";
					font-family: FontAwesome;
					font-size: 13px;
					color: @color-green03;
				}
			}
		}
		.progressbar__wrapper {
			display: inline-block;
			width: calc(~"100% - 45px");
			box-sizing: border-box;
		}
		.progressbar__caption {
			margin: 5px 0 10px 0;
			@media @media-notdesktop {
				padding: 0 5px 0 37px;
			}
		}
		&.progressbar__bg {

		}
	}
	.progressbar__actions {
		display: inline-block;
		color: @color-gray03;
		vertical-align: top;
		float: right;
	}
	.progressbar__pad {
		padding: 10px;

	}
	&.progressbar--no-summary {
		@media @media-wide {
			.progressbar__item--last + .progressbar__components {
				display: block;
			}
		}
		@media @media-notwide {
			.progressbar__item--last + .progressbar__components {
				display: block;
			}
		}
	}
	@media @media-wide {
		.progressbar__item--active + .progressbar__components {
			display: block;
		}
	}
	@media @media-notwide {
		.progressbar__item--active + .progressbar__components {
			display: block;
		}
	}
	@media @media-notdesktop {
		margin: 1em -15px 0;
		padding: 0 0 2em 0;
		.flex-direction(column);
		.progressbar__content {
			.progressbar__header {
				display: inline-block;
				width: calc(~"100% - 90px");
				padding-right: 10px;
				&.progressbar__header--hasedit {
					display: inline-block;
					width: calc(~"100% - 90px");
					padding-right: 10px;
				}
			}
		}
		.progressbar__item {
			.justify-content(flex-start);
			border-left: 4px transparent;
			border-style: solid none none solid;
			.flex(0 0 auto);
			width: 100%;
			.flex-order(2);
			min-height: 55px;
			border-left: 4px solid transparent;
			&.progressbar__item--active {
				min-height: 55px;
				.flex-order(2);
				/*border-bottom: 1px solid @color-gray05;*/
				border-left: 4px solid @color-white;
			}
			&.progressbar__item--confirmed {
				min-height: 55px;
				.flex-order(2);
				border-bottom: 1px @color-gray06;
				border-left: 4px solid @color-green01;
			}
		}
		.progressbar__item--active + .progressbar__components {
			display: block;
		}
		.progressbar__pad {
			padding: 10px 13px;
		}
	}
	/*&.progressbar--primary {
		.progressbar__item {
			width: 400px;
			.flex(1);
			.justify-content(center);
			.align-items(center);
			height: auto;
			min-height: 71px;
			padding: 10px;
			margin: 0;
			border-right: none;
			&.progressbar__item--active {
				background: @color-white;
				border-bottom: 4px solid @color-gray05;
				@media @media-mobile {
					border-bottom: 1px solid @color-gray05;
					border-left: 4px solid @color-gray05;
				}
			}
			&.progressbar__item--confirmed {
				background: @color-white;
				border-bottom: 4px solid @color-green01;
				@media @media-mobile {
					border-bottom: 1px @color-gray06;
					border-left: 4px solid @color-green01;

				}
			}
			&.progressbar__item--last {
				padding-left: 110px;
				border-style: solid none solid solid;
				@media @media-tablet {
					padding-left: 40px;
				}
				@media @media-mobile {
					border-left: 4px transparent;
					padding: 10px;
					border-bottom: 1px @color-gray06;
					border-style: solid none solid solid;;
				}
			}
			&.progressbar__item--last {
				padding-left: 110px;
				@media @media-tablet {
					padding-left: 40px;
				}
			}
			&.progressbar__item--two-first  {
				@media @media-desktop {
					width: 50%;
					padding-right: 246px;
				}
				@media @media-tablet {
					width: 50%;
					padding-right: 135px;
				}
				@media @media-mobile {
					width: 100%;
				}
			}
			&.progressbar__item--two-last  {
				@media @media-desktop {
					width: 50%;
					padding-left: 246px;
				}
				@media @media-tablet {
					width: 50%;
					padding-left: 135px;
				}
				@media @media-mobile {
					width: 100%;
				}
			}
			@media @media-mobile {
				.flex(0 0 auto);
				width: 100%;
			}
		}
		.progressbar__content {
			width: auto;
			height: auto;
			.progressbar__title {
				white-space: nowrap;
			}
		}
	}*/
	&.progressbar--secondary {
		.progressbar__item {
			&.progressbar__item--confirmed {
				border-bottom: 4px solid @color-green01;
				@media @media-desktop {
					&:hover {
						border-bottom: 4px solid @color-green01;
					}
				}
				@media @media-notdesktop {
					border-bottom: 1px @color-gray06;
				}
			}
			&.progressbar__item--active {
				@media @media-notdesktop {
					border-top: 2px solid @color-green01;
					border-left: none;
					min-height: 55px;
				}
			}
			&.progressbar__item--summary {
				@media @media-notdesktop {
					border-top: 1px @color-gray06;
				}
			}
			@media @media-notdesktop {
				.flex-direction(column);
				border-style: solid none none none;
				min-height: 55px;
				.flex(0 0 auto);
				width: 100%;
				margin: 0;
				padding: 10px;
			}
		}
		.progressbar__item--last {
			border-color: @color-gray05;
			@media @media-notdesktop {
				border-style: solid none solid none;
			}
		}
		.progressbar__components {
			@media @media-notdesktop {
				padding: 0 15px;
				border-bottom: 2px solid @color-green01;
			}
		}
	}
}
.progressbar__components {
	padding: 50px 0 20px 0;
	display: none;
	.flex(1 1 auto);
	width: 100%;
	.flex-order(2);
	@media @media-notdesktop {
		padding: 0 13px 0 17px;
	}
}