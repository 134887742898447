/* 6. Thickbox */
@import (reference) "mixins/zindex_mixins";
.tb {
	&_overlay { position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: @color-black; opacity: 0.6; .zindex(@modalZindex, 0); }
	&_loader { .box-sizing(border-box); position: fixed; .zindex(@modalZindex,1); left: 50%; top: 50%; padding: 30px; text-align: center; background: @color-white; }
	&_frame {
		.box-sizing(border-box); position: fixed; .zindex(@modalZindex,2); left: 0; right: 0; top: 0; bottom: 0; padding: 0;
		&.tb_gallery { position: fixed; }
		.message { padding: 20px; }
		P {
			margin: 0; padding: 0;
			&.tb_image {
				overflow: hidden; padding: 30px; background: @color-white; text-align: center; .box-sizing(border-box); position: relative;
				IMG { max-width: 100%; max-height: 100%; width: auto !important; height: auto !important; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; }
			}
			&.tb_title {
				width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; padding: 50px 135px 45px 46px; font-size: 38px; color: @color-black; height: 135px; .box-sizing(border-box);
				+ P.tb_desc { padding-top: 0; }
			}
			.tb_desc { padding: 20px; }
		}
		&.type03 {
			font-size: 25px; line-height: 34px; font-weight: bold; font-family: @font-main; color:@color-black-light;
		}
		.tb_controls { position: relative; .zindex(@modalZindex,3); }
		.tb_image_wrapper {
			position: relative; height: 100%;
			DIV.nav {
				overflow: hidden; margin: 35px; background: @color-white; position: absolute; bottom: 0; left: 0; right: 0;
				UL { clear: both; list-style: none; margin: 0 auto; padding: 0; overflow: hidden; text-align: center; height: 80px; }
				LI {
					height: 80px; width: 75px; .box-sizing(border-box); padding: 0; margin: 0; overflow: hidden; float: left;
					&:before { display: none; }
					&:first-child A { margin: 0; }
					&.active A { border-bottom: 2px solid @color02; }
					A {
						display: block; position: relative; margin: 0 0 0 10px; .box-sizing(border-box); padding: 0; height: 80px; width: 65px; text-align: center; overflow: hidden; background: none !important;
						IMG { pointer-events: none; max-height: 65px; max-width: 65px; position: absolute; top: 0; left: 0; right: 0; bottom: 15px; margin: auto; }
					}
				}
			}
		}
		&_inner {
			overflow: hidden; position: fixed; width: 100%; height: 100%; left: 0 !important; right: 0 !important; top: 0 !important; bottom: 0 !important; margin-left: 0 !important;
			.tb_gallery & { height: 90%; top: 5% !important; bottom: 5% !important; width: 800px; margin-left: auto !important; margin-right: auto !important; background: @color-white; -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.15); box-shadow: 0 0 10px 0 rgba(0,0,0,0.15); }
			IFRAME { width: 100% !important; }

		}
		@media @media-mobile {
			.tb_image_wrapper {
				height: 100%; background: @color-white;
				DIV.nav { padding: 10px 35px; background: @color-white; position: absolute; left: 0; bottom: 0; right: 0;}
			}
			P.tb_title { padding: 6px 45px 3px 15px; font-size: 18px; height: 35px; }
			P.tb_image { height: 300px; .display(flex); .align-items(center); vertical-align: middle; }
			&_inner {
				&,
				.tb_gallery & { width: 100%; height: 100%; position: fixed; top: 0 !important; bottom: 0; left: 0 !important; right: 0; margin: 0 !important; margin-left: 0 !important }
			}
		}
		@media @media-notdesktop {
			&_inner IFRAME { height: 100% !important; }
		}
	}
	&__header {
		padding: 33px 34px 27px 34px;
		width: 100%;
		left: 0;
		top: 0;
		position: sticky;
		border-radius: 3px;
		background-color: rgba(255, 255, 255, 0.95);
		.box-sizing(border-box);
		z-index: 1;
		&.tb__header--pad {
			&-sm {
				padding: 23px 45px 17px 34px;
				@media @media-mobile {
					padding: 23px 45px 17px 18px;
				}
			}
		}
		H1 {
			&.tb_title {
				font-size: 25px;
				line-height: 1.36;
				padding: 0;
				font-weight: 700;
				&.tb_title--itb {
					color: @color-gray01;
					font-family: @font-main;
				}
			}
		}
		&.tb__header-anim {
			@media @media-mobile {
				padding: 8px 47px 8px 18px;
				H1 {
					&.tb_title {
						font-size: 15px;
						line-height: 2.27;
						padding: 0;
						-webkit-transition: font-size 0.3s ease, line-height, .3s, ease;
						transition: font-size, .3s, ease, line-height, .3s, ease;
					}
				}
			}
		}
		@media @media-mobile {
			padding: 18px 47px 24px 18px;
			H1 {
				&.tb_title {
					font-size: 24px;
					line-height: 1.42;
				}
			}
		}
	}
	&_box01 {
		min-height: 360px; .zindex(@modalZindex, 0);
		&,
		&.tb_frame_inner { max-height: 90%; /*max-height: 100%;*/ max-width: 100%; position: absolute !important; top: 5% !important; bottom: 5%; left: 0 !important; right: 0; margin: auto !important; .box-sizing(border-box);  }
		&.tb_frame_inner { position: fixed !important;
			@media @media-mobile {
				-webkit-overflow-scrolling: touch;
				overflow-y: scroll;
			}
		}
		&.overlay-content--w782 {
			width: 782px;
			@media @media-mobile {
				width: 100%;
			}
		}
		&.tb_img {
			&--success {
				width:132px;
				height: 118px;
				margin: 0 auto;
				text-align: center;
				display: block;
				IMG {
					width: 100%;
					height: auto;
				}
			}
		}
		.tb_controls { position: absolute; top: 21px; right: 19px; height: 25px; width: 20px; line-height: 25px;
			@media @media-mobile {
				width: 15px;
				height: 18px;
				line-height: 18px;
				/*top: 21px;*/
				top: 18px;
			}
		}
		.tb_box02 {
			background: @color-white; top: 0; left: 0; max-height: 100%; overflow: auto; padding: 35px; .box-sizing(border-box);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			-o-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			-moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			.tb_heading02 {
				font-size: 25px; line-height: 34px; font-weight: bold; font-family: @font-main; color:@color01;
			}
			&__bg {
				background: @color-gray07;
				margin: 0 -35px;
				padding: 5px 35px;
				@media @media-mobile {
					margin: 0 -20px;
					padding: 5px 20px;
				}
			}
			&__pad {
				&--feedback {
					padding: 18px;
				}
			}
			.tb_heading01 {
				background: lighten(@color-gray-lightest,5%);
				margin: -35px -35px 35px;
				padding: 35px 35px;
				overflow: hidden;
				.display(flex);
				.align-items(center);
				.img {
					float: left;
					width: 56px;
					margin-top: 10px;
					IMG {
						max-width: 100%;
						height: auto;
					}
				}
				.text {
					float: left;
					width: 100%;
					margin-left: -91px;
					padding-left: 137px;
					box-sizing: border-box;
					font-size: 14px;
				}
				.meta {

				}
			}
			.box_end {
				background: #fff;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 35px;
				padding: 20px 0 35px 0;
			}
			.tb_list_item {
				padding-bottom: 30px;
				position: relative;
				font-size: @base-font-size-paragraph;
				.listcol {
					color: @color-gray-medium-light;
				}
				.icon-numb {
					position: absolute;
					top: -4px;
				}
				.pad01 {
					padding-left: 25px;
					.nopadding {
						padding: 0!important;
					}
				}
				.pad {
					padding-left: 40px;
					P {
						margin: 0;
					}
					.txt {
						margin-left: 20px;
						P {
							margin: 10px 0 0 0;
						}
						SPAN.ico-check {
							&::before {
								color: #86d986;
								font-weight: bold;
							}
						}
					}
				}
			}
			@media @media-mobile {
				height: 100%;
			}
		}
		&b {
			.tb_box02 { background: @color-black; padding: 0; }
			.tb_controls { top: -30px; right: -20px; }
		}
		.tb_box03 {
			height: 480px;
			padding-top: 60px;
			IFRAME {
				width: 100%;
				height: 100%;
			}
		}
		#tb_close { float: right; }
		H1 {
			font-size: 38px; font-weight: normal;
			&.tb_title {
				font-size: 25px;
				font-weight: 700;
				line-height: 1.42;
				color: @color01;
				font-family: @font-main;
				@media @media-mobile {
					text-align: left;
					font-size: 24px;
					line-height: 1.42;
				}
				&--feedback {
					font-size: 15px;
					font-weight: 700;
					padding: 0;
					line-height: 1.4;
					font-family: @font-main;
					color: @color-blue01;
					padding-right: 25px;
					text-align: left;
				}
				&.tb_title--itb {
					color: @color-gray02;
				}
			}
		}
		@media @media-notdesktop {
			top: 0 !important; bottom: 0 !important; max-height: 100%;
			#tb_close { margin-right: 0; }
		}
		@media @media-mobile {
			top: 0; left: 0; right: 0; height: 100% !important; width: 100% !important; transform: none;
			-webkit-overflow-scrolling: touch;
			.tb_box02 {
				padding: 20px;
				top: 35px;
				left: 0;
				right: 0;
				bottom: 0;
				overflow: hidden;
			}
		}
		/*hack before new modal is born*/
		&--max-height {
			max-height: 1000px;
			height: 90%;
			@media @media-mobile {
				max-height: 100%;
				height: 100%;
			}
		}
	}

}

#tb {
	&_close {
		position: absolute; height: 25px; width: 20px; text-indent: -10000px; top: 0; right: 0; .zindex(@modalZindex,5); float: right;
		&:before { font-family: FontAwesome; content: "\f00d";  line-height: 25px; float: left; text-indent: 0; text-align: center; color: @color-gray04; font-size: 25px;  }
		&:hover { border-bottom: none !important; text-decoration: none !important; outline: none !important; }
		#content > &,
		.col00 > & {
			display: none;
			.framed & { display: block; }
		}
		@media @media-mobile {
			width: 15px;
			height: 18px;
			&:before { font-size: 18px; line-height: 18px; }
		}
	}
	&_prev { background: url(arr04.png) no-repeat 50% 50%; left: 50%; margin: -45px 0 0 -350px; }
	&_next { background: url(arr05.png) no-repeat 50% 50%; right: 50%; margin: -45px -350px 0 0; }
	&_prev, &_next {
		position: fixed; width: 40px; height: 90px; text-indent: -10000px; top: 45%;  .zindex(@modalZindex,3);
		&:hover { opacity: 0.5; }
		&.disabled { display: none; }
		@media @media-mobile {
			&,
			&:hover { background-color: transparent; }
		}
	}
	@media @media-mobile {
		&_prev, &_next {
			&,
			&:hover { background-color: transparent; }
		}
	}
}
.iframe_tb_overflow {
	overflow: inherit!important;
	overflow-y: inherit!important;
}
.iframe_tb_padding {
	padding: 34px 34px 0 34px;
	@media @media-mobile {
		padding: 18px 18px 0 18px;
	}
}
.tb__pad {
	&--md {
		padding: 0 35px;
		@media @media-mobile {
			padding: 0 18px;
		}
	}
	&--has-tb-box-end {
		padding-bottom: 100px;
		@media @media-mobile {
			padding-bottom: 150px;
		}
	}
}
.iframe_tb_pad {
	padding: 0 60px!important;
	overflow: inherit!important;
	overflow-y: inherit!important;
	.tb_controls {
		#tb_close {
			display: none;
		}
	}
	#tb_close {
		display: none;
	}
	@media @media-mobile {
		padding: 0 40px!important;
		.tb_close {
			display: none;
		}
		.tb_controls {
			#tb_close {
				display: none;
			}
		}
	}
}
.tb_box_end {
	.box-sizing(border-box);
	width: 100%;
	max-width: 100%;
	z-index: 10000;
	background: @color-white;
	padding: 20px 15px;
	position: relative;
	&.tb_box_end--has-gradient {
		background: transparent;
		background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2 ), rgba(255, 255, 255, 0.0 ));
		position: absolute;
		bottom: 0;
		left: 0;
	}

}
@media @media-notdesktop {
	HTML.tb_open {
		BODY { height: 100%; overflow: hidden; }
		#box00 { opacity: 0; }
	}
}
@media @media-desktop {
	HTML.tb_open {
		BODY { height: 100%; overflow: hidden; }
	}
}