.publicparam-grid() {
	.flex-wrap(wrap);
	&.publicparam--4 {
		.publicparam--item {
			width: 50%;
			margin: 0;
			padding: 20px 0 0;

			&:nth-of-type(-n+2) {
				border-bottom: 1px solid @color-gray06;
				padding: 0 0 20px;
			}


			&:nth-of-type(2) {
				border-right: none;
			}
		}
	}
}

.publicparam-column() {
	.flex-wrap(wrap);
	.publicparam--item {
		border-top: 1px solid @color-gray06;
		.display(flex);
		.align-items(center);
		border-right: none;
		border-bottom: none;
		width: 100%;
		min-height: 65px;
		&:not(:last-child) {
			border-right: none;
		}
		&:first-child {
			border-top: 0;
		}
		.publicparam--value {
			.flex(1 1 auto);
			text-align: right;
		}

		// only first two
		&:nth-of-type(-n+2) {
			padding: 0 0 0;
		}

		.publicparam--icon {
			margin-right: 5px;

			.fa {
				position: relative;
				top: 3px;
			}
		}
	}
}

.publicparam {
	.display(flex);
	.flex-wrap(nowrap);
	margin-top: 30px;

	&.publicparam--noicons {
		.publicparam--icon {
			I,.fa {
				display: none;
			}
		}
	}
	&.publicparam--3 {
		.publicparam--item {
			padding: 0 0 10px;
			width: 33%;
			margin-left: 0;
		}
	}
	&.publicparam--4 {
		.publicparam--item {
			margin: 0;
			padding-left: 5px;
			padding-right: 5px;
			width: 25%;
			min-width: auto;
			&:nth-of-type(2) {
				border-right: 1px solid @color-gray06;
			}
		}
	}
	&.publicparam--5 {
		.publicparam--item {
			&:last-child {
				border-right: 1px solid @color-gray06;
			}
		}
	}
	&.publicparam--5, &.publicparam--6 {
		.flex-wrap(wrap);
		.publicparam--item {
			width: 33%;
			margin: 0;
			padding: 20px 0 0;

			&:nth-of-type(-n+3) {
				border-bottom: 1px solid @color-gray06;
				padding: 0 0 20px;
			}
			&:nth-of-type(3) {
				border-right: none;
			}
		}
	}
	.publicparam--icon {
		text-align: center;
		.fa {
			color: @color03;
			font-size: 24px;
		}
	}
	.publicparam--item {
		padding: 0 5px;
		&:not(:last-child) {
			border-right: 1px solid @color-gray06;
		}
		text-align: center;

		// TODO: change class to publicparam--item__inactive
		&.publicparam__inactive {
			.publicparam--icon {
				I,.fa {
					color: @color-gray04;
				}
			}
		}

		.publicparam--value {
			font-size: 18px;
			line-height: 32px;
			font-weight: 700;
			text-align: center;
			&-sm {
				font-size: 15px;
				line-height: 32px;
				font-weight: 700;
				text-align: center;
			}
		}

		.publicparam__unit {
			vertical-align: text-top;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.4;
			margin-left: 5px;
		}

		.publicparam__roaming {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.4;
		}
	}
	&.publicparam--sm {
		@media @media-wide {
			.publicparam-grid();
		}

		@media @media-notwide, @media-tablet, @media-mobile {
			.publicparam-column();
			&.publicparam--6 {
				.publicparam--item {
					padding: 0 0 0;
					&:nth-of-type(-n+3) {
						border-bottom: none;
						padding: 0 0 0;
					}
				}
			}
			&.publicparam--5 {
				.publicparam--item {
					padding: 0 0 0;
					&:nth-of-type(-n+3) {
						border-bottom: none;
						padding: 0 0 0;
					}

					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}
	&--compare {
		margin-top: 20px;
		&.publicparam--text-left {
			.publicparam--title {
				text-align: left;
			}
			.publicparam--value {
				text-align: left;
			}
		}
		&.publicparam--compare-left {
			@media @media-desktop {
				.publicparam--item {
					text-align: left;
					&:first-child {
						padding-left: 0;
						margin-left: 0;
					}
				}
				.publicparam--title {
					text-align: left;
				}
				.publicparam--value {
					text-align: left;
				}
			}
		}
		.publicparam--icon {
			display: none;
			@media @media-mobile {
				display: inline-block;
			}
		}
	}

	@media @media-mobile {
		&.publicparam--6 {
			.publicparam-column();
			.publicparam--item {
				padding: 0 0 0;
				&:nth-of-type(-n+3) {
					border-bottom: none;
					padding: 0 0 0;
				}
			}
		}
		&.publicparam--5 {
			.publicparam-column();
			.publicparam--item {
				padding: 0 0 0;
				&:nth-of-type(-n+3) {
					border-bottom: none;
					padding: 0 0 0;
				}

				&:last-child {
					border-right: none;
				}
			}
		}
		&.publicparam--3 {
			.publicparam-column();
		}
		&.publicparam--4 {
			.publicparam-column();
		}
		.publicparam-column();
	}
}
