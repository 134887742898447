.type-search-address {
    margin-top: 50px;
    @media @media-tablet {
        margin-top: 40px;
    }
    @media @media-mobile {
        margin-top: 15px;
    }
    &__title {
        margin-bottom: 8px;
        @media @media-mobile {
            font-size: 18px;
            line-height: 1.39;
            margin-bottom: 13px;
        }
    }
    &__tabs {
        margin-top: 40px;
        &--content {
            margin-top: 85px;
            @media @media-tablet {
                margin-top: 40px;
            }
            @media @media-mobile {
                margin-top: 40px;
            }
        }
        &--menu {
            margin-top: 50px;
            margin-bottom: 50px;
            &+.type-search-address__tabs--content {
                margin-top: 0;
            }
        }
        @media @media-tablet {
            margin-top: 0;
        }
        @media @media-mobile {
            margin-top: 0;
        }
        &--icon {
            max-width: 80px;
            margin-right: 15px;
            float: left;
            @media @media-mobile {
                max-width: 60px;
            }
        }
        &--text {
            font-size: 13px;
            line-height: 1.54;
            color: @color-gray02;
        }
        &--title {
            font-size: 15px;
            font-weight: bold;
            line-height: 1.47;
            color: @color-gray01;
            margin-bottom: 5px;
        }
        &--caption {
            float: left;
            width: calc(~"100% - 96px");
            @media @media-mobile {
                width: calc(~"100% - 76px");
            }
        }
        &--caption-wrapper {
            @media @media-tablet {
                margin-bottom: 15px;
            }
            @media @media-mobile {
                margin-bottom: 30px;
            }
        }
        &--koduinternet {
            margin-top: 20px;
        }
    }
    &__internet-options {
        margin: 40px 0 40px;
        >.col-md-4 {
            padding-left: 30px;
            padding-right: 30px;
            @media @media-tablet {
                padding-left: 0;
                padding-right: 0;
            }
            @media @media-mobile {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &__options {
        margin-top: 70px;
        margin-bottom: 30px;
        @media @media-tablet {
            margin-top: 50px;
        }
        @media @media-mobile {
            margin-top: 50px;
        }
    }
    &__switch-options {
        .display(flex);
        .align-items(center);
        box-sizing: border-box;
        P { margin: 0; }
        padding: 15px 0;
        border-bottom: 1px solid @color-gray06;
        &--title {
            /*font-size: 15px;*/ font-weight: 400; font-family: @font-main; color:@color-gray01; line-height: 1.4;
        }
        &--meta {
            /*font-size: 15px;*/ font-family: @font-main; font-weight: 400; line-height: 1.4; color: @color-gray03;
        }
        &--txt-error {
            /*font-size: 15px;*/ font-family: @font-main; font-weight: 600; line-height: 1.4; color: @color-red01;
        }
        &--txt-ok {
            /*font-size: 15px;*/ font-family: @font-main; font-weight: 600; line-height: 1.4; color: @color-green01;
        }
        @media @media-tablet {
            margin-top: 10px;
        }
        @media @media-mobile {
            margin-top: 10px;
        }
        &.type-search-address__switch-options--pad-md {
            padding: 10px 0;
        }
    }
    &__actions {
        text-align: center;
        margin-bottom: 125px;
        @media @media-tablet {
            margin-bottom: 120px;
        }
        @media @media-mobile {
            margin-bottom: 60px;
        }
    }
    &__price {
        @media @media-mobile {
            margin-bottom: 30px;
        }
    }
    &__header {
        font-size: 18px;
        line-height: 1.67;
        color: @color-gray08;
        display: inline-block;
        text-align: left;
        @media @media-mobile {
            font-size: 15px;
        }
        &--icon {
            &:before {
                content: "\f058";
                color: @color03;
                font-family: 'FontAwesome';
                font-size: 35px;
                position: absolute;
                left: 0;
                width: 35px;
                height: 35px;
                line-height: 1;
                top: calc(~"(50% - 17px)");
                @media @media-mobile {
                    top: 0;
                }
            }
        }
        &--title {
            float: left;
            margin-right: 10px;
            padding-left: 45px;
            position: relative;
            @media @media-tablet {
                float: none;
            }
            @media @media-mobile {
                float: none;
            }
        }
        &--wrapper {
            text-align: center;
        }
        &--info {
            font-size: 15px;
            line-height: 1.33;
            color: @color-gray09;
            text-decoration: underline;
        }
    }
    &__check-again {
        font-size: 18px;
        line-height: 1.67;
        float: left;
        @media @media-tablet {
            float: none;
            padding-left: 45px;
            margin-top: 10px;
        }
        @media @media-mobile {
            float: none;
            padding-left: 45px;
            margin-top: 10px;
            font-size: 15px;
        }
    }
    &__col10 {
        margin-left: -10px;
        margin-right: -10px;
        .col-xs-1,
        .col-sm-1,
        .col-md-1,
        .col-lg-1,
        .col-xs-2,
        .col-sm-2,
        .col-md-2,
        .col-lg-2,
        .col-xs-3,
        .col-sm-3,
        .col-md-3,
        .col-lg-3,
        .col-xs-4,
        .col-sm-4,
        .col-md-4,
        .col-lg-4,
        .col-xs-5,
        .col-sm-5,
        .col-md-5,
        .col-lg-5,
        .col-xs-6,
        .col-sm-6,
        .col-md-6,
        .col-lg-6,
        .col-xs-7,
        .col-sm-7,
        .col-md-7,
        .col-lg-7,
        .col-xs-8,
        .col-sm-8,
        .col-md-8,
        .col-lg-8,
        .col-xs-9,
        .col-sm-9,
        .col-md-9,
        .col-lg-9,
        .col-xs-10,
        .col-sm-10,
        .col-md-10,
        .col-lg-10,
        .col-xs-11,
        .col-sm-11,
        .col-md-11,
        .col-lg-11,
        .col-xs-12,
        .col-sm-12,
        .col-md-12,
        .col-lg-12 {
            position: relative;
            min-height: 1px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    &__col30 {
        margin-left: -30px;
        margin-right: -30px;
        .col-xs-1,
        .col-sm-1,
        .col-md-1,
        .col-lg-1,
        .col-xs-2,
        .col-sm-2,
        .col-md-2,
        .col-lg-2,
        .col-xs-3,
        .col-sm-3,
        .col-md-3,
        .col-lg-3,
        .col-xs-4,
        .col-sm-4,
        .col-md-4,
        .col-lg-4,
        .col-xs-5,
        .col-sm-5,
        .col-md-5,
        .col-lg-5,
        .col-xs-6,
        .col-sm-6,
        .col-md-6,
        .col-lg-6,
        .col-xs-7,
        .col-sm-7,
        .col-md-7,
        .col-lg-7,
        .col-xs-8,
        .col-sm-8,
        .col-md-8,
        .col-lg-8,
        .col-xs-9,
        .col-sm-9,
        .col-md-9,
        .col-lg-9,
        .col-xs-10,
        .col-sm-10,
        .col-md-10,
        .col-lg-10,
        .col-xs-11,
        .col-sm-11,
        .col-md-11,
        .col-lg-11,
        .col-xs-12,
        .col-sm-12,
        .col-md-12,
        .col-lg-12 {
            position: relative;
            min-height: 1px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

input.type-search-address {
    &__input {
        width: 100%;
        @media @media-mobile {
            margin-bottom: 13px;
        }
        &:focus,
        &:active {
            outline: @color-gray04;
            border-color: @color-gray04;
        }
    }
    &__button {
        padding: 15px 20px;
        position: static !important;
        width: 280px;
        @media @media-tablet {
            width: 230px;
        }
        @media @media-mobile {
            width: 100%;
            margin-top: 0;
        }
    }
}

.options__select {
    &--rec {
        .SumoSelect {
            >.CaptionCont {
                &:before {
                    content: "\e904";
                    font-size: 28px;
                    text-align: left;
                    color: @color03;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }
    &--internet {
        .SumoSelect {
            >.CaptionCont {
                &:before {
                    content: "\e901";
                    font-size: 28px;
                    text-align: left;
                    color: @color03;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }
    &--tv {
        .SumoSelect {
            >.CaptionCont {
                &:before {
                    content: "\e905";
                    font-size: 28px;
                    text-align: left;
                    color: @color03;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }
}

.options__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.67;
    color: @color-gray01;
    margin-top: 0;
    position: relative;
    padding-left: 40px;
    @media @media-mobile {
        font-size: 15px;
        font-weight: bold;
        line-height: 1.73;
        padding-left: 35px;
    }
    >span {
        font-size: 28px;
        text-align: left;
        color: @color03;
        vertical-align: middle;
        position: absolute;
        left: 0;
        @media @media-mobile {
            font-size: 20px;
            top: 3px;
        }
    }
}

.options__single {
    margin-bottom: 40px;
}

.options__actions {
    &--btn {
        width: 349px;
        @media @media-tablet {
            width: 100%;
        }
    }
}

.results-dropdown {
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    overflow: hidden;
}

.services-list {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
    overflow: auto;
    position: relative;
    max-height: 250px;
    li {
        font-size: 15px;
        line-height: 1.33;
        color: @color-gray01;
        padding: 12px 20px;
        margin: 0;
        cursor: pointer;
        width: 110%;
        &:before {
            display: none;
        }
        &:hover,
        &:active,
        &:focus {
            background-color: @color-gray07;
        }
    }
}

.sb-container {
    position: relative;
    overflow: hidden;
    padding-right: 10px;
}

.sb-content {
    height: 100%;
    width: 120%;
    padding-right: 20%;
    overflow-y: scroll;
    box-sizing: border-box;
}

.sb-scrollbar-container {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 12px;
    background-color: @color-gray07;
    border-left: 1px solid @color-gray06;
    z-index: 1000;
}

.sb-container-noscroll .sb-scrollbar-container {
    right: -20px;
}

.sb-scrollbar {
    position: absolute;
    right: 2px;
    height: 30px;
    width: 7px;
    border-radius: 5px;
    background: @color-gray05;
}

.sb-scrollbar:hover {
    background: rgba(0, 0, 0, 0.5);
}