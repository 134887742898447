.type-overview {
	&.type-overview__inactive {
		.type-overview__image {
			IMG {
				.grayscale(100%);
			}
		}
		.type-overview__price {
			color: @color-gray01;
		}
		.type-overview__speed--icon {
			.fa {
				color: @color-gray04;
			}
		}
		.type-overview__title {
			color: @color-gray01;
		}
		&::after {
			content: "\f103";
			font-family: FontAwesome;
			margin-right: 5px;
			line-height: 1.05;
			text-align: center;
			display: block;
			color: @color-gray04;
			font-size: 30px;
		}
	}
	&__border {
		&--top {
			border-top: 1px solid @color-gray05;
		}
		&--bottom {
			border-bottom: 1px solid @color-gray05;
		}
		&--left {
			border-left: 1px solid @color-gray05;
		}
		&--right {
			border-right: 1px solid @color-gray05;
		}
	}
	.type-overview__summary {
		padding: 0 30px 30px;
	}
	/*replace with this type-overview--service-change*/
	&.type-overview__service-change {
		font-family: @font-main;
		.type-overview__title {
			font-size: 25px;
			font-weight: 700;
			line-height: 1.92;
			color: @color01;
			margin: 0;
			font-family: @font-main;
			@media @media-mobile {
				text-align: center;
				font-size: 15px;
			}
		}
		.type-overview__msg {
			padding: 0 0 10px 0;
			margin: 0 0 10px 0;
		}
		.type-overview__subtitle {
			color: @color-gray03;
			@media @media-mobile {
				text-align: center;
				font-size: 14px;
				margin-top: 0;
			}
		}
		.type-overview__text {
			margin-top: 8px;
			font-size: 15px;
			line-height: 1.47;
			color: @color-gray01;
			font-family: @font-main;
			&--mr20 {
				margin-right: 20px;
			}
			&--text-nowrap {
				white-space: nowrap;
			}
		}
		.type-overview__item {
			position: relative;
			&--bordered {
				border: 1px solid @color-gray05;
			}
		}
		.type-overview__badge {
			position: absolute;
			top: -20px;
			background-color: @color02;
			left: -1px;
			padding: 7px 16px;
			color: white;
			font-size: 15px;
			line-height: 1.07;
			border-top-left-radius: 20px;
			border-bottom-right-radius: 20px;
		}
		.type-overview__info {
			padding: 30px;
			.display(flex);
			.justify-content(flex-end);
			@media @media-mobile {
				padding: 20px;
				display: block;
			}
			@media @media-tablet {
				padding: 20px;
			}
		}
		.type-overview__image {
			order: 1;
			width: 115px;
			.flex(0 0 115px);
			margin-right: 30px;
			@media @media-mobile {
				margin: 0 auto 20px;
				min-width: initial;
				width: 85px;
				.flex(0 0 85px);
				img {
					margin: auto;
				}
			}
		}
		.type-overview__caption {
			order: 2;
			width: 100%;
			@media @media-mobile {
				text-align: center;
			}
		}
		.type-overview__action {
			height: 100%;
			padding: 0 0 0 40px;
			&.type-overview__action--pad {
				&-sm {
					padding: 0 0 0 10px;
					@media @media-mobile {
						padding: 0 0 0 40px;
					}
				}
			}
		}
		.type-overview__action--wrapper {
			padding-left: 30px;
			border-left: 1px solid @color-gray06;
			.display(flex);
			.flex-wrap(wrap);
			.flex-direction(column);
			.justify-content(center);
			height: 100%;
			@media @media-tablet {
				padding: 20px 20px 30px;
			}
			@media @media-mobile {
				border: 1px @color-gray06;
				border-style: solid none none none;
				margin: 0;
				padding: 10px 0 0 0;
			}
		}
		.type-overview__price {
			font-size: 15px;
			font-weight: 400;
			color: @color01;
			line-height: 1;
			margin: 0;
			text-align: left;
			width: 100%;
			@media @media-tablet {
				float: none;
				margin: 0;
			}
			@media @media-mobile {
				float: none;
			}
		}
		.type-overview__per {
			font-size: 15px;
			text-align: left;
			color: @color-gray03;
			vertical-align: top;
			font-weight: 400;
			line-height: 1.3;
			@media @media-mobile {
				line-height: 1;
			}
		}
		.type-overview__button {
			margin-top: auto;
			padding-top: 1em;
			@media @media-tablet {
				float: none;
				width: auto;
			}
		}
		.type-overview__parameters {
			margin-top: 30px;
			&--item {
				display: inline-block;
				margin-left: 40px;
				padding-right: 40px;
				text-align: center;
				padding-bottom: 20px;
				@media @media-desktop {
					&:not(:last-child) {
						border-right: 1px solid @color-gray06;
					}
				}
				@media @media-notdesktop {
					display: block;
					border-top: 1px solid @color-gray06;
					margin: 0;
					text-align: left;
					padding: 10px 0;

					&:first-child {
						border: none;
					}
				}
			}
			&--icon {
				text-align: center;
				.fa {
					color: @color03;
					font-size: 24px;
				}
				@media @media-notdesktop {
					display: inline-block;
					.fa {
						position: relative;
						top: 5px;
					}
				}
			}
			&--title {
				font-size: 14px;
				line-height: 1.36;
				text-align: center;
				color: @color-gray03;
			}
			&--value {
				font-size: 15px;
				line-height: 1.47;
				color: @color-gray01;
				@media @media-notdesktop {
					float: right;
					font-size: 18px;
				}

				&.type-overview__speed--value--sm {
					font-size: 16px;
					line-height: 35px;
					@media @media-mobile {
						font-size: 15px;
						line-height: 1.2;
					}
				}
			}
			&--mbit {
				font-size: 14px;
				font-weight: 400;
				vertical-align: text-top;
				line-height: 1.4;
				margin-left: 5px;
			}
			&--eu {
				font-size: 14px;
				font-weight: 400;
				line-height: 1.4;

				@media @media-mobile {
					display: none;
				}
			}
		}
		.clearfix::before, .clearfix::after {
			content: normal;
		}
	}
}