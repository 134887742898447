.additional-services-column() {
	min-height: 345px;
	.additional-services__wrapper {
		.flex-direction(column);
		.text-align(center);
	}
	.additional-services__info {
		order: 2;
		.flex(0 0 100%);
		width: 100%;
		margin: 0;
	}
	.additional-services__caption {
		order: 2;
		.flex(0 0 100%);
		width: 100%;
		margin: 0;
	}
	.additional-services__actions {
		order: 3;
		.flex(0 0 100%);
		width: 100%;
		padding: 1em 0 0 0;
		&--wrapper {
			.align-items(center);
		}
	}
	.additional-services__wrapper-image {
		order: 1;
		.flex(0 0 100%);
		width: 100%;
		margin: 0;
		IMG {
			margin: 0 auto;
		}
	}
}
.additional-services-row() {
	.additional-services__header {
		min-height: 0;
	}
	.additional-services__wrapper-image {
		order: 1;
		width: 115px;
		.flex(0 0 115px);
		margin: 0 30px 0 0;
		.align-self(flex-start);
	}
	.additional-services__info {
		order: 2;
		.flex(1 1 0px);
		margin: 0;
	}
	.additional-services__caption {
		width: 100%;
		padding: 0;
	}
	.additional-services__actions {
		width: 100%;
		padding: 1em 0 0 0;
		&--wrapper {
			.align-self(flex-end);
		}
	}
}
.additional-services-row-tablet() {
	.additional-services__wrapper-image {
		order: 1;
		width: 115px;
		.flex(0 0 115px);
		margin: 0 30px 0 0;
	}
	.additional-services__info {
		order: 2;
		.flex(1 1 0px);
		margin: 0 30px 0 0;
	}
	.additional-services__caption {
		.flex(1 1 0px);
		margin: 0 30px 0 0;
	}
	.additional-services__actions {
		order: 3;
		.flex(0 0 240px);
		width: 240px;
		&--wrapper {
			.align-self(flex-end);
		}
	}
}
.additional-services {
	font-family: @font-main;
	&__header {
		min-height: 61px;
		margin: 0 0 1em 0;
	}
	&__image {
		-webkit-transition: all .5s;
		transition: all .5s;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		will-change: transform;
		width: 100%;
		height: 110px;
		display: block;
		position: relative;
		IMG {
			max-height: 100%;
			position: absolute;
			right: 0;
			left: 0;
			margin: auto;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			transform: translateY(-50%);
			-webkit-transition: .25s;
			-moz-transition: .25s;
			-ms-transition: .25s;
			-o-transition: .25s;
			transition: .25s;
		}
	}
	&__has-zoom {
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			color: @color-gray05;
		}
	}
	&__info {
		text-align: left;
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(wrap);
	}
	&__wrapper-image {
		cursor: pointer;
		min-height: 110px;
	}
	&__wrapper {
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(wrap);
		.align-items(center);
	}
	&__actions {
		&--wrapper {
			.display(flex);
			.box-sizing(border-box);
			.flex-wrap(wrap);
			.flex-direction(column);
		}
	}
	&__item {
		&--bordered {
			border: 1px solid @color-gray05;
		}
	}
	&__content {

	}
	&__text {
		/*think take to global not double the code global color paragraph*/
		&--value {
			font-family: @font-main;
			font-size: 13px;
			font-weight: 400;
			color: @color-gray01;
			line-height: 1.46;
		}
		&--note {
			font-family: @font-main;
			font-size: 13px;
			font-weight: 400;
			color: @color-gray03;
			line-height: 1.46;
		}
		&--wrapper {

		}
	}
	&__title {
		font-family: @font-main;
		line-height: 1.67;
		font-weight: 700;
		font-size: 15px;
		margin: 0;
		color: @color-gray01;
	}
	&__pad {
		padding: 20px;
		@media @media-mobile {
			padding: 20px 0;
		}
	}
	.additional-services__separator {

	}
	@media @media-wide {
		.additional-services-column();

	}
	@media @media-notwide {
		.additional-services-column();

	}
	/*@media @media-notdesktop {
		.additional-services-row();

	}*/
	@media @media-tablet {
		.additional-services-row-tablet();

	}
	@media @media-mobile {
		.additional-services-row();
	}
	&.additional-services--has-more-than-4 {
		@media @media-wide {
			.additional-services-row();

		}
		@media @media-notwide {
			.additional-services-row();

		}
		@media @media-tablet {
			.additional-services-row-tablet();

		}
		@media @media-notdesktop {
			.additional-services-row();

		}
		@media @media-mobile {
			.additional-services-row();

		}
	}
}
.additional-services-wrapper {
	.display(flex);
	.box-sizing(border-box);
	.flex-wrap(wrap);
	&.additional-services-wrapper--bordered {
		border: 1px solid @color-gray05;
	}
	&__item {
		&--separator {
			position: relative;
			border: 2px @color-gray05;
			border-style: none none none dashed;
			&:after {
				background: @color-white;
				content: "\f067";
				font-family: "FontAwesome";
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				left: 0;
				margin: 0 0 0 -11px;
				padding: 3px;
				top: 43%;
				text-align: center;
				color: @color-gray05;
			}
			@media @media-notdesktop {
				border-style: dashed none none none;
				&:after {
					top: 0;
					margin: -13px 0 0 0;
					left: 50%;
				}
			}
		}
		&--mrg-mobile {
			margin: 0 20px;
		}
	}
}
