.title-with-action {
	.display(flex); .justify-content(space-between); .box-sizing(border-box);
	&__item {
		.align-self(flex-end);
	}
	&__action {

	}
	&__title {
		margin: 0;

	}
}