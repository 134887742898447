.type-puk {
	.display(flex);
	.justify-content(space-between);
	width: 100%;
	.flex-wrap(wrap);
	padding-top: 24px;
	.box-sizing(border-box);
	.type-puk__item {
		.flex(0 0 auto);
		width: 50%;
	}
	.type-puk__label {
		display: inline-block;
		width: 50px;
		padding-right: 9px;
	}
	.type-puk__value {
		display: inline-block;
		top: calc(~"(100% - 51px)");
	}
	.type-puk__text {
		margin: 0;
	}
	@media @media-notdesktop {
		padding-top: 12px;
		.type-puk__item {
			.flex(0 0 auto);
			width: 100%;
			padding-bottom: 4px;
		}
	}
}