@import (reference) "mixins/zindex_mixins.less";
.thesearch{
	color: @color-gray01;
	font-family: @font-main;
	font-weight: 400;
	&.w100p {
		width: 100%;
	}

	width: 100%;
	position: relative;
	&.thesearch__sm {

	}
	&.thesearch__lg {

	}
	&.thesearch--disabled {
		&::before {
			content: "";
			display: block;
			background: rgba(255,255,255, 0.4);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			cursor: default;
			position: absolute;
		}
	}
	::-webkit-input-placeholder,
	:-moz-placeholder,
	::-moz-placeholder,
	:-ms-input-placeholder { /* Chrome/Opera/Safari */
		color: @color-gray04;
		font-style: italic;
	}
	float: left; margin: 0 0 10px; position: relative;
	INPUT[type="search"] { font-size:15px; float: left; background: @color-white; border-radius: 0; width: 100%; font-family: @font-main;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 50px;
		padding: 0 55px 0 16px;
	}
	SPAN.btn {
		/*float: left;*/ border-radius: 0; height: 48px; width: 48px; padding: 0; margin: 0; color: @color-blue01; background: @color21;
		position: absolute; right: 1px; top: 1px;
		/*.btn { padding-top: 8px; padding-bottom: 8px; background: @color21; }*/
	}
	BUTTON { background: none; border:none;  width: 100%; height: 48px; color: @color01; font-size: 18px; line-height: 2.67;
		&:focus {outline:0;}
		&:before {
			line-height: 2.67;
			content: "\f002";
			color: @color-blue01;
			border: none;
			font-family: FontAwesome;
			padding: 0;
			position: relative;
			text-align: center;
		}
	}
	&.loading {
		SPAN.btn {
			width: 30px;  background: @color-white url(loader.gif) no-repeat 50% 50%;
			BUTTON { display: none; }
		}
	}
	UL {
		display: block;
		position: absolute;
		background: @color-white;
		left: 0;
		right: 0;
		top: 51px;
		width: auto;
		.zindex(@searchZindex, 0);
		margin: 0;
		max-height: 280px;
		overflow: auto;
		list-style-type: none;
		padding: 0;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
		-moz-box-shadow:    0 1px 3px 0 rgba(0, 0, 0, 0.4);
		-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
		& > LI {
			margin: 0;
			padding: 0;
			&:first-child {
				margin-top: 0;
			}
			&:before {
				display: none;
			}
			A {
				font-size: 15px;
				display: block;
				overflow: hidden;
				padding: 5px 53px 5px 16px;
				cursor: pointer;
				color: @color-gray01;
				text-decoration: none;
				&:hover {
					background: @color21;
				}
			}
		}
	}
	/*@media @media-notwide {
		INPUT[type="text"] {
			width: 165px;
		}
	}
	@media @media-tablet {
		float: left;
		INPUT[type="text"] {
			width: 165px;
		}
	}*/
	/*&.error {
		INPUT {
			color: @color-negative;
			border: 1px solid lighten(@color-negative,15%);
			height: 34px;
			padding-right: 40px;
		}
	}
	/*&.error {
		INPUT {
			color: @color-negative;
			border: 1px solid lighten(@color-negative,15%);
			height: 34px;
			padding-right: 40px;
		}
	}
	display: inline-block;
	margin: 0;
	padding: 0;
	position: relative;
	height: 40px;
	.box-sizing(border-box);
	INPUT {
		width: 100%;
		margin: 0;
		height: 40px;
		padding: 0 60px 0 16px;
		text-indent: 0;
		color: @color-gray01;
	}
	&:after {
		position: absolute;
		padding-right: 2px;
		content: "";
		top:0;
		bottom: 0;
		margin: auto;
		line-height: 48px;
		font-size: 15px;
		text-align: center;
		background: @color21;
		text-indent: -9999px;
		cursor: pointer;
		width: 48px;
		!*height: 38px;*!
		right: 1px;
		border-left: 1px solid #ebebeb;
	}
	.svg-ico {
		position: absolute;
		width: 16px;
		height: 20px;
		display: block;
		z-index: 9;
		cursor: pointer;
		margin: 0;
		right: 20px;
		top: 10px;
		color: @color01;
		font-size: 16px;
		text-align: center;
		&.w100p {
			width: 100%;
		}
	}*/
}