/* Not supported resolution */
#box00 { overflow: hidden; }
@media @media-tiny {
	#box00 { display: none; }
	#notsupported { display: block; padding: 0 10px 10px 10px; }
	#notsupported P { margin: 10px 0 0 0; }
	#notsupported P.logo IMG { width: 100%; }
	.tb_box01 { display: none; }
	BODY, HTML { background: @color-white; }
	.tb_overlay,
	.tb_loader,
	.tb_frame { display: none; }
}

/* Basic styling */

#notsupported { display: none; }
