.overlay {
    border-radius: 0;
}

.modal-block {
    z-index: 100;
    width: 50%;
    overflow: hidden;
    margin: 5% auto;
    background: @color-white;
    border-radius: 0;
    border: 1px solid @color-gray-medium-lightest;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    @media @media-tablet {
        width: 100%;
        margin: 0 auto;
        border: none;
    }
    @media @media-notdesktop {
        width: 100%;
        margin: 0 auto;
        border: none;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &__header {
        margin-bottom: 25px;

        @media @media-notdesktop {
            text-align: center;
            background: rgba(255,255,255,0.9);
            padding: 10px 20px 0;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
        }
    }
    &__title {
        font-size: 24px;
        line-height: 1.42;
        text-align: left;
        color: @color01;
        font-weight: 400;
        margin-top: 0;
    }
    &__sub-title {
        line-height: 1.47;
        color: @color-gray08;
        font-size: 15px;
        font-weight: 700;
        margin-top: 40px;
        margin-bottom: 15px;
    }
    .pad {
        padding: 35px 35px 50px 35px;
        @media @media-tablet {
            padding: 35px 20px 40px 20px;
        }
        @media @media-mobile {
            padding: 35px 20px 40px 20px;
        }
    }
    p {
        line-height: 1.47;
        color: @color-gray08;
        font-size: 15px;
    }
    .close {
        background: rgba(255,255,255,0.9);
        display: block;
        width: 26px;
        height: 26px;
        border-radius: 13px;
        position: fixed;
        right: 12%;
        //top: 7%;
        text-align: center;
        line-height: 26px;
        opacity: 1;
        transition: opacity 0.3s ease;
        cursor: pointer;
        z-index: 100;
        margin-top: -20px;
        @media @media-notdesktop {
            background: transparent;
            width: 35px;
            height: 18px;
            position: fixed;
            right: 28px;
            top: 15px;
            margin-top: 0;
        }
        @media @media-mobile {
            right: 10px;
        }

        &:before {
            font-family: FontAwesome;
            content: "\f00d";
            color: @color-gray05;
            font-size: 24px;
            @media @media-notdesktop {
                font-size: 25px;
                padding: 10px;
            }
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            opacity: 0.5;
            border-bottom: none;
            &:before {
                text-decoration: none;
                border-bottom: none;
            }
        }
    }
    &__list {
        list-style: none;
        -moz-column-count: 4;
        -moz-column-gap: 0;
        -webkit-column-count: 4;
        -webkit-column-gap: 0;
        column-count: 4;
        column-gap: 30px;
        li {
            font-size: 15px;
            line-height: 1.13;
            color: black;
            padding-left: 25px;
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
            a {
                font-size: 15px;
                line-height: 1.13;
                color: black;
                opacity: 1;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    opacity: 0.6;
                }
                &.inactive {
                    color: @color-gray04;
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                        opacity: 0.6;
                    }
                }
            }
            @media @media-mobile {
                font-size: 15px;
                line-height: 1.53;
            }
            &:before {
                content: "\f00c";
                font-family: FontAwesome;
                font-size: 13px;
                color: @color03;
                font-weight: bold;
                top: calc(~"(50% - 5px)");
                line-height: 1;
                height: 10px;
            }
            &.inactive {
                font-size: 15px;
                line-height: 1.13;
                color: @color-gray04;
                a {
                    cursor: default;
                    color: @color-gray04;
                    transition: none;
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                        opacity: 1;
                    }
                }
                &:before {
                    display: none;
                }
            }
        }
    }

    &__canals {
        @media @media-notdesktop {
            padding: 80px 15px 0;
        }

        .accordion.expandable {
            .blockheading {
                padding-left: 25px;
                position: relative;
            }
            &.active {
                .blockheading {
                    &:before {
                        content: "\f00c";
                        font-family: FontAwesome;
                        font-size: 13px;
                        color: #00baf2;
                        font-weight: bold;
                        top: calc((50% - 5px));
                        line-height: 1;
                        height: 10px;
                        position: absolute;
                        top: 10px;
                        left: 0;
                    }
                }
            }
            .img-canal {
                max-width: 200px;
            }
        }
    }
}

#tv-modal, #internet-modal {
    width: 80%;
    max-height: 85vh;
    overflow-y: scroll;
    @media @media-notdesktop {
        width: 100%;
        max-height: 100vh;
        height: 100%;
    }
    .modal-block__title {
        margin-bottom: 20px;
    }
}

#order-modal {
    position: absolute;
    pointer-events: auto;
    box-shadow: none;
    background: transparent;
    display: none;

    &.visible {
        display: block;
    }
}

.showing-modal {
    @media @media-notdesktop {
        overflow: hidden;
    }
}

.img-canal {
    max-width: 100px;
}