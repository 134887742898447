.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}
/* 16x9 Aspect Ratio */
.intrinsic-container-16x9 {
	padding-bottom: 56.25%;
}
/* 4x3 Aspect Ratio */
.intrinsic-container-4x3 {
	padding-bottom: 75%;
}
.video-container {
	IFRAME { position: absolute; top: 0; left: 0; width: 100%; height: 100%;  }
}
.block25 {
	padding-left: 20px;
	padding-bottom: 60px;
	H3.type02 { font-weight: 300; margin-left: -20px; margin-top: 2em; }
}
P.line-through {
	text-align: center;
	position: relative;
	margin: 30px 0;
	&:before {
		content: "";
		position: absolute;
		border: 1px solid @color-gray-lightest;
		width: 100%;
		left: 0;
		top: 10px;
		z-index: -1
	}
	SPAN {
		color: @color-gray-medium;
		display: inline-block;
		background: @color-white;
		z-index: 1;
		padding: 0 50px;
	}
}
.equalheight .col.w40p > * {
	flex: 0 0 100%;
}
.equalheight .col.w60p > * {
	flex: 0 0 100%;
}
.separated-right-bottom {
	border-right: 1px solid @color-gray-lightest;
	padding: 0 25px 0 0;
	@media @media-mobile {
		border-bottom: 1px solid @color-gray-lightest;
		border-right: 0;
		padding: 0 0 30px 0;
	}
}
.separated-left-top {
	border-left: 1px solid @color-gray-lightest;
	@media @media-mobile {
		border-top: 1px solid @color-gray-lightest;
		border-left: 0;
	}
}

.search-w100p {
	display: flex;
	& > INPUT.default {
		flex: 0 1 100%;
		font-style: italic;
	}
	UL.actions01 {
		flex: 1 0 auto;
		margin: -3px 0 0 5px !important;
	}
}
.contact {
	text-align: center;
	padding: 30px 0;
	display: flex;
	justify-content: center;
	flex-direction: row;
	SPAN {
		display: block;
		font-size: 15px;
		position: relative;
		font-weight: bold;
		padding-left: 35px;
		&:before {
			position: absolute;
			top: 8px;
			left: 0;
			margin-top: -14px;
			font-size: 30px;
		}
	}
	A {
		display: block;
		font-size: 15px;
		position: relative;
		font-weight: bold;
		padding-left: 60px;
		text-align: left;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
		&:before {
			position: absolute;
			top: 8px;
			left: 20px;
			margin-top: -14px;
			font-size: 30px;
		}

	}
	@media @media-mobile {
		display: block;
		SELECT,
		INPUT {
			+ DIV { padding-top: .5em; }
		}
		.w01,
		.w02,
		.wauto { float: none !important; width: 100% !important; margin: 10px 0 0 0 !important; }

	}
}
.contact01 {
	text-align: left;
	padding: 20px 0;
	position: relative;
	&:before {
		position: absolute;
		left: 0;
		font-size: 30px;
	}
	SPAN {
		display: block;
		position: relative;
	}
	.text {
		margin-left: 35px;
		margin-top: 0;
		P { margin: 0; }
		SPAN { display: inline-block; color: @color-gray-medium;  }
	}
}
A.btn {
	&.back {
		color: @color01; padding: 7px 0 2px 0!important; /*border: 1px solid transparent;*/ background: none; font-weight: normal; text-decoration: none;
		&:before { float: left; content: @ico-arrleft; font-family: "icons"; }
		&:hover {
			text-decoration: none!important;
			color: darken(@color01,10%);
			&:before { text-decoration: none !important; }
		}
	}
}

H1.expanding {
	display: block;
	.align-content(center);
	.display(flex);
	.align-content(center);

	A.toggler {
		text-decoration: none; cursor: pointer; display: block;
		position: relative;
		min-height: 80px;
		.display(flex);
		.align-items(center);
		&:hover { text-decoration: none; }
		&.open SPAN.txt:after { content: @ico-arrup; }
		SPAN.img {
			max-width: 60px;
			position: absolute;
			top: 0;
			left: 0
		}
		SPAN.txt {
			font-family: @font-heading;
			padding: 0 40px 0 85px;
			display: inline-block;
			position: relative;
			font-size: 60px;
			color: @color-black;
			&:after {
				display: inline-block;
				content: @ico-arrdown;
				font-family: "icons";
				border-radius: 100%;
				background: @color12;
				width: 34px;
				height: 34px;
				text-align: center;
				line-height: 34px;
				font-size: 16px;
				position: absolute;
				top: 26px;
				right: 0;

			}
			@media @media-mobile {
				font-size: 30px;
				&:after {
					top: 0;
				}

			}
		}

	}
}

/*left-side navigation*/
.news04 {
	padding-right: 20px;
	LI {
		border-top: 1px solid @color-gray-medium-lightest;
		.a {
			padding-bottom: 20px;
		}
		&:before { display: none; }
		&:first-child {
			border-top: none;
		}
		P.meta {
			color: @color-gray-medium;
		}
		P.heading01 {
			padding-bottom: 1.5em; position: relative;
			A.toggler {
				text-decoration: none; cursor: pointer; padding-right: 30px; display: inline-block;
				&:hover { text-decoration: none; }
				&:after {
					content: @ico-arrdown; font-family: "icons"; float: right; width: 14px; height: 10px; right: 15px; top: 0; position: absolute;
				}
				&.open:after { content: @ico-arrup; }
			}
		}

	}
}
