#menu-login {
	display: none;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	clear: both;
	background-color: @color-white;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
	position: absolute;
	right: 0;
	width: 280px;
	top: 0;
	z-index: 102;
	&.nav__dispatcher {
		box-shadow: none;
		border: 1px solid @color-orange02;
		&:after {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 30%;
			margin-left: -14px;
			border-width: 14px;
			border-style: solid;
			border-color: transparent transparent @color-gray02 transparent;
		}
		LI {
			A {
				color: @color-gray03;
				&:focus,
				&:hover {
					color: @color-blue05;
					background: none;
				}
			}
		}
	}
	@media @media-notdesktop {
		display: block;
		box-shadow: none;
		top: 59px;
		height: 100vh;
		width: 320px;
		transform: translate3d(100%, 0, 0);
		transition: transform .3s;
	}

	@media @media-mobile {
		width: 100%;
	}

	li {
		@media @media-notdesktop {
			border-top: 1px solid @color-gray06;
		}

		a {
			color: @color-gray01;
			padding: 15px 20px;
			margin: 0;
			display: block;
			font-size: 15px;
			font-weight: 400;
			font-family: @font-main;
			.note {
				color: @color-gray03;
				font-family: @font-main;
				font-size: 13px;
				font-weight: 400;
				display: block;
			}
			&:hover {
				text-decoration: none;
				background: @color-gray07;
			}

			@media @media-notdesktop {
				color: @color01;
			}

			&.active {
				border-left: 3px solid @color03;
				font-weight: 700;
				padding: 15px 20px 15px 17px;
				color: @color-gray01;
			}
		}

		&:before {
			display: none;
		}
	}

	&.open {
		display: block;

		@media @media-notdesktop {
			transform: translate3d(0, 0, 0);
			transition: transform .5s;
		}
	}
}