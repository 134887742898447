@font-face {
	font-family: "icons";
	/*src: url('@{ico-font-path}icons.eot');
	src: url('@{ico-font-path}icons.eot?#iefix') format('eot'),
	url('@{ico-font-path}icons.woff') format('woff'),
	url('@{ico-font-path}icons.ttf') format('truetype'),
	url('@{ico-font-path}icons.svg#icons') format('svg');*/
	src: data-uri('application/x-font-woff;charset=utf-8;base64', '@{ico-font-path}icons.woff'),
	data-uri('application/x-font-ttf;charset=utf-8;base64', '@{ico-font-path}icons.ttf');
	font-weight: normal;
	font-style: normal;
}

[class^="ico"]:before,
[class*=" ico"]:before,
[class^="ico"]:after,
[class*=" ico"]:after {
	display: inline-block;
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.ico-4g:before { content: "\E001" }
@ico-4g: "\E001";

.ico-4g2:before { content: "\E002" }
@ico-4g2: "\E002";

.ico-alert:before { content: "\E003" }
@ico-alert: "\E003";

.ico-android:before { content: "\E004" }
@ico-android: "\E004";

.ico-apple-ios:before { content: "\E005" }
@ico-apple-ios: "\E005";

.ico-arrdown:before { content: "\E006" }
@ico-arrdown: "\E006";

.ico-arrleft:before { content: "\E007" }
@ico-arrleft: "\E007";

.ico-arrleft02:before { content: "\E008" }
@ico-arrleft02: "\E008";

.ico-arrright:before { content: "\E009" }
@ico-arrright: "\E009";

.ico-arrright02:before { content: "\E00A" }
@ico-arrright02: "\E00A";

.ico-arrup:before { content: "\E00B" }
@ico-arrup: "\E00B";

.ico-arved:before { content: "\E00C" }
@ico-arved: "\E00C";

.ico-battery:before { content: "\E00D" }
@ico-battery: "\E00D";

.ico-baubel:before { content: "\E00E" }
@ico-baubel: "\E00E";

.ico-blank:before { content: "\E00F" }
@ico-blank: "\E00F";

.ico-box:before { content: "\E010" }
@ico-box: "\E010";

.ico-bullet01:before { content: "\E011" }
@ico-bullet01: "\E011";

.ico-business:before { content: "\E012" }
@ico-business: "\E012";

.ico-buyout:before { content: "\E013" }
@ico-buyout: "\E013";

.ico-calendar-clock:before { content: "\E014" }
@ico-calendar-clock: "\E014";

.ico-calendar:before { content: "\E015" }
@ico-calendar: "\E015";

.ico-calendar02:before { content: "\E016" }
@ico-calendar02: "\E016";

.ico-call:before { content: "\E017" }
@ico-call: "\E017";

.ico-calls:before { content: "\E018" }
@ico-calls: "\E018";

.ico-camera:before { content: "\E019" }
@ico-camera: "\E019";

.ico-canceled:before { content: "\E01A" }
@ico-canceled: "\E01A";

.ico-cancellation:before { content: "\E01B" }
@ico-cancellation: "\E01B";

.ico-card:before { content: "\E01C" }
@ico-card: "\E01C";

.ico-card2:before { content: "\E01D" }
@ico-card2: "\E01D";

.ico-cart:before { content: "\E01E" }
@ico-cart: "\E01E";

.ico-chat:before { content: "\E01F" }
@ico-chat: "\E01F";

.ico-check:before { content: "\E020" }
@ico-check: "\E020";

.ico-check03:before { content: "\E021" }
@ico-check03: "\E021";

.ico-coins:before { content: "\E022" }
@ico-coins: "\E022";

.ico-columns:before { content: "\E023" }
@ico-columns: "\E023";

.ico-combo:before { content: "\E024" }
@ico-combo: "\E024";

.ico-computer:before { content: "\E025" }
@ico-computer: "\E025";

.ico-connect:before { content: "\E026" }
@ico-connect: "\E026";

.ico-contract-euro:before { content: "\E027" }
@ico-contract-euro: "\E027";

.ico-contract-pen:before { content: "\E028" }
@ico-contract-pen: "\E028";

.ico-contract:before { content: "\E029" }
@ico-contract: "\E029";

.ico-conversation:before { content: "\E02A" }
@ico-conversation: "\E02A";

.ico-cross:before { content: "\E02B" }
@ico-cross: "\E02B";

.ico-delivered:before { content: "\E02C" }
@ico-delivered: "\E02C";

.ico-delivery:before { content: "\E02D" }
@ico-delivery: "\E02D";

.ico-device-accessory:before { content: "\E02E" }
@ico-device-accessory: "\E02E";

.ico-device-add:before { content: "\E02F" }
@ico-device-add: "\E02F";

.ico-device-elisa:before { content: "\E030" }
@ico-device-elisa: "\E030";

.ico-device-phone:before { content: "\E031" }
@ico-device-phone: "\E031";

.ico-device-tablet:before { content: "\E032" }
@ico-device-tablet: "\E032";

.ico-device-zen:before { content: "\E033" }
@ico-device-zen: "\E033";

.ico-disabled:before { content: "\E034" }
@ico-disabled: "\E034";

.ico-display:before { content: "\E035" }
@ico-display: "\E035";

.ico-display2:before { content: "\E036" }
@ico-display2: "\E036";

.ico-document:before { content: "\E037" }
@ico-document: "\E037";

.ico-down:before { content: "\E038" }
@ico-down: "\E038";

.ico-download:before { content: "\E039" }
@ico-download: "\E039";

.ico-download02:before { content: "\E03A" }
@ico-download02: "\E03A";

.ico-email:before { content: "\E03B" }
@ico-email: "\E03B";

.ico-envelope:before { content: "\E03C" }
@ico-envelope: "\E03C";

.ico-euro:before { content: "\E03D" }
@ico-euro: "\E03D";

.ico-exclamation:before { content: "\E03E" }
@ico-exclamation: "\E03E";

.ico-extraparams:before { content: "\E03F" }
@ico-extraparams: "\E03F";

.ico-extraservice:before { content: "\E040" }
@ico-extraservice: "\E040";

.ico-facebook:before { content: "\E041" }
@ico-facebook: "\E041";

.ico-feather:before { content: "\E042" }
@ico-feather: "\E042";

.ico-gauge:before { content: "\E043" }
@ico-gauge: "\E043";

.ico-general:before { content: "\E044" }
@ico-general: "\E044";

.ico-gift:before { content: "\E045" }
@ico-gift: "\E045";

.ico-gift01:before { content: "\E046" }
@ico-gift01: "\E046";

.ico-gift02:before { content: "\E047" }
@ico-gift02: "\E047";

.ico-googleplus:before { content: "\E048" }
@ico-googleplus: "\E048";

.ico-governmental:before { content: "\E049" }
@ico-governmental: "\E049";

.ico-graph:before { content: "\E04A" }
@ico-graph: "\E04A";

.ico-grid:before { content: "\E04B" }
@ico-grid: "\E04B";

.ico-hard-drive:before { content: "\E04C" }
@ico-hard-drive: "\E04C";

.ico-home:before { content: "\E04D" }
@ico-home: "\E04D";

.ico-house:before { content: "\E04E" }
@ico-house: "\E04E";

.ico-id:before { content: "\E04F" }
@ico-id: "\E04F";

.ico-income:before { content: "\E050" }
@ico-income: "\E050";

.ico-info-clear:before { content: "\E051" }
@ico-info-clear: "\E051";

.ico-info-full:before { content: "\E052" }
@ico-info-full: "\E052";

.ico-info:before { content: "\E053" }
@ico-info: "\E053";

.ico-inprocess:before { content: "\E054" }
@ico-inprocess: "\E054";

.ico-instagram:before { content: "\E055" }
@ico-instagram: "\E055";

.ico-ios:before { content: "\E056" }
@ico-ios: "\E056";

.ico-liabilities:before { content: "\E057" }
@ico-liabilities: "\E057";

.ico-limited:before { content: "\E058" }
@ico-limited: "\E058";

.ico-linkedin:before { content: "\E059" }
@ico-linkedin: "\E059";

.ico-list:before { content: "\E05A" }
@ico-list: "\E05A";

.ico-mail:before { content: "\E05B" }
@ico-mail: "\E05B";

.ico-memory:before { content: "\E05C" }
@ico-memory: "\E05C";

.ico-menu:before { content: "\E05D" }
@ico-menu: "\E05D";

.ico-mint:before { content: "\E05E" }
@ico-mint: "\E05E";

.ico-minus:before { content: "\E05F" }
@ico-minus: "\E05F";

.ico-money:before { content: "\E060" }
@ico-money: "\E060";

.ico-network:before { content: "\E061" }
@ico-network: "\E061";

.ico-newspaper:before { content: "\E062" }
@ico-newspaper: "\E062";

.ico-nodata-needed:before { content: "\E063" }
@ico-nodata-needed: "\E063";

.ico-oigused:before { content: "\E064" }
@ico-oigused: "\E064";

.ico-ok:before { content: "\E065" }
@ico-ok: "\E065";

.ico-owl:before { content: "\E066" }
@ico-owl: "\E066";

.ico-owl2:before { content: "\E067" }
@ico-owl2: "\E067";

.ico-params:before { content: "\E068" }
@ico-params: "\E068";

.ico-pencil:before { content: "\E069" }
@ico-pencil: "\E069";

.ico-percent:before { content: "\E06A" }
@ico-percent: "\E06A";

.ico-phone:before { content: "\E06B" }
@ico-phone: "\E06B";

.ico-pinterest:before { content: "\E06C" }
@ico-pinterest: "\E06C";

.ico-play:before { content: "\E06D" }
@ico-play: "\E06D";

.ico-play01:before { content: "\E06E" }
@ico-play01: "\E06E";

.ico-plus:before { content: "\E06F" }
@ico-plus: "\E06F";

.ico-postkast:before { content: "\E070" }
@ico-postkast: "\E070";

.ico-pricetag:before { content: "\E071" }
@ico-pricetag: "\E071";

.ico-print:before { content: "\E072" }
@ico-print: "\E072";

.ico-private:before { content: "\E073" }
@ico-private: "\E073";

.ico-returned:before { content: "\E074" }
@ico-returned: "\E074";

.ico-sailfish:before { content: "\E075" }
@ico-sailfish: "\E075";

.ico-seaded:before { content: "\E076" }
@ico-seaded: "\E076";

.ico-search:before { content: "\E077" }
@ico-search: "\E077";

.ico-service-add:before { content: "\E078" }
@ico-service-add: "\E078";

.ico-service-new:before { content: "\E079" }
@ico-service-new: "\E079";

.ico-service-replace:before { content: "\E07A" }
@ico-service-replace: "\E07A";

.ico-shuffle:before { content: "\E07B" }
@ico-shuffle: "\E07B";

.ico-sign:before { content: "\E07C" }
@ico-sign: "\E07C";

.ico-sitemap:before { content: "\E07D" }
@ico-sitemap: "\E07D";

.ico-star01:before { content: "\E07E" }
@ico-star01: "\E07E";

.ico-stop-limit:before { content: "\E07F" }
@ico-stop-limit: "\E07F";

.ico-stop-phone:before { content: "\E080" }
@ico-stop-phone: "\E080";

.ico-stop-stop:before { content: "\E081" }
@ico-stop-stop: "\E081";

.ico-stop-stopped:before { content: "\E082" }
@ico-stop-stopped: "\E082";

.ico-symbian:before { content: "\E083" }
@ico-symbian: "\E083";

.ico-tarbimine:before { content: "\E084" }
@ico-tarbimine: "\E084";

.ico-teenused:before { content: "\E085" }
@ico-teenused: "\E085";

.ico-teller:before { content: "\E086" }
@ico-teller: "\E086";

.ico-tellimused:before { content: "\E087" }
@ico-tellimused: "\E087";

.ico-thumbsup:before { content: "\E088" }
@ico-thumbsup: "\E088";

.ico-time:before { content: "\E089" }
@ico-time: "\E089";

.ico-transport-filled:before { content: "\E08A" }
@ico-transport-filled: "\E08A";

.ico-transport:before { content: "\E08B" }
@ico-transport: "\E08B";

.ico-twitter:before { content: "\E08C" }
@ico-twitter: "\E08C";

.ico-unlimited:before { content: "\E08D" }
@ico-unlimited: "\E08D";

.ico-up:before { content: "\E08E" }
@ico-up: "\E08E";

.ico-upload:before { content: "\E08F" }
@ico-upload: "\E08F";

.ico-user:before { content: "\E090" }
@ico-user: "\E090";

.ico-view:before { content: "\E091" }
@ico-view: "\E091";

.ico-windows:before { content: "\E092" }
@ico-windows: "\E092";

.ico-youtube:before { content: "\E093" }
@ico-youtube: "\E093";