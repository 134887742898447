footer#main {
	margin: 60px 0 0 0; padding-bottom: 0; border-top: 1px solid @color-gray-light;
	UL {
		list-style: none; margin: 0; padding: 30px 0;
		LI {
			float: left; width: 24%; margin: 0 0 0 1%; padding: 0;
			&:before { display: none; }
			&:first-child { margin: 0; }
			A {
				font-size: 19px; color: @color-black; font-weight: bold;
				font-family: @font-heading;
				.ru & { font-family: @font-heading-ru; }
				&:hover { text-decoration: none; color: @color01; }
			}
		}
		UL {
			margin: 20px 0 0 0; padding: 0;
			LI {
				font-size: 15px; float: none; clear: both; width: auto; margin: 5px 0 0 0; color: @color-gray-medium;
				A {
					font-size: 15px; color: @color01; font-weight: normal; font-family: @font-main;
					&:hover { border-bottom: 1px solid @color02; }
				}
			}
		}
	}
	& > DIV.col00 > UL > LI {
		& > SPAN { font-size: 19px; color: @color-black; font-weight: bold; font-family: @font-heading;
			.ru & { font-family: @font-heading-ru; }
		}
		&:nth-of-type(n+5) { margin-top: 2em; }
	}
	P {
		margin: .5em 0 0 0; overflow: hidden;
		&.heading01 { font-size: 19px; font-weight: normal;
			font-family: @font-heading;
			.ru & { font-family: @font-heading-ru; }
		}
		A.ico {
			margin: 0 30px 0 0; float: left; height: 30px;
			&.f-right { float: right; margin: 0 0 0 30px; }
			&:last-child { margin: 0; }
			&:before { font-size: 24px; }
		}
	}
	DIV.b {
		background: lighten(@color-gray-lightest ,10%);
		UL {
			padding: 35px 0;
			LI {
				font-size: 18px;
				A { font-size: 18px; }
				STRONG { font-size: 18px;
					font-family: @font-heading;
					.ru & { font-family: @font-heading-ru; }
				}
				UL {
					padding: 0; font-size: 13px; margin: 5px 0 0 0;
					LI {
						font-size: 13px; margin: 0; clear: both;
						A { font-size: 13px; color: @color-gray-medium; }
					}
				}
				&.last { float: right; margin-top: 1.4em; }
			}
		}
		.ico {
			&-envelope:before { font-size: 20px; }
			&:before { color: @color-black; font-size: 17px; position: relative; top: 3px;  }
		}
	}
	@media @media-tablet {
		DIV.b UL LI {
			width: 24%;
			LI { width: auto; }
		}
	}
	@media @media-mobile {
		margin: 30px 0 0 0; padding-bottom: 30px;
		UL {
			padding: 20px 0;
			LI {
				width: 100%; margin: 20px 0 0;
				UL {
					margin: 5px 0 0 0;
					LI {
						float: none; width: auto; margin: 5px 0 0 0;
						A { font-size: 17px; }
					}
				}
				A { font-size: 20px; }
			}
		}
	}
}

