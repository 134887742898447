.type-package-compare {
	font-family: @font-main;
	border: 1px @color-gray06;
	border-style: none solid;
	.box-sizing(border-box);
	.display(flex);
	.flex-wrap(wrap);
	height: 100%;
	&.__header {
		&--no-img{
			order: 1;
			width: 120px;
			.flex(0 0 120px);
			margin-right: 30px;
			@media @media-mobile {
				margin: 0 auto 20px;
				min-width: initial;
				width: 100px;
				.flex(0 0 100px);
			}
		}
	}
	&__title {
		font-family: @font-main;
		font-size: 15px;
		margin: 0 0 5px 0;
		font-weight: 700;
		line-height: 1.33;
		color: @color01;
		&--itb {
			color: @color-gray01;
		}
		@media @media-mobile {
			text-align: left;
			font-size: 15px;
			line-height: 1.33;
			padding: 0;
		}
	}
	&__values {
		.display(flex);
		.justify-content(space-between);
		.box-sizing(border-box);
		width: 100%;
		.flex-direction(row);
		.flex-wrap(wrap);
	}
	&__subtitle {
		color: @color-gray03;
		margin-top: 0;
		@media @media-mobile {
			text-align: center;
			font-size: 14px;
			margin-top: 0;
		}
	}
	&__text {
		margin-top: 15px;
		font-size: 15px;
		line-height: 1.47;
		color: @color-gray01;
		&--preferential {
			color: @color-orange01;
		}
	}
	&__item {
		margin: 0;
		position: relative;
		@media @media-notdesktop {
			padding: 10px 0;
			margin-bottom: 0;

			border-right: none;
			width: 100%;
			.flex(1 0 100%);
		}
		@media @media-mobile {
			.flex(1 0 100%);
			margin-right: -15px;
			margin-left: -15px;
		}
	}
	&__badge {
		position: absolute;
		top: -20px;
		background-color: @color02;
		left: -1px;
		padding: 7px 16px;
		color: white;
		font-size: 15px;
		line-height: 1.07;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 20px;
	}
	&__info {
		padding: 15px;
		.display(flex);
		&--pad20 {
			padding: 20px;
		}
		@media @media-tablet {
			padding: 15px;
		}
		@media @media-mobile {
			padding: 30px;
			display: block;
		}

	}
	&__image {
		order: 1;
		width: 69px;
		.flex(0 0 69px);
		.align-self(flex-start);
		margin-right: 15px;
		&--mrg {
			@media @media-desktop {
				margin-right: 38px;
				margin-left: 23px;
			}
			@media @media-tablet {
				margin-right: 38px;
				margin-left: 18px;
			}
		}
		@media @media-mobile {
			display: none;
		}
	}
	&__caption {
		order: 2;
		width: 100%;
		@media @media-mobile {
			text-align: center;
		}
	}
	&__action {
		height: 100%;
		padding: 15px 30px;
		@media @media-tablet {
			padding: 20px 20px 30px 30px;
		}
		@media @media-mobile {
			display: none;
		}
		&--wrapper {
			height: 100%;
			.display(flex);
			.flex-direction(column);
			padding-left: 30px;
			border-left: 1px solid @color-gray06;
			@media @media-tablet {
				padding: 0 0 0 20px;
				.display(flex);
				border-top: none;
			}
			@media @media-mobile {
				display: none;
			}
		}
	}
	&__price {
		font-size: 25px;
		margin: 0;
		font-weight: 700;
		color: @color01;
		line-height: 1;
		text-align: left;
		&--itb {
			color: @color-gray01;
		}
		&--preferential {
			color: @color-orange01;
		}
		@media @media-tablet {
			font-size: 18px;
			float: none;
			margin-top: 8px;
		}
		@media @media-mobile {
			font-size: 18px;
			float: none;
			text-align: left;
			padding-bottom: 15px;
			margin-top: 8px;
		}
	}
	&__per {
		font-size: 15px;
		text-align: left;
		color: @color-gray03;
		vertical-align: top;
		font-weight: 400;
		line-height: 1.3;
		@media @media-mobile {
			line-height: 1;
		}
	}
	&__button {
		margin-top: auto;
		padding-top: 1em;
		@media @media-tablet {
			float: none;
			width: auto;
		}
	}
	&__seperator {
		border-right: 1px solid @color-gray06;
		@media @media-notdesktop {
			border-right: none;
			border-bottom: 1px solid @color-gray05;
		}
	}
	&__number {
		color: @color01;
		font-size: 30px;
		font-weight: 700;
		margin: 10px 0;
	}
	.type-package-compare__arrow {
		&--down {
			&::after {
				content: "\f103";
				font-family: FontAwesome;
				margin-right: 5px;
				line-height: 1.05;
				text-align: center;
				display: block;
				color: @color-gray04;
				font-size: 30px;
			}
		}
	}
	.type-package-compare__inactive {
		.type-package-compare__image {
			IMG {
				.grayscale(100%);
			}
		}
		.type-package-compare__price {
			color: @color-gray01;
		}
		/*publicparam has own inactive incase diff*/
		.publicparam--icon {
			I,.fa {
				color: @color-gray04;
			}
		}
		.type-package-compare__title {
			color: @color-gray01;
		}
	}
	.type-package-compare__nomargin {
		&--top {
			margin-top: 0;
		}
		&--bottom {
			margin-bottom: 0;
		}
	}
	.type-package-compare__nopadding {
		&--bottom {
			padding-bottom: 0;
		}
	}
	.clearfix::before, .clearfix::after {
		content: normal;
	}
	&.type-package-compare--multi {
		.type-package-compare__action {
			padding: 15px 5px 15px 0;
			/*@media @media-tablet {
				padding: 15px 5px 15px 0;
			}*/
			&--wrapper {
				padding-left: 0;
				border-left: 0;
				@media @media-tablet {
					padding-left: 30px;
				}
			}
		}
	}
	@media @media-mobile {
		border: none;
	}
}


