.type-slider01 {
	@media @media-notdesktop {
		padding-top: 30px;
	}

	SECTION.type04& {
		padding: 60px 0;

		@media @media-mobile {
			padding: 40px 0;
		}
	}

	SECTION.type03& {
		background-color: @color-gray07;
		padding: 60px 0;

		@media @media-mobile {
			padding: 40px 0;
		}
	}
	SECTION.type03& H2 {
		color: @color01;
		font-size: 30px;
		font-weight: 300;
		line-height: 48px;
		padding: 0 0 15px;

		@media @media-mobile {
			font-size: 24px;
			line-height: 34px;
		}
	}
	h2 {
		display: table-cell;
		padding: 0 0 15px;
		text-align: left;
		width: 100%;
	}
	a.category-link {
		display: table-cell;
		white-space: nowrap;
		text-decoration: none;
		span {
			display: inline-block;
			text-transform: lowercase;
			&:first-letter {
				text-transform: uppercase;
			}
		}
		@media @media-mobile {
			display: none;
		}
		&:after {
			content: "\f105";
			font-family: 'FontAwesome';
			font-size: 20px;
			font-weight: 400;
			margin-left: 5px;
			vertical-align: -1px;
		}
	}
}
ul.arguments {
	display: table-cell;
	white-space: nowrap;
	@media @media-mobile {
		display: block;
		white-space: normal;
	}
	li {
		display: inline-block;
		padding: 0;
		font-size: 15px;
		margin-right: 35px;
		@media @media-mobile {
			margin: 0 15px 0 0;
		}

		span {
			display: inline-block;
			line-height: 16px;
			white-space: normal;
			padding-bottom: 6px;
			vertical-align: middle;

			@media @media-notmobile {
				max-width: 150px;
				padding-bottom: 15px;
			}
		}

		&:before {
			/*color: @color03;*/
			color: @color-gray01;
			font-size: 30px;
			position: static;
			margin-right: 8px;

			@media @media-mobile {
				font-size: 16px;
			}
		}

		&:before {
			font-weight: 700;
			font-family: "Open Sans", Arial, Helvetica, sans-serif;
		}

		&.deposit:before {
			content: "0€";
		}

		&.interest:before {
			content: "0%";
		}

		&.transport:before {
			content: "\f0d1";
			font-family: FontAwesome;
			font-weight: 400;
		}
	}
}
.type-lisatarvikud {
	a.category-link {
		display: table-cell;
		white-space: nowrap;
		text-decoration: none;
		span {
			display: inline-block;
			text-transform: lowercase;
			font-size: 15px;
			font-weight: bold;
			&:first-letter {
				text-transform: uppercase;
			}
		}
		&:after {
			content: "\f105";
			font-family: 'FontAwesome';
			font-size: 20px;
			font-weight: 400;
			margin-left: 5px;
			vertical-align: -1px;
		}
	}
}
.slider-book {
	position: relative;

	@media @media-tablet {
		margin-left: 9px;
	}

	@media @media-mobile {
		margin-left: 5px;
	}

	.owl-item .item {
		opacity: 1;
		height: auto;
		display: block;
		width: 100%;
		&:first-of-type {
			margin-left: 0;
		}
	}
	.item {
		display: inline-block;
		padding: 10px;
		vertical-align: top;
		&:hover {
			img {
				-ms-transform: scale(1.02);
				-webkit-transform: scale(1.02);
				transform: scale(1.02);
			}
			.b.center a .heading02 {
				color: @color-black;
			}
		}
		@media only screen and (min-width: 1500px) {
			width: calc(~"(100% - 170px)/7");
			&:first-of-type {
				margin-left: 75px;
			}
			&:nth-of-type(n+8) {
				display: none;
			}
		}
		@media only screen and (min-width: 1200px) and (max-width: 1499px) {
			width: calc(~"(100% - 170px)/6");
			&:first-of-type {
				margin-left: 75px;
			}
			&:nth-of-type(n+7) {
				display: none;
			}
		}
		@media only screen and (min-width: 1024px) and (max-width: 1199px) {
			width: calc(~"(100% - 170px)/5");
			&:first-of-type {
				margin-left: 75px;
			}
			&:nth-of-type(n+6) {
				display: none;
			}
		}
		@media @media-tablet {
			width: calc(~"(100% - 100px)/3");
			&:nth-of-type(n+4) {
				display: none;
			}
		}
		@media @media-mobile {
			width: calc(~"(100% - 100px)/2");
			&:nth-of-type(n+3) {
				display: none;
			}
		}
		@media only screen and (max-width: 480px) {
			width: calc(~"100% - 100px");
			&:nth-of-type(n+2) {
				display: none;
			}
		}
		img {
			width: 100%;
			transition: 0.35s;
		}
		.b.center {
			@media @media-desktop {
				height: 146px;
			}

			a {
				text-decoration: none;
				.heading02 {
					color: @color-gray01;
					display: block;
					font-weight: 700;
					padding-top: 10px;
					max-height: 102px;
					overflow: hidden;
					width: 100%;
				}
				.meta01 {
					color: @color-gray03;
					max-height: 44px;
					display: block;
					overflow: hidden;
				}
			}
		}
	}
	.owl-item {
		display: inline-block;
		vertical-align: top;
		&.active .center {
			display: block;
		}
	}
	.owl-stage-outer {
		.box-sizing(border-box);
	}
	.owl-stage {
		@media @media-notdesktop {
			padding-left: 0 !important;
		}
	}
	.owl-prev,
	.owl-next {
		background-color: rgba(39, 54, 69, 0.78);
		background-image: url("../../assets/img/components/bg-white1x1.jpg");
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: 100% 162px;
		cursor: pointer;
		font-size: 0;
		width: 65px;
		position: absolute;
		top: 10px;
		bottom: 0;
		&:hover {
			opacity: 0.9;
		}
		@media @media-notdesktop {
			display: none;
		}
		&.disabled {
			display: none;
		}
		.type-slider01 & {
			background-image: url("../../assets/img/components/bg-grey.jpg");
		}
	}
	.owl-next {
		right: 0;
		&:before {
			content: "\f105";
			color: @color-white;
			display: block;
			font-family: 'FontAwesome';
			font-size: 45px;
			line-height: 45px;
			position: absolute;
			right: 0;
			top: calc(~"(100% - 200px)/2");
			text-align: center;
			width: 65px;
		}
	}
	.owl-prev {
		left: 0;
		&:before {
			content: "\f104";
			color: @color-white;
			display: block;
			font-family: 'FontAwesome';
			font-size: 45px;
			line-height: 45px;
			position: absolute;
			right: 0;
			top: calc(~"(100% - 200px)/2");
			text-align: center;
			width: 65px;
		}
	}
}

.slider-device {
	position: relative;
	padding: 0 65px;
	overflow: hidden;
	@media @media-notdesktop {
		padding: 0;
	}
	li {
		display: inline-block;
		padding: 10px;
		vertical-align: top;
		text-align: center;
		.img {
			-webkit-transition: all .5s;
			transition: all .5s;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			will-change: transform;
			width: 100%;
		}
		&:hover {
			.img {
				-ms-transform: scale(1.05);
				-webkit-transform: scale(1.05);
				transform: scale(1.05);
			}
			.heading01 {
				color: @color-black;
			}
		}
		&:before {
			content: "";
		}
		a:hover {
			text-decoration: none;
		}
		@media only screen and (min-width: 1500px) {
			width: calc(~"(100% - 220px)/6");
			&:first-of-type {
				margin-left: 110px;
			}
			&:nth-of-type(n+7) {
				display: none;
			}
		}
		@media only screen and (min-width: 1200px) and (max-width: 1499px) {
			width: calc(~"(100% - 220px)/5");
			&:first-of-type {
				margin-left: 110px;
			}
			&:nth-of-type(n+6) {
				display: none;
			}
		}
		@media only screen and (min-width: 1024px) and (max-width: 1199px) {
			width: calc(~"(100% - 220px)/4");
			&:first-of-type {
				margin-left: 110px;
			}
			&:nth-of-type(n+5) {
				display: none;
			}
		}
		@media @media-tablet {
			width: calc(~"(100% - 100px)/3");
			&:nth-of-type(n+4) {
				display: none;
			}
		}
		@media @media-mobile {
			width: calc(~"(100% - 100px)/2");
			&:nth-of-type(n+3) {
				display: none;
			}
		}
		@media only screen and (max-width: 480px) {
			width: calc(~"100% - 100px");
			&:nth-of-type(n+2) {
				display: none;
			}
		}
		.img {
			height: 300px;
			display: block;
			position: relative;
			img {
				position: absolute;
				right: 0;
				left: 0;
				margin: auto;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				transform: translateY(-50%);
				-webkit-transition: .25s;
				-moz-transition: .25s;
				-ms-transition: .25s;
				-o-transition: .25s;
				transition: .25s;
			}
		}
		.heading01 {
			color: @color-gray01;
			display: block;
			font-size: 18px;
			font-weight: 700;
			margin: 15px 0;
		}
		.price01 {
			color: @color02;
			display: block;
			font-size: 25px;
			font-weight: 700;
			small {
				font-weight: 400;
				font-size: 14px;
				color: @color-gray03;
				display: inline-block;
				vertical-align: top;
				margin-top: 7px;
				&:before {
					content: "/";
				}
			}
		}
		.price02 {
			color: @color02;
			display: block;
			font-size: 14px;
			del {
				color: @color-gray05;
			}
		}
	}
	.owl-item {
		display: inline-block;
		vertical-align: top;
		li {
			opacity: 1;
			height: auto;
			display: block;
			width: 100%;
			&:first-of-type {
				margin-left: 0;
			}
		}
	}
	.owl-stage {
		@media @media-notdesktop {
			padding-left: 0 !important;
		}
	}
	.owl-prev,
	.owl-next {
		background: @color-white;
		cursor: pointer;
		color: @color-gray04;
		font-size: 0;
		width: 65px;
		position: absolute;
		top: 0;
		bottom: 0;
		&:hover {
			color: @color-gray03;
		}
		&:before {
			display: block;
			font-family: 'FontAwesome';
			font-size: 60px;
			position: absolute;
			right: -20px;
			top: 115px;
			width: 65px;
		}
		@media @media-notdesktop {
			display: none;
		}
		&.disabled {
			display: none;
		}
	}
	.owl-next {
		right: 0;
		&:before {
			content: "\f105";
		}
	}
	.owl-prev {
		left: 0;
		&:before {
			content: "\f104";
		}
	}
}

.slider-movies {
	position: relative;
	padding: 0 65px;
	overflow: hidden;
	@media @media-notdesktop {
		padding: 0;
	}
	li {
		display: inline-block;
		padding: 10px;
		vertical-align: top;
		text-align: center;
		.img {
			-webkit-transition: all .5s;
			transition: all .5s;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			will-change: transform;
			width: 100%;
		}
		&:hover {
			.img {
				-ms-transform: scale(1.05);
				-webkit-transform: scale(1.05);
				transform: scale(1.05);
			}
			.heading01 {
				color: @color-black;
			}
		}
		&:before {
			content: "";
		}
		a:hover {
			text-decoration: none;
		}
		@media only screen and (min-width: 1500px) {
			width: calc(~"(100% - 220px)/6");
			&:first-of-type {
				margin-left: 110px;
			}
			&:nth-of-type(n+7) {
				display: none;
			}
		}
		@media only screen and (min-width: 1200px) and (max-width: 1499px) {
			width: calc(~"(100% - 220px)/5");
			&:first-of-type {
				margin-left: 110px;
			}
			&:nth-of-type(n+6) {
				display: none;
			}
		}
		@media only screen and (min-width: 1024px) and (max-width: 1199px) {
			width: calc(~"(100% - 220px)/4");
			&:first-of-type {
				margin-left: 110px;
			}
			&:nth-of-type(n+5) {
				display: none;
			}
		}
		@media @media-tablet {
			width: calc(~"(100% - 100px)/3");
			&:nth-of-type(n+4) {
				display: none;
			}
		}
		@media @media-mobile {
			width: calc(~"(100% - 100px)/2");
			&:nth-of-type(n+3) {
				display: none;
			}
		}
		@media only screen and (max-width: 480px) {
			width: calc(~"100% - 100px");
			&:nth-of-type(n+2) {
				display: none;
			}
		}
		.img {
			height: 291px;
			display: block;
			position: relative;
			.img-wrapper {
				&:after {
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.56);
					position: absolute;
				}
			}
			img {
				position: absolute;
				right: 0;
				left: 0;
				margin: auto;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				transform: translateY(-50%);
				-webkit-transition: .25s;
				-moz-transition: .25s;
				-ms-transition: .25s;
				-o-transition: .25s;
				transition: .25s;
			}
		}
		.heading01 {
			color: @color-gray02;
			display: block;
			font-size: 15px;
			font-weight: 700;
			margin: 8px 0;
		}
	}
	.owl-item {
		display: inline-block;
		vertical-align: top;
		li {
			opacity: 1;
			height: auto;
			display: block;
			width: 100%;
			&:first-of-type {
				margin-left: 0;
			}
		}
	}
	.owl-stage {
		@media @media-notdesktop {
			padding-left: 0 !important;
		}
	}
	.owl-prev,
	.owl-next {
		background: transparent;
		cursor: pointer;
		color: white;
		font-size: 0;
		width: 65px;
		position: absolute;
		top: 0;
		bottom: 0;
		&:hover {
			color: @color-gray05;
		}
		&:before {
			display: block;
			font-family: 'FontAwesome';
			font-size: 60px;
			position: absolute;
			right: -20px;
			top: 115px;
			width: 65px;
		}
		@media @media-notdesktop {
			display: none;
		}
		&.disabled {
			display: none;
		}
	}
	.owl-next {
		right: 0;
		&:before {
			content: "\f105";
		}
	}
	.owl-prev {
		left: 0;
		&:before {
			content: "\f104";
		}
	}
	&__title {
		margin-bottom: 10px;
	}
}

.slider-accessories {
	overflow: hidden;
	position: relative;
	padding: 0 39px;
	.img {
		display: block;
		text-align: center;
		height: 200px;
		IMG {
			max-height: 150px;
			width: auto;
			height: 150px;
			max-width: 150px;
		}
	}
	.active ~ .active {
		margin-left: -2px;
		border-left: 2px solid @color-gray11;
	}

	SPAN.heading02 {
		font-weight: bold; color: @color01; font-family: @font-main; font-size: 15px;  line-height: 1.4;
	}
	span.meta {
		font-size: 18px;
		font-weight: bold;
	}
	.owl-item .item {
		opacity: 1;
		height: auto;
		display: block;
		width: 100%;
		&:first-of-type {
			margin-left: 0;
		}
	}
	.owl-prev,
	.owl-next {
		&:hover {
			background-image: none;
		}
	}
	.item {
		display: inline-block;
		vertical-align: top;
		padding: 20px 0;
		&:hover {
			img {
				-ms-transform: scale(1.02);
				-webkit-transform: scale(1.02);
				transform: scale(1.02);
			}
			.b.center a .heading02 {
				color: @color-black;
			}
		}
		@media only screen and (min-width: 1500px) {
			width: calc(~"(100% - 170px)/7");
			&:first-of-type {
				margin-left: 75px;
			}
			&:nth-of-type(n+8) {
				display: none;
			}
		}
		@media only screen and (min-width: 1200px) and (max-width: 1499px) {
			width: calc(~"(100% - 170px)/6");
			&:first-of-type {
				margin-left: 75px;
			}
			&:nth-of-type(n+7) {
				display: none;
			}
		}
		@media only screen and (min-width: 1024px) and (max-width: 1199px) {
			width: calc(~"(100% - 170px)/5");
			&:first-of-type {
				margin-left: 75px;
			}
			&:nth-of-type(n+6) {
				display: none;
			}
		}
		@media @media-tablet {
			width: calc(~"(100% - 100px)/3");
			&:nth-of-type(n+4) {
				display: none;
			}
		}
		@media @media-mobile {
			width: calc(~"(100% - 100px)/2");
			&:nth-of-type(n+3) {
				display: none;
			}
		}
		@media only screen and (max-width: 480px) {
			width: calc(~"100% - 100px");
			&:nth-of-type(n+2) {
				display: none;
			}
		}
		img {
			/*width: 100%;*/
			transition: 0.35s;
		}
		.b.center {
			padding: 0 32px;
			@media @media-desktop {
				height: auto;
			}
			@media @media-mobile-narrow {
				padding: 0 13px;
			}

			a {
				text-decoration: none;
				.heading02 {
					color: @color01;
					display: block;
					padding-top: 10px;
					max-height: 102px;
					overflow: hidden;
					width: 100%;
					height: 75px;
					font-size: 15px;
					font-weight: 700;
					font-family: @font-main;

				}
				.meta01 {
					color: @color-gray08;
					max-height: 44px;
					display: block;
					overflow: hidden;
					font-weight: 700;
					font-size: 18px;
					font-weight: 700;
					font-family: "Open Sans", Arial, Helvetica, sans-serif;
				}
			}
		}
	}
	.owl-item {
		display: inline-block;
		vertical-align: top;
		&.active .center {
			display: block;
		}
	}
	.owl-stage {
		@media @media-notwide {
			padding-left: 0 !important;
			margin-left: -2px;
		}
		@media @media-notdesktop {
			padding-left: 0 !important;
			margin-left: -2px;
		}
	}
	.owl-prev,
	.owl-next {
		background: @color-white;
		background-image: url("../../assets/img/components/bg-white1x1.jpg");
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: 100% 162px;
		cursor: pointer;
		font-size: 0;
		width: 39px;
		position: absolute;
		top: 0;
		bottom: 0;
		border: none;
		&:hover {
			background-color: #f0f1f5;
		}
		&:focus {
			outline: none;
		}
		@media @media-notdesktop {
			display: none;
		}
		&.disabled {
			display: none;
		}
		.type-slider01 & {
			background-image: url("../../assets/img/components/bg-grey.jpg");
		}
	}
	.owl-next {
		right: 0;
		&:before {
			content: "\f105";
			color: @color29;
			display: block;
			font-family: 'FontAwesome';
			font-size: 40px;
			line-height: 40px;
			position: absolute;
			right: 0;
			top: calc(~"(100% - 45px)/2");
			text-align: center;
			width: 39px;
		}
	}
	.owl-prev {
		left: 0;
		&:before {
			content: "\f104";
			color: @color29;
			display: block;
			font-family: 'FontAwesome';
			font-size: 45px;
			line-height: 45px;
			position: absolute;
			right: 0;
			top: calc(~"(100% - 45px)/2");
			text-align: center;
			width: 39px;
		}
	}
	@media @media-notdesktop {
		.active  {
			margin-right: -2px;
			border-right: 2px solid @color-gray11;
		}
	}
	@media @media-tablet {
		margin-left: 0;
		padding: 0;
	}
	@media @media-mobile {
		margin-left: 0;
		padding: 0;
	}
}

.type-slider {
	&:after {
		height: 0;
	}
	&__tv {
		height: auto;
		width: 100%;
		position: relative;
		&--title {
			font-size: 30px;
			font-weight: bold;
			line-height: 1.27;
			color: white;
			@media @media-tablet {
				display: none;
			}
			@media @media-mobile {
				display: none;
			}
		}
		&--text {
			font-size: 18px;
			line-height: 1.56;
			color: white;
			@media @media-tablet {
				font-size: 20px;
				line-height: 1.6;
				color: @color01;
			}
			@media @media-mobile {
				font-size: 18px;
				line-height: 1.56;
				color: @color01;
			}
		}
		&--list {
			margin-top: 30px;
			li {
				color: white;
				font-size: 15px;
				font-weight: 600;
				line-height: 1.53;
				padding-left: 25px;
				@media @media-tablet {
					color: @color-gray01;
					font-weight: 400;
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				@media @media-mobile {
					color: @color-gray01;
					font-weight: 400;
					margin-bottom: 15px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				&:before {
					content: "\f00c";
					font-family: FontAwesome;
					font-size: 13px;
					color: @color03;
					font-weight: bold;
					top: 6px;
					line-height: 1;
					height: 10px;
				}
			}
		}
		&--footer {
			position: absolute;
			bottom: 0;
			overflow: hidden;
			height: 65px;
			width: 100%;
			background-color: rgba(39, 54, 69, 0.65);
			z-index: 1010;
			padding-left: 130px;
			@media @media-tablet {
				top: 325px;
				padding-left: 20px;
			}
			@media @media-mobile {
				height: 40px;
				top: 210px;
				padding-left: 20px;
			}
		}
		&--item {
			position: relative;
		}
		&--image {
			background-position: center;
			background-size: cover;
			height: 720px;
			position: absolute;
			top: 0;
			width: 100%;
			@media @media-tablet {
				height: 390px;
			}
			@media @media-mobile {
				height: 250px;
				background-position: left center;
			}
		}
		&--slide-1 {
			background-image: url("../../assets/img/components/type-slider-tv-1.png");
		}
		&--slide-2 {
			background-image: url("../../assets/img/components/type-slider-tv-2.png");
		}
		&--slide-3 {
			background-image: url("../../assets/img/components/type-slider-tv-3.png");
		}
		&--slide-4 {
			background-image: url("../../assets/img/components/type-slider-tv-4.png");
		}
		&--slide-5 {
			background-image: url("../../assets/img/components/type-slider-tv-5.png");
		}
		&--slide-6 {
			background-image: url("../../assets/img/components/type-slider-tv.png");
		}
		&--slide-7 {
			background-image: url("../../assets/img/components/type-slider-tv-7.png");
		}
		&--slide-8 {
			background-image: url("../../assets/img/components/type-slider-tv-8.png");
		}
		&--slide-9 {
			background-image: url("../../assets/img/components/type-slider-tv-9.png");
		}
		&--content {
			height: 720px;
			position: relative;
			z-index: 1000;
			@media @media-tablet {
				position: relative;
				height: auto;
				padding-bottom: 50px;
			}
			@media @media-mobile {
				position: relative;
				height: auto;
				padding-bottom: 40px;
			}
			.tv-content {
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				width: 30%;
				right: 135px;
				@media @media-tablet {
					position: static;
					height: auto;
					transform: none;
					width: 100%;
					margin-top: 425px;
					padding: 0 20px;
				}
				@media @media-mobile {
					position: static;
					height: auto;
					transform: none;
					width: 100%;
					margin-top: 270px;
					padding: 0 20px;
				}
			}
		}
		&--menu {
			height: 65px;
			font-size: 18px;
			padding: 21px 25px;
			line-height: 24px;
			color: white;
			opacity: 1;
			transition: opacity 0.3s ease;
			cursor: pointer;
			&:hover {
				opacity: 0.6;
				text-decoration: none;
			}
			&:active,
			&:focus {
				opacity: 1;
				text-decoration: none;
				outline: none;
			}
			&.slick-current {
				background-color: @color03;
				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
			@media @media-mobile {
				height: 40px;
				font-size: 15px;
				padding: 10px 20px;
				line-height: 20px;
			}
			li {
				display: inline-block;
				vertical-align: top;
				margin: 0;
				padding: 0;
				&:before {
					display: none;
				}
				a {
					background-color: transparent;
					color: white;
					font-size: 18px;
					padding: 21px 25px;
					line-height: 24px;
					display: block;
					opacity: 1;
					transition: opacity 0.3s ease;
					@media @media-mobile {
						font-size: 15px;
						padding: 10px 20px;
						line-height: 20px;
					}
					&:hover,
					&:active,
					&:focus {
						opacity: 0.6;
						text-decoration: none;
					}
					&.active {
						font-weight: bold;
						background-color: @color03;
						&:hover,
						&:active,
						&:focus {
							opacity: 1;
							text-decoration: none;
						}
					}
				}
			}
		}
		.slick-arrow {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 29px;
			height: 80px;
			z-index: 1000;
			font-size: 0;
			line-height: 0;
			position: absolute;
			display: block;
			padding: 0;
			cursor: pointer;
			color: transparent;
			border: none;
			outline: none;
			background: transparent;
			@media @media-tablet {
				transform: none;
				top: 150px;
			}
			@media @media-mobile {
				width: 29px;
				height: 67px;
				transform: none;
				top: 100px;
			}
			&:before {
				display: block;
				width: 29px;
				height: 80px;
				font-family: FontAwesome;
				font-size: 80px;
				color: #ccc;
				opacity: 0.4;
				line-height: 1;
				transition: opacity 0.3s ease;
				@media @media-mobile {
					width: 29px;
					height: 67px;
					font-size: 40px;
				}
			}
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				&:before {
					opacity: 0.8;
					text-decoration: none;
				}
			}
		}
		.slick-next {
			right: 48px;
			@media @media-tablet {
				right: 26px;
			}
			@media @media-mobile {
				right: 4px;
			}
			&:before {
				content: "\f105";
			}
		}
		.slick-prev {
			left: 48px;
			@media @media-tablet {
				left: 26px;
			}
			@media @media-mobile {
				left: 4px;
			}
			&:before {
				content: "\f104";
			}
		}
		&--light-background {
			&.active {
				&~.type-slider__tv--next,
				&~.type-slider__tv--prev {
					&:before {
						color: black;
					}
				}
			}
			.type-slider__tv--title,
			.type-slider__tv--text {
				color: @color01;
			}
			.type-slider__tv--list {
				li {
					color: @color-gray01;
					font-weight: 400;
				}
			}
		}
	}
}