.options__accordion {
	.SumoSelect {
		width: 100%;
	}
	.SumoSelect > .optWrapper > .options {
		max-height: none;
	}

	.SumoSelect>.CaptionCont {
		border: none;
		border-bottom: solid 1px @color-gray05;
		border-radius: 0;
	}

	.SumoSelect>.CaptionCont>label>i {
		background-image: none;
		width: 20px;
		height: 20px;
		font-style: normal;
		opacity: 1;
		&:before {
			font-family: FontAwesome;
			content: "\f107";
			display: block;
			font-size: 18px;
			color: @color-gray01;
		}
	}

	.SumoSelect>.CaptionCont>span {
		display: inline-block;
		vertical-align: middle;
		padding-right: 5px;
		color: @color-gray01;
		font-weight: 700;
	}

	.SumoSelect>.optWrapper {
		border: none;
		box-shadow: none;
		border-radius: 0;
		position: relative;
	}

	.SumoSelect.open>.optWrapper {
		top: 0;
		display: block;
		border-radius: 0;
	}

	.SumoSelect>.optWrapper>.options li.opt {
		padding: 6px 6px;
		position: relative;
		border-bottom: none;
		&:before {
			display: none;
		}
	}

	.SumoSelect>.optWrapper>.options li.opt {
		padding: 10px 19px;
		margin: 0;
	}

	.SumoSelect>.optWrapper>.options>li.selected {
		border-left: 3px solid @color03;
		font-weight: 700;
		padding: 10px 19px 10px 16px;
	}

	.SumoSelect>.CaptionCont {
		/*border: none;*/
		border-bottom: solid 1px @color-gray06;
		/*border-radius: 0;*/
	}

	.SumoSelect:focus>.CaptionCont,
	.SumoSelect:hover>.CaptionCont,
	.SumoSelect.open>.CaptionCont {
		box-shadow: initial;
		border-color: transparent;
		border-bottom-color: @color-gray06;
	}

	.SumoSelect>.optWrapper>.options>li.opt:first-child {
		border-radius: 0;
	}

	&.sumo__select {
		&--default {
			.SumoSelect>.CaptionCont {
				padding: 0 10px 13px 0;
				>span {
					font-size: 15px;
					color: @color-gray01;
				}
				>label>i {
					height: 35px;
				}
			}
			.SumoSelect>.optWrapper>.options li label {
				font-size: 15px;
				color: @color-gray01;
			}
			.SumoSelect.open>.optWrapper {
				top: 0;
				display: block;
				border-radius: 0;
				@media @media-mobile {
					top: 42px;
				}
			}
			.SumoSelect>.optWrapper>.options li.opt {
				padding: 8px 19px;
				margin: 0;
			}
			.SumoSelect>.optWrapper>.options>li.selected {
				padding: 8px 19px 8px 16px;
			}
		}
		&--large {
			.SumoSelect>.CaptionCont {
				padding: 0 10px 20px 9px;
				>span {
					font-size: 18px;
					color: @color-gray01;
					@media @media-mobile {
						font-size: 15px;
					}
				}
				>label>i {
					height: 35px;
				}
				@media @media-mobile {
					padding: 0 10px 13px 9px;
				}
			}
			.SumoSelect>.optWrapper>.options li label {
				font-size: 18px;
				color: @color-gray01;
				@media @media-mobile {
					font-size: 15px;
				}
			}
			.SumoSelect.open>.optWrapper {
				top: 49px;
				display: block;
				border-radius: 0;
				@media @media-mobile {
					top: 42px;
				}
			}
		}
		&--xsmall {
			.SumoSelect>.CaptionCont {
				padding: 4px 10px 4px 0;
				border-bottom: 0;
				>span {
					font-size: 14px;
					color: @color-gray01;
					@media @media-mobile {
						font-size: 14px;
					}
				}
				>label>i {
					height: 20px;
					text-align: right;
					margin-right: 0;
				}
			}
			.open {
				.CaptionCont {
					span {
						font-weight: 700;
					}
				}
			}
			.SumoSelect>.optWrapper>.options li label {
				font-size: 14px;
				color: @color-gray01;
				@media @media-mobile {
					font-size: 14px;
				}
			}
			.SumoSelect.open>.optWrapper {
				top: 35px;
				display: block;
				border-radius: 0;
				@media @media-mobile {
					top: 35px;
				}
			}
			.SumoSelect>.optWrapper>.options li.opt {
				padding: 8px 19px;
				margin: 0;
			}
			.SumoSelect>.optWrapper>.options>li.selected {
				padding: 8px 19px 8px 16px;
			}
		}
		&--bordered {
			.SumoSelect>.CaptionCont {
				border: solid 1px @color-gray05;
				padding: 12px 10px 13px 9px;
			}
			.SumoSelect>.CaptionCont>label {
				top: 15px;
			}
			.SumoSelect.open>.optWrapper {
				top: 50px;
			}
		}
	}

	.sumo__icon {
		.SumoSelect {
			>.CaptionCont {
				padding-left: 0;
				&:before {
					font-family: 'elisa-icons' !important;
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
			}
		}
	}

}
.options__title {
	font-size: 18px;
	font-weight: bold;
	line-height: 1.67;
	color: @color-gray01;
	margin-top: 0;
	position: relative;
	padding-left: 40px;
	@media @media-mobile {
		font-size: 15px;
		font-weight: bold;
		line-height: 1.73;
		padding-left: 35px;
	}
	>span {
		font-size: 28px;
		text-align: left;
		color: @color03;
		vertical-align: middle;
		position: absolute;
		left: 0;
		@media @media-mobile {
			font-size: 20px;
			top: 3px;
		}
	}
}

.options__single {
	margin-bottom: 40px;
}

.options__actions {
	&--btn {
		width: 349px;
		@media @media-tablet {
			width: 100%;
		}
	}
}

.results-dropdown {
	border: none;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
	box-sizing: border-box;
	overflow: hidden;
}

.services-list {
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
	overflow: auto;
	position: relative;
	max-height: 250px;
	li {
		font-size: 15px;
		line-height: 1.33;
		color: @color-gray01;
		padding: 12px 20px;
		margin: 0;
		cursor: pointer;
		width: 110%;
		&:before {
			display: none;
		}
		&:hover,
		&:active,
		&:focus {
			background-color: @color-gray07;
		}
	}
}
