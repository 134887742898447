.modal {
	.align-items(center);
	.zindex(@modalZindex, 0);
	position: fixed;
	overflow: hidden;
	height: 100vh;
	width: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	&.is-active {
		.display(flex);
	}
	&__overlay {
		background-color: rgba(10,10,10,.6);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	&__close {
		position: absolute;
		height: 25px;
		width: 20px;
		top: 21px;
		right: 19px;
		&:before {
			font-family: "FontAwesome";
			content: "\f00d";
			line-height: 25px;
			float: left;
			text-indent: 0;
			text-align: center;
			color: @color-gray04;
			font-size: 25px;
		}

		&:hover {

		}
		@media @media-mobile {
			top: 18px;
		}
	}
	&__content {
		position: relative;
		width: 800px;
		background-color: @color-white;
		.display(flex);
		.flex-direction(column);
		max-height: calc(~"100vh - 40px");
		overflow: hidden;
		margin: 0 auto;
		&--w600 {
			width: 600px;
		}
		@media @media-mobile {
			max-height: 100vh;
		}
	}

	&__header {
		height: 100px;
		.zindex(@modalZindex, 1);
		.modal__title {
			font-size: 25px;
			font-weight: 700;
			line-height: 1.42;
			color: @color01;
			font-family: @font-main;
			&--color {
				&-dark {
					color: @color-gray02;
				}
			}
			@media @media-mobile {
				text-align: left;
				font-size: 24px;
				line-height: 1.42;
			}

		}
		&--anim {
			padding: 0;
			.modal__title {
				@media @media-mobile {
					font-size: 15px;
					line-height: 2.27;
					-webkit-transition: font-size 0.3s ease, line-height, .3s, ease;
					transition: font-size, .3s, ease, line-height, .3s, ease;
				}
			}
		}
	}
	&__body {
		.flex(1 1 auto);
		overflow-y: auto;
	}
	&__footer {
		height: 100px;
		width: 100%;
		.display(flex);
		.align-items(center);
		&--has-gradient {
			background: transparent;
			background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2 ), rgba(255, 255, 255, 0.0 ));
			position: absolute;
			bottom: 0;
			left: 0;
		}
		@media @media-mobile {
			height: 150px;
		}
	}
	&__pad {
		padding: 33px 34px 27px 34px;
		@media @media-mobile {
			padding: 18px 47px 24px 18px;
		}
		&--has-gradient {
			padding: 33px 34px 100px 34px;
			@media @media-mobile {
				padding: 18px 47px 150px 18px;
			}
		}
	}
}