.clear {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
	*zoom: 1;
}
.clear02 { overflow: hidden; }
.clear03 { overflow: visible!important; }
.clear04 { overflow: auto!important; }

.w50 { width: 50px; }
.w60 { width: 60px; }
.w70 { width: 70px; }
.w80 { width: 80px; }
.w90 { width: 90px; }
.w100 { width: 100px; }
.w150 {width: 150px; }
.w200 {width: 200px; }
.w500 {
	width: 462px;
	@media @media-mobile {
		width: 100%;
	}
}

.img-left { float: left; margin: .3em 1em 1em 0; clear: both; }
.img-right { float: right; margin: .3em 0 1em 1em; clear: both; }
.strike { text-decoration: line-through; }
.posrel { position: relative; }
.block { display: block; }

.f-left { float: left; }
.f-right { float: right; }
.hidden { display: none; visibility: hidden; }
.c-left { clear: left; }
.c-right { clear: right; }
.c-both { clear: both; }

TR.hidden { visibility: visible; }
.ui-helper-hidden-accessible { display: none; }
.visuallyhidden { position: absolute; left: -10000px; top: -10000px; }
.nowrap { white-space: nowrap; }
.left { text-align: left; }
.right { text-align: right; }
.center { text-align: center !important; }
.nomargin { margin: 0 !important; }
.nopadding { padding: 0 !important; }
.noblank:after { display: none !important; }
.bold { font-weight: bold !important; }
.b-bottom {
	border: 1px solid @color-gray-lightest;
}

.w10p { width: 10%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w30p { width: 30%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w70p { width: 70%; }
.w100p { width: 100%; }

// Todo: Possibly unused selectors, remove permanently
.w50 { width: 50px; }
.w60 { width: 60px; }
.w70 { width: 70px; }
.w80 { width: 80px; }
.w90 { width: 90px; }
.w100 { width: 100px; }

.mt5 { margin-top: 5px !important; }
.mt7 { margin-top: 7px !important; }
.mt10 { margin-top: 10px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mt1em { margin-top: 1em !important; }
.mt2em { margin-top: 2em !important; }

.mr20 { margin-right: 20px !important; }
.mr5 { margin-right: 5px !important; }
.mr10 { margin-right: 10px !important; }
.mr30 { margin-right: 30px !important; }
.ml10 { margin-left: 10px !important; }
.ml20 { margin-left: 20px !important; }
/*.ml30 { margin-left: 30px!important;  }*/
.ml25 { margin-left: 25px !important; }
.ml50 { margin-left: 50px !important; }
.w311 { width: 311px; @media @media-notdesktop {width: 100%;} }

.noborder { border: none !important; }
.pt1em { padding-top: 1em !important; }

// Todo: Possibly unused selectors, remove permanently
.pb1em { padding-bottom: 1em !important; }
.pb2em { padding-bottom: 2em !important; }

.contrast (@prop, @val, @amount) when (lightness(@val) >= 50%) { @{prop}: darken(@val, @amount); }
.contrast (@prop, @val, @amount) when (lightness(@val) < 50%) { @{prop}: lighten(@val, @amount); }

.distribute {
	text-align: justify;
	&:after { content: ""; display: inline-block; width: 100%; height: 1px;}
}

.flex {
	.display(flex); .flex-wrap(wrap);
}
.flex01 {
	.display(flex); .flex-wrap(wrap);
	@media @media-mobile {
		display: block;
	}
}
.flex-h100p {
	.display(flex); .flex-direction(column); .flex(1 0 auto); height: auto;
}
.flex-w100p {
	 .display(flex); .flex-direction(column); .flex(1 0 100%); height: 100%;
}
.flex-end {
	.align-self(flex-end);
}

.inactive {
	IMG { .grayscale(100%); }
}

svg.gray {
	background:url(http://4.bp.blogspot.com/-IzPWLqY4gJ0/T01CPzNb1KI/AAAAAAAACgA/_8uyj68QhFE/s400/a2cf7051-5952-4b39-aca3-4481976cb242.jpg);
}
.cursor01 {
	cursor: pointer;
}
.cursor02 {
	cursor: default!important;
}
.flex-center {
	.display(flex);
	.align-content(center);
	.align-items(center);
	.justify-content(center);
}
.flex-center-wrap {
	.display(flex);
	.align-items(center);
	.flex-wrap(wrap);
}

.flex-v-center {
	.display(flex); .flex-direction(column); .justify-content(center);
}

.inline-flex {
	.display(inline-flex);
}
.flex-nowrap {
	.flex-wrap(nowrap);
}
.flex-wrap {
	.flex-wrap(wrap);
}
.flex-column {
	.flex-direction(column);
}

