.badge {
	width: auto;
	height: 31px;
	position: relative;
	color: @color-white;
	font-size: 13px;
	text-align: center;
	.box-sizing(border-box);
	z-index: 9;
	font-family: @font-main;
	&.badge--neg {
		position: absolute;
		top: -20px;
		left: -1px;
	}
	&.badge__rel {
		position: relative;
		top: 0;
		left: 0;
		float: left;
	}
	&.badge__pos {
		position: absolute;
		top: 0;
		left: 0;
		/*&--align01 {
			top: -20px;
			left: -1px;
		}*/
	}
	&--w200 {
		width: 200px;
	}
	&__txt {
		font-family: @font-main;
	}
	&__icon {
		&:before {
			width: 13px;
			height: 13px;
			font-size: 13px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			margin-right: 5px;
		}
		&--dispatcher {
			&:before {
				font-family: elisa-icons;
				content: "\e913";
			}
		}
	}
	&__products {
		position: relative;
		display: block;
		padding: 7px 16px;
		color: @color-white;
		font-size: 15px;
		line-height: 1.07;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 20px;
	}
	&__products:nth-child(2n+2) {
		margin-top: 2px;
		left: 0;
		border-top-left-radius: 0;
		display: table;
	}
	&__products:first-child {
		margin-top: 0;
	}
	&__products:nth-child(2n + 1) {
		margin-top: 2px;
		left: -1px;
	}
	&__client {
		text-transform: uppercase;
		font-weight: 700;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		padding: 3px 6px;
		font-size: 13px;
		color: @color-white;
	}
	&__dispatcher {
		text-transform: uppercase;
		font-weight: 700;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		padding: 3px 6px;
		font-size: 13px;
		color: @color-gray01;
	}
	&__offer {
		font-weight: 400;
		border-top-left-radius: 20px;
		padding: 5px 15px;
		float: left;
		font-size: 13px;
		font-family: @font-main;
		&:nth-child(2n + 2) {
			border-top-left-radius: 0;
			border-bottom-right-radius: 20px;
		}
		&:nth-child(2n + 1) {
			margin-right: 5px;
			margin-bottom: 5px;
		}
		&.badge__offer--full {
			border-top-left-radius: 20px;
			border-bottom-right-radius: 20px;
		}
	}
	&__pre-order {

	}
	&--bg-orange {
		background: @color-orange02;
		color: @color-gray01;
	}
	&--bg-blue {
		background: @color-blue02;
		color: @color-white;
	}
}
