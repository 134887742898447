@import (reference) "../fonts/Icons/icons.less";

BODY, HTML {
	height: 100%;
}
BODY {
	font: 14px/150% @font-main;
	&.hassticky {
		padding-bottom: 100px; /* padding-bottom: 80%; */
		@media @media-mobile {
			padding-bottom: 0;
		}
	}
}

P, PRE, TABLE, H1, H2, H3, H4, H5, H6 { margin: 1em 0 0 0; }

H1, H2, H3, H4, H5, H6 {
	font-family: @font-heading; font-weight: 300; color: @color-black; line-height: normal;
	.ru & { font-family: @font-heading-ru; }
	&.itb__heading {
		color: @color-gray01; font-family: @font-main;
		&.itb__heading--light {
			color: @color-gray03;
		}
	}
}

H1 {
	font-size: 60px; margin: .3em 0 0 0;
	&.type02 { color: @color02; }
	SPAN.small { // Todo: Possibly unused selector, remove permanently
		font-size: @base-font-size; font-family: @font-main; margin-top: 3.5em;
		@media @media-mobile {
			display: block; float: none; margin-top: 0;
		}
	}
	&.type03 {
		@media @media-mobile {
			font-size: 40px;
		}
	}
	&.type04 {
		font-family: @font-main; font-size: 40px; line-height: 1.25; color: @color01; font-weight: 700; text-transform: uppercase;
		@media @media-mobile {
			font-size: 30px;
			line-height: 35px;
		}
	}
	&.heading {
		font-family: @font-main; font-size: 30px; line-height: 1.6; color: @color01; font-weight: 300;
		@media @media-mobile {
			font-size: 30px;
		}
	}
	&.itb__heading {
		font-weight: 700; font-size: 30px; line-height: 1.33; color: @color-gray02; text-transform: uppercase;
		@media @media-mobile {
			font-size: 25px;
			line-height: 1.2;
		}
	}
}
H2 {
	font-size: 24px; color: @color02; margin: 1em 0 0;
	SECTION &,
	.content &{ font-size: 46px; }
	&.mt0 { margin-top: 0; }
	&.step-heading { font-size: 24px; color: @color-black; margin: 1em 0 0; }
	SPAN.small {
		font-size: 15px !important; font-weight: normal; font-family: @font-main; color: @color-black;
		&.f-right { margin-top: .7em; }
	}
	.imbi & { color: @color-black; font-size: 32px; }
	.imbi .col.w70p > & { margin: .5em 0 0 0 !important; }
	.imbi .col.w50p > & { font-size: 18px; margin: 1em 0 0 0 !important; }
	&.blue { color: @color01; }
	&.internal {
		color: @color02; position: relative;
		SPAN {
			margin-left: 0;
			&:before { float: none; display: inline-block; top: -.3em; position: relative; }
		}
	}
	&.type02 { font-size: 22px; margin: 1.5em 0 -.7em 0; } // Todo: Possibly unused selector, remove permanently
	&.type03 {
		color: @color01; border-bottom: 1px solid @color-gray-light; font-size: 28px;
		SPAN {
			float: right; text-transform: uppercase; font-size: 14px; color: @color-gray-dark; font-weight: bold; font-family: @font-main;
			&:before { font-size: 24px; font-weight: normal; width: auto; }
			@media @media-mobile { float: none; display: block; }
		}
	}
	.btn { font-family: @font-main; }
	&.type04 { color:  @color-gray-dark; } // Todo: Possibly unused selector, remove permanently
	&.type05 { color:  @color-black; font-size: 36px !important; } // Todo: Possibly unused selector, remove permanently
	&.type06 { color:  @color-black; font-weight: bold; }
	&.type07 { color:  @color-black !important; font-weight: 400; }
	&.type08 { color: @color01; font-size: 30px; font-family: @font-main; font-weight: 300; line-height: 1.5; }
	&.type09 { color: @color01; font-size: 20px; font-family: @font-main; font-weight: 300; line-height: 1.5; }
	&.itb__heading {
		font-weight: 300; font-size: 28px; line-height: 1.43;
		@media @media-mobile {
			font-size: 23px;
		}
	}
	@media @media-mobile {
		&.type04.f-left { float: none; } // Todo: Possibly unused selector, remove permanently
		& + SELECT { margin-top: 1em; float: none; } // Todo: Possibly unused selector, remove permanently

		&.type08 { font-size: 24px; line-height: 35px; }
	}
}
H3 {
    font-size: 18px;
	SPAN.small { font-size: @base-font-size; }
	A.btn { font-size: 12px; font-family: @font-main; margin-left: 2em; }
    &.type01 {
        font-size: 30px;
        @media @media-mobile {
          font-size: 16px;
        }
    }
	&.type02 {
		font-size: 18px;
		color: @color02;
		font-weight: bold;
		&:before {
			padding-right: 10px;
		}
	}
	&.type05 {
		font-size: 20px;
		color: @color-blue01;
		font-weight: 400;
		font-family: @font-main;
		line-height: 1.5;
	}
	&.itb__heading  {
		font-weight: 400; font-size: 20px; line-height: 1.5; color: @color-gray01;
		@media @media-mobile {
			font-size: 18px;
			line-height: 1.44;
		}
	}
	/*used in block21*/
	/*&.type04 {
		font-size: 18px;
		color: @color01;
		font-family: @font-main;
	}*/
}

H4 { font-size: 16px;
	&.type01 {
		font-size: 18px;
		font-weight: 400;
		font-family: @font-main;
		color: @color-blue01;
	}
	&.itb__heading {
		font-weight: 400; font-size: 18px; line-height: 1.5;
		@media @media-mobile {
			font-size: 17px;
			line-height: 1.47;
		}
	}

}
H5 { font-size: 14px;
	&.type01 {
		& + P {
			margin: 1em 0 0 0;
		}
		font-size: 15px;
		font-weight: 600;
		font-family: @font-main;
		color: @color-blue01;
	}
	&.itb__heading {
		font-weight: 600; font-size: 15px; line-height: 1.67;
		@media @media-mobile {
			font-size: 15px;
			line-height: 1.47;
		}
	}
}
H6 { font-size: 12px; }
@media @media-mobile {
	H1 { font-size: 30px; }
	H2 { font-size: 25px; }
	SECTION H2,
	.content H2{
		font-size: 35px;
	}
}
.red { color: @color-negative; }
.orange { color: @color02!important; }
.gray { color: @color-gray-medium; }
.green { color: @color-positive; }
.warn { color: @color-warn; }
P {
	margin: 1.5em 0 0 0; color: @color-black; line-height: 1.4;
	H2 + &,
	H3 + & { margin-top: .5em }
	H4 + &,
	H5 + &,
	H6 + & { margin-top: 0; }
	&.mt0 { margin-top: 0; }
	&.type01 {
		font-size: 142%;
		&.hasico:before { float: left; font-size: 72px; margin-right: 20px; color: @color01; width: auto; height: auto; line-height: 1; }
	}
	&.type02 {
		font-size: 24px; font-family: @font-heading;
		.ru & { font-family: @font-heading-ru; }
	}
	&.type03 {
		font-size: 15px; font-family: @font-main; color:@color-gray01; font-weight: 400; line-height: 1.4;
	}
	&.p-note {
		font-size: 15px; font-family: @font-main; font-weight: 400; line-height: 1.4; color: @color-gray03;
	}
	/*
	&.type04 {
		color:@color28; line-height: 30px;
	}&.type04 {
		font-size: 18px; font-weight: 700; font-family: @font-main; color:@color-gray01; line-height: 1.67;
	}*/
	/*&.type04 {
		font-size: 25px; line-height: 34px; font-weight: bold; font-family: @font-main; color:@color01;
	}*/
	/*check*/
	/*&.type05 {
		font-size: 18px; font-weight: bold; font-family: @font-main; color:@color01;
	}*/
	&.p-semibold {
		font-size: 15px; font-weight: 600; font-family: @font-main; color:@color-gray01; line-height: 1.4;
	}
	&.p-bold {
		font-size: 15px; font-weight: 700; font-family: @font-main; color:@color-gray01; line-height: 1.4;
	}
	&.price01 { font-size: 28px; font-weight: bold; }
	&.minttitle {
		overflow: hidden; line-height: 29px; font-family: @font-main; font-weight: bold; border-bottom: 2px dotted @color-gray-medium-lightest; padding-bottom: 20px;
		IMG { float: left; margin: 0 10px 0 0; }
	}
	&.ico-owl {
		background: @color02; padding: .5em 20px; color: @color-white !important; position: relative; margin: 2.5em 0 0 0 !important; text-align: center; font-weight: bold !important;
		font-family: @font-heading;
		.ru & { font-family: @font-heading-ru; }
		&:before { position: absolute; font-weight: normal; left: 0; right: 0; bottom: 100%; width: 100%; font-size: 2em; line-height: 1; text-align: center; color: @color02; height: auto; margin-right: 0; }
	}
	&.muted { color: @color-gray-medium; } // Todo: Possibly unused selectors, remove permanently
	&.note {
		color: @color-gray-medium; /*font-size: 86%;*/ font-size: 13px; line-height: 1.5; margin: 0;
		LABEL { display: block; margin: 5px 0 0 0; }
		INPUT { margin: 0 4px 0 0; }
		&.error { color: @color-negative; }
		&.error-ico:before { font-family: "icons"; }
	}
	&.hasico {
		&:before { top: .25em; width: auto; height: auto; font-size: inherit; line-height: inherit; }
		&.ico-ok:before { top: .15em; }
		&.meta {
			margin: 0; font-size: 13px; color: @color-gray-medium; line-height: normal;
			&:before { margin-right: 10px; width: auto; margin-top: 2px; }
			&.ico-ok { color: @color-positive; }
		}
	}
	&.smaller { font-size: 80%; }
	&.img-recommend {
		background: @color-white url(recommend_et02.png) no-repeat 0 100%; padding: 10px 10px 10px 100px; text-align: center;
		IMG { max-width: 100%; height: auto; }
	}
	&.itb__text {
		font-weight: 400; font-size: 18px; line-height: 1.67;
		@media @media-mobile {
			font-size: 18px;
			line-height: 1.47;
		}
	}
}
A { text-decoration: none; color: @color01;
	&:focus { outline: thin dotted; }
	&:active,
	&:hover { outline: 0; text-decoration: underline; }
	&.help {
		display: inline-block; position: relative; top: .3em; cursor: pointer; line-height: 22px; width: 24px; height: 24px; overflow: hidden; text-align: center;  color: @color-gray-medium; font-size: 16px; font-weight: bold; border: 2px solid @color-gray-medium; .box-sizing(border-box); border-radius: 12px;
		SPAN { display: inline-block; text-indent: -10000px; width: 0; height: 0; }
		&:before { content: "i"; display: inline-block; width: 20px; height: 20px; line-height: 20px;  }
		&:hover { border-color: @color03; color: @color03; }
	}
	&.question {
		display: inline-block; position: relative; top: 2px; cursor: pointer; line-height: 14px; width: 14px; height: 14px; overflow: hidden; text-align: center; color: @color-gray-medium; font-weight: bold; border: 1px solid @color-gray-medium;  -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; border-radius: 14px; margin: 0 auto;
		SPAN { display: inline-block; text-indent: -10000px; width: 0; height: 0; }
		&:after { content: "?"; display: inline-block; width: 10px; height: 10px; line-height: 10px; font-size: 10px; text-align: center;  position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
		&:hover { border-color: @color03; color: @color03; }
		&-dark {
			color: @color-white; border: 1px solid @color01; background: @color01;
			&:hover {
				color: @color-white; border: 1px solid @color01; background: @color01;
			}
		}
		&-light {
			color: @color-white; border: 1px solid @color-gray-medium-light; background: @color-gray-medium-light;
			&:hover {
				color: @color-white; border: 1px solid @color-gray-medium-light; background: @color-gray-medium-light;
			}
		}
	}
	&.exclamation {
		line-height: 8px; display: inline-block; position: relative; top: 2px; cursor: pointer; /*line-height: 10px;*/ width: 14px; height: 14px; overflow: hidden; text-align: center; color: @color-gray-medium; font-weight: bold; border: 1px solid @color-gray-medium;  -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; border-radius: 14px; margin: 0 auto;
		SPAN { display: inline-block; text-indent: -10000px; width: 0; height: 0; }
		&:before { content: "!"; display: inline-block; width: 10px; height: 10px; line-height: 10px; font-size: 10px; text-align: center; }
		&:hover { border-color: @color03; color: @color03; }
		&-dark {
			color: @color-white; border: 1px solid @color01; background: @color01;
			&:hover {
				color: @color-white; border: 1px solid @color01; background: @color01;
			}
		}
		&-light {
			color: @color-white; border: 1px solid @color-gray-medium-light; background: @color-gray-medium-light;
			&:hover {
				color: @color-white; border: 1px solid @color-gray-medium-light; background: @color-gray-medium-light;
			}
		}
	}
	/*&[target=_blank],
	&.blank {
		&:after { !*content: @ico-blank; font-family: "icons";*! content: @ico-blank; font-family: "icons"; color: @color-gray-medium; font-size: 80%; margin-left: .5em; text-decoration: none; display: inline-block; }
	}
	&[target=_blank-link],
	&.blank-link {
		&:before { !*content: "\f35d"; font-family: FontAwesome;*! content: @ico-blank; font-family: "icons"; color: @color-blue02; font-size: 10px; margin-right: .5em; text-decoration: none; display: inline-block; }
	}*/
	&.download-link {
		&:before { content: "\f019"; font-family: FontAwesome;  color: @color-blue02; font-size: 10px; margin-right: .5em; text-decoration: none; display: inline-block; }
	}
	&.pdf {
		&:after { content: "PDF"; margin-left: .5em; padding: 1px 3px; color: @color-white; display: inline-block; background: @color02; .rounded(3px); font-size: 8px; position: relative; top: -4px; }
	}
	&.bdoc {
		&:after {
			content: "bdoc"; margin-left: .5em; padding: 1px 3px; color: @color-white; display: inline-block; background: @color01; .rounded(3px); font-size: 10px; position: relative; top: -4px; text-transform: uppercase; }
	}
	.bdoc {
		color: @color-gray09; background: @color-gray07; font-weight: 700; display: inline-block; position: relative; .rounded(3px); /*background: @color01;*/ padding: 2px 4px; /*color: @color-white;*/ margin: 0 5px; font-size: 10px; top: -4px;
		&:before { font-family: "icons"; color: @color-white; display: inline-block; font-size: 8px; margin-right: 2px; }
		&:after { content: "bdoc"; /*color: @color-white;*/ color: @color-gray09; display: inline-block; font-size: 10px; text-transform: uppercase;}
		&--blue {
			background: @color01;
		}
		&--orange {
			background: @color02;
		}
		&__download {
			&:before {
				content: @ico-download02;
			}
		}
		&__external {
			&:before {
				content: @ico-blank;
			}
		}
		&.bdoc--md {
			font-size: 13px;
		}
	}
	.doc {
		color: @color-gray09; font-weight: 700; background: @color-gray07; display: inline-block; position: relative; .rounded(3px); /*background: @color01;*/ padding: 2px 4px; /*color: @color-white;*/ margin: 0 5px; font-size: 10px;
		&--blue {
			background: @color01;
		}
		&--orange {
			background: @color02;
		}
		&__download {
			&:before {
				font-family: "icons";
				content: @ico-download02;
			}
		}
		&__external {
			&:before {
				font-family: "icons";
				content: @ico-blank;
			}
		}
		&.doc--md {
			font-size: 13px;
		}
		/*&--onright {
			&:before {

			}
		}
		&--onleft {
			&:after {

			}
		}*/
		&--pdf {
			&:before {
				content: "pdf";
			}
		}
		&--bdoc {
			&:before {
				content: "bdoc";
			}
		}
		&:before,
		&:after { /*font-family: "icons";*/ /*color: @color-white;*/ color: @color-gray09; font-weight: 700; display: inline-block; /*font-size: 10px;*/ text-transform: uppercase; }
	}
	&.xlsx {
		&:after { content: "XLSX"; margin-left: .5em; padding: 1px 3px; color: @color-white; display: inline-block; background: @color01; .rounded(3px); font-size: 8px; position: relative; top: -4px; }
	}
	&.offset { position: absolute; top: -40px; }
	&.more01 {
		@media @media-notmobile { display: none; }
		@media @media-mobile {
			display: block; border-bottom: 2px dashed @color-gray-light; text-align: center; margin: 1em 0; text-decoration: none !important;
			&:before { content: @ico-arrdown; font-family: "icons"; margin-right: 5px; }
			&.open:before { content: @ico-arrup; }
		}

	}
}
PRE {
	font-family: Monaco, Consolas, 'Lucida Console', 'Courier New', monospace; font-size: 10px; white-space: pre-wrap; word-wrap: break-word; margin: 1em 0 0; color: @color-gray-medium; font-style: normal; font-variant: normal; font-weight: normal; letter-spacing: normal; line-height: 21px; orphans: auto; text-align: start; text-indent: 0; text-transform: none; widows: auto; word-spacing: 0; -webkit-text-stroke-width: 0; border: 1px solid @color-gray-lightest; padding: 10px 15px; background-color: lighten(@color-gray-lightest,3%);
}
HR {
	border: 2px @color-gray-medium-lightest; border-style: dotted none none; margin-top: 2em;
	&.type02 { border-top: 4px solid @color02; }
	&.type03 { border-top: 2px dashed @color-gray-light; }
	&.type04 { border-top: 2px solid @color-gray-light; }
	&.type05 { border-top: 2px dashed @color-gray-light; float: left; margin-top: 1em; width: 380px; @media @media-mobile {width: 160px;} }
	&.type06 { border-top: 1px solid @color-gray-light; }
}
.text-upper {
	text-transform: uppercase;
}
/*box-shadow*/
/*.shadow01 {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
	-moz-box-shadow:    0 1px 3px 0 rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
}*/

.badge03 {
	background: @color02; color: @color-white; position: absolute; left: 0; top: 30px; padding: 10px 5px; font-size: 14px; width: 132px; font-weight: bold; text-align: center; .box-sizing(border-box);
	/*.size01 {}*/
}

UL.countrylist01 {
	list-style: none;
	LI {
		position: relative; padding-left: 35px; margin-top: 5px !important; font-size: 15px;
		&:before { display: block; content: ""; position: absolute; left: 10px; top: 3px; width: 20px; height: 20px; background: no-repeat 50% 3px; }
		&.selected { font-weight: bold; color: @color-gray01; }
		&.ad:before { background-image: url(flags/ad.png); }
		&.ae:before { background-image: url(flags/ae.png); }
		&.af:before { background-image: url(flags/af.png); }
		&.ag:before { background-image: url(flags/ag.png); }
		&.ai:before { background-image: url(flags/ai.png); }
		&.al:before { background-image: url(flags/al.png); }
		&.am:before { background-image: url(flags/am.png); }
		&.an:before { background-image: url(flags/an.png); }
		&.ao:before { background-image: url(flags/ao.png); }
		&.aq:before { background-image: url(flags/aq.png); }
		&.ar:before { background-image: url(flags/ar.png); }
		&.as:before { background-image: url(flags/as.png); }
		&.az:before { background-image: url(flags/az.png); }
		&.at:before { background-image: url(flags/at.png); }
		&.au:before { background-image: url(flags/au.png); }
		&.aw:before { background-image: url(flags/aw.png); }
		&.ba:before { background-image: url(flags/ba.png); }
		&.bb:before { background-image: url(flags/bb.png); }
		&.bd:before { background-image: url(flags/bd.png); }
		&.be:before { background-image: url(flags/be.png); }
		&.bf:before { background-image: url(flags/bf.png); }
		&.bg:before { background-image: url(flags/bg.png); }
		&.bh:before { background-image: url(flags/bh.png); }
		&.bi:before { background-image: url(flags/bi.png); }
		&.bj:before { background-image: url(flags/bj.png); }
		&.bm:before { background-image: url(flags/bm.png); }
		&.bn:before { background-image: url(flags/bn.png); }
		&.bo:before { background-image: url(flags/bo.png); }
		&.br:before { background-image: url(flags/br.png); }
		&.bs:before { background-image: url(flags/bs.png); }
		&.bz:before { background-image: url(flags/bz.png); }
		&.bt:before { background-image: url(flags/bt.png); }
		&.bw:before { background-image: url(flags/bw.png); }
		&.by:before { background-image: url(flags/by.png); }
		&.ca:before { background-image: url(flags/ca.png); }
		&.cd:before { background-image: url(flags/cd.png); }
		&.cf:before { background-image: url(flags/cf.png); }
		&.cg:before { background-image: url(flags/cg.png); }
		&.ch:before { background-image: url(flags/ch.png); }
		&.ci:before { background-image: url(flags/ci.png); }
		&.cis:before { background-image: url(flags/cis.png); }
		&.ck:before { background-image: url(flags/ck.png); }
		&.cl:before { background-image: url(flags/cl.png); }
		&.cm:before { background-image: url(flags/cm.png); }
		&.cn:before { background-image: url(flags/cn.png); }
		&.co:before { background-image: url(flags/co.png); }
		&.cr:before { background-image: url(flags/cr.png); }
		&.cz:before { background-image: url(flags/cz.png); }
		&.cu:before { background-image: url(flags/cu.png); }
		&.cv:before { background-image: url(flags/cv.png); }
		&.cy:before { background-image: url(flags/cy.png); }
		&.de:before { background-image: url(flags/de.png); }
		&.dj:before { background-image: url(flags/dj.png); }
		&.dk:before { background-image: url(flags/dk.png); }
		&.dm:before { background-image: url(flags/dm.png); }
		&.do:before { background-image: url(flags/do.png); }
		&.dz:before { background-image: url(flags/dz.png); }
		&.ec:before { background-image: url(flags/ec.png); }
		&.ee:before { background-image: url(flags/ee.png); }
		&.eg:before { background-image: url(flags/eg.png); }
		&.eh:before { background-image: url(flags/eh.png); }
		&.er:before { background-image: url(flags/er.png); }
		&.es:before { background-image: url(flags/es.png); }
		&.et:before { background-image: url(flags/et.png); }
		&.eu:before { background-image: url(flags/eu.png); }
		&.fi:before { background-image: url(flags/fi.png); }
		&.fj:before { background-image: url(flags/fj.png); }
		&.fm:before { background-image: url(flags/fm.png); }
		&.fo:before { background-image: url(flags/fo.png); }
		&.fr:before { background-image: url(flags/fr.png); }
		&.ga:before { background-image: url(flags/ga.png); }
		&.gb:before { background-image: url(flags/gb.png); }
		&.gd:before { background-image: url(flags/gd.png); }
		&.ge:before { background-image: url(flags/ge.png); }
		&.gf:before { background-image: url(flags/gf.png); }
		&.gg:before { background-image: url(flags/gg.png); }
		&.gh:before { background-image: url(flags/gh.png); }
		&.gi:before { background-image: url(flags/gi.png); }
		&.gm:before { background-image: url(flags/gm.png); }
		&.gn:before { background-image: url(flags/gn.png); }
		&.gq:before { background-image: url(flags/gq.png); }
		&.gr:before { background-image: url(flags/gr.png); }
		&.gt:before { background-image: url(flags/gt.png); }
		&.gu:before { background-image: url(flags/gu.png); }
		&.gw:before { background-image: url(flags/gw.png); }
		&.gy:before { background-image: url(flags/gy.png); }
		&.hk:before { background-image: url(flags/hk.png); }
		&.hn:before { background-image: url(flags/hn.png); }
		&.hr:before { background-image: url(flags/hr.png); }
		&.ht:before { background-image: url(flags/ht.png); }
		&.hu:before { background-image: url(flags/hu.png); }
		&.id:before { background-image: url(flags/id.png); }
		&.ie:before { background-image: url(flags/ie.png); }
		&.il:before { background-image: url(flags/il.png); }
		&.im:before { background-image: url(flags/im.png); }
		&.in:before { background-image: url(flags/in.png); }
		&.iq:before { background-image: url(flags/iq.png); }
		&.ir:before { background-image: url(flags/ir.png); }
		&.is:before { background-image: url(flags/is.png); }
		&.it:before { background-image: url(flags/it.png); }
		&.je:before { background-image: url(flags/je.png); }
		&.jm:before { background-image: url(flags/jm.png); }
		&.jo:before { background-image: url(flags/jo.png); }
		&.jp:before { background-image: url(flags/jp.png); }
		&.ke:before { background-image: url(flags/ke.png); }
		&.kg:before { background-image: url(flags/kg.png); }
		&.kh:before { background-image: url(flags/kh.png); }
		&.ki:before { background-image: url(flags/ki.png); }
		&.km:before { background-image: url(flags/km.png); }
		&.kn:before { background-image: url(flags/kn.png); }
		&.kp:before { background-image: url(flags/kp.png); }
		&.kr:before { background-image: url(flags/kr.png); }
		&.kz:before { background-image: url(flags/kz.png); }
		&.kw:before { background-image: url(flags/kw.png); }
		&.ky:before { background-image: url(flags/ky.png); }
		&.la:before { background-image: url(flags/la.png); }
		&.lb:before { background-image: url(flags/lb.png); }
		&.lc:before { background-image: url(flags/lc.png); }
		&.li:before { background-image: url(flags/li.png); }
		&.lk:before { background-image: url(flags/lk.png); }
		&.lr:before { background-image: url(flags/lr.png); }
		&.ls:before { background-image: url(flags/ls.png); }
		&.lt:before { background-image: url(flags/lt.png); }
		&.lu:before { background-image: url(flags/lu.png); }
		&.lv:before { background-image: url(flags/lv.png); }
		&.ly:before { background-image: url(flags/ly.png); }
		&.ma:before { background-image: url(flags/ma.png); }
		&.mc:before { background-image: url(flags/mc.png); }
		&.md:before { background-image: url(flags/md.png); }
		&.me:before { background-image: url(flags/me.png); }
		&.mg:before { background-image: url(flags/mg.png); }
		&.mh:before { background-image: url(flags/mh.png); }
		&.mk:before { background-image: url(flags/mk.png); }
		&.ml:before { background-image: url(flags/ml.png); }
		&.mm:before { background-image: url(flags/mm.png); }
		&.mn:before { background-image: url(flags/mn.png); }
		&.mo:before { background-image: url(flags/mo.png); }
		&.mr:before { background-image: url(flags/mr.png); }
		&.ms:before { background-image: url(flags/ms.png); }
		&.mz:before { background-image: url(flags/mz.png); }
		&.mt:before { background-image: url(flags/mt.png); }
		&.mu:before { background-image: url(flags/mu.png); }
		&.mv:before { background-image: url(flags/mv.png); }
		&.mw:before { background-image: url(flags/mw.png); }
		&.mx:before { background-image: url(flags/mx.png); }
		&.my:before { background-image: url(flags/my.png); }
		&.na:before { background-image: url(flags/na.png); }
		&.ne:before { background-image: url(flags/ne.png); }
		&.ng:before { background-image: url(flags/ng.png); }
		&.ni:before { background-image: url(flags/ni.png); }
		&.nl:before { background-image: url(flags/nl.png); }
		&.no:before { background-image: url(flags/no.png); }
		&.np:before { background-image: url(flags/np.png); }
		&.nr:before { background-image: url(flags/nr.png); }
		&.nz:before { background-image: url(flags/nz.png); }
		&.om:before { background-image: url(flags/om.png); }
		&.pa:before { background-image: url(flags/pa.png); }
		&.pe:before { background-image: url(flags/pe.png); }
		&.pg:before { background-image: url(flags/pg.png); }
		&.ph:before { background-image: url(flags/ph.png); }
		&.pk:before { background-image: url(flags/pk.png); }
		&.pl:before { background-image: url(flags/pl.png); }
		&.pr:before { background-image: url(flags/pr.png); }
		&.ps:before { background-image: url(flags/ps.png); }
		&.pt:before { background-image: url(flags/pt.png); }
		&.pw:before { background-image: url(flags/pw.png); }
		&.py:before { background-image: url(flags/py.png); }
		&.qa:before { background-image: url(flags/qa.png); }
		&.ro:before { background-image: url(flags/ro.png); }
		&.rs:before { background-image: url(flags/rs.png); }
		&.ru:before { background-image: url(flags/ru.png); }
		&.rw:before { background-image: url(flags/rw.png); }
		&.sa:before { background-image: url(flags/sa.png); }
		&.sb:before { background-image: url(flags/sb.png); }
		&.sc:before { background-image: url(flags/sc.png); }
		&.sd:before { background-image: url(flags/sd.png); }
		&.se:before { background-image: url(flags/se.png); }
		&.sg:before { background-image: url(flags/sg.png); }
		&.si:before { background-image: url(flags/si.png); }
		&.sk:before { background-image: url(flags/sk.png); }
		&.sl:before { background-image: url(flags/sl.png); }
		&.sm:before { background-image: url(flags/sm.png); }
		&.sn:before { background-image: url(flags/sn.png); }
		&.so:before { background-image: url(flags/so.png); }
		&.sr:before { background-image: url(flags/sr.png); }
		&.sz:before { background-image: url(flags/sz.png); }
		&.st:before { background-image: url(flags/st.png); }
		&.sv:before { background-image: url(flags/sv.png); }
		&.sy:before { background-image: url(flags/sy.png); }
		&.za:before { background-image: url(flags/za.png); }
		&.zm:before { background-image: url(flags/zm.png); }
		&.zw:before { background-image: url(flags/zw.png); }
		&.tc:before { background-image: url(flags/tc.png); }
		&.td:before { background-image: url(flags/td.png); }
		&.tg:before { background-image: url(flags/tg.png); }
		&.th:before { background-image: url(flags/th.png); }
		&.tj:before { background-image: url(flags/tj.png); }
		&.tl:before { background-image: url(flags/tl.png); }
		&.tm:before { background-image: url(flags/tm.png); }
		&.tn:before { background-image: url(flags/tn.png); }
		&.to:before { background-image: url(flags/to.png); }
		&.tp:before { background-image: url(flags/tp.png); }
		&.tr:before { background-image: url(flags/tr.png); }
		&.tz:before { background-image: url(flags/tz.png); }
		&.tt:before { background-image: url(flags/tt.png); }
		&.tv:before { background-image: url(flags/tv.png); }
		&.tw:before { background-image: url(flags/tw.png); }
		&.ua:before { background-image: url(flags/ua.png); }
		&.ug:before { background-image: url(flags/ug.png); }
		&.uk:before { background-image: url(flags/uk.png); }
		&.un:before { background-image: url(flags/un.png); }
		&.us:before { background-image: url(flags/us.png); }
		&.uz:before { background-image: url(flags/uz.png); }
		&.uy:before { background-image: url(flags/uy.png); }
		&.va:before { background-image: url(flags/va.png); }
		&.vc:before { background-image: url(flags/vc.png); }
		&.ve:before { background-image: url(flags/ve.png); }
		&.vg:before { background-image: url(flags/vg.png); }
		&.vi:before { background-image: url(flags/vi.png); }
		&.vn:before { background-image: url(flags/vn.png); }
		&.ws:before { background-image: url(flags/ws.png); }
		&.vu:before { background-image: url(flags/vu.png); }
		&.ye:before { background-image: url(flags/ye.png); }
	}
	@media @media-mobile {
		&.countrylist01b {
			LI {
				&:nth-of-type(5) ~ LI { display: none; }
				&.selected {
					display: block !important;
					&:nth-child(-n+5) {
						~ LI {
							&:nth-of-type(6) { display: block !important; }
						}
					}
				}
				&:before { top: 5px; }
			}
			&.open {
				LI:nth-of-type(5) ~ LI { display: block; }
			}
			&.in3cols {
				.clearfix(); .display(flex); .flex-wrap(wrap);
				LI {
					float: left; width: 48%; margin: 5px 2% 0 0; .flex(0 0 48%);
					&:nth-of-type(3n+1) { clear: none; }
				}
			}
		}
	}
}
.title {
	&--dark {
		color: @color-gray01;
	}
	&--bold {
		font-weight: 700;
	}
	&--semibold {
		font-weight: 600;
	}
	&--note {
		font-size: 15px; font-family: @font-main; font-weight: 400; line-height: 1.4; color: @color-gray03;
	}
	&--small {
		font-size: 13px; font-family: @font-main; font-weight: 400; line-height: 1.54;
	}
}
.text {
	&--dark {
		color: @color-gray01;
	}
	&--orange {
		color: @color-orange01;
	}
	&--bold {
		font-weight: 700;
	}
	&--semibold {
		font-weight: 600;
	}
	&--note {
		font-size: 15px; font-family: @font-main; font-weight: 400; line-height: 1.4; color: @color-gray03;
	}
	&--small {
		font-size: 13px; font-family: @font-main; font-weight: 400; line-height: 1.54;
	}
}
.border {
	&--top {
		border-top: 1px solid @color-gray05;
	}
	&--bottom {
		border-bottom: 1px solid @color-gray05;
		&-dashed {
			border-bottom: 1px dashed @color-gray05;
		}
	}
	&--left {
		border-left: 1px solid @color-gray05;
		&-dashed {
			border-left: 1px dashed @color-gray05;
		}
	}
	&--right {
		border-right: 1px solid @color-gray05;
	}
	&--no {
		border: none;
	}
}
.nobackground {
	background: none!important;
}
.row.is-flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	> [class*='col-'] {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}
}
@media @media-mobile {
	.col-xs-auto { width: auto; }
}
@media @media-tablet {
	.col-sm-auto { width: auto; }
}
@media @media-desktop {
	.col-md-auto { width: auto; }
}
@media @media-hd {
	.col-lg-auto { width: auto; }
}
.ml-auto {
	margin-left: auto;
}
.itb-text {
	&--disabled {
		opacity: 0.4;
		&:hover,
		&:focus,
		&:active {
			cursor: default;
			opacity: 0.4;
		}
	}
	&__note {
		font-size: 15px; font-family: @font-main; font-weight: 400; line-height: 1.4; color: @color-gray03;
	}
	&--light {
		color: @color-gray03;
	}
}
.title {
	&--dark {
		color: @color-gray01;
	}
	&--bold {
		font-weight: 700;
	}
	&--semibold {
		font-weight: 600;
	}
	&--note {
		font-size: 15px; font-family: @font-main; font-weight: 400; line-height: 1.4; color: @color-gray03;
	}
	&--small {
		font-size: 13px; font-family: @font-main; font-weight: 400; line-height: 1.54; color: @color-gray01;
	}
	&--light {
		color: @color-gray03;
	}
}
/*gutters*/
.no-gutters-md {
	margin-right: 0;
	margin-left: 0;
	& > .col,
	[class*='col-'] {
		@media @media-notdesktop {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.gutter-5  {
	margin-right: -5px;
	margin-left: -5px;
	& > .col,
	[class*='col-'] {
		padding-right: 5px;
		padding-left: 5px;
		@media @media-notdesktop {
		}
	}
}



.gutter-10  {
	margin-right: -10px;
	margin-left: -10px;
	& > .col,
		[class*='col-'] {
		padding-right: 10px;
		padding-left: 10px;
		@media @media-notdesktop {
		}
	}
}

.gutter-20  {
	margin-right: -20px;
	margin-left: -20px;
	& > .col,
		[class*='col-'] {
		padding-right: 20px;
		padding-left: 20px;
		@media @media-notdesktop {
		}
	}
}

.gutter-30  {
	margin-right: -30px;
	margin-left: -30px;
	& > .col,
		[class*='col-'] {
		padding-right: 30px;
		padding-left: 30px;
		@media @media-notdesktop {
		}
	}
}


