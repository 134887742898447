.toggler-switch {
	&.toggler-switch__flex {
		width: 100%;
		.box-sizing(border-box);
		.display(flex);
		&.toggler-switch__flex--flex-end {
			.justify-content(flex-end);
		}
		&.toggler-switch__flex--flex-start {
			.justify-content(flex-start);
		}
	}
	&__text {
		font-size: 15px;
		font-family: @font-main;
		color:@color-gray01;
		font-weight: 400;
		line-height: 1.4;
		&--active {
			font-weight: 700;
		}
	}
	.toggler-switch__slider {
		position: relative;
		display: inline-block;
		/*width: 60px;*/
		width: 42px;
		height: 20px;
		.link-outline-none;
		INPUT {
			display: none;
		}
		.toggler-switch__handle {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 47px;
			height: 20px;
			background-color: @color-white;
			border: 1px solid @color-red01;
			-webkit-transition: .4s;
			transition: .4s;
			&.disabled {
				opacity: .4; cursor: default;
			}
			&:before {
				position: absolute;
				content: "";
				left: -4px;
				top: -6px;
				width: 30px;
				height: 30px;
				background-color: @color-red01;
				-webkit-transition: .4s;
				transition: .4s;
				box-shadow: 0 1px 1px 0 @color-gray05;
			}
			&.toggler-switch__handle--round {
				border-radius: 10px;
				&:before {
					border-radius: 50%;
				}
			}
			&.toggler-switch__handle--dispatcher {
				background-color: @color-orange03;
				border: 1px solid @color-orange03;
				&:before {
					background-color: @color-orange02;
				}
			}
		}
		INPUT {
			&:checked {
				&+.toggler-switch__handle {
					background-color: @color-white;
					border: 1px solid @color-green01;
					&:before {
						-webkit-transform: translateX(26px);
						-ms-transform: translateX(26px);
						transform: translateX(26px);
						background-color: @color-green01;
					}
					&.toggler-switch__handle--dispatcher {
						background-color: @color-orange03;
						border: 1px solid @color-orange03;
						&:before {
							background-color: @color-orange02;
						}
					}
				}
			}
		}
		&--mrg {
			margin: 0 20px;
		}
	}
	&--small {
		.toggler-switch__slider {
			height: 15px;
			width: 30px;
			.toggler-switch__handle {
				width: 30px;
				height: 15px;
				&:before {
					left: -10px;
					top: -5px;
					width: 23px;
					height: 23px;
				}
			}
		}
		.toggler-switch__text {
			line-height: 15px;
		}
	}
}
