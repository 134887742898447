.expandable-table {
	&__header {
		background: @color-gray07;
	}
	&__header__item {
		display: inline-block;
	}
	&__wrapper {
		border: 1px solid @color-gray06;
	}
}
.expandable-content-mobile-list  {
	&__item {

	}
}
.expandable-content {
	font-family: @font-main;
	position: relative;
	border: 1px @color-gray06;
	border-style: none none solid none;
	.link-outline-none;
	background: @color-white;
	.expandable-content__pad {
		&--table {
			padding: 10px 10px 10px 50px;
		}
		&--sm {
			padding: 10px;
		}
		&--md {
			padding: 23px 50px 26px 24px;
		}
	}
	&.expandable-content--bordered {
		border-style: solid solid solid solid;
	}
	&.expandable-content--bordered-no-top {
		border-style: none solid solid solid;
	}
	&.expandable-content--noborder {
		border: 0;
	}
	&__wrapper {
		display: inline-block;
		width: 100%;
	}
	&__text-wrapper {
		display: inline-block;
	}
	&__meta {
		font-size: 13px;
		font-weight: 400;
		line-height: 1.54;
		letter-spacing: normal;
		color: @color-gray03;
	}
	&__title {
		font-size: 15px;
		font-weight: 700;
		line-height: 1.47;
		letter-spacing: normal;
		color: @color-gray01;
	}
	&__text {
		font-size: 15px;
		line-height: 1.47;
		letter-spacing: normal;
		color: @color-gray01;
		&--bold {
			font-weight: 700;
		}
	}
	&__step {
		display: inline-block;
		width: 24px;
		height: 24px;
		background: @color-gray07;
		color: @color-gray01;
		border: 2px solid @color-gray06;
		.rounded(50%);
		text-align: center;
		font-family: @font-main;
		line-height: 21px;
		font-size: 13px;
		font-weight: 700;
		vertical-align: top;
		margin: 0 9px 0 0;
		&.expandable-content__step--active {
			background: @color-white;
			color: @color-gray01;
			border: 2px solid @color-green01;
		}
		&.expandable-content__step--confirmed {
			background: @color-white;
			color: @color-green03;
			border: 2px solid @color-green01;
			&::before {
				content: "\f00c";
				font-family: FontAwesome;
				font-size: 13px;
				color: @color-green03;
			}
		}
	}
	&__header {
		/*background: @color-white;*/
		border: none;
		font-size: 15px;
		font-weight: 400;
		line-height: 22px;
		background: @color-white;
		.link-outline-none;
		.text-decoration-none;
		&.expandable-content__header--bg {
			background: @color-gray07;
		}
	}
	&__icon {
		color: @color-blue02;
		font-size: 22px;
		vertical-align: middle;
		padding-right: 10px;
		line-height: 1;
	}
	&__remove {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 10px 5px;
		float: none;
		vertical-align: top;
		&.expandable-content__remove--w100p {
			width: 100%;
		}
		&::before {
			text-decoration: none;
			content: "\f00d";
			font-family: FontAwesome;
			font-size: 14px;
			color: @color-gray05;
			//margin-right: 10px;
			opacity: 1;
			transition: opacity 0.3s ease;
		}
	}
	&__toggler {
		cursor: pointer;
		color: @color-gray01;
		min-height: 52px;
		width: 100%;
		padding: 10px 10px 10px 50px;
		display: inline-block;
		box-sizing: border-box;
		position: relative;
		float: none;
		&::before {
			position: absolute;
			top: 10px;
			left: 10px;
		}
		&--right {
			padding: 10px 50px 10px 10px;
			&::before {
				right: 10px;
				left: auto;
			}
		}
		&::before {
			text-decoration: none;
			content: "\f107";
			color: @color-gray01;
			font-family: 'FontAwesome';
			transition: all 0.4s ease;
			height: auto;
			width: auto;
			font-size: 16px;
		}
		@media @media-notdesktop {
			min-height: auto;
		}
	}
	&__item {
		/*display: inline-block;*/
		width: 20%;
		.flex(0 0 auto);
		padding: 0 20px 0 0;
		vertical-align: text-top;
		&.expandable-content__item--middle {
			vertical-align: middle;
		}
	}
	&__content {
		.link-outline-none;
		.text-decoration-none;
		.expandable-content__item {
			//padding: 10px 20px 10px 0;
			padding: 0 20px 0 0;
		}
	}
	&--has-btn {
		.expandable-content__toggler {
			//display: inline-block;
			width: calc(~"(100% - 40px)");
		}
		.expandable-content__wrapper {
			//display: inline-block;
			width: calc(~"(100% - 40px)");
		}
	}
	&--has-icon {
		.expandable-content__toggler {
			padding: 23px 50px 26px 50px;
		}
		.expandable-content__wrapper {

		}
	}
	&.open {
		&:first-child {
			border-style: solid solid solid solid;
		}
		border-left: 2px @color-blue02;
		border-right: 2px @color-blue02;
		border-style: none solid solid solid;
		/*margin: 0 -1px;*/
		> .expandable-content__header {
			/*background: none;*/
			border: none;
			/*background: @color-white;*/
			&.expandable-content__header--bg {
				background: @color-white;
			}
			.expandable-content__toggler {
				border: none;
				font-weight: 700;
				&::before {
					transform: rotate(180deg);
				}
			}
			/*&--has-icon {

				padding: 0;

			}*/
		}

		.expandable-content__content {
			overflow: hidden;
			height: 0;
			/*opacity: 0;
			max-height: 0;
			transition: all .4s ease;*/
			.col00 {
				width: auto;
				padding: 0;
			}
			&.open  {
				height: auto;
				overflow: visible;
				transition: all .4s ease;
				/*opacity: 1;
				max-height: 500px;*/
			}
		}
		&.expandable-content--mobile-neg {
			@media @media-notdesktop {
				border-style: solid none;
				padding: 0 10px;
				margin: -20px;
			}
		}
	}
	&--mobile-neg {
		@media @media-notdesktop {
			border-style: solid none;
			padding: 0 10px;
			margin: -20px;
		}
		.open & {

		}
	}
	&.expandable-content--secondary {
		.expandable-content__header {
			padding: 0;
		}
		.expandable-content__toggler {
			padding: 23px 50px 26px 24px;
		}
		.expandable-content__wrapper {
			padding: 3px 24px 25px 24px;
		}
		.expandable-content__toggler--right {
			padding: 23px 50px 26px 24px;
			&::before {
				right: 15px;
				top: 32px;
			}
		}
	}
}
.expandable-content-list {
	.display(flex);
	.flex-direction(row);
	.box-sizing(border-box);
	.align-items(flex-start);
	.flex-wrap(nowrap);
	&__item {
		width: 20%;
		vertical-align: text-top;
		padding: 0 20px 0 0;
	@media-notdesktop {
		.flex-direction(column);
		width: 100%;
		padding: 0 0 15px 0 ;
	}
	}
	/*&__pad {
		padding: 0 20px 0 0;
		@media-notdesktop {
			padding: 0 0 15px 0 ;
		}
	}*/
	&__pad-mobile-md {
		@media @media-mobile {
			margin: 0 15px;
		}
	}
	@media @media-notdesktop {
		.flex-direction(column);
	}
	&--mobile {
		.flex-direction(column);
		.expandable-content-list__item {
			width: 100%;
			padding: 0 0 15px 0 ;
		}
	}
}