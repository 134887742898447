// Core variables and mixins
@import (reference) "variables.less";
@import (reference) "mixins.less";
@import (reference) "mixins/anchor.less";
@import (reference) "mixins/flexbox.less";
@import "mixins/zindex_mixins.less";

// Reset and dependencies
@import (reference) "../fonts/Icons/icons.less";
@import (reference) "normalize.less";
@import (reference) "opus.reset.less";
@import (reference) "opus.utils.less";
@import (reference) "print.less";
@import "_supported.less";
@import "component_styles.less";


#logo01 {
	font-family: desktop;
	@media @media-mobile {
		font-family: mobile;
	}
	@media @media-tablet {
		font-family: tablet;
	}
	@media @media-wide {
		font-family: wide;
	}
}

@import "_typography.less";

/* 2. General frame parts */
@import "_layout.less";
@import "_content.less";

@import "_search.less";
@import "_tables.less";

/* 4. Headers */
@import "_header_old.less";
/*@import "_header.less";*/

/* 4.4 Menus */
@import "_menus.less";

/* 4.5 Loader */
@import "_loader.less";

/* 5. Footers */
@import "_footers.less";

/* Tugiinfo */
@import "_support.less";

@import "_tb.less";

/* Inputs */
@import "_inputs.less";

/* banners */
@import "_banners.less";

/*Animations*/
@import "_animations.less";

/* 7. Form */

.form01 {
	DIV.row {
		clear: both; /*overflow: hidden;*/
		.clear();
		padding: 0;
		position: relative;
		margin: 1em 0 0 0;
		.clearfix();
		&.clear {
			overflow: visible;
		}
		&.stripetop {
			border-top: 1px solid @color-gray-medium-lightest;
			margin-top: 1em;
			padding-top: .5em;
		}
		.progress03 .summary & {
			SPAN.label {
				padding-left: 15px;
			}
		}
		&.expander {
			cursor: pointer;
			position: relative;
			overflow: visible !important;
			margin-top: 5px;
			.progress03 .summary & {
				margin-top: 0;
				SPAN.label {
					padding-left: 25px;
				}
				SPAN.label:before {
					background: @color-gray06;
					font-size: 8px;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					text-align: center;
					line-height: 20px;
					/*display: inline-block;*/

					color: @color-blue01;
					position: absolute;
					top: 1em;
					left: 0;
				}
			}
			/*checkthisout*/
			&--arrows {
				SPAN.label:before,
				LABEL:before {
					font-family: "icons";
					content: @ico-arrdown;
					width: 8px;
					height: 8px;
					font-size: 11px;
					color: @color-gray-medium;
					float: left;
					text-align: center;
					line-height: 14px;
					position: relative;
					margin-right: 6px;
					top: .3em;
				}
				&.open {
					SPAN.label:before,
					LABEL:before {
						content: @ico-arrup;
						line-height: 14px;
					}
				}

			}
			&--toggler {
				/*SPAN.label:before,
				LABEL:before { font-family:"icons"; content: @ico-arrdown; width: 8px; height: 8px; font-size: 11px; color: @color-gray-medium; float: left; text-align: center; line-height: 14px; position: relative; margin-right: 6px; top: .3em; }
				&.open {
					SPAN.label:before,
					LABEL:before { content: @ico-arrup; line-height: 14px; }
				}*/

			}
			SPAN.label:before,
			LABEL:before {
				font-family: "icons";
				content: @ico-arrdown;
				width: 8px;
				height: 8px;
				font-size: 11px;
				color: @color-gray-medium;
				float: left;
				text-align: center;
				line-height: 14px;
				position: relative;
				margin-right: 6px;
				top: .3em;
			}
			&.open {
				SPAN.label:before,
				LABEL:before {
					content: @ico-arrup;
					line-height: 14px;
				}
			}
			+ DIV {
				position: relative;
				top: -5px;
			}
			&__color {
				&--blue {
					SPAN.label:before,
					LABEL:before {
						color: @color-blue01;
						font-weight: 400;
						margin-right: 12px;
					}
				}
			}
		}
		&.expandable {
			margin: 0 0 0 5px;
			color: @color-gray-medium;
			LABEL,
			SPAN.label {
				padding-left: 14px !important;
				.box-sizing(border-box);
			}
		}
		&.type02 {
			LABEL,
			SPAN.label {
				width: 100% !important;
				margin-right: -65px !important;
				padding-right: 65px !important;
				.box-sizing(border-box);
				&.wauto {
					width: auto !important;
					margin-right: 0 !important;
					padding-right: 0 !important;
				}
			}
			DIV.items {
				width: 65px !important;
				&.wauto {
					width: auto !important;
				}
			}
		}
		&.inline {
			LABEL, SPAN.label {
				width: 80px !important;
			}
			DIV.items {
				float: none !important;
				width: 100% !important;
				padding-left: 90px;
			}
		}
		.input-wrapper {
			.currency {
				INPUT {
					margin: 0;
				}
			}
		}
		&.type03 {
			&.ml50 {
				margin-left: 50px;
			}
			.right {
				text-align: right !important;
			}
			> LABEL,
			> SPAN.label { /*width: 190px !important;*/
				width: 210px !important;
				margin: 0 !important;
				position: relative;
				z-index: 2;
			}
			DIV.items {
				width: 100% !important; /*margin-left: -190px !important;*/
				margin-left: -210px !important; /*padding-left: 200px;*/
				padding-left: 220px;
				.box-sizing(border-box);
				font-weight: normal !important;
				SPAN.text {
					font-size: 14px;
					font-weight: bold;
					SMALL {
						font-weight: normal;
						color: @color-gray-medium;
					}
				}
			}
		}
		.ico-ok {
			&:before {
				display: inline-block;
			}
		}
		.width-label-input(@labelwidth, @inputwidth) {
			LABEL, SPAN.label {
				width: @labelwidth;
			}
			div.items {
				width: @inputwidth;
			}
		}
		&.type06 {
			.width-label-input(18%, 80%);
			&.type06b {
				.width-label-input(36%, 62%);
				UL.actions01 {
					LI {
						A.btn {
							&.remove {
								text-indent: -10000px;
								font-weight: bold;
							}
						}
					}
				}
			}
			.mt0 {
				margin-top: 0;
			}
			.ico-ok {
				&:before {
					display: inline-block;
				}
			}
			UL.actions01 {
				LI {
					margin: 0.5em 0 0 0 !important;
					border: none;
					width: 100%;
					display: inline-block;
					INPUT {
						/*width: 84%;*/
						margin-left: 0;
						float: left;
						/*&.max { width: 100%; }*/
					}
					A.btn {
						&.add,
						&.remove {
							width: auto;
							float: left;
							display: inline-block;
							position: relative;
							margin: 0 !important;
							padding: 0 !important;
						}
						&.remove {
							/*text-indent: -10000px;*/
							/*font-weight: bold;*/
							left: 0.5em;
							top: 0.8em;
							&:before {
								text-indent: 0;
							}
						}
					}
				}
			}
			@media @media-mobile {
				.width-label-input(100%, 100%);
				DIV.items {
					UL.actions01 {
						LI {
							INPUT {
								width: 92%;
								margin-left: 0;
								float: left;
							}
							A.btn {
								&.remove {
									text-indent: -10000px;
								}
							}
						}
					}
				}
			}
		}
		.form__label {
			text-align: left;
			font-weight: 400;
			font-family: @font-main;
			color: @color-gray01;
			font-size: 15px;
			margin: 0 4% 0 0;
			.box-sizing(border-box);
			&--right {
				text-align: right;
			}
			&--wauto {
				width: auto;
			}
			&--bold {
				font-weight: 700;
			}
			&--input-md {
				padding: 15px 0 0 0;
			}
			&--small {
				font-size: 13px;
			}
			&--withicon {
				.display(flex);
			}
			&--fw700 {
				font-weight: 700;
			}
			&--fw600 {
				font-weight: 600;
			}

		}
		.form__text {
			text-align: left;
			font-weight: 400;
			font-family: @font-main;
			color: @color-gray01;
			font-size: 15px;
			margin: 0 4% 0 0;
			.box-sizing(border-box);
			&.form__text--right {
				text-align: right;
			}
			&.form__text--small {
				font-size: 13px;
			}
			&.form__text--fw700 {
				font-weight: 700;
			}
		}
		&.type04 {
			.medium {
				width: 62%;
			}
			.width-label-input(32%,65%);
			/*LABEL*/
			LABEL,
			SPAN.label {
				/*width: 32% !important;*/
				.box-sizing(border-box);
				/*padding: 16px 0 0;*/
				&.right {
					text-align: right;
				}
				&.wauto {
					width: auto;
				}
			}
			DIV.items {
				/*width: 65% !important;*/
				.box-sizing(border-box);
				&.wauto {
					width: auto !important;
				}
				.ico-ok {
					&:before {
						display: inline-block;
					}
				}
				UL.actions01 {
					.btn {
						/*[class*=" ico"]*/
						&.add,
						&.remove {
							padding: 0 !important;
							margin-left: 0 !important;
							&:before {
								display: inline-block;
							}
						}
					}

				}
			}
			&.type04b {
				.width-label-input(12%,86%);
				/*LABEL,
				SPAN {
					!*width: 12% !important;*!
					!*padding: 16px 0 0 10px;*!
				}
				DIV.items {
					!*width: 86% !important;*!
				}*/
			}


			/*replace with form__description*/
			&.type04c {
				LABEL,
				SPAN.label {
					text-align: right;
					font-weight: 600;
					font-family: @font-main;
					color: @color-gray01;
					font-size: 15px;
					margin: 0 4% 0 0;
				}
				SPAN.text {
					padding: 9px 0 0 0;
					font-weight: 400;
					color: @color-gray01;
					font-family: @font-main;
					font-size: 15px;
				}
			}
			@media @media-tablet {
				.medium {
					width: 85%;
				}
				.width-label-input(100%,100%);
				DIV.items {
					/*width: 100% !important;*/
					UL.actions01 {
						A.btn {
							&.add,
							&.remove {
								width: auto;
								float: left;
							}
							&.remove {
								text-indent: -10000px;
								&:before {
									text-indent: 0;
								}
							}
						}
					}
				}
				&.type04b {
					.width-label-input(100%,100%);
					LABEL {
						/*width: 100% !important;*/
						padding: 16px 0 0;
					}
					DIV.items {
						/*width: 100% !important;*/
					}
				}

				&.type04c {
					.width-label-input(100%,100%);
					LABEL,
					SPAN.label {
						text-align: left;
					}
				}
			}
			@media @media-mobile {
				width: auto;
				.width-label-input(100%,100%);
				DIV.items {
					/*width: 100% !important;*/
					LABEL {
						width: auto;
					}
					UL.actions01 {
						INPUT {
							width: 82%;
							margin-left: 0;
							float: left;
						}
						A.btn {
							&.add,
							&.remove {
								width: auto;
								float: left;
							}
							&.remove {
								text-indent: -10000px;
								&:before {
									text-indent: 0;
								}
							}
						}
					}
				}
				&.type04b {
					.width-label-input(100%,100%);
					/*LABEL {
						width: 100% !important;
					}
					DIV.items {
						width: 100% !important;
					}*/
				}
				&.type04c {
					.width-label-input(100%,100%);
					LABEL,
					SPAN.label {
						text-align: left;
					}
				}
			}
		}
		&.type05 {
			DIV.items {
				&.w100p {
					width: 100% !important;
				}
				UL.actions01 {
					LI {
						margin: 0.5em 0 0 0 !important;
						border: none;
						width: 100%;
						display: inline-block;
						INPUT {
							width: 84%;
							margin-left: 0;
							float: left;
							/*&.max { width: 100%; }*/
						}
						A.btn {
							&.add,
							&.remove {
								width: auto;
								float: left;
								display: inline-block;
								position: relative;
								margin: 0 !important;
								padding: 0 !important;
							}
							&.remove {
								text-indent: -10000px;
								font-weight: bold;
								left: 0.5em;
								top: 0.8em;
								&:before {
									text-indent: 0;
								}
							}
						}
					}
				}
			}
			@media @media-tablet {
				DIV.items {
					UL.actions01 {
						LI {
							INPUT {
								width: 91%;
							}
						}
					}
				}
			}
			@media @media-mobile {
				DIV.items {
					UL.actions01 {
						LI {
							INPUT {
								width: 87%;
							}
						}
					}
				}
			}
		}
		&.type00 {
			margin: 0;
			padding: 0;
			LABEL,
			SPAN.label {
				width: 100%;
				padding: 0;
				float: none;
			}
		}
		&.form__row {
			/*sm not in use yet*/
			&--sm {
				.width-label-input(60%,36%);
				.form__description,LABEL,
				SPAN.label {
					margin: 0 10px 0 0;
					padding: 0;
				}
			}
			&--md {
				.width-label-input(32%,63%);
				.form__description,LABEL,
				SPAN.label {
					margin: 0 25px 0 0;
				}
			}
			&--lg {
				.width-label-input(32%,65%);
			}
		}
		.form-group {
			/*padding: 0 15px 19px 0;*/
			/*margin: 5px 0 8px 0;*/
			margin: 0;
			padding: 0;
			input[type=radio] {
				&:disabled + LABEL {
					cursor: default;
					&:before {
						cursor: default;
					}
				}
			}
			input[type=checkbox] {
				&:disabled + LABEL {
					cursor: default;
					&:before {
						cursor: default;
						background: @color-gray07;
					}
				}
			}
			&.form-group__col {
				&--w500 {
					width: 500px;
					@media @media-mobile {
						width: 100%;
					}
				}
			}
			.form-group__list {
				&.form-group__pad {
					padding: 0 0 14px 0;
				}
				&--neg-left-20 {
					margin-left: -20px;
					padding-left: 20px;
				}
				&--flex {
					width: 100%;
					.display(flex);
					.flex-wrap(nowrap);
					@media @media-mobile {
						.flex-wrap(wrap);
					}
				}
				&--anim-editable {
					width: 100%;
					text-align: center;
					@media @media-notdesktop {
						text-align: left;
					}
				}
			}
			.form-group__inline {
				LABEl {
					display: inline-block;
					float: none;
					vertical-align: top;
				}
				.items {
					float: none;
					display: inline-block;
				}
				&.form-group__inline--w145 {
					> SPAN.label, > LABEL {
						width: 145px;
						@media @media-mobile {
							width: 100%;
						}
					}
					.items {
						width: calc(~"100% - 160px");
						@media @media-mobile {
							width: 100%;
						}
					}
				}
			}
			&--disabled {
				opacity: 0.9;
				cursor: default;
			}
			&--pad {
				padding: 17px 15px 19px 16px;
			}
			&--bg {
				background: @color-gray07;
			}
			@media @media-mobile {
				padding: 0 13px 15px 0;
				&--pad {
					padding: 10px 13px 15px 13px;
				}
			}
			LABEL,
			SPAN.label {
				padding: 15px 0 0;
				text-align: left;
				font-weight: 600;
				font-family: @font-main;
				color: @color-gray01;
				font-size: 15px;
				.box-sizing(border-box);
				&.right {
					text-align: right;
				}
			}
			SPAN.text {
				padding: 15px 0 0 0;
				font-weight: 400;
				color: @color-gray01;
				font-family: @font-main;
				font-size: 15px;
			}
			DIV.items {
				.box-sizing(border-box);
				max-width: 100%;
				&.wauto {
					width: auto;
				}
			}
			@media @media-notdesktop {

			}
			@media @media-tablet {

			}
			@media @media-mobile {
				.width-label-input(100%,100%);
				LABEL,
				SPAN.label {
					padding: 4px 0 6px 0;
					text-align: left;
					font-weight: 600;
					font-family: @font-main;
					color: @color-gray01;
					font-size: 15px;
					.box-sizing(border-box);
					&.right {
						text-align: right;
					}
				}
			}
		}
		.form-input-checkbox {
			&__list {
				.input-wrapper {
					padding: 0 0 5px 0;
				}
			}
		}
		.form-group {
			/*padding: 0 15px 19px 0;*/
			/*margin: 5px 0 8px 0;*/
			margin: 0;
			padding: 0;
			input[type=radio] {
				&:disabled + LABEL {
					cursor: default;
					&:before {
						cursor: default;
					}
				}
			}
			input[type=checkbox] {
				&:disabled + LABEL {
					cursor: default;
					&:before {
						cursor: default;
						background: @color-gray07;
					}
				}
			}
			&.form-group__col {
				&--w500 {
					width: 500px;
					@media @media-mobile {
						width: 100%;
					}
				}
			}
			.form-group__list {
				&.form-group__pad {
					padding: 0 0 14px 0;
				}
				&--neg-left-20 {
					margin-left: -20px;
					padding-left: 20px;
				}
				/*name use __ special as component name*/
				&--flex {
					width: 100%;
					.display(flex);
					.flex-wrap(nowrap);
					@media @media-mobile {
						.flex-wrap(wrap);
					}
				}
			}
			.form-group__inline {
				LABEl {
					display: inline-block;
					float: none;
					vertical-align: top;
				}
				.items {
					float: none;
					display: inline-block;
				}
				&.form-group__inline--w145 {
					> SPAN.label, > LABEL {
						width: 145px;
						@media @media-mobile {
							width: 100%;
						}
					}
					.items {
						width: calc(~"100% - 160px");
						@media @media-mobile {
							width: 100%;
						}
					}
				}
			}
			&--disabled {
				opacity: 0.9;
				cursor: default;
			}
			&--pad {
				padding: 17px 15px 19px 16px;
			}
			&--bg {
				background: @color-gray07;
			}
			@media @media-mobile {
				padding: 0 13px 15px 0;
				&--pad {
					padding: 10px 13px 15px 13px;
				}
			}
			LABEL,
			SPAN.label {
				padding: 15px 0 0;
				text-align: left;
				font-weight: 600;
				font-family: @font-main;
				color: @color-gray01;
				font-size: 15px;
				.box-sizing(border-box);
				&.right {
					text-align: right;
				}
			}
			SPAN.text {
				padding: 15px 0 0 0;
				font-weight: 400;
				color: @color-gray01;
				font-family: @font-main;
				font-size: 15px;
			}
			DIV.items {
				.box-sizing(border-box);
				max-width: 100%;
				&.wauto {
					width: auto;
				}
			}
			@media @media-notdesktop {

			}
			@media @media-tablet {

			}
			@media @media-mobile {
				.width-label-input(100%,100%);
				LABEL,
				SPAN.label {
					padding: 4px 0 6px 0;
					text-align: left;
					font-weight: 600;
					font-family: @font-main;
					color: @color-gray01;
					font-size: 15px;
					.box-sizing(border-box);
					&.right {
						text-align: right;
					}
				}
			}
		}
		.form-input-checkbox {
			&__list {
				.input-wrapper {
					padding: 0 0 5px 0;
				}
			}
		}
		.data01& {
			LABEL,
			SPAN.label {
				font-weight: normal;
			}
			DIV.items {
				font-weight: bold;
				A {
					font-weight: normal;
				}
			}
		}
		.fieldset-wrapper, .form-checkboxes, .field-input-wrapper {
			display: inline-block;
			position: relative;
			width: 100%;
			padding: 8px 0;
			font-size: 15px;
			font-family: @font-main;
			span {
				display: block;
			}
			label {
				display: inline;
				float: none;
				width: auto;
			}
			LABEL,
			SPAN.label {
				&.right {
					text-align: right;
				}
			}
			.field-input-wrapper {
				width: auto;
			}
			// radio button, checkbox
			input[type=checkbox] {
				&:disabled + LABEL {
					cursor: default;
					&:before {
						cursor: default;
						background: @color-gray07;
					}
				}
				&.form-checkbox {
					display: none;
				}

				+ label {
					font-weight: 400;
					line-height: 1.47;
					display: inline-block;
					margin-left: 35px;
					cursor: pointer;
					width: auto;
					max-width: none;
					padding: 8px 0;
					&.error {
						color: @color-gray01;
						&:before {
							border-color: @color-alert04;
							background: @color-alert05;
						}
					}
				}

				+ label:before {
					content: "\f00c";
					color: transparent;
					border: 1px solid @color-gray05;
					font-family: FontAwesome;
					padding: 2px 6px 2px 5px;
					position: absolute;
					left: 0;
					top: 14px;
					width: 25px;
					height: 25px;
					box-sizing: border-box;
					line-height: 18px;
					background: @color-white;
				}

				&:checked + label:before {
					color: @color01;
					border: 1px solid @color03;
				}
			}
			&--meta {
				font-size: 13px;
				color: @color-gray03;
			}
			input[type=radio] {
				&:disabled + LABEL {
					cursor: default;
					&:before {
						cursor: default;
					}
				}
				&.form-radio {
					display: none;
				}
				&:disabled + LABEL {
					opacity: 0.9;
					cursor: default;
				}
				+ label {
					font-weight: 400;
					cursor: pointer;
					line-height: 20px;
					display: inline-block;
					margin-left: 35px;
					width: auto;
					max-width: none;
					padding: 3px 0 0 0;
				}

				+ label:before {
					border: 1px solid @color-gray05;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;
					content: "\f111";
					color: transparent;
					font-family: FontAwesome;
					padding: 2px 6px;
					position: absolute;
					left: 0;
					top: 14px;


					background: @color-white;
				}

				&:checked + label:before {
					border: 1px solid @color03;
					content: "\f111";
					color: @color01;

				}
				&:disabled + LABEL {
					cursor: default;
					&:before {
						cursor: default;
					}
				}
			}
		}
		.form-checkboxes {
			&__label {

			}
			&__text {
				&--nowrap {
					white-space: nowrap;
				}
			}
			&--wauto {
				width: auto;
			}
			&.checkboxes__multi-sim {
				position: inherit;
				SPAN.checkboxes__text {
					display: inline-block;
					width: calc(~"100% - 32px");

				}
				input[type=radio] {
					+ label:before {
						padding: 2px 4px;
						position: inherit;
						display: inline-block;
						width: 23px;
						height: 23px;
						line-height: 18px;
						margin-right: 5px;
						vertical-align: top;
						font-size: 12px;
						text-align: center;
					}
					+ label {
						line-height: 1.64;
						margin: 0;
						padding: 0 0 15px 0;
						width: 100%;
						.checkboxes__text {
							word-break: normal;
							word-wrap: normal;
							display: inline-block;
							width: calc(~"100% - 32px");
						}
					}
				}
			}
			&.form-checkboxes__block {
				position: relative;
				input[type=radio] {
					+ label:before {
						padding: 2px 4px;
						position: inherit;
						display: inline-block;
						/*width: 23px;
						height: 23px;*/
						line-height: 16px;
						margin: 0 auto;
						text-align: center;
					}
					+ label {
						margin: 0;
						padding: 0;
						width: auto;
						.checkboxes__text {
							word-break: normal;
							word-wrap: normal;
							display: block;
							text-align: center;
							padding-top: 2px;
							font-family: @font-main;
							font-size: 14px;
							color: @color-gray01;
							font-weight: 400;
						}
					}
					&:checked + label {
						.checkboxes__text {
							font-weight: 700;
						}
					}
				}
			}
			&--disabled {
				opacity: 0.9;
				cursor: default;
			}
		}
		.readonly&,
		&.readonly {
			margin: 0;
			LABEL,
			SPAN.label {
				font-weight: normal;
				color: @color-gray-medium;
			}
			DIV.items {
				font-weight: bold;
				color: @color-gray-medium;
				A {
					font-weight: normal;
					&.ask {
						margin-top: 8px;
					}
				}
				&.w100p {
					font-weight: normal;
				}
			}
		}
		/*.readonly02&,
		&.readonly02{
			margin: 0;
			LABEL,
			SPAN.label { font-weight: bold; color: @color-gray-medium;  }
			DIV.items { font-weight: normal; color: @color-gray-medium; }
		}*/
		.readonly02&,
		&.readonly02 {
			margin: 0;
			LABEL,
			SPAN.label {
				font-weight: bold;
				color: @color-gray-medium;
			}
			DIV.items {
				font-weight: normal;
				color: @color-gray-medium;
			}
			@media @media-notdesktop {
				padding-left: 42px;
				P {
					margin: 0;
				}
			}
		}
		.readonly03&,
		&.readonly03 {
			margin: 0;
			LABEL,
			SPAN.label {
				font-weight: normal;
				color: @color-black;
				padding: 4px 0 0 0;
			}
			DIV.items {
				font-weight: bold !important;
				color: @color-black;
				SPAN.text {
					padding: 4px 0 0 0;
				}
			}
			@media @media-mobile {
				font-size: 14px !important;
			}
		}
		DIV.item {
			clear: both;
			.clearfix();
			& + DIV.item {
				margin-top: 5px;
			}

		}
		/*DIV.row {
			&.type00 {
				DIV.item {
					& + DIV.item { margin: 0 }

				}
			}
		}*/
		LABEL,
		SPAN.label {
			float: left;
			width: 18%;
			margin: 0 2% 0 0;
			text-align: left;
			padding: 8px 0 0;
			&[class^="ico"]:before,
			&[class*=" ico"]:before {
				margin-right: 5px;
			}
			&.w100p {
				width: 100%;
			}
			&.w500 {
				width: 462px;
				display: block;
				float: none;
				&.text {
					color: @color-gray-medium;
					&.right {
						text-align: right !important;
					}
					&.ico-check {
						color: @color01;
					}
				}
				@media @media-mobile {
					width: 100%;
				}
			}
		}
		> LABEL,
		> SPAN.label {
			A.note,
			SPAN.note,
			P.note {
				padding: 0;
			}
		}
		DIV.items {
			float: left;
			width: 80%;
			margin: 0;
			LABEL {
				display: inline-block;
				float: none;
				width: auto;
				&.f-left {
					float: left;
				}
				&.text {
					float: left;
					padding: 17px 0 0;
					margin: 0 5px 0 0;
				}
			}
			&.f-right {
				float: right;
			}
			&.wauto {
				width: auto !important;
			}
			DIV.input-wrapper {
				margin: 0 10px 0 0;
			}
			A.edit,
			A.remove,
			A.add,
			A.view {
				margin-left: 15px;
				font-size: 12px;
				margin-top: 0;
			}
			SPAN.text {
				//margin-left: 60px;
				&.f-right {
					text-align: right;
					float: right;
				}
				SPAN.small {
					font-size: 10px;
					font-weight: normal;
					color: @color-gray-medium;
				}
				&.ico-check:before {
					margin-right: 5px;
					font-size: 80%;
				}
			}
			DIV.hasprefix {
				position: relative;
				padding-left: 40px;
				SPAN.text.prefix {
					color: @color-gray-dark;
					position: absolute;
					left: 0;
					top: 0;
					max-width: 35px;
					overflow: hidden;
				}
				@media @media-mobile {
					padding-left: 45px;
					SPAN.text.prefix {
						padding-top: 4px;
					}
				}
			}
			INPUT,
			SELECT,
			TEXTAREA {
				float: left;
				& + A.edit,
				& + A.remove,
				& + A.add,
				& + A.view {
					margin-top: 12px;
				}
				&.wauto {
					width: auto;
					float: left;
				}
			}
			&.w100p {
				width: 94% !important;
				margin-right: 4%;
				.products02b & {
					width: 100% !important;
					margin-right: 0;
				}
			}
			&.w500 {
				width: 470px;
				float: none;
				display: block;
				.text {
					color: @color-gray-medium;
				}
				@media @media-mobile {
					width: 100%;
					INPUT {
						width: 100%;
					}
				}
			}
			UL.actions01 {
				/*.discrete { margin: 10px 0 0 0; padding: 7px 0; }*/
				.discrete {
					margin: 10px 0 0 0;
					padding: 7px 0 0;
				}
			}
			DIV.editable {
				UL.actions01 {
					.discrete {
						margin: 0;
					}
				}
			}
			DIV.time01 {

				&.time01b {
					width: 105px;
					background: @color-white;
					INPUT {
						width: 34px;
						margin: 0 3px;
					}
				}
				border: 1px solid @color-gray-medium-lightest;
				display: inline-block;
				width: 124px;
				height: 35px;
				margin: 0;
				padding: 0;
				position: relative;
				.box-sizing(border-box);
				INPUT {
					border: none;
					width: 42px;
					margin: 0 4px;
					padding: 6px 0;
					height: auto;
				}
				&:before {
					position: absolute;
					content: ':';
					right: 24px;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					line-height: 4px;
					width: 4px;
					height: 4px;
				}
				&:after {
					position: absolute;
					padding-right: 2px;
					font-family: icons;
					content: @ico-time;
					top: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					width: 24px;
					height: 24px;
					line-height: 24px;
					text-align: center;
				}
			}

		}
		SPAN.text {
			/*padding: 4px 1% 4px 0;*/
			padding: 8px 1% 4px 0;
			max-width: 100%;
			&.error-ico:before {
				width: 16px;
				position: relative;
				top: .3em;
			}
			&:first-child {
				margin-left: 0;
			}
		}
		SPAN.text,
		& > INPUT
		& > TEXTAREA
		& > SELECT {
			float: left;
			margin-left: 5px;
			&:first-child {
				margin-left: 0;
			}
			&.error {
				color: @color-negative;
			}
		}
		INPUT,
		SELECT,
		TEXTAREA {
			float: left;
			&.readonly {
				background: @color-gray-lightest;
				color: @color-gray-medium;
			}
		}
		INPUT[type="file"] {
			border: none;
			box-shadow: none;
		}
		DIV.editable {
			INPUT,
			SELECT,
			TEXTAREA {
				float: none;
			}
		}
		DIV.button-wrapper {
			margin: 0 0 0 42%;
			padding-top: 0;
			padding-bottom: 0.5em;
		}
		DIV.col {
			UL.checkboxes01,
			UL.checkboxes {
				margin-top: 5px;
			}
		}
		UL.actions01 {
			.discrete {
				margin: 0;
				padding: 7px 0
			}
		}
	}
	DIV.subrow {
		clear: both;
		overflow: hidden;
		margin: .5em 0;
	}
	DIV.items {
		position: relative;
		UL.checkboxes {
			margin: -.1em 0 0 0;
		}
	}
	A.note,
	SPAN.note,
	P.note {
		clear: both;
		display: block;
		padding: 5px 0 0 0;
		margin: 0;
		font-size: 12px;
		color: @color-gray-medium;
		font-weight: normal;
		&.hidden {
			display: none;
		}
		&.error {
			color: @color-negative;
		}
		&.text {
			display: inline-block;
			float: none !important;
		}
	}
	P.desc {
		margin: .3em 0 0 0;
		padding: 0 0 0 16px;
		clear: both;
		font-size: 10px;
		color: @color-gray-medium;
		line-height: normal;
		A {
			font-weight: bold;
			color: @color-black;
		}
	}
	P.longtext {
		margin: 10px 0;
	}
	P.error {
		color: @color-negative;
	}
	.error {
		color: @color-negative;
		INPUT,
		SELECT,
		TEXTAREA,
		P.desc {
			color: @color-negative;
		}
	}
	.req {
		color: @color02 !important;
	}

	SPAN.small {
		font-size: 11px;
	}
	H2:first-child,
	H3:first-child {
		margin-top: 0;
	}

	.max {
		width: 100%;
		margin-right: 0;
	}
	/*.default2x {
		width: 236px;
		height: 37px;
	}*/
	.default {
		width: 48%;
	}
	.medium {
		width: 64%;
	}
	.medium2x {
		width: 95%;
	}
	.min {
		width: 25%;
	}
	.min2x {
		width: 51%;
	}
	.year {
		width: 50px;
	}
	UL.checkboxes01,
	UL.checkboxes {
		padding: 0;
		margin: 1em 0 0 10px;
		&.mt0 {
			margin-top: 0;
		}
		&.ml0 {
			margin-left: 0;
		}
		.msg01 {
			LI {
				&:before {
					display: block;
				}
				width: auto;
			}
		}
		LI {
			padding: 0;
			margin: .4em 0 0 0;
			background: none;
			overflow: hidden;
			clear: both;
			width: 100%;
			display: block;
			&:first-child {
				margin: 0;
			}
			:before {
				display: none;
			}
			A.btn {
				&:before {
					display: inline-block;
				}
			}
			DIV.item {
				float: left;
				padding: 0;
				width: auto;
			}
			DIV.item > LABEL,
			> LABEL {
				padding: .6em 0 .6em 24px;
				margin: 0 5px 0 0;
				float: left;
				width: auto !important;
				text-align: left;
				font-weight: normal;
				position: relative;
				overflow: hidden;
				line-height: normal;
				cursor: pointer;
				INPUT[type="checkbox"],
				INPUT[type="radio"] {
					margin: 5px 0;
					width: 24px;
					height: 14px;
					position: absolute;
					left: 1px;
					top: .4em;
				}
				A.ask {
					margin-top: 0;
				}
				&.disabled {
					cursor: default;
					color: @color-gray-medium;
				}
			}
			SELECT,
			INPUT[type="date"], INPUT[type="datetime"], INPUT[type="datetime-local"], INPUT[type="email"], INPUT[type="month"], INPUT[type="number"], INPUT[type="password"], INPUT[type="search"], INPUT[type="tel"], INPUT[type="text"], INPUT[type="time"], INPUT[type="url"], INPUT[type="week"], INPUT:not([type]) {
				float: left;
				margin: 8px 5px 0 0;
			}
		}
		&.nospacing {
			LI {
				margin: .2em 0 0 0;
				DIV.item > LABEL,
				> LABEL {
					padding: .3em 0 .3em 24px;
					INPUT[type="checkbox"],
					INPUT[type="radio"] {
						top: .2em;
					}
				}
			}
		}
		&.in3cols {
			.clearfix();
			LI {
				float: left;
				width: 32.3333%;
				margin: 0 1% 0 0;
				clear: none;
				&:nth-of-type(3n+1) {
					clear: both;
				}
				LABEL {
					padding-top: .5em;
					padding-bottom: 0;
				}
			}
		}
		&.in2cols {
			.clearfix();
			LI {
				float: left;
				width: 49%;
				margin: 0 1% 0 0;
				clear: none;
				&:nth-of-type(2n+1) {
					clear: both;
				}
				LABEL {
					padding-top: .5em;
					padding-bottom: 0;
				}
			}
		}
		&.scrollable {
			height: 150px;
			overflow: auto;
			border: 1px solid @color-gray-lightest;
			padding: 10px;
		}
	}
	.pickdatewrapper {
		float: left;
		position: relative;
		margin: 5px 1% 0 0;
		padding-right: 28px;
		A {
			position: absolute;
			right: 0;
			top: 0;
		}
		.wdate {
			width: 83px;
			margin: 0;
		}
	}

	TABLE.default {
		width: 100%;
	}

	DIV.col.w50p {
		LABEL,
		SPAN.label {
			float: left;
			width: 38.5%;
			margin: 0 2% 0 0;
			&.w100p {
				width: 100% !important;
			}
		}
		DIV.items {
			float: left;
			width: 59.5%;
			margin: 0;
			&.w100p {
				width: 100% !important;
			}
		}
		&:last-child {
			LABEL,
			SPAN.label {
				width: 32%;
			}
			DIV.items {
				width: 64%;
			}
		}
		&:first-child:last-child {
			DIV.items {
				width: 60%;
			}
			LABEL,
			SPAN.label {
				width: 38%;
			}
		}
		.default {
			width: 85%;
		}
		/*.ac01 {
			width: 85%;
		}*/
	}
	DIV.col07 & {
		LABEL,
		SPAN.label {
			float: left;
			width: 38%;
			margin: 0 2% 0 0;
		}
		DIV.items {
			float: left;
			width: 60%;
			margin: 0;
		}
	}
	DIV.col.w60p & {
		LABEL,
		SPAN.label {
			float: left;
			width: 38%;
			margin: 0 2% 0 0;
		}
		DIV.items {
			float: left;
			width: 60%;
			margin: 0;
		}
	}
	DIV.block01 &,
	DIV.block01 {
		DIV.row {
			LABEL,
			SPAN.label {
				width: 18%;
			}
			DIV.items {
				width: 80%;
			}
			DIV.col.w50p {
				LABEL,
				SPAN.label {
					width: 39%;
				}
				DIV.items {
					width: 59%;
				}
			}
			&.type00 {
				LABEL,
				SPAN.label {
					width: auto;
				}
				DIV.items {
					width: auto;
				}
				DIV.col.w50p {
					LABEL,
					SPAN.label {
						width: auto;
					}
					DIV.items {
						width: auto;
					}
				}
			}
		}

	}
	UL.type01 P.title {
		margin: .4em 0 0 0;
	}
	TD DIV.row {
		margin: -0.5em 0 0 0;
	}

	@media @media-tablet {
		.col.w60p & {
			.max,
			.default,
			.min2x {
				width: 100%;
				margin-right: 0;
			}
			.min {
				width: 80%;
				margin-left: 0 !important;
			}
		}

		DIV.row {
			&,
			.w50p,
			.w50p:last-child {
				LABEL,
				SPAN.label {
					width: 38%;
				}
				DIV.items {
					width: 60%;
				}
			}
		}

		DIV.col.w50p {
			float: none;
			width: 100% !important;
			margin-left: 0;
		}

		DIV.col.w50p &,
		DIV.col.w60p &,
		DIV.col.w70p & {
			LABEL,
			SPAN.label {
				float: none;
				width: 100%;
			}
			DIV.items {
				float: none;
				width: 100%;
			}
			DIV.row.type03 {
				> LABEL,
				> SPAN.label {
					width: 100% !important;
					margin: 0 !important;
				}
				DIV.items {
					width: 100% !important;
					margin-left: 0 !important;
					padding-left: 0;
					.box-sizing(border-box);
				}
			}
			&.readonly DIV.row.type03 {
				> LABEL,
				> SPAN.label {
					width: 120px !important;
				}
				DIV.items {
					margin-left: -130px !important;
				}
			}
		}
	}
	@media @media-mobile {
		.max,
		.default,
		.medium,
		.min2x {
			width: 100%;
			margin-right: 0;
		}

		.min {
			width: 80%;
			margin-left: 0 !important;
		}

		.min02 {
			width: 100%;
			margin-right: 0;
		}

		DIV.row,
		DIV.col.w50p,
		DIV.col.w60p,
		DIV.col.w50p:last-child,
		DIV.col.w50p:first-child:last-child,
		DIV.block01 DIV.row,
		DIV.block01 & DIV.row,
		DIV.block01 DIV.row DIV.col.w50p,
		DIV.block01 & DIV.row DIV.col.w50p,
		DIV.row .col-phone,
		DIV.row .col-email,
		DIV.col.w60p & {
			LABEL,
			SPAN.label {
				width: 100%;
				margin: 0 0 0 0;
			}
		}

		DIV.row DIV.items,
		DIV.col.w50p DIV.items,
		DIV.col.w60p DIV.items,
		DIV.col.w50p:last-child DIV.items,
		DIV.col.w50p:first-child:last-child DIV.items,
		DIV.row .col-phone DIV.items,
		DIV.row .col-email DIV.items,
		.col-phone02 DIV.block01 DIV.row DIV.items,
		.col-email02 DIV.block01 DIV.row DIV.items,
		.col-phone02 DIV.block01 & DIV.row DIV.items,
		.col-email02 DIV.block01 & DIV.row DIV.items,
		DIV.block01 DIV.row DIV.items,
		DIV.block01 DIV.row DIV.col.w50p DIV.items,
		DIV.block01 & DIV.row DIV.items,
		DIV.block01 & DIV.row DIV.col.w50p DIV.items,
		DIV.col.w50p .default,
		DIV.col.w60p & DIV.items {
			width: 100%;
		}

		DIV.row02 DIV.items SPAN.text {
			A.btn {
				float: left !important;
			}
		}

		DIV.items SPAN.text:first-child:last-child {
			padding: 0;
			margin: 0;
			display: block;
			float: none;
			A.btn {
				float: right;
				width: auto;
				margin: 4px 0 0 0;
			}
		}

		DIV.row {
			/* Todo: Affects all forms! */
			> LABEL,
			SPAN.label {
				padding-bottom: 4px;
			}
			DIV.items {
				&.w100p {
					width: 100% !important;
				}
				INPUT, TEXTAREA, SELECT {
					float: none;
					+ .btn {
						margin-left: 0;
						margin-top: 5px;
					}
				}
			}
			.col {
				.clearfix();
				& + .col {
					margin-top: 5px !important;
				}
			}
			&.type03 {
				> LABEL,
				> SPAN.label {
					width: 100% !important;
					margin: 0 !important;
				}
				DIV.items {
					width: 100% !important;
					margin-left: 0 !important;
					padding-left: 0;
					.box-sizing(border-box);
				}
				&.type03a {
					SPAN.label {
						width: 30% !important;
						margin: 0 !important;
					}
					DIV.items {
						margin-left: -30% !important;
						padding-left: 30% !important;
						> *:first-child {
							margin-left: 20px;
						}
					}
				}
			}
			&.expander {
				LABEL,
				SPAN.label {
					padding-top: 0;
					&:before {
						top: 3px;
					}
				}
			}
			UL.actions01 {
				clear: both;
				A, SPAN, INPUT {
					&.btn {
						float: none;
					}
				}

			}
		}

		DIV.row
		UL.checkboxes {
			LI {
				LABEL {
					padding-right: 10px;
				}
			}
			&.in3cols LI {
				float: none;
				width: 100%;
				margin: 0;
			}
		}
	}
	@media @media-notdesktop {
		DIV.row {
			.readonly&,
			&.readonly {
				margin: 0;
				font-size: ceil(@base-font-size*0.86);
				LABEL,
				SPAN.label {
					width: 120px !important;
					float: left !important;
					word-wrap: break-word;
					margin: 0 0 0 0;
					padding: 0;
				}
				DIV.items {
					width: 100% !important;
					margin-left: -120px !important;
					padding-left: 130px !important;
					float: left !important;
					.box-sizing(border-box);
					&.w100p {
						margin-left: 0 !important;
						padding-left: 0 !important;
					}
					SPAN.text {
						padding: 0 1%;
						&:first-child {
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}

/*.form02 {
	.display(flex); .justify-content(space-between);
	.w01 { .flex(1 1 auto); margin-right: 2%; }
	.w02 { .flex(2 1 auto); margin-right: 2%; }
	SELECT,
	INPUT { height: 40px; }
	.iefix & {
		.w01 { width: 18%;}
		.w02 { width: 38%;}
		.wauto { float: right; }
	}
	@media @media-mobile {
		display: block;
		SELECT,
		INPUT {
			+ DIV { padding-top: .5em; }
		}
		.w01,
		.w02,
		.wauto { float: none !important; width: 100% !important; margin: 10px 0 0 0 !important; }

	}
}*/
.form02 {
	.display(flex);
	.justify-content(space-between);
	.w01 {
		.flex(1 1 auto);
		margin-right: 2%;
	}
	.w02 {
		.flex(2 1 auto);
		margin-right: 2%;
	}
	SELECT,
	INPUT {
		height: 40px;
	}
	.iefix & {
		.w01 {
			width: 18%;
		}
		.w02 {
			width: 38%;
		}
		.wauto {
			float: right;
		}

		/*temporary before width and spacing*/
		.mobile-nomargin {
			@media @media-mobile {
				margin: 0;
			}
		}
	}
	@media @media-mobile {
		display: block;
		SELECT,
		INPUT {
			+ DIV {
				padding-top: .5em;
			}
		}

		.w01,
		.w02,
		.wauto {
			float: none !important;
			width: 100% !important;
			margin: 10px 0 0 0 !important;
		}

	}
	.form02-row {
		.display(flex); /*check koneliitumine */
		.justify-content(flex-start);
		.flex-wrap(wrap);
		.box-sizing(border-box);
		&.form02-row--center {
			.align-items(center);
		}
		&.form-row--space-between {
			.justify-content(space-between);
		}
	}
	.form02-group {
		.box-sizing(border-box);
		.display(flex);
		.flex-wrap(wrap);
		@media @media-mobile {
			width: 100%;
		}
	}
	.form02-item {
		@media @media-mobile {
			width: 100%;
		}
		&.form02-item--center {
			.display(flex);
			.align-items(center);
			.flex-wrap(wrap);
		}
		.form02-item-inline01 {
			.box-sizing(border-box);
			.display(flex);
			.align-items(center);
			.flex-wrap(wrap);
			width: auto;
			> LABEL {
				font-weight: 700;
				margin-right: 20px;
			}
			&.form02-item-inline01--white {
				> LABEL {
					color: @color-white;
				}
			}
			@media @media-mobile {
				width: 100%;
				margin-bottom: 2em;
				> LABEL {
					margin-bottom: 10px;
				}
			}
		}
		margin: 0;
		> LABEL {
			box-sizing: border-box;
			color: @color-gray01;
			font-family: @font-main;
			display: inline-block;
			font-weight: 600;
			line-height: 20px;
			padding: 0 10px 0 0;
			margin: 15px 0 8px;
			width: 100%;
			max-width: 500px;
		}
	}
}

DIV.select01 {
	height: 35px;
	UL {
		float: left;
		list-style: none;
		margin: 0;
		.box-sizing(border-box);
		line-height: 26px;
		position: relative;
		&.default {
			width: 48%;
		}
		&:before {
			content: "";
			position: absolute;
			right: 11px;
			top: 0;
			z-index: 10;
			height: 35px;
			color: @color-gray-dark;
			width: 30px;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkFGQTYwRDdDODc1MTExRTQ4NDgzRjhGNDRGRDAwNkIwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkFGQTYwRDdEODc1MTExRTQ4NDgzRjhGNDRGRDAwNkIwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUZBNjBEN0E4NzUxMTFFNDg0ODNGOEY0NEZEMDA2QjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QUZBNjBEN0I4NzUxMTFFNDg0ODNGOEY0NEZEMDA2QjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4ELdMYAAAAZklEQVR42mKUlJR0YWBg2MNAGnB59uwZXA8TEM8E4jQSDEiD6oEDFiA2AeIzUP4sIgwoh+pBMeQ9kQYhG/Ae3RAGIgzCaQCyIfgMwmsAuiHYDIIZoowvoFiwiCEb9B49ELEBgAADAE4uHOcTWXEIAAAAAElFTkSuQmCC') 100% 50% no-repeat;
		}
		A {
			color: @color-black;
			text-decoration: none;
		}
		LI {
			background: @color-white;
			border: 1px solid @color-gray-medium-lightest;
			display: none;
			margin: 0;
			padding: 5px 40px 4px 5px;
			position: relative;
			z-index: 9;
			&:before {
				display: none;
			}
			&.active {
				display: block;
			}
			&.actions01 {
				A.btn {
					margin-left: 0 !important;
					padding-left: 0 !important;
				}
				&.active A.btn {
					color: @color-black;
					text-decoration: none;
					font-size: 14px;
					&:before {
						display: none;
					}
					&:hover {
						cursor: default;
						color: @color-black;
						text-decoration: none;
					}
					&:focus {
						outline: none;
					}
				}
			}
		}
		&.active {
			LI {
				display: block;
				border-style: none solid;
				&.actions01 {
					border-top-style: solid;
				}
				&:first-child {
					border-top-style: solid;
				}
				&:last-child {
					border-bottom-style: solid;
				}
			}
		}
		@media @media-mobile {
			&.default {
				width: 100%;
			}
		}
	}

}

.custom-combobox {
	position: relative;
	width: 370px;
	margin: 1em 0 0 0;
	display: inline-block;
	.custom-combobox-toggle {
		position: absolute;
		top: 0;
		bottom: 0;
		margin-left: -1px;
		padding: 0;
		right: 0;
		border-left: 2px solid @color-gray-light;
		text-indent: -10000px;
		width: 40px;
		text-align: center;
		&:before {
			content: "";
			position: absolute;
			right: 11px;
			top: 0;
			z-index: 10;
			height: 45px;
			color: @color-gray-dark;
			width: 30px;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkFGQTYwRDdDODc1MTExRTQ4NDgzRjhGNDRGRDAwNkIwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkFGQTYwRDdEODc1MTExRTQ4NDgzRjhGNDRGRDAwNkIwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUZBNjBEN0E4NzUxMTFFNDg0ODNGOEY0NEZEMDA2QjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QUZBNjBEN0I4NzUxMTFFNDg0ODNGOEY0NEZEMDA2QjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4ELdMYAAAAZklEQVR42mKUlJR0YWBg2MNAGnB59uwZXA8TEM8E4jQSDEiD6oEDFiA2AeIzUP4sIgwoh+pBMeQ9kQYhG/Ae3RAGIgzCaQCyIfgMwmsAuiHYDIIZoowvoFiwiCEb9B49ELEBgAADAE4uHOcTWXEIAAAAAElFTkSuQmCC') 100% 50% no-repeat;
		}

	}
	INPUT {
		margin: 0;
		border-width: 2px;
		width: 100%;
		padding: 5px 10px;
		height: 45px;
	}
	@media @media-mobile {
		width: 100%;
	}
}

.ui-autocomplete.combobox-menu {
	list-style: none;
	.rounded(0);
	border-top: none;
	LI {
		margin: 0 !important;
		margin-top: 0 !important;
		padding-top: 7px;
		padding-bottom: 7px;
		&:before {
			content: "";
			top: 10px;
		}
		&:hover {
			background: @color11;
		}
	}
}

.countrylist01 INPUT {
	padding-left: 35px;
	background: @color-white no-repeat 10px 50%;
	&.ad {
		background-image: url(flags/ad.png);
	}
	&.ae {
		background-image: url(flags/ae.png);
	}
	&.af {
		background-image: url(flags/af.png);
	}
	&.ag {
		background-image: url(flags/ag.png);
	}
	&.ai {
		background-image: url(flags/ai.png);
	}
	&.al {
		background-image: url(flags/al.png);
	}
	&.am {
		background-image: url(flags/am.png);
	}
	&.an {
		background-image: url(flags/an.png);
	}
	&.ao {
		background-image: url(flags/ao.png);
	}
	&.aq {
		background-image: url(flags/aq.png);
	}
	&.ar {
		background-image: url(flags/ar.png);
	}
	&.as {
		background-image: url(flags/as.png);
	}
	&.az {
		background-image: url(flags/az.png);
	}
	&.at {
		background-image: url(flags/at.png);
	}
	&.au {
		background-image: url(flags/au.png);
	}
	&.aw {
		background-image: url(flags/aw.png);
	}
	&.ba {
		background-image: url(flags/ba.png);
	}
	&.bb {
		background-image: url(flags/bb.png);
	}
	&.bd {
		background-image: url(flags/bd.png);
	}
	&.be {
		background-image: url(flags/be.png);
	}
	&.bf {
		background-image: url(flags/bf.png);
	}
	&.bg {
		background-image: url(flags/bg.png);
	}
	&.bh {
		background-image: url(flags/bh.png);
	}
	&.bi {
		background-image: url(flags/bi.png);
	}
	&.bj {
		background-image: url(flags/bj.png);
	}
	&.bm {
		background-image: url(flags/bm.png);
	}
	&.bn {
		background-image: url(flags/bn.png);
	}
	&.bo {
		background-image: url(flags/bo.png);
	}
	&.br {
		background-image: url(flags/br.png);
	}
	&.bs {
		background-image: url(flags/bs.png);
	}
	&.bz {
		background-image: url(flags/bz.png);
	}
	&.bt {
		background-image: url(flags/bt.png);
	}
	&.bw {
		background-image: url(flags/bw.png);
	}
	&.by {
		background-image: url(flags/by.png);
	}
	&.ca {
		background-image: url(flags/ca.png);
	}
	&.cd {
		background-image: url(flags/cd.png);
	}
	&.cf {
		background-image: url(flags/cf.png);
	}
	&.cg {
		background-image: url(flags/cg.png);
	}
	&.ch {
		background-image: url(flags/ch.png);
	}
	&.ci {
		background-image: url(flags/ci.png);
	}
	&.cis {
		background-image: url(flags/cis.png);
	}
	&.ck {
		background-image: url(flags/ck.png);
	}
	&.cl {
		background-image: url(flags/cl.png);
	}
	&.cm {
		background-image: url(flags/cm.png);
	}
	&.cn {
		background-image: url(flags/cn.png);
	}
	&.co {
		background-image: url(flags/co.png);
	}
	&.cr {
		background-image: url(flags/cr.png);
	}
	&.cz {
		background-image: url(flags/cz.png);
	}
	&.cu {
		background-image: url(flags/cu.png);
	}
	&.cv {
		background-image: url(flags/cv.png);
	}
	&.cy {
		background-image: url(flags/cy.png);
	}
	&.de {
		background-image: url(flags/de.png);
	}
	&.dj {
		background-image: url(flags/dj.png);
	}
	&.dk {
		background-image: url(flags/dk.png);
	}
	&.dm {
		background-image: url(flags/dm.png);
	}
	&.do {
		background-image: url(flags/do.png);
	}
	&.dz {
		background-image: url(flags/dz.png);
	}
	&.ec {
		background-image: url(flags/ec.png);
	}
	&.ee {
		background-image: url(flags/ee.png);
	}
	&.eg {
		background-image: url(flags/eg.png);
	}
	&.eh {
		background-image: url(flags/eh.png);
	}
	&.er {
		background-image: url(flags/er.png);
	}
	&.es {
		background-image: url(flags/es.png);
	}
	&.et {
		background-image: url(flags/et.png);
	}
	&.eu {
		background-image: url(flags/eu.png);
	}
	&.fi {
		background-image: url(flags/fi.png);
	}
	&.fj {
		background-image: url(flags/fj.png);
	}
	&.fm {
		background-image: url(flags/fm.png);
	}
	&.fo {
		background-image: url(flags/fo.png);
	}
	&.fr {
		background-image: url(flags/fr.png);
	}
	&.ga {
		background-image: url(flags/ga.png);
	}
	&.gb {
		background-image: url(flags/gb.png);
	}
	&.gd {
		background-image: url(flags/gd.png);
	}
	&.ge {
		background-image: url(flags/ge.png);
	}
	&.gf {
		background-image: url(flags/gf.png);
	}
	&.gg {
		background-image: url(flags/gg.png);
	}
	&.gh {
		background-image: url(flags/gh.png);
	}
	&.gi {
		background-image: url(flags/gi.png);
	}
	&.gm {
		background-image: url(flags/gm.png);
	}
	&.gn {
		background-image: url(flags/gn.png);
	}
	&.gq {
		background-image: url(flags/gq.png);
	}
	&.gr {
		background-image: url(flags/gr.png);
	}
	&.gt {
		background-image: url(flags/gt.png);
	}
	&.gu {
		background-image: url(flags/gu.png);
	}
	&.gw {
		background-image: url(flags/gw.png);
	}
	&.gy {
		background-image: url(flags/gy.png);
	}
	&.hk {
		background-image: url(flags/hk.png);
	}
	&.hn {
		background-image: url(flags/hn.png);
	}
	&.hr {
		background-image: url(flags/hr.png);
	}
	&.ht {
		background-image: url(flags/ht.png);
	}
	&.hu {
		background-image: url(flags/hu.png);
	}
	&.id {
		background-image: url(flags/id.png);
	}
	&.ie {
		background-image: url(flags/ie.png);
	}
	&.il {
		background-image: url(flags/il.png);
	}
	&.im {
		background-image: url(flags/im.png);
	}
	&.in {
		background-image: url(flags/in.png);
	}
	&.iq {
		background-image: url(flags/iq.png);
	}
	&.ir {
		background-image: url(flags/ir.png);
	}
	&.is {
		background-image: url(flags/is.png);
	}
	&.it {
		background-image: url(flags/it.png);
	}
	&.je {
		background-image: url(flags/je.png);
	}
	&.jm {
		background-image: url(flags/jm.png);
	}
	&.jo {
		background-image: url(flags/jo.png);
	}
	&.jp {
		background-image: url(flags/jp.png);
	}
	&.ke {
		background-image: url(flags/ke.png);
	}
	&.kg {
		background-image: url(flags/kg.png);
	}
	&.kh {
		background-image: url(flags/kh.png);
	}
	&.ki {
		background-image: url(flags/ki.png);
	}
	&.km {
		background-image: url(flags/km.png);
	}
	&.kn {
		background-image: url(flags/kn.png);
	}
	&.kp {
		background-image: url(flags/kp.png);
	}
	&.kr {
		background-image: url(flags/kr.png);
	}
	&.kz {
		background-image: url(flags/kz.png);
	}
	&.kw {
		background-image: url(flags/kw.png);
	}
	&.ky {
		background-image: url(flags/ky.png);
	}
	&.la {
		background-image: url(flags/la.png);
	}
	&.lb {
		background-image: url(flags/lb.png);
	}
	&.lc {
		background-image: url(flags/lc.png);
	}
	&.li {
		background-image: url(flags/li.png);
	}
	&.lk {
		background-image: url(flags/lk.png);
	}
	&.lr {
		background-image: url(flags/lr.png);
	}
	&.ls {
		background-image: url(flags/ls.png);
	}
	&.lt {
		background-image: url(flags/lt.png);
	}
	&.lu {
		background-image: url(flags/lu.png);
	}
	&.lv {
		background-image: url(flags/lv.png);
	}
	&.ly {
		background-image: url(flags/ly.png);
	}
	&.ma {
		background-image: url(flags/ma.png);
	}
	&.mc {
		background-image: url(flags/mc.png);
	}
	&.md {
		background-image: url(flags/md.png);
	}
	&.me {
		background-image: url(flags/me.png);
	}
	&.mg {
		background-image: url(flags/mg.png);
	}
	&.mh {
		background-image: url(flags/mh.png);
	}
	&.mk {
		background-image: url(flags/mk.png);
	}
	&.ml {
		background-image: url(flags/ml.png);
	}
	&.mm {
		background-image: url(flags/mm.png);
	}
	&.mn {
		background-image: url(flags/mn.png);
	}
	&.mo {
		background-image: url(flags/mo.png);
	}
	&.mr {
		background-image: url(flags/mr.png);
	}
	&.ms {
		background-image: url(flags/ms.png);
	}
	&.mz {
		background-image: url(flags/mz.png);
	}
	&.mt {
		background-image: url(flags/mt.png);
	}
	&.mu {
		background-image: url(flags/mu.png);
	}
	&.mv {
		background-image: url(flags/mv.png);
	}
	&.mw {
		background-image: url(flags/mw.png);
	}
	&.mx {
		background-image: url(flags/mx.png);
	}
	&.my {
		background-image: url(flags/my.png);
	}
	&.na {
		background-image: url(flags/na.png);
	}
	&.ne {
		background-image: url(flags/ne.png);
	}
	&.ng {
		background-image: url(flags/ng.png);
	}
	&.ni {
		background-image: url(flags/ni.png);
	}
	&.nl {
		background-image: url(flags/nl.png);
	}
	&.no {
		background-image: url(flags/no.png);
	}
	&.np {
		background-image: url(flags/np.png);
	}
	&.nr {
		background-image: url(flags/nr.png);
	}
	&.nz {
		background-image: url(flags/nz.png);
	}
	&.om {
		background-image: url(flags/om.png);
	}
	&.pa {
		background-image: url(flags/pa.png);
	}
	&.pe {
		background-image: url(flags/pe.png);
	}
	&.pg {
		background-image: url(flags/pg.png);
	}
	&.ph {
		background-image: url(flags/ph.png);
	}
	&.pk {
		background-image: url(flags/pk.png);
	}
	&.pl {
		background-image: url(flags/pl.png);
	}
	&.pr {
		background-image: url(flags/pr.png);
	}
	&.ps {
		background-image: url(flags/ps.png);
	}
	&.pt {
		background-image: url(flags/pt.png);
	}
	&.pw {
		background-image: url(flags/pw.png);
	}
	&.py {
		background-image: url(flags/py.png);
	}
	&.qa {
		background-image: url(flags/qa.png);
	}
	&.ro {
		background-image: url(flags/ro.png);
	}
	&.rs {
		background-image: url(flags/rs.png);
	}
	&.ru {
		background-image: url(flags/ru.png);
	}
	&.rw {
		background-image: url(flags/rw.png);
	}
	&.sa {
		background-image: url(flags/sa.png);
	}
	&.sb {
		background-image: url(flags/sb.png);
	}
	&.sc {
		background-image: url(flags/sc.png);
	}
	&.sd {
		background-image: url(flags/sd.png);
	}
	&.se {
		background-image: url(flags/se.png);
	}
	&.sg {
		background-image: url(flags/sg.png);
	}
	&.si {
		background-image: url(flags/si.png);
	}
	&.sk {
		background-image: url(flags/sk.png);
	}
	&.sl {
		background-image: url(flags/sl.png);
	}
	&.sm {
		background-image: url(flags/sm.png);
	}
	&.sn {
		background-image: url(flags/sn.png);
	}
	&.so {
		background-image: url(flags/so.png);
	}
	&.sr {
		background-image: url(flags/sr.png);
	}
	&.sz {
		background-image: url(flags/sz.png);
	}
	&.st {
		background-image: url(flags/st.png);
	}
	&.sv {
		background-image: url(flags/sv.png);
	}
	&.sy {
		background-image: url(flags/sy.png);
	}
	&.za {
		background-image: url(flags/za.png);
	}
	&.zm {
		background-image: url(flags/zm.png);
	}
	&.zw {
		background-image: url(flags/zw.png);
	}
	&.tc {
		background-image: url(flags/tc.png);
	}
	&.td {
		background-image: url(flags/td.png);
	}
	&.tg {
		background-image: url(flags/tg.png);
	}
	&.th {
		background-image: url(flags/th.png);
	}
	&.tj {
		background-image: url(flags/tj.png);
	}
	&.tl {
		background-image: url(flags/tl.png);
	}
	&.tm {
		background-image: url(flags/tm.png);
	}
	&.tn {
		background-image: url(flags/tn.png);
	}
	&.to {
		background-image: url(flags/to.png);
	}
	&.tp {
		background-image: url(flags/tp.png);
	}
	&.tr {
		background-image: url(flags/tr.png);
	}
	&.tz {
		background-image: url(flags/tz.png);
	}
	&.tt {
		background-image: url(flags/tt.png);
	}
	&.tv {
		background-image: url(flags/tv.png);
	}
	&.tw {
		background-image: url(flags/tw.png);
	}
	&.ua {
		background-image: url(flags/ua.png);
	}
	&.ug {
		background-image: url(flags/ug.png);
	}
	&.uk {
		background-image: url(flags/uk.png);
	}
	&.un {
		background-image: url(flags/un.png);
	}
	&.us {
		background-image: url(flags/us.png);
	}
	&.uz {
		background-image: url(flags/uz.png);
	}
	&.uy {
		background-image: url(flags/uy.png);
	}
	&.va {
		background-image: url(flags/va.png);
	}
	&.vc {
		background-image: url(flags/vc.png);
	}
	&.ve {
		background-image: url(flags/ve.png);
	}
	&.vg {
		background-image: url(flags/vg.png);
	}
	&.vi {
		background-image: url(flags/vi.png);
	}
	&.vn {
		background-image: url(flags/vn.png);
	}
	&.ws {
		background-image: url(flags/ws.png);
	}
	&.vu {
		background-image: url(flags/vu.png);
	}
	&.ye {
		background-image: url(flags/ye.png);
	}
	&.yt {
		background-image: url(flags/yt.png);
	}
}

LABEL.type01 {
	font-weight: bold;
	margin-right: 10px;
}

LABEL.type02 {
	font-weight: normal;
	margin-right: 10px;
	color: @color-black-light;
}

.label.error-ico:before {
	margin: 0;
}

/* Columned forms */
.col-phone {
	width: 45%;
	margin: 1em 5% 0 0;
	&:first-child {
		margin-top: 1em;
	}
	INPUT.default {
		width: 100%;
	}
	A.remove {
		margin-right: 0;
	}
	.form01 DIV.row &,
	.form01 DIV.block01 DIV.row &,
	DIV.block01 .form01 DIV.row & {
		SPAN.label,
		LABEL {
			float: left;
			width: 42%;
			margin: 0 2% 0 0;
		}
		DIV.items {
			float: left;
			width: 56%;
			margin: 0;
		}
	}
	&.col-phone02 {
		DIV.block01 DIV.row & DIV.items {
			width: 65%;
		}
		.form01.data01 & {
			DIV.row {
				LABEL,
				SPAN.label {
					width: 33%;
				}
			}
		}
	}
	@media @media-notdesktop {
		width: 100% !important;
		float: none;
		margin: 1em 0 0 0;
	}
}

.col-email {
	width: 48%;
	margin: 1em 0 0 0;
	INPUT.default {
		width: 100%;
		*width: 93%;
	}
	A.remove {
		margin-right: 0;
	}
	.form01 DIV.row &,
	.form01 DIV.block01 DIV.row &,
	DIV.block01 .form01 DIV.row & {
		SPAN.label,
		LABEL {
			float: left;
			width: 40%;
			margin: 0 2% 0 0;
		}
		DIV.items {
			float: left;
			width: 58%;
			margin: 0;
		}
	}
	&.col-email02 {
		width: 50%;
		DIV.block01 DIV.row & DIV.items {
			width: 60%;
		}
		.form01.data01 & {
			DIV.row {
				LABEL,
				SPAN.label {
					width: 38%;
				}
			}
		}
	}
	@media @media-notdesktop {
		width: 100% !important;
		float: none;
		margin: 1em 0 0 0;
	}
}

.col-phone,
.col-email {
	H3:first-child + A, H3:first-child + INPUT {
		&.btn.f-right.margneg {
			margin-top: -1.8em !important;
		}
	}
	@media @media-mobile {
		margin-top: 1em !important;
		overflow: hidden;
		H3:first-child + A, H3:first-child + INPUT {
			&.btn.f-right.margneg {
				margin-top: 0 !important;
				padding-left: 0 !important;
			}
		}
	}
}

UL.checkboxes {
	margin-left: 0;
	&.ml2em {
		margin-left: 2em !important;
	}
	> LI {
		background: none !important;
		padding: 0 !important;
		&:before {
			display: none;
		}
		> LABEL {
			position: relative;
			padding: 0 20px 0 20px;
			cursor: pointer;
			.col.w40p & {
				white-space: normal;
			}
			&.margr {
				margin-right: 30px !important;
			}
		}
		INPUT[type="radio"],
		INPUT[type="checkbox"] {
			position: absolute;
			left: 0;
			top: 3px;
			margin: 0;
		}

		> INPUT[type="text"],
		> INPUT[type="password"],
		> INPUT[type="file"],
		> INPUT[type="date"],
		> INPUT[type="number"],
		> INPUT[type="email"],
		> INPUT:not([type]),
		> SELECT,
		> TEXTAREA {
			position: relative;
			top: -.35em;
			margin-left: 5px;
		}

		SPAN.img {
			display: block;
			overflow: hidden;
			position: relative;
			min-width: 120px;
			margin: 0 0 10px 0;
			IMG {
				float: left;
				margin-right: 10px;
			}
			SPAN {
				position: absolute;
				bottom: 0;
				font-size: 11px;
				color: @color-gray-medium;
				width: 60px;
				white-space: normal;
			}
			+ INPUT[type="radio"],
			+ INPUT[type="checkbox"] {
				position: absolute;
				left: 3px;
				bottom: 1.2em;
				top: auto;
				margin: 0;
			}
		}
	}
}

UL.checkboxes02 {
	float: left;
	overflow: hidden;
	list-style: none;
	margin: 2em 0;
	&.f-right {
		float: right;
	}
	&.mt0 {
		margin-top: 0;
	}
	SPAN.label + & {
		margin: -.3em 0 0 1em;
	}

	LI {
		list-style: none;
		margin: 0 0 0 0;
		padding: 0;
		text-align: left;
		background: none;
		float: left;
		.box-sizing(border-box);
		&:before {
			display: none;
		}
		LABEL {
			position: relative;
			text-align: center;
			display: block;
			font-weight: normal;
			overflow: hidden;
			background: none;
			padding: 7px 30px;
			z-index: 1;
			line-height: normal;
			font-size: 15px;
			cursor: pointer;
		}
		&:first-child,
		&.text + LI {
			SPAN:before {
				border-left-style: solid;
			}
		}
		&.text {
			padding: 6px 10px;
			font-weight: bold;
			font-size: 15px;
		}
	}
	INPUT {
		visibility: hidden;
		position: absolute;
		left: -100000px;
		&:checked + SPAN:before {
			background: @color12;
			border-color: @color12;
		}
	}
	SPAN {
		color: @color-black;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			.box-sizing(border-box);
			display: block;
			background: none;
			border: 1px solid @color-gray-light;
			border-left-style: none;
			background: @color-white;
		}
	}
	@media @media-tablet {
		margin: 2em 0 1em;
	}
}

UL.checkboxes03 {
	float: left;
	overflow: visible;
	list-style: none;
	margin-top: -40px !important;
	width: 100%;
	&.checkboxes03b {
		margin-top: 0 !important;
		LI {
			LABEL {
				STRONG {
					display: inline-block;
				}
			}
		}
	}
	&.checkboxes03c {
		margin-top: .5em !important;
		margin: 0;
		.display(flex);
		.flex-wrap(wrap);
		.justify-content(space-between);
		LI {
			width: 31.3333%;
			padding: 0 !important;
			margin: .5em 0 0 0;
			.flex(0 0 auto);
		}
		SPAN:before {
			min-height: 0;
		}
		@media @media-notwide {
			LI {
				width: 48%;
			}
		}
		@media @media-mobile {
			LI {
				display: block;
				LABEL {
					text-align: center;
					SPAN:before {
						display: block;
						border: 1px solid @color-gray-light;
					}
					STRONG {
						float: none;
					}
				}
				INPUT:checked + LABEL {
					STRONG {
						color: @color02;
					}
					SPAN:before {
						border: 2px solid @color02;
					}
				}
			}
		}
	}
	SPAN.label + & {
		margin: -.3em 0 0 1em;
	}
	LI {
		width: 20%;
		list-style: none;
		margin: 0 0 0 0;
		text-align: left;
		background: none;
		float: left;
		.box-sizing(border-box);
		padding: 0 15px;
		position: relative;
		&:first-child {
			padding-left: 0;
		}
		&.haschildren {
			padding-right: 0;
			INPUT {
				&:checked + LABEL {
					SPAN {
						color: @color01;
						STRONG {
							color: @color01;
						}
						&:before {
							background: @color-white;
							border-color: @color-gray-light;
						}
					}
				}
			}
			LABEL,
			A {
				SPAN {
					&:before {
						border-top-style: none;
					}
					&.a {
						display: block !important;
					}
					&.b {
						display: none !important;
					}
					STRONG {
						font-size: 14px;
						@media @media-tablet {
							line-height: 20px;
						}
					}
				}
			}
			UL {
				position: absolute;
				display: none;
				overflow: hidden;
				height: auto;
				top: 100%;
				left: 0;
				right: 0;
				z-index: 100;
				margin: 0 0 0 15px;
				width: auto;
				padding: 0;
				.box-sizing(border-box);
				border: 1px @color-gray-light;
				border-style: none solid solid solid;
				border-radius: 0 0 5px 5px;
				LI {
					width: auto;
					float: none;
					padding: 0;
					.box-sizing(border-box);
					margin: 0;
					background: @color-white;
					LABEL {
						&:first-of-type {
							padding-top: 0;
						}
						SPAN {
							display: block;
							text-align: right;
							&:before {
								min-height: 0 !important;
								border-radius: 0;
								border: 0;
								top: -4px;
							}
							STRONG {
								float: left;
								font-size: 16px;
								line-height: 22px;
							}
						}
					}
					INPUT {
						&:checked + LABEL {
							SPAN {
								color: @color-white;
								STRONG {
									color: @color-white;
								}
								&:before {
									background: @color02;
									border-color: @color02;
								}
							}
						}
					}
				}
			}
			&.open {
				UL {
					display: block;
				}
				I {
					display: none;
				}
			}
			&.selected {
				> INPUT:checked + LABEL {
					SPAN {
						color: @color02;
						&.a {
							display: none !important;
						}
						&.b {
							display: block !important;
						}
						STRONG {
							color: @color02;
							font-size: 20px;
						}
						&:before {
							background: @color-white;
							border-color: @color02;
							border-width: 3px;
							border-top-style: solid;
						}
					}
				}
				&.open {
					> INPUT:checked + LABEL {
						SPAN {
							color: @color-gray-medium;
							STRONG {
								color: @color01;
								font-size: 20px;
							}
							&:before {
								background: @color-white;
								border-color: @color-gray-light;
							}
						}
					}
				}
			}
		}
		&:before {
			display: none;
		}
		LABEL,
		A {
			position: relative;
			text-align: center;
			display: block;
			font-weight: normal;
			overflow: visible;
			background: none;
			padding: 10px 20px;
			z-index: 1;
			line-height: normal;
			font-size: 16px;
			cursor: pointer;
			text-decoration: none;
			STRONG {
				font-size: 20px;
				display: block;
				font-weight: 700;
				line-height: 30px;
			}
		}
	}
	INPUT {
		visibility: hidden;
		position: absolute;
		left: -100000px;
		&:checked + LABEL {
			SPAN {
				color: @color02;
				STRONG {
					color: @color02;
				}
				&:before {
					background: @color-white;
					border-color: @color02;
					border-width: 3px;
				}
			}
		}
		/*&:checked ~ UL {
			display: block;
		}*/
	}
	SPAN {
		color: @color-gray-medium;
		STRONG, I {
			color: @color01;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			.box-sizing(border-box);
			display: block;
			background: none;
			border: 1px solid @color-gray-light;
			background: @color-white;
			min-height: 80px;
		}
	}
	@media @media-mobile {
		margin-top: 1em !important;
		LI {
			padding: 0;
			width: 100%;
			display: none;
			LABEL {
				text-align: right;
				min-height: 30px;
				STRONG {
					display: inline-block;
					float: left;
				}
				SPAN {
					&:before {
						border-radius: 0;
						border: 0;
						min-height: 0;
					}
				}
			}
			&.haschildren {
				display: block;
				SPAN:before {
					.rounded(5px);
					border: 1px solid @color-gray-lightest;
					border-top-style: solid !important;
				}
				&.open {
					SPAN:before {
						border-bottom: none;
						.rounded(5px 5px 0 0);
					}
					UL LI LABEL SPAN:before {
						.rounded(0);
					}
				}
				UL {
					margin: 0;
					LI {
						display: block;
						LABEL SPAN {
							padding-top: 10px;
							&:before {
								top: 0;
								.rounded(0);
							}
						}
					}
				}
			}
		}
	}
}

UL.checkboxes04 {
	list-style: none;
	width: 100%;
	& + .actions01 {
		LI {
			width: 33%;
			margin: 0 auto !important;
			float: none;
			padding: 0 6px !important;
			.box-sizing(border-box);
			.btn.xxl {
				width: 100%;
				margin-top: 1em;
				padding: 15px 60px !important;
				font-weight: normal;
				text-align: center;
			}
			@media @media-tablet {
				float: left;
				width: 50%;
				padding: 0 10px 0 0 !important;
			}
			@media @media-mobile {
				padding: 0 !important;
				width: 100%;
			}
		}
	}
	&.checkboxes04b {
		float: none;
		.display(flex);
		flex-wrap: wrap;
		LI {
			padding: 10px !important;
			.display(flex);
			LABEL {
				text-align: left;
				padding: 15px;
			}
			.iefix & {
				&:nth-of-type(3n+1) {
					clear: both;
				}
				SPAN {
					min-height: 7em;
					display: block;
				}
			}
			@media @media-tablet {
				width: 50%;
				.iefix & {
					&:nth-of-type(3n+1) {
						clear: none;
					}
					&:nth-of-type(odd) {
						clear: both;
					}
				}
			}
			@media @media-mobile {
				padding: 10px 0 !important;
				LABEL {
					height: auto;
					line-height: normal;
					SPAN {
						&:before {
							min-height: 0;
						}
					}
				}
			}
		}
	}
	SPAN.label + & {
		margin: -.3em 0 0 1em;
	}
	LI {
		width: 33.3333%;
		list-style: none;
		margin: 0 0 0 0;
		text-align: left;
		background: none;
		float: left;
		.box-sizing(border-box);
		padding: 0 10px;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
		&:before {
			display: none;
		}
		LABEL {
			position: relative;
			text-align: center;
			display: block;
			font-weight: normal;
			overflow: visible;
			background: none;
			padding: 15px 5px;
			z-index: 1;
			line-height: normal;
			font-size: 15px;
			cursor: pointer;
		}
	}
	INPUT {
		visibility: hidden;
		position: absolute;
		left: -100000px;
		&:checked + SPAN {
			color: @color02;
			I {
				color: @color02;
			}
			&:before {
				background: @color-white;
				border-color: @color02;
			}
		}
	}
	SPAN {
		color: @color-black;
		I {
			color: @color-gray-medium;
			font-size: 36px;
			display: inline-block;
			padding-right: 10px;
			vertical-align: middle;
		}
		SPAN.b {
			display: inline-block;
			text-align: left;
			line-height: 1;
			margin-top: -9px;
			position: relative;
			top: 9px;
			&:before {
				display: none;
			}
		}
		SMALL {
			font-size: 80%;
			color: @color-gray-medium;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			z-index: -1;
			.box-sizing(border-box);
			border: 3px solid @color-gray-lightest;
			background: @color-white;
			min-height: 80px;
		}
	}
	@media @media-mobile {
		LI {
			width: 100%;
			padding: 5px 0;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
			LABEL {
				padding: 10px;
				height: 30px;
				line-height: 30px;
				SPAN {
					I {
						font-size: 28px;
					}
					&:before {
						min-height: 0;
					}
				}
			}
		}
	}
}

UL.checkboxes05 {
	float: left;
	overflow: hidden;
	list-style: none;
	width: 100%;
	height: 90px;
	SPAN.label + & {
		margin: -.3em 0 0 1em;
	}
	LI {
		width: 33.3333%;
		list-style: none;
		margin: 0 0 0 0;
		text-align: left;
		background: none;
		float: left;
		.box-sizing(border-box);
		padding: 0 10px;
		&:first-child {
			padding-left: 0;
			SPAN {
				&:before {
					background: url(wraps/wrap01.png) no-repeat !important;
					background-size: cover !important;
				}
			}
		}
		&:nth-of-type(2) {
			SPAN {
				&:before {
					background: url(wraps/wrap01.png) no-repeat !important;
					background-size: cover !important
				}
			}
		}
		&:last-child {
			padding-right: 0;
			SPAN {
				&:before {
					background: url(wraps/wrap01.png) no-repeat !important;
					background-size: cover !important;
				}
			}
		}
		&:before {
			display: none;
		}
		LABEL {
			position: relative;
			text-align: center;
			display: block;
			font-weight: normal;
			overflow: visible;
			background: none;
			padding: 15px 20px;
			z-index: 1;
			line-height: normal;
			font-size: 15px;
			cursor: pointer;
		}
	}
	INPUT {
		visibility: hidden;
		position: absolute;
		left: -100000px;
		&:checked + SPAN {
			color: @color01;
			I {
				color: @color01;
			}
			&:before {
				background: @color-white;
				border: 3px solid @color01;
			}
		}
	}
	SPAN {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			z-index: -1;
			.box-sizing(border-box);
			border: 1px solid @color-gray-light;
			min-height: 80px;
		}
	}
	@media @media-mobile {
		LI {
			LABEL {
				height: 30px;
				SPAN {
					&:before {
						min-height: 0;
					}
				}
			}
		}
	}
}

UL.checkboxes06 {
	float: left;
	overflow: visible;
	list-style: none;
	width: 100%;
	SPAN.label + & {
		margin: -.3em 0 0 1em;
	}
	LI {
		width: 20%;
		list-style: none;
		margin: 0 0 0 0;
		text-align: left;
		background: @color-white;
		float: left;
		.box-sizing(border-box);
		padding: 0 0 0 20px;
		height: 55px;
		&:first-child {
			padding-left: 0;
		}
		&.bank01 {
			SPAN {
				&:before {
					background: url(banks/bank01.png) no-repeat 50% 50% !important;
				}
			}
		}
		&.bank02 {
			SPAN {
				&:before {
					background: url(banks/bank02.png) no-repeat 50% 50% !important;
				}
			}
		}
		&.bank03 {
			SPAN {
				&:before {
					background: url(banks/bank03.png) no-repeat 50% 50% !important;
				}
			}
		}
		&.bank04 {
			SPAN {
				&:before {
					background: url(banks/bank06.png) no-repeat 50% 50% !important;
				}
			}
		}
		&.bank05 {
			SPAN {
				&:before {
					background: url(banks/bank05.png) no-repeat 50% 50% !important;
				}
			}
		}
		&:before {
			display: none;
		}
		LABEL {
			position: relative;
			text-align: center;
			display: block;
			font-weight: normal;
			overflow: visible;
			background: none;
			padding: 15px 20px;
			z-index: 1;
			line-height: normal;
			font-size: 15px;
			cursor: pointer;
		}
	}
	INPUT {
		visibility: hidden;
		position: absolute;
		left: -100000px;
		&:checked + SPAN {
			color: @color01;
			I {
				color: @color01;
			}
			&:before {
				background: @color-white;
				border: 3px solid @color01;
			}
		}
	}
	SPAN {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			z-index: -1;
			.box-sizing(border-box);
			border: 1px solid @color-gray-light;
			min-height: 55px;
		}
	}
	@media @media-mobile {
		overflow: hidden;
		LI {
			width: 100%;
			padding: 5px 0 !important;
			overflow: hidden;
			LABEL {
				padding: 10px !important;
				height: 30px;
				SPAN {
					&:before {
						min-height: 0 !important;
					}
				}
			}
		}
	}
}

UL.checkboxes07 {
	.clearfix();
	margin-top: 60px;
	LI {
		width: 50%;
		float: left;
		padding: 0 0 0 30px;
		.box-sizing(border-box);
		&:nth-of-type(odd) {
			padding: 0 30px 0 0;
			clear: both;
		}
		&:before {
			display: none;
		}
		LABEL {
			display: block;
			cursor: pointer;
		}
		INPUT {
			position: absolute;
			left: -10000px;
			&:checked + SPAN:before {
				background: @color02;
			}
		}
		SPAN {
			font-weight: 600;
			font-size: 20px;
			display: block;
			position: relative;
			padding-left: 30px;
			&:before {
				position: absolute;
				left: 0;
				top: .4em;
				content: "";
				.rounded(50%);
				width: 12px;
				height: 12px;
				border: 3px solid @color-white;
				.shadow(0 0 1px 1px, @color03);
			}
		}
	}
	@media @media-mobile {
		margin-top: 40px;
		LI {
			width: 100%;
			float: none;
			padding: 0 !important;
		}
	}
}

UL.checkboxes08 {
	.clearfix();
	margin: 2em 0 0 0;
	LI {
		float: left;
		padding: 0;
		margin: 1em 30px 0 0;
		&:before {
			display: none;
		}
	}
	LABEL {
		position: relative;
		INPUT {
			position: absolute;
			left: -10000px;
			top: 0;
			&:checked + SPAN {
				background: lighten(@color-gray-lightest, 5%);
				border: 2px solid @color12;
				padding-top: 20px;
				&.hasarrow:after {
					content: "";
					background-image: url(triangle.png);
					position: absolute;
					bottom: -31px;
					left: 50%;
					margin-left: -17px;
					background-repeat: no-repeat;
					display: inline-block;
					height: 18px;
					width: 36px;
				}
			}
		}
		SPAN {
			cursor: pointer;
			position: relative;
			color: @color01;
			display: block;
			border: 2px solid @color-gray-light;
			.rounded(10px);
			text-align: center;
			width: 95px;
			height: 95px;
			.box-sizing(border-box);
			padding-top: 20px;
			&:before {
				.rounded(3px); /*.shadow();*/
				content: "";
				display: block;
				margin: 0 auto 10px;
				height: 26px;
				width: 26px;
				background: no-repeat 50% 50%; /*.shadow(0 2px 2px, rgba(0, 0, 0, 0.1));*/
			}
		}
		&.fb SPAN:before {
			background-image: url(facebook.png);
		}
		&.google SPAN:before {
			background-image: url(google.png);
		}
		&.email SPAN:before {
			background-image: url(mail.png);
		}
	}
	@media @media-mobile {
		.display(flex); .justify-content(space-between);
		LI {
			margin: 1em 5px 0 0;
			.flex(0 0 32%);
		}

		LABEL {
			SPAN {
				width: 85px;
			}
			INPUT {
				&:checked + SPAN {
					&.hasarrow01:after {
						bottom: -30px;
					}
				}
			}
		}
	}
}

ul.checkboxes09 {
	margin-left: 0;
	.display(flex);
	.flex-direction(row);
	.box-sizing(border-box);
	width: 100%;
	&.ml2em {
		margin-left: 2em !important;
	}
	> li {
		background: none !important;
		padding: 0 !important;
		.display(flex);
		.flex(0 1 auto); /*width: 20%;*/
		&:before {
			display: none;
		}
		> LABEL {
			position: relative;
			padding: 0 25px 0 0;
			cursor: pointer;
			.display(flex);
			.col.w40p & {
				white-space: normal;
			}
			&.margr {
				margin-right: 30px !important;
			}
		}
		INPUT[type="radio"] {
			&:checked + SPAN {
				&:before {
				}
			}
		}
		INPUT[type="radio"],
		INPUT[type="checkbox"] {
			visibility: hidden;
			position: absolute;
			left: -100000px;
		}

		INPUT[type="radio"] + SPAN,
		INPUT[type="checkbox"] + SPAN {
		}

		INPUT[type="radio"] + SPAN {
		}
		INPUT[type="checkbox"] + SPAN {
			border: 1px solid @color-gray-medium-light;
			width: 25px;
			height: 25px;
			min-width: 25px;
			min-height: 25px;
			text-align: center;
			position: relative;
			top: -2px;
			left: 0;
			&::before {
				.transition();
				font-family: "icons";
				content: "";
				top: 0;
				display: block;
				line-height: 25px;
				font-size: 15px;
				text-align: center;
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				z-index: 9;
				color: @color01;
			}
		}
		INPUT[type="checkbox"] {
			&:checked + SPAN {
				border-color: @color03;
				&::before {
					content: @ico-check;
				}
			}
		}

		> INPUT[type="text"],
		> INPUT[type="password"],
		> INPUT[type="file"],
		> INPUT[type="date"],
		> INPUT[type="number"],
		> INPUT[type="email"],
		> INPUT:not([type]),
		> SELECT,
		> TEXTAREA {
			position: relative;
			top: -.35em;
			margin-left: 5px;
		}
		SPAN.txt {
			padding: 0 10px;
			color: @color16;
		}

		SPAN.img {
			display: block;
			overflow: hidden;
			position: relative;
			min-width: 120px;
			margin: 0 0 10px 0;
			IMG {
				float: left;
				margin-right: 10px;
			}
			SPAN {
				position: absolute;
				bottom: 0;
				font-size: 11px;
				color: @color-gray-medium;
				width: 60px;
				white-space: normal;
			}
			+ INPUT[type="radio"],
			+ INPUT[type="checkbox"] {
				position: absolute;
				left: 3px;
				bottom: 1.2em;
				top: auto;
				margin: 0;
			}
		}
	}

	@media @media-notdesktop {
		.flex-direction(column);
		> li {
			width: 100%;
			> LABEL {
				padding: 0;
			}
		}

	}
}

.radio-wrapper {
	width: 108px;
	margin: 0 0 1.5em;
	height: 36px;
	display: inline-block;
	vertical-align: middle;
	.rounded(18px);
	background: lighten(@color-gray-lightest, 3%);
	.shadow-inset(0 1px 2px, rgba(0, 0, 0, 0.1));
	cursor: pointer;
	position: relative;
	&.onoff {
		width: 72px;
	}
	label {
		z-index: 9;
		display: inline-block;
		width: 36px !important;
		height: 36px !important;
		padding: 0 !important;
		margin-top: -1px !important;
		border-radius: 50%;
		position: absolute;
		text-align: center !important;
		text-indent: -10000px;
		&:before {
			font-family: "icons";
			color: @color-white;
			font-size: 16px;
			line-height: 34px;
		}
	}
	SPAN {
		display: none !important;
		position: absolute;
		top: 100%;
		text-align: center;
		font-size: 80%;
		color: @color-gray-medium;
		left: 0;
		right: 0;
	}

	input[type="radio"] {
		display: none;
		&:checked + LABEL {
			.shadow(0 2px 2px, rgba(0, 0, 0, 0.1));
			text-indent: 0;
			& + SPAN {
				display: block !important;
			}
		}
		&.neutral {
			& + label {
				left: 33%;
				transition: transform 1s;
			}
			&:checked + label {
				border: 1px solid @color-gray-light;
				background: @color-gray-lightest;
			}
		}
		&.positive {
			& + label {
				right: 0;
			}
			&:checked + label {
				background: @color-positive;
				border: 1px solid darken(@color-positive, 5%);
				-moz-animation-duration: 0.4s;
				-moz-animation-name: slideno;
				-webkit-animation-duration: 0.4s;
				-webkit-animation-name: slideno;
				animation-duration: 0.4s;
				animation-name: slideno;
				-webkit-transform: translateZ(0);
				&:before {
					content: @ico-check;
				}
			}
		}
		&.negative {
			& + label {
				left: 0;
			}
			&.negative02:checked + label {
				background: @color-gray-lightest;
				border: 1px solid @color-gray-light;
				&:before {
					color: @color-gray-medium;
				}
			}
			&:checked + label {
				background: @color-negative;
				border: 1px solid darken(@color-negative, 5%);
				-moz-animation-duration: 0.4s;
				-moz-animation-name: slidein;
				-webkit-animation-duration: 0.4s;
				-webkit-animation-name: slidein;
				animation-duration: 0.4s;
				animation-name: slidein;
				-webkit-transform: translateZ(0);
				&:before {
					content: @ico-cross;
				}
			}
		}
	}

	i {
		z-index: 99;
		font-size: 18px;
	}
	@media @media-mobile {
		float: none !important;
		display: block;
		margin: .7em auto 1.5em;
	}

}

@keyframes slidein {
	from {
		transform: translate(36px, 0);
	}
	to {
		transform: translate(0px, 0px);
	}
}

@keyframes slideno {
	from {
		transform: translate(-36px, 0);
	}
	to {
		transform: translate(0px, 0px);
	}
}

@keyframes returnLeft {
	from {
		transform: translate(-36px, 0);
	}
	to {
		transform: translate(0px, 0);
	}
}

@keyframes returnRight {
	from {
		transform: translate(36px, 0);
	}
	to {
		transform: translate(0px, 0);
	}
}

/* 8. Buttons part01 */

UL.actions01 {
	list-style: none;
	margin: 0;
	padding: 0;
	&.margt {
		margin-top: 1em;
	}
	&.nomargin {
		margin: 0 !important;
	}
	&.margneg {
		margin: -2.5em 0 0 0 !important;
		H2.blockheading + & {
			margin: -3.5em 20px 0 0 !important;
		}
		&.f-right .btn {
			&.add,
			&.remove,
			&.view,
			&.edit {
				padding: 2px 0 5px !important;
			}
		}
	}
	&.f-right LI {
		float: right;
		margin: 5px 10px 0 0;
	}
	/*&.center LI {
		float: none;
		display: inline-block;
		&.f-left {
			float: left;
		}
		&.f-right {
			float: right;
		}
	}*/
	&.actions01a {
		float: left;
		padding: 0;
		margin: 10px 1em 0 0;
		background: none;
		border-top: 1px solid transparent;
		overflow: hidden;
		.btn {
			margin: 0 1em 0 0 !important;
		}
		@media @media-mobile {
			float: none;
			clear: both;
		}
	}
	&.actions01b {
		margin: 30px 0 0;
		border: 1px solid @color-gray-lightest;
		padding: 5px 10px 10px 30px;
		SPAN.btn.discrete,
		UL.actions01b A.btn.discrete {
			font-weight: bold;
			font-size: 17px;
		}
	}
	&.actions01c {
		margin: 0;
		padding: 0;
		LI {
			text-align: left;
			margin: 5px 0 0 0 !important;
			/*margin: 5px 30px 0 0!important;*/
			A {
				margin-left: 0 !important;
				/*margin: 0;*/
				/*&.discrete {
					border: none;
				}*/
				&.disabled {
					&, &:hover {
						opacity: 0.4;
						cursor: default;
						color: @color01;
						text-decoration: none !important;
					}
				}
			}
		}
		@media @media-tablet {
			LI {
				/*margin: 5px 0 0 10px!important;
				&:first-child { margin: 5px 0 0 0!important; }*/
				margin: 5px 10px 0 0 !important;
			}
		}
		@media @media-mobile {
			LI {
				A.disabled {
					display: none;
				}
			}
		}
	}
	/*&.actions01d {
		width: 100%;
		LI {
			border: none;
			width: auto;
			display: inline-block;
			float: none;
			INPUT {
				width: 86%;
				margin-left: 0;
				float: left;
			}
		}
		@media @media-mobile {
			LI {
				float: none;
				width: 100%;
				padding: 0;
			}
		}
	}*/
	&.numbers01 {
		&.numbers01b {
			LI {
				height: 105px;
				A.btn,
				SPAN.btn {
					height: 95px;
					&.ico-alert {
						&:before {
							z-index: 2;
							left: 45px !important;
							top: 10px !important;
							border: none !important;
							width: 20px !important;
							height: 20px !important;
							background: @color02;
							border-radius: 50%;
							@media @media-tablet {
								left: 38px !important;
							}
							@media @media-mobile {
								left: 38px !important;
							}
						}
					}
					SPAN {
						padding-left: 50px;
						@media @media-tablet {
							padding-left: 55px;
						}
						@media @media-mobile {
							padding-left: 55px;
						}
						&.small {
							padding-left: 0;
							margin: 0.5em 0 0 0;
							padding-bottom: 10px;
							@media @media-tablet {
								margin: 1em 0 0 0;
							}
							@media @media-mobile {
								margin: 1em 0 0 0;
							}
						}
						.ico-user {
							color: @color01;
							display: inline-block;
							position: absolute;
							left: 20px;
							padding-left: 0;
							line-height: 40px;
							font-size: 21px;
							width: 35px;
							height: 35px;
							border: 2px solid @color10;
							background: @color10;
							border-radius: 50%;
							text-align: center;
							@media @media-tablet {
								left: 10px;
							}
							@media @media-mobile {
								left: 10px;
							}
						}
						&.ico-cross,
						&.ico-minus {
							display: none;
						}
					}
					&.selected02 {
						SPAN {
							.ico-user {
								color: @color01;
								background: @color-white;
								border-color: @color-white;
							}
							&.ico-plus {
								display: none;
							}
							&.ico-cross,
							&.ico-minus {
								display: block;
							}

						}
					}
					&.disabled {
						opacity: 1 !important;
						background: lighten(@color-gray-lightest, 5%);
						color: @color-gray-medium !important;
						SPAN .ico-user {
							color: @color-gray-medium;
							background: @color-gray-medium-light;
							border-color: @color-gray-medium-light;
						}
					}
				}
			}
		}
		margin-top: 1em;
		margin-right: -20px;
		&.margt {
			margin-top: 2em;
		}
		DIV.block01 > &:first-child {
			margin: 0 !important;
		}
		LI {
			width: 33.3333%;
			margin: 20px 0 0 !important;
			float: left !important;
			A.btn,
			SPAN.btn {
				background: lighten(@color-gray-lightest, 5%);
				display: block;
				float: none;
				text-align: left;
				margin: 0 20px 0 0;
				padding: 15px;
				min-height: 62px;
				.box-sizing(border-box);
				border: 1px solid @color-gray-medium-lightest;
				&:hover {
					border-color: @color01;
				}
				&.disabled:hover {
					border-color: @color-gray-medium-lightest;
					cursor: pointer;
				}
				&.hasico {
					position: relative;
					&.ico-alert:before,
					&.ico-alert02:before {
						position: absolute;
						left: 3px;
						top: 3px;
						width: 20px !important;
						height: 20px !important;
						background: @color02;
						border-radius: 50%;
						content: @ico-exclamation;
						color: @color-white;
						line-height: 20px;
						font-weight: bold;
						font-size: 12px;
						text-align: center;
					}
					&.ico-cart {
						&.ico-alert,
						&.ico-alert02 {
							&:before {
								display: block;
							}
						}

						&:before {
							display: none;
						}
						&:after {
							content: @ico-cart;
							color: @color02;
							font-family: "icons";
							position: absolute;
							left: 45px;
							top: 35px;
							font-size: 20px;
						}
					}
				}
				&.type02 {
					border-style: dashed;
				}
				&.type03 {
					background: @color-white;
					color: @color01;
					border: 2px solid @color01;
					.box-sizing(border-box);
					text-align: center;
					font-size: 18px;
					font-weight: bold !important;
					&:hover {
						border-color: @color02;
						color: @color02;
					}
					SPAN:first-child:last-child {
						margin-top: 4px;
						font-weight: normal;
					}
				}
				SPAN {
					&.hasico:before {
						font-weight: normal;
						height: auto;
						width: auto;
						margin-top: -2px;
					}
					line-height: 1.2;
					display: block;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					&.small {
						display: block;
						color: @color-gray-medium;
						font-size: 15px;
						line-height: 17px;
						margin: .2em 0 0 0;
						font-weight: normal;
						SPAN.price {
							float: right;
							font-weight: bold;
							margin-top: 0;
						}
					}
					&:first-child:last-child {
						margin-top: 9px;
					}
				}
				&.selected SPAN.small {
					color: fade(@color-black, 50%);
				}
			}
			A.btn {
				SPAN.hasico.ico-plus,
				SPAN.hasico.ico-cross,
				SPAN.hasico.ico-minus {
					float: right;
					font-size: 15px;
					margin: -0em 0 0 0;
					line-height: 1;
					color: @color01;
					font-weight: normal;
					&:before {
						font-size: 10px;
						width: auto;
						height: auto;
						margin-right: 10px;
						position: relative;
						top: .3em;
					}
				}
				&.selected SPAN.hasico.ico-plus,
				&.selected SPAN.hasico.ico-cross,
				&.selected SPAN.hasico.ico-minus {
					color: @color01;
				}
			}
			SPAN.btn {
				A {
					float: right;
					font-size: 15px;
					margin: -0em 0 0 0;
					line-height: 1;
					&:before {
						font-size: 10px;
						width: auto;
						height: auto;
						margin-right: 10px;
						position: relative;
						top: .3em;
					}
				}
				&.selected A {
					color: @color01;
				}
				&.static:hover {
					border-color: @color-gray-light;
				}
			}
		}
		.w50p &,
		.w40p & {
			overflow: visible;
			LI {
				width: 100%;
				float: none;
			}
		}
		.w60p & {
			overflow: visible;
			margin: 2em 0 0 0;
			LI {
				width: 50%;
			}
		}
		@media screen and (min-width: 1024px) and (max-width: 1199px) {
			LI {
				width: 50%;
			}
		}
		@media screen and (min-width: 760px) and (max-width: 1023px) {
			LI {
				width: 100%;
				margin-right: 0;
				.w60p & {
					width: 100%;
					float: none;
				}
				A.btn,
				SPAN.btn {
					height: auto;
					padding: 10px 10px;
					margin-right: 0;
				}
			}
		}
		@media @media-mobile {
			max-height: none;
			overflow: visible;
			/*removed important 15.11.2018*/
			margin: .5em 0 0;

			LI {
				height: auto;
				width: 100%;
				margin: .5em 0 0 0;
				A.btn,
				SPAN.btn {
					height: auto;
					padding: 10px 10px;
					margin-right: 0;
					SPAN:first-child:last-child {
						padding-bottom: 5px;
					}
				}
				.w60p & {
					width: 100%;
					float: none;
				}
			}
		}
	}
	&.in1col LI {
		float: none;
		margin: 10px 0 0 0 !important;
		&:first-child {
			margin: 0 !important;
		}
		A {
			display: block;
			text-align: center;
		}
	}
	&.in2cols {
		H2 + & {
			margin: .3em 0 0 0;
		}
		LI {
			width: 49%;
			margin: 0 2% 5px 0 !important;
			float: left !important;
			&:nth-of-type(even) {
				margin-right: 0 !important;
			}
			A {
				display: block;
				float: none;
				text-align: center;
				margin: 0 0 10px 0;
				padding-left: 3px;
				padding-right: 3px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
	&.in3cols {
		LI {
			width: 32%;
			margin: 0 !important;
			float: left !important;
			A.btn,
			SPAN.btn {
				display: block;
				float: none;
				text-align: left;
				margin: 0 10px 10px 0;
			}
		}
	}

	LI {
		&, DIV.block01 & {
			float: left;
			margin: 5px 0 0 10px;
			/*removed important 15.11.2018 need check all actions01 inside block01 */
			padding: 0; // Todo: siin oli enne margin: 18px 0 0 10px, mis kohti see võis mõjutada
			&:first-child {
				margin: 5px 0 0 0;
			}
			&.f-right {
				float: right;
			}
		}
		&:before {
			display: none;
		}
		&:first-child,
		&.c-both {
			SPAN.text {
				padding-left: 0;
			}
			A, SPAN, INPUT {
				&.btn.discrete {
					margin-left: 0;
				}
			}
		}
		&:last-child {
			SPAN.text {
				padding-right: 0;
			}
			A, SPAN, INPUT {
				&.btn.discrete {
					margin-right: 0;
				}
			}
		}
		&.f-right SPAN.text {
			padding-left: 0;
			padding-right: 10px;
		}
		&.togglable {
			A.btn {
				&:after {
					font-family: "icons";
					content: @ico-arrdown;
					width: 24px;
					margin-left: 5px;
				}
			}
			&.open {
				A.btn {
					&:after {
						content: @ico-arrup;
					}
				}
				DIV.b {
					display: block;
				}
			}
			DIV.b {
				display: none;
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				margin: -10px 0 0 0;
				background: @color-white;
				border: 1px solid @color01;
				padding: 15px 5px 8px;
				z-index: 19;
				text-align: center;
				* {
					margin: 0;
				}
			}
		}
		&.mr0 {
			margin-right: 0 !important;
		}
		&.mt0 {
			margin-top: 0 !important;
		}
		&.f-right {
			float: right;
			margin: 5px 10px 0 0;
		}
		SPAN.text {
			float: left;
			padding: 7px 0;
			font-size: 14px;
		}
		/*removed important 19.11.2018*/
		/*DIV.row DIV.items & {
			margin: -1px 1em 0 0 !important;
		}*/
	}
	SPAN {
		background: none;
		&.text {
			display: inline-block;
			padding: 10px 0 9px 10px;
			font-size: 15px;
		}
	}
	.colorselect {
		LABEL {
			margin: 0 -5px 0 0;
			float: left;
			padding: 6px 12px 6px 0;
			border: 1px solid transparent;
			color: @color-black;
			font-size: 14px;
		}
		SELECT {
			margin: 0;
			border-color: @color-gray-medium-lightest;
			float: left;
			position: relative;
			z-index: 1;
			border-radius: 3px;
		}
	}
	INPUT.btn {
		margin: 0;
	}
	.hasico:before {
		top: .3em;
	}

	.col.w70p P + & {
		margin: 1em 0 0 0;
	}
	INPUT + &,
	SELECT + &,
	DIV#ac-container + & {
		margin: -1px 0 0 5px !important;
		padding: 1px 0 0 0 !important;
		@media @media-mobile {
			margin: 5px -1em 0 0 !important;
			float: none;
			position: relative;
			z-index: 10;
			LI {
				border-top: 1px solid transparent;
			}
		}
	}
	UL.msg02 & {
		float: left !important;
		padding: 0 !important;
		background: none;
		border: none;
		border-top: 1px solid transparent;
		margin: 5px 5px 0 0 !important;
		border-radius: 0 !important;
	}
	&.actions01__beside {
		width: 100%;
		LI {
			border: none;
			width: auto;
			display: inline-block;
			float: none;
		}
		@media @media-mobile {
			LI {
				/*important is temp issue with global btn list margin important*/
				margin: 0 0 0 15px!important;
				&:first-child {
					margin: 0!important;
				}
			}
		}
	}
	&.actions01__center {
		text-align: center;
		width: 100%;
		> li {
			float: none;
			display: inline-block;
		}
		&--popup {
			> li {
				width: 100%;
			}
		}
		/*if not this check above*/
		@media @media-mobile {
			> li {
				width: 100%;
			}
		}
	}
	&.actions01__pad {
		&--md {
			> LI {
				&:first-child {
					margin: 0;
				}
				margin: 15px 0 0 0;
			}
			&-inline {
				> LI {
					margin: 0 25px 0 0;
					display: inline-block;
					float: left;
					&:last-child {
						margin: 0;
					}
				}
			}
		}
		@media @media-mobile {
			> LI {
				&:first-child {
					margin: 0;
				}
				margin: 10px 0 0 0;
			}
		}
	}
	&.actions01__space-between {
		&--mobile {
			@media @media-mobile {
				width: 100%;
				> li {
					margin: 0;
					&:first-child  {
						float: left;
						margin-right: 25px;
					}
					&:last-child  {
						float: right;
					}
				}
			}
		}
	}
	&.actions01--nomargin {
		> LI {
			margin: 0;
		}
	}
	@media @media-notdesktop {
		&.margneg {
			margin: 0 !important;
			float: none;
			overflow: hidden;
		}

		H2.blockheading + & {
			&, &.margneg {
				margin: 0 !important;
				float: none;
				overflow: hidden;
				border-bottom: 1px solid lighten(@color-gray-lightest, 5%);
				padding: 0 8px !important;
				LI {
					float: left !important;
					padding: 0 3px;
					margin: 5px 5px 0 0 !important;
				}
			}
		}
	}
	@media @media-tablet {
		DIV.product-group H2 + &.margneg.f-right {
			float: right;
		}

		.col.w50p & {
			&.in2cols,
			&.in3cols,
			& {
				LI {
					float: none;
					width: 100%;
					margin-right: 0;
					margin-left: 0;
					* {
						float: none;
						width: 100%;
						text-align: center;
					}
				}
			}
		}

		LI {
			.col02 &,
			UL.products01 DIV.txt & {
				float: none;
				margin-left: 0;
				A.btn.discrete,
				INPUT.btn.discrete,
				SPAN.btn.discrete {
					margin: 0;
					text-align: center;
				}
			}
		}
	}
	@media @media-mobile {
		LI {
			&, DIV.block01 & {
				/*removed important 22.11.2018 */
				float: none;
				/*removed important 15.11.2018 */
				margin: 5px 0 0 0;
				text-align: center;
			}
			&.togglable {
				DIV.b {
					margin: 0;
				}
			}
		}

		&.in2cols,
		&.in3cols {
			LI {
				width: 100%;
				margin-right: 0;
			}
		}

		LI.left {
			text-align: left;
			A {
				margin: 0 !important;
			}
		}

	}
}

UL.actions02 {
	margin: 0;
	padding: 0;
	.display(flex);
	LI {
		&:before {
			display: none;
		}
		.display(flex);
		.flex-direction(row);
		.align-content(stretch);
		padding: 0 !important;
		A {
			.flex(0 0 auto);
			width: 100%;
			background: @color-white;
			.box-sizing(border-box);
			display: block;
			border: 1px solid @color01;
			padding: 10px;
			text-decoration: none;
			color: @color-black;
			text-align: center;
			&:before {
				display: block;
				font-size: 55px;
				color: @color01;
			}
			&:hover {
				border-color: @color02;
				color: @color-black;
				&:before {
					color: @color02;
				}
			}
		}
	}
	@media @media-mobile {
		.display(block);
		LI A {
			padding: 15px 10px 15px 80px;
			position: relative;
			text-align: left;
			&:before {
				position: absolute;
				left: 10px;
				top: 5px;
				font-size: 45px;
			}
		}
	}
}

UL.numbers02 {
	.clearfix();
	margin: 0 -10px 0 0 !important;
	padding: 0;
	.display(flex);
	flex-wrap: wrap;
	.link-outline-none;
	&.in3cols {
		LI {
			width: 33%;
			.flex(0 0 33%);
		}
	}
	&.w100p {
		LI {
			.flex(0 0 100%);
			width: 100%;
			padding: 0;
		}
	}
	LI {
		padding: 0 10px 0 0;
		.box-sizing(border-box);
		margin: 10px 0 0 0;
		float: left;
		width: 33.3333%;
		.flex(0 0 33.3333%);
		.display(flex);
		&:before {
			display: none;
		}
		> A,
		> SPAN {
			display: block;
			.flex(1);
			overflow: hidden;
			padding: 10px 20px;
			border: 1px solid @color-gray-light;
			background: lighten(@color-gray-lightest, 5%);
			color: @color-black;
			&.active {
				background: @color12;
				border-color: @color12;
			}
			&, &:hover {
				&.disabled {
					cursor: default;
					background: rgba(235, 235, 235, 0.2);
					border-color: transparent;
					.link-outline-none;
					SPAN.text {
						color: @color15;
					}
					SPAN.add {
						color: @color15;

					}
					SPAN.hasico {
						color: @color15;
						text-decoration: none;
					}
				}
				&.disabled-noico {
					SPAN.hasico,
					SPAN.add {
						display: none;
					}
				}
			}
			SPAN.hasico {
				float: right;
				padding: 0;
				color: @color01;
				max-width: 80px;
				position: relative;
				top: 2px;
				font-size: @base-font-size;
				&:hover {
					text-decoration: underline;
				}
				&:before {
					width: auto;
					height: auto;
					position: relative;
					top: .3em;
				}
			}
			SPAN.text {
				float: left;
				width: 100%;
				margin-right: -80px;
				padding-right: 90px;
				font-size: 15px;
				.box-sizing(border-box);
				SMALL {
					white-space: nowrap;
					font-size: 100%;
					color: fade(@color-black, 70%);
				}
			}
		}
		> A:hover {
			border-color: @color12;
			text-decoration: none;
		}
	}
	&.numbers02b {
		LI {
			text-align: center;
			width: 20%;
			.flex(0 0 20%);
			cursor: move;
			cursor: grab;
			cursor: -moz-grab;
			cursor: -webkit-grab;
			SPAN {
				background: @color-white;
				border-style: dashed;
				min-height: 20px;
				padding: 10px;
				&.active {
					background: @color12;
					border-color: @color12;
				}
			}
		}
	}
	&.numbers02c {
		LI {
			/*&:first-child {
				margin: 20px 0 0 0;
			}*/
			> A,
			> SPAN {
				.display(flex);
				.align-items(center); /*.justify-content(space-around);*/
				SPAN.hasico {
					line-height: 1.5;
					/*width: 100%;*/
					width: auto;
					text-align: right;
					float: none;
					&:before {
						float: none;
						top: .2em;
					}
				}
				&.disabled {
					SPAN.text {
						color: @color15;
						SMALL {
							color: @color15;
						}
					}
				}
				SPAN.text {
					float: none;
					width: auto;
					padding: 0;
					margin: 0 auto 0 0;
					SMALL {
						display: block;
						font-size: 80%;
					}
				}
			}
		}
	}
	/*&.numbers02d {
		LI {
			> A,
			> SPAN {
				.display(flex);
				.align-items(center);
				.justify-content(space-around);
				SPAN.text {
					.note {
						display: block;
						font-size: 80%;
					}
				}
				SPAN.hasico {
					top: 0;
					&:before {
						top: .3em;
					}
				}
			}
		}
	}*/
	@media @media-tablet {
		LI {
			width: 50%;
			.flex(0 0 50%);
		}

		&.numbers02b {
			LI {
				width: 33.3333%;
				.flex(0 0 33.3333%);
			}
		}

	}
	@media @media-mobile {
		margin: 0 !important;
		LI {
			width: 100%;
			.flex(0 0 100%);
			padding-right: 0;
		}

		SPAN.text {
			SMALL {
				display: block;
			}
		}

		&.numbers02b {
			LI {
				width: 100%;
				.flex(0 0 100%);
			}
		}
	}
}

/* buttons part02 */
.btn02 {
	border: 2px solid @color-blue01;
	margin-bottom: 20px;
	.equalheight & {
		.flex-align-self(stretch);
		width: 100%;
	}
	display: block;
	position: relative;
	.clearfix();
	text-decoration: none !important;
	width: 100%;
	text-align: center;
	padding-top: 200px;
	padding-bottom: 30px;
	color: @color-black;
	font-weight: bold;
	&:hover {
		text-decoration: none;
		border: 2px solid @color-orange01;
	}
	IMG {
		margin: 0 auto;
		display: block;
		text-align: center;
		padding: 30px 0 30px 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		.box-sizing(border-box);
	}
	&.btn02b {
		IMG {
			padding: 60px 0 30px 0;
		}
	}
	&.disabled {
		&,
		&:hover,
		&:focus,
		&:active {
			color: @color-black;
			border: 2px solid @color-blue01;
			opacity: 0.4;
			cursor: default;
			text-decoration: none;
		}
	}
}

.btn {
	A&,
	INPUT&,
	SPAN& {
		display: inline-block;
		cursor: pointer;
		line-height: 1.2 !important;
		padding: 10px 30px 9px;
		font-size: 15px;
		color: @color-black;
		border: none;
		background: @color-gray-lightest;
		.box-sizing(border-box);
		text-decoration: none !important;
		/*&.disabled {
			&, &:hover { cursor: default; background: @color-gray-lightest; opacity: 0.5 !important; }
		}*/
		&.disabled {
			&, &:hover {
				opacity: 0.4;
				cursor: default;
				text-decoration: none !important;
				.link-outline-none;
				/*!*remove this if need to go back to old disabled style*!
				color: @color01;
				!*border: 1px solid #0019af;*!
				border: none;
				background: none;*/
			}
		}
		&.nopadding {
			padding-left: 5px;
			padding-right: 5px;
			overflow: hidden;
			SPAN.a {
				margin-top: 4px;
			}
			SPAN.b {
				margin-bottom: 4px;
			}
		}
		&.desktop-hide {
			display: none;
			@media @media-mobile {
				display: block;
			}
		}
		SPAN.a {
			display: block;
			margin-top: -6px;
			text-align: center;
		}
		SPAN.b {
			display: block;
			font-size: ceil(@base-font-size*0.75);
			text-align: center;
			margin-bottom: -6px;
		}
		UL.actions01 LI & {
			margin: 0;
			&.discrete {
				margin: 0 30px;
				@media @media-tablet {
					margin: 0;
				}
				@media @media-mobile {
					margin: 0;
				}
			}
		}
		/*DIV.row DIV.items & {
			margin: 10px 10px 0 0;
		}*/
		DIV.row DIV.items > & {
			margin: 0 0 0 10px;
			&:first-child {
				margin: 0;
			}
			@media @media-mobile {
				margin-left: 0;
				margin-top: 10px;
			}
		}
		INPUT + &,
		SELECT + & {
			line-height: 1.1 !important;
			position: relative;
			top: 1px;
			.items & {
				top: 0;
			}
		}
	}

	A&,
	INPUT& {
		&:hover {
			background: @color-gray-lightest;
			text-decoration: none;
		}
		&.f-right {
			margin-right: 0;
			margin-left: 5px;
			float: right;
		}
		&.main {
			color: @color01;
			background: @color-white;
			border: 1px solid @color01;
			padding: 9px 29px 8px;
			&:hover {
				border-color: @color02 !important;
				color: @color02;
			}
			/*&:hover { border-color: @color01 !important; color: @color01; }*/
			&.ico-plus:hover:before {
				color: @color02;
			}
			/*&.ico-plus:hover:before { color: @color01; }*/
			&.disabled {
				&.ico-plus:hover:before {
					color: @color01;
				}
				&, &:hover {
					opacity: 0.4;
					cursor: default;
					text-decoration: none !important;
					.link-outline-none;
					/*remove this if need to go back to old disabled style*/
					color: @color01;
					border: 1px solid @color01;
					border-color: @color01 !important;
					background: none;
				}
			}
		}
		&.color01 {
			color: @color-white;
			background: @color01;
			&:hover {
				background: darken(@color01, 10%);
				color: @color-white;
			}
			&.disabled {
				&, &:hover {
					background: @color-gray-medium;
				}
			}
		}
		&.discrete {
			/*color: @color01; background: none; border: none; border-bottom: 1px solid @color01; padding: 10px 0 0; margin: 0 30px; border-radius: 0;
			&:hover { background: none; color: @color03; border-color: @color03; }*/
			color: @color01;
			background: none;
			border: none;
			padding: 10px 0 0;
			margin: 0 30px;
			border-radius: 0;
			&:hover {
				background: none; /*border-bottom: 1px solid @color01;*/
				text-decoration: underline !important;
				border: none;
			}
			UL.actions01 & {
				margin-left: 0;
			}
			&.hasico {
				border-bottom: none;
			}
			&.disabled {
				&, &:hover {
					border: none;
				}
			}
		}
		&.size01 {
			font-size: 26px;
			font-weight: bold;
			padding: 15px 30px;
			&.discrete {
				font-size: 17px;
				padding: 22px 0 0;
				font-weight: normal;
			}
		}
		&.size02 {
			font-size: 18px;
			font-weight: bold;
			padding: 12px 30px;
			&.discrete {
				font-size: 15px;
				padding: 12px 0 0;
				font-weight: normal;
			}
		}
		/*need confirmation*/
		&.size03 {
			width: 100%;
			padding: 10px;
			margin-right: 0;
			font-size: 14px;
			text-align: center;
		}
		&.size04 {
			width: 100%;
			padding: 6px;
			margin-right: 0;
			font-size: 12px;
			text-align: center;
		}
		&.size05 {
			width: 100%;
			margin-right: 0;
			font-size: 14px;
			text-align: center;
		}
		&.btn--fix-01 {
			width: 200px;
			padding: 10px;
			margin-right: 0;
			font-size: 14px;
			text-align: center;
			@media @media-notdesktop {
				width: 100%;
			}
		}
		&.margneg {
			margin-top: -2em;
			margin-bottom: 0;
			position: relative;
			z-index: 5;
			&.f-right {
				margin-top: -1.8em;
				margin-bottom: 0;
				position: relative;
				z-index: 5;
				H3 + & {
					margin-top: -1.8em;
				}
				H2 + & {
					margin-top: -2.15em;
				}
				& + & {
					margin-top: -2.15em;
				}
				.form01 H3:first-child + &,
				.form01 H3:first-child + & + & {
					margin-top: -1.3em;
				}
				.form01 H2:first-child + &,
				.form01 H1:first-child + &,
				.form01 H2:first-child + & + & {
					margin-top: -1.35em;
				}
			}
		}
		&.margneg02 {
			margin-top: -40px;
			margin-bottom: 0;
			position: relative;
			z-index: 5;
		}
		@media @media-mobile {
			float: none;
			display: block;
			width: 100%; .box-sizing(border-box);
			text-align: center;
			&.discrete {
				width: auto;
				display: inline-block;
				margin-left: 0;
				margin-right: 0;
				&:hover {
					border-bottom: 0;
				}
			}

			&.size01 {
				font-size: 25px;
				padding: 12px 30px;
			}

			&.f-right {
				margin-left: 0;
			}
		}
		@media @media-tablet {
			UL.tabs01 + &.margneg02 {
				margin-top: 0;
				&.f-right {
					float: none;
					display: block;
					border: 1px @color-gray-lightest;
					margin: 0 !important;
					padding: 7px 10px !important;
					border-style: none solid;
				}
			}
		}
	}

	A& {
		.padding-icons(@addpadding) {
			padding: @addpadding;
		}
		SPAN.text & {
			float: none;
			display: inline-block;
		}
		&:before {
			margin-right: 10px;
		}
		&.arrup,
		&.arrdown,
		&.add,
		&.remove,
		&.edit,
		&.view,
		&.print,
		&.list,
		&.compare,
		&.scrolldown,
		&.scrollup,
		&.settings,
		&.forward,
		&.back {
			background: none;
			font-weight: normal;
		}
		&.arrup,
		&.arrdown,
		&.remove,
		&.edit,
		&.view,
		&.settings,
		&.forward,
		&.back {
			color: @color01; /*padding: 7px 0 7px 0 !important;*/
			.padding-icons(7px 0 7px 0);
			&:before {
				float: left;
			}
			&:hover {
				color: darken(@color01, 10%);
				text-decoration: underline !important;
				&:before {
					text-decoration: none !important;
				}
			}
			DIV.row & { /*padding: 0 0 0 20px !important;*/
				.padding-icons(0 0 0 20px);
			}
			/*DIV.row02 & { padding: 0 !important; }*/
		}
		&.list,
		&.compare {
			color: @color01 !important;
			margin-left: 20px !important;
		}

		&.remove02 {
			height: 20px;
			top: -35px;
			position: relative;
			left: 103%;
		}
		&.add {
			/*color: @color-positive;*/
			color: @color01; /*padding: 2px 0 5px 0 !important;*/
			.padding-icons(2px 0 5px 0);
			&:before {
				content: "+";
				display: inline-block;
				text-align: center;
				margin: -2px 3px -5px 0;
				position: relative;
				top: 2px;
				line-height: 18px; /*color: @color-positive;*/
				color: @color01;
				font-weight: bold;
				font-size: 20px;
			}
			&:hover {
				text-decoration: underline;
			}
			TABLE & {
				padding: 3px 0 0 0 !important;
				&.margneg {
					margin-top: -7px;
				}
				&:before {
					content: "+";
				}
			}
			DIV.row & { /*padding: 0 0 0 20px !important;*/
				.padding-icons(0 0 0 20px);
			}
		}
		&.mobile-indent-neg {
			@media @media-mobile {
				text-indent: -10000px;
				&::before {
					text-indent: 0;
				}
			}
		}
		&.ico-plus {
			&:before {
				content: "+";
				display: inline-block;
				text-align: center;
				margin: -2px 3px -5px 0;
				position: relative;
				top: 1px;
				line-height: 18px; /*color: @color-positive;*/
				color: @color01;
				font-weight: bold;
				font-size: 20px;
			}
		}
		&.list {
			color: @color-positive;
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-list;
				font-family: "icons";
			}
		}
		&.compare {
			color: @color-positive;
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-columns;
				font-family: "icons";
			}
		}
		/*todo: can i remove this*/
		&.scrolldown {
			color: @color-positive;
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-arrdown;
				font-family: "icons";
			}
			TABLE & {
				text-indent: -10000px;
			}
			&-color01 {
				color: @color01;
			}
		}
		&.scrolldown {
			color: @color01;
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-arrdown;
				font-family: "icons";
			}
			TABLE & {
				text-indent: -10000px;
			}
		}
		/*todo: can i remove this*/
		&.scrollup {
			color: @color-positive;
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-arrup;
				font-family: "icons";
			}
			&-color01 {
				color: @color01;
			}
		}
		&.scrollup02 {
			color: @color01;
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-arrup;
				font-family: "icons";
			}
		}
		&.back {
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-arrleft;
				font-family: "icons";
			}
		}
		&.forward {
			padding: 7px 0 7px 0 !important;
			margin: 0;
			border: none;
			&:hover {
				border: none;
				&:after {
					float: right;
				}
			}
			&:after {
				content: @ico-arrright;
				font-family: "icons";
				left: 3px;
				float: right;
				top: 1px;
				position: relative;
			}
			.link-outline-none;
		}
		&.settings {
			padding: 7px 0 7px 0 !important;
			&:before {
				content: @ico-battery;
				font-family: "icons";
			}
		}
		&.visibleactive {
			visibility: hidden;
			TR.active & {
				visibility: visible;
			}
		}
		&.print {
			color: @color01;
			padding: 7px 0 7px 25px !important;
			background: url(ico_print.gif) no-repeat 0 .5em;
		}
		&.remove, &.edit, &.view, &.settings {
			&:before {
				font-family: "icons";
				margin-right: 3px;
			}
		}
		&.remove:before {
			content: @ico-cross;
		}
		&.edit:before {
			content: @ico-pencil;
			margin-right: 3px;
		}
		&.view:before {
			content: @ico-view;
			margin-right: 3px;
		}
		.ico {
			&:before {
				float: left;
				width: 16px;
				height: 16px;
				content: "";
				margin-right: 5px;
			}
		}

		&.fb,
		&.google,
		&.email {
			padding: 15px 20px;
			background: @color-white;
			border: 1px solid @color-gray-light;
			&:before {
				content: "";
				float: left;
				width: 26px;
				height: 26px;
				margin: -4px 10px -3px -5px;
				border-radius: 3px;
				.box-sizing(border-box);
			}
		}
		&.fb:before {
			background: url(old/logo_facebook.png) no-repeat -2px -2px;
		}
		&.google:before {
			background: url(old/logo_google.png) no-repeat -2px -2px;
		}
		&.email:before {
			background: url(old/logo_email.png) no-repeat -2px -2px;
		}
		&.btn-helper {
			&-color01 {
				&::before {
					color: @color03;
				}
			}
			&-color02 {
				&::before {
					color: @color02;
				}
			}
		}
	}
	INPUT& {
		&.idcard,
		&.mobileid {
			background: url(old/btn_services.png) no-repeat 50% -300px @color-white !important;
			text-indent: -10000px;
			width: 88px;
			text-transform: capitalize;
			display: block;
			float: left;
			border: none;
			cursor: pointer;
			margin: 10px 10px 0 0;
			height: 31px;
			&.disabled {
				&,
				&:hover {
					cursor: default;
					opacity: 0.5 !important;
					border-color: @color-gray-medium !important;
				}
			}
		}

		&.mobileid {
			background-position: 50% -300px !important;
			&:hover {
				background-position: 50% -300px !important;
				opacity: 0.8;
			}
		}
		&.idcard {
			background-position: 50% -250px !important;
			&:hover {
				background-position: 50% -250px !important;
				opacity: 0.8;
			}
		}
		&.bank_swed {
			display: block;
			text-transform: capitalize;
			text-indent: -10000px;
			height: 32px;
			width: 86px;
			.link-outline-none;
			&,
			&.disabled:hover {
				background: url(old/btn_services.png) no-repeat 50% 0;
				border: 1px solid @color-swed;
			}
		}
		&.bank_seb {
			display: block;
			text-transform: capitalize;
			text-indent: -10000px;
			height: 32px;
			width: 86px;
			.link-outline-none;
			&,
			&.disabled:hover {
				background: url(old/btn_services.png) no-repeat 50% -50px;
				border: 1px solid @color-seb;
			}
		}
		&.bank_danske {
			display: block;
			text-transform: capitalize;
			text-indent: -10000px;
			height: 32px;
			width: 86px;
			.link-outline-none;
			&,
			&.disabled:hover {
				background: url(old/btn_services.png) no-repeat 50% -100px;
				border: 1px solid @color-danske;
			}
		}
		&.bank_nordea {
			display: block;
			text-transform: capitalize;
			text-indent: -10000px;
			height: 32px;
			width: 86px;
			.link-outline-none;
			&,
			&.disabled:hover {
				background: url(old/btn_services.png) no-repeat 50% -150px; /*border: 1px solid @color-nordea;*/
			}
		}
		&.bank_krediit {
			display: block;
			text-transform: capitalize;
			text-indent: -10000px;
			height: 32px;
			width: 86px;
			.link-outline-none;
			&,
			&.disabled:hover {
				background: url(old/btn_services.png) no-repeat 50% -200px;
				border: 1px solid @color-krediit;
			}
		}
		&.bank_lhv {
			display: block;
			text-transform: capitalize;
			text-indent: -10000px;
			height: 32px;
			width: 86px;
			.link-outline-none;
			&,
			&.disabled:hover {
				background: url(old/btn_services.png) no-repeat 50% -350px;
				border: 1px solid @color-lhv;
			}
		}
	}
	SPAN& {
		cursor: default;
	}

	&.selected {
		A&,
		INPUT&,
		SPAN& {
			border: 1px solid @color-gray-medium;
			background: lighten(@color-gray-medium, 20%) !important;
			color: @color-white !important;
		}
		A&:hover,
		INPUT&:hover {
			background: lighten(@color-gray-medium, 10%) !important;
		}
		SPAN& {
			A.remove {
				float: right;
				width: 20px;
				height: 20px;
				margin-top: 5px;
				margin-right: -15px;
				&:before {
					content: @ico-cross;
					font-family: "icons";
				}
			}
		}
	}
	&.selected02 {
		A&,
		INPUT&,
		SPAN& {
			color: @color-black !important;
			cursor: default;
			border: 1px solid @color12 !important;
			background: @color12 !important;
			&.selected {
				cursor: pointer;
			}
			&.disabled,
			&.disabled:hover {
				background: @color12;
				border-color: @color12;
				opacity: 0.5 !important;
			}
		}
		A&:hover,
		INPUT&:hover {
			background: darken(@color12, 5%) !important;
			color: @color-black !important;
		}

	}
	&.hidden {
		display: none !important;
	}
	&.green {
		background: @color01;
		color: @color-white !important;
		&.ico-plus:before {
			color: @color-white;
		}
		&:hover {
			background: darken(@color01, 10%);
			color: @color-white !important;
		}
		&.disabled {
			/*&, &:hover { background: @color12; color: @color-white !important; opacity: 1 !important; }*/
			&, &:hover {
				background: darken(@color01, 10%);
				color: @color-white !important;
			}
		}
	}
	&.orange {
		background: @color02;
		color: @color-white !important;
		&.ico-plus:before {
			color: @color-white;
		}
		&:hover {
			background: lighten(@color02, 10%);
			color: @color-white !important;
		}
		&.disabled {
			&, &:hover {
				background: @color02;
				opacity: .5 !important;
			}
		}
	}
	&.blue {
		background: @color03;
		color: @color-white !important;
		&.ico-plus:before {
			color: @color-white;
		}
		&:hover {
			background: lighten(@color03, 10%);
			color: @color-white !important;
		}
		&.disabled {
			&, &:hover {
				background: @color03;
				opacity: .5 !important;
			}
		}
	}
	&.arr {
		&right,
		&left,
		&down,
		&up {
			padding-right: 10px !important;
			&:before {
				font-family: "icons";
				float: left; /*margin-left: 15px*/
			}
		}
		&right:before {
			content: @ico-arrright;
		}
		&down:before {
			content: @ico-arrdown;
		}

		&left {
			padding-left: 10px !important;
			&:before {
				content: @ico-arrleft;
				float: left;
				margin-right: 15px;
				margin-left: 0;
			}
		}
		&right {
			padding-left: 10px !important;
		}
		&up:before {
			content: @ico-arrup;
		}
	}
	&.add-to-cart {
		padding-left: 10px !important;
		&:after {
			content: @ico-cart;
			font-family: "icons";
			float: left;
			margin-right: 10px;
		}
	}
	&.internal {
		border-color: @color-status01-border;
		background: @color-status01;
		&:hover {
			color: @color-black;
			background: lighten(@color-status01, 10%);
		}
		&:before {
			content: "S";
			display: inline-block;
			height: 16px;
			width: 16px;
			max-width: 17px;
			max-height: 17px;
			margin: 0 10px 0 0;
			float: left;
			UL.actions01.in2cols & {
				display: none;
			}
		}
	}
	&.small {
		font-size: 12px;
		padding: 7px 10px;
		width: auto !important;
		&.main {
			padding: 6px 9px;
		}
	}
	&.xl {
		font-size: 18px;
		padding: 10px 30px;
	}
	&.xxl {
		font-size: 26px;
		font-weight: bold;
		padding: 15px 90px;
	}
	@media @media-mobile {
		.btn + &,
		INPUT + &,
		SELECT + &,
		TEXTAREA + & {
			margin-top: 5px;
		}

		+ .btn.discrete {
			width: 100%;
			display: block;
			margin: 5px 0 0 0;
			border-bottom: none;
			text-decoration: underline !important;
		}

		A.btn {
			&.arrup01,
			&.arrdown,
			&.remove,
			&.edit,
			&.view,
			&.print,
			&.add {
				display: block;
				text-align: left;
			}
		}

		&.margneg,
		&.margneg02,
		&.margneg02.f-right {
			A&, SPAN&, INPUT& {
				H3 + &,
				H2 + &,
				& + &,
				.form01 H1:first-child + &,
				.form01 H2:first-child + &,
				.form01 H3:first-child + & {
					margin-top: 0 !important;
					float: none;
					display: block;
					text-align: left;
				}

			}
		}

		&.list, &.compare {
			A& {
				margin-left: 0 !important;
				text-align: left;
			}
		}
	}
}
DIV.input-wrapper {
	float: left;
	font-size: 14px;
	color: @color-gray-medium;
	margin: 15px 10px 0 0;
	HEADER.sub & {
		margin: 0 10px 0 0;
		min-width: 210px;
		float: right !important;
		INPUT[type="date"], INPUT[type="datetime"], INPUT[type="datetime-local"], INPUT[type="email"], INPUT[type="month"], INPUT[type="number"], INPUT[type="password"], INPUT[type="search"], INPUT[type="tel"], INPUT[type="text"], INPUT[type="time"], INPUT[type="url"], INPUT[type="week"], INPUT:not([type]) {
			padding: 5px 15px 5px;
			height: 30px;
			width: 100%;
		}
		SPAN.note {
			line-height: 1.2;
			margin-top: 3px;
			max-width: 210px;
			&.error {
				color: @color-white;
			}
		}
	}
	&.with-label {

	}
	&.without-label {
		padding-top: 19px;
	}
	LABEL {
		display: block;
		line-height: 1.4;
	}
	A.note,
	SPAN.note {
		display: block;
		margin: 10px 0 0 0;
		max-width: 190px;
		font-size: 12px;
		&.error {
			color: @color-negative;
		}
	}
	&.mobile-w100p {
		@media @media-mobile {
			width: 100% !important;
		}
	}
	&.calc-2-10p {
		width: calc(1 / 2 * 100% - (1 - 1 / 2) * 10px);
		@media @media-notwide {
			.calc-2-10p {
				width: calc(1 / 2 * 100% - (1 - 1 / 2) * 5px);
			}
		}
	}
	&.input-wrapper--calc-2-5p {
		width: calc(1 / 2 * 100% - (1 - 1 / 2) * 5px);
		@media @media-notdesktop {
			width: 100%;
		}
		@media @media-notwide {
			width: 100%;
		}
	}
	&.input-wrapper--mt10 {
		margin-top: 10px;
	}
	.input-wrapper__indicator {
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(nowrap);
		&.input-wrapper__indicator--period {
			.w70min {
				min-width: 70px;
			}
		}

	}
	.input-wrapper__item {
		.display(flex);
		.align-items(center);
		.flex(1 1 auto);
		&.input-wrapper__item--base01 {
			.display(flex);
			.flex(0 1 auto);
		}
		&.input-wrapper__item--base02 {
			.display(flex);
			.flex(1 1 auto);
		}
		&.input-wrapper__item--w50p {
			width: 50%;
			@media @media-mobile {
				width: 100%;
			}
		}
		&.input-wrapper__item--w65p {
			width: 65%;
			@media @media-mobile {
				width: 100%;
			}
		}
		&.input-wrapper__item--w85p {
			width: 85%;
			@media @media-mobile {
				width: 100%;
			}
		}
	}
	&.input-wrapper__sortable-list {
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(wrap);
		float: none;
		width: 100%;
		margin: 0;
		.justify-content(space-around);
		@media @media-notdesktop {
			.flex-direction(column);
			.input-wrapper__list {
				width: 100%;
			}
		}
	}
	&.input-wrapper--flex {
		.flex-wrap(nowrap);
		.display(flex);
		.box-sizing(border-box);
		float: none;
		width: 100%;
		margin: 0;
	}
	.calc-2-10p {
		width: calc(1 / 2 * 100% - (1 - 1 / 2) * 10px);
		@media @media-notwide {
			width: calc(1 / 2 * 100% - (1 - 1 / 2) * 5px);
		}
	}
	.input-wrapper__item {
		.display(flex);
		.align-items(center);
		.flex(1 1 auto);
		position: relative;
		&.input-wrapper__item--checkout {
			width: 212px;
			padding: 0 20px 0 0;
			@media @media-tablet {
				width: 145px;
			}
			@media @media-mobile {
				width: 100%;
				padding: 0;
			}
		}
		&.input-wrapper__item--base01 {
			.flex(0 1 auto);
		}
		&.input-wrapper__item--w50p {
			width: 50%;
			@media @media-mobile {
				width: 100%;
			}
		}
	}
	.input-wrapper__msg {
		/*needs better strukture*/
		white-space: nowrap;
		.flex(1 1 auto);
		.display(flex);
		.align-items(center);
		.justify-content(flex-start);
		&.input-wrapper__msg--base01 {
			.display(flex);
			.flex(0 1 auto);
		}
		&.input-wrapper__msg--base02 {
			.display(flex);
			.flex(1 1 auto);
		}
	}
	&.mr27 {
		margin-right: 27px !important;
	}
	&.w100p {
		width: 100%;
	}
	&.w110 {
		width: 110px;
		float: left;
		/*SELECT {
			width: 100%;
		}*/
		@media @media-mobile {
			width: 110px;
			&.w100p {
				width: 100%;
			}
		}
	}
	&.w100 {
		width: 100px;
		float: left;
		/*SELECT {
			width: 100%;
		}*/
		@media @media-mobile {
			width: 100px;
			&.w100p {
				width: 100%;
			}
		}
	}
	&.w300 {
		width: 300px;
		float: left;
		/*SELECT {
			width: 100%;
		}*/
		@media @media-mobile {
			width: 100px;
			&.w100p {
				width: 100%;
			}
		}
	}
	&.w160 {
		width: 160px;
		float: left;
		/*SELECT {
			width: 100%;
		}*/
		@media @media-mobile {

			&.w100p {
				width: 100%;
			}
		}
	}
	&.w170 {
		width: 170px;
		float: left;
		/*SELECT {
			width: 100%;
		}*/
		@media @media-mobile {

			&.w100p {
				width: 100%;
			}
		}
	}
	&.w85 {
		width: 85px;
		float: left;
		/*color: @color-text-main;*/
		margin-top: 0;
		.currency {
			INPUT {
				height: 28px;
				padding-right: 25px;
			}
			&:after {
				width: 24px;
				height: 26px;
				line-height: 26px;
			}
		}
		@media @media-mobile {
			width: 85px;
			&.w100p {
				width: 100%;
			}
		}
	}
	.fs18 {
		font-size: 18px !important;
	}
	.desktop-hide {
		display: none;
	}
	&.input-label-dark {
		LABEL {
			color: @color-black;
		}
		INPUT {
			color: @color-black;
			font-weight: bold;
		}
	}
	&.input-size01 {
		INPUT {
			height: 45px;
			font-size: 18px;
		}
	}
	&.input-label-error {
		LABEL {
			color: @color-negative;
		}
	}
	&.center {
		LABEL {
			text-align: center;
		}
		INPUT {
			text-align: center;
		}
	}
	&.type01 {
		margin: 0;
		> LABEL {
			box-sizing: border-box;
			color: @color-gray01;
			font-family: @font-main;
			display: inline-block;
			font-weight: 600;
			line-height: 20px;
			padding: 0 10px 0 0;
			margin: 15px 0 8px;
			width: 100%;
			max-width: 500px;
			font-size: 15px;
		}
	}
	&.input-wrapper--nofloat {
		float: none;
	}
	&.w304 {
		width: 304px;
	}
	&.w100p {
		width: 100%;
	}
	&.max-width {
		max-width: 100%;
		float: none;
		@media @media-mobile {
			width: auto;
			max-width: 100%;
		}
	}
	.valitator {
		height: 50px;
		line-height: 50px;
		display: inline-block;
		border: none;
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		width: 30px;
		&--check {
			&:after {
				font-weight: 400;
				content: "\f00c";
				font-family: FontAwesome;
				margin-right: 5px;
				font-size: 15px;
				color: @color-green01;
			}
		}
	}
	/*change .currency into global name*/
	.currency {
		&.currency__label {
			width: auto;
			text-align: center;
			&:after {
				height: 50px;
				line-height: 50px;
				display: inline-block;
				border: 1px solid @color-gray05;
				position: relative;
				right: 0;
			}
			&.currency__label--onleft {
				-webkit-order: 0;
				-ms-flex--order: 0;
				order: 0;
				&:after {
					border-right: none;
				}
			}
			&.currency__label--onright {
				-webkit-order: 2;
				-ms-flex--order: 2;
				order: 2;
				&:after {
					border-left: none;
				}
			}
			&.no-icon {
				height: 50px;
				SPAN.text01 {
					line-height: 50px;
				}
			}
			&.currency__label--period {
				SPAN.text01 {
					position: relative;
					top: 0;
					bottom: 0;
					right: 0;
					margin: 0 auto;
					width: 50px;
					font-size: 15px;
					text-align: center;
					color: @color-gray03;
					font-weight: normal;
					font-family: @font-main;
					vertical-align: baseline;
					height: 50px;
					line-height: 50px;
					display: inline-block;
					border: 1px solid @color-gray05;
					background: @color-gray07;
				}
				&.currency__label--onright {
					SPAN.text01 {
						border-style: solid solid solid none;
					}
				}
				&.currency__label--onleft {
					SPAN.text01 {
						border-style: solid none solid solid;
					}
				}
				&:after {
					display: none;
				}
			}
		}
		&.currency--small {
			/*make it global with btn size style*/
		}
		&.currency--md {
			input[type="number"]::-webkit-outer-spin-button,
			input[type="number"]::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			input[type="number"] {
				-moz-appearance: textfield;
			}
			INPUT {
				padding: 0 40px 0 16px;
				height: 50px;
			}
			&:after {
				height: 50px;
				line-height: 50px;
			}
			&.error {
				/*take it to input global*/
				INPUT {
					padding: 0 40px 0 16px;
					height: 50px;
				}
			}
		}
		&.error {
			/*take it to input global*/
			INPUT {
				color: @color-negative;
				border: 1px solid lighten(@color-negative, 15%);
				height: 34px;
				/*padding-right: 40px;*/
				padding: 0 40px 0 5px;
			}
		}
		&.on-left {
			&:after {
				left: 1px;
				right: auto;
			}
			SPAN.text01 {
				left: 1px;
				right: auto;
			}
			INPUT {
				padding: 0 60px 0 5px;
			}
		}
		&.on-right {
			&:after {
				left: 1px;
				right: auto;
			}
			SPAN.text01 {
				right: 1px;
				left: auto;
			}
			INPUT {
				padding: 0 5px 0 60px;
			}
		}
		&.no-icon {
			&:after {
				width: 90px;
				content: '';
			}
			SPAN.text01 {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 1px;
				margin: 0 auto;
				width: 90px;
				/*width: 55px;
				height: 32px;*/
				line-height: 34px;
				text-align: center;
				font-size: 15px;
				color: @color-gray-medium;
				z-index: 9; /*or use rgba color as background*/
				font-weight: normal;
				font-family: @font-main;
				vertical-align: baseline;

			}
			INPUT {
				width: 100%;
				margin: 0;
				/*padding: 0;*/
				/*text-indent: 5.00px;*/
				text-indent: 0;
				/*background: lighten(@color-negative,35%);*/
				padding: 8px 90px 7px 5px;
			}
			&.error {
				INPUT {
					padding: 0 90px 0 5px;
				}
			}
			&.no-icon---sm {
				&:after {
					width: 40px;
					content: '';
				}
				SPAN.text01 {
					width: 40px;
				}
			}
		}
		/*&.text-icon {
			height: 50px;
			line-height: 50px;
			display: inline-block;
			border: 1px solid #c9cad1;
			position: relative;
			right: 0;
			position: absolute;
			padding-right: 2px;
			!* font-family: icons; *!
			content: "\0020AC";
			top: 0;
			bottom: 0;
			right: 1px;
			margin: auto;
			width: 35px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			background: #f0f1f5;
			font-size: 18px;
			color: #5f6d7a;


			position: absolute;
			top: 0;
			bottom: 0;
			right: 1px;
			margin: 0 auto;
			width: 100px;
			!*width: 55px;
			height: 32px;*!
			line-height: 34px;
			text-align: center;
			font-size: 15px;
			color: @color-gray-medium;
			z-index: 9; !*or use rgba color as background*!
			font-weight: normal;
			font-family: inherit;
			vertical-align: baseline;

		}*/
		&.quantity {
			&.et {
				&:after {
					content: 'tk';
				}
			}
			&.ru {
				&:after {
					font-family: font-heading-ru;
					content: 'шт';
				}
			}
		}
		&.area-code {
			&:after {
				content: '+372';
				font-size: 15px;
				width: 55px;
				font-weight: 400;
			}
			INPUT {
				padding: 0 5px 0 60px;
			}
			&.error {
				INPUT {
					padding: 0 5px 0 60px;
				}
			}
		}
		display: inline-block;
		width: 100%;
		margin: 0;
		padding: 0;
		position: relative;
		.box-sizing(border-box);
		INPUT {
			width: 100%;
			margin: 0;
			/*padding: 0;*/
			/*text-indent: 5.00px;*/
			/*background: lighten(@color-negative,35%);*/
			padding: 0 40px 0 5px;
			height: 35px;
		}
		&:after {
			position: absolute;
			padding-right: 2px;
			/*font-family: icons;*/
			content: "\0020AC";
			top: 0;
			bottom: 0;
			right: 1px;
			margin: auto;
			width: 35px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			background: @color-gray07;
			font-size: 18px;
			color: @color-gray03;
			/*border-right: 1px solid @color-gray05;*/
		}
		&--md {
			&:after{
				height: 48px;
				line-height: 48px;
			}
		}
		&.currency--md {
			INPUT {
				padding: 0 40px 0 16px;
				height: 50px;
			}
			&:after {
				height: 48px;
				line-height: 48px;
			}
			&.error {
				/*take it to input global*/
				INPUT {
					padding: 0 40px 0 16px;
					height: 50px;
				}
			}
		}
		&.w100p {
			width: 100%;
		}
		/*type-package__*/
		@media @media-mobile {
			&.w100p {
				width: 100%;
			}
		}
	}
	&.input-wrapper--prefix {
		.flex-wrap(nowrap);
		.display(flex);
		.box-sizing(border-box);
		float: none;
		width: 100%;
		margin: 0;
	}
	.prefix {
		.prefix__text {
			text-align: center;
			color: @color-gray03;
			font-weight: 400;
			font-family: @font-main;
			font-size: 15px;
			&.prefix__text--area-code {

			}
			&.prefix__text--period {

			}
		}
		.prefix__currency {
			padding: 0 6px;
			&::after {
				content: "\0020AC";
				font-size: 18px;
				color: @color-gray03;
			}
		}
		&.prefix__quantity {
			&.et {
				&::after {
					content: 'tk';
				}
			}
			&.ru {
				&::after {
					font-family: font-heading-ru;
					content: 'шт';
				}
			}
		}
		&.prefix__label {
			border: 1px solid @color-gray05;
			background: @color-gray07;
			.justify-content(center);
			.align-items(center);
			.display(flex);
			.flex(1 0 auto);
			padding: 0 8px;
			height: 50px;
			&.prefix__label--onleft {
				-webkit-order: -1;
				-ms-flex--order: -1;
				order: -1;
				border-style: solid none solid solid;
			}
			&.prefix__label--onright {
				-webkit-order: 1;
				-ms-flex--order: 1;
				order: 1;
				border-style: solid solid solid none;
			}
		}
		&.prefix__editable {
			border: none;
			background: none;
			.justify-content(center);
			.align-items(center);
			.display(flex);
			.flex(1 0 auto);
			height: 50px;
			width: 62px;
			padding: 0;
			&.prefix__editable--onleft {
				-webkit-order: -1;
				-ms-flex--order: -1;
				order: -1;
			}
			&.prefix__editable--onright {
				-webkit-order: 1;
				-ms-flex--order: 1;
				order: 1;
			}

		}
	}
	/*it replacing type01 from container*/
	.input-wrapper__description {
		font-size: 13px;
		font-weight: 400;
		line-height: 1.54;
		padding: 0 0 8px 0;
	}
	.input-wrapper__button {
		margin-top: 28px;
	}
	@media @media-mobile {
		float: none;
		padding: 0;
		margin-right: 0;
		width: 100%;
		&.f-left {
			float: left !important;
		}

		INPUT,
		SELECT {
			float: none;
			/*width: 100%;*/
			&.w90 {
				width: 90px;
			}
		}

		HEADER.sub & {
			float: left !important;
			width: 165px;
			min-width: 0;
		}
	}
}


DIV.button-wrapper {
	float: left;
	padding-top: 1.6em;
	font-size: 12px;
	color: @color-gray-medium;
	margin: 15px 0 0 0;
	HEADER.sub & {
		padding-top: 0;
		margin: 0 10px 0 0 !important;
		float: right !important;
		.btn {
			padding: 6px 15px;
		}
	}
	INPUT,
	SELECT {
		float: left;
		margin-right: 10px;
	}
	&.w100 {
		width: 100px;
		float: left;
		SELECT {
			width: 100%;
		}
		@media @media-mobile {
			width: 100%;
			&.w100p {
				width: 100%;
			}
		}
	}
	&.with-label {

	}
	&.without-label {

	}
	A {
		float: left;
		margin: .7em 0;
		text-align: center;
		&.btn {
			margin: 0;
		}
	}
	@media @media-mobile {
		float: none;
		padding: 0;
		margin-right: 0;
		HEADER.sub & {
			padding-top: 0;
			margin-right: 0 !important;
			margin-left: 0;
		}
	}
}

/* Service buttons */

.banklinks {
	margin: 10px 0 0 20px;
	padding: 0;
	overflow: hidden;
	list-style: none;
	LI {
		padding: 0 !important;
		float: left;
		background: none !important;
		margin: 10px 20px 0 0 !important;
		width: 86px;
		&:before {
			display: none;
		}
		INPUT {
			margin: 0 auto !important;
			text-transform: capitalize;
		}
		A {
			display: block;
			text-align: center;
			margin-top: 0.5em;
			text-decoration: none;
		}
		.col.w60p & {
			margin: 0 15px 0 0 !important;
		}
	}
	@media @media-mobile {
		margin: 20px 0;
		/*LI,
		.col.w60p & LI { width: auto; margin: 0 2% 0 0 !important; }*/
		.col.w60p & LI {
			width: 48%;
			margin: 0 2% 0 0 !important;
			&:first-child {
				margin: 0 2% 0 0 !important;
			}
		}

		LI {
			width: auto;
			margin: 0 2% 0 0 !important;
			&:first-child {
				margin: 0 2% 0 0 !important;
			}
		}

		LI INPUT {
			margin: 0 auto !important;
		}

		A {
			margin-top: 0;
			padding-bottom: 1em;
		}
	}
}

/* 10. Listings */
UL.type05 {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 15px;
	color: @color-gray-medium;
	LI {
		&.ico {
			padding: 0 0 0 50px;
			position: relative;
			margin: 20px 0 0 0;
			.box-sizing(border-box);
			&:first-child {
				margin: 0;
			}
			&:before {
				position: absolute;
				left: 10px;
				top: 0;
				height: 24px;
				width: 30px;
				font-size: 24px;
			}
			&.showtooltip {
				cursor: pointer;
				&:hover {
					color: @color03;
				}
			}
		}
	}
	&.type05b {
		.clearfix();
		margin: 1em 0 0 0;
		LI {
			float: left;
			width: 50%;
			margin: .5em 0 0 0 !important;
			white-space: normal !important;
			&:nth-child(odd) {
				clear: both;
			}
			&:before {
				color: @color-gray-medium;
				font-size: 18px;
			}
			@media @media-notdesktop {
				float: none;
				width: auto;
			}
		}
	}
}

UL.type06 {
	list-style: none;
	margin: 0 -10px;
	padding: 0 0 65px;
	font-size: 17px;
	LI.ico {
		padding: 0 10px;
		position: relative;
		margin: 65px 0 0 0;
		width: 33.3333%;
		float: left;
		.box-sizing(border-box);
		&.w100p {
			width: 100%;
		}
		&:nth-of-type(3n+1) {
			clear: both;
		}
		&:before {
			position: absolute;
			left: 10px;
			top: -10px;
			height: auto;
			width: auto;
			font-size: 45px;
			color: @color01;
		}
	}
	A.ask {
		position: relative;
		top: -2px;
		background: none;
		line-height: 12px;
		&:before {
			content: "?";
			font-size: 11px;
			font-weight: bold;
			line-height: 12px;
			border-bottom: 1px solid @color01;
		}
	}
	DL {
		font-size: 15px;
		margin-top: 1em;
		margin-bottom: 1em;
		DT {
			font-weight: bold;
			width: 45%;
		}
		DD {
			width: 52%;
			margin: 0 0 0 3%;
			&.ico {
				&:before {
					position: relative;
					top: .3em;
				}
				&-check:before {
					color: @color-positive;
				}
				&-minus:before {
					color: @color-negative;
				}
			}
		}
	}
	P {
		margin: .5em 0 0 0;
		&.heading {
			margin: 0;
			font-size: 20px;
			font-weight: bold;
			padding: 0 0 15px 70px;
			border-bottom: 1px solid @color-gray-lightest;
		}
	}
	UL {
		list-style: none;
		margin: 1.5em 0 0 0;
		padding: 0;
		LI {
			position: relative;
			padding-left: 30px;
			margin-top: .5em;
			&:before {
				font-family: "icons";
				content: @ico-arrright;
				position: absolute;
				left: 0;
				top: 0;
				color: @color03;
			}
		}
	}
	@media @media-mobile {
		LI.ico {
			width: auto;
			float: none;
		}
	}

	@media @media-tablet {
		LI.ico {
			width: 50%;
			&:nth-of-type(3n+1) {
				clear: none;
			}
			&:nth-of-type(2n+1) {
				clear: both;
			}
		}
	}
}

.type07 {
	position: relative;
	overflow: hidden;
	list-style: none;
	margin: 1em 0 0 0;
	padding: 0;
	&:first-child {
		margin: 0;
	}
	&.in2cols .news01,
	&.in2cols .news01 {
		width: 48%;
		&:before {
			width: 48%;
		}
	}
	&.in4cols .news01 {
		width: 22%;
		&:before {
			width: 22%;
		}
	}
	.news01 {
		position: static !important;
		float: left;
		width: 30%;
		margin: 0 0 0 4% !important;
		padding: 0;
		background: none;
		&.type02:before {
			border: 1px solid @color-gray-light;
		}
		&.type03 {
			border: 1px solid @color-gray-light;
			padding-top: 20px !important;
		}
		&:first-child {
			margin: 0 !important;
		}
		&:before {
			content: "" !important;
			position: absolute;
			top: 0;
			bottom: 0;
			left: auto !important;
			display: block;
			background: lighten(@color-gray-lightest, 5%);
			width: 30%;
			.box-sizing(border-box);
			SECTION.type01 &,
			SECTION.type04 & {
				background: @color-white;
			}
		}
		P.img,
		DIV.pad {
			position: relative;
		}
		&
		P.img {
			text-align: center;
			margin: 20px 4px 4px;
			IMG {
				max-width: 100%;
				max-height: 250px;
				height: auto;
				width: auto;
			}
		}
		P.img-large IMG {
			max-height: 420px
		}
		DIV.pad {
			padding: 30px 30px 70px 30px;
			font-size: 15px;
			&.pad02 {
				.display(flex);
				.flex-direction(column);
				.justify-content(center);
				.align-items(center);
			}
		}
		P {
			margin: 1em 0 0 0;
		}
		P.center IMG {
			margin: 0 auto;
		}
		P.heading01,
		SPAN.heading01 {
			display: block;
			margin: 0;
			font-size: 20px;
			color: @color02;
			line-height: 1.2;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		P.heading02,
		SPAN.heading02 {
			display: block;
			margin: 0;
			font-size: 24px;
			color: @color-black;
			font-weight: bold;
			line-height: 1.2;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		SPAN.heading01 {
			text-align: center;
		}
		P.heading01 A {
			color: @color02;
		}
		SPAN.price01 {
			display: block;
			color: @color-black;
			font-size: 22px;
			font-weight: bold;
			margin: .6em 0 0 0;
			text-align: center;
		}

		A:hover {
			text-decoration: none;
		}
	}
	A {
		text-decoration: none;
		&.btn {
			outline: none;
			margin-top: 1.5em;
			&.discrete {
				bottom: 0;
				margin: 0;
				background: transparent;
			}
			&:hover {
				outline: none;
			}
		}
	}
	&.nobg {
		.news01 {
			&:before {
				display: none;
			}
			DIV.pad {
				padding: 30px 0 70px;
			}
		}
	}
	&.slick-slider01 {
		padding-bottom: 80px;
		.display(flex);
		&.slick-initialized {
			display: block;
		}
		SECTION.content & {
			padding-bottom: 0;
		}
		> .news01 {
			max-width: none !important;
			.flex(0 0 20%);
			@media @media-notwide {
				.flex(0 0 25%);
			}
			@media @media-tablet {
				.flex(0 0 33.3333%);
			}
			@media @media-mobile {
				.flex(0 0 100%);
			}
		}
		.slick-track {
			.display(flex);
			.align-items(stretch);
			margin: 0 auto;
		}
		.news01 {
			.display(flex);
			background: @color-white;
			.flex-direction(column);
			float: left;
			height: auto;
			padding: 0;
			max-width: 370px;
			width: auto;
			margin: 0 5px !important;
			&:before {
				display: none;
			}
			&.slick-slide {
				max-width: none;
			}

			P.img {
				text-align: center;
				overflow: hidden;
				margin: 0;
				position: relative;
				&:before {
					content: "";
					display: block;
					padding-bottom: 60%;
				}
				A {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					display: block;
					height: 100%;
					background: no-repeat 50% 50%;
					background-size: cover;
				}
				IMG {
					display: block;
					margin: 0 auto;
				}
				&.img02 {
					&:before {
						display: none;
					}
					.col.w50p {
						padding-left: 5px;
						&:first-child {
							padding-right: 5px;
						}
					}
					min-height: 150px;
					max-height: 150px;
					padding: 0 10px;
					@media @media-tablet {
						max-height: 140px;
						min-height: 140px;
					}
					@media @media-mobile {
						max-height: 260px;
						min-height: 260px;
						.col {
							display: block;
							.clearfix();
							&:first-child {
								padding-top: 0;
							}
						}

						IMG {
							max-height: 100px;
							width: auto;
						}
					}
					A {
						position: static;
					}
				}
			}
			DIV.pad {
				/*.display(flex); .flex-wrap(wrap);*/
				padding: 20px 20px 60px;
				.flex(1 1 auto);
				position: relative;
				& > P {
					margin-top: .5em;
				}
				&.pad02 {
					& > P.heading01 {
						margin: 1em 0 0 0 !important;
					}
				}
			}
			A.btn {
				padding: 0;
				padding-left: 0 !important;
				margin: 2em 0 0 0;
				bottom: 20px;
				position: absolute;
				&.discrete {
					border-bottom: none;
				}
			}
			&:focus {
				outline: none;
			}
			P.heading01 {
				color: @color01;
				font-weight: bold;
				font-size: 22px !important;
				margin: 0 !important;
			}

		}
		@media @media-tablet {
			margin-bottom: 40px;
			padding-bottom: 0;
		}
		@media @media-mobile {
			margin-bottom: 40px;
			padding-bottom: 0;
			.news01 {
				min-height: 0;
				DIV.pad {
					padding-bottom: 60px;
					P {
						font-size: 14px;

					}
					A {
						text-align: left;
					}
				}
			}

			BUTTON.slick-arrow {
				display: none !important;
			}

			.hasarrows& {
				BUTTON.slick-arrow {
					display: inline-block !important;
					width: 60px;
					&.slick-disabled {
						display: none !important;
					}
					&:before {
						font-size: 60px;
					}
					&.slick-next {
						right: 10px;
					}
					&.slick-prev {
						left: 10px;
					}
				}
			}
		}
	}
	&.type07b .news01 {
		position: static !important;
		float: none;
		overflow: hidden;
		width: auto;
		margin: 1em 0 0 0 !important;
		background: lighten(@color-gray-lightest, 5%);
		&:before {
			display: none;
		}
		P.img {
			float: left;
			margin: 0;
			width: 360px;
		}
		DIV.pad {
			padding: 30px 30px 30px 390px;
			.news01 {
				text-align: left;
			}
		}
		A.btn {
			position: static;
		}
	}
	@media @media-tablet {
		&.news01 {
			float: none;
			margin: 20px 0 0 0 !important;
			padding: 3px !important;
			width: 100%;
			background: lighten(@color-gray-lightest, 5%);
			overflow: hidden;
			.box-sizing(border-box);
			&:before {
				display: none;
			}
			DIV.pad {
				width: 48%;
				float: left;
				margin: 0 0 0 2%;
				.box-sizing(border-box);
				padding: 1em;
			}
			P.img {
				float: left;
				margin: 0;
				width: 50%;
			}
		}

		&.type07b .news01 {
			DIV.pad {
				padding: 30px 30px 30px 32%;
				float: none;
				width: auto;
			}
			P.img {
				width: 30%;
			}
		}

		&.slick-slider01 .news01 {
			float: left;
			padding: 0 5px;
		}
	}

	@media @media-mobile {
		.news01 {
			/*quickfix*/
			width: 100% !important;
			float: none;
			width: 100%;
			margin: 20px 0 0 0 !important;
			background: lighten(@color-gray-lightest, 5%);
			padding: 0;
			overflow: hidden;
			min-height: 500px;
			&:before {
				display: none;
			}
			DIV.pad {
				padding: 0 15px 15px;
			}
		}

		&.slick-slider01 .news01 {
			float: left;
			padding: 5px 5px;
		}

		&.type07b .news01 {
			P.img {
				width: auto;
				float: none
			}
			DIV.pad {
				padding: 30px;
				float: none;
				width: auto;
			}
		}
	}
}

UL.type08 {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 14px;
	.display(flex);
	.flex-wrap(wrap);
	.justify-content(flex-start);
	.flex-flow(row wrap);
	.align-content(space-between);
	& > LI {
		background: @color-white;
		margin-top: 1%;
		min-height: 160px;
		border: 1px solid @color-gray-lightest;
		.box-sizing(border-box);
		padding: 10px !important;
		&:before {
			display: none;
		}
	}
	&.in3cols > LI {
		width: 31%;
	}
	&.in4cols > LI {
		width: 22%;
	}
	DIV.txt {
		float: left;
		width: 100%;
		margin-left: -100px;
		padding-left: 110px;
		.box-sizing(border-box);
		&:first-child {
			margin-right: -100px;
			padding-right: 110px;
			margin-left: 0;
			padding-left: 0;
		}
		*:first-child {
			margin: 0;
		}
		P {
			margin: .5em 0 0 0;
		}
	}
	DIV.img {
		float: left;
		width: 100px;
	}
	.ico {
		&:before {
			width: auto;
		}
		&-document:before {
			top: 0;
			position: relative;
			color: @color01;
			margin: 0;
			font-size: 16px;
			text-align: center;
			float: none;
		}
	}
	UL {
		margin: .5em 0 0 0;
	}
	@media @media-notwide {
		&.in4cols > LI {
			width: 31%;
		}
	}
	@media @media-tablet {
		&.in4cols LI,
		&.in3cols LI {
			width: 47%;
		}
	}
	@media @media-mobile {
		&.in3cols LI,
		&.in4cols LI {
			width: 100%;
		}
	}
}

UL.type09 {
	background: @color-white;
	border: 2px solid @color-gray-light;
	position: relative;
	list-style: none;
	padding: 0;
	margin: 2em 0 0 0;
	min-height: 335px;
	LI {
		position: static !important;
		padding: 0 !important;
		margin: 0 !important;
		* {
			color: @color-black;
		}
		&:before {
			display: none;
		}
		&:first-child DIV.a {
			border-top: none;
		}
		&.active {
			DIV.b {
				display: block;
			}
			DIV.a A {
				background: @color01;
				color: @color-white; /*height: 21px;*/
				.box-sizing(border-box);
				&:after {
					left: 100%;
					top: 50%;
					border: solid;
					content: "";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(0, 25, 175, 0);
					border-left-color: @color01;
					border-width: 21px;
					margin-top: -21px;
				}
			}
		}
	}
	DIV.a {
		position: relative;
		z-index: 2;
		width: 30%;
		border-right: 1px solid @color-gray-light;
		border-top: 1px solid @color-gray-light;
		.box-sizing(border-box);
		A {
			display: block;
			padding: 10px 20px;
			text-decoration: none;
			color: @color01;
			position: relative;
		}
	}
	DIV.b {
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		width: 70%;
		bottom: 0;
		padding: 5px 30px 5px 50px;
		.box-sizing(border-box);
	}
	.chart {
		margin: 1em 0 0 0;
		text-align: center;
		IMG {
			max-height: 100%;
			max-width: 100%;
			width: auto;
			height: auto;
		}
	}
	@media @media-tablet {
		.chart {
			height: 200px;
		}
	}
	@media @media-mobile {
		padding-bottom: 270px;
		DIV.a {
			width: auto;
			border-right: none;
			A {
				padding: 10px;
				&:after {
					display: none;
				}
				&:before {
					float: right;
					font-family: "icons";
					content: @ico-plus;
				}
			}
		}

		DIV.b {
			position: static;
			padding: 5px 10px;
			width: 100%;
			H4 {
				margin: 0;
			}
		}

		LI.active DIV.a A:before {
			content: @ico-minus;
		}
	}
}

UL.type10 {
	list-style: none;
	padding: 0;
	margin: 2em 0 0 0;
	overflow: hidden;
	LI {
		width: 20%;
		float: left;
		margin: 0;
		padding: 0 10px;
		text-align: center;
		.box-sizing(border-box);
		&:before {
			display: none;
		}
	}
	P.txt {
		font-size: 15px;
		color: @color-gray-medium;
		STRONG {
			display: block;
			font-size: 36px;
			color: @color02;
		}
	}
	@media @media-tablet {
		text-align: center;
		LI {
			width: 28%;
			display: inline-block;
			float: none;
		}

		P.txt {
			font-size: 13px;
		}
	}
	@media @media-mobile {
		LI {
			width: auto;
			float: none;
			padding: 0;
			margin: 1em 0 0 0;
			overflow: hidden;
		}

		P.img {
			float: left;
			width: 100px;
			margin: 10px 0 0 0;
		}

		P.txt {
			font-size: 13px;
			padding-left: 120px;
			text-align: left;
		}
	}
}

UL.type11 {
	list-style: none;
	padding: 0;
	margin: 2em 0 0 0;
	overflow: hidden;
	LI {
		width: 50%;
		float: left;
		margin: 10px 0 0 0;
		padding: 0 5px;
		text-align: center;
		.box-sizing(border-box);
		&:first-child {
			width: 100%;
		}
		&:before {
			display: none;
		}
	}
	DIV {
		display: table;
		width: 100%;
	}
	A {
		text-shadow: 0 0 10px @color-black;
		line-height: 1.2;
		display: table-cell;
		height: 100px;
		vertical-align: middle;
		color: @color-white;
		padding: 10px;
		background: no-repeat 50% 50%;
		background-size: cover;
		font-size: 20px;
		text-transform: uppercase;
		&:hover {
			text-decoration: none;
			opacity: .7;
		}
	}
	@media @media-mobile {
		LI {
			width: 100%;
		}
	}
}

UL.type12 {
	list-style: none;
	padding: 0;
	margin: 2em 0 0 0;
	overflow: hidden;
	&.type12b {
		LI {
			height: 180px;
			SPAN {
				background: @color-gray-lightest !important;
				font-size: 18px;
				height: 180px;
				&, * {
					color: @color-gray-darkest !important;
				}
				> SPAN {
					&.small {
						display: block;
						clear: both;
						font-size: 13px;
						color: @color-gray-medium !important;
						height: auto !important;
						padding: 5px 0;
					}
				}
				STRONG {
					padding-bottom: 10px;
					&.small {
						font-size: 22px !important;
						line-height: 28px;
					}
				}
			}
		}
	}
	LI {
		width: 100%;
		float: left;
		display: table;
		margin: 10px 0 0 0;
		padding: 0 5px !important;
		text-align: center;
		.box-sizing(border-box);
		height: 210px;
		line-height: 1;
		font-family: @font-heading;
		.ru & {
			font-family: @font-heading-ru;
		}
		color: @color-white;
		font-size: 27px;
		SPAN {
			background: @color02;
			display: table-cell;
			height: 210px;
			padding: 0 5px 0;
			.box-sizing(border-box);
			vertical-align: middle;
		}
		&:nth-of-type(odd) SPAN {
			background: @color04;
		}
		STRONG {
			display: block;
			font-size: 70px;
		}
		@media @media-notwide {
			STRONG {
				font-size: 50px;
			}
		}
		&:before {
			display: none;
		}
		&:nth-last-child(2) {
			&,
			& ~ LI {
				width: 50%;
			}
		}
		&:nth-last-child(3) {
			width: 34%;
			& ~ li {
				width: 33%;
			}
		}
		&:nth-last-child(4) {
			&,
			& ~ li {
				width: 25%;
			}
		}
		&:nth-last-child(5) {
			&,
			& ~ li {
				width: 20%;
				font-size: 20px;
				STRONG {
					font-size: 55px;
				}
			}
		}
	}
	@media @media-tablet {
		LI {
			width: 50% !important;
		}
	}
	@media @media-mobile {
		LI {
			width: 100% !important;
		}
	}
}

UL.type13 {
	LI:before {
		content: @ico-check;
		color: @color02;
		top: .6em;
	}
}

UL.type14 {
	.clearfix();
	background: lighten(@color-gray-lightest, 5%);
	margin: 1em 0 0 0;
	LI {
		float: left;
		padding: 20px;
		max-width: 260px;
		font-size: 13px;
		color: @color-gray-medium;
		text-align: center;
		margin: 0;
		&:before {
			display: none;
		}
		P.img {
			height: 45px;
		}
		STRONG {
			display: block;
			font-size: 15px;
		}
	}
	@media @media-mobile {
		LI {
			float: none;
			padding: 20px 0;
		}
	}
}

UL.type15 {
	.clearfix();
	float: right;
	margin: 2em 0 0 0;
	font-family: @font-heading;
	font-weight: 300;
	.ru & {
		font-family: @font-heading-ru;
		LI {
			font-size: 18px;
			line-height: 40px;
		}
	}
	LI {
		float: left;
		font-size: 25px;
		position: relative;
		padding-left: 70px;
		padding-right: 20px;
		border-left: 2px solid @color-gray-lightest;
		&:first-child {
			border-left: none;
			padding-left: 50px;
			&:before {
				left: 0;
			}
		}
		&:last-child {
			padding-right: 0;
		}
		&:before {
			font-size: 32px;
			color: @color01;
			position: absolute;
			left: 20px;
			top: .15em;
		}

	}
	@media @media-notwide {
		DIV.block13 & {
			float: left;
			padding-left: 160px;
			top: -40px;
			position: relative;
		}
	}
	@media @media-tablet {
		float: none;
		clear: both;
		DIV.block13 & {
			float: none;
			margin: 1em 0 0 0;
			LI {
				float: none;
				border-left: 0;
				padding-left: 40px !important;
				font-size: 20px;
				&:before {
					left: 0;
					font-size: 32px;
					top: 0;
				}
			}
		}
	}
	@media @media-mobile {
		float: none;
		margin: 1em 0 0 0;
		LI {
			float: none;
			border-left: 0;
			padding-left: 40px !important;
			font-size: 20px;
			&:before {
				left: 0;
				font-size: 32px;
				top: 0;
			}
		}
	}

}

UL.type16 {
	position: relative;
	overflow: hidden;
	list-style: none;
	margin: 1em 0 0 0;
	padding: 0;
	&:first-child {
		margin: 0;
	}
	&.in2cols .news01,
	&.in2cols .news01 {
		width: 48%;
		&:before {
			width: 48%;
		}
	}
	&.in4cols .news01 {
		width: 22%;
		&:before {
			width: 22%;
		}
	}
	.news01 {
		position: static !important;
		float: left;
		width: 22%;
		margin: 0 0 0 4% !important;
		padding: 0;
		background: none;
		&.type02:before {
			border: 1px solid @color-gray-light;
		}
		&.type03 {
			border: 1px solid @color-gray-light;
			padding-top: 20px !important;
		}
		&:first-child {
			margin: 0 !important;
		}
		&:before {
			content: "" !important;
			position: absolute;
			top: 0;
			bottom: 0;
			left: auto !important;
			display: block;
			background: lighten(@color-gray-lightest, 5%);
			width: 30%;
			.box-sizing(border-box);
			SECTION.type01 &,
			SECTION.type04 & {
				background: @color-white;
			}
		}
		P.img,
		DIV.pad {
			position: relative;
		}
		&
		P.img {
			text-align: center;
			margin: 20px 4px 4px;
			IMG {
				max-width: 100%;
				max-height: 250px;
				height: auto;
				width: auto;
			}
		}
		P.img-large IMG {
			max-height: 420px
		}
		DIV.pad {
			padding: 30px 30px 70px 30px;
			font-size: 15px;
			&.pad02 {
				.display(flex);
				.flex-direction(column);
				.justify-content(center);
				.align-items(center);
			}
		}
		P {
			margin: 1em 0 0 0;
		}
		P.center IMG {
			margin: 0 auto;
		}
		P.heading01,
		SPAN.heading01 {
			display: block;
			margin: 0;
			font-size: 20px;
			color: @color02;
			line-height: 1.2;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		P.heading02,
		SPAN.heading02 {
			display: block;
			margin: 0;
			font-size: 24px;
			color: @color-black;
			font-weight: bold;
			line-height: 1.2;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		SPAN.heading01 {
			text-align: center;
		}
		P.heading01 A {
			color: @color02;
		}
		SPAN.price01 {
			display: block;
			color: @color-black;
			font-size: 22px;
			font-weight: bold;
			margin: .6em 0 0 0;
			text-align: center;
		}

		A:hover {
			text-decoration: none;
		}
	}
	A {
		text-decoration: none;
		&.btn {
			outline: none;
			margin-top: 1.5em;
			&.discrete {
				bottom: 0;
				margin: 0;
				background: transparent;
			}
			&:hover {
				outline: none;
			}
		}
	}
	&.nobg {
		.news01 {
			&:before {
				display: none;
			}
			DIV.pad {
				padding: 30px 0 70px;
			}
		}
	}
	&.slick-slider01 {
		padding-bottom: 80px;
		.display(flex);
		&.slick-initialized {
			display: block;
		}
		SECTION.content & {
			padding-bottom: 0;
		}
		> .news01 {
			max-width: none !important;
			.flex(0 0 20%);
			@media @media-notwide {
				.flex(0 0 25%);
			}
			@media @media-tablet {
				.flex(0 0 33.3333%);
			}
			@media @media-mobile {
				.flex(0 0 100%);
			}
		}
		.slick-track {
			.display(flex);
			.align-items(stretch);
			margin: 0 auto;
		}
		.news01 {
			.display(flex);
			background: @color-white;
			.flex-direction(column);
			float: left;
			height: auto;
			padding: 0;
			max-width: 370px;
			width: auto;
			margin: 0 5px !important;
			&:before {
				display: none;
			}
			&.slick-slide {
				max-width: none;
			}

			P.img {
				text-align: center;
				overflow: hidden;
				margin: 0;
				position: relative;
				&:before {
					content: "";
					display: block;
					padding-bottom: 60%;
				}
				A {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					display: block;
					height: 100%;
					background: no-repeat 50% 50%;
					background-size: cover;
				}
				IMG {
					display: block;
					margin: 0 auto;
				}
				&.img02 {
					&:before {
						display: none;
					}
					.col.w50p {
						padding-left: 5px;
						&:first-child {
							padding-right: 5px;
						}
					}
					min-height: 150px;
					max-height: 150px;
					padding: 0 10px;
					@media @media-tablet {
						max-height: 140px;
						min-height: 140px;
					}
					@media @media-mobile {
						max-height: 260px;
						min-height: 260px;
						.col {
							display: block;
							.clearfix();
							&:first-child {
								padding-top: 0;
							}
						}

						IMG {
							max-height: 100px;
							width: auto;
						}
					}
					A {
						position: static;
					}
				}
			}
			DIV.pad {
				/*.display(flex); .flex-wrap(wrap);*/
				padding: 20px 20px 60px;
				.flex(1 1 auto);
				position: relative;
				& > P {
					margin-top: .5em;
				}
				&.pad02 {
					& > P.heading01 {
						margin: 1em 0 0 0 !important;
					}
				}
			}
			A.btn {
				padding: 0;
				padding-left: 0 !important;
				margin: 2em 0 0 0;
				bottom: 20px;
				position: absolute;
				&.discrete {
					border-bottom: none;
				}
			}
			&:focus {
				outline: none;
			}
			P.heading01 {
				color: @color01;
				font-weight: bold;
				font-size: 22px !important;
				margin: 0 !important;
			}

		}
		@media @media-tablet {
			margin-bottom: 40px;
			padding-bottom: 0;
		}
		@media @media-mobile {
			margin-bottom: 40px;
			padding-bottom: 0;
			.news01 {
				min-height: 0;
				DIV.pad {
					padding-bottom: 60px;
					P {
						font-size: 14px;

					}
					A {
						text-align: left;
					}
				}
			}

			BUTTON.slick-arrow {
				display: none !important;
			}

			.hasarrows& {
				BUTTON.slick-arrow {
					display: inline-block !important;
					width: 60px;
					&.slick-disabled {
						display: none !important;
					}
					&:before {
						font-size: 60px;
					}
					&.slick-next {
						right: 10px;
					}
					&.slick-prev {
						left: 10px;
					}
				}
			}
		}
	}
	&.type07b .news01 {
		position: static !important;
		float: none;
		overflow: hidden;
		width: auto;
		margin: 1em 0 0 0 !important;
		background: lighten(@color-gray-lightest, 5%);
		&:before {
			display: none;
		}
		P.img {
			float: left;
			margin: 0;
			width: 360px;
		}
		DIV.pad {
			padding: 30px 30px 30px 390px;
			.news01 {
				text-align: left;
			}
		}
		A.btn {
			position: static;
		}
	}
	@media @media-tablet {
		&.news01 {
			float: none;
			margin: 20px 0 0 0 !important;
			padding: 3px !important;
			width: 100%;
			background: lighten(@color-gray-lightest, 5%);
			overflow: hidden;
			.box-sizing(border-box);
			&:before {
				display: none;
			}
			DIV.pad {
				width: 48%;
				float: left;
				margin: 0 0 0 2%;
				.box-sizing(border-box);
				padding: 1em;
			}
			P.img {
				float: left;
				margin: 0;
				width: 50%;
			}
		}

		&.type07b .news01 {
			DIV.pad {
				padding: 30px 30px 30px 32%;
				float: none;
				width: auto;
			}
			P.img {
				width: 30%;
			}
		}

		&.slick-slider01 .news01 {
			float: left;
			padding: 0 5px;
		}
	}

	@media @media-mobile {
		.news01 {
			float: none;
			width: 100%;
			margin: 20px 0 0 0 !important;
			background: lighten(@color-gray-lightest, 5%);
			padding: 0;
			overflow: hidden;
			min-height: 500px;
			&:before {
				display: none;
			}
			DIV.pad {
				padding: 0 15px 15px;
			}
		}

		&.slick-slider01 .news01 {
			float: left;
			padding: 5px 5px;
		}

		&.type07b .news01 {
			P.img {
				width: auto;
				float: none
			}
			DIV.pad {
				padding: 30px;
				float: none;
				width: auto;
			}
		}
	}
}

.counter01 {
	.display(flex);
	.flex-wrap(wrap);
	.justify-content(flex-start);
	margin: 2em 0 0 0;
	padding: 0;
	.box-sizing(border-box);
	.clearfix();
	border: none;
	&:last-child {
		border-right: 0;
	}
	LI {
		.display(flex);
		.flex-direction(column);
		line-height: 1.2;
		position: relative;
		font-size: ceil(@base-font-size*1.15);
		.flex(0 0 auto);
		width: 20%;
		/*border-right: 1px solid @color-gray-medium-light;*/
		color: @color-black-medium;
		.align-items(flex-start);
		.justify-content(flex-start);
		.box-sizing(border-box);
		text-align: left;
		padding: 0;
		margin: 0;

		> * {
			position: relative;
			z-index: 2;
		}
		STRONG {
			display: block;
			width: 100%;
			font-size: ceil(@base-font-size*2);
			SMALL {
				font-weight: normal;
				font-size: @base-font-size;
				&.muted {
					display: block;
					color: @color-gray-medium;
					font-size: ceil(@base-font-size*0.92);
				}
			}
		}

		.pad {
			margin: 0;
			padding: 20px 0 20px 15px;
			width: 100%;
			.box-sizing(border-box);
		}
		SMALL.muted {
			color: @color-black-light;
		}
		border-left: 1px solid @color-gray-medium-light;
		&:first-child {
			padding: 0;
			margin: 0;
			border-left: none;
			.pad {
				margin: 0;
				padding: 20px 0 20px 0;
				width: 100%;
			}
		}
		&::before {
			display: none;
		}
		&::after {
		}
		/*&.hasborder {
			border-right: 1px solid @color-gray-medium-light;
		}*/

	}
	@media @media-tablet {
		LI {
			border-top: 0;
			/*&:nth-of-type(n+4) {
				border-top: none;
			}*/
		}
	}

	/*@media @media-tablet {
		border-left: none;
		LI {
			.flex(0 0 33.3333%); width: 33.3333%;
			&:nth-of-type(3n+3):after { display: none; }
			&:nth-of-type(n+4) {
				border-top: 2px solid @color-gray-lightest; padding-top: 95px;
				&:before { top: 20px; }
			}
		}
	}*/
	/*@media @media-mobile {
		border-left: none;
		LI {
			.flex(0 0 100%); width: 100%; padding: 10px 33% 10px 75px; text-align: left; clear: both;
			&:before { left: 0; top: 10px; width: 56px; right: auto; }
			&:after { display: none; }
			border: 2px @color-gray-lightest; border-style: none none solid none !important;
			STRONG { position: static; }
			SMALL.muted { position: absolute; right: 0; top: 10px; width: 30%; }
		}
	}*/

	@media only screen and (max-width: 800px) {
		margin: 0 -20px;
		LI {
			.flex(0 0 100%);
			width: 100%;
			border: 1px @color-gray-medium-light;
			padding: 0 20px;
			margin: 0;
			border-style: solid none none none !important;
			text-align: left;
			clear: both;
			&:first-child {
				margin: 0;
				border-style: solid none none none !important;

			}
			&:last-child {
				margin: 0;
				border-style: solid none solid none !important;

			}
			.pad {
				padding: 20px 0 20px 0;
				margin: 0;
			}
			&:first-child {
				padding: 0 20px;
				.pad {
					margin: 0;
					padding: 20px 0 20px 0;
				}
			}
			SMALL.muted {
				position: absolute;
				right: 0;
				top: 50%;
				width: 30%;
			}
		}
	}
}

UL.params {
	.display(flex);
	.flex-wrap(wrap);
	.justify-content(flex-start);
	margin: 2em 0 0 0;
	padding: 0;
	.box-sizing(border-box);
	.clearfix();
	border-left: 2px solid @color-gray-lightest;
	LI {
		.flex(0 0 16.6666%);
		width: 16.6666%;
		text-align: center;
		margin: 0;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
		float: left;
		padding: 75px 0 10px 0;
		line-height: 1.2;
		position: relative;
		.box-sizing(border-box);
		font-size: ceil(@base-font-size*1.15);
		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			border-right: 2px solid @color-gray-lightest;
		}
		&:nth-of-type(6n+1) {
		}
		> * {
			position: relative;
			z-index: 2;
		}
		STRONG {
			display: block;
			width: 100%;
			font-size: ceil(@base-font-size*2);
			SMALL {
				font-weight: normal;
				font-size: @base-font-size;
				&.muted {
					display: block;
					color: @color-gray-medium;
					font-size: ceil(@base-font-size*0.92);
				}
			}
		}
		&:before {
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 0;
			z-index: 2;
			font-family: "icons";
			color: @color02;
			font-size: ceil(@base-font-size*4);
		}
		&.type01:before {
			content: @ico-down;
			color: @color-black;
			border: 3px solid @color02;
			.rounded(50%);
			width: 30px;
			height: 30px;
			.box-sizing(border-box);
			line-height: 24px;
			font-size: @base-font-size;
			text-align: center;
		}
		&.type02:before {
			content: @ico-up;
			color: @color-black;
			border: 3px solid @color02;
			.rounded(50%);
			width: 30px;
			height: 30px;
			.box-sizing(border-box);
			line-height: 24px;
			font-size: @base-font-size;
			text-align: center;
		}
		&.type03:before {
			content: @ico-unlimited;
		}
		&.type04:before {
			content: @ico-chat;
		}
		&.type05:before {
			content: @ico-calls;
		}
		&.type06:before {
			content: "";
			background: url(ico02.png) no-repeat 50% 50%;
			height: 56px;
		}
		&.type07:before {
			content: @ico-4g;
		}
		&.type08:before {
			content: @ico-network;
		}
		&.type09:before {
			content: "";
			background: url(ico03.png) no-repeat 50% 0;
			height: 57px;
		}
		&.type10:before {
			content: "";
			background: url(ico04.png) no-repeat 50% 0;
			height: 57px;
		}
		&.type11:before {
			content: "";
			background: url(ico05.png) no-repeat 50% 0;
			height: 57px;
		}

	}
	@media @media-tablet {
		border-left: none;
		LI {
			.flex(0 0 33.3333%);
			width: 33.3333%;
			&:nth-of-type(3n+3):after {
				display: none;
			}
			&:nth-of-type(n+4) {
				border-top: 2px solid @color-gray-lightest;
				padding-top: 95px;
				&:before {
					top: 20px;
				}
			}
		}
	}
	@media @media-mobile {
		border-left: none;
		LI {
			.flex(0 0 100%);
			width: 100%;
			padding: 10px 33% 10px 75px;
			text-align: left;
			clear: both;
			&:before {
				left: 0;
				top: 10px;
				width: 56px;
				right: auto;
			}
			&:after {
				display: none;
			}
			border: 2px @color-gray-lightest;
			border-style: none none solid none !important;
			STRONG {
				position: static;
			}
			SMALL.muted {
				position: absolute;
				right: 0;
				top: 10px;
				width: 30%;
			}
		}
	}
}

DIV.credit01 {
	padding: 0;
	float: right;

	&.credit01b {
		.display(flex);
		.align-items(flex-end);
		SPAN {
			&:before {
				color: @color01;
				content: @ico-coins;
				font-size: 29px;
				top: inherit;
				bottom: -5px;
			}
			color: @color16;
			line-height: 1.64;
			STRONG {
				color: @color16;
				font-size: 30px;
			}
		}
		@media @media-notdesktop {
			.flex-direction(column);
			.align-items(self-start);
			width: 100%;
			.btn {
				width: 100%;
			}
		}

	}

	SPAN {
		float: left;
		position: relative;
		margin-right: 30px;
		font-size: 15px;
		color: @color-gray-medium;
		padding: 0 0 0 50px;
		&:before {
			position: absolute;
			z-index: 2;
			color: @color-gray-medium;
			font-family: "icons";
			content: @ico-money;
			font-size: ceil(@base-font-size*3);
			top: -5px;
			left: 0;
		}
	}
	STRONG {
		display: block;
		font-size: 26px;
		line-height: 1;
		color: @color-black;
	}
	/*.btn {
		float: right; background: @color-white; border: 2px solid @color01; margin: 8px 0 0 0; color: @color01;
		&:hover { border-color: @color02; color: @color02; }
	}*/
	@media @media-notdesktop {
		min-width: 150px;
		SPAN {
			margin-right: 0;
		}

		.btn {
			clear: left;
			float: left;
		}
	}
	@media @media-mobile {
		float: none;
		margin-top: 1em;
		padding-bottom: 1em;
		overflow: hidden;
		.btn {
			margin-top: 1em;
		}
	}
}

DIV.confirm01 {
	margin: 40px 0 0 0;
	border: 3px solid @color-gray-lightest;
	.display(flex);
	.clearfix();
	&.confirm01b {
		margin: 0 -15px 0;
		border: none;
		> DIV.a {
			border: none;
			.flex(0 0 228px);
		}
		> DIV.b {
			margin-left: -228px;
			padding-left: 298px;
			.justify-content(flex-start);
			@media @media-mobile {
				margin: 0;
				padding: 20px;
			}
		}
	}
	H2 {
		font-weight: normal;
		margin: 0;
	}
	> DIV.a {
		.flex(0 0 350px);
		float: left;
		width: 350px;
		position: relative;
		border-right: 2px solid @color-gray-lightest;
		.box-sizing(border-box);
		//&:before { content: ""; position: absolute; left: 100%; top: 0; bottom: 0; width: 50px; background: url(arrow02.png); background-size: 100% 100%; }
	}
	> DIV.b {
		.flex(0 0 100%);
		padding: 20px 20px 20px 70px;
		width: 100%;
		float: left;
		margin-left: -350px;
		padding-left: 420px;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
		.box-sizing(border-box);
	}
	DIV.item01 {
		border: none;
		padding: 0;
	}
	.compare02 {
		background: lighten(@color-gray-lightest, 5%);
		padding: 0;
		margin: 0;
		> DIV.item02 {
			DIV.heading01 {
				background: none;
				border-bottom-color: @color-white;
			}
			UL.params02 LI {
				border-bottom-color: @color-white;
			}
		}
	}
	@media @media-mobile {
		display: block;
		border: none;
		margin: 2em -15px 0;
		> DIV.a {
			border-bottom: 3px solid @color-gray-lightest;
			padding: 20px;
			width: auto;
			float: none;
			&:before {
				display: none;
			}
		}

		> DIV.b {
			padding: 20px;
			padding-left: 20px;
			margin-left: 0;
		}
	}
	@media @media-tablet {
		margin: 2em -20px 0;
	}
}

DIV.compare02 {
	.clearfix();
	background: lighten(@color-gray-lightest, 5%);
	padding: 40px;
	margin: 2em 0 0 0;
	> DIV.a {
		width: 20%;
		float: left;
		UL.params02 LI {
			font-weight: bold;
			position: relative;
			padding-left: 35px;
			&:before {
				display: block;
				position: absolute;
				left: 0;
				top: 10px;
				content: "";
				width: 30px;
				height: 35px;
				background-position: 50% 0 !important;
			}
			&.ico01:before {
				background: url(ico02_sm.png) no-repeat;
			}
			&.ico02:before {
				background: url(ico06_sm.png) no-repeat;
			}
			&.ico03:before {
				background: url(ico07_sm.png) no-repeat;
			}
			&.ico04:before {
				background: url(ico08_sm.png) no-repeat;
			}
			&.ico05:before {
				background: url(ico03_sm.png) no-repeat;
			}
			&.ico06:before {
				background: url(ico04_sm.png) no-repeat;
			}
			&.ico07:before {
				background: url(ico05_sm.png) no-repeat;
			}
		}
	}
	> DIV.b {
		width: 80%;
		float: left;
		position: relative;
		DIV.heading01 {
			padding: 20px;
			background: @color-white;
			color: @color-gray-medium;
			text-align: center;
			border-bottom: 2px solid @color-gray-lightest;
			H3 {
				color: @color-black;
				STRONG {
					color: @color02;
				}
			}
			P {
				color: @color-gray-medium;
			}
		}
		DIV.wrapper {
			overflow: hidden;
			padding-bottom: 30px;
			DIV.items {
				margin: 0 -10px 0 0;
			}
		}
		BUTTON.slick-next,
		BUTTON.slick-prev {
			position: absolute;
			padding: 0;
			background: none;
			border: none;
			cursor: pointer;
			z-index: 99;
			top: 25px;
			height: auto;
			width: auto;
			text-indent: -10000px;
			&:before {
				text-indent: 0;
				float: left;
				font-family: "icons";
				color: @color02;
				font-size: 30px;
			}
			&.disabled {
				opacity: 0.2;
				cursor: default;
			}
		}
		&.nopaging A.shuffle {
			display: block;
		}
		BUTTON.slick-prev {
			left: -35px;
			&:before {
				content: @ico-arrleft;
			}
		}
		BUTTON.slick-next {
			right: -35px;
			&:before {
				content: @ico-arrright;
			}
		}
		DIV.slick-list,
		DIV.slick-track {
			.clearfix();
			position: relative;
		}
		UL.slick-dots {
			clear: both;
			text-align: center;
			margin-top: 2em;
			LI {
				display: inline;
				&:before {
					display: none;
				}
				BUTTON {
					border: none;
					text-indent: -10000px;
					display: inline-block;
					padding: 10px 10px 0 0;
					width: 0;
					height: 0;
					background: @color-gray-medium;
					.rounded(50%);
					margin: 0 5px;
				}
				&.slick-active BUTTON {
					background: @color02;
				}
			}

		}
		DIV.item02 {
			float: left;
			width: 25%;
			position: relative;
			border-right: 10px solid lighten(@color-gray-lightest, 5%);
			background: @color-white;
			.box-sizing(border-box);
			&.recommended {
				&:before {
					position: absolute;
					right: 0;
					top: 0;
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 55px 55px 0;
					border-color: transparent @color02 transparent transparent;
				}
				&:after {
					content: @ico-thumbsup;
					font-family: "icons";
					color: @color-white;
					position: absolute;
					right: 5px;
					top: 0;
					font-size: 1.5em;
				}

			}
			@media @media-notwide {
				width: 33%;
			}
			@media @media-tablet {
				width: 50%;
			}
			@media @media-mobile {
				width: 100%;
			}
		}
		UL.params02 LI {
			text-align: center;
		}
	}
	DIV.heading01 {
		height: 115px;
		.box-sizing(border-box);
		overflow: hidden;
		H2, H3 {
			margin: 0;
			font-weight: normal;
		}
		H2 {
			font-size: ceil(@base-font-size*2);
		}
		H3 {
			font-size: ceil(@base-font-size*1.69);
			STRONG {
				display: block;
				font-size: ceil(@base-font-size*2);
			}
		}
		P {
			margin: 0;
		}
	}
	UL.params02 {
		margin: 10px 0 0;
		LI {
			padding-left: 0;
			.display(flex);
			.justify-content(center);
			.flex-direction(column);
			height: 45px;
			overflow: hidden;
			border-bottom: 1px solid fade(@color-black, 10%);
			&:before {
				display: none;
			}
			SPAN.label {
				display: none;
			}
			&.hilite {
				SPAN {
					display: none;
				}
				&:after {
					color: @color02;
					content: @ico-minus;
					font-family: "icons";
					display: block;
					text-align: center;
					text-indent: 0;
					position: static;
				}
			}
		}
	}
	DIV.actions01 {
		padding: 15px 30px;
		A.toggle {
			display: block;
			text-align: center;
			cursor: pointer;
			padding: 10px 0;
			height: 1em;
			margin: 0 -30px;
			outline: none;
			> SPAN {
				display: block;
				margin-bottom: 0;
				position: relative;
				&.hidden {
					display: none;
				}
				SPAN {
					position: relative;
					z-index: 2;
				}
			}
			&.open > SPAN {
				&:before {
					content: "";
					position: absolute;
					bottom: -45px;
					left: 0;
					width: 100%;
					height: 30px;
					background: url(arrow.png);
					background-size: 100% 100%;
				}
			}
		}
		.btn {
			padding: 10px;
			clear: both;
			display: block;
			text-align: center;
			font-size: ceil(@base-font-size*1.23);
			font-weight: bold;
			+ .btn {
				margin-top: 10px;
			}
		}
	}
	> DIV.item02 {
		DIV.heading01 {
			padding: 20px;
			height: auto;
			background: @color-white;
			color: @color-gray-medium;
			text-align: center;
			border-bottom: 2px solid @color-gray-lightest;
			H3 {
				color: @color-black;
				STRONG {
					color: @color02;
				}
			}
			P {
				color: @color-gray-medium;
			}
		}
		UL.params02 {
			LI {
				display: block;
				padding: 10px 10px 10px 50px;
				height: auto;
				&.hilite:after {
					float: right;
					width: 49%;
					text-align: left;
				}
			}
			SPAN {
				float: right;
				width: 49%;
				text-align: left;
				white-space: normal;
				&.label {
					float: left;
					font-size: 1em;
					color: @color-black;
					display: block;
					&:before {
						display: block;
						position: absolute;
						left: 10px;
						top: 5px;
						content: "";
						width: 30px;
						height: 35px;
						background-position: 50% 0 !important;
					}
					&.ico01:before {
						background: url(ico02_sm.png) no-repeat;
					}
					&.ico02:before {
						background: url(ico06_sm.png) no-repeat;
					}
					&.ico03:before {
						background: url(ico07_sm.png) no-repeat;
					}
					&.ico04:before {
						background: url(ico08_sm.png) no-repeat;
					}
					&.ico05:before {
						background: url(ico03_sm.png) no-repeat;
					}
					&.ico06:before {
						background: url(ico04_sm.png) no-repeat;
					}
					&.ico07:before {
						background: url(ico05_sm.png) no-repeat;
					}
				}
			}
		}
	}
	DIV.item01 {
		border-style: none none solid none;
		border-width: 2px;
		border-color: @color-gray-medium-lightest;
		> DIV.a {
			padding: 0 0 20px 0;
		}
	}
	@media @media-notdesktop {
		padding: 30px;
		margin-left: -20px;
		margin-right: -20px;
		> DIV.a,
		> DIV.b {
			float: none;
			width: auto;
		}

		> DIV.a {
			DIV.heading01 {
				height: auto;
			}
			UL.params02 {
				display: none;
			}
		}

		> DIV.b {
			margin: 2em 0 0 0;
			DIV.heading01 {
				padding: 15px 5px;
				P {
					font-size: ceil(@base-font-size*0.86);
				}
			}
			A.prev {
				left: -30px;
			}
		}

		UL.params02 {
			SPAN {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: ceil(@base-font-size*1.2);
			}
			SPAN.label {
				display: block !important;
				font-weight: bold;
				color: @color-gray-medium;
				font-size: ceil(@base-font-size*0.86);
			}
		}

		DIV.actions01 {
			padding: 10px;
			A.toggle {
				margin: 0 -10px;
				&.open > SPAN {
					&:before {
						bottom: -40px;
					}
				}
			}
			A.btn {
				margin: 10px 0 0 0;
				padding: 10px;
			}
		}

		DIV.toggle-wrapper {
			margin: 0 auto 2em auto;
			display: block;
			.switch:after {
				font-size: ceil(@base-font-size*0.86);
				width: 130px;
				text-align: center;
				top: 100%;
				left: 50%;
				margin: 5px 0 0 -65px;
			}
		}

	}
	@media @media-mobile {
		margin-left: -15px;
		margin-right: -15px;
		UL.params02 {
			LI {
				display: block;
				padding: 10px 10px 10px 40px;
				height: auto;
				&.hilite:after {
					float: right;
					width: 49%;
					text-align: left;
				}
			}
			SPAN {
				float: right;
				width: 49%;
				text-align: left;
				white-space: normal;
				&.label {
					float: left;
					font-size: 1em;
					color: @color-black;
					&:before {
						display: block;
						position: absolute;
						left: 5px;
						top: 5px;
						content: "";
						width: 30px;
						height: 35px;
						background-position: 50% 0 !important;
					}
					&.ico01:before {
						background: url(ico02_sm.png) no-repeat;
					}
					&.ico02:before {
						background: url(ico06_sm.png) no-repeat;
					}
					&.ico03:before {
						background: url(ico07_sm.png) no-repeat;
					}
					&.ico04:before {
						background: url(ico08_sm.png) no-repeat;
					}
					&.ico05:before {
						background: url(ico03_sm.png) no-repeat;
					}
					&.ico06:before {
						background: url(ico04_sm.png) no-repeat;
					}
					&.ico07:before {
						background: url(ico05_sm.png) no-repeat;
					}
				}
			}
		}
	}
}

DIV.compare03 {
	&.compare03b {
		background: @color-white;
		.link-outline-none;
		padding: 0; /*margin-left: 0; margin-right: 0;*/
		> DIV.b {
			.link-outline-none;
			DIV.wrapper {
				DIV.items {
					margin: 0;
				}
				margin: 0;
			}
			DIV.heading01 {
				padding: 20px 0 0 0;
				background: @color-white;
				color: @color-gray-medium;
				text-align: center;
				border-bottom: none;
				height: 100px;
				H3 {
					color: @color-black-medium;
					font-size: 18px;
					font-weight: bold;
					font-family: @font-sans-serif;
					STRONG {
						color: @color02;
					}
				}
				P {
					color: @color-black-light;
				}
				.price {
					SPAN {
						padding: 15px 0;
						font-size: 25px;
						color: @color-black-dark;
						font-weight: bold;
					}
				}

			}
			BUTTON.slick-next,
			BUTTON.slick-prev {
				.link-outline-none;
				position: absolute;
				padding: 0;
				background: none;
				border: none;
				cursor: pointer;
				z-index: 99;
				top: 25px;
				height: auto;
				width: auto;
				text-indent: -10000px;
				&:before {
					text-indent: 0;
					float: left;
					font-family: "icons";
					color: @color17;
					font-size: 24px;
				}
				&.disabled {
					opacity: 0.2;
					cursor: default;
				}
			}
			BUTTON.slick-next {
				right: 10px;
				top: 50%;
				&:before {
					content: @ico-arrright;
				}
			}
			BUTTON.slick-prev {
				left: 0;
				top: 50%;
				&:before {
					content: @ico-arrleft;
				}
			}

			UL.slick-dots {
				position: absolute;
				top: 8px;
				right: 5px;
				margin: 0;
				LI {
					&.slick-active {
						BUTTON {
							background: @color03;
						}
					}
					BUTTON {
						background: @color-gray-medium-light;
					}
				}
			}
			UL.params02 {
				LI {
					margin: 0;
					padding: 10px 0 10px 0;
					&.doubled {
						height: 111px;
					}
					P {
						color: @color-black-medium;
						margin: 0;
					}
					border-top: 1px solid fade(@color-black, 10%);
					border-bottom: none;
				}
			}
			DIV.actions01 {
				padding: 15px 30px;
				A.btn,
				INPUT.btn {
					&.primary {
						border: 1px solid transparent;
					}
				}
				A.toggle {
					transition: all 0.5s linear;
					display: block;
					text-align: center;
					cursor: pointer;
					padding: 10px 0;
					height: 1em;
					margin: 0 -30px;
					outline: none;
					> SPAN {
						display: block;
						margin-bottom: 0;
						position: relative;
						&.hidden {
							display: none;
						}
						SPAN {
							position: relative;
							z-index: 2;
						}
					}
					&.open > SPAN {
						&:before {
							display: none;
						}

					}
				}
				.btn {
					padding: 10px;
					clear: both;
					display: block;
					text-align: center;
					font-size: ceil(@base-font-size*1.23);
					font-weight: bold;
					+ .btn {
						margin-top: 10px;
					}
				}
			}
			/*DIV.item02 {
				&.recommended {
					&:before { position: absolute; right: 0; top: 0; content: ""; width: 0; height: 0; border-style: solid; border-width: 0 55px 55px 0; border-color: transparent @color02 transparent transparent;}
					&:after { content: @ico-thumbsup; font-family: "icons"; color: @color-white; position: absolute; right: 5px; top: 0; font-size: 1.5em; }

				}
				@media @media-notwide { width: 33%; }
				@media @media-tablet { width: 50%; }
				@media @media-mobile { width: 100%; }
			}*/
			.items {
				.slick-pt30 {
					padding-top: 30px;
				}
			}
			.item02 {
				border-right: none;
				padding-right: 10px;
				.link-outline-none;
				.pad {
					.link-outline-none;
					padding: 30px 20px 20px 20px;
					border: 1px solid @color-gray-medium-light;
					.img {
						width: 100px;
						height: 100px;
						text-align: center;
						.box-sizing(border-box);
						margin: 0 auto;
					}
					.txt {

					}
					UL.params02 {

					}
				}
				.compare03__is-active {
					border: 2px solid @color-gray04;
				}
				/*if possible use this*/
				.compare03__bordered {
					border: 1px solid @color-gray-medium-light;
				}
			}
			DIV.actions01 {
				padding: 15px 0 0 0;
			}
		}
		@media @media-notdesktop {

		}
		@media @media-tablet-narrow {
			margin-left: 0;
			margin-right: 0;
		}
		@media (min-width: 600px) {
			margin-left: 0;
			margin-right: 0;
		}
		@media @media-tablet {
			margin-left: 0;
			margin-right: 0;
		}
		@media @media-mobile {
			.item02 {
				&.item02b {

				}
			}

			padding: 0;
			UL.slick-dots {
				left: 0;
				right: 0;
			}

			UL.params02 {
				LI {

				}
			}
		}
	}

	&.compare03c {
		margin: 0;
		> DIV.b {
			margin: 0;
			.items {
				.item02 {
					width: 100%;
					padding: 0;
				}
			}
		}
	}

	&.compare03d {
		height: 1000px;
		.slick-track {
			.display(flex);
		}
		.item02 {
			&.item02b {
				&.slick-track {
					.display(flex);
				}
				.display(flex);
				.flex(0 0 auto);
				min-height: 100%;
				.subitem {
					.display(flex);
					.flex-direction(column);
					width: 100%;
					.actions01 {
						.align-content(center);
						.display(flex);
						.flex-direction(column);
						.justify-content(flex-end);
						.box-sizing(border-box);
						width: 100%;
					}
				}
				@media @media-mobile {
					UL.params02 {
						LI {
							.display(flex);
						}
					}
				}
			}
		}
	}

	.clearfix();
	background: lighten(@color-gray-lightest, 5%);
	padding: 40px;
	margin: 2em 0 0 0;
	> DIV.a {
		width: 20%;
		float: left;
		UL.params02 LI {
			font-weight: bold;
			position: relative;
			padding-left: 35px;
			&:before {
				display: block;
				position: absolute;
				left: 0;
				top: 10px;
				content: "";
				width: 30px;
				height: 35px;
				background-position: 50% 0 !important;
			}
			&.ico01:before {
				background: url(ico02_sm.png) no-repeat;
			}
			&.ico02:before {
				background: url(ico06_sm.png) no-repeat;
			}
			&.ico03:before {
				background: url(ico07_sm.png) no-repeat;
			}
			&.ico04:before {
				background: url(ico08_sm.png) no-repeat;
			}
			&.ico05:before {
				background: url(ico03_sm.png) no-repeat;
			}
			&.ico06:before {
				background: url(ico04_sm.png) no-repeat;
			}
			&.ico07:before {
				background: url(ico05_sm.png) no-repeat;
			}
		}
	}
	> DIV.b {
		width: 100%;
		float: left;
		position: relative;
		DIV.heading01 {
			padding: 20px;
			background: @color-white;
			color: @color-gray-medium;
			text-align: center;
			border-bottom: 2px solid @color-gray-lightest;
			H3 {
				color: @color-black-medium;
				STRONG {
					color: @color02;
				}
			}
			P {
				color: @color-gray-medium;
			}
		}
		DIV.wrapper {
			overflow: hidden;
			padding-bottom: 30px;
			DIV.items {
				margin: 0 -10px 0 0;
			}
		}
		/*BUTTON.slick-next,
		BUTTON.slick-prev{
			position: absolute; padding: 0; background: none; border: none; cursor: pointer; z-index: 99; top: 25px; height: auto; width: auto; text-indent: -10000px;
			&:before { text-indent: 0; float: left; font-family: "icons"; color: @color02; font-size: 30px; }
			&.disabled { opacity: 0.2; cursor: default; }
		}
		&.nopaging A.shuffle { display: block; }
		BUTTON.slick-prev {
			left: -35px;
			&:before { content: @ico-arrleft; }
		}
		BUTTON.slick-next {
			right: -35px;
			&:before { content: @ico-arrright; }
		}*/
		DIV.slick-list,
		DIV.slick-track {
			.clearfix();
			position: relative;
		}
		UL.slick-dots {
			clear: both;
			text-align: center;
			margin-top: 2em;
			LI {
				display: inline;
				&:before {
					display: none;
				}
				BUTTON {
					border: none;
					text-indent: -10000px;
					display: inline-block;
					padding: 10px 10px 0 0;
					width: 0;
					height: 0;
					background: @color-gray-medium;
					.rounded(50%);
					margin: 0 5px;
				}
				&.slick-active BUTTON {
					background: @color02;
				}
			}

		}
		DIV.item02 {
			float: left;
			width: 25%;
			position: relative;
			border-right: 10px solid lighten(@color-gray-lightest, 5%);
			background: @color-white;
			.box-sizing(border-box);
			&.recommended {
				&:before {
					position: absolute;
					right: 0;
					top: 0;
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 55px 55px 0;
					border-color: transparent @color02 transparent transparent;
				}
				&:after {
					content: @ico-thumbsup;
					font-family: "icons";
					color: @color-white;
					position: absolute;
					right: 5px;
					top: 0;
					font-size: 1.5em;
				}

			}
			@media @media-notwide {
				width: 33%;
			}
			@media @media-tablet {
				width: 50%;
			}
			@media @media-mobile {
				width: 100%;
			}
		}
		UL.params02 LI {
			text-align: center;
		}
	}
	DIV.heading01 {
		height: 115px;
		.box-sizing(border-box);
		overflow: hidden;
		H2, H3 {
			margin: 0;
			font-weight: normal;
		}
		H2 {
			font-size: ceil(@base-font-size*2);
		}
		H3 {
			font-size: ceil(@base-font-size*1.69);
			STRONG {
				display: block;
				font-size: ceil(@base-font-size*2);
			}
		}
		P {
			margin: 0;
		}
	}
	UL.params02 {
		margin: 10px 0 0;
		LI {
			padding-left: 0;
			.display(flex);
			.justify-content(center);
			.flex-direction(column);
			height: 45px;
			overflow: hidden;
			border-bottom: 1px solid fade(@color-black, 10%);
			&:before {
				display: none;
			}
			SPAN.label {
				display: none;
			}
			&.hilite {
				SPAN {
					display: none;
				}
				&:after {
					color: @color02;
					content: @ico-minus;
					font-family: "icons";
					display: block;
					text-align: center;
					text-indent: 0;
					position: static;
				}
			}
		}
	}
	DIV.actions01 {
		padding: 15px 30px;
		A.toggle {
			display: block;
			text-align: center;
			cursor: pointer;
			padding: 10px 0;
			height: 1em;
			margin: 0 -30px;
			outline: none;
			> SPAN {
				display: block;
				margin-bottom: 0;
				position: relative;
				&.hidden {
					display: none;
				}
				SPAN {
					position: relative;
					z-index: 2;
				}
			}
			&.open > SPAN {
				&:before {
					content: "";
					position: absolute;
					bottom: -45px;
					left: 0;
					width: 100%;
					height: 30px;
					background: url(arrow.png);
					background-size: 100% 100%;
				}
			}
		}
		.btn {
			padding: 10px;
			clear: both;
			display: block;
			text-align: center;
			font-size: ceil(@base-font-size*1.23);
			font-weight: bold;
			+ .btn {
				margin-top: 10px;
			}
		}
	}
	> DIV.item02 {
		DIV.heading01 {
			padding: 20px;
			height: auto;
			background: @color-white;
			color: @color-gray-medium;
			text-align: center;
			border-bottom: 2px solid @color-gray-lightest;
			H3 {
				color: @color-black;
				STRONG {
					color: @color02;
				}
			}
			P {
				color: @color-gray-medium;
			}
		}
		UL.params02 {
			LI {
				display: block;
				padding: 10px 10px 10px 50px;
				height: auto;
				&.hilite:after {
					float: right;
					width: 49%;
					text-align: left;
				}
			}
			SPAN {
				float: right;
				width: 49%;
				text-align: left;
				white-space: normal;
				&.label {
					float: left;
					font-size: 1em;
					color: @color-black;
					display: block;
					&:before {
						display: block;
						position: absolute;
						left: 10px;
						top: 5px;
						content: "";
						width: 30px;
						height: 35px;
						background-position: 50% 0 !important;
					}
					&.ico01:before {
						background: url(ico02_sm.png) no-repeat;
					}
					&.ico02:before {
						background: url(ico06_sm.png) no-repeat;
					}
					&.ico03:before {
						background: url(ico07_sm.png) no-repeat;
					}
					&.ico04:before {
						background: url(ico08_sm.png) no-repeat;
					}
					&.ico05:before {
						background: url(ico03_sm.png) no-repeat;
					}
					&.ico06:before {
						background: url(ico04_sm.png) no-repeat;
					}
					&.ico07:before {
						background: url(ico05_sm.png) no-repeat;
					}
				}
			}
		}
	}
	DIV.item01 {
		border-style: none none solid none;
		border-width: 2px;
		border-color: @color-gray-medium-lightest;
		> DIV.a {
			padding: 0 0 20px 0;
		}
	}
	@media @media-notdesktop {
		padding: 30px;
		margin-left: -20px;
		margin-right: -20px;
		> DIV.a,
		> DIV.b {
			float: none;
			width: auto;
		}

		> DIV.a {
			DIV.heading01 {
				height: auto;
			}
			UL.params02 {
				display: none;
			}
		}

		> DIV.b {
			margin: 2em 0 0 0;
			DIV.heading01 {
				padding: 15px 5px;
				P {
					font-size: ceil(@base-font-size*0.86);
				}
			}
			A.prev {
				left: -30px;
			}
		}

		UL.params02 {
			SPAN {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: ceil(@base-font-size*1.2);
			}
			SPAN.label {
				display: block !important;
				font-weight: bold;
				color: @color-gray-medium;
				font-size: ceil(@base-font-size*0.86);
			}
		}

		DIV.actions01 {
			padding: 10px;
			A.toggle {
				margin: 0 -10px;
				&.open > SPAN {
					&:before {
						bottom: -40px;
					}
				}
			}
			A.btn {
				margin: 10px 0 0 0;
				padding: 10px;
			}
		}

		DIV.toggle-wrapper {
			margin: 0 auto 2em auto;
			display: block;
			.switch:after {
				font-size: ceil(@base-font-size*0.86);
				width: 130px;
				text-align: center;
				top: 100%;
				left: 50%;
				margin: 5px 0 0 -65px;
			}
		}

	}
	@media @media-mobile {
		margin-left: -15px;
		margin-right: -15px;
		UL.params02 {
			LI {
				display: block;
				padding: 10px 10px 10px 40px;
				height: auto;
				&.hilite:after {
					float: right;
					width: 49%;
					text-align: left;
				}
			}
			SPAN {
				float: right;
				width: 49%;
				text-align: left;
				white-space: normal;
				&.label {
					float: left;
					font-size: 1em;
					color: @color-black;
					&:before {
						display: block;
						position: absolute;
						left: 5px;
						top: 5px;
						content: "";
						width: 30px;
						height: 35px;
						background-position: 50% 0 !important;
					}
					&.ico01:before {
						background: url(ico02_sm.png) no-repeat;
					}
					&.ico02:before {
						background: url(ico06_sm.png) no-repeat;
					}
					&.ico03:before {
						background: url(ico07_sm.png) no-repeat;
					}
					&.ico04:before {
						background: url(ico08_sm.png) no-repeat;
					}
					&.ico05:before {
						background: url(ico03_sm.png) no-repeat;
					}
					&.ico06:before {
						background: url(ico04_sm.png) no-repeat;
					}
					&.ico07:before {
						background: url(ico05_sm.png) no-repeat;
					}
				}
			}
		}
	}
}

/* 11. Tabs related */

UL.tabs01 {
	margin: 1.5em 0 0 0;
	padding: 0;
	position: relative;
	top: 1px;
	list-style: none;
	border-bottom: 1px solid @color-gray-medium-light;
	&.noborder {
		border: 0;
		LI {
			border: 0 !important;
			A {
				background: none;
				border: 0 !important;
				SPAN {
					height: auto;
					padding: 0 10px;
					font-size: 14px;
					line-height: 24px;
					font-weight: normal;
					@media @media-mobile {
						text-align: left;
						padding: 0 !important;
					}
				}
			}
			@media @media-tablet {
				width: auto;
			}
		}
	}
	LI {
		float: left;
		padding: 0;
		background: none;
		position: relative;
		top: 1px;
		border-top: 1px solid transparent;
		margin: 0 0 0 -1px !important;
		&.f-right {
			float: right;
		}
		&:before {
			display: none;
		}
		&:first-child {
			margin: 0 !important;
			A {
				margin: 0;
			}
		}
		&.search {
			margin: 0 !important;
			float: right;
			border-top: 1px solid @color-gray-medium-lightest;
		}
		&.separated {
			float: right;
		}
		&.hasico {
			max-width: 760px;
			&:before {
				display: none;
			}
			SPAN {
				padding: 14px 10px 9px 10px;
				text-align: left;
				&.ico {
					&:before {
						font-size: 20px;
						float: left;
						position: relative;
						top: 0;
						margin: 0 5px 0 0;
						width: 20px;
					}
					&.ico01:before {
						text-indent: 0;
						content: @ico-private;
						display: block;
					}
					&.ico02:before {
						text-indent: 0;
						content: @ico-business;
						display: block;
					}
				}
			}
			A {
				&,
				SPAN {
					display: block;
					float: left;
					height: auto;
				}
			}
		}
	}
	A {
		border: 1px solid @color-gray-medium-light;
		text-decoration: none;
		cursor: pointer;
		display: table-cell;
		padding: 0;
		background: @color-gray-lightest;
		SPAN {
			padding: 0 30px 0 30px;
			margin: 0;
			font-size: 17px;
			line-height: 19px;
			height: 58px;
			color: @color-black;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			font-weight: bold;
		}
		&.active {
			background: @color-white;
			border-bottom-color: @color-white;
			SECTION.type05 & {
				background: lighten(@color-gray-lightest, 5%);
				border-bottom-color: lighten(@color-gray-lightest, 5%);
			}
			SPAN {
				color: @color02;
			}
			&:hover {
				background: @color-white;
			}
		}
		&:hover {
			background: @color-gray-medium-lightest;
		}
	}
	A.discrete,
	SPAN.discrete {
		background: none;
		padding: 14px 5px 6px 0;
		font-size: 14px;
		border: none;
	}
	&.tabs01b {
		.display(flex);
		position: relative;
		z-index: 3;
		LI {
			.display(flex);
			margin-left: 0 !important;
			padding: 0 !important;
			&:first-child {
				&:nth-last-child(2) {
					&,
					& ~ LI {
						width: 50%;
					}
				}
				&:nth-last-child(3) {
					width: 34%;
					& ~ li {
						width: 33%;
					}
				}
				&:nth-last-child(4) {
					&,
					& ~ li {
						width: 25%;
					}
				}
				&:nth-last-child(5) {
					&,
					& ~ li {
						width: 20%;
					}
				}
				A {
					border-left-style: solid;
				}
			}

			&.active A {
				background: @color-white;
				border-bottom-color: @color-white;
			}
			A {
				border-left-style: none;
			}
		}
		A {
			display: block;
			width: 100%;
			font-size: 14px;
			font-weight: bold;
			background: @color-gray-lightest;
			border-bottom: 1px solid @color-gray-medium-light;
			&:hover {
				background: lighten(@color-gray-lightest, 5%);
			}
			SPAN {
				color: @color-black;
				height: auto;
				padding: 15px;
				display: block;
			}
		}

		.iefix & {
			LI A {
				border-left-style: solid;
			}
		}
	}
	@media @media-tablet {
		display: table;
		width: 100%;
		border-bottom: none;
		&.tabs01b {
			.display(flex);
			LI {
				display: -moz-box !important;
				display: -ms-flexbox !important;
				display: -webkit-flex !important;
				display: flex !important;

				&:first-child A {
					border-right-style: solid;
				}
			}
		}

		LI {
			width: 100%;
			display: table-row;
			margin: 0 !important;
			border-top: none;
			&:first-child {
				A {
					border-top: 1px solid @color-gray-medium-light;
					border-right-style: none;
				}
				&:nth-last-child(2),
				&:nth-last-child(3),
				&:nth-last-child(4) {
					&,
					& ~ li {
						display: table-cell;
						margin: 0 !important;
						border-top: 1px solid transparent;
						A {
							border-top: 1px solid @color-gray-medium-light;
							margin: 1px 0 0 0;
							&.active {
								border-bottom-color: @color-white !important;
							}
						}
					}
					& ~ li A {
						border-right-style: solid;
					}
				}
				&:nth-last-child(2) {
					&,
					& ~ LI {
						width: 50%;
					}
				}
				&:nth-last-child(3) {
					width: 34%;
					& ~ li {
						width: 33%;
					}
				}
				&:nth-last-child(4) {
					&,
					& ~ li {
						width: 25%;
					}
				}
			}
			A {
				border-left-style: solid;
				margin: 0;
				border-top: none;
				border-bottom-color: @color-gray-medium-light !important;
				.box-sizing(border-box);
				display: table;
				width: 100%;
				SPAN {
					display: table-cell;
				}
				&,
				SPAN {
					float: none;
				}
			}
		}
	}
	@media @media-mobile {
		display: block;
		width: 100%;
		border-bottom: none;
		LI {
			display: block;
			width: 100%;
			&:first-child A {
				border-top: 1px solid @color-gray-medium-light;
			}
			&.hasico A {
				&,
				SPAN {
					float: none;
				}
			}
		}

		&.tabs01b {
			.flex-wrap(wrap);
			> LI {
				width: 100% !important;
				float: none;;
				flex-shrink: 0;
				A {
					border-left-style: solid;
				}
				&:first-child:nth-last-child(2) {
					&,
					& ~ LI {
						width: 50% !important;
						float: left;
						A {
							width: 100%;
							border-left-style: none;
							border-top: 1px solid @color-gray-medium-light;
							&.active {
								border-bottom-color: @color-white !important;
							}
							SPAN {
								padding: 15px 3px;
							}
						}
						&:first-child A {
							border-left-style: solid;
						}
					}
				}
				.iefix & {
					&:first-child:nth-last-child(2) {
						&,
						& ~ LI {
							width: 100% !important;
							float: none;
							&:first-child A {
								border-top: 1px solid @color-gray-medium-light;
							}
							A {
								border-top: none;
								border-left-style: solid !important;
								&.active {
									border-bottom-color: @color-gray-medium-light !important;
								}
								SPAN {
									padding: 15px;
								}
							}
						}
					}
				}
			}
		}

		LI,
		LI A,
		LI A SPAN {
			float: none;
			display: block;
		}

		A {
			border-left-style: solid;
			margin: 0;
			border-top: none;
			border-bottom-color: @color-gray-medium-light !important;
			SPAN {
				padding: 10px 30px;
				height: auto;
			}
		}
	}
}

DIV.tabcontent01 {
	display: none;
	margin: 1em 0 0 0;
	overflow: hidden;
	background: @color-white;
	border: 1px @color-gray-medium-lightest;
	border-style: solid;
	padding: 24px 18px 30px;
	&.tabcontent01b {
		display: block;
	}
	&.tabcontent01c {
		overflow: visible;
	}
	&.tabcontent01d {
		border: none;
		border-top: 2px solid;
		margin: 0 !important;
		padding-top: 0;
		&.color01 {
			border-top-color: @color03;
		}
		&.color02 {
			border-top-color: @color02;
		}
		&.color03 {
			border-top-color: @color13;
		}
	}
	&.active {
		display: block;
	}
	&.noborder {
		margin: 0;
		border: none;
		padding-left: 0;
		padding-right: 0;
	}
	H2 {
		margin: 1em 0 0;
		font-size: 24px;
		font-weight: bold;
		color: @color-black;
	}
	P {
		margin: 1em 0 0 0;
	}
	SECTION.type05 & {
		background: lighten(@color-gray-lightest, 5%);
	}
	UL.tabs01 + &,
	DIV.tabcontent01 + &,
	A.btn.f-right + & {
		margin: 0
	}
	.margneg {
		margin: -24px -18px 0;
	}
	.pad {
		padding: 0 10px;
	}
	TABLE TH {
		background: none;
		color: @color-black;
		border-top: none;
	}
	UL.msg02:first-child {
		margin-top: 0;
	}
	@media @media-mobile {
		TABLE:first-child {
			margin-top: -20px !important;
		}
	}
}

DIV.tabs03 {
	UL {
		position: relative;
		top: 2px;
		list-style: none;
		margin: 1em 0 0 0;
		padding: 0 0 20px;
		text-align: center;
		&:first-child {
			margin: 0;
		}
		&.in2cols LI {
			width: 47%;
		}
		&.in3cols LI {
			width: 30.3333%;
		}
		&.in4cols LI {
			width: 22%;
		}
	}
	LI {
		position: relative;
		display: inline-block;
		float: none !important;
		border: 2px solid;
		max-width: 450px;
		padding: 25px 15px 23px;
		margin: 18px 1.5% 0;
		.box-sizing(border-box);
		&:before {
			display: none
		}
		&.color01 {
			border-color: @color03;
			SPAN.heading01 {
				color: @color03;
			}
			&.active:after {
				border-top-color: @color03;
			}
		}
		&.color02 {
			border-color: @color02;
			SPAN.heading01 {
				color: @color02;
			}
			&.active:after {
				border-top-color: @color02;
			}
		}
		&.color03 {
			border-color: @color13;
			SPAN.heading01 {
				color: @color13;
			}
			&.active:after {
				border-top-color: @color13;
			}
		}
		&.active {
			&:after {
				content: "";
				width: 0;
				display: block;
				position: absolute;
				top: 100%;
				left: 50%;
				margin-left: -40px;
				height: 0;
				border-style: solid;
				border-width: 20px 40px 0 40px;
				border-color: @color-black transparent transparent transparent;
			}
		}
	}
	A {
		position: relative;
		display: block;
		min-height: 130px;
		color: @color-black;
		font-size: 15px;
		line-height: 1.5;
		overflow: hidden;
		text-decoration: none;
		&:hover {
			text-decoration: none;
			color: @color-black;
		}
	}
	SPAN {
		display: block;
		margin: 25px 0 0 0;
		&.heading01 {
			display: block;
			text-align: center;
			margin: 0;
			font-size: 30px;
			color: @color01;
			line-height: 1.15;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		&.btn {
			max-width: 100px;
			margin: 25px auto 0;
			cursor: pointer;
		}
	}
	@media @media-mobile {
		margin-top: 0 !important;
		UL {
			margin: 0;
		}

		LI {
			float: none;
			width: 100% !important;
			margin: 1em 0 0 !important;
			&:first-child {
				margin: 0 !important;
			}
			&.active:after {
				display: none;
			}
		}

		A {
			height: auto;
		}
	}
}

DIV.tabs02 {
	border-bottom: 2px solid @color02;
	padding-top: 1px;
	UL {
		overflow: hidden;
		position: relative;
		top: 2px;
		list-style: none;
		margin: 1em 0 0 0;
		padding: 0;
		&:first-child {
			margin: 0;
		}
		&.in2cols LI {
			&, &:before {
				width: 47%;
			}
		}
		&.in3cols LI {
			&, &:before {
				width: 30.3333%;
			}
		}
		&.in4cols LI {
			&, &:before {
				width: 22%;
			}
		}
	}

	LI {
		position: static;
		float: left;
		padding: 25px 35px 23px;
		margin: 18px 1.5% 0;
		.box-sizing(border-box);
		&:before {
			content: "";
			position: absolute;
			top: 18px;
			bottom: 2px;
			left: auto;
			display: block;
			background: @color-white;
			margin: 0 0 0 -3%;
			border-top: 3px solid @color01;
			.box-sizing(border-box);
		}
		&.color01 {
			&:before {
				border-color: @color03;
			}
			SPAN.heading01 {
				color: @color03;
			}
		}
		&.color02 {
			&:before {
				border-color: @color-positive;
			}
			SPAN.heading01 {
				color: @color-positive;
			}
		}
		&.color03 {
			&:before {
				border-color: @color13;
			}
			SPAN.heading01 {
				color: @color13;
			}
		}
		&.active {
			margin: 0 1.5% 0;
			padding: 40px 35px 26px;
			border-bottom: none;
			&:before {
				top: 0;
				bottom: 0;
				border: 2px solid;
				border-color: @color02 !important;
				border-bottom: none;
			}
			SPAN.heading01 {
				color: @color02 !important;
			}
		}
	}
	A {
		position: relative;
		display: block;
		color: @color-black;
		font-size: 15px;
		line-height: 1.5;
		overflow: hidden;
		&:hover {
			text-decoration: none;
		}
	}
	SPAN {
		display: block;
		margin: 25px 0 0 0;
		&.heading01 {
			display: block;
			text-align: center;
			margin: 0;
			font-size: 20px;
			color: @color01;
			line-height: 1.15;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
	}
	@media @media-tablet {
		LI {
			padding: 25px 15px 23px;
			&.active {
				padding: 40px 15px 26px;
			}
		}
	}
	@media @media-mobile {
		display: none;
	}
}

DIV.tabcontent02 {
	padding: 40px 0;
	display: none;
	//.col00 & { max-width: 900px; }
	&.active {
		display: block;
	}
	DIV.heading01 {
		display: none;
	}
	H3 {
		color: @color02;
		font-size: 28px;
	}
	P.lead {
		font-size: 20px;
		margin: 1em 0 0 0;
	}

	@media @media-mobile {
		display: block !important;
		border-top: 3px solid @color01;
		background: lighten(@color-gray-lightest, 5%);
		padding: 0;
		margin: 1em 0 0 0;
		DIV.heading01 {
			display: block;
			position: relative;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
			A {
				position: relative;
				display: block;
				color: @color-black;
				font-size: 15px;
				line-height: 1.5;
				overflow: hidden;
				padding: 10px 10px 10px 35px;
				&:before {
					content: @ico-plus;
					font-family: "icons";
					position: absolute;
					font-size: 17px;
					color: @color-gray-medium-light;
					left: 10px;
					top: 10px;
				}
				&:hover {
					text-decoration: none;
				}
			}

			SPAN {
				display: block;
				margin: 0;
				&.heading01 {
					display: block;
					text-align: left;
					margin: 0;
					font-size: 15px;
					font-weight: bold;
					color: @color01;
					line-height: 1.5;
				}
			}
		}

		&.active DIV.heading01 A:before {
			content: @ico-minus;
		}

		&.color01 {
			border-color: #01baf2;
			DIV.heading01 SPAN.heading01 {
				color: #01baf2;
			}
		}

		&.color02 {
			border-color: #00a950;
			DIV.heading01 SPAN.heading01 {
				color: #00a950;
			}
		}

		&.color03 {
			border-color: #fcaf17;
			DIV.heading01 SPAN.heading01 {
				color: #fcaf17;
			}
		}

		> DIV.b {
			display: none;
			background: @color-white;
			padding: 10px;
			font-size: 15px;
		}

		&.active > DIV.b {
			display: block;
		}

		H3 {
			font-size: 20px;
		}

		P.lead {
			font-size: 15px;
			font-weight: bold;
		}

	}
}

DIV.tabcontent03 {
	display: none;
	&.active {
		display: block;
	}
	P.heading01 {
		display: block;
		font-size: 20px;
		color: @black;
		line-height: 1.2; /*font-weight: bold;*/
		font-family: @font-heading;
		.ru & {
			font-family: @font-heading-ru;
		}
	}
	.input-wrapper {
		INPUT.default2x {
			width: 236px;
			height: 37px;
		}
	}
	.button-wrapper {
		padding-top: 0;
	}
	&.disabled {
		opacity: 0.2;
	}
	@media @media-mobile {
		UL.actions01 {
			A.btn {
				&.add {
					text-align: left;
					padding: 0 !important;
				}
			}
		}

		.input-wrapper {
			INPUT.default2x {
				width: 100%;
			}
		}
	}
}

UL.tabs04 {
	margin: 1.5em 0 0 0;
	padding: 0;
	position: relative;
	top: 1px;
	list-style: none;
	border-bottom: 2px solid @color12;
	.link-outline-none;
	&.tabs04b {
		&:before {
			content: "";
			width: 3000px;
			border-bottom: 2px solid @color12;
			position: absolute;
			bottom: -2px;
			left: 50%;
			margin-left: -1500px;
		}
		LI {
			min-width: 300px;
		}
		A {
			SPAN {
				padding: 10px 20px;
				font-size: 20px;
			}
			&.active {
				font-weight: bold;
			}
		}
		@media @media-mobile {
			LI {
				min-width: 0;
			}

			A SPAN {
				font-size: 15px;
				padding: 10px;
			}
		}
	}
	&.tabs04c {
		LI {
			margin: 0 0 0 8px !important;
			&:first-child {
				margin: 0 !important;
			}
		}
	}
	&.tabs04d {
		border-bottom: 2px solid @color-gray-light;
		&:before {
			border-bottom: 2px solid @color-gray-light;
		}
		LI {
			margin: 0 0 0 8px !important;
			&:first-child {
				margin: 0 !important;
			}
		}
		A {
			border-color: @color-gray-light transparent @color-gray-light;
			&.active {
				border-color: @color-gray-light @color-gray-light @color-white;
			}
			&:hover {
				border-color: @color-gray-light;
				SPAN:before {
					color: @color-white;
				}
			}
		}
	}
	&.tabs04e {
		A {
			SPAN {
				font-size: 17px;
				.display(flex);
				.flex-direction(column);
				.justify-content(center);
			}
		}
		@media @media-mobile {
			SPAN {
				.display(flex) !important;
			}
		}
	}
	&.tabs04f {
		border: none;
		LI {
			top: 0;
			&:first-child {
				margin: 0 !important;
			}
		}
		A {
			border-color: @color-gray-medium-light transparent @color03 transparent;
			background: @color21; .box-sizing(border-box);
			.display(flex); height: 100%; .justify-content(center); .align-items(center);
			&:hover {
				border-color: @color03 transparent @color03 transparent;
			}
			SPAN {
				padding: 15px 18px;
				font-size: @base-font-size-paragraph;
				color: @color19;
				&.tabs-ico-sm {
					&:hover {
						&::before {
							color: @color29;
						}
					}
					&::before {
						margin: 0;
						display: inline-block;
						font-size: 18px;
						position: relative;
						top: 3px;
						right: 6px;
						color: @color29;
					}
					@media @media-mobile {
						&::before {
							top: 0;
						}
					}
				}
			}
			&.active {
				border-color: @color03 @color03 @color-white;
				SPAN {
					color: @color16;
					&.tabs-ico-sm {
						&:hover {
							&::before {
								color: @color01;
							}
						}
						&::before {
							color: @color01;
						}
					}

				}
				&::before {
					content: '';
					position: absolute;
					width: 2000px;
					right: 100%;
					bottom: 0;
					border-bottom: 2px solid @color03;
				}
				&::after {
					content: '';
					position: absolute;
					border-bottom: 2px solid @color03;
					width: 2000px;
					left: 100%;
					bottom: 0;
					@media @media-mobile {
						margin-left: 2px;
					}
				}
			}
		}
	}
	&.disabled {
		opacity: 0.2;
	}
	LI {
		float: left;
		padding: 0;
		background: none;
		position: relative;
		top: 2px;
		border-top: 2px solid transparent;
		margin: 0 0 0 2px !important;
		&.f-right {
			float: right;
		}
		&:before {
			display: none;
		}
		&:first-child {
			margin: 0 0 0 20px !important;
			A {
				margin: 0;
			}
		}
	}
	&.in3cols,
	&.in2cols {
		.display(flex);
		.flex-direction(row);
		.justify-content(space-between);
		LI {
			margin: 0 5px !important;
			.flex(1);
			width: auto;
			.display(flex);
			&:first-child {
				margin-left: 0 !important;
			}
			&:last-child {
				margin-right: 0 !important;
			}
		}
		A SPAN {
			min-height: 40px;
			padding: 20px;
		}
	}
	A {
		border-width: 2px; .flex(0 0 100%); border-style: solid none solid none; border-color: @color-gray-light transparent @color12; display: block; text-decoration: none; cursor: pointer; padding: 0; background: lighten(@color-gray-lightest, 5%); .box-sizing(border-box);
		SPAN {
			display: block;
			padding: 10px 20px;
			margin: 0;
			font-size: 15px;
			line-height: 1.5;
			color: @color-black;
			text-align: center;
			IMG {
				display: block;
				opacity: .5;
				margin: auto auto 10px;
				.grayscale(100%);
			}
			&:before {
				margin: 10px 0 0 0;
				display: block;
				font-size: 90px;
				line-height: 1;
				text-align: center;
				color: @color-gray-medium;
			}
		}
		&.active {
			background: @color-white;
			border-color: @color12 @color12 @color-white;
			border-style: solid;
			SPAN {
				font-weight: bold;
				IMG {
					display: block;
					opacity: 1;
					.grayscale(0);
					filter: none;
				}
				&:before {
					color: @color01;
				}
			}
			&:hover {
				background: @color-white;
				border-bottom-color: @color-white;
				cursor: default;
				SPAN {
					color: @color-black;
					&:before {
						color: @color01;
					}
				}
			}
		}
		&:hover {
			border-color: @color12;
			SPAN:before {
				color: @color-white;
			}
		}
	}
	A.discrete,
	SPAN.discrete {
		background: none;
		padding: 14px 5px 6px 0;
		font-size: 14px;
		border: none;
	}
	@media @media-mobile {
		.display(flex);
		&.in3cols,
		&.in2cols {
			margin: 0 -15px;
			padding: 0 15px;
			A {
				.align-items(flex-start);
				IMG {
					margin-bottom: .5em;
				}
				&.active {
					border-bottom: 2px solid @color-white !important;
				}
				SPAN {
					font-size: 12px;
				}
			}
		}

		LI {
			float: none;
			&.active {
				position: relative;
				z-index: 2;
			}
			&:first-child {
				margin-left: 0 !important;
				&:nth-last-child(2),
				&:nth-last-child(3),
				&:nth-last-child(4) {
					&,
					& ~ li {
						.flex(1);
						.display(flex);
						A {
							.flex(0 0 100%);
							.display(flex);
							.align-items(center);
							.justify-content(center);
							line-height: 1.2; /*border-bottom: 2px solid @color12;*/
							SPAN { line-height:  1.2; display: block; height: auto; padding: 5px 10px; }
						}
					}
				}
				&:nth-last-child(2) {
					&,
					& ~ LI {
						width: 50%;
					}
				}
				&:nth-last-child(3) {
					width: 34%;
					& ~ li {
						width: 33%;
					}
				}
				&:nth-last-child(4) {
					&,
					& ~ li {
						width: 25%;
					}
				}
			}
		}
		&.tabs04f {
			LI {
				&:first-child {
					&:nth-last-child(2),
					&:nth-last-child(3),
					&:nth-last-child(4),
					&:nth-last-child(5) {
						&,
						& ~ li {
							A {
								SPAN {
									padding: 15px 5px;
								}

							}
						}
					}
				}
				&.active {
					A {
						&::after {
							margin-left: 0;
						}
					}
				}
				A {
					SPAN {
						padding: 15px 5px;
					}
				}
				&:nth-last-child(2) {
					&,
					& ~ LI { width: 100%; .flex(1 1 auto); }
				}
				&:nth-last-child(3) {
					&,
					& ~ li { width: 100%; .flex(1 1 auto); }
				}
				&:nth-last-child(4) {
					&,
					& ~ li {
						width: 100%; .flex(1 1 auto);
					}
				}
				&:nth-last-child(5) {
					&,
					& ~ li {
						width: 100%; .flex(1 1 auto);
					}
				}
			}
		}
	}
}

ul.tabs05 {
	margin: 1.5em 0 0 0;
	padding: 0;
	position: relative;
	top: 1px;
	list-style: none;
	LI {
		float: left;
		padding: 0;
		background: none;
		position: relative;
		top: 2px;
		margin: 0 0 0 0 !important;
		.link-outline-none;
		&.f-right {
			float: right;
		}
		&:before {
			display: none;
		}
		&:first-child {
			margin: 0 0 0 0 !important;
			A {
				margin: 0;
			}
		}
	}
	A {
		display: block;
		text-decoration: none;
		cursor: pointer;
		padding: 0;
		padding-right: 20px;
		SPAN {
			display: block;
			padding: 0;
			margin: 0;
			font-size: 15px;
			line-height: 40px;
			height: 40px;
			color: @color-gray-medium;
			text-align: center;
		}
		&.active {
			SPAN {
				height: 42px;
				border-bottom: 2px solid @color02;
				color: @color-black;
				font-weight: bold;
			}
			&:hover {
				SPAN {
					color: @color-black;
				}
			}
		}
		&:hover {
			color: @color-black;
		}
	}
	A.discrete,
	SPAN.discrete {
		background: none;
		padding: 14px 5px 6px 0;
		font-size: 14px;
		border: none;
	}
	@media @media-mobile {
		.display(flex);

		DIV.tabcontent02 {
			margin: 0 !important;
			border-top: 0 !important;
		}

		LI {
			float: none;
			&.active {
				position: relative;
				z-index: 2;
			}
			&:first-child {
				margin-left: 0 !important;
				&:nth-last-child(2),
				&:nth-last-child(3),
				&:nth-last-child(4) {
					&,
					& ~ li {
						.flex(1);
						.display(flex);
						A {
							.flex(0 0 100%);
							.display(flex);
							.align-items(center);
							.justify-content(center);
							line-height: 1.2;
							SPAN {
								line-height: 1.2;
								display: block;
								height: auto;
								padding: 5px 10px;
							}
						}
					}
				}
				&:nth-last-child(2) {
					&,
					& ~ LI {
						width: 50%;
					}
				}
				&:nth-last-child(3) {
					width: 34%;
					& ~ li {
						width: 33%;
					}
				}
				&:nth-last-child(4) {
					&,
					& ~ li {
						width: 25%;
					}
				}
			}
		}
	}
}

.tabs06 {
	overflow: visible;
	list-style: none;
	margin-bottom: 2em; /*margin-top: 0!important;*/
	.display(flex);
	.flex-wrap(wrap);
	.flex-direction(row);
	&.tabs06b {
		.items23 {
			width: 154px;
			margin-left: 2px;
			margin-bottom: 5px;
			margin-top: 5px;
		}
		.tabgroup {
			UL {
				LI {
					width: 118px;
					padding: 6px 10px;
					min-height: 65px;
					&.active {
						padding: 7px 11px;
						&:hover {
							padding: 7px 11px;
						}
					}
					&:hover {
						padding: 7px 11px;
					}
				}
			}
		}

	}
	.tabgroup {
		&.separator {
			margin-right: 20px;
		}
		P {
			color: @color-gray-medium;
		}
		UL {
			overflow: visible;
			list-style: none;
			width: 100%;
			margin-top: 0 !important;
			.display(flex);
			.flex-wrap(wrap);
			.flex-direction(row);
			LI {
				&.ac {
					cursor: default;
					flex: 1 0 auto;
					position: relative;
					padding: 7px 18px;
					width: auto;

					INPUT.select {
						padding-right: 35px;
						margin-top: 5px;
						font-style: italic;
					}
					&:hover {
						border: 2px solid @color-gray-lightest;
						cursor: default;
						background: lighten(@color-gray-lightest, 5%);
						padding: 7px 18px;
						z-index: 9;
					}
					& > UL {
						display: block;
						position: absolute;
						background: @color-white;
						border: 1px solid @color-gray-lightest;
						padding: 8px 0;
						left: 4px;
						right: 18px;
						top: 46px;
						width: auto;
						max-height: 215px;
						overflow-y: scroll;
						overflow-x: hidden;
						.zindex(@tabs06Zindex, 1);
						& > LI {
							&:first-child {
								margin-top: 0;
							}
							border: 0;
							padding: 0;
							float: none;
							&:before {
								display: none;
							}
							background: none;
							min-height: 100%;
							max-width: 100%;
							width: 100%;
							//border-left: 2px solid transparent;
							P.heading01 {
								padding: 0 15px;
								font-style: italic;
							}
						}
						A {
							display: block;
							overflow: hidden;
							padding: 5px 25px;
							&:hover {
								border-left: 2px solid @color02;
								A {
									SPAN {
										color: @color-black;
									}
								}
								color: @color-black;
								/*font-weight: bold;*/
								padding: 5px 25px;
								padding-left: 23px;
							}
						}
					}
				}
				min-height: 87px;
				padding: 6px 17px;
				/*margin-top: 1em;*/
				border: 2px solid @color-gray-lightest;
				background: lighten(@color-gray-lightest, 5%);
				cursor: pointer;
				margin-right: -2px;
				position: relative;
				text-align: left;
				.link-outline-none;
				width: 152px;
				.box-sizing(border-box);
				flex: 0 0 auto;
				&.active {
					border: 2px solid @color10;
					background: @color-main;
					text-decoration: none;
					z-index: 99;
					cursor: default;
					padding: 7px 18px;
					&:hover {
						border: 2px solid @color10;
						border-left: 2px solid @color10;
						padding: 7px 18px;
					}
					A {
						cursor: default;
					}
				}

				A {
					color: @color-text-main;
					text-decoration: none;
					STRONG, SPAN {
						display: block;
					}
					SPAN {
						color: @color-gray-medium;
					}
				}
				&:hover {
					border: 1px solid @color01;
					background: @color-main;
					text-decoration: none;
					border-left: 1px solid @color01;
					.zindex(@tabs06Zindex, 0);
					padding: 7px 18px;
				}
				&:before {
					display: none;
				}
				P {
					margin: 0;
				}
			}

			&.open {
				UL {
					display: block;
				}
				I {
					display: none;
				}
			}
			&.selected {
				&.open {

				}
			}
		}

	}
	@media @media-tablet {
		.tabgroup {
			flex: 0 0 100%;
			&:last-child {
				margin-left: 0 !important;
			}
		}
	}
	@media @media-mobile {

	}
}

DIV.tabcontent04 {
	margin: 1em 0 0 0;
	display: none;
	border-bottom: 1px dashed @color-gray-light;
	padding-bottom: 40px;
	H3 {
		font-size: 18px;
	}
	&.active {
		display: block;
	}
}

/* 12. Paging */
UL.pages {
	margin: 1em 0 2em 0;
	padding: 0;
	line-height: normal;
	LI {
		float: left;
		padding: 0;
		margin: 0 5px 0 0 !important;
		background: none;
		&.f-right {
			float: right;
			SPAN {
				padding: 8px 0 8px 10px;
				color: @color-black;
				background: none;
				border-color: transparent;
			}
		}
		&:before {
			display: none;
		}
		& > A,
		& > SPAN {
			float: left;
			margin: 0;
			padding: 0;
			text-decoration: none;
			white-space: nowrap;
			font-size: 14px;
			border: 1px solid @color-gray-medium-lightest;
			background: #fbfbfb;
			&:hover {
				background: @color-gray-medium-lightest;
			}
			&.active {
				border: 1px solid @color01;
				background: @color01;
				&:hover {
					background: #0026fc;
				}
				SPAN {
					color: @color-white;
				}
			}
		}
		A SPAN,
		SPAN SPAN {
			float: left;
			margin: 0 10px 0 0;
			padding: 8px 0 8px 10px;
			color: @color-black;
		}
		&.separator SPAN,
		&.label SPAN {
			padding: 8px 10px 8px 0;
			color: @color-black;
			background: none;
			border-color: transparent;
		}
		&.shuffle {
			A,
			SPAN {
				& + A,
				& + SPAN {
					margin-left: 5px;
				}
				SPAN {
					padding: 8px 10px 8px 20px;
				}
			}
		}

	}
	@media @media-tablet {
		clear: both;
		margin: 0;
		padding-top: 1em;
		LI.shuffle {
			A,
			SPAN {
				SPAN {
					padding: 8px 0 8px 10px;
				}
			}
		}

		&:hover {
			background: @color-gray-medium-lightest;
		}
	}
	@media @media-tablet {
		clear: both;
		margin: 0;
		padding-top: 1em;
	}
	@media @media-mobile {
		margin: 0;
		padding-top: 1em;
		clear: both;
		LI {
			display: none;
			&.shuffle {
				display: block;
				width: 49%;
				margin: 0 0 0 1% !important;
				&:first-child {
					margin: 0 1% 0 0 !important;
				}
				A,
				SPAN {
					display: block !important;
					float: none !important;
					text-align: center;
					&.mobile-hide {
						display: none !important;
					}
				}
			}
			&.f-right {
				display: block;
				float: none;
				clear: both;
				SPAN {
					display: block;
					float: none;
					clear: both;
					text-align: center;
				}
			}
		}
	}
}

/* 13. Tooltip */

#tooltip {
	position: absolute;
	left: -1000px;
	top: -1000px;
	text-align: left;
	line-height: 16px;
	.zindex(@tooltipZindex,1);
	margin: 0;
	padding-right: 12px;
	max-width: 440px;
	&.tooltip-sticky-zindex {
		.zindex(@tooltipstickyZindex, 0);
	}
	#tooltip-inner {
		background: @color-gray01;
		color: @color-white;
		/*padding: 20px;*/
		padding: 17px 10px 17px 13px;
		position: relative;
		P {
			margin: 5px 0 0 0;
			color: @color-white;
			&:first-child {
				margin: 0;
			}
		}
		A {
			color: @color-white;
		}
		SPAN.fs28 {
			font-size: 28px;
			line-height: 14px;
			display: inline-block;
			top: 0.2em;
			position: relative;
		}
		.tooltip02 {
			width: 700px;
			&:before {
				content: "";
				position: absolute;
				background: @color-gray01;
				top: 0;
				left: 50%;
				right: 0;
				bottom: 0;
			}
			.col {
				position: relative;
				.box-sizing(border-box);
				padding: 0 20px 0 0;
				&.b {
					padding: 0 0 0 20px;
				}
			}
			P {
				margin: 1em 0 0 0;
				font-size: 17px;
				line-height: 1.2;
				&:first-child {
					margin: 0;
				}
				&.note {
					font-size: 13px;
					color: #c5c5c5;
					B {
						color: @color-white;
					}
				}
			}
		}
	}
	#tooltip-arrow {
		content: "";
		display: none;
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 12px 12px 0 12px;
		border-color: transparent;
		border-top-color: @color-gray01;
		DIV {
			display: none;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 11px 5px 0 5px;
			border-color: transparent;
			border-top-color: @color-gray01;
			margin: -12px 0 0 -5px;
		}
	}
	#tooltip-close {
		display: none;
		position: absolute;
		width: 20px;
		height: 20px;
		right: 0;
		top: 0;
		.zindex(@tooltipZindex,0);
		&.type01 {
			display: block;
		}
		A {
			display: block;
			width: 20px;
			height: 20px;
			text-indent: -10000px;
			background: url(bg02.png) no-repeat 0 -1800px;
		}
	}
	&.left {
		&.top {
			margin-top: -12px;
			padding-right: 0;
			#tooltip-arrow {
				left: 8px;
				bottom: 0;
				margin-bottom: -11px;
			}
		}
		&.side {
			padding-right: 0;
			margin: -6px 0 0 -12px;
			#tooltip-arrow {
				right: 0;
				top: 12px;
				margin-right: -11px;
				border-width: 6px 0 6px 12px;
				border-color: transparent;
				border-left-color: @color-gray01;
				DIV {
					border-width: 5px 0 5px 11px;
					border-color: transparent;
					margin: -5px 0 0 -12px;
					border-left-color: @color-gray01;
				}
			}
		}
	}
	&.right {
		&.top {
			margin-top: -12px;
			padding-right: 0;
			#tooltip-arrow {
				right: 8px;
				bottom: 0;
				margin-bottom: -11px;
			}
		}
		&.side {
			padding-right: 0;
			margin: -6px 0 0 12px;
			#tooltip-arrow {
				left: 0;
				top: 12px;
				margin-left: -11px;
				border-width: 6px 12px 6px 0;
				border-color: transparent;
				border-right-color: @color-gray01;
				DIV {
					border-width: 5px 11px 5px 0;
					border-color: transparent;
					margin: -5px 0 0 1px;
					border-right-color: @color-gray01;
				}
			}
		}
	}
	&.center.top {
		margin-top: -12px;
		padding-right: 0;
		#tooltip-arrow {
			right: 50%;
			bottom: 0;
			margin: 0 0 -11px -6px;
		}
	}
	@media @media-mobile {
		/*max-width: 90%;*/
		max-width: 100%;
		left: 20px !important;
		right: 20px !important;
		#tooltip-inner .tooltip02 {
			width: auto;
			&:before {
				display: none;
			}
			&.col {
				padding: 0;
				&.b {
					background: #333;
					margin: 20px -20px -20px -20px !important;
					padding: 20px;
				}
			}
		}

		#tooltip-arrow {
			display: inline-block;
			left: 0 !important;
			margin-bottom: -9px !important;
		}
	}
}
.tooltip-channel {
	font-family: @font-main;
	max-width: 323px;
	&__text {
		font-size: 13px; font-family: @font-main; font-weight: 400; line-height: 1.54; color: @color-white;
		&--small {
		}
	}
	&__title {
		font-family: @font-main;
		color: @color-white;
		font-weight: 400;
		font-size: 15px;
		line-height: 1.67;
		margin: 0;
	}
	&__language {
		font-size: 13px; font-family: @font-main; font-weight: 400; line-height: 1.54; color: @color-white;
	}
}

/* 14. Frontpage parts */

/* 15. Content blocks */

#content {
	font-size: 14px;
	line-height: 1.64;
	.clearfix();
	&:last-child {
		padding-bottom: 30px;
	}
	IMG {
		max-width: 100%;
		height: auto;
	}
	.slider-accessories {
		.img {
			IMG {
				height: 150px;
				max-width: 150px;
			}
		}
	}
	&:focus {
		outline: none;
	}
	.content-heading {
		H1.type02 {
			margin: 0;
			display: inline-block;
		}
		A {
			margin: 2em 0 0 0;
			margin-top: 20px
		}
	}
}

DIV.block04 {
	float: left;
	margin: 0 75px 0 0;
	H2 {
		margin: 1em 0 0;
		font-size: 24px;
		font-weight: bold;
		color: @color-black;
	}
	INPUT {
		float: left;
		margin: 20px 3px 0 0 !important;
	}
	.default {
		width: 190px;
		height: 31px;
	}
}

DIV.block05 {
	margin: 35px 0 0 0;
	max-width: 675px;
	P {
		margin: 0;
		&.heading01 {
			font-size: 20px;
			font-weight: bold;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		&.meta {
			color: @color-gray-medium;
			font-size: 14px;
		}
	}
	SPAN.rate {
		margin-top: 7px;
	}
}

DIV.block06 {
	margin: 50px 0 0 0;
	background: #f4f4f4;
	padding: 30px;
	H3 {
		font-size: 30px;
		font-weight: bold;
		text-align: left;
		margin: 0 0 0 0;
	}
	P.img-left {
		margin: 0 30px 0 0;
	}
	&:first-child {
		margin: 20px 0 0 0;
	}
	IMG {
		max-width: 100%;
		height: auto;
	}
}

DIV.block07 {
	padding: 30px;
	height: 380px;
	.box-sizing(border-box);
	.display(flex);
	.flex-direction(column);
	.align-items(center);
	.justify-content(center);
	background: no-repeat 50% 50%;
	background-size: cover;
	text-align: center;
	.clearfix();
	> A.btn {
		max-width: 320px;
		font-size: 28px;
		padding: 10px 60px;
		position: relative;
		z-index: 9;
		&.btn02 {
			padding: 10px;
			float: none;
			display: block;
		}
	}
	A.btn {
		margin-top: 1em;
	}
	SECTION.type03 + & {
		margin: 80px 0 0 0;
	}
	&.block07b {
		height: 480px;
		text-align: left;
		H2 {
			font-size: 45px;
			color: @color-black;
		}
		p {
			font-size: 24px;
			color: @color-black;
		}
	}
	@media @media-tablet {
		&.tablet-bottom {
			padding: 180px 30px 50px;
		}

		A.btn.btn02 {
			width: auto;
			display: inline-block;
			font-weight: bold;
			padding: 20px 60px;
			&.tablet-margt {
				margin-top: 50px;
			}
		}

		&.block07b {
			background-position: 35% 50% !important;
		}
	}
	@media @media-mobile {
		padding: 0;
		background: none !important;
		height: auto;
		A.btn {
			font-size: 17px;
			font-weight: bold;
			padding: 10px;
		}

		SECTION.type03 + & {
			margin: 30px 0 0 0;
		}

		&.block07b {
			height: auto;
			H2 {
				font-size: 22px !important;
				text-align: center;
			}
			P {
				font-size: 16px !important;
				text-align: center;
				padding-bottom: 30px;
			}
		}
	}
}

.block08 {
	margin: 1em 0 0 0;
	border: 2px solid @color-gray-medium;
	padding: 10px;
	font-size: 15px;
	.block08a {
		P {
			margin: 0;
		}
		A {
			color: @color-gray-medium;
			display: block;
			&:after {
				float: right;
				content: "i";
				width: 22px;
				height: 22px;
				border-radius: 11px;
				text-align: center;
				line-height: 22px;
				color: @color-white;
				background: @color-gray-medium;
				.open& {
					content: @ico-cross;
					font-family: "icons";
					font-size: 11px;
				}
			}
		}
	}
	.block08b {
		display: none;
		padding: .5em 0;
		P {
			margin: .5em 0 0 0;
		}
		.open& {
			display: block;
		}
	}
}

DIV.block09 {
	margin: 150px 0 0;
	border: 1px solid @color-gray-light;
	&.noborder {
		border: none;
	}
	#content & {
		margin: 40px 0 0;
	}
	DIV.a {
		.clearfix();
		border-bottom: 1px solid @color-gray-light;
		padding: 20px;
		P {
			color: @color-gray-medium-light;
			font-size: ceil(@base-font-size*3.5);
			font-weight: bold;
			margin: -.2em 0 0 0;
		}
	}
	DIV.b {
		padding: 20px;
		UL LI.search {
			border-style: solid;
		}
		+ UL LI.search {
			border-style: solid none none;
		}
	}
	DIV.c {
		border-top: 1px solid @color-gray-light;
		padding: 20px;
	}
	H1 {
		margin: .7em 0 0;
		font-size: ceil(@base-font-size*2);
		font-weight: bold;
		color: @color01;
		&:first-child {
			margin: 0;
		}
	}
	UL LI.search {
		margin: 0;
		border-style: none none solid none;
		&.w100p {
			FORM {
				padding: 0 20px;
			}
			SPAN {
				right: 10px;
			}
		}
	}
	SPAN.separator {
		margin: 0 10px;
		color: @color-gray-medium;
	}
	@media @media-tablet {
		margin: 20px 0 0;
	}
	@media @media-mobile {
		margin: 0;
	}
}

DIV.block10 {
	.clearfix();
	DIV.a {
		width: 100%;
		.box-sizing(border-box);
		margin-right: -180px;
		padding-right: 190px;
		float: left;
		position: relative;
		.clearfix();
	}
	DIV.c {
		width: 50%;
		float: left;
		position: relative;
	}
	DIV.b {
		width: 180px;
		float: left;
	}
	P {
		text-align: center;
		font-size: 15px;
		line-height: 1.2;
		&.heading01 {
			color: @color-gray-medium;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		&.img {
			height: 100px;
			IMG {
				max-height: 100%;
				max-width: 100%;
				height: auto;
				width: auto;
			}
		}
		&.price01 {
			font-weight: normal;
			STRONG {
				color: @color02;
				font-size: 22px;
				display: block;
				font-weight: bold;
			}
		}
		&.formula {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -40px !important;
			font-size: 80px;
			line-height: 80px;
			margin-left: 19px;
		}
	}
	@media @media-tablet {
		margin-top: -50px;
	}
	@media @media-mobile {
		DIV.a {
			float: none;
			margin-right: 0;
			padding-right: 0;
		}

		DIV.b {
			float: none;
			margin: 0 auto;
		}

		P.formula {
			right: -20px;
		}

		DIV.c + DIV.c P.formula {
			display: none;
		}
	}
}

DIV.block11 {
	border: 1px solid @color-gray-light;
	&.block11b {
		background: @color-gray-medium-lightest;
		border: none;
		SPAN.text {
			color: @color01;
		}
	}
	&.block11c {
		background: @color-white;
		border: none;
	}
	.equalheight & {
		.flex-align-self(stretch);
		width: 100%;
	}
	A {
		display: block;
		position: relative;
		.clearfix();
		text-decoration: none !important;
		&:hover {
			text-decoration: none;
		}
	}
	SPAN.img {
		display: block;
		width: 50%;
		float: right;
		position: relative;
		text-align: center;
		padding: 30px 30px 30px 0;
		.box-sizing(border-box);
	}
	SPAN.text {
		.clearfix();
		display: block;
		float: left;
		.box-sizing(border-box);
		width: 50%;
		padding: 30px 0 70px 30px;
		color: @color-gray-darkest;
		* {
			display: block;
		}
	}
	SPAN.heading01 {
		font-family: @font-heading;
		font-size: 40px;
		font-weight: bold;
		line-height: 1.1;
		.ru & {
			font-family: @font-heading-ru;
		}
	}
	SPAN.content {
		font-size: 16px;
		margin: 1em 0 0 0;
	}
	SPAN.btn {
		position: absolute;
		bottom: 30px;
		left: 30px;
		margin: .5em 0 0 0;
		background: @color01;
		color: @color-white;
		float: left;
		padding: 7px 20px;
		font-size: 16px;
		font-weight: bold;
		font-family: @font-heading;
		.ru & {
			font-family: @font-heading-ru;
		}
		&:hover {
			background: @color02;
		}
	}
	SPAN.price01 {
		position: absolute;
		width: 125px;
		height: 125px;
		bottom: 20px;
		color: @color-white;
		right: 20px;
		padding: 20px 15px;
		.box-sizing(border-box);
		background: url(bg06.png) no-repeat 50% 50%;
		background-size: contain;
		text-align: center;
		font-family: @font-heading;
		font-weight: 300;
		font-size: 20px;
		line-height: 0.95;
		STRONG {
			font-weight: 300;
			display: block;
			font-size: 50px;
			SPAN {
				display: inline;
				font-size: 26px;
			}
		}
		.ru & {
			font-family: @font-heading-ru;
		}
	}
	@media @media-tablet {
		SPAN.img {
			width: 100%;
			float: none;
		}

		SPAN.text {
			width: 100%;
			float: none;
			padding: 20px;
		}

		SPAN.btn {
			position: static;
		}
	}
	@media @media-mobile {
		margin: 15px 0 0 0;
		SPAN.img {
			width: auto;
			padding: 15px 15px;
			float: none;
		}

		SPAN.text {
			position: static;
			width: 100%;
			padding: 10px 15px 15px;
			text-align: center;
		}

		SPAN.btn {
			position: static;
			float: none;
			text-align: center;
		}

		SPAN.heading01 {
			font-size: 18px;
		}

		SPAN.content {
			font-size: 13px;
		}

		SPAN.price01 {
			left: 50%;
			margin-right: -80px;
			color: @color-white;
		}
	}
}

DIV.block12 {
	A {
		display: block;
		border: 1px solid @color-gray-light;
		background: @color-gray-light;
		color: @color01;
		font-weight: bold;
		font-size: 22px;
		&:hover {
			color: @color02;
			text-decoration: none;
		}
	}
	SPAN.img {
		display: block;
		position: relative;
		background: @color-white;
		.box-sizing(border-box);
		text-align: center;
		IMG {
			max-width: 100%;
			width: auto;
			margin: 0 auto;
			height: auto;
			display: block;
		}
	}
	SPAN.txt {
		display: block;
		padding: 20px;
		text-align: center;
		font-family: @font-heading;
		.ru & {
			font-family: @font-heading-ru;
		}
	}
	@media @media-mobile {
		margin: 20px 0 0 0;
		SPAN.img IMG {
			width: 100%;
		}

		SPAN.txt {
			padding: 10px;
		}
	}
}

DIV.block13 {
	position: relative;
	padding-top: 50px;
	SECTION.type01 & {
		padding-bottom: 40px;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 10px;
		z-index: 2;
		width: 120px;
		height: 120px;
		background: url(owl04.png) no-repeat 50% 50%;
		background-size: contain;
	}
	H2 {
		float: left;
		border: 1px solid @color02;
		.rounded(15px);
		color: @color-black;
		font-weight: 500;
		top: -40px;
		margin: 0 0 0 160px;
		padding: 10px 20px;
		display: block;
		position: relative;
		clear: both;
		font-size: 22px;
		&:before {
			content: "";
			position: absolute;
			left: -15px;
			top: 16px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 16px 16px 0;
			border-color: transparent @color02 transparent transparent;
		}
		&:after {
			content: "";
			position: absolute;
			left: -13px;
			top: 17px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 14px 14px 0;
			border-color: transparent @color-white transparent transparent;
		}
	}
	UL.type15 {
		margin: 0;
	}
	DIV.pad {
		border: 1px solid @color12;
		padding: 0 10px;
		margin: 10px 0 0 0;
		DIV#slider01 UL.products02 {
			margin: 1em 0 0 0;
			> LI {
				padding-bottom: 20px;
			}
		}
	}
	@media @media-tablet {
		padding-top: 10px;
		H2 {
			top: 0;
			display: none;
		}

		UL.type15 {
			margin: -20px 0 0 180px;
		}
	}
	@media @media-mobile {
		padding-top: 0;
		&:before {
			width: 100px;
			height: 100px;
			left: 0;
		}

		H2 {
			display: none;
			font-size: 18px;
			margin-left: 120px;
		}

		UL.type15 {
			margin: 0 0 0 110px;
			LI {
				margin: 0;
			}

		}
	}
}

DIV.block14 {
	&.hasbg {
		.col00 {
			background: url("bg07.png") no-repeat 100% 100%;
			border: 1px @color-gray-light;
			border-style: none solid solid solid;
			.box-sizing(border-box);
			@media @media-tablet {
				background: none;
				border: 0;
			}
			@media @media-mobile {
				background: none;
				border: 0;
			}
		}
	}
	@media @media-tablet {
		UL.actions01 + .mt10 {
			margin-top: 0 !important;
			width: 65% !important;
		}
	}
	@media @media-mobile {
		.form01 {
			.w66p {
				INPUT.min, SELECT.min, TEXTAREA.min {
					width: 100%;
					margin: 3px 0;
				}
			}
		}

		H2 + .clear {
			margin: 0 !important;
		}
	}
}

DIV.block15 {
	margin-top: 3em;
	.btn.xxl {
		font-weight: normal;
	}
	> DIV {
		&:last-child {
			margin-bottom: 100px !important;
		}
	}
	@media @media-mobile {
		margin-top: 0;
		P.details {
			margin: 1em 0 !important;
		}

		> DIV {
			&:last-child {
				margin: 1em 0 0 0 !important;
			}
		}
	}
}

DIV.block16 {
	position: relative;
	.msg {
		display: none;
	}
	&.disabled {
		&:before {
			content: "";
			position: absolute;
			z-index: 9;
			background: fade(@color-white, 80%);
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
		&:hover .msg {
			display: block;
			position: absolute;
			text-align: center;
			left: 0;
			right: 0;
			top: 50%;
			color: @color-black;
			padding: 0 20px;
			font-size: ceil(@base-font-size*1.6);
			z-index: 10;
			&:before {
				color: @color14;
				margin-right: 10px;
				position: relative;
				top: 3px;
			}
		}
	}
	H2 {
		color: @color-black;
		font-weight: 400;
		margin: 2em 0 0 0;
	}
	UL.numbers01 {
		margin: 0;
	}
}

DIV.block17 {
	background: lighten(@color-gray-lightest, 5%);
	padding: 20px 25px;
	margin: 2em 0 0 0;
	@media @media-mobile {
		margin: 1em -15px 0;
		padding: 10px 15px;
	}
}

DIV.block18 {
	background: lighten(@color-gray-lightest, 5%);
	padding: 5px 15px 10px;
	margin: 0 -15px;
	.row {
		margin: 5px 0 0;
	}
	.checkboxes & {
		margin: 0;
	}
}

DIV.block19 {
	border-bottom: 1px solid @color-gray-lightest;
	padding-bottom: 20px;
	H2 {
		margin-top: 1em !important;
	}
	A {
		font-size: @base-font-size;
		font-weight: normal;
		margin-left: 2em;
	}
	@media @media-mobile {
		A.btn {
			text-align: left;
			margin-left: 0;
		}
	}
}

DIV.block20 {
	position: relative;
	padding: 20px 0;
	margin: 20px 0 0 0;
	.posrel > *:first-child {
		margin-top: 0;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		width: 2560px;
		margin-left: -1280px;
		background: lighten(@color-gray-lightest, 5%);
	}
	@media @media-mobile {
		margin: 30px -15px 0;
		background: lighten(@color-gray-lightest, 5%);
		padding: 13px;
		&:before {
			display: none;
		}
	}
}

DIV.block22 {
	margin: 27px 0 0 0 !important;
	.clearfix();
	max-width: 600px;
	.pad {
		padding: 15px;
		background: none;
		float: left;
		.clearfix();
		border: 1px solid @color12;
		border-radius: 8px;
		DIV.row {
			min-width: 500px;
			.items.w100p {
				white-space: nowrap;
				margin-right: 0;
				width: 100% !important;
				INPUT[type="text"],
				INPUT[type="email"] {
					width: 400px;
				}
			}
			&.type04 {
				padding-top: 1em;
				width: 100%;
				border-top: 2px dashed @color-gray-light;
				text-align: left;
				margin-top: 2em;
			}
		}
		DIV.text {
			float: left;
		}

		UL.actions01.f-right {
			margin-left: 30px;
		}
		P {
			margin: 0;
			white-space: nowrap;
		}
	}
	@media @media-mobile {
		.pad {
			float: none;
			DIV.row {
				min-width: 0;
				.items {
					INPUT[type="text"],
					INPUT[type="email"] {
						width: 100%;
						float: left !important;
					}
				}
			}
			SPAN.text {
				float: right;
			}
			DIV.text {
				float: none;
			}
			UL.actions01.f-right {
				margin: 0 !important;
			}
			P {
				white-space: normal;
			}
		}

	}
}

.block21 {
	&.block21b {
		P {
			color: @color-black-light;
			font-size: @base-font-size-paragraph;
		}
		.pad {
			&.active {
				border-style: none none solid none;
				border-color: @color-gray-light;
			}
			border-style: none none solid none;
			P {
				color: @color-black;
			}
		}
		H3 {
			font-weight: bold;
			color: @color01;
			font-family: @font-main;
		}
	}
	margin: 1em 0 0 0;
	P {
		color: @color-gray-medium;
		font-size: 15px;
	}
	.pad {
		&.active {
			border: 1px solid @color01;
		}
		border: 1px solid @color-gray-light;
		padding: 25px;
		.display(flex);
		margin: .5em 0 0 0;
		P {
			color: @color-black;
		}
		/*A.btn.arrdown,
		A.btn.arrup { &:after { float: left; margin-left: 0; margin-right: 6px;  margin-top: 2px; } }*/
	}
	H3 {
		font-weight: bold;
	}
	.text {
		.flex(1 1 auto);
		padding: 0 10px;
		/*P A { font-weight: bold; }*/
		A {
			display: inline-block;
		}
	}
	.img {
		float: left;
		width: 100px;
		.flex(0 0 auto);
		text-align: center;
		IMG {
			max-width: 100%;
			height: auto;
		}
	}
	.prices {
		float: right;
		width: 140px;
		.flex(0 0 auto);
		margin: 0;
		.display(flex);
		.flex-direction(column);
		.justify-content(space-between);
		P {
			font-weight: bold;
			font-size: 24px;
			text-align: right;
			DEL {
				display: block;
				font-size: 15px;
				color: @color-gray-medium;
				font-weight: normal;
			}
		}
		.btn {
			padding: 9px;
			text-align: center;
			&.remove {
				text-align: left;
			}
			&:before {
				position: relative;
				top: .1em;
			}
			&.ico-plus {
				/*&:hover:before { color: @color02; }*/
				&:before {
					top: 1px;
					margin-right: 7px;
				}
			}
		}
	}
	@media @media-notdesktop {
		.pad {
			display: block;
			.clearfix();
		}

		.text {
			margin-left: -100px;
			padding-left: 120px;
			.box-sizing(border-box);
			width: 100%;
			float: left;
		}

		.prices {
			width: 100%;
			padding-top: 20px;
			.flex-direction(row);
			.align-items(flex-start);
			padding-left: 120px;
			.box-sizing(border-box);
			P {
				text-align: left;
				DEL {
					display: inline;
				}
			}
		}
	}
	@media @media-mobile {
		.prices {
			.flex-direction(column);
			.align-items(stretch);
			.btn {
				margin: 1em 0 0 0;
			}
		}
	}

}

.block23 {
	position: relative;
	padding: 0 0 0 30px;
	&:before {
		position: absolute;
		left: 0;
		top: 0;
		font-size: 20px;
	}
	&.ico-disabled:before {
		color: @color-negative;
	}
	&.ico-check:before {
		color: @color-positive;
	}
	TABLE & {
		P:first-child {
			margin: 0;
		}
		margin: 1em 0 0 0;
		&:first-child {
			margin: 0;
		}
	}
}

.block24 {
	margin: 0;
	padding: 20px 40px 40px 40px;
	border: 1px solid @color-gray-medium-lightest;
	clear: both;
	border-bottom: none;
	&.block24a {
		background: @color-gray-lightest;
		margin: 0;
		padding: 20px 18px;
		border: 1px @color-gray-medium-lightest;
		border-style: none solid solid solid;
		h2 {
			font-size: 46px;
		}
	}
	.item01.item01i {
		& > .a {
			.col-c {
				padding: 0 300px 0 0 !important;
			}
			.col-b {
				width: 280px;
			}
		}
	}
	.item03 {
		DIV.txt {
			margin-left: -120px;
			margin-right: -260px;
			padding-right: 280px;
			padding-left: 140px;
		}
		DIV.prices {
			width: 260px;
		}
	}
	@media @media-tablet {
		.item03 {
			DIV.txt {
				margin-left: -120px;
				margin-right: -230px;
				padding-right: 250px;
				padding-left: 140px;
			}
			DIV.prices {
				width: 230px;
			}
		}

		.item01.item01i {
			& > .a {
				.col-c {
					padding: 0 270px 0 0 !important;
				}
				.col-b {
					width: 250px;
				}
			}
		}
	}
	@media @media-mobile {
		.item01.item01i {
			& > .a {
				.col-c {

				}
				.col-b {
					width: 100%;
				}
			}
		}
	}
}

.block25 {
	border: 1px solid @color-gray-light;
	background: lighten(@color-gray-lightest, 5%);
	.equalheight & {
		.flex-align-self(stretch);
		width: 100%;
	}
	A {
		display: block;
		position: relative;
		.clearfix();
		text-decoration: none !important;
		width: 100%;
		text-align: center;
		padding-top: 200px;
		padding-bottom: 30px;
		color: @color-black;
		font-weight: bold;
		&:hover {
			text-decoration: none;
			border: 1px solid @color01;
		}
		IMG {
			margin: 0 auto;
			display: block;
			text-align: center;
			padding: 30px 0 30px 0;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			.box-sizing(border-box);
		}
	}
	/* TODO: remove those permanently used in _support.less */
	/*.block25 {
	padding-left: 20px;
	padding-bottom: 60px;
	H3.type02 { font-weight: 300; margin-left: -20px; margin-top: 2em; }
}*/

	@media @media-tablet {

	}
	@media @media-mobile {

	}
}

.block26 {
	.flex(0 0 100%);
	.display(flex);
	.flex-direction(row);
	.justify-content(center);
	.align-items(center);
	margin: 0;
	width: 100%;
	height: 100%;
	min-height: 155px;
	SPAN.text {
		color: @color-gray-medium;
		&[class^="ico"]:before,
		&[class*="ico"]:before {
			display: block;
			font-size: 80px;
			text-align: center;
			margin: 0 0 20px 0;
			color: @color-gray-medium-lightest;
		}
	}
	/*H3 { font-weight: bold; }*/
	/*&.block26b { margin: 0 auto; display: block; text-align: center; padding: 100px 0 100px 0; position: relative; top: 0; left: 0; right: 0; .box-sizing(border-box); min-height: auto;
		SPAN.text {
			color: @color-gray-medium;
			&[class^="ico"]:before,
			&[class*="ico"]:before {
				display: block;
				font-size: 80px;
				text-align: center;
				margin: 0 0 20px 0;
				color: @color-gray-medium-lightest;
			}
		}
	}*/
	@media @media-tablet {

	}
	@media @media-mobile {

	}
}

.block27 {
	.clearfix();
	border: 1px solid @color-gray-light;
	padding: 20px;
	font-family: @font-main;
	.pad {
		.box-sizing(border-box);
		P.note {
			margin: 1em 0 1em 0;
			font-size: 14px;
			line-height: 1.4;
		}
		A {
			&.discrete {
				@media @media-mobile {
					display: block;
					text-align: left;
				}
			}
		}
		.icon-square {
			font-family: @font-main;
			&:before {
				.box-sizing(border-box);
				position: relative;
				top: .1em;
				overflow: hidden;
				float: none;
			}
		}
	}
}

.block28 {
	border: 1px solid @color01;
	.item03-item01(@margin, @border-top) {
		.item03 {
			margin: @margin;
		}
		.item01 {
			border-top: @border-top;
		}
	}
	.item03-item01(0, 0);
	&.block28b {
		border: 1px solid @color12;
		.pad {
			padding: 20px 40px;
		}
	}
}

.block29 {
	.display(flex);
	.flex-direction(row);
	.flex-wrap(nowrap);
	margin: 0;
	border: none;
	margin: 0 -0.5%;
	margin-bottom: 2em;
	.align-items(flex-start); /*overflow: hidden;*/
	&.active-height {
		height: 475px;
	}
	.active & {
		height: 475px;
	}
	DIV.input-wrapper {
		A.note,
		SPAN.note {
			max-width: 100%;
			font-size: 12px;
			margin: 5px 0 0 0;
		}
		.theselect {
			A.dropdown {
				padding: 12px 20px 12px 5px;
			}
			UL {
				& > LI {
					A {
						padding: 5px;
					}
				}
			}
		}
	}

	.item {
		/*item*/
		.display(flex);
		.flex-direction(column);
		.flex(0 0 auto);
		width: 32.33%;
		float: left;
		.box-sizing(border-box);
		margin: 10px 0.5%;
		height: 210px;
		border: 1px solid @color18;
		cursor: pointer;
		.clearfix();
		padding: 20px;
		background: @color-white;
		position: relative;

		&.done {
			/*subitem*/
			.subitem {
				.txt {
					.heading01 {
						font-size: 18px;
					}
				}
			}
			/*subitem02*/
			.subitem02 {
				.prices03 {
					.price {
						.price-month {
							font-size: 35px;
						}
					}
				}
			}
		}

		/*typography utils*/
		text-align: center;
		P {
			font-size: 14px;
		}
		&::before {
			display: none;
		}

		&:hover {
			border: 1px solid @color01;
		}
		.anim-editable-hide {
			display: none;
		}
		.anim-editable-show {
			display: block;
		}
		/*subitem*/
		.subitem {
			.txt {
				height: 63px;
				.heading01 {
					margin-top: 0;
					color: @color-black-light;
					font-weight: bold;
				}
				.meta {
					color: @color03;
					margin: 0;
					font-size: 15px;
					font-weight: bold;
				}
			}
		}

		/*subitem02*/
		.subitem02 {
			DIV.input-wrapper {
				float: none;
				INPUT {
					cursor: pointer;
				}
			}
			SELECT {
				width: 100%;
				@media @media-desktop {
					.anim-display(none);
				}
			}
			.prices03 {
				.price {
					padding: 15px 0;
					color: @color-black-light;
					.price-month {
						font-weight: bold;
						line-height: 0.9;
					}
					.price-helper {
						vertical-align: top;
						color: @color19;
						display: inline-block;
						font-size: 14px;
					}
				}
				.exclamation {
					.anim-display(none);
				}
				.show-open {
					opacity: 0;
					&--price {
						font-size: 0;
						color: @color-gray08;
					}
				}
			}
			.anim-input {
				.display(flex);
				.justify-content(space-between);
				.box-sizing(border-box);
				width: 100%;
				.flex-direction(row);
				padding: 0 2px;
				.calc-2-10p {
					width: calc(1 / 2 * 100% - (1 - 1 / 2) * 10px);
				}
				@media @media-notwide {
					.calc-2-10p {
						width: calc(1 / 2 * 100% - (1 - 1 / 2) * 5px);
					}
				}
			}
		}
		/*mixins*/
		.anim-display(@value) when (@value = none), (@value = inline-block), (@value = block) {
			display: @value;
		}

		/*block editable*/
		.anim-editable {
			position: relative;
			.zindex(@animEditableZindex,1);
			opacity: 0;
			height: 0;
			cursor: pointer;
			DIV.input-wrapper {
				INPUT {
					cursor: pointer;
				}
				LABEL {
					cursor: pointer;
				}
			}
			&__text {
				text-align: center;
				@media @media-notdesktop {
					text-align: left;
				}
			}
		}
		.anim-editable--additional {
			.zindex(@animEditableZindex,0);
		}
		.anim-editable__zindex {
			&--0 {
				.zindex(@animEditableZindex,0);
			}
		}
		/*block editable media query */
		.anim-editable-expand-mobile {
			@media @media-desktop {
				.anim-display(none);
			}
		}
		.anim-editable-mobile {
			@media @media-desktop {
				.anim-display(none);
			}
		}

		.anim-editable-prices-mobile {
			@media @media-desktop {
				.anim-display(none);
			}
		}

		/*block editable prices */
		.anim-editable-prices {
			width: 100%;
		}

		/*block editable btn */
		.anim-editable-btn {
			z-index: 9;
			cursor: default;
			opacity: 0;
			height: 0;
		}
		.anim-editable-btn--mobile {
			display: none;
		}
		.anim-editable-prices--mobile {
			display: none;
		}
		/*animation + status active main-block item*/
		&.active {
			/*typography utils*/
			cursor: default;
			.anim-editable-btn {
				z-index: 9;
				cursor: default;
				animation: editable-btn-animation .3s linear forwards, editable-btn-animation-delay .3s linear 0.3s forwards;
				@keyframes editable-btn-animation {
					to {
						height: 55px;
					}
				}

				@keyframes editable-btn-animation-delay {
					to {
						opacity: 1;
					}
				}

			}
			.anim-editable-prices {
				cursor: default;
			}
			/*animation status active main-block item*/
			animation: active-animation .3s linear forwards;
			@keyframes active-animation {
				to {
					background: @color-white;
					border: 1px solid @color03;
					width: 39%;
					height: 355px;
					max-height: none;
				}
			}
			&.active-direct {
				height: auto;
				max-height: none;
				.anim-input {
					display: none;
				}
				/*.anim-editable {
					.anim-display(none);
				}*/
				animation: active-direct-animation .3s linear forwards;
				@keyframes active-direct-animation {
					to {
						background: @color-white;
						border: 1px solid @color03;
						width: 39%;
						height: 260px;
					}
				}
			}
			&.active-additional {
				height: auto;
				max-height: none;
				animation: active-additional-animation .3s linear forwards;
				@keyframes active-additional-animation {
					to {
						background: @color-white;
						border: 1px solid @color03;
						width: 39%;
						height: 470px;
					}
				}
			}
			&.active-direct-additional {
				height: auto;
				max-height: none;
				animation: active-direct-additional-animation .3s linear forwards;
				@keyframes active-direct-additional-animation {
					to {
						background: @color-white;
						border: 1px solid @color03;
						width: 39%;
						height: 355px;
					}
				}
			}
			/*subitem status active*/
			.subitem {
				.txt {
					cursor: default;
					text-align: center;
					.heading01 {
						animation: heading01-animation-active .3s linear forwards;
						@keyframes heading01-animation-active {
							to {
								font-size: 18px;
							}
						}
					}
				}
			}
			/*subitem02 status active*/
			.subitem02 {
				.prices03 {
					height: 80px;
					.exclamation {
						.anim-display(inline-block);
					}
					.show-open {
						opacity: 1;
						&--price {
							animation: show-open-price-animation-active .3s linear forwards;
							@keyframes show-open-price-animation-active {
								to {
									font-size: 14px;
								}
							}
						}
					}
					.price {
						.price-month {
							animation: prices03-animation-active .3s linear forwards;
							@keyframes prices03-animation-active {
								to {
									font-size: 35px;
								}
							}
						}
					}
				}
				DIV.input-wrapper {
					float: none;
					INPUT {
						cursor: text;
					}
					LABEL {
						cursor: default;
					}
				}
			}
			/*block editable animation*/
			.anim-editable {
				cursor: default;
				animation: editable-animation 0.3s linear forwards, editable-animation-delay .3s linear 0.3s forwards;
				@keyframes editable-animation {
					to {
						height: 101px;
					}
				}
				@keyframes editable-animation-delay {
					100% {
						opacity: 1;
					}
				}
			}
			/*block media query editable*/
			.anim-editable-mobile {
				@media @media-desktop {
					.anim-display(none);
				}
			}
			.anim-editable-expand-mobile {
				@media @media-desktop {
					.anim-display(none);
				}
			}
		}
		&.inactive {
			/*inactive typography utils*/
			cursor: pointer;
			border: 1px solid @color18;
			overflow: hidden;
			&:hover {
				border: 1px solid @color01;
			}
			/*animation status inactive main-block item*/
			animation: inactive-animation .3s linear forwards;
			@keyframes inactive-animation {
				to {
					background: lighten(@color18, 5%);
					width: 29%;
				}
			}
			.anim-editable {
				animation: editable-animation-inactive 0.3s linear forwards;
				@keyframes editable-animation-inactive {
					from {
						height: 101px;
					}
					to {
						height: 0;
					}
				}
			}
			/*subitem status inactive*/
			.subitem {
				.txt {
					cursor: pointer;
					.heading01 {
						animation: heading01-animation-inactive .3s linear forwards;
						@keyframes heading01-animation-inactive {
							from {
								font-size: 18px;
							}
							to {
								font-size: 15px;
							}
						}
					}
				}
			}
			/*subitem02 status inactive*/
			.subitem02 {
				.prices03 {
					.price {
						.price-month {
							animation: prices03-animation-inactive .3s linear forwards;
							@keyframes prices03-animation-inactive {
								from {
									font-size: 35px;
								}
								to {
									font-size: 25px;
								}
							}
						}

					}
				}
				DIV.input-wrapper {
					float: none;
					INPUT {
						cursor: pointer;
					}
					LABEL {
						cursor: pointer;
					}
				}
			}
		}
		&--additional {

		}
	}
	@media @media-desktop {
		DIV.input-wrapper {
			.currency {
				&.currency--md {
					INPUT {
						padding: 0 40px 0 5px;
					}
				}
			}
		}
	}
	@media (min-width: 1024px) and (max-width: 1101px) {
		.item {
			padding: 10px;
		}
	}
	@media (min-width: 1200px) and (max-width: 1280px) {
		.item {
			padding: 10px;
		}
	}
	@media @media-notdesktop {
		display: block;
		.item {
			/*item notdesktop*/
			display: block;
			width: 100%;

			height: auto;
			min-height: 1px;
			/*max-height: 85px;*/
			max-height: 100px;
			.anim-editable-prices {
				display: none;
			}
			.anim-editable-expand-mobile {
				height: 0;
				opacity: 0;
			}
			.anim-editable-btn {
				display: none;
			}
			.anim-editable-prices--mobile {
				display: block;
				opacity: 0;
			}
			.anim-editable-btn--mobile {
				display: block;
				z-index: 9;
				cursor: default;
				opacity: 0;
				height: 0;
			}
			.anim-editable-mobile {
				display: block;
				opacity: 0;
				height: 0;
			}
			.price-helper {
				text-align: right;
			}
			.subitem {
				.display(flex);
				.flex-direction(row);
				.box-sizing(border-box);
				.justify-content(space-between);
				.txt {
					text-align: left;
					.heading01 {
						text-align: left;
						margin-top: 0;
						color: @color-black-light;
						font-weight: bold;
					}
					.meta {
						text-align: left;

						color: @color03;
						margin: 0;
						font-size: 15px;
						font-weight: bold;
					}
				}
				.prices03 {
					.price {
						.price-month {
							white-space: nowrap;
							font-size: 22px;
							animation: none;
						}
					}
				}
			}
			/*main-block item status active notdesktop*/
			&.active {
				height: auto;
				width: 100%;
				animation: none;
				background: @color-white;
				cursor: default;
				border: 1px solid @color03;
				max-height: none;
				.txt {
					.heading01 {
						animation: none;
					}
				}
				/*editable status active notdesktop animation*/
				.anim-editable-mobile {
					opacity: 1;
					height: 100px;
					position: relative;
					/*animation: editable-mobile-animation .3s linear forwards;
					@keyframes editable-mobile-animation {
						to {
							opacity: 1;
							position: relative;
						}
					}*/
				}
				.anim-editable-expand-mobile {
					height: auto;
					opacity: 1;
					position: relative;
				}
				.anim-editable-btn--mobile {
					z-index: 9;
					cursor: default;
					height: 75px;
					opacity: 1;
				}
				.anim-editable-prices--mobile {
					opacity: 1;
				}
				/*no editable block*/
				&.active-direct {
					height: auto;
					width: 100%;
					animation: none;
					background: @color-white;
					cursor: default;
					border: 1px solid @color03;
					.anim-editable-mobile {
						opacity: 1;
						height: 75px;
						position: relative;
					}
				}
				&.active-additional {
					height: auto;
					width: 100%;
					animation: none;
					background: @color-white;
					cursor: default;
					border: 1px solid @color03;
					.anim-editable-mobile {
						opacity: 1;
						position: relative;
					}
				}
				&.active-direct-additional {
					height: auto;
					width: 100%;
					animation: none;
					background: @color-white;
					cursor: default;
					border: 1px solid @color03;
					.anim-editable-mobile {
						opacity: 1;
						position: relative;
					}
				}
			}
			/*main-block item status inactive notdesktop*/
			&.inactive {
				width: 100%;
				animation: none;
				border: 1px solid @color18;
				background: lighten(@color18, 5%);
				/*max-height: 85px;*/
				max-height: 100px;
			}
			&--additional {

			}
		}
	}
}

.block32 {

}

.block33 {
	.heading03 {
		/*padding: 7px 10px 7px 0;*/
		padding-right: 5px;
		/*line-height: 2.7;*/
		position: relative;
		top: 10px;
		left: 0;
		float: left;
		text-align: left;
		font-size: @base-font-size-paragraph;
		/*.note {
			color: @color-gray-medium;
		}*/
	}
	.heading04 {
		color: @color-gray-medium;
		font-size: 28px;
		font-weight: normal;
		line-height: normal;
		margin: 0;
		padding: 0;
	}
	P {
		margin: 0 !important;
		.date {

		}
		.note {
			color: @color-gray-medium;
			padding-right: 5px;
		}
	}
	DIV.input-wrapper {
		margin: 0;
	}
	DIV.button-wrapper {
		margin: 0;
		padding: 0;
		.btn {
			padding: 8px 15px;
		}
	}
}

.block34 {
	/*border: 1px solid @color-gray-light;*/
	width: 100%;
	position: relative;
	.equalheight & {
		.flex-align-self(stretch);
		width: 100%;
	}
	&.bg {
		background: @color25;
	}
	.pad {
		padding: 20px;
	}
	.badge04 {
		left: 0;
		top: -18px;
	}
	.item {
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(nowrap);
		.flex-direction(row);
		.justify-content(flex-start);
		.subitem-img {
			.box-sizing(border-box);
			.flex(0 1 auto);
			height: 200px;
			text-align: center;
			/*max-height: 200px;*/
			overflow: hidden;
			#content & IMG {
				max-width: none;
				height: 100%;
			}
			IMG {
				max-width: none;
				height: 100%;
			}
		}
		.subitem-img01 {
			.box-sizing(border-box);
			.flex(1 1 auto);
			width: 30%;
			height: 200px;
			text-align: center;
			/*max-height: 200px;*/
			overflow: hidden;
			background: no-repeat 50% 50%; background-size: cover;
		}
		.subitem01 {
			.flex(0 0 auto);
			width: 70%;
			margin-left: 20px;
			SPAN {
				white-space: nowrap;
			}
			.heading01 {
				font-weight: bold;
				font-size: 18px;
				margin: 0;
			}
		}
	}
	@media @media-mobile {
		.item {
			.flex-wrap(wrap);
			.subitem01 {
				width: 100%;
				margin-left: 0;
				.heading01 {
					margin: 1em 0 0 0;
				}
			}
			.subitem-img01 {
				width: 100%;
			}
			.subitem-img {
				width: 100%;
			}
		}
	}
	@media @media-tablet {

	}
	@media @media-mobile {

	}
}

/* 16. Messages */
UL.msg01 {
	margin: 10px 0 0 0;
	padding: 0;
	clear: both;
	DIV.block01 & {
		margin: 10px 0 0 0;
	}
	&:first-child,
	H1 + & {
		margin: 2em 0 0 0;
	}
	& > LI {
		background: @color-msg-info;
		margin: 0 0 10px 0;
		padding: 15px 15px 15px 50px !important;
		color: @color-black;
		font-size: 15px;
		overflow: hidden;
		position: relative;
		&:before {
			text-align: center;
			line-height: 22px;
			background: @color-white;
			width: 22px;
			height: 22px;
			.rounded(50%);
			font-family: "icons";
			position: absolute;
			top: 15px;
			left: 15px;
			font-size: 12px;
			content: @ico-info-clear;
			color: @color-msg-info-text;
		}

		&.msg-neutral {
			background: @color-blue-light;
			&:before {
				content: @ico-info-clear;
				color: @color-msg-info-text;
			}
		}
		&.msg-error {
			background: @color-msg-negative;
			&:before {
				content: @ico-cross;
				color: @color-msg-negative-text;
			}
		}
		&.msg-ok {
			background: @color-msg-positive;
			&:before {
				content: @ico-check;
				color: @color-msg-positive-text;
			}
		}
		&.msg-info {
			background: @color-msg-warn;
			&:before {
				content: @ico-exclamation;
				color: @color-msg-warn-text;
			}
		}
		&.msg-muted,
		&.type02 {
			background: @color-gray-lightest;
			color: @color-black;
			&:before {
				color: @color-gray-medium;
			}
		}
		[class^="ico"]:before,
		[class*=" ico"]:before {
			margin-right: 5px;
		}
		&.type02 {
			border-width: 1px;
		}
		&.isclosable {
			padding-right: 50px;
		}
		&.noico {
			padding-left: 15px !important;
			font-size: 13px;
			&:before {
				display: none;
			}
		}
		H2 {
			margin: 10px 0 0 0 !important;
		}
		P {
			margin: 10px 0 0 0 !important;
			padding: 0;
			color: @color-black;
			text-align: left;
		}
		P:first-child,
		A.btn.f-right + P,
		H3:first-child,
		H2:first-child {
			margin: 0 !important;
		}
		H2,
		H3,
		H4,
		H5,
		H6,
		LI {
			color: @color-black;
		}
		A {
			color: @color01;
			text-decoration: underline;
			&.close {
				position: absolute;
				top: 15px;
				right: 20px;
				text-indent: -10000px;
				display: block;
				width: 12px;
				height: 12px;
				background: url(ico01.png) no-repeat 100% -2550px;
				:before {
					content: @ico-cross;
					font-family: "icons";
				}
			}
		}
	}
}

.msg02 {
	margin: 50px 0 0 0;
	background: url(bg106.png) no-repeat 0 0;
	padding: 45px 0 45px 200px;
	min-height: 192px;
	.box-sizing(border-box);
	P {
		margin: 0;
		font-size: 20px;
		color: @color-black;
	}
}

/* 17. Filters */
/*RF*/
#filters01 {
	padding: 0;
	margin: 1em 0 0 0;
	H2 {
		margin: 0;
		font-size: 17px;
		color: @color-black;
		font-weight: bold;
	}
	.block01 {
		padding: 0;
	}
	.filter01a,
	.filter01b {
		.display(flex);
		.flex-wrap(wrap); /*.justify-content(space-between);*/
		padding: 10px 0;
	}
	.filter01a {
	}
	.filter01b {
		display: none;
		border-top: 1px solid @color-gray-light;
	}
	.filter01c {
		padding: 15px 30px;
		.clearfix();
		A.toggler01 {
			float: left;
			margin-top: 10px;
		}
		UL.actions01 {
			float: right;
			LI {
				&:first-child {
					margin-left: 20px;
				}
			}
		}
	}
	.filter01e {
		.col {
			&.w35p {
				flex: 1 0 35%;
				width: 35%;
			}
			&.w00p {
				flex: 0 0 auto;
				width: auto;
			}
			border-left-style: none;
			padding: 10px 0;
			.discrete {
				padding: 0;
			}
			.checkboxes01 {
				padding-left: 30px;
			}
			.search01 {
				INPUT[type="text"] {
					height: 30px;
				}
				SPAN.btn {
					height: 30px;
				}
				BUTTON {
					height: 30px;
				}
			}
		}
	}
	A.close {
		display: none;
	}
	A.toggler01 {
		font-weight: bold;
		font-size: 17px;
		cursor: pointer;
		&:before {
			font-family: "icons";
			content: @ico-arrdown;
			margin-right: 10px;
			font-size: 12px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	&.open {
		.filter01b {
			display: block;
			.display(flex);
		}
		A.toggler01 {
			&:before {
				content: @ico-arrup;
			}
		}
	}
	.col {
		.flex(0 0 auto);
		width: 33%;
		padding: 10px 30px;
		.box-sizing(border-box);
		position: relative;
		border: 1px @color-gray-light;
		border-style: none none none solid;
		&:nth-of-type(3n+1) {
			border-left-style: none;
		}
		&:nth-of-type(n+4) {
			border-top-style: solid;
		}
	}

	UL.checkboxes01 {
		margin: 1em 0 0 0;
		LI {
			&:before {
				display: none;
			}
			LABEL {
				padding: .6em 0 .6em 24px;
			}
			A {
				padding: .6em 0;
				cursor: pointer;
				display: block;
			}
		}
	}
	SELECT {
		display: none;
	}
	@media @media-tablet {
		padding: 0 0 40px;
		UL.actions01 {
			float: none;
			margin: 20px 0 0 0;
		}

		.filter01c {
			UL.actions01 {
				margin: 0;
			}
		}

		UL.checkboxes01 {
			&.in2cols,
			&.in3cols {
				LI {
					width: 100%;
					margin: 0;
				}
			}
		}
	}
	@media @media-mobile {
		padding: 0 0 20px;
		position: static;
		.col {
			padding: 20px 20px 0;
			border-top: none !important;
			&:after {
				display: none;
			}
		}

		.block01 {
			display: none;
			margin: 0;
			padding-bottom: 20px;
			P.center {
				margin: 1em 20px 0;
			}
		}

		A.toggler01 {
			display: block;
			text-align: center;
			padding-top: 10px;
			border-top: 2px dashed @color-gray-light;
		}

		.filter01a {
			display: block;
			border-bottom: none;
			padding-bottom: 10px;
		}

		&.mobile-open {
			&:before {
				content: "";
				position: fixed;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: fade(@color-black, 80%);
				z-index: 98;
			}
			.block01 {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 99;
			}
		}

		&.open {
			.filter01b {
				display: block;
				padding: 0;
				border-top: 0
			}
		}

		A.close {
			position: absolute;
			right: 20px;
			top: 15px;
			text-indent: -10000px;
			display: block;
			z-index: 10;
			&:before {
				float: left;
				font-family: "icons";
				content: @ico-cross;
				color: @color-gray-medium;
				font-size: 22px;
				text-indent: 0;
			}
		}

		UL.actions01 {
			float: none;
			margin: 20px 0 0 0;
			LI {
				display: block;
			}
		}

		H2 {
			font-weight: bold;
			margin: .5em 0 0 0;
		}

		UL.checkboxes01 {
			display: none;
		}

		SELECT {
			display: block;
			margin: .5em 0 0 0;
			OPTION {
				height: 25px;
			}
		}

		#filters02 UL.colors01 LI {
			margin-left: 2px;
			&:first-child {
				margin-left: 0;
			}
		}

		.filter01c {
			padding: 0;
			A.toggler01 {
				float: none;
				margin-top: 0;
			}
			UL.actions01 {
				float: none;
				LI {
					float: none;
					&:first-child {
						margin-left: 0;
					}
					.btn.discrete {
						margin: 0;

					}
				}
			}
		}
	}
}

#filters02 {
	padding: 40px 0 0;
	P {
		float: left;
		font-size: 24px;
		margin: 0;
	}
	UL.checkboxes02 {
		float: right;
		margin: 0;
	}
	@media @media-mobile {
		padding: 0;
		P {
			float: none;
		}
	}
}

.filters {
	.filter {
		&__item {
			padding-right: 30px;
			padding-left: 30px;
			padding-bottom: 30px;
		}
	}
	.filter__item:not(:last-child) {
		border-right: 1px solid @color-gray06;
		@media @media-mobile {
			border: 0;
		}
	}
	&.open {
		.filter:not(:last-child) {
			border-bottom: 1px solid @color-gray06;
		}
		.filters__anim {
			max-height: 300px;
		}
	}
	.filters__anim {
		max-height: 0;
		overflow: hidden;
		transition: max-height 500ms;
	}
}

/* 18. Product Lists */
UL.products01 {
	margin: 30px 0 0 0;
	padding: 0;
	list-style: none;
	clear: both;
	&:first-child {
		margin: 0;
	}
	& > LI {
		margin: 30px 0 0 0;
		padding: 30px 0;
		border: 1px solid @color-gray-light;
		zoom: 1;
		position: relative;
		.clearfix();
		&:before {
			display: none;
		}
		&:first-child {
			margin: 0;
		}
		&.type01 {
			border-bottom-width: 2px;
			background: no-repeat 100% 100%;
			background-size: 100% auto;
			DIV.badge01 {
				position: absolute;
				bottom: 21px;
				left: 35px;
			}
		}
		&.type02 {
			padding-top: 40px;
			border-width: 2px;
			border-color: @color02;
			P.heading01 {
				background: @color02;
				color: @color-white;
				margin: 3px 20px 0 -20px;
				padding: 10px 100px 10px 20px;
				font-size: 22px;
				position: relative;
				.ru {
					padding: 10px 115px 10px 20px;
				}
				A {
					color: @color-white;
					&:hover {
						color: @color-white;
					}
				}
				SPAN {
					position: absolute;
					right: 0;
					width: 60px;
					text-align: center;
					bottom: 0;
					top: 0;
					padding: 10px 20px;
					line-height: 30px;
					color: @color-white;
					font-size: 15px;
					&:before {
						font-family: "icons";
						content: @ico-owl;
						font-weight: normal;
						line-height: 30px;
						position: absolute;
						color: @color02;
						right: 20px;
						bottom: 100%;
						font-size: 30px;
					}
					.ru & {
						width: 95px;
						padding: 10px;
					}
				}
			}
			.col05 {
				margin: 1.5em 0 0 0;
			}
			P.price02 {
				font-weight: bold;
			}
		}
	}
	.col01 {
		width: 22%;
		margin: 0 0 0 3%;
	}
	.col05 {
		width: 27.3333%;
		margin: 0 0 0 3%;
	}
	+ UL.actions01 {
		margin: 50px 0 0 0;
	}
	DIV.txt {
		float: left;
		width: 100%;
		margin-left: -280px;
		margin-right: -25%;
		padding-left: 280px;
		.box-sizing(border-box);
	}
	DIV.img {
		position: relative;
		float: left;
		margin: 0 40px;
		width: 235px;
		padding-bottom: 40px;
		text-align: center;
		.box-sizing(border-box);
		IMG {
			max-width: 100%;
			max-height: 235px;
			height: auto;
			width: auto;
		}
	}
	DIV.params01 {
		margin: 30px 0 -30px;
		border-top: 1px solid @color-gray-lightest;
		background: lighten(@color-gray-lightest, 5%);
		padding: 30px;
	}
	P.heading01 {
		font-size: 32px;
		margin: 0;
		font-family: @font-heading;
		.ru & {
			font-family: @font-heading-ru;
		}
		A {
			color: @color-black;
			&:hover {
				text-decoration: none;
				color: @color02;
			}
		}
	}
	P.heading02 {
		font-size: 20px;
		margin: 0;
	}
	P.price01 {
		margin: .3em 0 0 0;
		font-size: 27px;
		font-weight: bold;
		white-space: nowrap;
		A.help {
			top: .2em;
		}
	}
	P.price02 {
		margin: 0;
		line-height: 1.2;
		font-size: 16px;
		color: @color-black;
		font-weight: bold;
	}
	UL.actions01 {
		margin: 1em 0 0 0;
		LI.separated {
			&:after {
				content: "|";
				color: @color-gray-medium;
				margin-left: 10px;
			}
			&:last-child:after {
				display: none;
			}
		}
		.btn {
			&.color01 {
				font-weight: bold;
				font-size: 18px;
				margin-right: 20px;
			}
			&.discrete {
				border-bottom: none;
				margin: 0;
				padding: 12px 0 0 0;
			}
		}

	}

	@media @media-tablet {
		DIV.img {
			margin: 0 20px;
			padding-bottom: 0;
			width: 185px;
		}

		DIV.txt {
			margin-left: -185px;
			padding-left: 185px;
		}

		& > LI.type01 DIV.badge01 {
			bottom: 20px;
			left: 35px;
		}

		DIV.col04,
		DIV.col05 {
			width: auto;
			float: none;
			margin: 1em 0 0;
		}

		UL.actions01 {
			LI {
				float: left !important;
				margin-left: 10px !important;
				&:first-child {
					margin-left: 0 !important;
				}
			}
		}
	}
	@media @media-mobile {
		margin: 5px -15px 0;
		& > LI {
			overflow: hidden;
			padding: 15px;
			margin: 20px 0 0 0;
			&.type01 {
				background: none !important;
				DIV.badge01 {
					clear: both;
					position: static;
					text-align: center;
					padding: 1em 0 0 0;
				}
			}
			&.type02 {
				background: none !important;
				P.heading01 {
					margin: 3px 0 0 0;
					padding: 10px;
					SPAN {
						text-indent: -10000px;
						&:before {
							text-indent: 0;
						}
					}
				}
				UL.colors01 {
					margin: 1em 0 0 -6px;
				}
			}
		}

		P.heading01 {
			font-size: 25px;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
		}

		P.price01 {
			margin: .5em 0 0;
			white-space: normal;
			font-size: 20px;
		}

		P.price02 {
			font-size: 14px;
		}

		DIV.img {
			width: 85px;
			margin: 0;
			padding-bottom: 0;
		}

		DIV.txt {
			padding-left: 120px;
			margin-left: -100px;
			margin-right: 0;
			padding-bottom: 0;
		}

		DIV.col {
			float: none;
			width: auto;
		}

		DIV.col01 {
			padding-left: 100px;
			margin: 0;
		}

		DIV.col04 {
			display: none;
		}
	}
}

P.price03 {
	margin: 0;
	float: right;
	width: 180px;
	height: 184px;
	line-height: 1;
	.box-sizing(border-box);
	padding-top: 55px;
	background: url(bg05.png) no-repeat 50% 50%;
	background-size: contain;
	color: @color-white;
	text-align: center;
	font-size: 17px;
	font-weight: bold;
	STRONG {
		display: block;
		font-weight: bold;
		font-size: 42px;
	}
	SPAN {
		display: block;
		font-size: 15px;
	}
	@media @media-mobile {
		float: none;
		margin: 20px auto 0 !important;
	}
}

/*SPAN.recommend01 {
	float: left; text-indent: -10000px; width: 110px; height: 27px; background: url(ico_recommend.png) no-repeat;
	@media @media-mobile { display: none; width: 80px; height: 18px; background-size: 100% 100%; margin: 4px 0 0 0; }
}*/

UL.products02 {
	list-style: none;
	padding: 0;
	margin: 0 -.5% 0 -.5%;
	position: relative;
	padding-bottom: 20px;
	.display(flex);
	.flex-wrap(wrap);
	&.type02 > LI SPAN.img {
		height: 260px;
	}
	& > LI {
		overflow: hidden;
		width: 32.3333%;
		position: relative;
		float: left;
		padding: 40px 20px 20px;
		.box-sizing(border-box);
		text-align: center;
		border: 1px solid #ddd;
		margin: 1% .5% 0 .5%;
		@media screen and (min--moz-device-pixel-ratio: 0) {
			margin-top: 15px;
		}
		.display(flex);
		.flex-direction(column);
		> A {
			.flex(1 0 auto);
		}
		&.disabled {
			&:before {
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: fade(@color-white, 50%);
				content: "";
				z-index: 10;
			}
		}
		SPAN.img {
			position: relative;
			SPAN.bonus {
				z-index: 9;
				left: 25px;
				bottom: -10px;
				right: auto;
				display: block;
				position: absolute;
				width: 80px;
				height: 80px;
			}
			SPAN.bonus01 {
				border-radius: 50%;
				border: none;
				margin-top: -1em;
				z-index: 9;
				left: 25px;
				bottom: -10px;
				right: auto;
				display: block;
				position: absolute;
				width: 80px;
				height: 80px;
				background: @color02;
				.ico {
					text-align: center;
					color: @color-white;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 14px;
					font-family: @font-heading;
					line-height: 14px;
					&:before {
						margin: 0 auto;
						float: none;
						font-size: 18px;
					}
				}
			}
			&.owl {
				&:after {
					display: block;
					position: absolute;
					right: 25px;
					left: auto;
					bottom: -10px;
					content: "";
					background: @color12 url(owl02.png) no-repeat 50% 50%;
					background-size: contain;
					width: 80px;
					height: 80px;
					.rounded(50%);
					z-index: 10;
				}
			}
		}
		&.type02 {
			SPAN.price01 {
				color: @color02 !important;
				SMALL {
					color: @color02;
				}
			}
			SPAN.price02 {
				color: @color02;
				content: "";
				DEL, STRIKE {
					display: inline;
					color: @color-gray-medium;
				}
			}
			SPAN.img {
				position: relative;
				SPAN.bonus {
					z-index: 9;
					left: 25px;
					bottom: -10px;
					right: auto;
					display: block;
					position: absolute;
					width: 80px;
					height: 80px;
				}
				SPAN.bonus01 {
					border-radius: 50%;
					border: none;
					margin-top: -1em;
					z-index: 9;
					left: 25px;
					bottom: -10px;
					right: auto;
					display: block;
					position: absolute;
					width: 80px;
					height: 80px;
					background: @color02;
					.ico {
						text-align: center;
						color: @color-white;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 14px;
						font-family: @font-heading;
						line-height: 14px;
						&:before {
							margin: 0 auto;
							float: none;
							font-size: 18px;
						}
					}
				}
			}
		}
		&.type03 {
			position: relative;
			SPAN.img {
				position: relative;
				SPAN.bonus {
					z-index: 9;
					left: 25px;
					bottom: -10px;
					right: auto;
					display: block;
					position: absolute;
					width: 80px;
					height: 80px;
				}
				SPAN.bonus01 {
					border-radius: 50%;
					border: none;
					margin-top: -1em;
					z-index: 9;
					left: 25px;
					bottom: -10px;
					right: auto;
					display: block;
					position: absolute;
					width: 80px;
					height: 80px;
					background: @color02;
					.ico {
						text-align: center;
						color: @color-white;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 14px;
						font-family: @font-heading;
						line-height: 14px;
						&:before {
							margin: 0 auto;
							float: none;
							font-size: 18px;
						}
					}
				}
			}
			border-color: @color02;
			SPAN.heading01 {
				font-size: 14px;
				margin: .5em -10px 0;
				position: relative;
				overflow: visible;
				SPAN {
					padding: .3em 17px;
					max-height: 3.7em;
					line-height: 1.4;
					overflow: hidden;
					font-size: 18px;
				}
				+ .price01 {
					margin-top: 25px;
				}
			}
			SPAN.price01 {
				color: @color02;
				SMALL {
					color: @color02;
				}
			}
			SPAN.price02 {
				color: @color02;
				DEL, STRIKE {
					display: inline;
					color: @color-gray-medium;
				}
			}
		}
		&.type04 {
			background: fade(@color-gray-lightest, 40%);
			cursor: default;
			&:hover {
				cursor: default;
				border-color: #ddd !important;
			}
		}
		&.type05 {
			background: fade(@color-gray-lightest, 40%);
		}

		&.nocolor {
			& > A {
				padding-bottom: 50px;
			}
		}
		&.nobuy {
			P.actions01 {
				padding-top: 0;
			}
		}
		&:before {
			display: none;
		}
		&.ico-check:before {
			display: block;
			position: absolute;
			left: 20px;
			top: 20px;
			font-size: 30px;
			color: @color-positive;
		}

		& > A {
			display: block;
		}
		A {
			text-decoration: none;
			* {
				display: block;
			}
			&:hover {
				text-decoration: none;
				SPAN.heading01 {
					color: @color02;
				}
			}
		}
		SPAN.badge01 {
			position: absolute;
			left: 0;
			top: 15px;
			padding: 10px 5px;
			font-size: 17px;
			font-weight: bold;
			width: 175px;
			text-align: center;
			.box-sizing(border-box);
		}
		SPAN {
			display: block;
		}
		SPAN.status {
			position: relative;
			padding-left: 25px;
			&:before {
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		SPAN.img {
			height: 291px;
			position: relative;
			&.small {
				height: 160px;
			}
			IMG {
				max-width: 100%;
				max-height: 100%;
				height: auto;
				width: auto;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				pointer-events: none;
				margin: auto;
			}
			@media @media-mobile {
				height: auto;
			}
		}
		SPAN.heading01 {
			font-size: 18px;
			margin: .6em 0 0 0;
			height: 4.2em;
			line-height: 1.5;
			overflow: hidden;
			color: @color-black;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
			& + SPAN.price02 {
				margin-top: 40px;
			}
		}
		SPAN.price01 {
			color: @color-black;
			font-size: 30px;
			font-weight: bold;
			margin: .2em 0 0 0;
			&:last-child:first-child,
			&.last {
				padding-bottom: 33px;
			}
			SMALL {
				display: inline;
				font-weight: normal;
				color: @color-gray-medium;
				font-size: 14px;
			}
		}
		SPAN.price02 {
			color: @color-black;
			font-size: 14px;
			display: block;
			line-height: 1.2;
			min-height: 2.4em;
		}
		SPAN.noprice {
			margin: 0;
			color: @color-gray-medium;
			font-size: 14px;
			padding: 26px 0;
		}
		UL.actions01 {
			text-align: center;
			margin: .3em 0 0 0;
			LI {
				float: none !important;
				display: inline-block;
				width: auto;
				margin: .5em 0 0 0;
				width: auto !important;
				A.btn,
				INPUT.btn {
					float: none;
					display: inline-block;
					min-width: 160px;
					.transform(translateZ(0));
				}
			}
		}
		P.small {
			font-size: 14px;
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
		}
		P.color01 {
			margin: .5em 0 0 0;
			SPAN {
				font-weight: bold;
				text-transform: uppercase;
			}
		}
		UL.colors01 {
			width: auto;
			text-align: center;
			margin: 1em auto 0;
			LI {
				float: none;
				display: inline-block;
				margin: 0;
			}
			A.more {
				margin: 0 0 -2px;
			}
		}
		P.actions01 {
			font-size: 14px;
			color: @color-gray-medium-light;
			SPAN.separator {
				display: inline-block;
				margin: 0 5px;
			}
		}
		P.alert01 {
			height: 0;
			text-align: center;
			margin: 0;
			SPAN {
				display: inline-block;
				background: fade(@color-black, 70%);
				.rounded(15px);
				color: @color-white;
				padding: 5px 15px;
				font-weight: bold;
				font-size: 12px;
				position: relative;
				z-index: 3;
			}
		}
	}
	&.products02b {
		& > LI {
			text-align: left;
			padding: 20px;
			margin-top: 5px;
			height: auto;
			min-height: 0;
			position: relative;
			.display(flex);
			&.active {
				border: 2px solid @color12;
			}
			&.type04 {
			}
			DIV.b {
				margin-top: 2em;
				SPAN.price01 {
					margin: 0;
				}
			}
			SPAN.img {
				.flex(0 0 291px);
			}
			SPAN.heading01 {
				font-size: 22px;
				font-weight: bold;
				height: auto;
				min-height: 3em;
				.flex(0 0);
				SMALL {
					font-family: @font-main;
					font-size: @base-font-size;
					display: block;
					font-weight: normal;
					color: @color-gray-medium;
				}
				&.hasicons {
					min-height: 4.5em
				}
				&:first-child {
					margin: -20px 0 0 0;
				}
				font-family: @font-heading;
				.ru & {
					font-family: @font-heading-ru;
				}
				SPAN.icons {
					display: block;
					overflow: hidden;
					min-height: 24px;
					SPAN {
						float: left;
						font-size: 16px;
						margin-right: 5px;
						color: @color-gray-medium;
					}
				}
			}
			P.numbers {
				max-height: 4.5em;
				overflow: hidden;
				SPAN {
					display: inline;
				}
			}
			DIV.form01 {
				min-height: 3em;
				DIV.items {
					LABEL { /*margin-top: 3px;*/
					}
					SPAN.text {
						padding: 12px 0 0 0;
						line-height: 1.2;
						&.ico-check,
						&.ico-cross {
							padding: 0;
							font-size: 86%;
						}
						&:before {
							margin-right: 5px;
						}
					}
					.btn {
						padding: 8px 13px;
						&.remove {
							margin-left: 0 !important;
							padding-left: 0;
							margin-right: 0 !important;
						}
						&:hover:before {
							color: @color02;
						}
						&.w100p {
							text-align: center;
						}
						&.ico-cross:before {
							font-size: 12px;
						}
					}

				}
			}
			SPAN.price01 {
				margin: 1em 0 0 0;
				font-size: 17px;
				font-weight: bold;
			}
			SPAN.description {
				overflow: hidden;
				.flex(1 0 auto);
				white-space: normal;
				SPAN.status {
					margin: 1em 0 0 0;
					font-weight: bold;
				}
			}
		}
	}
	&.products02c {
		margin: 0 -0.5%;
		& > LI {
			width: 19%;
			padding: 20px 10px 85px;
			border-width: 0 0 2px 0;
			DIV.b {
				bottom: 10px;
				left: 5px;
				right: 5px;
				@media @media-mobile {
					margin-top: 0;
				}
			}
			SPAN.heading02 {
				font-weight: bold;
				height: 2.4em;
				overflow: hidden;
				line-height: 1.2;
				STRONG {
					display: inline;
				}
			}
			SPAN.meta01 {
				font-size: 14px;
				color: @color-gray-dark;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				height: 1.4em;
				STRONG {
					display: inline;
				}
			}
			A {
				text-decoration: none;
				&:hover SPAN.heading02 {
					color: @color02;
				}
			}
			SPAN.img,
			A.img {
				position: relative;
				&:before {
					content: "";
					display: block;
					padding-bottom: 145%;
				}
				IMG {
					position: absolute;
					bottom: 0;
					left: 0;
					max-width: 100%;
					max-height: 100%;
					width: auto;
					height: auto;
				}
			}
		}
	}
	&.products02d {
		& > LI {
			height: 545px;
		}
	}
	&.products02e {
		& > LI {
			cursor: pointer;
			&:hover {
				border-color: @color12;
			}
			/*SPAN.ico-check:before {
				padding-right: 5px;
				color: @color-msg-positive;
				display: inline;
			}*/

		}
	}
	&.products02f {
		& > LI {
			border: none;
			padding: 0;
			SPAN.heading01 {
				font-size: 24px;
				color: @color02;
				STRONG {
					color: @color01;
					font-weight: normal;
				}
			}
		}
		&.in3cols {
			& > LI {
				width: 31%;
				margin: 0 2% 0 0;
			}
			@media @media-mobile {
				& > LI {
					width: 100%;
					margin: 2em 0 0 0;
				}
			}
		}
		@media @media-mobile {
			> LI SPAN.img {
				height: auto;
			}
		}
	}
	&.products02g {
		LI {
			cursor: default;
			.b {
				margin-top: 0 !important;
				DIV.form01 {
					DIV.items {
						P.price01 {
							font-size: 20px;
						}
						LABEL {
							width: 100%;
							font-size: 13px;
							padding: 11px 0 0 0;
						}
						.prices02 {
							height: 112px;
							min-height: 112px;
						}
						.prices02b {
							height: 112px;
							min-height: 112px;
							.display(flex);
							.align-items(flex-end);
						}
						.prices01 {
							height: 70px;
							min-height: 70px;
						}
						.prices01b {
							height: 70px;
							min-height: 70px;
							.display(flex);
							.align-items(flex-end);
						}
						.prices01c {
							height: 117px;
							min-height: 117px;
							.display(flex);
							.align-items(flex-end);
						}
						.text {
							&.ico-check {
								color: @color-msg-positive;
								font-weight: bold;
							}
						}
					}
				}

			}

		}
	}
	@media @media-desktop {
		& > LI {
			/*&:nth-of-type(3n+1) { clear: both;  } */
			clear: none !important;
		}

		&.products02c > LI {
			&:nth-of-type(3n+1),
			&:nth-of-type(4n+1) {
				clear: none !important;
			}
			&:nth-of-type(5n+1) {
				clear: both !important;
			}
		}
	}
	@media @media-wide {
		& > LI {
			width: 24%;
			&:nth-of-type(3n+1) {
				clear: none !important;
			}
			&:nth-of-type(4n+1) {
				clear: both !important;
			}
		}

		&.products02c {
			& > LI {
				width: 11.5%;
				&:nth-of-type(3n+1),
				&:nth-of-type(4n+1),
				&:nth-of-type(5n+1) {
					clear: none !important;
				}
				&:nth-of-type(8n+1) {
					clear: both !important;
				}

			}
		}
	}
	@media @media-tablet {
		& > LI {
			width: 49%;
			clear: none !important;
			/*&:nth-of-type(3n+1) { clear: none; }
			&:nth-of-type(2n+1) { clear: both !important; }*/
		}

		&.products02c {
			& > LI {
				width: 24%;
				&:nth-of-type(2n+1),
				&:nth-of-type(3n+1),
				&:nth-of-type(5n+1) {
					clear: none !important;
				}
				&:nth-of-type(4n+1) {
					clear: both !important;
				}
			}
		}

	}
	@media @media-mobile {
		display: block;
		margin: 0;
		& > LI {
			.clearfix();
			float: none;
			width: auto;
			height: auto;
			margin: 1em 0 0 0;
			min-height: 0;
			padding: 15px;
			&.nocolor {
				> A {
					padding-bottom: 0;
				}
			}
			A {
				.clearfix();
				display: block;
				&:after {
					display: none;
				}
			}
			&.type02:before {
				top: auto;
				bottom: 15px;
				left: 10px;
				background-position: 0 100%;
			}
			&.type03 SPAN.heading01 SPAN {
				max-height: none;
			}
			SPAN {
				.box-sizing(border-box);
				text-align: center;
				&.img {
					/*float: left; width: 85px; height: auto !important; */
					text-align: center;
					IMG {
						position: static;
						max-height: 250px;
						width: auto;
					}
				}
				&.heading01 {
					margin-top: 0;
					overflow: visible;
					line-height: 1.4;
					height: auto; /*padding-left: 120px;*/
					font-size: 25px !important;
					& + SPAN.price02 {
						margin-top: 10px;
					}
				}
				&.price01 {
					margin: .5em 0 0 0;
					font-size: 22px;
					font-weight: bold; /*padding-left: 120px;*/
					line-height: 1.2;
					&:last-child,
					&.last {
						padding-bottom: 0;
					}
				}
				&.price02 {
					margin: 0;
					line-height: 1.2;
					font-size: 14px;
					color: @color-gray-medium; /*padding-left: 120px;*/
				}
				&.noprice {
					margin: .5em 0 0 0;
					font-size: 20px; /*padding: 0 0 0 120px;*/
				}
			}
			UL.colors01 {
				&:before, &:after {
					display: none;
				}
				/*padding-left: 120px;*/
				text-align: left;
				overflow: visible;
				LI {
					padding: 0 3px !important;
				}
			}
			P.actions01,
			UL.actions01 {
				display: none;
				text-align: left; /*padding-left: 123px;*/
				margin-top: 20px;
				LI {
					display: block;
					A.btn,
					SPAN.btn {
						font-weight: bold;
						font-size: 18px;
						min-width: 0;
					}
				}
			}
			P.alert01 {
				height: auto; /*padding-left: 120px;*/
				text-align: left;
			}
		}

		&.products02b {
			& > LI {
				padding: 15px 0 20px;
				display: block;
				margin: 0;
				width: auto;
				border-style: none none solid none;
				&.active {
					padding-bottom: 10px;
				}
				&.type04 {
					padding-bottom: 10px;
				}
				A.img + DIV.b {
					padding-left: 120px;
				}
				DIV.b {
					position: static;
				}
				DIV.description + DIV.b {
				}
				&:first-child {
					border-top-style: solid;
				}
				SPAN {
					text-align: left;
					&.heading01:first-child {
						margin-top: 0;
					}
					&.img {
						margin-left: 10px;
						.flex(0 0 auto);
						float: left;
						width: 85px;
						& + SPAN.heading01 {
							padding-left: 105px;
							& + SPAN.description {
								padding-left: 10px;
								& + DIV.b {
									padding: 0 15px 10px 105px;
									DIV.row {
										overflow: visible;
									}
									.btn {
										clear: both;
										width: 100%;
										position: relative;
										top: 10px;
									}
								}
							}
						}
					}
				}

				DIV.form01 {
					DIV.items {
						LABEL {
							margin-right: 10px;
						}
						SELECT {
							width: auto;
						}
						.btn {
							float: right;
							clear: none;
							width: auto;
							margin: 0;
							&.remove {
							}
						}
					}
				}
				SPAN.heading01 {
					padding-left: 0;
					min-height: 0;
				}
				SPAN.price01 {
					padding-left: 0;
					margin: .5em 0 0 0 !important;
					text-align: left !important;
				}
				SPAN.description {
					height: auto;
				}
				A.img {
					padding-bottom: 0;
					IMG {
						position: static;
					}
				}
			}
		}

		&.products02c {
			& > LI {
				width: auto;
				A.img {
					width: 100px;
					float: left;
					&:before {
						display: none;
					}
				}
				SPAN {
					text-align: left;
				}
				SPAN.heading02 {
					height: auto;
				}
				SPAN.meta01 {
					white-space: normal;
				}
			}
		}

		&.products02d {
			& > LI {
				height: auto;
				SPAN {
					text-align: left;
				}
			}
		}

		&.products02e {
			& > LI {
				SPAN {
					text-align: left;
				}
			}
		}
	}
}

UL.products03 {
	.clearfix();
	margin: 0 -2% 0 0;
	.display(flex);
	.flex-wrap(wrap);
	LI {
		.display(flex);
		.flex-direction(column);
		.box-sizing(border-box);
		padding: 15px;
		border: 1px solid @color-gray-lightest;
		float: left;
		width: 23%;
		margin: 10px 2% 0 0;
		cursor: pointer;
		&:nth-child(4n+1) {
			clear: both;
		}
		&:before {
			display: none;
		}
		&:hover {
			border-color: @color12;
		}
		&.disabled {
			opacity: .5;
			cursor: default;
			&:hover {
				border-color: @color-gray-lightest;
			}
		}
		&.active {
			border-color: @color12;
			border-width: 2px;
			padding: 14px;
		}
		&.type01 {
			background: fade(@color-gray-lightest, 40%);
			cursor: default;
			&:hover {
				border-color: @color-gray-lightest;
			}
		}
		DIV.form02 {
			.items {
				.display(flex);
				.flex(1);
				.flex-direction(column);
				.box-sizing(border-box);
				height: 100%;
				.prices01 {
					/*height: 50px;*/
					min-height: 50px;
					.display(flex);
					.flex-direction(column);
					.justify-content(flex-end);
				}
				.prices02 {
					height: 100%;
					min-height: 142px;
					.display(flex);
					.flex-direction(column);
					.justify-content(flex-end);
					.box-sizing(border-box);
					.h01 {
						height: 71px;
						min-height: 71px;
						.display(flex);
						.justify-content(space-between);
						.align-items(flex-end);
						.box-sizing(border-box);
					}
				}
				LABEL {
					font-size: 13px;
					padding: 10px 0 0;
					&.wauto {
						width: auto;
						float: none;
						display: block;
					}
				}
				DEL {
					display: block;
					height: 22px;
				}
				SPAN.text {
					padding: 12px 0 0 0;
					line-height: 1.2;
					white-space: nowrap;
					&.ico-check:before {
						margin-right: 5px;
						font-size: 80%;
					}
					&.ico-check {
						padding: 7px 0;
						display: block;
						font-size: 86%;
					}
					&:before {
						margin-right: 5px;
					}
				}
			}
		}
	}
	P {
		margin: .5em 0 0 0;
		&.heading01 {
			font-size: 20px;
			margin: 0;
			font-weight: bold;
			font-family: @font-heading;
			.flex(0 0 auto);
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		&.price01 {
			font-size: 20px;
			margin: 1em 0 0 0;
			font-weight: bold;
			.flex(0 0 auto);
			.red {
				font-size: 14px;
				display: block;
			}
			.small {
				font-size: 12px;
				font-weight: normal;
			}
		}
		&.status {
			padding-left: 25px;
			position: relative;
			SPAN {
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		A.btn {
			float: right;
			font-size: @base-font-size;
			&.main {
				font-weight: normal;
				margin-top: -5px;
				font-size: 15px;
				line-height: 1.1 !important;
				padding: 9px 9px 8px;
				&.ico-plus:before {
					line-height: 1.1 !important;
					margin-top: -.5em;
				}
				&.ico-cross:before {
					font-size: 12px;
				}
				&:hover {
					color: @color02;
					&:before {
						color: @color02;
					}
				}
				@media @media-mobile {
					margin-top: 0;
					text-align: center;
				}
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
	SPAN.ico-check {
		color: @color-msg-positive;
		font-weight: bold;
	}
	DIV.text {
		overflow: hidden;
		.flex(1 1 auto);
		white-space: normal;
	}
	@media @media-notwide {
		LI {
			width: 31.3333%;
			&:nth-child(4n+1) {
				clear: none;
			}
			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}
	@media @media-tablet {
		LI {
			width: 48%;
			&:nth-child(4n+1),
			&:nth-child(3n+1) {
				clear: none;
			}
			&:nth-child(2n+1) {
				clear: both;
			}
		}
	}
	@media @media-mobile {
		LI {
			width: 100%;
			margin: 10px 0 0 0;
			DIV.form02 {
				DIV.items {
					.prices02 {
						height: 100%;
						.flex(1 1 auto);
						.h01 {
							.flex-direction(column);
							.justify-content(flex-end);
							.align-items(flex-start);
							height: 100%;
							.flex(1 1 auto);
						}
					}
					LABEL {
						padding: 0;
					}
				}
			}
		}

		DIV.text {
			height: auto;
		}

		A.btn {
			text-align: left;
		}
	}
}

UL.products04 {
	.clearfix();
	margin: 0 -2% 0 0;
	.display(flex);
	.flex-wrap(wrap);
	 LI.products04__item {
		position: relative;
		.display(flex);
		.flex-direction(column);
		.justify-content(flex-start);
		.box-sizing(border-box);
		padding: 30px;
		border: 1px solid @color-gray-lightest;
		float: left;
		width: 23%;
		margin: 10px 2% 0 0;
		cursor: pointer;
		&:nth-child(4n+1) {
			clear: both;
		}
		&:before {
			display: none;
		}
		&:hover {
			border-color: @color01;
		}
		.img {
			margin: 0 auto;
			max-width: 100px;
		}
		&.disabled {
			opacity: .5;
			cursor: default;
			&:hover {
				border-color: @color-gray-lightest;
			}
		}
		&.confirmed {
			border-color: @color12;
			border-width: 1px;
		}
		&.active {
			background: @color-white;
			border-color: @color01;
			border-width: 1px;
		}
		&.type01 {
			background: fade(@color-gray-lightest, 40%);
			cursor: default;
			&:hover {
				border-color: @color-gray-lightest;
			}
		}
		DIV.form02 {
			margin-top: auto;
			.prices {
				margin: 2em 0 0 0;
				.display(flex);
				.flex-direction(column);
				.justify-content(flex-start);
				.box-sizing(border-box);
				.align-items(center);
				height: 70px;
				/*height: 45px;*/
				P.price01 {
					color: #1a1a1a;
					font-size: 20px;
					font-weight: bold;
				}
			}
			DIV.items {
				.display(flex);
				.flex-direction(row);
				.justify-content(center);
				.box-sizing(border-box);
				.align-items(flex-end);
				/*.align-self(flex-end);*/
				height: 55px;
				LABEL {
					font-size: 13px;
					padding: 10px 0 0;
					&.wauto {
						width: auto;
						float: none;
						display: block;
					}
				}
				SPAN.text {
					padding: 12px 0 0 0;
					line-height: 1.2;
					white-space: nowrap;
					&.f-right {
						text-align: right;
						float: right;
					}
					SPAN.small {
						font-size: 10px;
						font-weight: normal;
						color: @color-gray-medium;
					}
					&.ico-check:before {
						margin-right: 5px;
						font-size: 80%;
					}
					&.ico-check,
					&.ico-cross {
						padding: 0;
						font-size: 86%;
					}
					&:before {
						margin-right: 5px;
					}
				}
				DEL {
					display: block;
					height: 22px;
				}
				@media @media-mobile {
					height: 90px;
				}
			}
		}
	}
	P {
		margin: .5em 0 0 0;
		&.heading01 {
			font-size: 20px;
			margin: 0;
			font-weight: bold;
			font-family: @font-heading;
			.flex(0 0 auto);
			.ru & {
				font-family: @font-heading-ru;
			}
		}
		&.status {
			padding-left: 25px;
			position: relative;
			SPAN {
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		A.btn {
			&.main {
				font-weight: normal;
				margin-top: -5px;
				font-size: 15px;
				line-height: 1.1 !important;
				padding: 9px 9px 8px;
				&.ico-plus:before {
					line-height: 1.1 !important;
					margin-top: -.5em;
				}
				&.ico-cross:before {
					font-size: 12px;
				}
				&:hover {
					color: @color02;
					&:before {
						color: @color02;
					}
				}
				@media @media-mobile {
					margin-top: 0;
					text-align: center;
				}
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
	@media @media-notwide {
		LI.products04__item {
			width: 31.3333%;
			&:nth-child(4n+1) {
				clear: none;
			}
			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}
	@media @media-tablet {
		LI.products04__item {
			width: 48%;
			&:nth-child(4n+1),
			&:nth-child(3n+1) {
				clear: none;
			}
			&:nth-child(2n+1) {
				clear: both;
			}
		}
	}
	@media @media-mobile {
		LI.products04__item {
			width: 100%;
			margin: 10px 0 0 0;
			UL.actions01 {
				width: 100%;
			}
		}
	}
}

DIV#slider01 {
	overflow: hidden;
	position: relative;
	DIV.a {
		margin: 0 30px;
		overflow: hidden;
	}
	UL.products02 {
		width: 10000px;
		margin: 2em 0 0 0;
		padding-bottom: 0;
		.flex-wrap(nowrap) !important;
		& > LI {
			width: 290px;
			min-height: 0;
			height: auto;
			margin: 0;
			border: none;
			padding: 0 0 70px;
			clear: none !important;
			float: left;
			SPAN.img {
				float: none;
				width: 98%;
				margin: 0 auto;
			}
		}
		LI:before {
			display: none;
		}
		SPAN.heading01 {
			font-size: 24px;
			height: 3em;
		}
		.price01,
		.price02 {
			color: @color02;
		}
		.price02 DEL {
			display: inline;
			color: @color-gray-medium;
		}
		.price01 SMALL {
			color: @color02;
		}
		P.small {
			bottom: 40px;
		}
	}
	A.prev,
	A.next {
		position: absolute;
		cursor: pointer;
		z-index: 99;
		display: none;
		top: 0;
		height: 100%;
		width: 30px;
		text-indent: -10000px;
		background-size: 27px auto !important;
		&.disabled {
			opacity: 0.2;
			cursor: default;
		}
	}
	&.haspages A.prev,
	&.haspages A.next {
		display: block;
	}
	A.prev {
		background: url(arr02.png) no-repeat 0 100px;
		left: 0;
	}
	A.next {
		background: url(arr02b.png) no-repeat 100% 100px;
		right: 0;
	}
	@media @media-tablet {
		UL.products02 > LI {
			margin: 1em 0 0 0;
			width: 210px;
		}
	}
	@media @media-mobile {
		UL.products02 {
			& > LI {
				padding-bottom: 0;
				P.actions01, UL.actions01 {
					display: block;
				}
				SPAN.img {
					float: none;
					width: 98%;
					margin: 0 auto;
					height: 200px !important;
					IMG {
						max-width: 100% !important;
						max-height: 100% !important;
						width: auto !important;
						height: auto !important;
					}
				}
				SPAN.heading01,
				SPAN.price01 {
					text-align: center;
					height: auto !important;
				}
				SPAN.heading01,
				SPAN.price01,
				UL.actions01 {
					padding-left: 0;
					width: 98%;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}

.toggle-wrapper {
	background: @color-white;
	border: 1px solid @color-gray-medium-lightest;
	.box-sizing(border-box);
	margin-bottom: 1.5em;
	display: inline-block;
	position: relative;
	height: 31px;
	width: 55px;
	.rounded(16px);
	&.type02 {
		width: 94px;
	}
	&.type03 {
		height: 25px;
		width: 50px;
		margin-bottom: 0;
		position: absolute;
		left: 0;
		top: .5em;
		&:after {
			text-indent: -10000px;
		}
		.switch {
			&:before {
				height: 15px;
				width: 15px;
			}
			&.bottom:after {
				font-size: 11px;
				display: none;
			}
		}
		.row .items & + SPAN.text {
			margin-left: 60px;
		}
	}
	input {
		height: 31px;
		margin: 0;
		padding: 0;
		opacity: 0;
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 2;
		&:first-child:checked ~ .switch:before {
			left: -1px;
			background: @color-negative;
		}
		&:first-child:nth-last-child(3) {
			width: 100%;
			&:checked ~ .switch {
				&:after {
					content: attr(data-off);
				}
			}
		}
		&:first-child:nth-last-child(3) ~ input {
			width: 100%;
			&:checked ~ .switch {
				background: @color-white;
				&:before {
					left: 23px;
					background: @color-positive;
					content: "";
				}
				&:after {
					content: attr(data-on);
				}
			}
		}

		&:first-child:nth-last-child(4) {
			width: 100%;
			&:checked {
				+ input:nth-of-type(2) {
					width: 66%;
					left: 0;
				}
			}
		}
		&:first-child:nth-last-child(4) ~ input {
			&:nth-of-type(2) {
				width: 66%;
				left: auto;
				right: 0;
				z-index: 3;
				&:checked {
					z-index: 0;
					& ~ input {
						width: 50%;
						left: auto;
					}
					& ~ .switch {
						&:before {
							left: 31px;
							background: @color-gray-medium;
							content: "";
						}
						&:after {
							content: attr(data-inbetween);
						}
					}
				}
			}
			&:nth-of-type(3):checked ~ .switch {
				background: @color-white;
				&:before {
					left: 62px;
					background: @color-positive;
					content: "";
				}
				&:after {
					content: attr(data-on);
				}
			}
		}

		&:checked {
			z-index: 0;
		}
	}
	.switch {
		background: lighten(@color-gray-lightest, 5%);
		height: 29px;
		.rounded(15px);
		&:before {
			background: @color15;
			content: "";
			color: @color-white;
			text-align: center;
			font-size: 16px;
			line-height: 21px;
			.rounded(50%);
			text-indent: 0;
			margin: 5px;
			display: block;
			float: left;
			height: 21px;
			width: 21px;
			left: -1px;
			position: relative;
			top: -1px;
			-webkit-transition: all .2s ease;
			transition: all .2s ease;
			z-index: 1; /* Remember, it must be below the invisible inputs */
		}
		&.bottom:after {
			left: 0;
			right: 0;
			text-align: center;
			top: 100%;
			margin-top: 5px;
			margin-left: 0;
			width: auto;
		}
		&:after {
			content: attr(data-off);
			position: absolute; /*top: 0;*/
			top: 8px;
			text-align: left;
			line-height: 1; /*color: @color-black;*/
			color: @color-gray-medium;
			left: 100%;
			width: 120px;
			margin: 0 0 0 10px;
		}
	}
}

SPAN.rate {
	display: block;
	width: 95px;
	overflow: hidden;
	margin: 2px 20px 0 0;
	float: left;
	A {
		float: left;
		width: 19px;
		height: 18px;
		margin: 0;
		text-indent: -10000px;
		background: url(ico_star01.png) no-repeat;
		&.active,
		&.hover-active {
			background: url(ico_star02.png) no-repeat;
		}
		&:hover {
			background: url(ico_star02.png) no-repeat !important;
		}
	}
	&.readonly A {
		cursor: default;
		background: url(ico_star01.png) no-repeat !important;
		&.active {
			background: url(ico_star02.png) no-repeat !important;
		}
	}
	@media @media-mobile {
		margin: 2px 15px 0 0;
	}
}

DIV.colors01 {
	margin: 30px 0 0 0;
	P {
		color: @color-gray-medium;
		font-size: ceil(@base-font-size);
	}
	STRONG {
		color: @color-black;
	}
	DIV.a {
		float: left;
		margin-right: 10px;
	}
	DIV.b {
		float: left;
	}
}

UL.colors01 {
	overflow: hidden;
	list-style: none;
	margin: 6px 0 0 -6px;
	padding: 0;
	float: none !important;
	LI {
		margin: 6px 0 0 3px;
		padding: 0 6px !important;
		text-align: left;
		background: none !important;
		float: left;
		.box-sizing(border-box);
		&:before {
			display: none;
		}
		LABEL {
			cursor: pointer;
			position: relative;
			text-align: center;
			display: block;
			font-weight: normal;
			overflow: hidden;
			background: none;
			padding: 0;
			z-index: 1;
			line-height: normal;
			font-size: 15px;
		}
	}
	INPUT {
		visibility: hidden;
		position: absolute;
		left: -100000px;
		&:checked + SPAN {
			&:before {
				background: url(ico_checked02.png) no-repeat 50% 50%;
			}
			&.type01:before {
				background: url(ico_checked.png) no-repeat 50% 50%;
			}
		}
		&:disabled + SPAN {
			SPAN { opacity: .5; }
			&:before { background: url(ico_disabled.png) no-repeat 50% 50%; background-size: contain; }
		}
	}
	SPAN {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
		}
		SPAN {
			display: block;
			width: 30px;
			height: 30px;
			padding: 0;
			background: url(bg01.png) no-repeat;
			background-size: cover;
			text-indent: -10000px;
			border-radius: 5px;
			overflow: hidden;
		}
		&.type01 SPAN {
			border: 1px solid @color-gray-medium-light;
			.box-sizing(border-box);
		}

	}
	A.more {
		text-indent: 0;
		line-height: 30px;
		text-align: center;
		background-color: @color-gray-lightest;
		color: @color-gray-medium;
		font-weight: bold;
		display: block;
		width: 30px;
		height: 30px;
		padding: 0;
		background: url(bg01.png) no-repeat;
		background-size: cover;
		border-radius: 5px;
		overflow: hidden;
		border: 1px solid @color-gray-medium-light;
	}
	@media @media-tablet {
		/*SPAN SPAN,*/
		A.more {
			width: 40px;
			height: 40px;
		}

		A.more {
			line-height: 40px;
		}
	}
	@media @media-mobile {
		LI {
			margin: 0 0 3px 0;
		}

		/*SPAN SPAN,*/
		A.more {
			width: 35px;
			height: 35px;
			line-height: 35px;
		}

		A.more {
			line-height: 35px;
		}
	}
}

.colors03 {
	.box-sizing(border-box);
	.display(flex);
	.flex-wrap(wrap);
	.flex-direction(row);
	.color-select {
		&.color-select__checkbox {
			&.color-select__checkbox--static {
				LABEL {
					height: auto;
					input[type=checkbox]:checked ~ SPAN.color-select--text {
						font-weight: 400;
					}
					input[type=checkbox] {
						&:disabled + SPAN {
							cursor: default;
							&:before {
								background: none;
							}
							transform: none;
						}
						&:checked + SPAN {
							border: none;
							width: 32px;
							/*height: 30px;*/
							display: block;
							cursor: default;
						}
					}
					border: 1px solid @color03;
					cursor: default;
					SPAN.color-select--text {
						.justify-content(center);
						padding: 0 10px;
						max-width: 150px;
					}
					input[type=checkbox] + SPAN {
						cursor: default;
						margin-right: 0;
					}
					input[type=checkbox]:checked + SPAN::before {
						opacity: 1;
						font: normal normal normal 16px/30px FontAwesome;
					}
				}
			}
			&.color-select__checkbox--sm {
				LABEL {
					.flex(0 0 auto);
					height: 20px;
					border: none;
					input[type=checkbox] + SPAN {
						width: 20px;
						height: 20px;
					}
				}
			}
			LABEL {
				SPAN.color-select--text {
					max-width: 100%;
					color: @color-gray01;
					font-size: 15px;
					font-weight: 400;
					.justify-content(flex-start);
					/*&.active {
						font-weight: 700;
					}*/
				}
				background: none;
				input[type=checkbox]:checked ~ SPAN.color-select--text {
					font-weight: 700;
					transition: font-weight .4s ease-in-out;
				}
				input[type=checkbox] {
					visibility: hidden;
					position: absolute;
					left: -100000px;
					&:checked + SPAN {
						border: 1px solid @color03;
						&::before {
							content: "\f00c";
							text-align: center;
							display: inline-block;
							font: normal normal normal 14px/25px FontAwesome;
							text-rendering: auto;
							-webkit-font-smoothing: antialiased;
						}
						&.type01::before {
							color: @color-white;
						}
					}
					&:disabled + SPAN {
						cursor: default;
						SPAN {
							opacity: .5;
						}
						/*&::after {
							background: url(ico_disabled.png) no-repeat;
							background-size: cover;
							opacity: 1;
							width: 100%;
							height: 100%;
						}*/
						&.nocolors {
							&::after {
								top: 50%;
								left: -87%;
								width: 90px;
								height: 2px;
								-webkit-transform: rotate(44deg); /* Safari and Chrome */
								-ms-transform: rotate(44deg); /* IE 9 */
								transform: rotate(44deg);
								display: block;
								text-align: center;
								position: absolute;
								z-index: 9;
								content: '';
								background-color: @color-red01;
							}
							&--light {
								&::after {
									background-color: @color-white;
								}
							}
						}
					}
				}
				input[type=checkbox] + SPAN::before {
					opacity: 0;
					.transition();
					font-family: "icons";
					content: "";
					top: 0;
					display: block;
					color: @color-gray01;
					line-height: 25px;
					font-size: 12px;
					text-align: center;
					position: absolute;
					left: 0;
					right: 0;
					margin: auto;
					z-index: 9;
					.transition(@duration: .3s) {
						-webkit-transition: @duration;
						-moz-transition: @duration;
						-ms-transition: @duration;
						-o-transition: @duration;
						transition: @duration;
					}
				}
				input[type=checkbox] + SPAN {
					position: relative;
					width: 25px;
					height: 25px;
					margin-right: 10px;
					/*display: block;
					float: left;*/
					.display(flex);
					.align-self(center);
					cursor: pointer;
					padding: 0;
					background: url(bg01.png) no-repeat;
					background-size: cover;
					overflow: hidden;
					cursor: pointer;
					z-index: 1;
					SPAN {
						&.hasmulti {
							position: absolute;
							left: 20%;
							width: 100%;
							height: 200%;
							background-color: rgb(255, 255, 255); /* fallback */
							background-color: rgba(255, 255, 255, 0.5);
							top: 0;
							-webkit-transform: rotate(45deg);
							-moz-transform: rotate(45deg);
							transform: rotate(45deg);
						}
					}
				}
				input[type=checkbox]:checked + SPAN::before {
					opacity: 1;
					.transition();
				}
			}
		}
		padding: 0;
		margin: 0 8px 0 0;
		.flex(0 0 auto);
		width: auto;
		.box-sizing(border-box);
		text-align: center;
		&:before {
			display: none;
		}
		LABEL {
			.flex(1 1 auto);
			.display(flex);
			background: white;
			height: 30px;
			.link-outline-none;
			cursor: pointer;
			border: 1px solid transparent;
			transition: border 0.3s ease-out;
			.box-sizing(content-box);
			SPAN.color-select--text {
				font-size: 13px;
				line-height: 13px;
				color: @color-gray-medium;
				.display(flex);
				.flex-direction(row);
				.justify-content(center);
				.align-items(center);
				.box-sizing(border-box);
				height: auto;
				min-height: 100%;
				.flex(1 1 auto);
				overflow: hidden;
				padding: 0 0;
				max-width: 0;
				transition: max-width 0.3s ease-out, padding 0.3s ease-out;
			}
			&.open {
				border: 1px solid @color03;
				transition: border 0.3s ease-in;
				cursor: default;
				SPAN.color-select--text {
					padding: 0 10px;
					max-width: 150px;
					transition: max-width 0.3s ease-in, padding 0.3s ease-in;
				}
				input[type=radio] + SPAN {
					cursor: default;
				}
			}
			input[type=radio] {
				visibility: hidden;
				position: absolute;
				left: -100000px;
				&:checked + SPAN {
					&::before {
						font-family: "icons";
						content: @ico-check;
					}
					&.type01::before {
						color: @color-white;
					}
				}
				&:disabled + SPAN {
					cursor: default;
					SPAN {
						opacity: .5;
					}
					/*&::after {
						background: url(ico_disabled.png) no-repeat;
						background-size: cover;
						opacity: 1;
						width: 100%;
						height: 100%;
					}*/
					&.nocolors {
						&::after {
							top: 50%;
							left: -87%;
							width: 90px;
							height: 2px;
							-webkit-transform: rotate(44deg); /* Safari and Chrome */
							-ms-transform: rotate(44deg); /* IE 9 */
							transform: rotate(44deg);
							display: block;
							text-align: center;
							position: absolute;
							z-index: 9;
							content: '';
							background-color: @color-red01;
						}
						&--light {
							&::after {
								background-color: @color-white;
							}
						}
					}
				}
			}
			input[type=radio] + SPAN::before {
				opacity: 0;
				.transition();
				font-family: "icons";
				content: "";
				top: 0;
				display: block;
				color: @color-black;
				line-height: 30px;
				font-size: 15px;
				text-align: center;
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				z-index: 9;
				.transition(@duration: .3s) {
					-webkit-transition: @duration;
					-moz-transition: @duration;
					-ms-transition: @duration;
					-o-transition: @duration;
					transition: @duration;
				}
			}
			input[type=radio] + SPAN {
				position: relative;
				width: 32px;
				height: 30px;
				display: block;
				float: left;
				cursor: pointer;
				padding: 0;
				background: url(bg01.png) no-repeat;
				background-size: cover;
				overflow: hidden;
				cursor: pointer;
				z-index: 1;
				SPAN {
					&.hasmulti {
						position: absolute;
						left: 20%;
						width: 100%;
						height: 200%;
						background-color: rgb(255, 255, 255); /* fallback */
						background-color: rgba(255, 255, 255, 0.5);
						top: 0;
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						transform: rotate(45deg);
					}
				}
			}
			input[type=radio]:checked + SPAN::before {
				opacity: 1;
				.transition();
			}
		}
		.color-box {
			&.color-box__border {
				&--gray {
					border: 1px solid @color-gray06;
				}
			}
		}
	}
	@media @media-notdesktop {
		.color-select {
			margin: 8px 8px 0 0;
		}
	}
	@media @media-mobile {
		.color-select {
			.flex(0 0 auto);
			width: auto;
		}
	}

}


SPAN.color {
	color: @color-black;
	padding: 6px;
	display: block;
	.rounded(7px);
	overflow: hidden;
	SPAN {
		display: block;
		width: 30px;
		height: 30px;
		padding: 0;
		background: url(bg01.png) no-repeat;
		background-size: cover;
		text-indent: -10000px;
		border-radius: 5px;
		overflow: hidden;
	}
	&.small {
		display: inline-block;
		padding: 0;
		.rounded(3px);
		SPAN {
			width: 16px;
			height: 16px;
			.rounded(3px);
		}
	}
}

P.color {
	overflow: hidden;
	font-size: 17px;
	line-height: 30px;
	padding-bottom: 1em;
	SPAN {
		float: left;
		margin: 0 .5em 5px 0;
		color: @color-black;
		padding: 0;
		display: block;
		border-radius: 7px;
		overflow: hidden;
		SPAN {
			display: block;
			width: 30px;
			height: 30px;
			padding: 0;
			background: url(bg01.png) no-repeat;
			background-size: cover;
			text-indent: -10000px;
			border-radius: 5px;
			overflow: hidden;
			margin: 0;
		}
	}
	@media @media-notdesktop {
		.w50p .itbma_col04 & {
			SPAN.label + SPAN {
				clear: both;
			}
		}
	}
}

DIV.rating01 {
	width: 73%;
	float: right;
	UL {
		margin: 0;
		padding: 0;
	}
	LI {
		float: left;
		text-indent: -10000px;
		padding: 0 3px 0 0;
		margin: 5px 0 !important;
		width: 20px;
		&:before {
			content: "★";
			position: static;
			float: left;
			text-indent: 0;
			font-size: 24px;
			color: @color-gray-light;
		}
		&.active:before {
			color: @color04;
		}
	}
	DIV.col03,
	UL.comments01 & {
		width: 100%;
		float: none;
	}
	UL.comments01 & {
		overflow: hidden;
		padding-bottom: 10px;
		line-height: 1;
		LI:before {
			margin-top: -10px;
		}
	}
	@media @media-mobile {
		overflow: hidden;
	}
}

DIV.product01 {
	margin-top: 1.5em;
	font-size: 17px;
	position: relative;
	overflow: hidden;
	H1 {
		width: 73%;
		float: right;
		font-size: 46px;
		font-weight: normal;
	}
	H1.type04 {
		width: 64%;
		font-family: @font-main;
		font-size: 40px;
		line-height: 50px;
		color: @color01;
		float: right;
		@media @media-mobile {
			float: left;
			width: 100%;
			font-size: 30px;
			line-height: 35px;
		}
	}
	H2 { /*width: 73%;*/
		width: 64%;
		float: right;
		font-size: 32px;
		font-weight: normal;
		color: @color-gray-dark;
		margin: 0;
	}

	P.heading02 {
		font-size: 20px;
	}
	.product-item {
		.display(flex);
		.flex-direction(column);
		.align-items(flex-end);
		.product-subitem {
			.flex(0 0 auto);
			width: 95%;
		}
		@media @media-mobile {
			width: 100%;
			display: block;
			.flex-direction(none);
			.product-subitem {
				width: 100%;
				display: block;
				flex: none;
			}
		}
	}
	DIV.col03 {
		padding-bottom: 60px;
		&.f-right {
			width: 73%;
		}
		H1, H2 {
			width: 100%;
			float: none;
		}
	}
	DIV.col02 {
		float: left;
		padding-left: 25%;
		P.bold {
			SPAN.price01 {
				DEL {
					font-weight: normal;
				}
			}
		}
		P.ico-calendar {
			&:before {
				font-size: 24px;
				padding-right: 10px;
			}
			margin: 1.5em 0 1.5em 0;
		}
	}
	DIV.col01 {
		+ DIV.col03 DIV.col02 {
			padding-left: 0;
		}
		&:last-child {
			float: right;
		}
	}
	UL.actions01 {
		margin: 2em 0 0 0;
	}
	UL.type05 {
		margin-top: 3em;
		font-size: 17px;
		LI {
			padding-left: 65px;
			&:before {
				font-size: 30px;
			}
		}
	}
	A.img01 {
		display: block;
		margin: 1em 0 0 0;
	}
	DIV.badge01 {
		position: absolute;
		bottom: 20px;
		left: 0;
	}
	@media @media-tablet {
		margin: 0;
		H1 {
			width: 100%;
			float: none;
		}

		DIV.col03 {
			padding-bottom: 30px;
			DIV.col {
				float: none;
				width: auto;
				margin: 0;
			}
			&.f-right {
				width: 64.6666%;
			}
		}

		UL.type05 {
			margin: 0;
			LI {
				width: 48%;
				float: left;
				margin: 20px 2% 0 0 !important;
				padding-left: 50px;
				&:before {
					left: 0;
				}
			}
		}

		UL.colors01 {
			margin: 0 0 0 -5px;
		}
	}

	@media @media-mobile {
		H1 {
			width: auto;
			float: none;
		}

		P.heading02 {
			font-size: 25px;
		}

		DIV.col {
			float: none !important;
			width: auto !important;
		}

		DIV.col02 {
			padding-left: 0;
			P.ico-calendar {
				margin: 1.5em 0 1.5em 0;
			}
		}

		DIV.col03 {
			padding-bottom: 0;
			&.f-right {
				width: 100%;
				float: none;
				H1 {
					margin: 0
				}
			}
		}

		UL.type05 {
			margin-top: 1em;
		}

		UL.colors01 {
			margin: 0 0 0 -5px;
		}

		DIV.badge01 {
			position: static;
			margin: 1em 0 0 0;
			IMG {
				max-height: 25px;
				width: auto;
			}
		}

		A.img01.img01b {
			text-align: center;
		}
	}
}

SECTION.product02 {
	padding: 0;
	height: 0;
	.col00 {
		display: none;
		overflow: hidden;
	}
	DIV.img {
		float: left;
		padding: 0 15px 0 0;
		max-height: 90px;
		max-width: 90px;
		width: auto;
		height: auto;
	}
	P.heading {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
		margin: 23px 0 0 0;
		height: 2.4em;
		overflow: hidden;
	}
	UL.menu {
		margin: 0;
		float: right;
		padding: 0;
		list-style: none;
		LI {
			float: left;
			line-height: 74px;
			font-size: 17px;
			padding: 0;
			margin: 0;
			&:before {
				display: none;
			}
			A {
				display: block;
				padding: 0 30px;
			}
		}
	}
	A.btn {
		float: right !important;
		width: auto !important;
		margin: 15px 0 0 0;
	}
	&.scroll-to-fixed-fixed {
		height: auto;
		background: @color-white;
		-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		.col00 {
			display: block;
			padding: 15px;
		}
	}
	@media @media-notdesktop {
		UL.menu {
			display: none;
		}
	}
}

DIV.product03 {
	.clearfix();
	margin: 0;
	DIV.img {
		width: 145px;
		float: left;
		IMG {
			max-height: 150px;
			height: auto;
			width: auto;
		}
	}
	DIV.txt {
		width: 100%;
		float: left;
		margin-left: -145px;
		padding-left: 165px;
		.box-sizing(border-box);
		P.price {
			font-weight: bold;
			margin: 1em 0 0;
			font-size: ceil(@base-font-size*1.69);
		}
		H2 + P {
			margin: 0;
		}
	}
	@media @media-mobile {
		DIV.img {
			float: none;
			margin: auto;
		}

		DIV.txt {
			margin-left: 0;
			padding-left: 0;
			float: none;
		}
	}
}

DIV#gallery01 {
	padding-bottom: 120px;
	position: relative;
	SPAN.badge01 {
		position: absolute;
		left: 0;
		top: 35px;
		padding: 15px 5px;
		font-size: 17px;
		font-weight: bold;
		width: 230px;
		text-align: center;
		.box-sizing(border-box);
		z-index: 29;
	}
	UL {
		padding: 0;
		margin: 0;
		list-style: none;
		LI {
			padding: 0;
			margin: 0;
			&:before {
				display: none;
			}
		}
		&.b {
			margin: 2em 0 0 0;
			overflow: hidden;
			height: 77px;
			LI {
				.link-outline-none;
				width: 23%;
				margin: 0 0 0 2%;
				float: left;
				IMG {
					max-width: 100%;
					max-height: 70px;
					width: auto;
					height: auto;
					margin: auto;
				}
				A {
					display: block;
					position: relative; /*border-bottom: 2px solid transparent;*/
					height: 75px;
					text-align: center;
					opacity: 0.25;
				}
				&.active A { /*border-bottom: 2px solid @color02;*/
					opacity: 1;
				}
				&.video A:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba(0, 182, 242, 0.2) url(ico_play.png) no-repeat 50% 50%;
				}
			}
		}
		&.c {
			display: none;
		}
	}
	DIV.wrapper {
		overflow: hidden;
		height: 539px;
		position: relative;
		&:before {
			content: "";
			z-index: 3;
			background: @color-white url(old/loader.gif) no-repeat 50% 50%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		UL {
			position: relative;
			LI {
				float: left;
				-webkit-touch-callout: none;
				.user-select(none);
				A {
					display: block;
					text-align: center;
					outline: none;
				}
				IMG {
					display: block;
					margin: auto;
					max-width: 100%;
					max-height: 539px;
					height: auto;
					width: auto;
					pointer-events: none;
					-webkit-user-drag: none;
					.user-select(none);
				}
			}
		}
		DIV.bonus {
			position: absolute;
			z-index: 9;
			left: 0;
			bottom: 0;
			div.item {
				border-radius: 50%;
				border: 2px solid @color02;
				margin-top: -1em;
				width: 100px;
				height: 100px;
				position: relative;
				background: @color-white;
				&:first-child {
					margin-top: 0;
				}
				IMG {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					opacity: inherit;
					visibility: inherit;
				}
			}
		}
	}
	A.prev,
	A.next {
		display: none;
	}
	&.loaded DIV.wrapper {
		height: auto;
		&:before {
			display: none;
		}
	}
	@media @media-tablet {
		DIV.wrapper {
			margin: 20px 0 0 0;
			DIV.bonus {
				DIV.item {
					width: 100px;
					height: 100px;
				}
			}
		}
	}
	@media @media-mobile {
		padding-bottom: 0;
		width: 100%;
		overflow: hidden;
		DIV.wrapper {
			margin: 1em 40px 0;
			height: 380px;
			UL {
				LI IMG {
					max-height: 380px;
				}
				&.b {
					text-align: center;
					height: auto;
					padding-bottom: 5px;
					LI {
						float: none;
						display: inline-block !important;
						width: 19px;
						height: 22px;
						overflow: hidden;
						A {
							height: 19px;
							width: 19px;
							border-bottom: none !important;
							IMG {
								display: none;
							}
							&:before {
								display: inline-block;
								position: static !important;
								content: "";
								height: 19px;
								width: 19px;
								.box-sizing(border-box);
								border: 1px solid @color-gray-medium-light;
								border-radius: 10px;
							}
						}
						&.video A:before {
							background: none;
						}
						&.active A:before {
							border: none;
							background: @color01 !important;
						}
					}
				}
			}
			DIV.bonus {
				DIV.item {
					width: 100px;
					height: 100px;
				}
			}
		}

		A.prev,
		A.next {
			position: absolute;
			display: block;
			top: 40%;
			height: 39px;
			width: 27px;
			text-indent: -10000px;
			background-size: 100% auto !important;
		}

		A.prev {
			background: url(arr02.png) no-repeat;
			left: 0;
		}

		A.next {
			background: url(arr02b.png) no-repeat;
			right: 0;
		}
	}
}

DIV.product03 {
	.clearfix();
	margin: 0;
	DIV.img {
		width: 145px;
		float: left;
		IMG {
			max-height: 150px;
			height: auto;
			width: auto;
		}
	}
	DIV.txt {
		width: 100%;
		float: left;
		margin-left: -145px;
		padding-left: 165px;
		.box-sizing(border-box);
		P.price {
			font-weight: bold;
			margin: 1em 0 0;
			font-size: ceil(@base-font-size*1.69);
		}
		H2 + P {
			margin: 0;
		}
	}
	@media @media-mobile {
		DIV.img {
			float: none;
			margin: auto;
		}

		DIV.txt {
			margin-left: 0;
			padding-left: 0;
			float: none;
		}
	}
}

DIV.product04 {
	.clearfix();
	margin: 0;
	DIV.img {
		width: 145px;
		float: left;
		IMG {
			max-height: 150px;
			height: auto;
			width: auto;
		}
	}
	DIV.txt {
		width: 100%;
		float: left;
		margin-left: -145px;
		padding-left: 165px;
		.box-sizing(border-box);
		P.price {
			font-weight: bold;
			margin: 1em 0 0;
			font-size: ceil(@base-font-size*1.69);
		}
		H2 + P {
			margin: 0;
		}
	}
	@media @media-mobile {
		DIV.img {
			float: none;
			margin: auto;
		}

		DIV.txt {
			margin-left: 0;
			padding-left: 0;
			float: none;
		}
	}
}

DIV#gallery01 {
	padding-bottom: 120px;
	position: relative;
	SPAN.badge01 {
		position: absolute;
		left: 0;
		top: 35px;
		padding: 15px 5px;
		font-size: 17px;
		font-weight: bold;
		width: 230px;
		text-align: center;
		.box-sizing(border-box);
		z-index: 29;
	}
	UL {
		padding: 0;
		margin: 0;
		list-style: none;
		LI {
			padding: 0;
			margin: 0;
			&:before {
				display: none;
			}
		}
		&.b {
			margin: 2em 0 0 0;
			overflow: hidden;
			height: 77px;
			LI {
				width: 23%;
				margin: 0 0 0 2%;
				float: left;
				IMG {
					max-width: 100%;
					max-height: 70px;
					width: auto;
					height: auto;
					margin: auto;
				}
				A {
					display: block;
					position: relative; /*border-bottom: 2px solid transparent;*/ /*height: 75px;*/
					height: 80px;
					text-align: center;
					opacity: 0.25;
				}
				&.active A { /* border-bottom: 2px solid @color02;*/
					opacity: 1;
				}
				&.video A:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba(0, 182, 242, 0.2) url(ico_play.png) no-repeat 50% 50%;
				}
			}
		}
		&.c {
			display: none;
		}
	}
	DIV.wrapper {
		overflow: hidden;
		height: 539px;
		position: relative;
		&:before {
			content: "";
			z-index: 3;
			background: @color-white url(old/loader.gif) no-repeat 50% 50%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		UL {
			position: relative;
			LI {
				float: left;
				-webkit-touch-callout: none;
				.user-select(none);
				A {
					display: block;
					text-align: center;
					outline: none;
				}
				IMG {
					display: block;
					margin: auto;
					max-width: 100%;
					max-height: 539px;
					height: auto;
					width: auto;
					pointer-events: none;
					-webkit-user-drag: none;
					.user-select(none);
				}
			}
		}
		DIV.bonus {
			position: absolute;
			z-index: 9;
			left: 0;
			bottom: 0;
			div.item {
				border-radius: 50%;
				border: 2px solid @color02;
				margin-top: -1em;
				width: 100px;
				height: 100px;
				position: relative;
				background: @color-white;
				&:first-child {
					margin-top: 0;
				}
				IMG {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					opacity: inherit;
					visibility: inherit;
				}
			}
		}
	}
	A.prev,
	A.next {
		display: none;
	}
	&.loaded DIV.wrapper {
		height: auto;
		&:before {
			display: none;
		}
	}
	@media @media-tablet {
		DIV.wrapper {
			margin: 20px 0 0 0;
			DIV.bonus {
				DIV.item {
					width: 100px;
					height: 100px;
				}
			}
		}
	}
	@media @media-mobile {
		padding-bottom: 0;
		width: 100%;
		overflow: hidden;
		DIV.wrapper {
			margin: 1em 40px 0;
			height: 380px;
			UL {
				LI IMG {
					max-height: 380px;
				}
				&.b {
					text-align: center;
					height: auto;
					padding-bottom: 5px;
					LI {
						float: none;
						display: inline-block !important;
						width: 19px;
						height: 22px;
						overflow: hidden;
						A {
							height: 19px;
							width: 19px;
							border-bottom: none !important;
							IMG {
								display: none;
							}
							&:before {
								display: inline-block;
								position: static !important;
								content: "";
								height: 19px;
								width: 19px;
								.box-sizing(border-box);
								border: 1px solid @color-gray-medium-light;
								border-radius: 10px;
							}
						}
						&.video A:before {
							background: none;
						}
						&.active A:before {
							border: none;
							background: @color01 !important;
						}
					}
				}
			}
			DIV.bonus {
				DIV.item {
					width: 100px;
					height: 100px;
				}
			}
		}

		A.prev,
		A.next {
			position: absolute;
			display: block;
			top: 40%;
			height: 39px;
			width: 27px;
			text-indent: -10000px;
			background-size: 100% auto !important;
		}

		A.prev {
			background: url(arr02.png) no-repeat;
			left: 0;
		}

		A.next {
			background: url(arr02b.png) no-repeat;
			right: 0;
		}
	}
}

DIV#slider02 {
	margin: 2em auto 30px;
	position: relative;
	width: 1200px;
	height: auto;
	&:first-child {
		margin: 0 auto 60px;
	}
	A {
		&:active,
		&:focus {
			outline: none;
		}
	}
	A.prev,
	A.next {
		position: absolute;
		z-index: 99;
		display: block;
		top: 40%;
		height: 39px;
		width: 27px;
		text-indent: -10000px;
		background-size: 100% auto !important;
	}
	A.prev {
		background: url(arr02.png) no-repeat;
		left: 20px;
	}
	A.next {
		background: url(arr02b.png) no-repeat;
		right: 20px;
	}
	UL.slides {
		position: relative;
		height: 350px;
		z-index: 1;
		border-bottom: 1px solid @color-gray-lightest;
		&.slides02 {
			height: auto !important;
			padding-bottom: 29%;
			@media @media-notwide {
				height: 0;
				padding-bottom: 29%;
			}
			@media @media-mobile {
				padding-bottom: 65.625%;
			}
		}
		LI {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			background: no-repeat 50% 50%;
			background-size: cover;
			&:first-child {
				z-index: 10;
			}
			&:before {
				display: none;
			}
			A {
				display: block;
				position: relative;
				height: 100%;
				&.text-right {
					SPAN.text {
						left: 50%;
						right: 0;
						padding: 20px 100px 80px 0;
						SPAN.btn {
							left: 0;
						}
						@media @media-tablet {
							SPAN.btn {
								left: 35px;
							}

							left: 50%;
							padding: 50px 80px 50px 35px;
						}
						@media @media-mobile {
							left: 0;
							right: 48%;
							padding: 10px 0 50px 25px;
							SPAN.btn {
								display: block;
								bottom: 15px;
								right: 0;
								left: 25px;
								padding-left: 5px;
								padding-right: 5px;
								font-size: 20px;
								height: auto;
							}
						}
					}
				}
			}

		}
		IMG {
			max-height: 100%;
			max-width: 100%;
			height: auto;
			width: auto;
			position: relative;
			left: 50%;
			top: 0;
			@media @media-notmobile {
				.transform(translate(-50%, 0));
			}
			.iefix & {
				.transform(none);
				left: 0;
			}
		}
		SPAN.img {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: no-repeat 50% 50%;
			background-size: cover;
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ SPAN.img {
				background-size: contain;
				background-position: 50% 0;
				@media @media-mobile {
					background-size: contain;
					background-position: 50% 0;
				}
			}
		}

		SPAN.text {
			background: none;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 50%;
			width: auto;
			height: auto;
			padding: 20px 0 80px 100px;
			.display(flex);
			.flex-direction(column);
			.justify-content(center);
			SPAN {
				line-height: 1em;
				font-family: @font-heading;
				display: block;
				.ru & {
					font-family: @font-heading-ru;
				}
				&.heading {
					font-size: 64px;
					color: @color01;
					font-weight: 300;
				}
				&.description {
					margin: .5em 0 0 0;
					font-size: 24px;
					color: @color-black;
					font-weight: 300;
				}
				&.btn {
					position: absolute;
					bottom: 20px;
					left: 100px;
					cursor: pointer;
					background: @color02;
					color: @color-white;
					margin: 1em 0 0 0;
					display: inline-block;
					font-size: 24px;
					font-weight: bold;
					height: 50px;
					text-align: center;
					&:hover {
						background: darken(@color02, 5%);
					}
				}
			}
			@media @media-tablet {
				right: 50%;
				padding: 50px 35px 50px 80px;
				SPAN {
					&.heading {
						font-size: 54px;
					}
					&.description {
						font-size: 20px;
					}
					&.btn {
						bottom: 20px;
						left: 80px;
					}
				}
			}
			@media @media-mobile {
				right: 48%;
				padding: 10px 0 50px 25px;
				SPAN {
					&.heading {
						font-size: 32px;
					}
					&.description {
						font-size: 20px;
						line-height: 1.2;
					}
					&.btn {
						display: block;
						bottom: 15px;
						left: 25px;
						right: 0;
						padding-left: 5px;
						padding-right: 5px;
						font-size: 20px;
						height: auto;
					}
				}
			}
		}

	}
	P.bullets {
		z-index: 19;
		position: absolute;
		bottom: -30px;
		right: 20px;
		left: 20px;
		margin: auto;
		text-align: center;
		A {
			display: inline-block;
			width: 12px;
			height: 12px;
			margin: 0 0 0 8px;
			border: 2px solid @color01;
			.rounded(6px);
			.box-sizing(border-box);
			&.active {
				background: @color01;
			}
			SPAN {
				display: none;
			}
		}
	}
	@media @media-notwide {
		width: 100%;
		P.bullets {
			width: auto;
		}
	}
	@media @media-tablet {
		width: auto;
		UL.slides {
		}

		UL.slides {
			height: 350px;
			.iefix & IMG {
				margin-left: 0;
				left: 0;
			}
		}

		P.bullets {
			text-align: center;
			width: auto;
		}
	}
	@media @media-mobile {
		width: auto; /*overflow: hidden;*/
		height: auto;
		UL.slides {
			height: 286px;
			overflow: hidden;
			.iefix & IMG {
				margin-left: 0;
				left: 0;
			}
			LI, A {
				height: 100%;
				display: block;
			}
			A {
				position: relative;
				width: 100%;
				overflow: hidden;
			}
			IMG {
				margin: 0 auto;
				.transform(none);
				left: 0;
				position: relative;
				top: 0;
				max-width: 100%;
				max-height: 100%;
				width: auto;
				height: auto;
			}
		}

		//P.bullets { display: none }
		A.prev,
		A.next {
			display: none;
		}
	}
}

DIV#banner01 {
	margin: 2em auto 0;
	position: relative;
	width: 1200px;
	height: 350px;
	&:first-child {
		margin: 0 auto;
	}
	.wrapper {
		overflow: hidden;
		height: 350px;
		position: relative;
	}
	A {
		&:active,
		&:focus {
			outline: none;
		}
	}
	A.prev,
	A.next {
		position: absolute;
		z-index: 99;
		display: block;
		top: 40%;
		height: 39px;
		width: 27px;
		text-indent: -10000px;
		background-size: 100% auto !important;
	}
	A.prev {
		background: url(arr02.png) no-repeat;
		left: 20px;
	}
	A.next {
		background: url(arr02b.png) no-repeat;
		right: 20px;
	}
	UL.slides {
		position: relative;
		height: 350px;
		z-index: 1;
		border-bottom: 1px solid @color-gray-lightest;
		LI {
			height: 100%;
			padding: 0;
			margin: 0;
			float: left;
			background: no-repeat 50% 50%;
			background-size: cover;
			&:before {
				display: none;
			}
			A {
				display: block;
				position: relative;
				height: 100%;
				width: 100%;
				overflow: hidden;
			}
		}
		IMG {
			height: 100%;
			max-width: none;
			width: auto;
			position: relative;
			left: 50%;
			top: 0;
			pointer-events: none;
			@media @media-notmobile {
				.transform(translate(-50%, 0));
			}
			-webkit-user-drag: none;
			-webkit-user-select: none;
			.iefix & {
				.transform(none);
				left: 0;
			}

		}

	}
	P.bullets {
		z-index: 19;
		position: absolute;
		bottom: 1px;
		right: 0;
		left: 0;
		margin: auto;
		width: 1200px;
		text-align: right;
		font-size: 16px;
		font-weight: 300;
		font-family: @font-heading;
		height: 30px;
		.ru & {
			font-family: @font-heading-ru;
		}
		A {
			display: inline-block;
			margin: 0 1px 0 0;
			width: 30px;
			text-align: center;
			height: 30px;
			line-height: 30px;
			color: @color-gray-dark;
			background: @color-white;
			&:before {
				content: attr(data-text);
				position: absolute;
				top: 100%;
				right: 0;
				margin-top: 1px;
				background: @color-gray-lightest;
				padding: 3px 10px;
				display: none;
			}
			&:hover {
				text-decoration: none;
				background: @color-gray-lightest !important;
				color: @color-gray-dark !important;
			}
			&.active {
				background: @color01;
				color: @color-white;
			}
		}
	}
	@media @media-notwide {
		width: 100%;
		P.bullets {
			width: 100%;
		}
	}
	@media @media-tablet {
		width: auto;
		UL.slides {
			height: 350px;
			.iefix & IMG {
				margin-left: 0;
				left: 0;
			}
		}

		P.bullets {
			text-align: center;
			width: auto;
		}
	}
	@media @media-mobile {
		width: auto;
		overflow: hidden;
		height: auto;
		UL.slides {
			height: auto;
			overflow: hidden;
			padding-bottom: 65.625%;
			.iefix & IMG {
				margin-left: 0;
				left: 0;
			}
			LI, A {
				height: 100%;
				display: block;
			}
			A {
				position: relative;
			}
			IMG {
				height: 100%;
				.transform(none);
				left: 0;
			}
			LI SPAN {
				display: none;
			}
		}

		P.bullets {
			bottom: 10px;
			text-align: center;
			width: auto;
		}

		A.prev,
		A.next {
			top: auto;
			bottom: 12px;
			height: 18px;
			width: 10px;
		}

		A.prev {
			left: 50%;
			margin-left: -75px;
		}

		A.next {
			right: 50%;
			margin-right: -75px;
		}
	}
}

DIV.banner03 {
	position: relative;
	overflow: hidden;
	width: 980px;
	margin: 2em auto 0;
	@media @media-mobile {
		width: auto;
		padding: 0;
	}
	@media screen and (min-width: 760px) and (max-width: 1199px) {
		width: auto;
	}
	@media @media-wide {
		width: 1200px;
	}
	&:first-child {
		margin: 0 auto;
	}
	DIV.img {
		background: no-repeat 50% 50%;
		background-size: cover;
		padding-bottom: 320px;
		@media @media-hd {
			padding-bottom: 320px;
		}
	}
	DIV.text {
		position: absolute;
		bottom: 50px;
		width: 1200px;
		left: 50%;
		margin-left: -600px;
		color: @color-white;
		padding-right: 600px;
		padding-left: 80px;
		font-size: 36px;
		line-height: 1.2;
		.box-sizing(border-box);
		H2,
		H1 {
			font-size: 70px;
			font-weight: 300;
		}
		H1 + H2 {
			font-size: 24px;
		}
		* {
			color: @color-white;
		}
	}
	&.banner03b DIV.text {
		&, * {
			color: @color-black;
		}
	}
	&.banner03c DIV.text {
		&, * {
			color: @color-gray-medium;
		}
	}
	&.banner03d DIV.text {
		padding-right: 60px;
		padding-left: 60px;
		bottom: 0;
		top: 30px;
		&, * {
			color: @color01;
		}
		H1 {
			font-weight: bold;
			font-size: 56px;
		}
		P {
			font-size: 30px;
			margin: .5em 0 0 0;
			font-weight: 100;
			&.ico-owl {
				font-size: 40px;
			}
		}
		.ru & {
			H1 {
				font-size: 46px;
			}
			P {
				font-size: 22px;
				&.ico-owl {
					font-size: 30px;
				}
			}
		}
		UL.actions01 {
			margin: 0;
		}
		.col.w25p UL.actions01 {
			margin: 1em 0 0 0;
			LI {
				&,
				A {
					float: none;
					display: block;
				}
			}
		}
	}
	&.banner03e {
		DIV.img {
			background: no-repeat 50% 50%;
			background-size: cover;
			padding-bottom: 260px;
			@media @media-hd {
				padding-bottom: 260px;
			}
			@media @media-mobile {
				display: none;
			}
		}
		DIV.text {
			text-align: center;
			padding-right: 60px;
			padding-left: 60px;
			bottom: 0;
			top: 50px;
			H2 {
				color: @color01;
				font-size: 46px;
			}
			P {
				&, * {
					font-size: 14px;
					color: @color-black;
				}
				SPAN {
					display: block;
					font-weight: 700;
				}
			}
			@media @media-desktop {
				width: 100%;
			}
			@media @media-tablet {
				width: 100%;
				top: 0;
				H2 {
					font-size: 42px;
					margin-top: 20px;
				}
			}
		}
		@media @media-mobile {
			margin-top: 0 !important;
		}
	}
	DIV.a {
		position: relative;
	}
	DIV.b {
		font-size: 20px;
		width: 1200px;
		border-bottom: 1px solid @color-gray-lightest;
		position: static;
		margin: 0 auto;
		padding: 20px !important;
		color: @color-black;
		* {
			color: @color-black;
		}
		& > *:first-child {
			margin: 0;
		}
	}
	@media screen and (min-width: 1199px) and (max-width: 1279px) {
		DIV.text {
			width: 980px;
			bottom: 30px;
			margin-left: -490px;
			padding-right: 490px;
		}

		&.banner03d DIV.text {
			left: 0;
			margin-left: 0;
			padding-right: 20px;
			padding-left: 20px;
			H1 {
				font-size: 36px;
			}
			P {
				font-size: 20px;
			}
			.ru & {
				H1 {
					font-size: 36px;
				}
				P {
					font-size: 20px;
					&.ico-owl {
						font-size: 22px;
					}
				}
			}
		}

		DIV.b {
			width: 980px;
		}
	}
	@media screen and (min-width: 760px) and (max-width: 1199px) {
		DIV.text {
			left: 0;
			width: 50%;
			bottom: 30px;
			margin-left: 0;
			padding-right: 0;
			padding-left: 20px;
			H2 {
				font-size: 56px;
			}
		}

		&.banner03d DIV.text {
			width: 100%;
			padding-right: 20px;
			padding-left: 20px;
			H1 {
				font-size: 36px;
			}
			P {
				font-size: 20px;
				&.ico-owl {
					font-size: 30px;
				}
			}
			A.btn {
				padding: 10px 5px 9px;
				text-align: center;
			}
			.ru & {
				H1 {
					font-size: 36px;
				}
				P {
					font-size: 20px;
					&.ico-owl {
						font-size: 22px;
					}
				}
			}
		}

		DIV.img {
			padding-bottom: 320px;
		}

		DIV.banner03 DIV.b {
			width: auto;
			padding: 20px;
		}
	}
	@media @media-tablet {
		&.banner03d DIV.text {
			.ru & {
				P {
					&.ico-owl {
						font-size: 18px;
					}
				}
			}
		}
	}
	@media @media-mobile {
		DIV.img {
			padding-bottom: 60%;
		}

		DIV.text {
			border-bottom: 1px solid @color-gray-lightest;
			position: static;
			margin-left: 0;
			padding: 20px 15px !important;
			width: auto;
			color: @color-black;
			font-size: 20px;
			* {
				color: @color-black;
			}
			& > *:first-child {
				margin: 0;
			}
			H2 {
				font-size: 36px;
			}

		}

		&.banner03d {
			DIV.img {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				padding-bottom: 0;
			}
			DIV.text {
				position: relative;
				z-index: 2;
				left: 0;
				bottom: 0;
				top: 0;
				H1 {
					font-size: 36px;
				}
				P {
					font-size: 20px;
				}
			}
		}

		DIV.b {
			font-size: 20px;
			width: auto;
			padding: 20px;
		}

		A.btn.color01 {
			color: @color-white;
		}
	}
}

DIV.banner04 {
	margin: 2em 0 0 0;
	position: relative;
	overflow: hidden;
	&:first-child {
		margin: 0;
	}
	> DIV.img {
		background: no-repeat 50% 50%;
		background-size: contain;
		padding-bottom: 575px;
		margin-bottom: 115px;
	}
	DIV#slider03 {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
	@media @media-tablet {
		& > DIV.img {
			padding-bottom: 455px;
			background-size: contain;
		}
	}
	@media @media-mobile {
		& > DIV.img {
			padding-bottom: 215px;
			margin-bottom: 140px;
			background-size: auto 100%;
		}
	}
}

DIV.banner05 {
	&.banner05b {
		height: 345px;
		@media @media-notdesktop {
			height: auto;
		}
	}
	SECTION + & {
		margin: 40px 0 0;
	}
	position: relative;
	height: 480px;
	DIV.img {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: no-repeat 100% 50%;
		background-size: cover;
	}
	.col00 {
		position: relative;
		overflow: hidden;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
		height: 100%;
	}
	.clear {
	}
	.col.w50p {
		font-size: 18px;
	}
	H1 {
		font-size: 60px;
		font-weight: bold;
		text-transform: uppercase;
		color: @color01;
		margin: 1em 0 0 0;
	}
	H2 {
		font-size: 40px !important;
		color: @color-black;
	}
	P.path,
	P#path01 {
		color: @color-gray-medium;
		position: absolute;
		top: 0;
		left: 20px;
		margin: 1.5em 0 0 0;
		@media @media-wide {
			left: 0;
		}
		A {
			color: @color-gray-medium;
		}
		SPAN {
			font-weight: bold;
			color: @color-black;
		}
	}
	@media @media-notdesktop {
		padding-top: @gridunit;
		text-align: center;
		height: auto;
		P.path,
		P#path01 {
			display: none;
		}

		DIV.img {
			position: static;
			margin: 2em 0 0 0;
			IMG {
				max-width: 100%;
			}
		}

		.col00 {
			padding-bottom: 0;
		}

		.col.w50p {
			width: 100% !important;
			float: none;
			margin: 20px 0;
			H1 {
				margin: 0 !important;
			}
		}

		H2 {
			color: @color-black;
			+ P {
				margin-top: 1em;
			}
		}

		IMG.img01 {
			max-width: 160px !important;
			height: auto;
		}
	}
	@media @media-mobile {
		.col.w50p {
			font-size: 13px;
		}

		H1 {
			font-size: 30px;
		}

		H2 {
			font-size: 22px !important;
			color: @color-black;
		}
	}
}

DIV.banner06 {
	height: 240px;
	background-size: cover;
	background-position: 2% 55%;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}

DIV.banner07 {
	padding: 240px 30px;
	background: no-repeat 50% 50%;
	background-size: cover;
	text-align: left;
	max-width: 2000px;
	margin: 0 auto;
	@media @media-tablet {
		padding: 160px 30px;
	}
	@media @media-mobile {
		padding: 55% 30px 0;
	}
}

DIV#slider03 {
	position: relative;
	height: 115px;
	background: @color-gray-lightest;
	border-bottom: 1px solid @color-gray-light;
	.box-sizing(border-box);
	A.prev,
	A.next {
		position: absolute;
		z-index: 99;
		display: block;
		top: -200px;
		height: 39px;
		width: 27px;
		text-indent: -10000px;
		background-size: 100% auto !important;
	}
	A.prev {
		background: url(arr02.png) no-repeat;
		left: 20px;
	}
	A.next {
		background: url(arr02b.png) no-repeat;
		right: 20px;
	}
	&.nopaging {
		A.prev,
		A.next {
			display: none;
		}
		UL {
			margin: 0 auto;
		}
	}
	.wrapper {
		height: 115px;
		width: 300px;
		margin: auto;
		&.wide {
			width: 450px;
			UL LI {
				width: 450px;
				SPAN.img {
					width: 420px;
					height: 350px;
					SPAN.btn {
						bottom: 40%;
						SPAN.a {
							height: 350px;
							width: 420px;
							bottom: -100px;
							left: -130px;
						}
					}
				}

			}
		}
		&.wrapper02 {
			width: 980px;
			UL LI {
				width: 100%;
				.box-sizing(border-box);
				SPAN.img {
					width: 90%;
					SPAN.btn {
						SPAN.a {
							width: 0;
						}
					}
				}
			}
			@media @media-wide {
				width: 90%;
			}
			@media @media-tablet {
				width: 100%;
			}
			@media @media-mobile {
				width: 100%;
			}
		}
	}
	UL {
		padding: 0;
		margin: 0;
		list-style: none;
		width: 10000px;
		position: relative;
		LI {
			padding: 0;
			margin: 0;
			width: 300px;
			float: left;
			position: relative;
			&:before {
				display: none;
			}

			A {
				display: block;
				text-align: center;
				&:hover {
					text-decoration: none;
					SPAN.img SPAN.btn {
						display: block;
					}
				}
				-webkit-user-drag: none;
				-webkit-touch-callout: none;
				.user-select(none);

				&.active {
					SPAN.img {
						bottom: 90px;
						z-index: 3;
						background-position: 50% 100%;
						-webkit-filter: grayscale(0%);
						-moz-filter: grayscale(0%);
						-ms-filter: grayscale(0);
						-o-filter: grayscale(0);
						filter: grayscale(0);
						filter: none;
						SPAN.btn {
							display: none !important;
						}
					}
					SPAN.txt {
						background: @color-white;
						border-color: @color-gray-light @color-gray-light @color-white;
						SPAN {
							&.heading01 {
								margin: 2em 0 0 0;
								color: @color-gray-darkest;
							}
							&.price01 {
								color: @color02;
							}
						}
					}
				}
			}
			SPAN.img {
				display: block;
				z-index: 1;
				position: absolute;
				bottom: 50px;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 280px;
				overflow: hidden;
				height: 470px;
				.transition(all, 500ms);
				background: no-repeat 50% 100%;
				background-size: contain;
				-webkit-filter: grayscale(100%);
				-moz-filter: grayscale(100%);
				-ms-filter: grayscale(100%);
				-o-filter: grayscale(100%);
				filter: grayscale(100%);
				filter: gray;

				SPAN.btn {
					cursor: pointer;
					color: @color-white;
					.box-sizing(border-box);
					padding: 10px;
					font-weight: bold;
					font-size: 15px;
					overflow: hidden;
					position: absolute;
					bottom: 300px;
					display: none;
					width: 160px;
					left: 0;
					right: 0;
					margin: 0 auto;
					SPAN.a {
						position: absolute;
						left: -60px;
						bottom: -300px;
						height: 470px;
						width: 280px;
						background: no-repeat 50% 100%;
						background-size: contain;
						-webkit-filter: blur(10px);
						&:before {
							content: "";
							display: block;
							height: 100%;
							width: 100%;
							background: fade(@color-white, 30%);
						}
					}
					SPAN.b {
						position: relative;
						font-size: 15px;
						margin-bottom: 0;
					}
				}
				@media @media-tablet {
					height: 360px;
					SPAN.btn {
						bottom: 150px;
						SPAN.a {
							height: 360px;
							bottom: -150px;
						}
					}
				}

			}

			SPAN.txt {
				display: block;
				position: relative;
				z-index: 2;
				background: @color-gray-lightest;
				border: 1px solid;
				border-color: @color-gray-lightest @color-gray-lightest @color-gray-light;
				.box-sizing(border-box);
				height: 115px;
				overflow: hidden;
				.transition(all, 500ms);
				SPAN {
					display: block;
					color: @color-gray-medium;
					&.heading01 {
						margin: 1.7em 0 0 0;
						.transition(margin-top, 500ms);
						font-size: 18px;
						font-weight: bold;
						line-height: 1.2;
						font-family: @font-heading;
						.ru & {
							font-family: @font-heading-ru;
						}
					}
					&.price01 {
						margin: 0 0 0 0;
						font-size: 22px;
						line-height: 1;
					}
				}
			}

		}
	}
	@media @media-mobile {
		height: 140px;
		background: @color-white;
		border-bottom: none;
		.wrapper {
			width: 90% !important;
			height: 140px;
			&.wide {
				UL LI {
					width: 80%;
					SPAN.img SPAN.btn {
						bottom: 60%;
						width: 200px !important;
					}
				}
			}
		}

		A.next,
		A.prev {
			top: -100px;
		}

		UL LI {
			width: 80%;
			A.active {
				SPAN.img {
					bottom: 85px;
				}
				SPAN.txt {
					border-bottom: 2px solid @color02;
					SPAN.heading01 {
						margin-top: 2.3em;
					}
				}
			}
			SPAN.txt {
				background: @color-white;
				height: 140px;
				border-left: none;
				border-right: none;
				border-bottom: none;
				SPAN.heading01 {
					font-size: 25px;
					line-height: 1.1;
					max-height: 2.2em;
					overflow: hidden;
					font-family: @font-heading;
					.ru & {
						font-family: @font-heading-ru;
					}
				}
				SPAN.price01 {
					font-size: 20px;
				}
			}
			SPAN.img {
				width: 90% !important;
				height: 225px !important;
				SPAN.btn {
					width: 80% !important;
					SPAN.a {
						width: 125% !important;
						height: 225px !important;
						left: -13% !important;
					}
				}
			}
			SPAN.price01 {
				line-height: 1;
			}
		}
	}
}

DIV.slick-slider01 {
	BUTTON.slick-next,
	BUTTON.slick-prev {
		position: absolute;
		text-indent: -10000px;
		top: 50%;
		width: 95px;
		margin-top: -72px;
		padding: 0;
		border: none;
		background: none;
		z-index: 9;
		outline: none;
		color: fade(@color01, 60%);
		&.slick-disabled {
			display: none !important;
		}
		&:before {
			font-family: icons;
			float: left;
			text-indent: 0;
			font-size: 85px;
		}
		&:hover {
			color: @color01;
		}
	}
	BUTTON.slick-next:before {
		float: right;
	}
	.col00 & {
		overflow: visible;
	}
	/* unvisited link */
	A:link {
		text-decoration: none;
	}
	/* visited link */
	A:visited {
		text-decoration: none;
	}
	/* mouse over link */
	A:hover {
		text-decoration: none;
	}
	/* selected link */
	A:active {
		text-decoration: none;
	}

	BUTTON.slick-next {
		right: 50px;
		&:before {
			content: @ico-arrright02;
		}
		.col00 & {
			right: -50px;
			@media @media-notwide {
				right: 0;
			}
			@media @media-notdesktop {
				right: 0;
			}
		}
	}
	BUTTON.slick-prev {
		left: 50px;
		&:before {
			content: @ico-arrleft02;
		}
		.col00 & {
			left: -50px;
			@media @media-notwide {
				left: 0;
			}
			@media @media-notdesktop {
				left: 0;
			}
		}
	}
	SPAN.heading02 {
		padding-top: 25px;
	}
	SPAN.meta01 {

	}
	LI {
		&:before {
			display: none;
		}
	}
}

DIV.prices {
	DIV.tabcontent01 & {
		margin: 0;
	}
	margin: 40px 0 0 0;
	P.price01,
	P.price02 {
		padding: 0;
		margin: 0;
		line-height: 1.2;
	}
	P.price01 {
		color: @color-black;
		font-size: 34px;
		font-weight: bold;
	}
	P.price02 {
		margin: .5em 0 0 0;
		padding-bottom: 1em;
		color: @color-black;
		font-size: 20px;
		font-weight: bold;
	}
	P.price04 {
		font-weight: bold;
		font-size: 14px;
	}
	P {
		margin: 0;
		SPAN {
			font-weight: normal;
			.campaign01,
			.campaign02 & {
				font-weight: bold;
			}
		}
		& + P.price01 {
			margin: .5em 0 0;
		}
		A.help {
			margin-top: -7px;
		}
	}
}

/*used in panda DIV.prices02*/
/*used in block29 DIV.prices03*/

P.ico-transport {
	padding-left: 75px;
	position: relative;
	margin: 2em 0 0 0;
	&:before {
		width: auto;
		height: auto;
		line-height: 1;
		position: absolute;
		left: 0;
		top: 0;
		font-size: 45px;
		color: @color-gray-medium;
	}
	@media @media-mobile {
		padding-left: 0;
		padding-bottom: 50px;
		&:before {
			display: none;
		}
	}
}

/* Slider */
UL.type03 {
	position: relative;
	z-index: 9;
	LI {
		float: left;
		width: 20%;
		padding: 0 2% 30px !important;
		.box-sizing(border-box);
		background: none;
		position: relative;
		&:before {
			display: none;
		}
		&.ico01 SPAN.ico {
			background-image: url(ico_service01.png);
		}
		&.ico02 SPAN.ico {
			background-image: url(ico_service02.png);
		}
		&.ico03 SPAN.ico {
			background-image: url(ico_service03.png);
		}
		&.ico04 SPAN.ico {
			background-image: url(ico_service04.png);
		}
		&.ico05 SPAN.ico {
			background-image: url(ico_service05.png);
		}
		&.ico06 SPAN.ico {
			background-image: url(ico_service06.png);
		}
		&.ico07 SPAN.ico {
			background-image: url(ico_service07.png);
		}
		&.ico08 SPAN.ico {
			background-image: url(ico_service08.png);
		}
		&.ico09 SPAN.ico {
			background-image: url(ico_service09.png);
		}
		&.level0 {
			SPAN.lvl0 {
				opacity: 0.5;
			}
			.slider:before {
				width: 0;
			}
		}
		&.level1 {
			SPAN.lvl1 {
				opacity: 1;
			}
			.slider:before {
				width: 33.3333%;
			}
			.slider:hover .tooltip.lvl1 {
				width: 240px;
				opacity: 1;
				height: auto;
			}
		}
		&.level2 {
			SPAN.lvl2 {
				opacity: 1;
			}
			.slider:before {
				width: 66.6666%;
			}
			.slider:hover .tooltip.lvl2 {
				width: 240px;
				opacity: 1;
				height: auto;
			}
		}
		&.level3 {
			SPAN.lvl3 {
				opacity: 1;
			}
			.slider:before {
				width: 100%;
				background: @color02;
			}
			.slider:hover .tooltip.lvl3 {
				width: 240px;
				opacity: 1;
				height: auto;
			}
		}
		&:after {
			width: 27.3333%;
			content: "";
			z-index: 0;
			border: 1px @color-gray-medium-light;
			border-style: none solid;
			height: 15px;
			position: absolute;
			bottom: 26px;
			left: 36.3333%;
			.box-sizing(border-box);
		}
		&:before {
			display: block;
			top: auto;
			width: 78%;
			content: "";
			z-index: 0;
			border: 1px @color-gray-medium-light;
			border-style: none solid;
			height: 15px;
			position: absolute;
			bottom: 26px;
			left: 11%;
			.box-sizing(border-box);
		}
	}
	P {
		height: 170px;
		text-align: center;
		font-size: 17px;
		line-height: 20px;
		position: relative;
		SPAN.ico {
			opacity: 0;
			position: absolute;
			display: block;
			left: 0;
			right: 0;
			bottom: 10px;
			margin: auto;
			width: 117px;
			-webkit-transition: all 500ms ease-out;
			-moz-transition: all 500ms ease-out;
			-o-transition: all 500ms ease-out;
			transition: all 500ms ease-out;
			height: 76px;
			background-size: 400% auto;
			background-repeat: repeat-x;
			&.lvl0 {
				background-position: 0 50%;
			}
			&.lvl1 {
				background-position: -100% 50%;
			}
			&.lvl2 {
				background-position: -200% 50%;
			}
			&.lvl3 {
				background-position: -300% 50%;
			}
		}
		STRONG {
			display: block;
			font-weight: bold;
			color: @color02;
		}
	}
	.slider {
		position: relative;
		background: @color-gray-lightest;
		border-radius: 3px;
		height: 7px;
		z-index: 1;
		-webkit-box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		.ui-slider-handle {
			cursor: e-resize;
			z-index: 3;
			width: 27px;
			height: 27px;
			background: url(ico_slider.png) no-repeat 50% 50%;
			display: block;
			margin: -10px 0 0 -13px;
			position: absolute;
			border: none;
			top: 0;
		}
		&:before {
			content: "";
			position: absolute;
			left: 0;
			width: 0;
			height: 7px;
			border-radius: 3px;
			background: @color-gray-medium;
			-webkit-box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
			box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		}
		.tooltip {
			opacity: 0;
			position: absolute;
			overflow: hidden;
			top: 100%;
			left: 0;
			height: 0;
			margin-top: 0;
			color: @color-white;
			width: 240px;
			-webkit-transition: opacity 500ms ease-out, margin-top 200ms ease-out, width 200ms ease-out;
			-moz-transition: opacity 500ms ease-out, margin-top 200ms ease-out, width 200ms ease-out;
			-o-transition: opacity 500ms ease-out, margin-top 200ms ease-out, width 200ms ease-out;
			transition: opacity 500ms ease-out, margin-top 200ms ease-out, width 200ms ease-out;
			.pad {
				padding: 20px;
				width: 240px;
				background: @color-black;
				color: @color-white;
				.box-sizing(border-box);
				-webkit-transition: margin-top 200ms ease-out;
				-moz-transition: margin-top 200ms ease-out;
				-o-transition: margin-top 200ms ease-out;
				transition: margin-top 200ms ease-out;
			}
		}
	}
}

/*RF*/
UL.type04 {
	background: none;
	padding: 0;
	margin: 0;
	LI {
		width: auto;
		margin-right: 20px;
		&.icon {
			&__color {
				&--blue {
					&:before {
						color: @color-blue02;
					}
				}
			}
		}
	}
	P {
		&,
		STRONG {
			font-size: @base-font-size;
		}
		&.mt0 {
			margin-top: 0!important;
		}
	}
	/*into global*/
	&.type04b {
		background: none;
		.display(flex);
		.flex-wrap(wrap);
		.flex-direction(row);
		.meter {
			float: left;
			margin: 0 auto 0;
		}
		&.in2cols {
			.clearfix();
			& > LI {
				width: 48%;
				float: left;
				margin: 3% 2% 0 0;
				.box-sizing(border-box);
				&:nth-of-type(3n+1) {
					clear: none;
				}
				&:nth-of-type(2n+1) {
					clear: both;
				}
			}
		}

		[class^="icon-"], [class*=" icon-"] {
			padding: 0 0 0 50px;
			position: relative;
			margin: 20px 0 20px 0;
			.box-sizing(border-box);
		}
		[class^="icon-"]:before, [class*=" icon-"]:before {
			line-height: 24px;
			font-size: 24px;
			left: 10px;
			top: 0;
			display: inline-block;
			color: @color-gray13;

		}
		LI {
			&:before {
				display: none;
			}
			P {
				margin-top: 1em;
				margin-left: 50px;
				float: none;
				text-align: left;
				font-family: @font-main;
				color: @color-gray-medium;
				STRONG {
					display: block;
					color: @color-black;
				}
				SPAN {
					display: inline-block;
				}
			}
			padding: 0;
			width: 28%;
			.flex(0 0 auto);
		}
		@media @media-tablet {
			width: 100%;
			&.in2cols {
				& > LI {
					margin: 3% 2% 0 0;
					width: 100%;
				}
			}

			LI {
				width: 44%;
			}
		}
		@media @media-tablet-narrow {
			LI {
				width: 100%;
			}
		}
		@media @media-notwide {
			LI {
				width: 44%;
			}
		}
		@media @media-mobile {
			display: flex;
			width: 100%;
			&.in2cols {
				& > LI {
					margin: 0 2% 0 0;
					width: 100%;
				}
			}

			LI {
				width: 100%;
				padding: 15px 0 0 0;
				margin: 0;
				.meter {
					float: none;
					margin: 0;
				}
				P {
					float: none;
					text-align: center;
					margin-left: 0;
				}
			}
		}
	}
	&.type04d {
		@media @media-mobile {

			background: none;
			padding: 0;
			margin: 0;
			LI {
				width: 50% !important;
				margin: 0 !important;
				float: left;
				padding: 0;
				background: none;
				text-align: center;
			}

			LI,
			LI:first-child:nth-last-child(4),
			LI:first-child:nth-last-child(4) ~ LI {
				width: auto;
				margin-right: 20px;
			}

			P {
				&,
				STRONG {
					font-size: @base-font-size;
				}
			}

			&.type04b {
				.meter {
					float: none;
				}
				P {
					float: none;
					text-align: center;
					margin-left: 0;
				}
			}
		}

	}
	/*type04e goes with type04b cuz of flex*/
	&.type04e {
		LI {
			width: 25%;
			color: @color-gray-medium;

			&:before {
				display: block;
			}
			P {
				margin: 0;
				STRONG {
					color: @color17;
				}
			}
			&.ico {
				padding: 0 0 0 50px;
				position: relative;
				margin: 20px 0 20px 0;
				.box-sizing(border-box);
				&:before {
					position: absolute;
					left: 10px;
					top: 4px;
					height: 24px;
					width: 30px;
					font-size: 24px;
					color: @color-gray05;
				}
				&.showtooltip {
					cursor: pointer;
					&:hover {
						color: @color03;
					}
				}
			}
			&.icon {
				&__color {
					&--blue {
						&:before {
							color: @color-blue02;
						}
					}
				}
			}
		}
		&.margneg {
			margin: 0 0 0 -10px;
		}
		@media @media-notdesktop {
			display: flex;
			width: 100%;
			.flex-direction(row);
			LI {
				width: 50%;
				padding: 15px 0 0 0;
				margin: 0;
				.meter {
					float: none;
					margin: 0;
				}
				P {
					float: none;
					text-align: left;
					margin-left: 0;
				}
			}
		}
	}
	.meter {
		width: 40px;
		height: 40px;
		.a {
			width: 40px;
			height: 40px;
			clip: rect(0, 20px, 40px, 0);
		}
		.circle {
			width: 40px;
			height: 40px;
			&.left {
				clip: rect(0, 20px, 40px, 0);
			}
			&.right {
				clip: rect(0, 40px, 40px, 20px);
			}
		}
		.b {
			width: 40px;
			height: 40px;
		}
		&.type04 {
			.b {
				height: 29px;
				top: 5px;
			}
		}
		&.type05 {
			.b {
				height: 30px;
				top: 3px;
			}
		}
		&:before {
			background-size: 100% 100% !important;
		}
	}
	background: none;
	padding: 0;
	margin: 0;
	LI {
		width: auto;
		margin-right: 20px;
		&.icon {
			&__color {
				&--blue {
					&:before {
						color: @color-blue02;
					}
				}
			}
		}
	}
	P {
		&,
		STRONG {
			font-size: @base-font-size;
		}
		&.mt0 {
			margin-top: 0 !important;
		}
	}
}

UL.parameters {
	background: none;
	padding: 0;
	margin: 0;
	.display(flex);
	.flex-wrap(wrap);
	.flex-direction(row);
	.meter {
		float: left;
		margin: 0 auto 0;
	}
	LI {
		width: auto;
		margin-right: 20px;
	}
	&.in2cols {
		.clearfix();
		& > LI {
			width: 48%;
			float: left;
			margin: 3% 2% 0 0;
			.box-sizing(border-box);
			&:nth-of-type(3n+1) {
				clear: none;
			}
			&:nth-of-type(2n+1) {
				clear: both;
			}
		}
	}
	LI {
		&:before {
			display: block;
		}
		P {
			margin: 0;
			float: none;
			text-align: left;
			color: @color-gray01;
			font-size: 15px;
			STRONG {
				font-size: 15px;
				display: block;
				color: @color17;
			}
			SPAN {
				display: inline-block;
			}
		}
		&.ico {
			padding: 0 0 0 50px;
			position: relative;
			margin: 0 0 20px 0;
			.box-sizing(border-box);
			&:before {
				position: absolute;
				left: 10px;
				top: 0;
				height: 24px;
				width: 30px;
				font-size: 24px;
				color: @color-gray-medium-light;
			}
			&.showtooltip {
				cursor: pointer;
				&:hover {
					color: @color03;
				}
			}
		}
		padding: 0;
		.flex(0 0 auto);
		width: 33.3333%;

	}
	&--blue {
		LI.ico:before {
			color: @color-blue02;
		}
	}
	.meter {
		width: 40px;
		height: 40px;
		.a {
			width: 40px;
			height: 40px;
			clip: rect(0, 20px, 40px, 0);
		}
		.circle {
			width: 40px;
			height: 40px;
			&.left {
				clip: rect(0, 20px, 40px, 0);
			}
			&.right {
				clip: rect(0, 40px, 40px, 20px);
			}
		}
		.b {
			width: 40px;
			height: 40px;
		}
		&.type04 {
			.b {
				height: 29px;
				top: 5px;
			}
		}
		&.type05 {
			.b {
				height: 30px;
				top: 3px;
			}
		}
		&:before {
			background-size: 100% 100% !important;
		}
	}
	&.margneg {
		margin: 0 0 0 -10px;
	}
	@media @media-notdesktop {
		width: 100%;
		.flex-direction(row);
		LI {
			width: 50%;
			padding: 15px 0 0 0;
			margin: 0;
			.meter {
				float: none;
				margin: 0;
			}
			P {
				float: none;
				text-align: left;
				margin-left: 0;
			}
		}
	}
}

DIV.items01 A {
	text-decoration: underline;
	&:hover {
		color: @color02;
	}
}

DIV.item01 {
	border: 1px solid @color-gray-light;
	margin: 1em 0 0 0;
	overflow: hidden;
	.sim-nums {
		.display(flex);
		.flex-wrap(wrap);
		.box-sizing(border-box);
		P {
			color: @color-text-main;
			margin-right: 25px;
			SPAN.span01 {
				margin-right: 10px;
				color: @color-gray-medium;
			}
			SPAN.span02 {

			}
		}
		&.desktop-hide {
			.display(none);
		}
	}
	.block01 & {
		border-style: none none dashed none;
		border-width: 2px;
		&:last-child {
			border-bottom: none;
		}
		& > .a {
			padding: 20px 0;
		}
		&.item01m {
			.a {
				padding: 20px 0;
			}
		}
	}
	.items09 .col & {
		.flex(1 0 auto);
		.col-b {
			margin-top: -.4em;
			+ .col-c {
				padding: 0 0 0 170px !important;
				@media @media-mobile {
					padding: 0 !important;
				}
			}
		}
		.col-c {
			UL.type04 {
				LI {
					width: 41% !important;
					@media @media-mobile {
						width: 50% !important;
					}
				}
				&.type04c {
					LI {
						float: none;
						width: auto !important;
						@media @media-mobile {
							P {
								margin-left: 10px !important;
							}
						}
					}
				}
			}
		}
		&.size02 {
			.col-b {
				width: 90px;
				margin-left: 20px;
				& + .col-c {
					padding: 0 110px 0 170px !important
				}
			}
			@media @media-mobile {
				&.size02 {
					.col-b {
						width: 100%;
						margin: 0;
						& + .col-c {
							padding: 0 !important;
						}
					}
				}
			}
		}
	}
	& > .a {
		padding: 20px 10px;
		overflow: hidden;
		position: relative;
		.col {
			.pad {
				padding: 0 20px;
			}
		}
		UL.type04 {
			background: none;
			padding: 0;
			margin: 0;
			LI,
			LI:first-child:nth-last-child(4),
			LI:first-child:nth-last-child(4) ~ LI {
				width: auto;
				margin-right: 20px;
			}
			P {
				&,
				STRONG {
					font-size: @base-font-size;
				}
			}
			/*&.type04b {
				.meter { float: left; }
				P { float: left; text-align: left; margin-left: 10px; }
				@media @media-mobile {
					.meter { float: none; }
					P { float: none; text-align: center; margin-left: 0; }`
				}
			}
			&.type04c {
				.meter { float: left; }
				P { float: left; text-align: left; margin-left: 10px; }
				@media @media-mobile {
					LI {
						float: none;
						width: auto!important;
						.meter { float: left; }
						P { float: left; text-align: left; margin-left: 10px; }
					}
				}
			}*/
		}
		.meter {
			&.type01:before {
				background: url(bg115.png) no-repeat;
			}
			&.type02:before {
				background: url(bg116.png) no-repeat;
			}
			&.type03:before {
				background: url(bg117.png) no-repeat;
			}
			&.type04:before {
				background: url(bg118.png) no-repeat;
			}
			&.type05:before {
				background: url(bg119.png) no-repeat;
			}
			&.type06:before {
				background: url(bg120.png) no-repeat;
			}
			&.type13:before {
				background: url(bg126.png) no-repeat;
			}

			width: 40px;
			height: 40px;
			.a {
				width: 40px;
				height: 40px;
				clip: rect(0, 20px, 40px, 0);
			}
			.circle {
				width: 40px;
				height: 40px;
				&.left {
					clip: rect(0, 20px, 40px, 0);
				}
				&.right {
					clip: rect(0, 40px, 40px, 20px);
				}
			}
			.b {
				width: 40px;
				height: 40px;
				.type04& {
					height: 42px;
					top: 8px;
				}
				.type05& {
					height: 38px;
					top: 10px;
				}
			}
			&:before {
				background-size: 100% 100% !important;
			}
		}
	}
	& > .b {
		padding: 0 20px 20px 20px;
		&.stripetop {
			border-top: 1px solid @color-gray-lightest;
			padding-top: 20px;
			.clearfix();
			margin: 0 20px;
		}
		H2 {
			color: @color02;
			font-size: 30px;
			font-weight: 300;
		}
		H3 {
			margin: 1em 0 0;
			font-size: 18px;
			color: @color-black;
		}
		DIV.txt H3 {
			font-size: 28px;
			font-weight: normal;
			margin: 0;
			line-height: 1 !important;
			color: @color02;
		}
		.meter {
			&.type01:before {
				background: url(bg115_alt.png) no-repeat;
			}
			&.type02:before {
				background: url(bg116_alt.png) no-repeat;
			}
			&.type03:before {
				background: url(bg117_alt.png) no-repeat;
			}
			&.type04:before {
				background: url(bg118_alt.png) no-repeat;
			}
			&.type05:before {
				background: url(bg119_alt.png) no-repeat;
			}
			&.type06:before {
				background: url(bg120_alt.png) no-repeat;
			}
			&.type13:before {
				background: url(bg126_alt.png) no-repeat;
			}
		}
	}
	& > .b,
	A.toggler SPAN.b {
		display: none;
	}
	A.btn {
		text-decoration: none;
	}
	A.toggler {
		DIV.block01 & {
			float: none !important;
			color: @color01;
			text-decoration: underline;
			&:before {
				display: none;
			}
			&:hover {
				color: @color02;
				outline: none;
			}
		}
		&:focus,
		&:active {
			outline: none;
		}
	}
	.badge02 {
		margin-top: 20px;
	}
	H2 {
		font-size: 20px;
		font-weight: bold;
		margin: 0;
		line-height: 1 !important;
		color: @color-black;
		word-wrap: break-word;
		SPAN {
			font-size: 60%;
			font-weight: bold;
		}
		A {
			color: @color-black;
			&:hover {
				color: @color02;
				text-decoration: none;
			}
		}
	}
	H3 {
		font-size: 28px;
		font-weight: normal;
		margin: 0;
		line-height: 1 !important;
		color: @color02;
	}
	H4 {
		font-size: 22px;
		font-weight: normal;
		margin: 1em 0 0;
		line-height: 1 !important;
		color: @color02;
		&:first-child {
			margin: 0;
		}

	}
	P {
		margin: 1em 0 0 0;
		&.lead {
			font-weight: bold;
		}
		&.meta {
			font-size: 17px;
			color: @color-gray-medium;
			margin: .2em 0 0 0;
			SPAN.hilite {
				color: @color02;
				font-weight: bold;
			}
		}
	}
	.col P:first-child {
		margin: 0;
	}
	&.open {
		A.toggler {
			SPAN.a {
				display: none;
			}
			SPAN.b {
				display: block;
			}
		}
		& > .a {
			padding: 20px 10px 0;
		}
		.b {
			display: block;
		}
	}
	&.item01b {
		border: 2px solid @color01;
		& > .a {
			padding: 29px 29px 39px;
		}
		& > .b {
			padding: 0 29px 29px 29px;
			&.stripetop {
				padding: 30px;
				margin: 0 29px;
			}
		}
		.col-a P {
			&.heading {
				display: block;
			}
			+ P {
				margin: .5em 0 0;
			}
			IMG {
				display: none;
				&.hilite {
					display: block;
				}
				&:first-child:last-child {
					display: block;
				}
			}
		}
	}
	&.item01c {
		background: lighten(@color-gray-lightest, 5%);
		* {
			color: @color-gray-medium !important;
		}
		.btn {
			&.main {
				color: @color01 !important;
			}
			&.green {
				color: @color-white !important;
			}
			&.discrete {
				color: @color01 !important;
			}
		}
		.col-c P.meta,
		.col-b P.subheading {
			color: @color-gray-medium;
		}
		> .a .meter {
			.circle {
				background: @color-gray-medium;
			}
			.b .fill {
				background: @color-gray-medium;
			}
			&.type01:before {
				background: url(bg115_alt.png) no-repeat;
			}
			&.type02:before {
				background: url(bg116_alt.png) no-repeat;
			}
			&.type03:before {
				background: url(bg117_alt.png) no-repeat;
			}
			&.type04:before {
				background: url(bg118_alt.png) no-repeat;
			}
			&.type05:before {
				background: url(bg119_alt.png) no-repeat;
			}
			&.type06:before {
				background: url(bg120_alt.png) no-repeat;
			}
			&.type07:before {
				background-image: url(bg121_active.png);
			}
			&.type08:before {
				background-image: url(bg122_active.png);
			}
			&.type10:before {
				background-image: url(bg123_active.png);
			}
			&.type11:before {
				background-image: url(bg124_active.png);
			}
			&.type12:before {
				background-image: url(bg125_active.png);
			}
			&.type13:before {
				background: url(bg126_alt.png) no-repeat;
			}

		}

	}
	&.item01d {
		border: 2px solid @color02;
		& > .a {
			padding: 29px 29px;
			.open& {
				padding: 29px 29px 0;
			}
		}
		& > .b {
			padding: 0 29px 29px 29px;
		}
		DIV.col-a P IMG {
			display: none;
			&.hilite {
				display: block;
				margin: auto;
			}
			&:first-child:last-child {
				display: block;
				margin: auto;
			}
		}
	}
	&.item01e {
		border: 2px solid @color-gray-medium-light;
		background: lighten(@color-gray-lightest, 5%);
		& > .a {
			padding: 29px 29px;
		}
		& > .b {
			padding: 0 29px 29px 29px;
		}
		DIV.col-a P.heading {
			display: block;
		}
	}
	&.item01f {
		& > .a {
			.col-a {
				width: 320px;
				font-weight: normal;
				font-size: 16px;
				UL {
					width: 100%;
					margin: 0;
					padding: 0;
					LI {
						list-style: none;
						float: left;
						margin: 0;
						padding: 0 10px;
						.box-sizing(border-box);
						width: 33.333%;
						&:before {
							display: none;
						}
					}
				}
			}
			.col-b + .col-c {
				padding: 0 230px 0 365px;
			}
		}
	}
	&.item01g {
	}
	&.item01h {
		.col-c {
			A.toggler {
				display: none;
			}
		}
	}
	&.item01i {
		margin: 0;
		border: 0;
		.clearfix();
		overflow: visible;
		& > .a {
			padding: 20px 0 20px 0;
			overflow: visible; /*padding-left: 0; padding-bottom: 0; padding-right: 0;*/
			dl {
				.clear();
				width: 100%;
			}
			dt {
				float: left;
				padding: 0;
				margin: 0;
				clear: none;
				margin-right: 10px;
				width: auto;
				color: @color-gray-medium;
			}
			dd {
				float: left;
				padding: 0;
				width: auto;
				margin: 0;
				clear: none;
				margin-right: 30px;
			}
			.col-c {
				/*padding: 0!important;*/
				padding: 0 340px 0 0 !important;
				P.heading01 {
					font-size: 28px;
					font-family: @font-heading;
					font-weight: bold;
					color: @color-black;
					line-height: normal;
					margin: 0;
					padding: 0;
					SPAN {
						color: @color-gray-medium;
						font-weight: normal;
						display: inline-block;
						padding: 0 10px;
					}
				}
				P.meta {
					display: inline-block;
					&:last-child {
						padding-left: 5px;
					}
				}
				.blinking-cursor {
					-webkit-animation: 1s blink step-end infinite;
					-moz-animation: 1s blink step-end infinite;
					-ms-animation: 1s blink step-end infinite;
					-o-animation: 1s blink step-end infinite;
					animation: 1s blink step-end infinite;
				}

			}
			.col-b {
				/*width: 311px;*/
				width: 320px;
				P {
					/*color: @color-gray-medium;*/
					&.heading01 {
						font-size: 28px;
						font-family: @font-heading;
						font-weight: bold;
						color: @color-black;
						line-height: normal;
						margin: 0;
						padding: 0;
						SPAN {
							color: @color-gray-medium;
							font-weight: normal;
							display: inline-block;
						}
					}
					&.price01 {
						line-height: 25px;
						.span01 {
							color: @color-gray-medium;
							font-size: 14px;
							vertical-align: top;
							line-height: 16px;
							font-weight: 400;
						}
						.span02 {
							font-size: 25px;
							color: @color-black;
							display: block;
						}
					}
				}
			}
		}
		@media @media-tablet {
			& > .a {
				.col-c {
					padding: 0 270px 0 0 !important;
				}
				.col-b {
					width: 250px;
				}
			}
		}
		@media @media-mobile {
			.a {
				border-bottom: 0;
				padding: 15px 0;
				/*dt { clear: both }*/
				.col-b {
					width: 100%;
					P {
						&.heading01 {
							float: none;
							SPAN {
								padding: 0;
							}
						}
						/*display: inline-block;
						text-align: left;
						float: left;*/
						/*padding-right: 20px;*/
						/*margin: 0.7em 0 0 0;*/
						&.price01 {
							line-height: 25px;
							.span01 {
								vertical-align: bottom;
							}
							.span02 {
								display: inline;
							}
						}
						&.orange {
							padding-bottom: 1em;
						}
					}
				}
			}
		}
	}
	&.item01j {
		margin: 0;
		.block01 & {
			&:last-child {
				border-bottom: 2px dashed @color-gray-light;
			}
		}
		DIV.a {
			padding: 20px 0;
			/*LABEL {
				SPAN,DEL {
					font-size: 20px;
					font-weight: bold;
					text-align: right;
				}
				DEL {
					color: @color-gray-light;
					margin: 30px 0 0 0;

				}
			}*/
			.price01 {
				margin: 1.5em 0 0 0;
				line-height: 1.4;
				font-size: 20px;
				DEL {
					color: @color-gray-light;

				}
			}
			H2 {
				margin-top: 1em !important;
			}
			UL.actions01 {
				float: right;
				padding: 0;
				margin: 20px 0 0 0;
				LI {
					margin: 0;
					text-align: center;
					A.btn {
						padding: 7px 30px;
					}
				}
			}
			@media @media-mobile {
				UL.actions01 {
					display: block;
					width: 100%;
				}

				LABEL SPAN {
					text-align: left;
				}
			}
		}
	}
	&.item01k {
		.f-left {
			float: left !important;
		}
		.col-b + .col-c {
			padding: 0 340px 0 140px;
		}
		.col-a {
			width: 140px;
			margin: 0 10px 0 0;
		}
		.col-b {
			width: 320px;
		}
		.col-c {
			.details {
				padding-right: 0;
			}
		}
	}
	&.item01l {
		.col-a {
			DIV.img {
				width: 130px;
			}
			margin: 0 15px 0 15px;
		}
		&.active {
			border: 2px solid @color01;
		}
	}
	&.item01m {
		.col-a {
			DIV.img {
				float: none;
				margin: 0 auto;
				text-align: center;
				max-width: 119px;
				@media @media-tablet {
					max-width: 60%;
				}
			}
		}
		.col-b + .col-c {
			padding: 0 330px 0 170px;
		}
		.col-c {
			.details {
				H2,
				P.meta {
					display: block;
				}
				P.note {
					margin-top: 0.5em;
				}
			}
			UL.type04 {
				LI {
					width: 41% !important;
					@media @media-mobile {
						width: 50% !important;
					}
				}
				&.type04c {
					LI {
						float: none;
						width: auto !important;
						margin-top: 0;
						@media @media-mobile {
							P {
								margin-left: 10px !important;
							}
						}
					}
				}
			}
		}
		.col-b {
			width: 210px;
		}
		.text { /*display: none;*/
			padding-bottom: 10px;
		}
		.open {
			& > .a {
				padding: 20px 0;
			}
			.text {
				display: block;
			}
		}
		DIV.a {
			padding: 20px 20px;
		}
	}
	&.item01n {
		background: lighten(@color-gray-lightest, 5%);
		border: none;
		> .a .meter {
			.circle {
				background: @color-gray-medium;
			}
			.b .fill {
				background: @color-gray-medium;
			}
			&.type01:before {
				background: url(bg115_alt.png) no-repeat;
			}
			&.type02:before {
				background: url(bg116_alt.png) no-repeat;
			}
			&.type03:before {
				background: url(bg117_alt.png) no-repeat;
			}
			&.type04:before {
				background: url(bg118_alt.png) no-repeat;
			}
			&.type05:before {
				background: url(bg119_alt.png) no-repeat;
			}
			&.type06:before {
				background: url(bg120_alt.png) no-repeat;
			}
			&.type07:before {
				background-image: url(bg121_active.png);
			}
			&.type08:before {
				background-image: url(bg122_active.png);
			}
			&.type10:before {
				background-image: url(bg123_active.png);
			}
			&.type11:before {
				background-image: url(bg124_active.png);
			}
			&.type12:before {
				background-image: url(bg125_active.png);
			}
			&.type13:before {
				background: url(bg126_alt.png) no-repeat;
			}

		}
		&.open {
			& > .a {
				padding: 20px 20px;
			}
			.txt {
				display: block;
			}
		}
	}
	&.size01 {
		.col-b {
			width: 280px;
			& + .col-c {
				padding: 0 300px 0 210px
			}
		}
	}
	/*&.size02 {
		.col-b {
			width: 90px;
			margin-left: 20px;
			& + .col-c { padding: 0 110px 0 170px!important;}

		}
		@media @media-mobile {
				&.size02 {
					.col-b {
						width: 100%;
						margin: 0;
						& + .col-c { padding: 0!important;}
					}
				}
			}
	}*/
	.col-a {
		float: left;
		width: 155px;
		text-align: center;
		font-size: 18px;
		margin: 0 15px 0 0;
		font-weight: bold;
		color: @color-black;
		.clearfix();
		P {
			margin: 0;
			&.heading {
				margin: .4em 0 0 0;
				font-size: 16px;
				display: none;
				position: absolute;
				bottom: 10px;
				left: 10px;
				text-indent: -10000px;
				background: url(recommend_et.png) no-repeat 0 50%;
				width: 113px;
				height: 24px;
			}
			&.note {
				position: absolute;
				top: -10px;
				left: 8px;
				font-weight: normal;
			}
			IMG.hilite {
				display: none;
			}
		}
		DIV.img {
			width: 119px;
			float: none;
			margin: 0 auto;
			max-width: 119px;
			IMG {
				float: none;
			}
		}
		DIV.txt {
			display: none;
		}
	}
	UL.type04 {
		background: lighten(@color-gray-lightest, 5%);
		padding: 20px;
		LI {
			width: 18%;
			float: left;
			padding: 0;
			margin: 0 1%;
			background: none;
			text-align: center;
			&:before {
				display: none;
			}
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ LI {
				width: 23%;
			}
		}
		P {
			font-size: 15px;
			color: @color-gray-medium;
			STRONG {
				display: block;
				font-size: 17px;
				color: @color-black;
				line-height: 1;
			}
		}
	}
	.w50p & {
		H2 {
			font-size: 24px;
		}
		.col-a {
			width: 100px;
		}
		.col-c {
			padding-left: 130px;
		}
	}
	DIV.img {
		width: 255px;
		float: left;
		IMG {
			max-width: 100%;
			height: auto;
			float: left;
			+ DIV.txt {
				padding-left: 290px;
			}
		}
	}
	.col-b {
		float: right;
		width: 210px;
		P.heading,
		P.subheading {
			display: none;
		}
		P.price01 {
			color: @color-black;
			font-size: 24px;
			font-weight: bold;
			margin: 0;
			line-height: 28px;
			&.price01b {
				border-bottom: 2px solid @color-gray-medium-light;
				padding-bottom: .2em;
				+ P.meta01 {
					margin: .3em 0 0 0;
				}
			}
			SMALL {
				display: block;
				font-size: 17px;
			}
		}
		P {
			SPAN.hilite {
				color: @color02;
				display: block;
				font-size: 20px;
				font-weight: bold;
			}
		}
		UL.actions01 {
			margin: 1em 0 0 0 !important;
			LI {
				float: none;
				.clearfix();
			}
			.btn {
				float: left;
				width: auto;
				display: block;
				.box-sizing(border-box);
				text-align: center;
				&.nofloat {
					float: none;
				}
			}
		}
	}
	.col-c {
		padding: 0 0 0 170px;
		P.price01 {
			color: @color-black;
			font-size: 24px;
			font-weight: bold;
			margin: .5em 0 0 0;
			line-height: 28px;
			&.price01b {
				border-bottom: 2px solid @color-gray-medium-light;
				padding-bottom: .2em;
				+ P.meta01 {
					margin: .3em 0 0 0;
				}
			}
			SMALL {
				display: block;
				font-size: 17px;
			}
		}
		DIV.details {
			A.toggle {
				display: none;
			}
		}
	}
	.col-b + .col-c {
		padding: 0 230px 0 170px;
	}
	&.noico .col-c {
		padding: 0 230px 0 10px !important;
	}
	@media @media-tablet {
		&.item01f {
			& > .a {
				.col-c {
					clear: both;
					padding: 10px 15px !important;
				}
			}
		}

		&.item01k {
			.col-b + .col-c {
				padding: 0 260px 0 150px;
			}
			.col-a {
				width: 130px;
			}
			.col-b {
				width: 250px;
			}
			.col-c {

			}
		}
	}
	@media @media-mobile {
		&.noico .col-c {
			padding: 0 0 0 10px !important;
		}

		& > .a {
			padding: 30px 15px;
			.col {
				.pad {
					padding: 0;
				}
			}
		}

		.block01 & {
			.col-b {
				text-align: center;
			}
		}

		.col-a {
			width: 100%;
			margin: 0;
			float: none;
			text-align: center;
			P {
				margin: 0;
				&.heading {
					font-size: 15px;
				}
			}
			IMG {
				margin: 0 auto;
				text-align: center;
			}
			DIV.img {
				display: block;
				float: left;
				width: 45%;
				text-align: right;
				IMG {
					text-align: right;
					float: right;
					max-width: 80px !important;
					height: auto;
				}
				/*DIV.img {
					float: none;
					width: 119px;
    				max-width: 119px;
					text-align: center;
					margin: 0 auto;
					IMG { width: 100% !important; text-align: center; float: none; }
				}*/
				/*remove above to use on top of global*/
				&.mobile-img-center {
					display: block;
					float: none;
					width: 119px;
					max-width: 119px;
					text-align: center;
					IMG {
						text-align: center;
						float: none;
						max-width: 100%;
						height: auto;
					}
				}
			}
			DIV.txt {
				display: block;
				float: left;
				text-align: left;
				width: 100%;
				margin-left: -45%;
				padding-left: 50% !important;
				.box-sizing(border-box);
				P.heading01 {
					margin-top: 1em;
				}
			}
		}

		.col-b {
			width: 100%;
			float: none;
			padding-bottom: 1em;
			margin: 0;
			text-align: center;
			P {
				&.heading {
					display: block;
					margin: 0;
					font-size: 25px;
					color: @color-black;
					line-height: 1.2;
					+ P.price01 {
						margin: .5em 0 0 0;
					}
				}
				&.subheading {
					display: block;
					margin: 0;
					font-size: 15px;
					color: @color-gray-medium;
					+ P.price01 {
						margin: .5em 0 0 0;
					}
				}
				&.price01 {
					margin: 0;
					font-size: 25px;
					color: @color-black;
					SMALL {
						font-size: 15px;
					}
				}
				&.meta {
					display: none;
					font-size: 15px;
					margin: 1em 0 0 0;
				}
			}
			UL.actions01 {
				margin: .5em 0 0 0 !important;
				LI {
					margin: 0;
					padding: 0 10px;
					A.btn {
						font-size: 17px;
						/*font-weight: bold;*/
						padding: 10px;
						line-height: 1.2;
						float: none;
					}
				}
			}

		}

		.col-c {
			padding: 0 !important;
			clear: both;
			P {
				margin: 0;
				&.meta + P {
					display: none;
				}
			}
			H2,
			P.meta {
				display: none;
			}
			A.toggler {
				display: block;
				text-align: center;
				float: none;
			}
			DIV.details {
				text-align: center;
				UL.type04 {
					display: none;
				}
				A.toggle {
					display: block;
					text-decoration: underline;
				}
				&.open {
					UL.type04 {
						display: block;
					}
				}
			}
		}

		DIV.img {
			display: none;
			+ DIV.txt {
				padding-left: 0;
			}
		}

		UL.type04 {
			padding: 0 15px 15px;
			LI {
				width: 50% !important;
				margin: 0 !important;
			}
			P {
				font-size: 10px;
				STRONG {
					font-size: 12px;
				}
			}
		}

		&.item01b {
			& > .a {
				background: none;
				padding-bottom: 40px;
			}

		}

		&.item01f {
			& > .a {
				.col-a {
					width: 100%;
					padding: 0 0 15px;
					font-size: 14px;
					UL LI {
						padding: 0 2%;
					}
				}
			}
		}

		&.item01g {
			.col-a {
				width: 40%;
				float: left;
			}
			.col-b {
				width: 55%;
				float: right;
				text-align: left;
				P.price01 {
					display: block;
				}
			}
			.col-c {
				display: none;
			}
		}

		&.item01h {
			.col-a {
				width: 40%;
				float: left;
			}
			.col-b {
				width: 55%;
				float: right;
				text-align: left;
				P.price01 {
					display: block;
				}
			}
			.col-c {
				A.toggler {
					display: block;
					border: 2px @color-gray-light;
					border-style: dashed none;
					padding: .5em 0;
					text-decoration: none;
					text-align: center;
					&:before {
						content: @ico-arrdown;
						font-family: "icons";
						margin-right: 10px;
					}
					&:last-child {
						border-bottom: none;
						padding-bottom: 0;
					}
				}
				> .details {
					display: none;
				}
				&.open {
					.details {
						display: block;
					}
					A.toggler {
						margin-top: 1em;
						&:before {
							content: @ico-arrup;
						}
					}
				}
			}
		}

		&.item01k {
			.col-b + .col-c {
				padding: 0;
			}
			.col-a {
				max-width: 100%;
				width: 100%;
				margin: 0;
				float: none;
				text-align: center;
				DIV.img {
					float: none;
					width: 60%;
					text-align: center;
					margin: 0 auto;
					IMG {
						width: 100% !important;
						text-align: center;
						float: none;
					}
				}
			}
			.col-b {
				width: 100%;
			}
			.col-c {
				P {
					&.price01 {
						font-size: 20px;
						line-height: 1;
					}
					&.meta + P {
						display: none;
					}
				}
			}
		}

		&item01l {
			.col-a {
				max-width: 100%;
				width: 100%;
				margin: 0;
				float: none;
				text-align: center;
				DIV.img {
					float: none;
					width: 60%;
					text-align: center;
					margin: 0 auto;
					IMG {
						width: 100% !important;
						text-align: center;
						float: none;
					}
				}
			}
		}

		&.item01m {
			.col-a {
				max-width: 100%;
				width: 100%;
				margin: 0;
				float: none;
				text-align: center;
				DIV.img {
					float: none;
					width: 60%;
					text-align: center;
					margin: 0 auto;
					IMG {
						width: 100% !important;
						text-align: center;
						float: none;
					}
				}
			}
		}

		&.item01n {

		}

		&.size01 {
			.col-b {
				width: 100%;
			}
		}
	}
	@media @media-tablet {
		&.item01f {
			& > .a {
				.col-c {
					clear: both;
					padding: 10px 15px !important;
				}
			}
		}
	}
}

DIV.item03 {
	border: 1px solid @color-gray-lightest;
	margin: 2em 0 0 0;
	UL.type05 {
		LI {
			padding: 0 0 0 40px;
			&:before {
				left: 0;
			}
		}

	}
	.itemgroup {
		display: block;
		&:nth-child(n+2) {
			border-top: 1px solid @color-gray-lightest;
		}
	}
	H2 {
		font-weight: 400;
		font-size: ceil(@base-font-size*2);
		color: @color-black;
		&:first-child {
			margin-top: 0;
		}
	}
	H3 {
		font-weight: bold;
	}
	P {
		margin: .5em 0 0 0;
	}
	[class^="ico-"] {
		margin-left: 5px;
		white-space: nowrap;
		&:first-child {
			margin-left: 0;
		}
		&:before {
			color: @color01;
			margin-right: 5px;
		}
	}
	> .a,
	> .b,
	.itemgroup > .a,
	.itemgroup > .b {
		padding: 30px 20px;
		.box-sizing(border-box);
		.clearfix();
		position: relative;
		display: block;
		&:before {
			content: "";
			position: absolute;
			left: 200px;
			right: 40px;
			top: 0;
			border-top: 2px dashed @color-gray-light;
		}
		&:first-child {
			&:before {
				display: none;
			}
		}
		DIV.img {
			text-align: center;
		}
		DIV.txt {
			P.subheading {
				color: @color-gray-medium;
			}
			UL.params03 {
				padding: 0;
				border: 0;
				LI {
					padding: 0 0 45px 0;
					text-align: center;
					.flex(0 0 32.6666%);
					width: 32.6666%;
					font-size: 12px;
					STRONG {
						font-size: 12px;
					}
					&.type01:before {
						content: @ico-down;
					}
					&.type02:before {
						content: @ico-up;
					}
					&.type03:before {
						content: @ico-unlimited;
					}
					&.type04:before {
						content: @ico-chat;
					}
					&.type05:before {
						content: @ico-calls;
					}
					&.type06:before {
						content: @ico-limited;
					}
					&:before {
						left: 0;
						right: auto;
						font-size: 32px;
					}
					&:after {
						display: none;
					}
				}
			}
		}
	}
	DIV.itemgroup {
		border-top: 2px solid @color-gray-light;
		&:first-child {
			border-top: none;
		}
	}
	> .a,
	.itemgroup > .a {
		overflow: hidden;
		@media @media-mobile {
			UL.type04 {
				background: none;
				padding: 0;
				margin: 0;
				LI {
					width: 50% !important;
					margin: 0 !important;
					float: left;
					padding: 0;
					background: none;
					text-align: center;
				}
				LI,
				LI:first-child:nth-last-child(4),
				LI:first-child:nth-last-child(4) ~ LI {
					width: auto;
					margin-right: 20px;
				}
				P {
					&,
					STRONG {
						font-size: @base-font-size;
					}
				}
				&.type04b {
					.meter {
						float: none;
					}
					P {
						float: none;
						text-align: center;
						margin-left: 0;
					}
				}
			}
		}
	}
	> .b,
	.itemgroup > .b {

		&.type02 {
			border-top: 2px dashed @color-gray-light;
			&:before {
				background: transparent;
				position: absolute;
				left: 50%;
				z-index: 5;
				margin-left: -20px;
				top: -20px;
				text-align: center;
				width: 40px;
				height: 40px;
				line-height: 40px;
				.rounded(20px);
				border: 2px @color-gray-light;
				color: @color02;
				border-style: dashed none none;
			}
			&:after {
				background: @color-white;
				font-family: "icons";
				content: @ico-plus;
				font-size: 16px;
				position: absolute;
				left: 50%;
				margin-left: -20px;
				top: -20px;
				text-align: center;
				width: 40px;
				height: 40px;
				line-height: 40px;
				.rounded(20px);
				border: 2px @color-gray-light;
				color: @color02;
				border-style: dashed none none;
			}
		}
	}
	DIV.img {
		float: left; /*width: 155px;*/
		P.small {
			font-size: ceil(@base-font-size*0.86);
			color: @color-gray-medium;
			text-align: center;
		}

		max-width: 119px;
		min-width: 60px;
		width: 119px;
	}
	DIV.txt {
		width: 100%;
		float: left;
		.box-sizing(border-box);
		margin-left: -120px;
		margin-right: -300px;
		padding-right: 320px;
		padding-left: 140px;
		DIV.form01 {
			margin: 1.5em 0 0 0;
		}
		P.price {
			display: none;
		}
		.icon-color {
			margin-left: 0;
			white-space: normal;
			line-height: 18px;
			&:before {
				display: inline-block;
			}
			.ico-pencil {
				margin-left: 10px;
				@media @media-notdesktop {
					margin-left: 0;
				}
			}
		}
	}
	P {
		SMALL {
			font-size: 86%;
		}
	}
	UL.type05 {
		.clearfix();
		margin: 1em 0 0 0;
		LI {
			float: left;
			width: 50%;
			margin: .5em 0 0 0 !important;
			white-space: normal !important;
			&:nth-child(odd) {
				clear: both;
			}
			&:before {
				color: @color-gray-medium;
				font-size: 18px;
			}
			@media @media-notdesktop {
				float: none;
				width: auto;
			}
		}
	}
	DIV.prices {
		float: right;
		margin: .5em 0 0 0;
		width: 300px;
		P.price {
			+ P {
				margin: 5px 0 20px 0;
			}
		}
		DIV.row.type03 {
			SPAN.label,
			LABEL {
				width: 40% !important;
			}
			DIV.items {
				margin-left: -40% !important;
				padding-left: 42%;
			}
		}
		DIV.form01 {
			margin: 1em 0 0 0;
		}
	}
	P.price {
		font-size: ceil(@base-font-size*1.69);
		margin: 0;
		font-weight: bold;
		line-height: 1;
	}
	&.item03b {
		> .b {
			&:after {
				background: lighten(@color-gray-lightest, 5%);
				overflow: hidden;
				.rounded(20px);
				border: 0 solid lighten(@color-gray-lightest, 5%);
			}
			text-align: center;
			padding: 30px 30px;
			background: lighten(@color-gray-lightest, 5%);
			P {
				margin: 1.5em 0 0 0;
			}
		}
	}
	&.item03c {
		margin: 0;
		border-style: solid solid none solid;
		&:last-child {
			border-style: solid;
		}
		.itemgroup {
			.b {
				text-align: center; /*padding: 30px 30px;*/
				background: lighten(@color-gray-lightest, 5%);
				/*P {
					margin: 1.5em 0 0 0;
					color: @color-gray-medium;
				}*/
				&.type02b {
					&:after {
						background: lighten(@color-gray-lightest, 5%);
						overflow: hidden;
						.rounded(20px);
						border: 0 solid lighten(@color-gray-lightest, 5%);
					}
				}
				&:first-child {
					&:before {
						display: block;
					}
				}
			}
			.a {
				/*DIV.img {
					max-width: 155px;
					min-width: 60px;
				}*/
				/*DIV.txt { margin-left: -175px; margin-right: -260px; padding-right: 260px; padding-left: 200px; }*/
				DIV.prices {
					/*width: 260px;*/
					P {
						/*color: @color-gray-medium;*/
						&.price {
							color: @color-black;
						}
						SMALL {
							display: block;
							color: @color-black;
							margin: .5em 0 0 0;
						}
						DEL {
							color: @color-gray-medium;
						}
					}
				}
			}
		}
	}
	&.item03d {
		.itemgroup {
			.b {
				text-align: center;
				padding: 30px 30px;
				background: lighten(@color-gray-lightest, 5%);
				P {
					margin: 1.5em 0 0 0;
					color: @color-gray-medium;
				}
				&.type02b {
					margin-top: 10px;
					&:after {
						background: lighten(@color-gray-lightest, 5%);
						overflow: hidden;
						.rounded(20px);
						border: 0 solid lighten(@color-gray-lightest, 5%);
					}
				}
			}
			.a {
				DIV.img {
					max-width: 119px;
					min-width: 60px;
				}
				DIV.prices {
					P {
						color: @color-gray-medium;
						&.price {
							color: @color-black;
						}
						SMALL {
							display: block;
							color: @color-black;
						}
						DEL {
							color: @color-gray-medium;
						}
					}
				}
			}
		}
	}
	&.item03e {
		border: 1px solid @color01;
	}
	&.item03g {
		border-style: solid;
		.itemgroup {
			.a {
				/*DIV.prices {
					width: 300px;
				}*/
				/*DIV.txt {
					margin-left: -175px;
					margin-right: -300px;
					padding-right: 300px;
					padding-left: 200px;
				}*/
			}
		}
	}
	@media @media-tablet {
		DIV.img {
			max-width: 119px;
			min-width: 60px;
			width: 119px;
		}

		DIV.txt {
			margin-left: -120px;
			margin-right: -230px;
			padding-right: 250px;
			padding-left: 140px;
		}

		DIV.prices {
			width: 230px;
		}
	}
	@media @media-mobile {
		> .a,
		> .b {
			padding: 15px;
		}

		> .b:before {
			left: 20px;
			right: 20px;
		}

		DIV.img,
		DIV.txt,
		DIV.prices {
			float: none;
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
			padding-right: 0;
		}

		DIV.img {
			text-align: center;
			IMG {
				max-width: 100% !important;
				height: auto;
			}
		}

		DIV.txt {
			P.price {
				display: block;
				margin: .5em 0 0 0;
			}
			H2,
			P.price { /*text-align: center;*/
				text-align: left;
			}
			[class^="ico"] {
				display: block;
				margin-top: .5em;
				margin-left: 0 !important;
			}
			.ico-infinity {
				display: inline-block !important;
				margin-top: 0 !important;
			}
		}

		&.item03e {
			.itemgroup {
				.a {
					DIV.img {
						float: none;
						margin: 0 auto;
						text-align: center;
					}
					DIV.txt {
						float: none;
						width: 100%;
						margin-left: 0;
						margin-right: 0;
						padding-left: 0;
						padding-right: 0;
						margin-top: 2em;
					}
					DIV.prices {
						float: left;
					}
					DIV.pad {
						padding-left: 0;
						text-align: left !important;
						> * {
							text-align: left;
							margin-left: 0;
						}
					}

				}
			}
		}

		&.item03c {
			margin: 2em 0 0 0;
			border-style: solid;
			.itemgroup > .a,
			.itemgroup > .b {
				DIV.txt {
					float: none;
					width: 100%;
					margin-left: 0;
					margin-right: 0;
					padding-left: 0;
					padding-right: 0;
					DEL {
						margin: .5em 0 0 0;
					}
					P.price {
						margin-top: 0;
						text-align: left;
					}
					DIV.prices {
						clear: both;
						margin-top: 2em;
					}
				}
				DIV.prices {
					float: none;
					width: 100%;
					margin-left: 0;
					margin-right: 0;
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.itemgroup > .a,
		.itemgroup > .b {
			DIV.img {
				float: left;
				/*width: 30%;*/
				width: 20%;
				margin-right: 15px;
			}
			DIV.txt {
				float: none;
			}
			> DIV.pad {
				padding-left: 30%;
				text-align: left !important;
				> * {
					text-align: left !important;
					margin-left: 20px;
				}
			}
			DIV.details {
				clear: both;
			}
			.link-outline-none;
			A.toggler {
				display: block;
				border: 2px @color-gray-light;
				border-style: dashed none;
				padding: .5em 0;
				text-decoration: none;
				text-align: center;
				margin-top: 2em;
				&:before {
					content: @ico-arrdown;
					font-family: "icons";
					margin-right: 10px;
				}
				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}
			}
			.details {
				.data {
					display: none;
				}
				&.open {
					.data {
						display: block;
					}
					UL {
						&.type04 {
							.display(flex);
						}
					}
					A.toggler {
						margin-top: 1em;
						&:before {
							content: @ico-arrup;
						}
					}
				}
			}
		}

		&.item03f {
			.itemgroup {
				.a {
					DIV.img {
						float: none;
						margin: 0 auto;
						text-align: center;
					}
					DIV.txt {
						float: none;
						width: 100%;
						margin-left: 0;
						margin-right: 0;
						padding-left: 0;
						padding-right: 0;
						margin-top: 2em;
					}
					DIV.prices {
						float: left;
					}
					DIV.pad {
						padding-left: 0;
						> * {
							text-align: left;
							margin-left: 0;
						}
						H2,
						P.subheading,
						P.price {
							text-align: center;
						}
					}

				}
			}
		}
	}
}

DIV.item05 {
	position: relative;
	padding: 40px 20px 40px 140px;
	margin: 1em 0 0 0;
	border: 2px solid @color12;
	overflow: hidden;
	&.bg01 {
		border: 2px solid lighten(@color-gray-lightest, 5%);
		background-color: lighten(@color-gray-lightest, 5%);
	}
	P.heading {
		font-weight: bold;
		font-family: "Verlag", "Open Sans", Arial, Helvetica, sans-serif;
		margin: 0;
		font-size: 18px;
		& SPAN {
			display: block;
		}
	}
	P.meta {
		color: @color-gray-medium;
	}
	A.toggler {
		&.open {
			SPAN.a {
				display: none;
			}
			SPAN.b {
				display: block !important;
			}
		}
	}
	SPAN.img {
		position: absolute;
		left: 40px;
		top: 40px;
		text-align: center;
		max-width: 60px;
		min-width: 60px;
	}
	@media @media-mobile {

	}
}

DIV.item04 {
	&.item04b {
		padding: 20px;
	}
	SPAN.bullet {
		display: block;
		z-index: 9;
		width: 28px;
		height: 28px;
		.box-sizing(border-box);
		.rounded(50%);
		text-align: center;
		font-size: 15px;
		line-height: 27px;
		background-color: @color-msg-info;
		color: @color-white;
		position: absolute;
		left: 35px;
		margin-top: 10px;
	}
	DIV.form01.readonly {
		position: relative;
		padding-right: 100px;
		UL.actions01 {
			position: absolute;
			margin: 0;
			top: 0;
			right: 0;
		}
	}
	position: relative;
	padding: 20px 20px 20px 100px;
	margin: 1em 0 0 0;
	border: 1px solid #e1e1eb;
	overflow: hidden;
	@media @media-mobile {
		DIV.form01.readonly {
			UL.actions01 {
				margin: 0;
				LI A {
					font-size: small;
				}
			}
		}
	}
}
/*not used ?*/
/* Meters */
.meter {
	width: 58px;
	height: 58px;
	position: relative;
	overflow: hidden;
	margin: 1em auto 0 !important;
	background: none;
	&:after {
		.type01&,
		.type02&,
		.type03&,
		.type04&,
		.type05&,
		.type13&,
		.type06& {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1;
			background: @color-gray-light;
		}
		.type04& {
			bottom: 2px;
		}
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 3;
		.type01& {
			background: url(bg115.png) no-repeat;
		}
		.type02& {
			background: url(bg116.png) no-repeat;
		}
		.type03& {
			background: url(bg117.png) no-repeat;
		}
		.type04& {
			background: url(bg118.png) no-repeat;
		}
		.type05& {
			background: url(bg119.png) no-repeat;
		}
		.type06& {
			background: url(bg120.png) no-repeat;
		}
		.type07& {
			background: url(bg121.png) no-repeat;
			background-size: contain !important;
		}
		.type08& {
			background: url(bg122.png) no-repeat;
		}
		.type10& {
			background: url(bg123.png) no-repeat;
		}
		.type11& {
			background: url(bg124.png) no-repeat;
		}
		.type12& {
			background: url(bg125.png) no-repeat;
			background-size: contain !important;
		}
		.type13& {
			background: url(bg126.png) no-repeat;
		}

	}
	.a {
		width: 56px;
		height: 56px;
		position: absolute;
		clip: rect(0px, 28px, 56px, 0);
		top: 0; /*left: 2px;*/
		left: 0;
		padding: 0 !important;
	}
	.circle {
		width: 56px;
		height: 56px;
		.box-sizing(border-box);
		position: absolute;
		background: @color02;
		&.left {
			clip: rect(0, 28px, 56px, 0);
			z-index: 2;
		}
		&.right {
			clip: rect(0, 56px, 56px, 28px);
			z-index: 2;
		}
	}
	&.type04 {
		.b {
			height: 29px;
			top: 5px;
		}
	}
	&.type05 {
		.b {
			height: 30px;
			top: 3px;
		}
	}
	.b {
		width: 56px;
		height: 56px;
		.box-sizing(border-box);
		position: relative;
		z-index: 2;
		.fill {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 0;
			width: 100%;
			background: @color02;
		}
		/*.type04& { height: 42px; !*top: 8px;*! top: 6px; }
		.type05& { height: 38px; top: 6px; }*/
	}

	@media @media-mobile {
		width: 40px;
		height: 40px;
		.a {
			width: 40px;
			height: 40px;
			clip: rect(0, 20px, 40px, 0);
		}

		.circle {
			width: 40px;
			height: 40px;
			&.left {
				clip: rect(0, 20px, 40px, 0);
			}
			&.right {
				clip: rect(0, 40px, 40px, 20px);
			}
		}

		.b {
			width: 40px;
			height: 40px;
		}

		&.type04 {
			.b {
				height: 29px;
				top: 5px;
			}
		}

		&.type05 {
			.b {
				height: 30px;
				top: 3px;
			}
		}

		.b {
			width: 40px;
			height: 40px;
		}

		/*.b {
			width: 40px; height: 40px;
			.type04& { height: 42px; top: 8px; }
			.type05& { height: 38px; top: 10px; }
		}*/
		&:before {
			background-size: 100% 100% !important;
		}
	}
}

.meter02 {
	&.meter02b {
		&.meter02b--facelift {
			font-family: @font-main;
			.a { width: 156px; height: 156px;
				&.type02 {
					.circle {
						background: @color-blue02;
					}
				}
			}
			.b {
				box-shadow: none;
				-webkit-box-shadow: none;
			}
			.pad02 {
				P {
					margin: 0;
					&.heading--title01 {
						display: inline-block;
						font-size: 20px;
						font-weight: 400;
						color: @color-gray03;
						font-family: @font-main;
						webkit-hyphens: manual;
						-ms-hyphens: manual;
						hyphens: manual;
						STRONG {
							padding-right: 0;
						}
						SPAN {
							display: inline-block;
							font-size: 20px;
							font-weight: 400;
							color: @color-gray03;
						}
					}
					&.separated {
						margin-top: 3px;
						padding-top: 3px;
						border-top: 1px solid @color-gray06;
					}
					&.heading--title02 {
						font-size: 13px;
						font-weight: 400;
						color: @color-gray03;
						SPAN {
							font-size: 13px;
							font-weight: 400;
							color: @color-gray03;
						}
					}
					STRONG {
						padding: 0;
						color: @color-gray01;
					}
				}
			}
		}
		&.type02 {
			.b {
				background: lighten(@color-gray-lightest, 5%);
			}
			.pad02 {
				width: 128px;
				height: 128px;
				P {
					margin: 0;
					&.separated {
						margin-top: 5px;
						padding-top: 5px;
						border-top: 1px dashed @color-gray-light;
					}
				}
			}
			.c {
				border-bottom: 78px solid lighten(@color-gray-lightest, 5%);
			}
			&:after {
				background: @color-white;
			}
		}
		width: 156px;
		height: 156px;
		margin: 0 auto 1em;
		.a {
			width: 156px;
			height: 156px;
			.circle {
				width: 156px;
				height: 156px;
				&.left {
					clip: rect(0, 78px, 156px, 0);
				}
				&.right {
					clip: rect(0, 156px, 156px, 78px);
				}
			}
			&.type02 {
				.circle {
					background: @color-msg-info;
					&.left {
						clip: rect(0, 78px, 156px, 0);
					}
					&.right {
						clip: rect(0, 156px, 156px, 78px);
					}

				}
			}
		}
		.b {
			width: 128px;
			height: 128px;
			z-index: 99;
			.text {
				padding: 20px 40px;
				position: absolute;
				.zindex(@usagemeterZindex,1);
				P {
					&.separated {
						margin-top: 10px;
						padding-top: 10px;
						border-top: 1px dashed @color-gray-light;
					}
					&.heading--title01 {
						&.ico-alert {
							&:before {
								display: none;
							}
							&:after {
								content: @ico-alert;
								color: @color02;
								font-family: "icons";
							}
						}
						SPAN {
							display: block;
							font-weight: 700;
							font-size: 21px;
						}
					}
				}
			}
		}
		.pad02 {
			width: 128px; height: 128px;
			&.heading--title01 {
				color: @color-black;
				font-family: @font-heading;
				font-size: 23px;
				webkit-hyphens: manual;
				-ms-hyphens: manual;
				hyphens: manual;
				/*.hyphens ();*/
			}
		}
		.c {
			border-bottom: 78px solid @color-white;
			border-left: 78px solid transparent;
			border-right: 78px solid transparent;
			.zindex(@usagemeterZindex,0);
		}
	}
	width: 246px;
	height: 246px;
	position: relative;
	overflow: hidden;
	margin: 1em auto 1em;
	background: none;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		background: @color-gray-light;
		.rounded(50%);
	}
	&.ico-alert {
		&:before {
			position: absolute;
			right: 0;
			font-size: 21px;
		}
	}
	.color01 {
		color: @color01;
	}
	.color02 {
		color: @color02;
	}
	.color03 {
		color: @color03;
	}
	.color04 {
		color: @color-msg-positive;
	}
	.color05 {
		color: @color-negative;
	}
	.a {
		z-index: 2;
		width: 246px;
		height: 246px;
		.rounded(50%);
		position: absolute;
		padding: 0 !important;
		.circle {
			width: 246px;
			height: 246px;
			.box-sizing(border-box);
			position: absolute;
			background: @color01;
			.rounded(50%);
			transform-origin: 50% 50% 0;
			&.left {
				clip: rect(0, 123px, 246px, 0);
				z-index: 2;
			}
			&.right {
				clip: rect(0, 246px, 246px, 123px);
				z-index: 2;
			}
		}
		&.type02 {
			.circle {
				background: @color03;
				&.left {
					clip: rect(0, 123px, 246px, 0);
					z-index: 2;
				}
				&.right {
					clip: rect(0, 246px, 246px, 123px);
					z-index: 2;
				}
			}
		}
	}
	.b {
		content: "";
		position: absolute;
		z-index: 9;
		width: 212px;
		height: 212px;
		.rounded(50%);
		background: @color-white; /*border: 2px solid @color-white;*/
		.box-sizing(border-box);
		text-align: center;
		right: 0;
		bottom: 0;
		top: 0;
		left: 0;
		margin: auto;
		-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
		.display(flex);
		.justify-content(center);
		.align-items(center);
		.text {
			padding: 20px 40px;
			P {
				&.separated {
					margin-top: 10px;
					padding-top: 10px;
					border-top: 1px dashed @color-gray-light;
				}
				&.heading01 {
					&.ico-alert {
						&:before {
							display: none;
						}
						&:after {
							content: @ico-alert;
							color: @color02;
							font-family: "icons";
						}
					}
					SPAN {
						display: block;
						font-weight: 700;
						font-size: 21px;
					}

				}
			}
			.meter__text {
				&--value {
					font-size: 21px;
				}
				&--note {

				}
			}
		}
	}
	.pad02 {
		.display(flex);
		.justify-content(center);
		.align-items(center);
		.flex-direction(column);
		width: 212px;
		height: 212px;
		padding: 10px 10px;
		text-align: center;
		right: 0;
		bottom: 0;
		top: 0;
		left: 0;
		margin: auto;
		position: absolute;
		.zindex(@usagemeterZindex,1);
		.box-sizing(border-box);
		P {
			&.separated {
				margin-top: 10px;
				padding-top: 10px;
				border-top: 1px dashed @color-gray-light;
			}
			&.heading01 {
				/*margin: 0;*/
				&.ico-alert {
					&:before {
						display: none;
					}
					&:after {
						content: @ico-alert;
						color: @color02;
						font-family: "icons";
					}
				}
				SPAN {
					display: block;
					/*.bold;*/
					font-weight: 700;
					font-size: 21px;
				}
			}
			&.note {
				margin: 0;
			}
		}
	}
	.c {
		width: 0;
		height: 0;
		border-bottom: 123px solid @color-white;
		z-index: 99;
		border-left: 123px solid transparent;
		border-right: 123px solid transparent;
		position: absolute;
		bottom: 0;
	}
	@media @media-mobile {
		&.meter02c {
			&.type02 {
				margin: 0 auto;
			}
		}
	}
}

DIV.multiselect {
	position: relative;
	z-index: 19;
	& > A.btn {
		position: relative;
		z-index: 2;
		border: 1px solid @color-gray-medium-light;
		text-align: left !important;
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAICAYAAAAFiPQnAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGpJREFUeNpilJSUdGFgYNjDQBpwefbsGal64IAJiGcCcRoJetKgesgGLEBsAsRnoPxZRFhYDtVDkaXvibQY2cL3lFrKQITFVLMQ2VJ8FlPVQnRLsVkMs1SZgYqABYsYssXvKU002ABAgAEAMuUc51btI/sAAAAASUVORK5CYII=') 100% 50% no-repeat;
		padding: 7px 65px 7px 15px;
		&:hover {
			background-color: lighten(@color-gray-lightest, 5%);
		}
	}
	A.remove {
		cursor: pointer;
		z-index: 3;
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		background: lighten(@color-gray-lightest, 5%) url(ico_cross.png) no-repeat 50% 50%;
		text-indent: -10000px;
		width: 34px;
		height: 34px;
		border: 1px solid @color-gray-medium-light;
		.box-sizing(border-box);
		&:hover {
			background-color: @color-gray-lightest;
		}
	}
	&.hasvalue A.remove {
		display: block;
	}
	DIV.toggle-panel {
		display: none;
		position: absolute;
		left: 0;
		top: 33px;
		background-color: lighten(@color-gray-lightest, 5%);
		border: 1px solid @color-gray-medium-light;
		width: 335px;
		.box-sizing(border-box);
		* {
			width: auto !important;
			text-align: left !important;
		}
		DIV.pad {
			padding: 5px 20px 25px;
		}
	}
	&.open {
		z-index: 29;
		DIV.toggle-panel {
			display: block;
		}
		& > A.btn {
			background-color: lighten(@color-gray-lightest, 5%);
			border-bottom: 1px solid lighten(@color-gray-lightest, 5%);
		}
	}
	UL.checkboxes01 {
		list-style: none;
		padding: 0;
		margin: 0;
		LI {
			padding: 3px 0;
			margin: 0;
			float: none;
			font-size: 13px;
			LABEL {
				display: block;
				padding: 5px 0;
				cursor: pointer;
				INPUT {
					position: relative;
					top: 2px;
				}
			}
		}
	}
	UL.actions01 {
		margin: 10px 0 0 0;
	}
	@media @media-mobile {
		& > A.btn {
			text-align: left !important;
		}

		DIV.toggle-panel {
			z-index: 3;
			width: 100%;
			.box-sizing(border-box);
			top: 33px;
			border-top: none;
			text-align: left !important;
			UL.checkboxes01 LI {
				text-align: left;
			}
		}
	}
}

/* Compare table */

.sticky01 {
	background: @color-white;
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 110px;
	.zindex(@stickyZindex, 0);
	.sticky01__text {
		text-align: center;
		padding-top: 10px;
		font-size: 20px;
		font-weight: bold;
		font-family: @font-heading;
	}
	.sticky01__img {
		height: 30px;
		width: auto;
		position: relative;
		margin-right: 10px;
		top: .15em
	}
	&.bottom {
		top: auto;
		bottom: 0;
		height: auto;
		padding-bottom: 10px;
	}
	&.sticky01b {
		background: fade(@color-white, 95%);
		border-top: 1px solid @color-gray-lightest;
		text-align: center;
		padding-top: 10px;
		font-size: 20px;
		font-weight: bold;
		font-family: @font-heading;
		.ru & {
			font-family: @font-heading-ru;
		}
		P {
			margin: 0;
			padding: 10px 25px;
			&:before {
				font-size: 26px;
				margin-right: 10px;
				position: relative;
				top: .15em;
			}
			&.color01 {
				color: @color-negative;
			}
			&.color02 {
				color: @color01;
			}
			&.color03 {
				color: @color02;
			}
			&.color04 {
				color: @color-black;
			}
			.btn {
				margin: 0 0 0 20px;
				position: relative;
				font-size: 20px;
				font-family: @font-heading;
				height: 40px;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
			/*sticky01__img*/
			IMG {
				height: 30px;
				width: auto;
				top: 5px;
				position: relative;
				margin-right: 10px;
			}
		}
		A.ico-cross {
			position: absolute;
			text-indent: -10000px;
			height: 20px;
			width: 20px;
			right: 25px;
			top: 8px;
			background: white;
			padding: 2px;
			.box-sizing(border-box);
			&:before {
				text-indent: 0;
				float: left;
				overflow: hidden;
			}
		}
		@media @media-tablet {
			P {
				line-height: 1.2;
				SPAN.row {
					display: block;
					padding-top: 10px;
				}
				.btn {
					margin: 10px 0 0;
				}
			}

			A.ico-cross {
				right: 10px;
			}
		}
		@media @media-mobile {
			font-size: 16px;
			.col00 {
				padding: 0 10px;
			}

			P {
				padding: 0;
				margin-top: 10px;
				font-size: 20px;
				SPAN.row {
					display: block;
				}
				.btn {
					margin: 10px 0 0;
					width: auto;
					display: block;
					float: none;
					&.discrete {
						border-bottom: none;
						margin-top: 0;
						padding: 0;
					}
				}
			}

			A.ico-cross {
				right: 10px;
			}
		}
	}
	.form01 {
		SPAN {
			&.label {
				width: auto !important;
				font-size: 20px;
			}
			&.text, &.label {
				line-height: 30px;
			}
		}
		.btn {
			margin-left: 30px !important;
		}
	}
	TABLE.compare01 {
		visibility: hidden;
		margin: 0;
		THEAD {
			visibility: visible;
		}
	}
	UL.compare01 {
		padding: 0;
		margin: 0;
		list-style: none;
		overflow: hidden;
		LI {
			float: left;
			width: 19%;
			padding: 0;
			margin: 0 0 0 1%;
			&:before {
				display: none;
			}
			&:first-child {
				width: 20%;
				margin: 0;
			}
			&.f-right {
				padding: 10px 0 0 0;
				width: 110px;
				float: right;
				text-align: center;
				A.btn {
					display: block;
					margin: 5px 0 0 0;
					&.discrete {
						display: inline-block;
						padding: 5px 0 0 0;
						font-size: 13px;
					}
				}
			}
		}
		P.heading01 {
			margin-top: 20px;
		}
	}
	.compare01 {
		DIV.posrel {
			padding-top: 0;
			overflow: hidden;
			min-height: 90px;
			& > A:first-child {
				height: 70px;
				width: 70px;
				position: absolute;
				left: 0;
				top: 15px;
				text-align: center;
			}
		}
		IMG {
			max-height: 70px;
			max-width: 70px;
			height: auto;
			width: auto;
		}
		P.heading01 {
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
			height: 2.5em;
			line-height: 1.25;
			overflow: hidden;
			padding-left: 75px;
			margin: 10px 0 0 0;
			font-size: 15px;
			font-weight: bold;
			A {
				color: @color-black;
				&:hover {
					color: @color02;
					text-decoration: none;
				}
			}
		}
		A.remove {
			padding: 0 !important;
			position: static;
			margin-left: 75px;
			font-size: 13px;
		}
	}
	@media @media-mobile {
		display: none;
		overflow: hidden;
		&.sticky01b {
			display: block;
			.form01 {
				.btn {
					margin-left: 0 !important;
					clear: both;
				}
			}
		}
	}
	@media @media-tablet {
		.compare01 {
			IMG {
				display: none;
			}
			P.heading01 {
				padding-left: 0;
			}
			A.remove {
				margin-left: 0;
				&:before {
					display: none;
				}
			}
		}
	}
}

/* Content sections */
SECTION {
	.clearfix();
	position: relative;
	> H2 {
		margin-left: 20px !important;
		margin-right: 20px !important;
	}
	&.section {
		&__filters {
			background: @color-gray07;
			padding: 20px 0 29px 0;
			margin: 0 0 40px 0;
			position: relative;
			.filters__more {
				width: 169px;
				height: 0;
				margin: 55px auto 0 auto;
				border-top: 30px solid @color-gray07;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				display: block;
				background: none;

				text-align: center;
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				bottom: -30px;
			}
			.filters__more--text {
				text-align: center;
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				bottom: 8px;

				&:before {
					content: "\f107";
					font-family: FontAwesome;
					margin-right: 5px;
					font-size: 20px;
					line-height: 1.05;
					/*kick to global*/
					vertical-align: bottom;
				}
			}
			&.open {
				.filters__more--text {
					&:before {
						content: "\f106";
					}
				}
			}
		}
	}
	&.content,
	&.pad {
		padding: 60px 0;
		&.type01 {
			& + &,
			& + .type04 {
				padding-top: 0;
			}
		}
		&.type02 {
			& + & {
				padding-top: 0;
			}
		}
		&.type03 {
			& + & {
				padding-top: 0;
				margin-top: 0;
			}
			.block01 {
				background: @color-white;
			}
		}
		&.type04 {
			& + &,
			& + .type01 {
				padding-top: 0;
			}
		}
		.col00 > H2:first-child {
			margin: 0;
		}
	}
	&.pad02 {
		padding: 30px 0;
		.col00 > H2:first-child {
			margin: 0;
		}
	}

	&.bg01 {
		background: lighten(@color-gray-lightest, 5%);
		padding-bottom: 60px;
		padding-top: 60px;
		H2:first-child {
			margin-top: 0;
		}
		.col.w50p > H2 {
			font-size: 46px;
			&:first-child {
				margin-top: 0 !important;
			}
		}
		&.bg01b {
		}
	}
	&.bg02 {
		background: @color-gray07;
	}
	&.pad03 {
		padding: 20px 0 29px 0;
	}
	&.pad04 {
		padding: 40px 0 40px 0;
		@media @media-mobile {
			padding: 20px 0;
		}
	}

	#content > &:first-child {
		border-top: 1px solid @color-gray-lightest;
	}
	&.content {
		.clear {
			margin: 50px 0 0 0;
		}
		H2 {
			font-size: 46px;
			font-weight: normal;
			+ P {
				margin: 1.5em 0 0 0;
			}
		}
		H3 {
			font-size: 28px;
		}
		H2, H3 {
			&:first-child {
				margin: 0 !important;
			}
		}
		H4 {
			font-size: 22px;
		}
		H3, H4 {
			+ P {
				margin: .5em 0 0 0;
			}
		}
		P {
			margin: 1.5em 0 0 0;
			&.lead {
				font-size: 20px;
				font-weight: bold;
				&.lead02 {
					font-size: 40px;
					font-weight: 100;
					color: @color02;
					font-family: @font-heading;
					line-height: 46px;
					.ru & {
						font-family: @font-heading-ru;
					}
				}
				&.lead03 {
					font-size: 30px;
					font-weight: normal;
					@media @media-mobile {
						font-size: 20px;
					}
				}
				&:first-child {
					margin: 0
				}
			}
			&.type02 {
				font-size: 20px;
				color: @color-gray-medium;
				line-height: 1.5;
			}
			&.type03 {
				font-size: 300%;
				margin: .5em 0 0 !important;
				line-height: 1;
				@media @media-tablet {
					font-size: 200%;
					margin: .5em 0 0 !important;
					line-height: 3;
				}
			}
			+ UL {
				margin: 2em 0 0 0;
			}
			&.price01 + P {
				margin: 0;
			}
		}
		.col.w50p {
			padding-left: 20px;
			&:first-child {
				padding-left: 0;
				padding-right: 20px;
			}
			&.f-right:first-child {
				padding-left: 20px;
				padding-right: 0;
				margin-right: 1%;
				+ .col.w50p {
					padding-right: 20px;
					padding-left: 0;
					margin-right: 0;
				}
			}
		}
	}
	&.type02 {
		background: @color01;
		color: @color-white;
		* {
			color: @color-white;
		}
		&.content A {
			color: @color-white;
		}
		A.btn,
		INPUT.btn {
			color: @color-black;
			&.main {
				border-color: @color-white;
				background: none;
				color: @color-white;
				&:hover {
					border-color: @color02;
					color: @color02;
				}
			}
			&.green {
				background: @color-white;
				color: @color01 !important;
				&:hover {
					background: fade(@color-white, 80%);
				}
			}
			&.discrete {
				color: @color-white;
				border-color: @color-white;
				&:hover {
					color: @color02;
					border-color: @color02;
				}
			}
		}
		TABLE {
			TR > * {
				border-color: @color-white;
			}
			TH {
				background: fade(@color-white, 20%);
				color: @color-white;
			}
			TR:hover TD {
				background: fade(@color-white, 10%);
			}
		}
	}
	&.type03 {
		background: lighten(@color-gray-lightest, 5%);
		H2 {
			color: @color02;
		}
		&.type03b H3 {
			color: @color02;
		}
		& + & {
			margin: 80px 0 0;
		}
		&.type03c {
			H3 {
				margin: 2em 0 0 0;
			}
			P {
				line-height: 25px;
				font-size: 15px;
				margin: 1.5em 0 0 0;
			}
		}
		&.type03d {
			background: lighten(@color-gray-lightest, 5%) 100% 50% no-repeat;
			background-size: auto 100%;
			@media @media-mobile {
				background-image: none !important;
				> IMG {
					margin-top: -30px;
					+ * {
						margin-top: 20px;
					}
				}
			}
		}
	}
	&.type04 {
		padding: 20px 0;
		&.content .clear {
			margin: 1em 0 0 0;
		}
	}
	&.type05, &.type06 {
		background: @color-white;
		padding: 40px 0;
		> .col00 > *:first-child {
			margin-top: 0;
		}
	}
	&.type07 {
		background: @color-gray-lightest;
	}
	&.type05 {
		background: lighten(@color-gray-lightest, 5%);
	}
	&.type06 {
		background: @color-gray-lightest;
	}
	&.size02 {
		P, UL {
			font-size: 20px;
		}
	}
	&.campaign01 {
		border-bottom: 2px solid;
		background: no-repeat 100% 100%;
		border-bottom-color: @color02
	}
	&.campaign02 {
		DIV.prices {
			P.price01 {
				color: @color02;
				font-weight: bold !important;
				&.price01c {
					color: @color-black;
					A.help { /* top: 0.1em;*/
						top: 0;
					}
				}
			}
			P.price02 {
				font-weight: bold;
			}
		}
	}
	DIV.block01b *:first-child {
		margin: 0;
	}
	.index & {
		DIV.col.w50p > H2 {
			font-size: 46px;
			margin: 0 !important;
		}
	}
	@media @media-mobile {
		&.bg01 {
			padding-bottom: 30px;
			padding-top: 30px;
			.col.w50p > H2 {
				font-size: 25px;
				&:first-child {
					margin-top: 0 !important;
				}
			}
		}

		&.content,
		&.pad {
			padding: 30px 0;
		}

		&.type04 {
			padding: 10px 0;
		}

		&.mobile-stripetop {
			border-top: 2px solid @color02;
		}

		&.content {
			.clear {
				margin: 0;
			}
			.col.w50p {
				padding-left: 0;
				padding-top: 10px;
				&:first-child {
					padding-right: 0;
				}
			}
			H2 {
				font-size: 35px;
			}
			H3 {
				font-size: 25px;
			}
			P,
			H2 + P {
				margin: .5em 0 0 0;
			}
			P.lead.lead02 {
				font-size: 30px;
				line-height: 1.4;
			}
		}

		.index & DIV.col.w50p > H2 {
			font-size: 35px;
		}

		&.campaign01 {
			background: none !important;
		}

		&.campaign02 {
			H1 {
				padding-right: 20px;
				margin-right: -20px;
				.ru & {
					padding-right: 20px;
				}
				SPAN {
					text-indent: -10000px;
					&:before {
						text-indent: 0;
						right: 20px;
					}
				}
			}
		}

		&.type03 {
			& + & {
				margin: 30px 0 0;
			}
		}

		P,
		UL {
			font-size: 17px;
		}

		.block01.expandable {
			P {
				font-size: 14px;

			}
			H2.blockheading {
				font-size: 16px !important;
			}
		}

		&.size02 {
			P, UL {
				font-size: 14px;
			}
		}
	}

	@media @media-tablet {
		&.content {
			padding: 30px 0;
			.clear {
				margin: 20px 0 0 0;
				&:first-child {
					margin: 0;
				}
			}
		}

		&.type03.type03b DIV.col.w50p.mobile-hide {
			width: 50% !important;
			margin: 0 !important;
			padding: 0 !important;
			IMG {
				margin-left: 20px;
			}
			&:first-child IMG {
				margin-left: -20px;
			}
		}
	}
	@media @media-mobile {
		&.content {
			.col {
				padding-left: 0 !important;
				padding-right: 0 !important;
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}
}

/* ///// CONTENT BLOCKS //// */

/* Generic block */
.exampleblock01 {
	padding: 20px 18px;
	border: 1px solid @color-gray-light;
	margin: 1em 0 0 0;
	background: @color-white;
	& > *:first-child {
		margin-top: 0;
	}
	H2 {
		font-size: 24px;
	}
}

.block01 {
	margin: 1em 0 0 0;
	padding: 20px 18px;
	border: 1px solid @color-gray-medium-lightest;
	clear: both;
	& > *:first-child {
		margin-top: 0;
	}
	& > P.img-left {
		margin-top: 1.2em;
		&:first-child {
			&, & + P {
				margin-top: 0
			}
		}
	}
	DIV.items09 & {
		.flex-grow(2);
	}
	& > .blockheading:first-child {
		margin: -20px -18px 0 -18px;
	}
	.scroll-to-fixed-fixed & {
		background: @color-white;
		margin: 0;
	}
	&.scroll-to-fixed-fixed {
		margin-top: 0;
	}
	&.nopadding {
		padding: 0;
	}
	&.disabled {
		opacity: 0.5;
	}
	&.expandable {
		padding: 10px 20px;
		/*z-index: 2;*/
		position: relative; /*border: 2px solid lighten(@color-gray-lightest,5%);*/
		border: 2px solid @color-gray-medium-lightest;
		.link-outline-none;
		.blockheading {
			/*background: lighten(@color-gray-lightest,5%);*/
			background: @color-white;
			SECTION.type03 & {
				background: none !important;;
			}
		}
		&.open {
			border: 2px solid @color12;
			/*z-index: 3;*/
			.blockheading {
				background: none;
				border: none;
			}
		}
		+ DIV.block01.expandable {
			margin: 2px 0 0 0;
		}
		.blockheading {
			margin: -10px -20px -10px -20px !important;
			border-bottom: none;
			.open& {
				margin: -10px -20px 0 -20px !important;
			}
			A.toggler {
				float: none;
				display: block;
				color: @color-black;
				margin: -15px -20px;
				padding: 15px 20px;
				&.f-right {
					float: right !important;
				}
				&:before {
					margin-top: 10px;
					margin-right: 10px;
					font-size: 16px;
				}
				SPAN.col-a {
					float: left;
					min-width: 0;
					margin-right: 10px;
					@media @media-notdesktop {
						min-width: 0;
						margin-right: 10px;
						SMALL {
							display: block;
						}
					}
				}
				SPAN.col-b {
					float: right;
					min-width: 0;
					margin-right: 10px;
					width: 265px;
					@media @media-notdesktop {
						min-width: 0;
						margin-right: 10px;
						SMALL {
							display: block;
						}
					}
					@media @media-mobile {
						width: auto;
						display: block;
						float: none;
					}
				}
			}
		}
	}
	P.lead {
		font-weight: bold;
		font-size: 15px;
		margin: 0.5em 0 0 0;
	}
	DIV.block01a {
		margin: -.5em 0 0;
		font-size: 14px;
		UL.checkboxes {
			clear: none;
			margin: 0;
			color: @color01;
			padding: 0;
			LI INPUT {
				top: 2px;
			}
		}
	}
	DIV.block01b {
		.clearfix();
		&,
		.closed& {
			margin: 0;
			height: 0;
			overflow: hidden;
		}
		.open& {
			display: block;
			margin: 1em 0 0 0;
			padding: 0 0 20px 0;
			height: auto;
			overflow: visible;
		}
		UL {
			padding: 0;
		}
		H2 + DIV.block01.margneg {
			margin: 10px -20px 0 -20px;
		}
		DIV.block01.margneg {
			margin: -10px -20px 0 -20px;
		}
		UL.cart01 {
			border-bottom: 1px solid @color-gray-medium-lightest;
		}
		& > H2,
		& > H3,
		& > H4,
		& > H5,
		& > H6,
		& > P {
			&:first-child {
				margin: 0;
			}
		}
	}
	DIV.block01a,
	DIV.block01b {
		UL {
			padding: .3em 0 0;
			margin: 0;
		}
	}
	DIV.block01c {
		background: @color-gray-medium-lightest;
		padding: 20px;
		&.margneg {
			margin-top: 0;
			margin-bottom: -20px;
		}
	}
	DIV.block01d {
		background: @color-white;
		padding: 20px;
	}
	&.bg02 {
		margin: .5em 0 0 0;
		P.big {
			margin: 1em 0 0 0;
			font-weight: bold;
			font-size: 20px;
			color: @color02;
		}
		H2.ico-question {
			background: url(ico_question.png) no-repeat;
			padding: 5px 0 5px 40px;
			font-size: 20px;
		}
		&.ico-hourglass {
			background-image: url(ico_hourglass.png);
			background-repeat: no-repeat;
			background-position: 20px 20px;
			padding-left: 80px;
		}
	}
	&.bg03 {
		background: lighten(@color-gray-lightest, 5%);
		H2.blockheading {
			background: none;
		}
	}
	DIV.margneg {
		+ H2 {
			margin: 0;
		}
		margin: -20px -18px 0 -18px;
		.clearfix();
		&:first-child:last-child {
			margin: -20px -18px;
		}

	}
	.blockheading {
		margin: 0 -18px 0 -18px;
		padding: 15px 20px;
		line-height: normal;
		font-size: 24px !important;
		font-family: @font-main;
		background: @color-white;
		border-bottom: 1px solid @color-gray-medium-lightest;
		font-weight: normal;
		H2 {
			margin: 0;
		}
		SPAN.small,
		P.small {
			font-size: 15px !important;
			font-family: @font-main;
			color: @color-black;
		}
	}
	.blockheading01 {
		line-height: 1.5;
		font-size: 14px !important;
		padding: 10px 20px;
	}
	H2.blockheading {
		color: @color-black;
	}
	&.type02 {
		border-color: @color-status01-border;
		.blockheading {
			background: @color-white;
			color: @color02;
			&:before {
				float: left;
				content: "S";
				width: 16px;
				height: 16px;
				background: @color02;
				color: @color-white;
				margin-right: 15px;
				text-align: center;
				line-height: 16px;
				font-weight: bold;
				font-size: 11px;
			}
		}
	}
	&.type03 {
		border-color: @color-gray-medium-lightest;
		&.expandable {
			&.open {
				border-color: @color-gray-medium-lightest;
			}
		}
		.blockheading {
			padding: 0 0 0 40px;
			margin: 0 !important;
			.box-sizing(border-box);
			.display(flex);
			.align-self(center);
			.open& {
				margin: 0 !important;
			}
			background: lighten(@color-gray-lightest, 5%);
			A.toggler {
				float: none;
				display: inline-block;
				color: @color01;
				margin: 0;
				padding: 0;
				font-weight: bold;
				&:before {
					position: absolute;
					right: 0;
					top: 18px;
				}
				&.f-right {
					float: right !important;
				}
				SPAN.small {
					color: @color01;
					display: inline-block;
				}
			}
			SPAN.span01 {
				position: absolute;
				left: 10px;
				top: 15px;
				font-size: 15px;
				width: 28px;
				height: 28px;
				.rounded(50%);
				.box-sizing(border-box);
				text-align: center;
				line-height: 28px;
				background: @color-positive;
				border-color: @color-positive;
				text-indent: -10000px;
				display: inline-block;
				&:before {
					font-family: "icons";
					content: @ico-check;
					color: @color-white;
					text-indent: 0;
					float: left;
					width: 100%;
					font-size: 12px;
				}
			}
			SPAN.span02 {
				position: absolute;
				left: 10px;
				top: 15px;
				color: @color-white;
				font-size: 18px;
				font-weight: bold;
				width: 28px;
				height: 28px;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				background: @color-msg-negative;
				border: 2px solid @color-msg-negative;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				text-align: center;
				line-height: 24px;
			}

		}
		DIV.block01b {
			DIV.col.w33p {
				margin-left: 1%;
			}
			UL.checkboxes {
				padding-right: 20px;
			}

		}
		DIV.block01e {
			padding-bottom: 8px;
			position: relative;
			DIV.col.w33p {
				margin: 0 0 0 1%;
				.display(flex);
				.align-self(center);

			}
		}
	}
	A.toggler {
		float: right;
		text-decoration: none;
		color: @color-gray-dark;
		cursor: pointer;
		&:before {
			content: @ico-arrdown;
			font-family: "icons";
			float: right;
			width: 14px;
			height: 10px;
			margin-top: .5em;
			.open& {
				content: @ico-arrup;
			}
			.closed& {
				content: @ico-arrdown;
			}
		}
	}
	DIV.content {
		font-size: 13px;
		P {
			margin: .5em 0 0 0;
		}
	}
	@media @media-tablet {
		&.bg02 {
			margin-left: 0 !important;
		}
	}
	@media @media-mobile {
		padding: 10px 8px;
		TABLE.footable & {
			margin: 0 -10px;
		}

		&.expandable,
		.blockheading01,
		&.expandable.open .blockheading,
		.blockheading,
		& > .blockheading:first-child,
		&.expandable .blockheading A.toggler {
			padding-left: 8px;
			padding-right: 8px;
		}

		DIV.block01b H2 + DIV.block01.margneg,
		DIV.block01b DIV.block01.margneg,
		&.expandable .blockheading,
		&.expandable .blockheading A.toggler {
			margin-left: -8px !important;
			margin-right: -8px !important;
		}

		DIV.margneg,
		DIV.margneg:first-child:last-child,
		.blockheading,
		& > .blockheading:first-child,
		&.expandable.open .blockheading {
			margin-left: -8px !important;
			margin-right: -8px !important;
			margin-top: -10px !important;
		}

		DIV.margneg {
			margin-top: -10px;
		}

		DIV.margneg:first-child:last-child {
			margin-bottom: -8px;
		}

		DIV.block01c {
			padding: 10px 8px;
			&.margneg {
				margin-bottom: -10px;
			}
		}

		DIV.block01d {
			padding: 10px;
			margin: 0 -8px;
		}

		&.scroll-to-fixed-fixed {
			position: static !important;
			+ DIV {
				display: none !important;
			}
		}

		&.type03 {
			& > .blockheading:first-child,
			&.expandable.open .blockheading {
				margin: 0 !important;
				padding: 0 0 0 30px;
			}
			.blockheading {
				margin: 0 !important;
				padding: 0 0 0 50px !important;
				SPAN.span01, SPAN.span02 {
					top: 3px;
				}
				A.toggler {
					.open& {
						margin: 0;
					}
					&:before {
						position: absolute;
						right: 0;
						top: 4px;
					}
				}
				SPAN.span01 {
				}
			}
			DIV.block01b {
				DIV.col.w33p {
					padding-bottom: 30px;
					.checkboxes.mt2em {
						margin-top: 0 !important;
					}
				}
			}
		}
	}
}

/* Column headings and footers */

UL.col_heading,
UL.col_footer {
	min-height: 37px;
	height: auto !important;
	margin: 0 !important;
	padding: 0;
	font-family: @font-main;
	font-size: 16px;
	color: @color-gray-dark;
	border: solid @color-gray-medium-lightest;
	border-width: 0 0 1px 0;
	background: lighten(@color-gray-lightest, 5%);
	LI {
		float: left;
		margin: 0;
		padding: 0;
		background: none;
		&:before {
			display: none;
		}
		&.f-right {
			float: right;
			position: relative;
			z-index: 2;
		}
		&.title {
			padding: 10px 0 0 20px !important;
			width: 200px;
			SPAN.note {
				font-size: 10px;
				color: @color-gray-medium;
			}
			.block01 & {
				width: auto;
			}
		}
		&.action {
			float: right;
			A {
				display: block;
				height: 17px;
				padding: 9px 16px 11px 25px;
				background: @color-white url(old/ico_back_arrow01a.png) no-repeat;
				font-weight: bold;
				font-size: 13px;
			}
		}
		&.action02 A {
			display: block;
			height: 17px;
			padding: 12px 16px 8px 25px;
			font-weight: bold;
			font-size: 13px;
		}
	}
	A.btn {
		margin: 3px 0 0 3px;
	}
	.block01.nopadding & {
		border-bottom: none;
		border-top: none;
		margin: 0 !important;
		overflow: hidden;
		border-radius: 10px;
	}

	@media @media-mobile {
		.clearfix();
		LI.action02 {
			A {
				height: auto;
				padding: 10px 10px 10px 10px
			}
			&.f-right {
				float: none;
			}
		}

		LI.title {
			padding: 10px 10px 9px 10px !important;
		}
	}
}

UL.col_footer {
	border-top: solid @color-gray-medium-lightest;
	border-width: 1px 0 0 0;
	.block01 & {
		margin: 1em 0 0 !important;
		border-right: none;
	}
}

UL.col_heading {
	border: solid @color-gray-medium-lightest;
	border-width: 1px 0;
	P {
		float: left;
		margin: -.2em 10px 0 0;
	}
	LI {
		&.nofloat {
			float: none !important;
		}
		&.search {
			margin: 3px 8px 0 0;
		}
	}
	P.clientview {
		float: left;
		margin: -5px 10px -9px 0;
		A {
			float: left;
			padding: 4px 0 4px 25px;
			background: url(old/imbi21.png) -6px 6px no-repeat;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
			&.active {
				background-position: 0 -46px;
			}
		}
	}
	.block01 & {
		margin: 0 0 10px !important;
		.clearfix();
		border-right: none;
	}
}

/* Banner block */

DIV.block02 {
	position: relative;
	margin: 20px 0 0 0;
	background: @color-white;
	overflow: hidden;
	.a {
		overflow: hidden;
		.rounded(3px);
		border: 1px solid @color-gray-medium-lightest;
		width: auto;
		&.noborder {
			.rounded(0);
			border: none;
		}
		& > IMG,
		& > A IMG {
			display: block;
		}
		& > P {
			margin: 0;
			& > IMG,
			& > A > IMG {
				float: left;
			}
		}
		UL {
			LI {
				background: url(old/dot01a.png) no-repeat 0 .7em;
			}
			&.type02 LI {
				background: url(old/dot20b.png) no-repeat 0 .7em
			}
		}
		.pad {
			UL {
				LI {
					background: url(old/dot01.png) no-repeat 0 .7em
				}
				&.type02 LI {
					background: url(old/dot20.png) no-repeat 0 .7em
				}
			}
		}
		UL.actions01 LI {
			background: none;
		}
		.fs12 {
			font-size: 12px !important;
		}
		.fs14 {
			font-size: 14px !important;
		}
		.fs16 {
			font-size: 16px !important;
		}
		.fs18 {
			font-size: 18px !important;
		}
		.fs20 {
			font-size: 20px !important;
		}
		.fs22 {
			font-size: 22px !important;
		}
		.fs24 {
			font-size: 24px !important;
		}
		.fs26 {
			font-size: 26px !important;
		}
		.fs28 {
			font-size: 28px !important;
		}
		.fs30 {
			font-size: 30px !important;
		}
		.fs36 {
			font-size: 36px !important;
		}
		.fs42 {
			font-size: 42px !important;
		}
		.fs48 {
			font-size: 48px !important;
		}
		.fs52 {
			font-size: 52px !important;
		}
		.fs60 {
			font-size: 60px !important;
		}
	}
	.b {
		padding: 0 0 20px;
		zoom: 1;
		.clearfix();
		IMG {
			max-width: 100%;
		}
		& > P:first-child {
			margin: 1em 0 0 0;
		}
	}
	.c {
		padding: 0 20px 20px;
		margin: 0 -20px;
		background: lighten(@color-gray-lightest, 5%);
		border: 1px @color-gray-medium-lightest;
		border-style: solid none;
		&:first-child {
			border-style: none none solid;
		}
	}
	.mint-banner {
		background: url(old/et/bg_mint.jpg) no-repeat;
		height: 323px;
		padding: 0 275px 0 35px;
		overflow: hidden;
		P {
			color: @color-white !important;
			margin: 10px 80px 0 0 !important;
			&.title {
				font-size: 44px !important;
				line-height: 1;
				margin: 40px 0 10px 0 !important;
				color: @color-white !important;
			}
		}
		.ru & {
			background: url(old/ru/bg_mint.jpg) no-repeat;
			P.title {
				font-size: 32px !important;
				font-weight: bold;
				margin: 25px 0 10px 0 !important;
			}
			UL.actions01 SPAN {
				font-size: 14px !important;
			}
		}
	}
	.elisa-banner {
		background: url(old/bg88b.gif) repeat-y;
		padding: 0 35px 35px 35px;
		color: @color-white;
		overflow: hidden;
		P {
			color: @color-white;
			&.title {
				font-size: 24px;
				margin: 30px 0 10px 0;
				color: @color-white;
			}
		}
	}
	.elisa-banner,
	.mint-banner {
		TH {
			background: rgba(255, 255, 255, 0.3);
			color: @color-white;
			border-top: none !important;
			border-bottom: 1px solid @color-white;
		}
	}
	.elisa-banner,
	.mint-banner {
		TD {
			background: none;
			color: @color-white;
			border-bottom: 1px solid @color-white;
		}
	}
	.pad {
		background: @color-white;
		padding: 0 35px 35px 35px;
		color: @color-black;
		overflow: hidden;
		P {
			color: @color-black;
			&.title {
				font-size: 24px;
				margin: 30px 0 10px 0;
				color: @color01;
			}
		}
	}
	P {
		margin: .5em 0 0 0;
		&.img-right {
			margin: 1em 0 0 1em;
		}
		&.title {
			color: @color-gray-dark;
			margin: 0;
			&.mt2em {
				margin: 2em 0 0 0;
			}
		}
		&.mt0 {
			margin-top: 0;
		}
	}
}

/* TODO: remove those permanently */
/*DIV.banner01 { text-align: center; margin: 30px 0 0 0; }*/

/* Services listing */

DIV.block03 {
	margin: 2em 0 0 0;
	overflow: hidden;
	DIV.img {
		float: left;
		width: 100px;
		margin: .3em 0 0;
		overflow: hidden;
		IMG {
			max-width: 100%;
			height: auto;
		}
	}
	DIV.txt {
		padding: 0 15px 0 120px;
		&:first-child {
			padding: 0 15px 0 0;
		}
		&:before {
			display: none;
			content: "";
			height: 40px;
			margin-right: -16px;
			background: @color-white;
			border-top: 1px solid @color-gray-light;
		}
		DIV.col.w50p & {
			border-right: 1px solid @color-gray-light;
		}
		DIV.col.w50p:last-child & {
			border-right: none;
			padding-right: 0;
		}
	}
	&.noborder {
		DIV.txt:before {
			display: none;
		}
		DIV.img {
			padding-top: 0;
		}
	}
	H1 + {
		DIV.txt:before {
			display: none;
		}
		DIV.img {
			padding-top: 0;
		}
	}
	P {
		margin: .5em 0 0 0;
		&.title {
			margin: 0;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.2;
			A {
				text-decoration: none;
				color: @color01;
				&:hover {
					text-decoration: none;
					color: @color03;
				}
			}
		}
	}
}

/* Searchform to use in tabs and colheading */
UL LI.search {
	margin: 3px 8px 0 0;
	border: 1px solid @color-gray-medium-lightest;
	background: @color-white;
	width: 228px;
	height: 28px;
	padding: 0;
	position: relative;
	&:before {
		display: none;
	}
	FORM {
		width: 100%;
		margin: 0;
		padding: 0 5px;
		.box-sizing(border-box);
		height: 50px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	INPUT {
		border: none !important;
		background: none !important;
		border-radius: 0;
		.shadow-remove();
		width: 185px;
		float: left;
		padding: 0 0 0 9px;
		font-size: 15px;
		margin: 0;
		-webkit-appearance: none;
		height: 29px;
		line-height: 29px;
	}

	SPAN {
		float: right;
		INPUT {
			border-radius: 0;
			margin: 0 !important;
			width: 33px !important;
			padding: 0;
			outline: 0;
		}
	}
	&.w100p {
		width: auto !important;
		position: relative;
		height: 35px;
		margin: 3px 0 0;
		FORM {
			padding: 0;
			margin: 0 -1px;
			width: auto;
		}
		INPUT {
			width: 100% !important;
			height: 35px;
			line-height: 35px;
			padding-left: 10px;
			padding-right: 10px;
			.box-sizing(border-box);
			margin-top: 0;
		}
		SPAN {
			position: absolute;
			right: 0;
			top: 3px;
			width: 33px;
			INPUT {
				border-radius: 0;
				margin: 0 !important;
				width: 33px !important;
				padding: 0;
				outline: 0;
				height: auto;
			}
		}
	}
	.imbi & {
		width: 260px;
		FORM {
			padding: 0;
			margin: 0 -1px;
			width: auto;
			&#imbisearch .ui-autocomplete {
				border-top: none;
			}
		}
		INPUT {
			width: 100%;
			padding: 5px 10px;
			.box-sizing(border-box);
		}
		SPAN {
			position: absolute;
			right: 0;
			top: 0;
			INPUT {
				padding: 0;
			}
		}

	}
	@media @media-mobile {
		width: 100%;
		height: auto;
		padding: 5px 5px 5px 8px; .box-sizing(border-box);
		border-style: solid none none;
		margin: 0 !important;
		&.w100p {
			margin: .5em -8px 0 !important;
			border-bottom-style: solid;
		}

		.imbi & {
			width: 100%;
			height: 30px;
			INPUT {
				width: 100%;
			}
			SPAN.submit {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}

/* News listing */

UL.news {
	padding: 0;
	margin: 1em 0 0 0;
	line-height: 16px;
	LI {
		float: left;
		width: 100%;
		padding: 0;
		margin: 1em 0 0 0;
		&:before {
			display: none;
		}
		&:first-child,
		& *:first-child {
			margin: 0;
		}
		P.heading {
			margin: 0;
			padding: 0 0 4px 0;
			A {
				font-weight: bold;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		P.lead {
			margin: .5em 0 0 0;
			padding: 0 0 15px 0;
		}
	}
	DIV.img {
		width: 20%;
		float: left;
		+ DIV.txt {
			padding-left: 22%;
		}
	}
	P.meta {
		margin: -3px 0 0 0;
		color: @color-gray-medium;
		font-size: 13px;
	}
	@media @media-mobile {
		DIV.img {
			display: none;
			+ DIV.txt {
				padding-left: 0;
			}
		}
	}
}

UL.news02 {
	margin: 1em 0 0 0;
	padding: 0;
	font-size: 14px;
	LI {
		background: none;
		padding: 0;
		overflow: hidden;
		margin: 1em 0 0 0;
		&:before {
			display: none;
		}
	}
	A {
		color: @color-gray-darkest;
		text-decoration: none;
		display: block;
		&:hover {
			color: @color02;
			text-decoration: none;
		}
	}
	SPAN.date {
		float: left;
		width: 85px;
		color: @color-gray-medium;
	}
	DIV.txt {
		padding-left: 85px;
	}
}

.news03 {
	DIV.item {
		.clearfix();
		font-size: 16px;
		margin: 4em 0 0 0;
		line-height: 1.75;
		.img {
			width: 45%;
			float: right;
		}
		.text {
			width: 45%;
			float: left;
		}
		&:nth-child(even) {
			.img {
				float: left;
			}
			.text {
				float: right;
			}
		}
		&:last-child {
			background: none;
		}
		H3 {
			color: @color02;
		}
		P {
			line-height: inherit;
		}
		@media @media-mobile {
			.img,
			.text {
				width: 100%;
				float: none;
			}
		}
	}
}

/* TODO: remove permanently used in _support.less */


/* Blog */

/* TODO: remove permanently */
/*
DIV.bloglistitem {
	H2 {
		margin-top: 0;
		A { text-decoration: none; }
	}
	DIV.blog-text { width: 390px; margin: 0 15px 0 0; }
	DIV.blog-img {
		width: 290px; overflow: hidden; padding: 10px 0 0 0;
		IMG { width: 290px; }
	}
	P.meta {
		background: @color-gray-medium-lightest; padding: 7px 10px; color: @color-gray-medium-light; font-size: 11px; line-height: 14px; margin: 1em 0 0;
		A {
			color: @color-gray-medium-light;
			&:hover { color: #000; }
		}
	}
}
*/

/* TODO: Find out if this is in use */
P.sendtofriend01 {
	float: right;
	padding: 1.5em 0 0 0;
	margin: 0;
	A {
		float: left;
		background: url(old/ico_email.gif) 0 2px no-repeat;
		padding: 0 0 0 20px;
	}
	& + * {
		margin: 0;
	}
	@media @media-mobile {
		float: none;
		overflow: hidden;
		padding: 0;
	}
}

SPAN.fb_edge_comment_widget {
	left: -321px !important;
}

/* Comments */
UL.comments01 {
	padding: 0 0 1px 0;
	margin: 2em 0 0 0;
	& + & {
		margin: 2em 0 0 0;
	}
	> LI {
		padding: 0 20px 20px 80px;
		position: relative;
		margin: 0;
		border-bottom: 1px solid @color-gray-light;
		background: none !important;
		&:before {
			display: none;
		}
		&:first-child {
			border-top: none;
		}
		.block01 &:last-child {
			border-bottom: none;
		}
		&.hover {
			background-color: lighten(@color-gray-lightest, 5%);
		}
		&.type01 {
			border: 2px solid @color-gray-lightest;
			background: @color-white !important;
			margin: 20px 0 0;
			&:before {
				display: block;
				position: absolute;
				left: 15px;
				top: 0.5em;
				content: "★";
				color: @color04;
				font-size: 1.5em;
				z-index: 2;
				line-height: 1;
			}
			&:first-child {
				border-top: 2px solid @color-gray-lightest;
				margin: 0;
			}
			P.heading01 {
				color: @color02;
				font-size: ceil(@base-font-size*1.5);
			}
		}
		P {
			margin: 10px 0 0 0;
			padding: 0;
			&.gray1 A {
				color: @color-gray-medium;
				&:hover {
					color: @color01;
				}
			}
			&.heading01 {
				margin: 15px 0 0 0;
				font-weight: bold;
			}
			&.note {
				margin: 0;
			}
		}
		SPAN.img {
			width: 50px;
			height: 50px;
			position: absolute;
			left: 20px;
			top: 1em;
			.rounded(50%);
			overflow: hidden;
			IMG {
				width: 100%;
				height: auto;
			}
		}
	}
}

/* TODO: Find out if this is in use */
DIV.emphazised {
	background: lighten(@color-gray-lightest, 5%);
	margin: 1em 0 0 0;
	border-left: 5px solid @color-gray-light;
	padding: 1em;
}

/* Search results listing */

OL.searchresults,
UL.searchresults01 {
	line-height: 1.5;
	LI {
		margin: 1em 0 0 0;
	}
	P {
		margin: 0;
		A {
			font-size: 20px;
			.ac & {
				font-size: 13px;
			}

		}
		&.lead {
			padding: 5px 0 10px 0;
		}
		&.meta {
			font-size: 80%;
			color: @color-gray-medium;
			A {
				font-size: 1em;
				color: @color-gray-medium;
			}
		}
	}
	B {
		color: @color01;
		font-weight: bold;
	}
}

OL.searchresults {
	P:first-child {
		display: inline;
	}
}

UL.searchresults01 {
	LI {
		P {
			&.title {
				font-size: 20px;
			}
			&.path {
				font-size: 11px;
				color: @color-gray-medium;
			}
		}
	}
}

/* Legend */
UL.legend01 {
	clear: both;
	.clearfix();
	border-bottom: 2px dotted @color-gray-medium-lightest;
	padding-bottom: 1em;
	margin: 0;
	H2 + & {
		margin-top: 1em;
	}
	&.noborder {
		border-bottom: none;
		padding-bottom: 0;
	}
	LI {
		float: left;
		margin: 0 3% .5em 0;
		padding: 0;
		background: none;
		font-size: 13px;
		color: @color-gray-medium;
		height: 26px;
		&:before {
			display: none;
		}
		&:before {
			display: none;
		}
	}
	+ TABLE TH {
		border-top: 2px solid transparent !important;
		margin-top: 0;
	}
	&.noborder + TABLE TH {
		border-top: 2px dotted @color-gray-medium-lightest !important;
	}
	.ico-cart:before {
		font-size: 20px;
		color: @color02;
	}
}

SPAN.legend-ico {
	float: left;
	&.legend-inline {
		float: none;
		&:before {
			position: relative;
			top: .3em;
			font-size: 15px;
			line-height: 15px
		}
	}
	&:before {
		content: "";
		display: inline-block;
		font-size: 11px;
		line-height: 14px;
		text-align: center;
		font-weight: bold;
		border: 1px solid @color-gray-medium-light;
		width: 16px;
		height: 16px;
		border-radius: 8px;
		.box-sizing(border-box);
	}
	&.legend01:before {
		content: "P";
		color: @color01;
		border-color: @color01;
	}
	&.legend02:before {
		content: "V";
		color: @color05;
		border-color: @color05;
	}
	&.legend03:before {
		content: "K";
		color: @color02;
		border-color: @color02;
	}
	&.legend04:before {
		content: "T";
		color: darken(@color03, 10%);
		border-color: darken(@color03, 10%);
	}
	&.legend05:before {
		content: "B";
		color: darken(@color05, 10%);
		border-color: darken(@color05, 10%);
	}
	&.legend06:before {
		content: @ico-mint;
		font-family: "icons";
		border: none;
		border-radius: 0;
		color: darken(@color-positive, 20%);
	}
	&.legend07:before {
		content: "A";
		color: @color03;
		border-color: @color03;
	}
	&.legend08:before {
		content: "?";
	}
	&.legend09:before {
		content: "";
		background: url(ico01.png) no-repeat -1px -3001px;
		border: 1px solid transparent;
		.rounded(0);
	}
	&.legend10:before {
		content: "A";
	}
	&.legend11,
	&.legend12,
	&.legend13,
	&.legend14,
	&.legend15,
	&.legend16,
	&.legend17,
	&.ico-cart {
		&:before {
			font-family: "icons";
			border: none;
			width: auto;
			line-height: 20px;
			height: auto;
			color: @color-gray-medium;
			font-size: 25px;
			font-weight: normal;
		}
	}
	&.ico-cart:before {
		color: @color02;
	}
	&.legend11:before {
		content: @ico-device-phone;
	}
	&.legend12:before {
		content: @ico-shuffle;
	}
	&.legend13:before {
		content: @ico-combo;
	}
	&.legend14:before {
		content: @ico-teller;
		line-height: 18px;
		font-size: 18px;
	}
	&.legend15:before {
		content: @ico-device-accessory;
		position: relative;
		top: -5px;
		line-height: 28px;
		font-size: 34px;
	}
	&.legend16:before {
		content: @ico-extraservice;
		font-size: 33px;
	}
	&.legend17:before {
		content: @ico-service-add;
		line-height: 22px;
	}
	&.ico-cart:before {
		position: relative;
		top: 5px;
	}
	+ SPAN.legend-ico {
		margin-left: 5px;
	}
	UL.legend01 & {
		margin-right: 5px;
	}
	TABLE & {
		height: 16px;
	}
}

DIV.legend01 {
	overflow: hidden;
	P {
		font-size: @base-font-size;
		&.img-left {
			IMG {
				max-width: 20px;
				height: auto;
			}
			+ P {
				margin: .8em 0 0;
				padding-left: 40px;
			}
		}
	}
	@media @media-mobile {
		P.img-left {
			float: left;
			margin: .3em 1em 1em 0;
		}
	}
}

SPAN.ico-fb {
	float: left;
	width: 18px;
	height: 18px;
	background: url(old/logo_facebook02.png) no-repeat;
}

SPAN.ico-google {
	float: left;
	width: 18px;
	height: 18px;
	background: url(old/logo_google02.png) no-repeat;
}

SPAN.ico-email {
	float: left;
	width: 18px;
	height: 18px;
	background: url(old/logo_email02.png) no-repeat;
}

SPAN.ico-fb,
SPAN.ico-google,
SPAN.ico-email {
	&:before {
		display: none;
	}
	&.inline {
		float: none;
		display: inline-block;
		margin: 0 .25em 0 0;
	}
}

/* Datepicker */

DIV.ui-datepicker {
	.zindex(@datepickerZindex,0)!important;
	border: 1px solid @color-gray-medium-lightest; border-radius: 3px; background: @color-white; overflow: hidden;
	DIV.ui-datepicker-header { margin: 0; font-size: 14px; padding: 10px; border-bottom: 1px solid @color-white; color: @color-white; background: @color01; overflow: hidden; }
	A.ui-datepicker-prev,
	A.ui-datepicker-next {
		.zindex(@datepickerZindex, -1);
		margin-top: .2em;
		color: @color-white;
		&:before {
			content: "";
			font-family: "icons";
			text-indent: 0;
			float: left;
		}
	}
	A.ui-datepicker-prev {
		float: left;
		&:before {
			content: @ico-arrleft;
		}
	}
	A.ui-datepicker-next {
		float: right;
		&:before {
			content: @ico-arrright;
		}
	}
	DIV.ui-datepicker-title {
		text-align: center;
	}
	TABLE {
		margin: 0;
	}
	TH,
	TD {
		padding: 5px;
	}
	TH {
		background: @color03;
		color: @color-white;
		border-radius: 0 !important;
	}
	TR {
		&:hover {
			& > TD { background: none; text-decoration: none; }
		}
		TD {
			background: @color-white;
			.ui-state-default {
				display: block;
				padding: 3px 7px;
				/*border-radius: 3px;*/
				/*border: 1px solid @color-white;*/
				color: @color-blue04;
				&:hover {
					border: none;
					background: none;
					text-decoration: none;
				}

				&.ui-state-highlight {
					/*border: 1px solid @color-gray-medium-lightest;*/
					border: none;
					color: @color-blue04;
					font-weight: 700;
					background: none;

				}
			}
			&:hover {
				background: @color-blue03;
				text-decoration: none;
			}
			&.disabled,
			&.disabled:hover {
				background: @color-gray07;
				color: @color-gray05;
				cursor: default;
				text-decoration: none;
			}
			&.ui-state-disabled {
				background: @color-gray07;
				color: @color-gray05;
				cursor: default;
				text-decoration: none;
			}
		}
	}
}

/* //// TOOLTIP //// */
.ask {
	display: inline-block;
	background: url(help01.png) no-repeat;
	width: 14px;
	height: 14px;
	margin-left: 5px;
	&:hover {
		text-decoration: none;
	}
	TD & { /*float: right;*/
		margin-top: .2em;
	}
	DIV.items & {
		margin-top: 1.4em;
	}
}

.ask02 {
	display: inline-block;
	background: url(help01.png) no-repeat 0 .3em;
	padding-left: 18px;
	min-height: 12px;
	margin-left: 5px;
	font-size: 15px;
	font-weight: normal;
}

/* //// IMBI //// */

/* Support */

DIV.support01 {
	overflow: hidden;
	padding: 0;
	margin: 0;
	float: left;
	height: 500px;
	min-height: 500px;
	width: 50%;
	DIV.support01a {
		height: 100%;
		float: left;
		width: 100%;
	}
	UL {
		float: left;
		width: 50%;
		height: 100%;
		overflow: hidden;
		padding: 10px 0;
		margin: 0;
		LI {
			float: left;
			width: 98%;
			margin: 0 1% 1% 1%;
			clear: both;
			padding: 0 !important;
			background: none;
			&:before {
				display: none;
			}
			A {
				position: relative;
				cursor: pointer;
				.shadow-remove();
				text-decoration: none;
				display: block;
				border: 1px solid @color-gray-medium-lightest;
				padding: 7px;
				.box-sizing(border-box);
				font-size: 15px;
				line-height: normal;
				margin: 0;
				color: @color-black;
				background: @color-gray-medium-lightest;
				&:hover {
					color: @color-white;
					background: @color01;
				}
				&.active {
					color: @color-white;
					border: 1px solid @color-gray-dark;
					background: @color-gray-dark;
					padding-right: 25px;
					&:after {
						position: absolute;
						right: 5px;
						top: 10px;
						float: right;
						content: @ico-arrright;
						font-family: "icons";
					}
					.ico-nochildren:after {
						display: none;
					}
					&:hover {
						background: @color-gray-medium;
					}
				}
			}
			&.internal {
				A {
					border-color: @color-status01-border;
					background: @color-status01;
					&:before {
						content: "S";
						display: inline-block;
						height: 16px;
						width: 16px;
						line-height: 16px;
						text-indent: 0;
						margin: 0 10px 0 0;
						float: left;
						background: @color02;
						color: @color-white;
						font-size: 11px;
						font-weight: bold;
						border-radius: 8px;
						text-align: center;
					}
					&:hover {
						border-color: @color02;
						color: @color02;
					}
					&.active {
						border: 1px solid @color-gray-dark;
						background: @color-gray-dark;
						&:hover {
							background: @color-gray-medium;
						}
					}
				}
			}
		}
		UL {
			display: none;
		}
		&.first {
			margin: 0;
		}
	}
	@media @media-mobile {
		width: 100%;
	}
}

DIV.support02 {
	width: 45%;
	float: right;
	padding: 0 0 20px 0;
	H2 {
		font-size: 28px;
		&:first-child {
			margin: .5em 0 0 0;
		}
	}
	@media @media-mobile {
		width: 100%;
		float: none;
		clear: both;
	}
}

#imbisearch .ui-autocomplete {
	z-index: 2;
	overflow: hidden;
	.rounded(0);
	LI {
		float: none;
		padding: 5px 10px;
		margin: 0;
		font-size: ceil(@base-font-size*1.15);
		&:before {
			display: none;
		}
		&.ui-state-focus {
			background: @color-gray-lightest;
			color: @color02;
			A {
				color: @color02;
				SPAN.meta01,
				SMALL {
					color: @color-gray-dark;
				}
			}
		}
		A {
			font-weight: bold;
			.clearfix();
			padding: 5px 10px;
			margin: -5px -10px;
			SPAN.meta01, SMALL {
				font-weight: normal;
				color: @color-gray-dark;
				font-size: 90%;
			}
			&:hover {
				text-decoration: none;
			}
		}
		SPAN.img {
			float: left;
			width: 60px;
			IMG {
				max-width: 100%;
				max-height: 87px;
				width: auto;
				height: auto;
			}
		}
		SPAN.txt {
			padding-left: 70px;
			display: block;
		}
	}
}

/*/////////////////// FORM RELATED ////////////////*/

/* Roaming country select */

#fakeselect {
	padding: 0;
	line-height: 14px;
	margin-top: 5px;
	padding-top: 5px;
	INPUT {
		float: left;
	}
	SELECT {
		float: left;
		margin: 0 5px 0 0;
	}
	P {
		float: left;
		margin: 0;
		padding: 3px 8px 0 0;
	}
	DIV {
		float: left;
	}
	&-current {
		border: 1px solid @color-gray-medium-light;
		.rounded(3px);
		cursor: pointer;
		background-position: 5px 50%;
		background-repeat: no-repeat;
		color: @color-black;
		-webkit-box-shadow: inset 1px 2px 5px 0 fade(@color-gray-medium, 47%);
		box-shadow: inset 1px 2px 5px 0 fade(@color-gray-medium, 47%);
		padding: 6px 5px 5px;
		font-size: 14px;
		DIV {
			width: 200px;
			background: url(old/bg16.gif) 100% 50% no-repeat;
			padding: 2px 5px 3px 25px;
		}
	}
	&-drop {
		position: absolute;
		left: 0;
		top: 0;
		border: solid @color-gray-medium-light;
		border-width: 0 1px 1px 1px;
		background: @color-white;
	}
	&-items {
		padding: 0;
		margin: 0;
		white-space: nowrap;
		height: 200px;
		width: 230px;
		overflow: auto;
		LI A {
			text-decoration: none;
			background-position: 5px 50%;
			background-repeat: no-repeat;
			color: @color-black;
			display: block;
			padding: 2px 10px 2px 25px;
			&.sel_hover {
				background-color: @color09;
				text-decoration: none;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
}

/* //// MINT BLOCKS //// */

/* TODO: Find out if this view is still in use: itb_dashboard_norights_ari.html */
DIV.mintblock05 {
	background: url(old/bg112.png) no-repeat 0 0;
	padding: 0 0 0 430px;
	min-height: 530px;
	@media @media-mobile {
		background: none;
		padding: 0;
		min-height: 0;
	}
}

DIV.mintblock06 {
	font-size: 20px;
	line-height: 31px;
	margin: 40px 0 0 0;
}

/* //// ITB MINT Arvutis //// */

.overlay {
	background: url(old/overlay.png);
	position: fixed;
	.zindex(@overlayZindex, 0);
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	.rounded(3px);
	.overlay-content-old {
		/*max-width: 610px;*/
		width: 610px;
		margin: 105px auto 0 auto;
		background: @color-white;
		padding: 20px;
		.rounded(3px);
	}
	.overlay-content-shadow {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	}
	/*NB use this when u need flex in modal*/
	.overlay-content {
		width: 610px;
		background: @color-white;
		.rounded(3px);
		height: 50vh;
		position: absolute;
		left: 50%;
		top: 50%;
		bottom: 0;
		.transform(translate(-50%, -50%));
		margin: 0;
		padding: 0;
		&.overlay-content--h70vh {
			height: 70vh;
			@media @media-mobile {
				height: 100vh;
			}
		}
		&.overlay-content--w782 {
			width: 782px;
			@media @media-mobile {
				width: 100%;
			}
		}
		.tb_box {
			width: 100%;
			max-width: 100%;
			.box-sizing(border-box);
			height: 100%;
			.tb_heading02 {
				background: none;
				display: block;
				margin: 0;
				padding: 25px 35px 25px;
				.tb_controls {
					right: 0;
				}
			}
			/*H1 {
				font-size: 25px;
				font-weight: 700;
				line-height: 34px;
				color: @color01;
				text-align: left;
				font-family: @font-main;
			}*/
			.tb_controls {
				top: 10px;
				right: 18px;
				height: 25px;
				width: 20px;
				position: absolute;
			}
			#tb_close {
				top: 0;
				right: 0;
				height: 25px;
				width: 20px;
				&:before {
					line-height: 25px;
				}
			}
			.tb_box_container {
				background: @color-white;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				.box-sizing(border-box);
				height: 50vh;
				.display(flex);
				.flex-direction(column);
				padding: 2px 0 2px 0;
				.rounded(3px);
				width: 100%;
				.justify-content(flex-end);
				position: relative;
				&--space-between {
					.justify-content(space-between);
				}
				&.pad01 {
					padding: 35px;
				}
				&.h100p {
					height: 100%;
				}
			}
			.iframe_tb_container {
				/*.display(flex);*/
				.flex-direction(column);
				overflow-y: auto;
				overflow-x: hidden;
				-webkit-overflow-scrolling: touch;
				IFRAME { /*position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: auto !important; height: auto !important;*/
					.flex(0 0 100%);
					max-height: 98%;
				}
				.flex (1 1 100%);
				position: relative;
				background: @color-white;
				.box-sizing(border-box);
				width: 100%;
			}
			.tb_box_end {
				.box-sizing(border-box);
				width: 100%;
				max-width: 100%;
				z-index: 10000;
				.display(flex);
				.flex(0 0 auto);
				background: @color-white;
				padding: 20px 15px;
				.justify-content(center);
				min-width: 100%;
				position: relative;
				&.tb_box_end--has-gradient {
					background: transparent;
					background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2 ), rgba(255, 255, 255, 0.0 ));
					position: absolute;
					bottom: 0;
					left: 0;
				}
				@media  @media-mobile {
					min-height: 220px;
				}
			}
			.tb_box_end01 {
				background: #fff;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 20px;
				padding: 20px 0;
			}
		}
		@media @media-mobile {
			height: 100%;
			width: 100%;
			.tb_box {
				margin: 0;
				.iframe_tb_container {
					IFRAME {
						max-height: 100%;
					}
				}
				.tb_box_container {
					height: 100%;
				}
			}
		}
		&.overlay-content-video {
			background: none;
			max-width: 1182px;
			width: 1182px;
			height: 69vh;
			.tb_box {
				.tb_box_container {
					background: none;
					height: 69vh;
					.iframe_tb_container {
						background: none;
						padding: 0;
					}
				}
				.tb_controls {
					top: -50px;
					right: 0;
					#tb_close {
						&::before {
							font-weight: bold;
							color: @color-white;
						}
					}
				}
			}
			@media @media-mobile {
				height: 100%;
				width: 100%;
				max-width: 100%;
				.tb_box {
					margin: 0;
					.tb_box_container {
						height: 100%;
					}
					.tb_controls {
						right: 18px;
						top: 10px;
						#tb_close {
							&::before {
								color: @color-gray04;
							}
						}
					}
				}
			}
		}
	}
}

/* Blocks */

.itbma_block01 {
	overflow: hidden;
	position: static;
	margin: 25px 0 0 0;
	padding: 0;
	border: none;
	.rounded(10px);
	background: url(old/bg111.png) 5px 0 repeat-y;
	& > DIV.pad {
		padding: 0 20px;
	}
	&.itbma_block01b {
		background: none;
	}
}

/* Columns */

.itbma_col03 {
	width: 10%;
	margin: 0 1% 0 0;
}

.itbma_col04 {
	&,
	.itbma_block01b & {
		width: 89%;
	}
	@media @media-mobile {
		.itbma_block01b & {
			float: none;
			clear: both;
			padding: 20px 0 0 0;
			padding-top: 20px;
			.box-sizing(border-box);
			width: 100% !important;
		}
	}
}

.step-nav {
	margin: 20px 0 0;
}

/* Confirmation */

/* TODO: Remove permanently */
/*.itbma-confirmation P.notification { margin: 0; padding: 32px 20px; color: #838383; font-style: italic; text-align: center; }*/
UL.confirmation-steps {
	margin: 2em 0 0 0;
	padding: 0;
	.tabcontent01 & {
		margin: -20px -20px 0;
	}
	LI.step {
		margin: 2em 0 0 0;
		padding: 0;
		background: none;
		border: none;
		&:before {
			display: none;
		}
		&:first-child {
			margin: 15px 0 0 0;
		}
		DIV.step-heading {
			overflow: hidden;
			H2 {
				margin: 0 !important;
				color: @color-black;
				font-size: 24px;
				line-height: normal;
				font-weight: normal;
				SPAN.span01:after {
					content: ". "
				}
			}
			P.hasico {
				float: right;
				position: relative;
				margin: 0;
			}
			UL.actions01.f-right {
				margin: -.9em 0 0 2em !important;
			}
		}
		H3 {
			font-weight: 500;
			font-size: 20px;
		}
		&.active DIV.step-heading H2 {
			color: @color-black;
		}
		&.disabled DIV.step-heading H2 {
			color: @color-gray-medium-light;
		}
		.step-content {
			margin: 20px 0 0 0;
		}
		H2 + DIV.block01.margneg {
			margin: 10px 0 0 0;
		}
		DIV.block01.margneg {
			margin: -10px 0 0 -0;
			&:last-child {
				margin: -10px 0 0 0;
			}
		}
		&.address H3 {
			margin: 25px 0 0 0;
		}
		&.confirmed .user-selection {
			color: @color-gray-medium;
			P {
				color: @color-gray-medium;
			}
			&.sim-selection {
				padding: 0;
				margin: 8px 0 0 0;
				P {
					margin: 0;
				}
			}
		}
		UL.banklinks {
			margin: .5em 0 0 0;
			LI {
				margin: .5em 20px 0 0;
			}
		}
	}
	@media @media-tablet {
		DIV.step-heading UL.actions01.f-right {
			float: right !important;
			margin-top: -0.5em !important;
		}
	}

	@media @media-mobile {
		margin: 0;
		LI.step {
			padding: 10px 0;
			DIV.step-heading {
				+ UL.actions01.f-right.margneg,
				UL.actions01.f-right {
					margin-top: 0 !important;
					float: none;
					clear: both;
					margin-left: 0 !important;
					.btn.edit {
						text-align: left;
					}
				}
				P.hasico {
					float: none;
					clear: both;
				}
			}
			H2 + DIV.block01.margneg {
				margin: 0 -10px 0 -10px;
			}
			DIV.block01.margneg {
				margin: -10px -10px 0 -10px;
				&:last-child {
					margin: -10px -10px -20px -10px;
				}
			}
		}

		P {
			margin: .5em 0 0 0;
		}

		UL.products02b {
			> LI {
				padding: 20px;
				SPAN.heading01:first-child {
					margin-top: 0;
				}
			}
		}
	}
}

UL.confirmation-steps02 {
	margin: 1em 0 0;
	padding: 0;
	LI.step {
		margin: 10px 0 0;
		padding: 25px;
		border: 2px solid lighten(@color-gray-lightest, 5%);
		background: lighten(@color-gray-lightest, 5%);
		&:first-child {
			margin: 0;
		}
		&:before {
			display: none;
		}
		DIV.step-heading {
			overflow: hidden;
			min-height: 30px;
			H2 {
				margin: 0 !important;
				color: @color-black;
				font-family: @font-main;
				font-size: 13px;
				font-weight: 700;
				line-height: 1.7;
				position: relative;
				padding-left: 42px;
				SPAN.span01 {
					position: absolute;
					left: 0;
					top: 0;
					font-size: 15px;
					width: 28px;
					height: 28px;
					.rounded(50%);
					background: @color-gray-medium-lightest;
					border: 2px solid @color-gray-medium-lightest;
					.box-sizing(border-box);
					text-align: center;
					line-height: 24px;
					margin-right: 15px;
				}
			}
			P {
				&.note {
					margin-top: 2em;
				}
			}
			UL.actions01.f-right {
				position: relative;
				margin: 0 !important;
				display: none;
				z-index: 2;
			}
			UL.checkboxes {
				margin-left: 40px;
				margin-top: 40px;
				clear: both;
			}
		}
		DIV.step-content {
			display: none;
			margin: 20px 0 0 0;
			padding-left: 40px;
			font-family: @font-main;
			font-size: 13px;
			font-weight: 400;
			&.readonly {
				P {
					font-family: @font-main;
					font-size: 13px;
					font-weight: 400;
				}
			}
			&.summary {
				display: block;
			}
		}
		H3 { font-size: 20px; }
		&.active {
			border-color: @color12;
			background: @color-white;
			DIV.step-heading H2 {
				SPAN.span01 {
					background: none;
					border-color: @color12;
				}
			}
			DIV.step-content {
				display: block;
				&.readonly {
					display: none;
				}
				&.readonly02 {
					display: block;
				}
			}
		}
		&.disabled {
			border: 1px solid @color-gray-medium-lightest;
			background: @color-white;
			DIV.step-heading H2 {
				SPAN.span01 {
					background: none;
					border-color: @color12;
				}
			}
			DIV.step-heading {
				/*H2 { color: @color-gray-medium; }*/
			}
		}
		&.na {
			background: lighten(@color-gray-lightest, 5%) url(bg08.png);
			DIV.step-heading {
				H2 {
					color: @color-gray-medium;
				}
			}
			DIV.step-content {
				display: block;
				font-style: italic;
				color: @color-gray-medium;
			}
		}
		&.confirmed {
			DIV.step-heading {
				H2 {
					display: inline-block;
					SPAN.span01 {
						background: @color-green01;
						border-color: @color-green01;
						text-indent: -10000px;
						&:before {
							font-family: "FontAwesome";
							content: '\f00c';
							color: @color-white;
							text-indent: 0;
							float: left;
							width: 100%;
							font-size: 15px;
						}
					}
				}
				A.link {
					font-family: @font-main;
					font-size: 13px;
					&:before {
						font-size: 12px;
					}
				}
				UL.actions01.f-right {
					display: block;
				}
			}
			DIV.step-content {
				display: block;
				&.editable {
					display: none;
				}
			}

		}
		UL.banklinks {
			margin: 20px 0 0 0;
		}
	}
	&.type02 {
		& > LI.step {
			display: none;
			border-style: none;
			padding: 0;
			&.active {
				display: block;
			}
			> DIV.step-heading {
				display: none;
			}
			> DIV.step-content {
				margin: 0;
				padding-left: 0;
			}
			> DIV.action02 {
				P {
					margin: 0;
				}
			}
		}
		.separated-right-bottom {
			padding: 0 25px 0 0;
			@media @media-mobile {
				padding: 0 0 30px 0;
			}
		}
	}
	&.confirmation-steps02b {
		.display(flex);
		.flex-direction(row); /*.justify-content(space-between);*/ /*.justify-content(flex-start);*/
		LI.step {
			.flex(1);
			margin: 0;
			.box-sizing(border-box);
			border: 1px @color-gray-light;
			border-style: none; /*border-style: none none none solid;*/ /*.flex(0 0 auto); width: 33.3333333333333%;*/
			&:first-child {
				border-left: none; /*padding-left: 0;*/
			}
			&:last-child {
				padding-right: 0;
			}
			DIV.step-heading H2 {
				font-size: 18px;
				font-weight: normal;
			}
			.display(flex);
			.flex-direction(column);
			.step-heading {
				.flex(1 0 auto);
			}
			.step-content {
				.flex(2 0 auto);
				margin: 0; /*height: 100%;*/
				.display(flex) !important;
				.flex-direction(row);
				.flex-wrap(wrap);
				> * {
					.flex(0 0 auto);
					width: 100%;
				}
			}
			/*UL.checkboxes03 { .align-self(flex-start); }*/
			UL.checkboxes03 {
				@media @media-tablet {
					LI {
						width: 100%
					}
				}
			}
			UL.banklinks {
				margin: .5em 0 0 0;
				LI {
					margin: .5em 20px 0 0;
				}
			}
			&.active {
				DIV.step-heading H2 {
					SPAN.span01 {
						background: @color01;
						border-color: @color01;
						color: @color-white;
					}
				}
			}
			&.disabled {

				DIV.step-heading H2 {
					SPAN.span01 {
						background: @color01;
						border-color: @color01;
						color: @color-white;
					}
				}
			}

		}
	}
	&.confirmation-steps02c {
		LI.step {
			border: none;
			padding: 25px 0;
			.step-content {
				A.toggler {
					text-decoration: none;
					cursor: pointer;
					display: inline-block;
					&:hover {
						text-decoration: none;
					}
					&:after {
						content: 'Peida';
					}
					&.open:after {
						content: 'Näita';
						padding-left: 10px;
					}
				}
				A.toggler02 {
					text-decoration: none;
					cursor: pointer;
					display: inline-block;
					&:hover {
						text-decoration: none;
					}
					&:before {
						content: @ico-search;
						font-family: "icons";
						width: 14px;
						height: 10px;
						padding: 0 5px;
					}
					&.open:before {
						content: @ico-cross;
						padding: 0 5px;
					}
				}
			}
		}
	}
	@media @media-notdesktop {
		&.type02 {
			LI.step {
				border: 2px solid @color-gray06;
				background: @color-gray07;
				margin: 0;
				&.active {
					border-style: solid none solid none;
					border-color: @color-blue02;
					background: @color-white;
					DIV.step-heading H2 {
						color: @color-gray01;
						SPAN.span01 {
							border-color: @color-blue02;
						}
					}
				}
				display: block;
				border-style: solid none none;
				padding: 10px 15px 20px;
				DIV.step-heading {
					display: block;
				}
				DIV.step-content {
					margin: 20px 0 0 0;
				}
				&.confirmed {
					padding-bottom: 10px;
					DIV.step-content {
						margin: 0;
					}
				}

			}
		}

		/*&.confirmation-steps02b {
			.display(block);
			LI.step {
				.display(block); .flex(1); margin: 0; .box-sizing(border-box); border: 1px @color-gray-light; border-style: solid none none none;
				&:first-child { border-top: none; padding-left: 15px; }
				&:last-child { padding-right: 0;}
			}

		}*/
	}
	@media @media-mobile {
		&.confirmation-steps02c {
			LI.step {
				padding: 10px 15px 20px;
			}
		}

		&.confirmation-steps02b {
			.flex-direction(column);
			LI.step {
				border: 1px @color-gray-light; /*border-style: none none none solid;*/
				.flex(0 0 auto);
				width: 100%;
			}
		}

		margin: 1em -15px 0;
		min-height: 0 !important;
		LI.step {
			&.nobg {
				border: none !important;
				background: none !important;
			}
			padding: 10px 15px 20px;
			DIV.step-heading {
				UL.actions01.f-right {
					float: right;
				}
			}
			DIV.step-content {
				padding-left: 0;
				UL.actions01 {
					margin-top: 1em;
				}
			}
			UL.banklinks LI {
				padding-top: 5px !important;
				&:first-child {
					margin-right: 2% !important;
				}
			}
			&.confirmed {
				DIV.step-heading {
					H2 {
						display: block;
					}
					/*> UL.actions01,
					DIV.form01 > UL.actions01 {
						float: none;
						display: block;
						.btn {
							float: left;
						}
					}*/
				}
			}

		}

	}
}

/* Product view */

.product-group {
	margin: 0;
	padding: 27px 19px 0 19px;
	border-top: 1px solid @color-gray-medium-lightest;

	&.first {
		margin: 0;
		padding: 20px 19px 0 19px;
		border: none;
	}
	LI.step & {
		border: 1px solid @color-gray-medium-lightest;
		margin: 1em 0 0 0;
		padding-bottom: 19px;
	}
	H2 {
		margin: 0;
		padding: 0 0 12px 0;
		color: @color01;
		+ UL.actions01.margneg {
			margin: -3.5em 0 0 0 !important;
		}
	}
	UL.actions01.f-right:first-child:last-child {
		padding-bottom: 7px !important;
	}
	@media @media-mobile {
		&,
		&.first {
			padding-left: 10px;
			padding-right: 10px;
		}

		H2 + UL.actions01.margneg {
			margin: 0 !important;
		}
	}
}

UL.products {
	margin: 0 0 0 0;
	padding: 0;
	.product-group & {
		margin: 0;
	}
	P.subtitle {
		margin: 10px 0 0 0;
		font-weight: bold;
		line-height: 17px;
	}
	& > LI {
		&:before {
			display: none;
		}
		margin: 1em 0 5px 0;
		border: 1px solid @color-gray-light;
		padding: 20px;
		.box-sizing(border-box);
		.product-group & {
			padding: 20px 0 24px 0;
			background: url(old/bg101.png) 0 top repeat-x;
			border: none;
		}
		H3.name A {
			text-decoration: none;
			color: @color-black;
			&:hover {
				color: @color02;
			}
		}
		&.type02 {
			border: 2px solid @color02;
			padding: 19px;
			H3.name A {
				display: block;
				background: @color02;
				padding: 10px 20px 10px 20px;
				color: @color-white;
				margin: 30px 0 0 -20px;
				position: relative;
				&:hover {
					text-decoration: none;
				}
				&:before {
					font-family: "icons";
					content: @ico-owl;
					font-weight: normal;
					line-height: 30px;
					position: absolute;
					color: @color02;
					right: 20px;
					bottom: 100%;
					font-size: 30px;
				}
			}
			DIV.price P STRONG {
				color: @color02;
			}
		}
	}
	&.products-b {
		& > LI {
			width: 50%;
			float: left;
			&:nth-of-type(odd) {
				clear: both;
			}
		}
		& > LI {
			border: none;
			padding: 0 10px;
			margin: 1em 0 0;
			&.type02 {
				padding: 0 10px;
				H3.name A {
					margin-left: -10px;
					padding-left: 10px;
				}
				.price {
					STRONG {
						color: @color02;
					}
				}
			}
			&.clear {
				margin: 0;
			}
		}
		LI {
			.overview {
				.img {
					width: 130px;
					A IMG {
						max-width: 130px;
						max-height: 260px;
					}
				}
				.general {
					padding-left: 155px;
				}
				.price {
					width: 100%;
					font-size: 16px;
					P {
						margin: .5em 0 0 0;
					}
					STRONG {
						font-size: 27px;
						line-height: 1;
						color: @color02;
					}
					SPAN.small {
						font-weight: bold;
					}

				}
			}
		}

	}
	LI {
		.overview {
			min-height: 155px;
			.img {
				width: 115px;
				margin: 0 1% 0 0;
				A {
					position: relative;
					display: block;
					text-align: center;
					SPAN {
						display: none;
						position: absolute;
						top: 50%;
						left: 50%;
						height: 100%;
						width: 100%;
						margin: -15px 0 0 -15px;
						background: url(old/ico_zoom.png) no-repeat;
						text-indent: -9999px;
					}
					IMG {
						width: auto;
						height: auto;
						margin: auto;
						max-width: 94px;
						max-height: 150px;
					}
					&:hover {
						SPAN {
							display: block;
						}
						IMG {
							opacity: 0.4;
							filter: alpha(opacity=40);
							&:first-child:last-child {
								opacity: 1;
								filter: none;
							}
						}
					}
				}
			}
			.general {
				width: auto;
				float: none;
				padding-left: 135px;
				H3 {
					margin: 0;
					font-size: 15px;
					color: @color01;
					&.name {
						font-size: 32px;
						color: @color-black;
					}
				}
				UL {
					float: left;
					margin: 4px 0 0 0;
					padding: 0;
					background: none;
					LI {
						margin: 8px 0 0 0;
						padding: 0 0 0 15px;
						min-height: 21px;
					}
				}
				A.more {
					float: left;
					margin: 6px 0 0 27px;
					.itbma_block01b & {
						clear: both;
					}
				}
				UL.checkboxes {
					float: none;
				}
			}
		}
		.details .price .add-to-cart {
			margin: 20px 0 0 0 !important;
		}
	}
	UL.actions01.f-right:first-child:last-child {
		padding-bottom: 7px !important;
	}
	@media @media-tablet {
		&.products-b {
			LI {
				.overview {
					.img {
						width: 100px;
						A IMG {
							max-width: 100px;
							max-height: 200px;
						}
					}
					.general {
						padding-left: 125px;
					}
					.price {
						font-size: 17px;
						STRONG {
							font-size: 26px;
						}
					}
				}
			}

		}
	}
	@media @media-mobile {
		&,
		&.first {
			padding-left: 10px;
			padding-right: 10px;
		}

		H2 + UL.actions01.margneg {
			margin: 0 !important;
		}

		margin: 1em 0 0 0 !important;
		LI {
			.overview {
				float: none;
				width: 100%;
				.general {
					padding-left: 0;
					H3.name {
						text-align: center;
					}
					UL {
						float: none;
					}
				}
				.img A IMG {
					max-width: 100%;
					max-height: 150px;
				}
			}
		}

		> LI {
			border-bottom: 2px solid @color-gray-medium-light;
			padding-bottom: 20px;
			&.type02 {
				border-bottom: 2px solid @color02;
			}
		}

		&.products-b {
			padding: 0;
			margin: 0 !important;
			.clearfix();
			& > LI {
				width: 100%;
				clear: both;
				margin-top: 1em !important;
				&:first-child {
					margin-top: 0;
				}
				padding-bottom: 20px !important;
				&.type02 {
					padding: 0;
				}
			}
			LI {
				.overview {
					.price {
						font-size: 17px;
						STRONG {
							font-size: 26px;
						}
					}
					.general {
						padding-left: 0;
					}
				}
			}
		}
	}
}

.price-selection {
	margin: 0 !important;
	padding: 0 !important;
	overflow: hidden;
	float: none !important;
	width: 100%;
	&.margt {
		margin-top: 1em !important;
	}
	LI {
		float: left;
		margin: 9px 2% 0 0 !important;
		padding: 0 !important;
		background: none !important;
		width: 32%;
		&:before {
			display: none;
		}
		&.last {
			margin: 9px 0 0 0 !important;
		}
		.price {
			display: table;
			height: 80px;
			width: 100%;
			font-size: 13px !important;
			color: @color-gray-medium;
			text-decoration: none;
			SPAN.a {
				display: table-cell;
				vertical-align: middle;
				background: lighten(@color-gray-lightest, 5%);
				padding: 10px;
				border: 1px solid @color-gray-medium-lightest;
				text-align: center;
				SPAN {
					font-size: 17px !important;
					display: block;
					font-weight: bold;
					color: @color-black;
				}
			}
		}
		SPAN.price SPAN.a {
			vertical-align: top;
		}
		A.price {
			cursor: pointer;
			&.selected {
				&,
				&:hover {
					SPAN.a {
						color: @color-white;
						border: 1px solid @color03;
						background: @color03;
					}
				}
				SPAN.a SPAN {
					color: @color-white;
				}
			}
			&:hover SPAN.a {
				border: 1px solid @color12;
				background: @color12;
			}
		}
		&.disabled {
			A.price {
				cursor: default;
				&:hover SPAN.a {
					border-color: @color-gray-medium-lightest;
					background: lighten(@color-gray-lightest, 5%);
				}
			}
		}
	}
	&.h01 LI .price {
		height: 30px;
	}
	&.readonly LI .price SPAN.a {
		background: @color-white;
	}
	&.type02 {
		LI A.price {
			height: auto;
			SPAN.a {
				background: @color-white;
				border: 2px solid @color01;
				padding-bottom: 20px;
			}
			&.ico01 SPAN.a {
				background: url(old/ico16.png) no-repeat 50% 20px @color-white;
				padding-top: 120px;
			}
			&.ico02 SPAN.a {
				background: url(old/ico17.png) no-repeat 50% 30px @color-white;
				padding-top: 120px;
			}
			&:hover SPAN.a {
				border-color: @color02;
			}
		}
	}
	&.type03 {
		.prices {
			/*margin: 10px 10px 0 0;*/
			padding: 10px 10px 0 0;
			&.mt0 {
				margin-top: 0;
			}
			P.price01 {
				font-size: 16px;
				STRONG {
					font-size: 34px;
				}
				color: @color02;
				font-weight: bold !important;
				&.price01c {
					color: @color-black;
				}
			}
			P.price04 {
				font-weight: normal;
				margin-top: 1em;
				&.price04b {
					min-height: 3em;
				}
				color: @color02;
				DEL {
					color: @color-gray-medium;
				}
				&.price04b {
					color: @color-black;
				}
			}
		}
	}
	@media @media-mobile {
		LI {
			float: none;
			margin: 9px 0 0 0 !important;
			width: 100% !important;
		}

		&.type03 {
			float: left !important;
			.prices {
				margin: 10px 10px 0 0;
			}
		}
	}
}

.price-selection02 {
	position: relative;
	margin: 1em 0 0 0;
	&:before {
		content: "";
		border-left: 2px solid @color-gray-light;
		position: absolute;
		left: 50%;
		top: 0;
		bottom: 0;
	}
	DIV.item {
		/*cursor: pointer;*/
		&:before {
			content: "";
			background: fade(@color-white, 70%);
			z-index: 20;
			position: absolute;
			left: 1px;
			top: 0;
			right: 0;
			bottom: 0;
		}
		width: 50%;
		.box-sizing(border-box);
		padding: 0 0 0 30px;
		float: left;
		position: relative;
		&.active:before {
			display: none;
		}
		&:nth-of-type(odd) {
			clear: both;
			padding: 0 30px 0 0;
			&:before {
				left: 0;
			}
		}
	}
	DIV.prices {
		margin: 20px 0 0 0;
		P.heading01 {
			min-height: 3em;
			font-size: 16px;
		}
		P.price01 {
			font-size: 16px;
			STRONG {
				font-size: 34px;
			}
		}
		P.price04 {
			font-weight: normal;
			margin-top: 1em;
			&.price04b {
				min-height: 3em;
			}
			.campaign02 & {
				color: @color02;
				DEL {
					color: @color-gray-medium;
				}
				&.price04b {
					color: @color-black;
				}
			}
		}
		.mt0 {
			margin-top: 0;
		}
		.price05 {
			DIV.col {
				float: left;
			}
		}
	}
	@media @media-mobile {
		&:before {
			display: none;
		}

		DIV.item {
			width: 100%;
			float: none;
			padding: 0 !important;
			display: none;
			&.active {
				display: block;
			}
		}

		DIV.prices {
			margin-top: 10px;
			P.heading01 {
				min-height: 0;
			}
		}

	}
}

.owl01 {
	background: url(owl01.png) no-repeat 50% 100%;
	padding-bottom: 170px;
	&.owl01b {
		background: url(owl03.png) no-repeat 50% 100%;
	}
	P {
		border: 1px solid @color02;
		.rounded(10px);
		padding: 20px;
		text-align: center;
		font-style: italic;
		font-size: 20px;
		position: relative;
		&:after {
			content: "";
			width: 23px;
			height: 17px;
			position: absolute;
			top: 100%;
			left: 35%;
			background: url(arr06.png);
		}
	}
	@media @media-tablet {
		float: right;
		max-width: 200px;
		background-size: 120px auto;
		padding-bottom: 170px;
		P {
			margin: 0;
		}
	}
}

.owl02 {
	.display(flex);
	.align-items(center);
	.box-sizing(border-box);
	&.owl02b {
		.img {
			background: url(calendar-clock.svg) no-repeat 50% 50%;
		}
	}
	.img {
		width: 85px;
		height: 85px;
		.flex(0 0 auto);
		text-align: center;
		background: url(owl02.png) no-repeat 100% 100%;;
	}
	.txt {
		padding-left: 20px;
		P {
			text-align: left;
			margin: 0;
		}
	}
	@media @media-notdesktop {

	}
}

UL.progress03 {
	.display(flex);
	margin: 2em 0 0 0;
	padding-bottom: 20px;
	LI {
		.flex(1);
		padding: 10px; /*margin: 0 0 0 -1px;*/
		border: 1px solid @color-gray06;
		background: @color-gray07;
		position: relative;
		z-index: 1;
		&:first-child {
			margin-left: 0;
		}
		&:before {
			display: none;
		}
		DIV.step-heading {
			.clearfix();
			min-height: 45px;
			H2 {
				font-weight: 700; font-family: @font-main; float: left; margin: 0 -85px 0 0; /*margin: 0 -60px 0 0;*/ font-size: 13px; padding: 5px 85px 0 40px; /*padding: 5px 60px 0 40px;*/ position: relative; color: @color-gray14; width: 100%; .box-sizing(border-box);
				.span01 { width: 30px; height: 30px; .box-sizing(border-box); background: @color-gray06; border: 2px solid @color-gray06; .rounded(50%); position: absolute; left: 0; top: 0; text-align: center; line-height: 26px; color: @color-gray14; }
				&:first-child:last-child { margin-right: 0; padding-right: 0; }
			}
			A.btn {
				position: relative;
				z-index: 1;
				float: right;
				font-size: 12px;
				font-weight: bold;
				text-indent: -10000px;
				&:before {
					text-indent: 0;
				}
				@media @media-hd {
					text-indent: 0;
				}
			}
			A.link {
				position: relative;
				z-index: 1;
				float: right;
				line-height: 2.8;
				font-size: 13px;
				text-indent: -10000px;
				color: @color-gray03;
				&:before {
					text-indent: 0;
					font-size: 12px;
				}
				@media @media-hd {
					text-indent: 0;
				}
				@media @media-mobile {
					color: @color-gray03;
				}
			}
			@media screen and (min-width: 1024px) and (max-width: 1400px) {
				H2 {
					margin: 0 -30px 0 0; padding: 5px 30px 0 40px;

				}

				A.btn.edit {
					width: 24px;
				}
				A.link {
					width: 24px;
					&:before {
						line-height: 2.8;
						text-indent: 0;
						float: left;
					}
				}
			}
		}
		SVG.step-arrow {
			display: none;
			fill: none;
			stroke: #00baf2;
			stroke-width: 3px;
			height: 30px;
			vertical-align: top;
			width: 100%;
			position: absolute;
			vertical-align: top;
			bottom: -29px;
			position: absolute;
			left: 0;
			vector-effect: non-scaling-stroke;
		}
		DIV.step-content {
			font-size: 16px; font-family: @font-main; padding-left: 40px;
			.ru & { font-family: @font-heading-ru; }
			H3 { font-size:  16px; font-weight: normal; line-height: 1.2; }
			P {
				line-height: 1.47;
				color: @color-gray01;
				font-weight: 400;
				margin: 0;
				font-size: 13px;
				font-family: @font-main;
				&:first-child { margin-top: 0; }
			}
			.hasico {
				&:before {
					float: none;
					display: inline-block;
				}
			}
		}
		&.active {
			border: 2px solid @color12; z-index: 2; background: @color-white;
			&:before { display: block; content: ""; width: 100%; height: 19px; background: url(bg09.png) no-repeat 50% 0; background-size: 100% 19px; position: absolute; top: 100%; left: 0; }
			DIV.step-heading {
				H2 {
					color: @color-gray01;
					.span01 { border-color: @color12; color: @color-gray01; background: none; }
				}
			}
			DIV.step-content {
				color: @color-gray01;
				H3 { color: @color-gray01; }
			}
			SVG.step-arrow {
				display: block;
			}
		}
		&.confirmed {
			&:hover {
				cursor: pointer;
				background: @color-gray10;
				border: solid 1px @color-gray03;
			}
			DIV.step-heading {
				H2 {
					color: @color-gray01;
					.span01 {
						border-color: @color-green01; background: @color-green01; color: @color-white; text-indent: -10000px;
						&:before { font-family: FontAwesome; content: "\f00c"; float: left; width: 100%; text-align: center; text-indent: 0; font-size: 18px; }
					}
				}
			}
			DIV.step-content {
				color: @color-gray01;
				H3 { color: @color-gray01; }
			}

		}
		&.status02 {
			background-image: url(bg08.png);
		}
		&.summary {
			padding: 10px 20px;
			border: none;
			background: none;
			.display(flex);
			.flex-direction(column);
			.justify-content(flex-start);
			.btn {
				margin-top: 10px;
				text-align: center;
			}
			DL {
				margin: 0;
				.clearfix();
				DT {
					clear: both;
					float: left;
					width: 100%;
					margin: 0 -80px 0 0;
					padding: 0 85px 0 0;
					.box-sizing(border-box);
				}
				DD {
					float: left;
					width: 80px;
					margin: 0;
					padding: 0;
					text-align: right;
				}
			}
		}
	}
	/*@media @media-tablet {
		flex-wrap: wrap;
		LI.summary {
			.flex(0 0 100%); padding: 10px; display: block; .box-sizing(border-box);
			DL {
				DT { width: auto; margin: 0; padding: 0 10px 0 0; }
				DD { width: auto; }
			}
		}
	}*/
	@media @media-notdesktop {
		display: none;
	}
}

UL.progress04 {
	.display(flex);
	margin: 1.5em 0 0 0;
	overflow: hidden;
	LI {
		margin: 0;
		.flex(1);
		background: @color-gray-lightest;
		padding: 35px 80px 30px 100px;
		position: relative;
		min-height: 180px;
		> * {
			position: relative;
			z-index: 3;
		}
		&:before {
			position: absolute;
			z-index: 1;
			left: -25px;
			top: 50%;
			margin-top: -250px;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 250px 0 250px 80px;
			border-color: transparent transparent transparent @color-white;
		}
		&:after {
			position: absolute;
			z-index: 2;
			right: -40px;
			left: auto;
			top: 50%;
			margin-top: -250px;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 250px 0 250px 80px;
			border-color: transparent transparent transparent @color-gray-lightest;
		}
		&.type02 {
			background: @color09;
		}
		P.heading01 {
			font-size: 16px;
			font-weight: bold;
			position: relative;
			margin: 0;
			SPAN.no {
				position: absolute;
				left: -45px;
				top: -7px;
				width: 35px;
				height: 35px;
				background: @color01;
				color: @color-white;
				.rounded(50%);
				text-align: center;
				line-height: 35px;
				font-size: 18px;
				font-weight: normal;
			}
		}
		&:first-child {
			padding: 30px 80px;
			&:before {
				display: none;
			}
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
		P {
			padding-bottom: 10px;
		}
	}
	@media (max-width: 480px) {
		.btn-center {
			display: block;
			margin: auto 30px 0 0;
			text-align: center;
			padding-bottom: 0;
			.btn {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
	@media @media-tablet {
		LI {
			padding: 35px 40px 30px 100px;
		}
	}
	@media @media-mobile {
		IMG {
			max-width: 160px !important;
		}

		.flex-direction(column);
		LI {
			margin: 15px 0 0;
			padding: 72px 20px 10px 80px;
			min-height: 130px;
			&:first-child {
				margin: 0;
				padding: 42px 20px 10px 80px;
			}
			&:last-child {
				padding-bottom: 30px;
			}
			&:before {
				border-width: 50px 384px 0 384px;
				border-color: @color-white transparent transparent transparent;
				left: 50%;
				margin-left: -384px;
				top: -5px;
				margin-top: 0;
			}
			&:after {
				border-width: 50px 384px 0 384px;
				border-color: @color-gray-lightest transparent transparent transparent;
				left: 50%;
				right: auto;
				margin-left: -384px;
				top: auto;
				bottom: -45px;
				margin-top: 0;
			}
		}
	}
}

TABLE.progress01 {
	/*float: left;*/
	margin: 1em 0 !important;
	padding: 0;
	border: 1px solid @color-gray-medium-lightest;
	border-width: 1px;
	background: lighten(@color-gray-lightest, 6%);
	.block01 & {
		float: none;
		border-width: 0 0 1px;
		margin: 0 !important;
	}
	.order-wrap & {
		display: none;
	}

	TD {
		padding: 6px 40px 5px 10px;
		background: lighten(@color-gray-lightest, 6%) url(old/ico_step01.png) right 50% no-repeat;
		color: @color-gray-medium;
		border: none !important;
		&.last {
			background: lighten(@color-gray-lightest, 6%);
		}
		&.active {
			background: @color-white url(old/ico_step02.png) right 50% no-repeat !important;
		}
		&.current {
			background: @color-white url(old/ico_step01.png) right 50% no-repeat;
			&.last {
				background: @color-white;
			}
		}
		&.cart {
			/*text-indent: -10000px; width: 40px;*/
			> SPAN,
			> A {
				&:before {
					font-family: "icons";
					content: @ico-cart;
					height: 20px;
					line-height: 20px;
					color: @color-gray-darkest;
					font-size: 20px;
					text-indent: 0;
					float: left;
					margin-right: 10px;
				}
			}
		}
		> SPAN,
		> A {
			padding: 0;
			&.note {
				background: none;
				padding: 0;
				font-size: 10px;
				color: @color-gray-medium;
				text-decoration: none;
				display: inline;
			}
		}
		&.active,
		&.current {
			color: @color-black;
			& > SPAN,
			& > A {
				position: relative;
				display: block;
				height: 21px !important;
				line-height: 21px;
				overflow: hidden;

			}
		}
		&.active {
			& > SPAN,
			& > A {
				padding: 0 0 0 30px;
				&:before {
					font-family: "icons";
					font-size: 20px;
					position: absolute;
					top: 0;
					left: 5px;
					content: @ico-ok;
					color: @color-positive;
				}
			}
		}
	}
	TR:hover TD {
		background: lighten(@color-gray-lightest, 6%) url(old/ico_step01.png) right 50% no-repeat;
		&.last {
			background: lighten(@color-gray-lightest, 6%);
		}
		&.active {
			background: @color-white url(old/ico_step02.png) right 50% no-repeat !important;
		}
		&.current {
			background: @color-white url(old/ico_step01.png) right 50% no-repeat;
			&.last {
				background: @color-white;
			}
		}
	}
	@media @media-tablet {
		TD {
			SPAN.note {
				display: none;
			}
			&.cart {
				text-indent: -10000px;
				width: 40px;
			}
		}
	}
	@media @media-mobile {
		&,
		TBODY,
		TR,
		TD {
			display: block;
			overflow: hidden;
		}

		TR {
			.display(flex);
			.justify-content(space-between);
		}

		TD {
			float: left;
			padding: 8px 2px 7px 2px;
			&:nth-of-type(1) {
				SPAN, A {
					&:after {
						content: "1"
					}
				}
			}
			&:nth-of-type(2) {
				SPAN, A {
					&:after {
						content: "2"
					}
				}
			}
			&:nth-of-type(3) {
				SPAN, A {
					&:after {
						content: "3"
					}
				}
			}
			&:nth-of-type(4) {
				SPAN, A {
					&:after {
						content: "4"
					}
				}
			}
			&:nth-of-type(5) {
				SPAN, A {
					&:after {
						content: "5"
					}
				}
			}
			&:nth-of-type(6) {
				SPAN, A {
					&:after {
						content: "6"
					}
				}
			}
			&:nth-of-type(7) {
				SPAN, A {
					&:after {
						content: "7"
					}
				}
			}
			&:first-child:nth-last-child(1) {
				.flex(0 1 100%);
			}
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ TD {
				.flex(0 1 50%);
			}
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ TD {
				.flex(0 1 33.3333%);
			}
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ TD {
				.flex(0 1 25%);
			}
			&:first-child:nth-last-child(5),
			&:first-child:nth-last-child(5) ~ TD {
				.flex(0 1 20%);
			}
			> SPAN,
			> A {
				display: block;
				text-align: center;
				text-indent: 0;
				float: none;
				min-width: 20px;
				.box-sizing(border-box);
				height: 21px !important;
				line-height: 21px;
				padding: 0;
				overflow: hidden;
				position: relative;
				color: transparent;
				//&:first-letter { color: @color-gray-medium-light; text-indent: 0; display: block; border: 1px solid green; width: 50px; text-align: center; }
				&:after {
					color: @color-gray-medium-light;
					display: block;
					text-align: center;
					position: absolute;
					left: 0;
					right: 10px;
					top: 0;
				}
			}
			&.active {
				padding-left: 0;
				> SPAN,
				> A {
					&:before {
						display: none;
					}
					&:after {
						left: 0;
					}
				}
			}
			&.cart {
				text-indent: -10000px;
				&.current {
					> SPAN,
					> A {
						text-indent: -10000px;
						text-align: center;
						&:before {
							text-indent: 0;
							display: block;
							color: @color-black;
							font-size: 20px;
						}
					}
					&:nth-of-type(1) {
						.flex-important(0 1 100%);
					}
					&:nth-of-type(2) {
						.flex-important(0 1 50%);
					}
					&:nth-of-type(3) {
						.flex-important(0 1 33.3333%);
					}
					&:nth-of-type(4) {
						.flex-important(0 1 25%);
					}
					&:nth-of-type(5) {
						.flex-important(0 1 20%);
					}
				}
				> SPAN,
				> A {
					&:before {
						position: absolute;
						color: @color-gray-medium-light;
						left: 0;
						right: 0;
						top: 0;
					}
					&:after {
						display: none;
					}
				}
			}
			&.current {
				padding-right: 10px;
				padding-left: 5px;
				.flex-important(1 1 100%);
				&.last {
					padding-right: 2px;
				}
				> SPAN,
				> A {
					text-indent: 0;
					width: 100%;
					text-align: left;
					padding: 0;
					color: @color-black;
					&:first-letter {
						color: @color-black;
					}
					&:before {
						font-size: 16px;
						display: none;
					}
					&:after {
						display: none !important;
					}
				}
			}
			&.active {
				> SPAN,
				> A {
					padding-left: 25px;
					&:before {
						font-size: 16px;
					}
				}
			}
		}
	}
}

/* 9. Maps */

.infoBox {
	background: @color-white;
	margin-top: 8px;
	padding: 7px;
	.rounded(3px);
	-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
	line-height: 1.6;
	P.title {
		text-align: center;
		font-size: 14px !important;
		font-family: @font-main !important;
		margin: 0;
		font-weight: normal !important
	}
	DIV.bar {
		color: @color-black;
		margin: 7px 0 0 0;
		padding: 7px 10px 0;
		border-top: 2px solid @color-gray-light;
		&.red {
			color: @color-negative;
		}
		P {
			font-family: @font-main;
			text-align: center;
			font-size: 12px;
			margin: 0;
			overflow: hidden;
			&.title {
				font-weight: bold;
				A {
					font-weight: bold;
					font-family: @font-main;
					font-size: 12px;
					color: darken(@color03, 20%);
				}
			}
			SPAN.a {
				display: block;
				font-family: @font-main;
				color: @color-gray-medium;
				padding: .3em 0 0 0;
				overflow: hidden;
			}
			SPAN.b {
				float: left;
				width: 50%;
				display: block;
				text-align: right;
				font-size: 30px;
				font-weight: 200;
				line-height: 30px;
				height: 30px;
				overflow: hidden;
				SMALL {
					font-size: 9px;
					font-weight: normal;
					position: relative;
					top: -1.6em;
				}
			}
			SPAN.c {
				float: left;
				display: block;
				text-align: left;
				font-size: 12px;
				line-height: 12px;
				padding: 8px 0 0 10px;
			}
		}
	}
}

SPAN.markerlabel {
	position: relative;
	left: -50%;
	top: 0;
	text-align: center;
	white-space: nowrap;
	font-size: 13px;
	font-weight: normal;
	color: @color-black;
	float: left;
	background: url(old/map/marker01.png) no-repeat 100% 0;
	text-shadow: -2px -2px 0 @color-white, 2px -2px 0 @color-white, -2px 2px 0 @color-white, 2px 2px 0 @color-white;
	SPAN {
		color: darken(@color03, 20%);
		font-weight: bold;
	}
}

/* 10. Cart */
UL.cart01 {
	margin: 1em 0 0 0;
	padding: 0;
	H3 {
		font-size: ceil(@base-font-size*1.2);
		font-weight: 600;
		margin: 0;
	}
	.ico-ok {
		&:before {
			display: block;
		}
	}
	&.cart01b {
		border: 2px solid @color-gray-medium-lightest;
		padding: 20px;
		margin: 1em 0 0 0;
		&.color01 {
			border-color: @color12;
		}
		> LI {
			border: none;
			padding: 10px 0 0 0;
			&:first-child {
				border-top: none;
			}
			&.mobile-hide {
				& + .mobile-hide {
					border-top: 1px solid @color-gray-light;
					padding-top: 20px;
				}
			}
			&.subheading {
				padding: 0 0 10px;
				H3 {
					font-size: 22px;
					font-weight: bold;
					margin: 0;
					float: left;
				}
			}
			&.total {
				background: none;
				margin: 0;
				padding: 0;
			}
		}
		DIV.img {
			width: 80px;
		}
		DIV.txt {
			position: relative;
			margin-left: -80px;
			padding-left: 90px;
			/*&.txt-b {
				&:before { display: none; }
			}
			@media @media-notdesktop {
				&.txt-b {
					margin-left: 0;
					padding-left: 0;
					padding-top: 0;
				}
			}*/
		}
		.subitem {
			DIV.img {
				margin-top: 40px;
				padding-bottom: 10px;
			}
			DIV.txt {
				padding-top: 20px;
				&:before {
					padding-top: 20px;
				}
				/*&.txt-b {
					&:before { display: none; }
				}
				@media @media-notdesktop {
					&.txt-b {
						margin-left: 0;
						padding-left: 0;
						padding-top: 0;
					}
				}*/
			}
			&:first-child {
				DIV.img {
					margin-top: 0;
				}
				DIV.txt {
					padding-top: 0;
				}
			}
		}
		&.cart01--mrg {
			&-content {
				margin: 0;
			}
			&-sidebar {
				margin: 0;
			}
		}
		&.cart01--pad {
			&-sidebar {
				padding: 0;
			}
		}
	}
	/*if not ok use this*/
	&.cart01c {
		/*.col-a {
			margin-right: -125px;
			padding: 0 130px 0 0;
		}
		.col-b {
			width: 125px;
		}*/
		.col-d {
			.input-wrapper {
				margin: 1px 10px 0 0;
			}
			.button-wrapper {
				margin: 0;
				padding: 0;
				font-size: 14px;
			}
		}
		@media @media-mobile {
			.col-d {

			}
		}
		padding-bottom: 30px;
		.pad {
			padding: 0;
		}
		.cart-a {
			padding-bottom: 30px;
		}
		.subitem {
			padding: 20px;
			.img {
				margin-top: 0;
			}
			.txt {
				padding-top: 0;
				&.separated {
					&:after {
						content: "";
						height: 0;
						display: block;
						border-top: 2px dashed @color-gray-medium-lightest;
						top: 15px;
						position: relative;
					}
				}
				&:before {
					display: none;
				}
				/*&:after { content: ""; height: 0; display: block; border-top: 2px dashed @color-gray-medium-lightest; }*/
			}

		}
		@media @media-tablet {
			.pad > {
				DIV.col-b {
					border-top: none;
				}
			}
		}
	}
	/*.item__separator {
		.subitem {
			&:not(:last-child) {
				border-bottom: 1px solid @color-gray05;
				padding-bottom: 30px;
			}
		}
	}
	.subitem__separator {
		.txt {
			padding-top: 30px;
			&:before {
				content: "";
				height: 0;
				display: block;
				border-top: 2px solid @color-gray05;
				padding-top: 30px;
			}
		}
	}*/
	&.cart01--overview {
		.item {
			.subitem {
				&.subitem--separator {
					border-bottom: 1px solid @color-gray05;
					padding-bottom: 30px;
				}
			}
			.txt {
				&:before {
					display: none;
				}
				&.txt--separator {
					padding-top: 30px;
					&:before {
						content: "";
						height: 0;
						display: block;
						border-top: 1px dashed @color-gray05;
						padding-top: 30px;
					}
				}
			}
		}
	}
	.cart01__noborders {
		border: none;
	}
	&.cart01--checkout {
		padding: 20px 0;
		border: 1px solid @color-gray05;
		&:before {
			display: none;
		}
		> LI {
			&.more {
				border-bottom: 1px solid @color-gray05;
				border-style: none none solid none;
				padding: 0 20px 10px;
			}
			&.total {
				border: none;
				padding: 0 20px 10px;
			}
			&.subheading {
				padding: 0 20px 10px;
			}
			.subitem {
				padding: 0 20px 20px 20px;
				.txt {
					&:before {
						display: none;
					}
				}
			}
		}
	}
	.cart01__separator {
		&.subitem {
			.txt {
				&:before {
					display: none;
				}
			}
		}
		&--top {
			border-top: 1px solid @color-gray06;
			&::before {
				display: none;
			}
		}
		&::before {
			margin: 0;
			content: '';
			position: relative;
			width: auto;
			display: block;
		}
		&--half {
			&::before {
				border-top: 1px solid @color-gray06;
				margin-left: 90px;
			}
		}
		&--dashed {
			border-style: dashed;
		}
	}
	.cart01__noborders {
		border: none;
		> LI .subitem {
			border-bottom: none;
		}
	}
	> LI {
		margin: 10px 0 0;
		padding: 0;
		border: 1px solid @color-gray-medium-lightest;
		font-size: @base-font-size;
		&.muted {
			* {
				color: @color-gray-medium !important;
			}
			UL.params {
				LI {
					&:before {
						color: @color-gray-medium;
					}
					&.type01:before,
					&.type02:before {
						color: @color-gray-medium;
						border-color: @color-gray-medium;
					}
				}
			}
		}
		&:first-child {
			margin: 0;
			&:last-child {
				border-style: none;
				.col.w70p & {
					border-style: solid;
				}
				> .txt H3 {
					font-size: 22px;
				}
				> .img {
					margin: 0;
					padding: 0;
				}
			}
		}
		&:before {
			display: none;
		}
		&.total {
			margin: 2em 0 0 0;
			border-top: 2px solid @color-gray-medium-lightest;
			background: lighten(@color-gray-lightest, 5%);
			.pad {
				padding: 20px;
			}
			.col-b {
				padding-right: 0;
			}
			SELECT {
				background-color: @color-white;
				cursor: pointer;
			}
		}
		&.more {
			border-top: 2px dashed @color-gray-medium-lightest;
			padding-bottom: 10px;
			P {
				margin: 0;
			}
		}
		&.cart01__transport {
			background: lighten(@color-gray-lightest, 5%);
			position: relative;
			padding: 25px 25px 25px 105px;
			margin: 20px -20px -20px;
			line-height: 1.4;
			/*remove this when olny used hasicon class and then change paddings */
			&:before {
				display: block;
				text-align: center;
				font-size: 30px;
				font-family: "FontAwesome";
				content: "\f0d1";
				color: @color01;
				position: absolute;
				left: 25px;
				top: 25px;
				/*width: 56px;
				height: 56px;*/
				/*border: 1px solid @color10;
				.rounded(50%);*/
				.box-sizing(border-box);
				line-height: 54px;
			}
			> *:first-child {
				margin: 0;
			}
			&--hasicon {
				padding: 25px 25px 25px 80px;
				margin: 20px -20px -20px;
				&::before {
					display: block;
					text-align: center;
					font-size: 30px;
					font-family: "FontAwesome";
					content: "\f0d1";
					color: @color01;
					position: absolute;
					left: 25px;
					top: 25px;
					/*width: 56px;
					height: 56px;*/
					/*border: 1px solid @color10;
					.rounded(50%);*/
					.box-sizing(border-box);
					line-height: 54px;
				}
			}
			&--bg {
				&-no {
					background: none;
				}
			}
			@media @media-tablet {
				padding: 105px 25px 25px;
				&:before {
					left: 50%;
					top: 25px;
					margin-left: -28px;
				}
				&--hasicon {
					padding: 80px 25px 25px;
					&::before {
						left: 50%;
						top: 25px;
						margin-left: -28px;
					}
				}
			}
		}
	}
	UL.actions01 LI .btn {
		margin-left: 5px;
		margin-right: 5px;
		text-align: center;
	}
	.heading01 {
		.clearfix();
		background: lighten(@color-gray-lightest, 5%);
		padding: 10px 20px;
		H2 {
			float: left;
			color: @color-gray-medium;
			margin: .5em 0 0 0;
			font-family: @font-main;
			font-size: @base-font-size;
			SMALL {
				color: @color-gray-medium;
				font-family: @font-main;
			}
		}
		P {
			float: left;
			color: @color-gray-medium;
			margin: .5em 0 0 0;
		}
		UL.actions01 {
			margin: 0 !important;
			LI {
				float: left;
			}
			.btn {
				font-size: ceil(@base-font-size*0.86);
				padding: 7px 0 !important;
				&.add {
					margin-top: -2px;
				}
			}
		}
		UL.checkboxes {
			clear: both;
			margin: .5em 0 0 0;
		}
	}
	DIV.prices {
		margin: .5em 0 0 0;
		P.price02 {
			font-size: 24px;
			font-weight: bold;
			STRONG {
				font-weight: bold;
			}
		}
		P.price01 {
			font-size: 17px;
		}
		P.price04 {
			color: @color-gray-medium;
			font-weight: bold;
		}
	}
	.pad {
		.clearfix();
		position: relative;
		padding: 20px;
		> DIV.col-a {
			padding: 0 350px 0 0;
			margin-right: -330px;
			.txt.separated {
				border-top: 2px dashed @color-gray-medium-lightest;
			}
		}
		> DIV.col-b {
			width: 330px;
		}
	}
	.subitem {
		.clearfix();
		.txt {
			padding-top: 30px;
			&:before {
				content: "";
				height: 0;
				display: block;
				border-top: 2px dashed @color-gray-medium-lightest;
				padding-top: 30px;
			}
		}
		.img {
			margin-top: 50px;
		}
		&:first-child {
			.txt {
				padding-top: 10px;
				&:before {
					display: none;
				}
			}
			.img {
				margin-top: 20px;
			}
		}
		TABLE & {
			H3 {
				margin-top: 1em;
			}
			P + P {
				margin: .5em 0 0;
			}
		}
	}
	.img {
		width: 120px;
		padding: 20px;
		margin: 20px 0 0 0;
		.box-sizing(border-box);
		float: left;
		position: relative;
		z-index: 2;
		text-align: center;
		IMG {
			max-width: 100%;
			max-height: 200px;
			height: auto;
			width: auto;
		}
	}
	.txt {
		float: left;
		width: 100%;
		margin-left: -120px;
		padding: 10px 0 0 120px;
		.box-sizing(border-box);
		position: relative;
		.clearfix();
		&:first-child {
			margin-left: 0 !important;
		}
		H4 {
			font-size: ceil(@base-font-size*1);
			font-family: @font-main;
			font-weight: bold;
		}
		/*&.txt-b {
			&:before { display: none; }
		}
		@media @media-notdesktop {
			&.txt-b {
				margin-left: 0;
				padding-left: 0;
				padding-top: 0;
			}
		}*/
	}
	.data01 {
		padding: 10px 0 0 30px;
		margin: 10px 0 0 0;
		position: relative;
		border-top: 2px dashed @color-gray-light;
		&__noicon {
			padding-left: 0;
			&:before {
				display: none;
			}
		}
		&:before {
			position: absolute;
			left: 5px;
			top: 12px;
		}
		&.ico-check:before {
			color: @color-positive;
		}
		&.ico-alert:before {
			color: @color-negative;
		}
		&.ico-time:before {
			color: @color-gray-medium;
		}
		DL,
		P:first-child {
			margin: 0;
		}
		&.data01b {
			/*mixins*/
			.ico-pos-top(@top) {
				top: @top;
			}
			padding: 10px 10px 10px 24px;
			border: 0;
			margin: 0;
			&:before {
				top: 22px;
				left: 5px;
			}

			&.pad02 {
				padding: 5px 10px 5px 24px;
			}
			.loading-circle {
				left: 5px;
				.ico-pos-top(22px);
				&.ico-top01 {
					.ico-pos-top(14px);
				}
			}
			&.form01 {
				/*take to global*/
				DIV.row {
					> LABEL,
					> SPAN.label {
						width: 20%;
						@media @media-notwide {
							width: 100%;
						}
						@media @media-notdesktop {
							width: 100%;
						}
					}
					DIV.items {
						width: 68%;
						@media @media-notwide {
							width: 82%;
						}
						@media @media-notdesktop {
							width: 82%;
						}
					}
				}
			}
		}
	}
	.col &,
	TABLE & {
		.img {
			width: 120px;
			padding: 0;
		}
		.txt {
			margin-left: -120px;
			padding-left: 140px;
			/*&.txt-b {
				&:before { display: none; }
			}
			@media @media-notdesktop {
				&.txt-b {
					margin-left: 0;
					padding-left: 0;
					padding-top: 0;
				}
			}*/
		}

		@media @media-mobile {
			.img {
				width: 90px;
				padding: 0;
			}

			.txt {
				margin-left: -90px;
				padding-left: 100px;
			}
		}
	}
	.orders01 & {
		> LI {
			padding: 20px;
			margin: 0;
			border-bottom: none;
			border-top-width: 1px;
			&:first-child {
				border-top: none;
			}
		}
	}
	.block01 & {
		margin: -20px -18px;
	}
	.blockheading & {
		margin: -15px -20px;
		border: none;
	}
	.col .block01 & {
		> LI {
			padding: 10px 18px;
			border-bottom-width: 1px;
			margin: 0;
			border-top: none;
			&:last-child {
				border-bottom: none;
			}
			&.total {
				margin-top: 0;
				border-top: none;
			}
		}
	}
	HR {
		margin-top: 1em;
	}
	.col-a {
		float: left; width: 100%; margin-right: -221px;
		padding: 0 221px 0 0; .box-sizing(border-box);
		> *:first-child {margin:  0; }
		&-sm { margin-right: -215px; padding: 0 225px 0 0; }
	}
	.col-b {
		float: left; text-align: right; width: 221px; .box-sizing(border-box);
		&-lg { width: 215px; }
		P.price {
			font-weight: bold;
			margin: 0;
			SMALL {
				font-weight: normal;
				color: @color-gray-medium;
				display: block;
				font-size: 1em;
			}
			&--disabled {
				color: @color-gray-medium;
			}
		}
		UL.actions01 {
			margin: 0 !important;
			float: right;
			clear: both;
			LI {
				margin: 5px 0 0 5px;
			}
			.btn {
				font-size: ceil(@base-font-size*0.86);
				padding: 7px 0 !important;
				margin: 0 !important;
				&.add {
					margin-top: -2px;
				}
			}
		}
	}
	.col-c {
		float: left;
		width: 250px;
		position: relative;
		H3 {
			font-size: 18px !important;
		}
		P.meta {
			color: @color-gray-medium;
		}
	}
	.col-d {
		float: left;
		width: 100%; /*margin: 0 -115px 0 -250px; padding: 0 125px 0 260px;*/
		margin: 0 0 0 -250px;
		padding: 0 0 0 260px;
		.box-sizing(border-box);
		P {
			margin: .3em 0 0 0;
			STRONG {
				display: block;
				margin: .5em 0 0 0;
			}
		}
	}
	.form01 {
		DIV.row {
			> LABEL,
			> SPAN.label {
				width: 43%;
			}
			DIV.items {
				width: 55%;
			}
			SMALL {
				font-weight: normal;
				white-space: nowrap;
			}
		}
	}
	P.ico-alert:before {
		margin-right: 10px;
	}
	P.ico-time:before {
		margin-right: 10px;
	}
	UL.params {
		.display(flex);
		flex-wrap: wrap;
		.justify-content(flex-start);
		margin: 0;
		border-left: none;
		LI {
			.flex(0 1 85px);
			border: none !important;
			margin: 1.5em 0 0 0;
			.display(flex);
			text-align: center;
			flex-direction: column;
			.justify-content(center);
			float: left;
			padding: 35px 10px 0;
			line-height: 1.2;
			position: relative;
			.box-sizing(border-box);
			font-size: ceil(@base-font-size*0.86);
			&:after {
				display: none;
			}
			STRONG {
				display: block;
				font-size: ceil(@base-font-size*1.125);
				SMALL {
					font-weight: normal;
					font-size: ceil(@base-font-size*0.86);
				}
			}
			&:before {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				margin: auto;
				text-align: center;
				font-family: "icons";
				color: @color02;
				font-size: ceil(@base-font-size*2);
			}
			&.type01:before {
				content: @ico-down;
				color: @color-black;
				border: 3px solid @color02;
				.rounded(50%);
				width: 30px;
				height: 30px;
				.box-sizing(border-box);
				line-height: 24px;
				font-size: @base-font-size;
				text-align: center;
			}
			&.type02:before {
				content: @ico-up;
				color: @color-black;
				border: 3px solid @color02;
				.rounded(50%);
				width: 30px;
				height: 30px;
				.box-sizing(border-box);
				line-height: 24px;
				font-size: @base-font-size;
				text-align: center;
			}
			&.type03:before {
				content: @ico-unlimited;
			}
			&.type04:before {
				content: @ico-chat;
			}
			&.type05:before {
				content: @ico-calls;
			}
			&.type06:before {
				background: none;
				content: @ico-limited;
			}
			&.type07:before {
				content: @ico-4g;
			}
			&.type08:before {
				content: @ico-network;
			}

		}
	}
	&.cart01--noborder {
		> LI.item {
			border: 0;
		}
	}
	&.cart01--mrg {
		&-content {
			margin: 0;
		}
		&-sidebar {
			margin: 0;
		}
	}
	&.cart01--pad {
		&-sidebar {
			padding: 0 0 20px 20px;
		}
	}
	.cart01__bg {
		background: @color-gray07;
	}
	@media @media-tablet {
		.heading01 {
			H2, P {
				float: none;
			}
			UL.actions01 {
				/*removed 22.11*/
				/*float: left;*/
				clear: both;
				LI {
					/*removed 22.11*/
					/*float: right !important;*/
					.btn {
						margin: 0 5px 0 0;
					}
				}
			}
		}

		.col-a {
			margin-right: 0;
			padding: 0;
			float: none;
		}

		.col-b {
			text-align: left;
			margin: 10px 0 0;
			width: auto;
			float: none;
		}

		.pad > {
			DIV.col-b {
				width: auto;
				border-top: 2px dashed @color-gray-light;
				clear: both;
				padding-top: 10px;
			}
			DIV.col-a {
				.clearfix();
				padding: 0;
				margin-right: 0;
			}
		}

		&.cart01b {
			> LI.subheading SPAN.f-right {
				display: block;
				float: none;
			}
			.subitem DIV.txt P STRONG {
				word-wrap: break-word;
			}
		}

	}
	@media @media-mobile {
		margin: 0 -15px;
		border: none;
		&.cart01b {
			margin: 0 -15px;
			border-style: solid none none;
			> LI {
				border: none;
				&.subheading {
					H3 {
						float: none;
					}
					SPAN.f-right {
						float: none;
						display: block;
						A.btn {
							float: left;
						}
					}
				}
				&.total {
					margin: 0;
					padding-top: 10px;
				}
			}
			&.color01 {
				height: auto !important;
				min-height: 0 !important;
				+ DIV {
					display: none !important;
				}
			}

		}
		> LI {
			border-style: solid none none;
			margin: 0;
		}

		.blockheading & {
			border-style: none;
		}

		.heading01 {
			H2, P {
				float: none;
			}
			UL.actions01 {
				float: none;
				clear: both;
				LI {
					float: left !important;
					margin-right: 10px !important;
					&:last-child {
						float: right !important;
						margin-right: 0 !important;
					}
					.btn {
						margin: 0 5px 0 0;
					}
				}
			}
		}

		.img {
			width: 90px;
		}

		.txt {
			/*&:first-child {
				padding: 0;
			}*/
			&.separated {
				padding-top: 20px;
				padding-bottom: 0;
				.col-a,
				.col-b {
					&:before {
						display: none;
					}
				}
			}
		}

		.col & {
			.img {
				width: 80px;
			}
			.txt {
				margin-left: -80px;
				padding-left: 100px;
			}
		}

		.pad {
			&--type-package {
				padding: 20px;
				margin-right: -20px;
				margin-left: -20px;
			}
			> DIV.col-a {
				padding: 0;
				margin-right: 0;
			}
			> DIV.col-b {
				width: auto;
				padding-left: 0;

				&:before {
					display: none;
				}
			}
		}

		.col-b P.price SMALL {
			display: inline;
		}

		.col-c {
			float: none;
			width: auto;
			position: relative;
		}

		.col-d {
			float: none;
			width: auto;
			margin: 1em 0 0 0 !important;
			padding: 0;
			P STRONG {
				margin: 0;
			}
			.input-wrapper {
				float: none;
			}
		}

		.block01 & {
			margin: -10px -9px;
		}

		UL.colors01 {
			LI {
				margin-top: 6px;
			}
			SPAN SPAN,
			A.more {
				width: 30px;
				height: 30px;
				line-height: 30px;
			}
		}

		.col .block01 & {
			LI {
				border-width: 2px 1px;
			}
		}

		.col-a {
			margin-right: 0;
			padding: 0;
			float: none;
		}

		.col-b {
			text-align: left;
			margin: 10px 0 0;
			width: auto;
			float: none;
		}

		UL.params {
			display: none;

		}
		&.cart01__mrg {
			&--content {
				margin: 0;
			}
		}
	}
}

TABLE.history01 UL.cart01 {
	DIV.row DIV.items SPAN.text.f-right {
		white-space: nowrap;
	}
	.col {
		&.item {
			width: 100%;
			padding: 0 350px 0 25px;
			margin-right: -330px;
			&:after {
				content: "";
				display: block;
				border-bottom: 1px solid @color-gray-light;
				padding-top: 10px;
				clear: both;
			}
			&:last-child:after {
				border-bottom: none;
			}
			.txt {
				padding-top: 25px;
			}
		}
		&.price {
			width: 330px;
			padding: 25px;
			position: relative;
			.box-sizing(border-box);
			UL.actions01.margneg {
				margin: -1.5em 0 0 0 !important;
			}
		}
		@media @media-mobile {
			&.item {
				padding: 0 15px;
				margin-right: 0;
				.col {
					&.img {
						width: 80px !important;
						float: none !important;
						margin: auto !important;
					}
				}
				.txt {
					width: 100% !important;
					float: none !important;
					margin: 0 !important;
					padding: 0 !important;
					.box-sizing(border-box);
				}
				UL.actions01 {
					float: none !important;
					padding-bottom: 10px;

				}
			}

			&.price {
				float: none !important;
			}
		}
	}

}

.orders01 {
	border: 1px solid @color-gray-light;
	padding: 20px;
	margin: 1em 0 0 0;
	TABLE & {
		border: none;
		padding: 0;
	}
	.order01 {
		margin: 20px 30px 0 130px;
		padding: 20px 0;
		border-top: 1px dashed @color-gray-light;
		.clearfix();
		&:first-child {
			margin-top: 0;
			border-top: 0;
		}
		DIV.a {
			width: 40%;
			float: left;
		}
		DIV.b {
			width: 60%;
			float: left;
		}
		H3, H4 {
			font-family: @font-main;
			font-size: 14px;
		}
		.img01 {
			float: left;
			width: 120px;
			margin-left: -130px;
			text-align: center; /*max-width: 120px;*/
			IMG {
				max-width: 100%;
				/*max-height: 150px;*/
				max-height: 85px;
				width: auto;
				height: auto;
			}
		}
		.heading01 {
			H3 {
				font-weight: bold;
				margin: 0;
			}
			P.meta {
				font-size: 14px;
				color: @color-gray-medium;
				margin: .5em 0 0 0;
			}
			.price01 {
				display: none;
			}
		}
		.text01 {
			margin-top: .5em;
		}
		.price01 {
			width: 25%;
			float: right;
			P {
				margin: 0;
				&.main {
					font-size: 16px;
					font-weight: bold;
				}
				&.meta {
					font-size: 14px;
					color: @color-gray-medium;
					margin: .5em 0 0 0;
				}
			}
		}
		.status01 {
			float: left;
			.box-sizing(border-box);
			width: 70%;
			padding-left: 35px;
			position: relative;
			color: @color-gray-medium;
			&:before {
				font-family: "icons";
				position: absolute;
				left: 0;
				top: 0;
				width: 22px;
				height: 22px;
				font-size: 12px;
				.rounded(50%);
				background: @color-gray-light;
				color: @color-white;
				content: "";
				text-align: center;
				line-height: 22px;
			}
			&.status-ok {
				H4 {
					color: @color-positive;
				}
				&:before {
					background: @color-positive;
					content: @ico-check;
				}
			}
			&.status-billing {
				&:before {
					background: @color12;
				}
			}
			&.status-error {
				H4 {
					color: @color-negative;
				}
				&:before {
					background: @color-msg-negative;
					content: @ico-exclamation;
				}
			}
			H4 {
				margin: 0;
				font-size: 14px;
				font-weight: 400;
				color: @color-black;
			}
			P {
				margin: .5em 0 0 0;
			}
		}
		&.order01b {
			margin: 20px 30px 0 155px;
			DIV.a {
				width: 50%;
			}
			DIV.b {
				width: 50%;
			}
			.img01 {
				width: 85px;
				margin-left: -108px;
				max-width: 85px;
				/*IMG { max-width: 100%; max-height: 150px; width: auto; height: auto;}*/
			}
			.price01 {
				width: 70%;
			}
			.text01 {
				.meta {
					color: @color-gray-medium;
					DL {
						margin: 0;
					}
					SPAN {
						color: @color-gray-medium;
						margin: 1.5em 0 0.5em 0;
						display: inline-block;
					}
				}
				DD {
					font-weight: bold;
				}
			}
			/*@media @media-mobile {
				padding: 20px 20px 0 110px;
				.price01 {
					width: 100%;
				}
				.img01 {
					width: 85px;
					margin-left: -100px;
					max-width: 85px;
				}
			}*/
		}
	}
	/*move to global*/
	.total {
		border-top: 1px dashed @color-gray-light;
		.display(flex);
		.flex-wrap(wrap);
		.justify-content(flex-end);
		.clearfix();
		.col {
			.flex(0 0 auto);
			width: 33.3333%;
			border-left: 1px dashed @color-gray-light;
			.box-sizing(border-box);
			padding: 30px 20px;
			&:first-child {
				border-left: 0;
			}
			H3 {
				font-weight: bold;
			}
			DD {
				font-weight: bold;
			}
			DT {
				/*word-wrap: normal;
				word-break: normal;
				white-space: nowrap;*/
			}

		}
		&__wrapper {
			padding: 0 20px;
			&.total__wrapper--sidebar {
				border: 1px @color-gray05;
				border-style: none none none solid;
				@media @media-notdesktop {
					border-style: solid none none none;
				}
			}
		}
		&__item {
			padding: 20px;
			@media @media-mobile {
				padding: 20px 0;
			}
		}
		.form01 {
			margin: -.7em 0 0 0;
		}
	}
	.signature {
		border-top: 1px dashed @color-gray-light;
		padding: 0 20px;
		@media @media-mobile {
			padding: 0;
		}
	}
	&.orders01__history {
		.order01 {
			padding: 20px 30px 0 165px;
			margin: 20px 0;
			border: none;
			&.order01--border {
				border-top: 1px solid @color-gray05;

			}
			&.order01--border-dashed {
				border-top: 1px dashed @color-gray05;

			}
			&.order01--separator {
				&:before {
					content: "";
					height: 0;
					display: block;
					border-top: 1px dashed @color-gray05;
					padding-top: 30px;
				}
			}
			&.order01--pushed {
				margin: 20px 0 20px 145px;
				DIV.b {
					width: 30%;
				}
				@media @media-notdesktop {
					margin: 0;
				}
			}
			@media @media-mobile {
				padding: 20px 20px 0 110px;
				.price01 {
					width: 100%;
				}
				.img01 {
					width: 85px;
					margin-left: -100px;
					max-width: 85px;
				}
			}
		}
	}
	@media @media-notdesktop {
		padding: 10px;
		.order01 {
			margin: 20px 0 0 0;
			DIV.a,
			DIV.b {
				float: none;
				width: auto;
			}
			DIV.b {
				margin: 1em 0 0;
			}
			.img01 {
				margin-left: 0;
			}
			.price01 {
				display: none;
				width: auto;
				float: none;
			}
			.heading01 {
				padding-left: 130px;
				.price01 {
					display: block;
				}
			}
			.text01 {
				margin: 0;
				clear: both;
				padding-top: 1em;
				white-space: normal;
				word-break: break-word;
			}
			.status01 {
				width: auto;
				float: none;
			}
			&.order01b {
				.heading01 {
					padding-left: 0;
				}
				DIV.a,
				DIV.b {
					float: none;
					width: auto;
				}
			}
		}

		.total {
			display: block;
			.col {
				width: auto;
				/*padding: 20px 0;*/
				border-left: none;
				border-top: 1px dashed @color-gray-light;
				&:first-child {
					border-top: none;
				}
				font-size: 13px;
				width: auto !important;
				float: none;
				margin: 0 !important;
			}
		}
	}
	@media @media-mobile {
		.total {
			.col {
				padding: 20px 0;
			}
		}
	}
}

DIV.history01 {
	@media @media-mobile {
		margin: 1em -15px 0;
	}
	TABLE {
		TR {
			&.active {
				TD {
					font-weight: bold;
				}
			}
			&.main {
				cursor: pointer;
			}
			&:hover > TD {
				background: #d3effc;
				background-clip: padding-box;
			}
			TD {
				word-break: normal;
				&.footable-first-column {
					border-left: lighten(@color-gray-lightest, 5%);
				}
				&.footable-last-column {
					border-right: lighten(@color-gray-lightest, 5%);
				}
			}
		}
		TR.footable-detail-show {
			> TD {
				position: relative;
				&:after {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					content: "";
					display: block;
					clear: both;
					background: @color12;
					height: 2px;
					margin: -2px;
				}
				&.footable-first-column {
					border-left: 2px solid @color12;
				}
				&.footable-last-column {
					border-right: 2px solid @color12;
				}
			}
		}
		TR.footable-row-detail {
			> TD {
				border-top: none;
				border-left: 2px @color12 solid;
				border-right: 2px @color12 solid;
				position: relative;
				&:after {
					content: "";
					display: block;
					clear: both;
					background: @color12;
					height: 2px;
					margin: 10px -10px -10px;
				}
			}

		}
		table-layout: fixed;
		SPAN.status {
			display: block;
			padding-left: 30px;
			position: relative;
			&.desktop-hide {
				display: none;
				@media @media-mobile {
					display: block;
				}
			}
			&:before {
				font-family: "icons";
				position: absolute;
				left: 0;
				top: 0;
				width: 22px;
				height: 22px;
				font-size: 12px;
				.rounded(50%);
				/*background: @color-gray-light;*/
				color: @color-white;
				content: "";
				text-align: center;
				line-height: 22px;
			}
			&.status-ok {
				H4 {
					color: @color-positive;
				}
				&:before {
					background: @color-positive;
					content: @ico-check;
				}
			}
			&.status-error {
				H4 {
					color: @color-negative;
				}
				&:before {
					background: @color-msg-negative;
					content: @ico-exclamation;
				}
			}

		}
		.footable-row-detail-row {
			word-wrap: normal;
			margin: 1em 0 0 0;
			.footable-row-detail-name {
				width: 130px;
				margin-right: 0 !important;
				font-weight: normal !important;
				color: @color-gray-medium;
			}
			.footable-row-detail-value {
				width: 100%;
				float: left;
				margin-left: -130px;
				padding-left: 140px;
				.box-sizing(border-box);
			}
			&:last-child {
				.footable-row-detail-value {
					margin-left: 0;
					padding-left: 0;
				}
			}
		}
		@media @media-mobile {
			table-layout: auto;
			margin: 0;
			width: 100%;
		}
	}
	.history01__mrg {
		margin: 0 28px;
		@media @media-mobile {
			margin: 0;
		}
	}
	&.history01__pad {
		TABLE {
			TR.footable-row-detail {
				> TD {
					padding: 10px 0;
					&:after {
						margin: 10px 0 -10px;
					}
				}

			}
		}
		@media @media-mobile {
			TABLE {
				TR.footable-row-detail {
					> TD {
						padding: 10px 10px;
						&:after {
							margin: 10px -10px -10px;
						}
					}

				}
			}
		}
	}

}

DIV.items02 {
	.display(flex);
	.align-items(flex-end);
	.justify-content(space-between);
	DIV.item {
		float: left;
		padding-right: 20px;
		&.f-right {
			padding-right: 0;
		}
		P {
			margin: 0;
			&.heading01 {
				font-family: @font-heading;
				font-size: ceil(@base-font-size*1.7);
				font-weight: 100;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
		}
	}
	@media @media-mobile {
		.flex-direction(column); .flex-wrap(nowrap);
		DIV.item {
			float: none !important;
			width: 100%;
			padding-right: 0;
			margin: 1em 0 0;
			&:first-child {
				margin: 0;
			}
		}
	}
}

UL.items03 {
	margin: 1em 0 0;
	clear: both;
	&.margt {
		margin: 2em 0 0;
	}
	& > LI {
		background: none;
		padding: 0;
		margin: 10px 0 0 0;
		overflow: hidden;
		&:before {
			display: none;
		}
	}
	UL {
		margin: .5em 0 0 0;
	}
	DIV.pad {
		padding: 5px;
		overflow: hidden;
		border: 1px solid @color-gray-medium-lightest;
		+ DIV.c {
			border-top: none;
		}
	}
	UL.actions01.f-right {
		margin: -15px 15px 0 0 !important;
		LI {
			float: left;
		}
		A {
			padding-bottom: 0 !important;
		}
	}
	.col-a {
		width: 58%;
		float: left;
	}
	.col-b {
		width: 41%;
		float: right;
	}
	.col-c {
		width: 24%;
		float: left;
		margin-left: .5%;
		&:first-child {
			margin-left: 0;
			margin-right: .5%;
		}
	}
	.col-d {
		width: 51%;
		float: left;
	}

	DIV.a {
		overflow: hidden;
		border: 1px solid @color-gray-medium-lightest;
		border-bottom: none;
		padding: 10px 15px;
		margin: 0;
		font-weight: normal;
		font-size: 11px;
		P {
			margin: .5em 0 0 0;
			&.ico-alert:before {
				top: .45em;
			}
			&.title {
				color: @color01;
				font-weight: normal;
				margin: 0;
				font-size: 15px;
				padding: 0;
			}

		}
	}
	DIV.b {
		overflow: hidden;
		&.margt {
			margin-top: 5px;
		}
	}
	DIV.c {
		padding: 10px 15px;
		border: 1px solid @color-gray-lightest;
		&:hover {
			background: lighten(@color-gray-lightest, 5%);
			border-color: @color-gray-medium-lightest;
		}
		+ DIV.pad {
			border-color: @color-gray-lightest;
			border-top: none;
		}
		P {
			margin: 10px 0 0 0;
			&.ico-alert {
				font-size: 12px;
				margin: 0;
				&:before {
					top: 5px;
				}
			}
			&.title {
				color: @color01;
				font-weight: normal;
				margin: 0;
				font-size: 15px;
				padding: 0;
			}
			&.small {
				font-size: 13px;
			}
		}
		.col {
			margin: 5px 2% 0 0;
			width: 30%;
			font-size: 13px;
			line-height: 1.5;
		}
		UL.actions01.f-right {
			margin-right: 0 !important;
		}
	}
	P.number {
		margin: 0;
		padding: 0;
		float: left;
		text-align: center;
		width: 50%;
		.box-sizing(border-box);
		font-size: 13px;
		font-weight: bold;
		border: 1px solid @color-gray-medium-lightest;
		height: 35px;
		position: relative;
		A,
		SPAN {
			display: block;
			padding: 7px 0;
			text-decoration: none;
		}
		SMALL {
			display: block;
			font-size: 11px;
			font-weight: normal;
			opacity: 0.7;
			margin: 2px 0 -3px 0;
		}
		&.ico-alert {
			&:before {
				display: none;
			}
			SPAN:before {
				font-family: "icons";
				content: @ico-alert;
				color: @color-negative;
				margin-right: 5px;
			}
		}
		&:first-child {
			border-right: none;
			z-index: 2;
			&:after {
				content: "";
				width: 37px;
				height: 37px;
				.box-sizing(border-box);
				position: absolute;
				right: -19px;
				top: -2px;
				-ms-transform: rotate(45deg) scale(0.68);
				-moz-transform: rotate(45deg) scale(0.68);
				-webkit-transform: rotate(45deg) scale(0.68);
				-o-transform: rotate(45deg) scale(0.68);
			}
			&:last-child {
				width: 100%;
				border-right: 1px solid @color-gray-medium-lightest;
				height: auto;
				min-height: 35px;
				&:after {
					display: none;
				}
			}

		}
		&.status01 {
			background: lighten(@color-gray-lightest, 5%);
			border-color: @color-gray-medium-lightest;
			color: @color-black;
			&:first-child {
				&:last-child {
					border-right: 1px solid @color-gray-medium-lightest;
				}
				&:after {
					background: lighten(@color-gray-lightest, 5%);
					border-right: 1px solid @color-gray-medium-lightest;
					border-top: 1px solid @color-gray-medium-lightest;
				}
			}
			&.ico-alert {
				&:before {
					display: none;
				}
				SPAN:before {
					font-family: "icons";
					content: @ico-alert;
					color: @color-negative;
					display: inline-block;
					width: 16px;
					height: 16px;
					margin-right: 5px;
					position: relative;
					top: .45em;
				}
			}
		}
		&.status02 {
			background: @color-white;
			border-color: @color-gray-medium-lightest;
			&:first-child:after {
				background: @color-white;
				border-right: 1px solid @color-gray-medium-lightest;
				border-top: 1px solid @color-gray-medium-lightest;
			}
		}
	}
	UL.checkboxes {
		margin: .4em 0 0 0;
		padding: 0;
		LI {
			background: none;
			padding: 0 !important;
			INPUT[type="radio"],
			INPUT[type="checkbox"] {
				top: 0;
			}
			LABEL {
				cursor: pointer;
				&:last-child {
					margin-right: 0 !important;
				}
				A.ask {
					margin-top: 0;
				}
			}

		}
	}

	@media @media-mobile {
		LI .c {
			.clearfix();
		}

		UL.actions01.f-right {
			margin: 0 !important;
			padding-bottom: 0 !important;
			LI {
				margin: 0 !important;
				A.btn {
					float: right;
					padding: 0 !important;
				}
			}
		}

		A.btn.add {
			text-align: center;
		}

		P.hasico.ico-alert,
		DIV.details {
			clear: both;
		}

		P.number {
			width: 100%;
			&.status01 {
				border-right: 1px solid @color-gray-medium-lightest;
				border-bottom: none;
			}
			&:after {
				display: none;
			}
		}

		.col-a {
			width: 100%;
			float: none;
		}

		.col-b,
		.col-c,
		.col-d {
			width: 100%;
			float: none;
			margin: 5px 0 0 0 !important;
		}
	}
}

UL.items04 {
	margin: .5em 0 0;
	padding: 0;
	list-style: none;
	LI {
		&:before {
			display: none;
		}
		padding: 0;
		margin: 5px 0 0;
	}
	A {
		display: block;
		overflow: hidden;
	}
	SPAN.img {
		float: left;
		width: 65px;
		IMG {
			max-width: 100%;
			height: auto;
			max-height: 65px;
			width: auto;
		}
	}
	SPAN.txt {
		font-weight: bold;
		float: left;
		width: 100%;
		padding-left: 80px;
		margin-left: -65px;
		.box-sizing(border-box);
		&.meta {
			font-weight: normal;
			color: @color-gray-medium;
		}
	}
	DIV.ac02 DIV.pad DIV.w50p & {
		margin: .5em -20px 0 -10px;
		A {
			padding: 5px 20px 0 10px;
		}
	}
	DIV.ac02 DIV.pad DIV.w50p:first-child & {
		margin: .5em 0 0 -20px;
		A {
			padding: 5px 20px 0 20px;
		}
	}
	DIV.ac DIV.pad & {
		margin: .5em -20px 0;
		LI {
			margin: 0;
		}
		A {
			padding: 5px 20px 0;
			&:hover,
			&:focus,
			&.hover,
			&.active {
				background: @color-gray-lightest;
				color: @color02;
				outline: none;
			}
		}
	}
	@media @media-mobile {
		SPAN.img {
			display: none;
		}

		SPAN.txt {
			margin-left: 0;
			padding-left: 0;
		}

		DIV.ac DIV.pad & {
			A {
				padding: 10px 20px;
			}
		}
	}
}

DIV.items05 {
	DIV.row {
		position: relative;
		.clearfix();
		margin: 30px 0 0 0;
	}
	DIV.item {
		width: 31.3333%;
		margin: 0 1%;
		.box-sizing(border-box);
		float: left;
		position: static;
		&:before {
			content: "";
			background: lighten(@color-gray-lightest, 5%);
			position: absolute;
			width: 31.3333%;
			top: 0;
			bottom: 0;
		}
		DIV.a {
			position: relative;
			background: @color-gray-light;
			padding: 30px;
			text-align: center;
			A {
				display: block;
				height: 380px;
				IMG {
					max-height: 100%;
					max-width: 100%;
					height: auto;
					width: auto;
				}
			}
		}
		DIV.b {
			position: relative;
			text-align: center;
			padding: 30px 30px 210px 30px;
		}
		P {
			margin: 0;
			&.heading01 {
				font-family: @font-heading;
				.ru & {
					font-family: @font-heading-ru;
				}
				A {
					color: @color02;
					text-decoration: none;
					font-size: 20px;
					border-bottom: 1px solid transparent;
					&:hover {
						border-bottom-color: @color02;
					}
				}
			}
			&.meta01 {
				font-weight: bold;
				color: @color-gray-dark;
				font-size: 15px;
			}
			&.price01 {
				font-size: 17px;
				white-space: nowrap;
				STRONG {
					font-weight: normal;
					font-size: 38px;
				}
			}
			&.meta02 {
				font-size: 15px;
				color: @color-gray-dark;
				line-height: 1;
				height: 2em;
				overflow: hidden;
			}
		}
		DIV.c {
			position: absolute;
			bottom: 30px;
			width: 31.3333%;
			padding: 0 30px;
			.box-sizing(border-box);
			text-align: center;
		}
		UL.actions01 {
			margin: 0;
			LI {
				float: none;
				.btn {
					display: block;
					text-align: center;
					&.discrete {
						border-bottom: none;
					}
				}
			}
		}
	}
	.in2cols {
		DIV.item {
			&,
			&:before,
			DIV.c {
				width: 48%;
			}
		}
	}
	@media @media-mobile {
		DIV.item {
			width: 100%;
			margin: 20px 0 0;
			background: lighten(@color-gray-lightest, 5%);
			&:before {
				display: none;
			}
			DIV.b {
				padding: 20px;
			}
			DIV.c {
				padding: 0 20px 20px;
				width: 100% !important;
				position: relative;
				bottom: 0;
			}
			P.meta02 {
				height: auto;
			}
			P.price01 {
				margin: 0;
			}
			UL.actions01 {
				margin: 1em 0 0 0;
			}
		}

		.in2cols {
			DIV.item {
				&,
				&:before,
				DIV.c {
					width: 100%;
				}
			}
		}

	}
}

DIV.items06 {
	.display(flex);
	margin: 0;
	border: 2px @color-gray-lightest;
	border-style: solid none;
	DIV.item {
		width: 50%;
		float: left;
		.box-sizing(border-box);
		padding: 25px 30px;
		border-left: 2px solid @color-gray-lightest;
		&:first-child {
			padding-left: 0;
			border-left: none;
		}
		P {
			margin: 0;
			line-height: 1.4;
			&.heading01 {
				font-family: @font-heading;
				font-size: ceil(@base-font-size*1.7);
				font-weight: 100;
				line-height: 1.2;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
			&.heading02 {
				font-size: ceil(@base-font-size*3.6);
				font-weight: bold;
				line-height: 1;
				font-family: @font-heading;
				padding-bottom: 10px;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
			&.note {
				font-size: @base-font-size;
				margin-top: 2em;
			}
		}
		.btn {
			font-size: ceil(@base-font-size*1.84);
			font-weight: bold;
			font-family: @font-heading;
			.ru & {
				font-family: @font-heading-ru;
			}
			&.discrete {
				font-family: @font-main;
				font-weight: normal;
				font-size: ceil(@base-font-size*1.125);
				padding-top: 1em;
			}
		}
	}
	.col23 & {
		.flex-direction(column);
		.flex-wrap(nowrap);
		margin: 1.5em 0 0 0;
		DIV.item {
			width: 100%;
			float: none;
			padding: 25px 0;
			border-top: 2px solid @color-gray-lightest;
			border-left: none;
			&:first-child {
				border-top: none;
			}
			P {
				&.heading02 {
					font-size: ceil(@base-font-size*3);
				}
			}
		}
	}
	@media @media-tablet {
		DIV.item {
			P {
				&.heading02 {
					font-size: ceil(@base-font-size*2.5);
				}
			}
		}
	}
	@media @media-mobile {
		.flex-direction(column); .flex-wrap(nowrap);
		DIV.item {
			width: 100%;
			float: none;
			padding: 25px 0;
			border-top: 2px solid @color-gray-lightest;
			border-left: none;
			&:first-child {
				border-top: none;
			}
			P {
				&.heading02 {
					font-size: ceil(@base-font-size*3.5);
				}
			}
		}
	}
}

UL.items07 {
	list-style: none;
	margin: 2em 0 0 0;
	padding: 0;
	border-top: 2px solid @color-gray-medium-lightest;
	LI {
		margin: .5em 0 0 0;
		padding: 0 0 .5em;
		border-bottom: 2px solid @color-gray-medium-lightest;
		&:before {
			display: none;
		}
		&.open {
			A.expander:before {
				content: @ico-minus;
			}
			DIV.b {
				display: block;
			}
		}
	}
	A.expander {
		color: @color-black;
		display: block;
		&:hover {
			text-decoration: underline;
		}
		&:before {
			.elisaicon();
			content: @ico-plus;
			position: relative;
			top: 3px;
			float: left;
			width: 16px;
			height: 16px;
			color: @color15;
			font-size: 8px;
			line-height: 16px;
			text-align: center;
			.rounded(8px);
			margin-right: 8px;
		}
	}
	DIV.b {
		display: none;
		padding-left: 24px;
	}
	@media @media-mobile {
		DIV.b {
			padding-left: 0;
			border-top: 1px solid @color-gray-light;
			padding-top: 5px;
		}

		A.expander {
			STRONG {
				display: block;
				float: none;
				margin-left: 25px;
			}
		}

		TABLE {
			margin: 0;
		}
	}
}

.items08 {
	list-style: none;
	margin: 2em 0 0 0;
	padding: 0;
	overflow: hidden;
	LI {
		padding: 0 !important;
		width: 100px;
		float: left;
		margin: 10px 10px 0 0;
		&:before {
			display: none;
		}
		&:nth-of-type(n+10) {
			display: none;
		}
		&:last-child {
			display: block !important;
		}
		.showall& {
			display: block !important;
			&:last-child {
				display: none !important;
			}
		}
	}
	SPAN {
		display: block;
		&.img {
			width: 100px;
			height: 100px;
			.rounded(50%);
			overflow: hidden;
			IMG {
				display: block;
				width: 100%;
				height: auto;
			}
			A {
				text-align: center;
				width: 100px;
				height: 100px;
				display: block;
				text-decoration: none;
				border: 5px solid @color02;
				.box-sizing(border-box);
				.rounded(50%);
				line-height: 90px;
				font-size: 30px;
				font-weight: bold;
				color: @color01;
			}
		}
		&.txt {
			text-align: center;
			margin: 1em 0 0;
			word-wrap: break-word;
		}
	}
	@media @media-wide {
		LI {
			margin-right: 15px;
		}
	}
	@media @media-notwide {
		LI {
			margin-right: 8px;
		}

		LI:nth-of-type(n+9) {
			display: none;
		}
	}
	@media @media-tablet {
		LI:nth-of-type(n+6) {
			display: none;
		}
	}
	@media @media-mobile {
		LI {
			.clearfix();
			width: auto;
			float: none;
			margin: 20px 0 0;
			&:first-child {
				margin: 0;
			}
			&:nth-of-type(n+4) {
				display: none;
			}
		}

		SPAN.img {
			float: left;
		}

		SPAN.txt {
			padding-left: 120px;
			padding-top: 20px;
			text-align: left;
		}
	}
}

DIV.items09 {
	overflow: hidden;
	position: relative;
	DIV.col {
		float: none;
		width: 100%;
		margin-top: 1em !important;
		.box-sizing(border-box);
		position: relative;
		&:first-child {
			&:after {
				font-family: "icons";
				content: @ico-arrdown;
				position: absolute;
				left: 50%;
				top: 102%;
				font-size: 24px;
				text-align: center;
			}
		}
	}
	@media @media-wide {
		.display(flex); .justify-content(space-between);
		DIV.col {
			width: 48% !important;
			.flex(0 0 48%);
			.display(flex);
			.flex-direction(column);
			.justify-content(space-between);
			&:first-child {
				float: left;
				&:after {
					content: @ico-arrright;
					left: 102%;
					top: 50%;
				}
			}
			&:last-child {
				float: right;
			}
		}

	}
}

UL.items10 {
	.display(flex);
	.flex-wrap(wrap);
	.justify-content(center);
	&.items10b {
		.justify-content(space-between);
	}
	&.in2cols {
		LI {
			max-width: 48%;
			width: 48%;
			@media @media-mobile {
				max-width: 100%;
				width: 100%;
			}
		}

	}
	&.type02 {
		LI {
			position: relative;
			padding: 0 15px 0 130px;
			margin-top: 2em;
			text-align: left;
			min-height: 120px;
			IMG {
				position: absolute;
				left: 0;
				top: 0;
			}
			@media @media-mobile {
				padding-left: 20%;
			}
		}
	}
	LI {
		max-width: 200px;
		background: none !important;
		padding: 0 15px;
		.box-sizing(border-box);
		&:before {
			display: none !important;
		}
		H2 {
			color: @color-black;
			font-weight: bold;
			font-size: 24px;
			margin: 0;
		}
		P {
			color: @color-black;
			margin: 0;
			font-size: 16px;
			padding: 0;
		}
	}
	@media @media-mobile {
		LI {
			.align-items(center);
			max-width: 100%;
			width: 100%;
			.display(flex);
			text-align: left;
			P, H2 {
				padding: 0 20px;
			}
			IMG {
				max-width: 20% !important;
			}
		}
	}
	@media @media-tablet {
		LI {
			max-width: 150px;
		}
	}
	@media @media-notwide {
		LI {
			max-width: 150px;
		}
	}
	@media @media-notmobile {
		LI {
			P {
				padding-top: 20px !important;
			}
		}
	}
}

UL.items11 {
	.display(flex);
	.flex-wrap(wrap);
	.justify-content(center);
	LI {
		padding: 0;
		max-width: 200px;
		padding: 0px 45px;
		&:before {
			display: none;
		}
		P {
			color: @color-black;
			margin: 0;
			font-size: 16px;
			padding: 0 5px;
		}
	}
	@media @media-mobile {
		LI {
			.align-items(center);
			max-width: 100%;
			width: 100%;
			.display(flex);
			text-align: left;
			P {
				padding: 0 20px;
			}
			IMG {
				max-width: 20% !important;
			}
		}
	}
	@media @media-tablet {
		LI {
			max-width: 150px;
		}
	}
	@media @media-notwide {
		LI {
			max-width: 150px;
		}
	}
	@media @media-notmobile {
		LI {
			P {
				padding-top: 20px !important;
			}
		}
	}
}

.items12 {
	margin: 2em 0 0 0 !important;
	.display(flex);
	.flex-wrap(nowrap);
	@media @media-mobile {
		.justify-content(center);
	}
	IMG {
		width: 100%;
	}
	.animation01 {
		A.img {
			&:hover {
				z-index: 999999;
			}
			transition: all 0.5s linear;
		}
		&:hover {
			z-index: 99;
		}
		A:hover {
			transform: scale3d(1.1, 1.1, 1);
		}
		IMG:hover {
			transform: scale3d(1.1, 1.1, 1);
		}
		IMG {
			transform: scale3d(1.1, 1.1, 1);
		}
	}
	.animation02 {
		A.img {
			overflow: hidden;
			z-index: 99;
			&:hover {
				IMG {
					transform: scale3d(1.2, 1.2, 1);
				}
			}
			IMG {
				transition: all 0.5s linear;
				transform: scale3d(1, 1, 1);
			}
		}

	}
	A.img {
		display: block;
		position: relative;
		max-width: 300px;
		margin: auto;
		max-height: 435px;
		overflow: hidden;
		&:before {
			display: block;
			content: "";
			padding-bottom: 145%;
		}
		IMG {
			max-width: none !important;
			position: absolute;
			top: 0;
			left: 0;
			height: 100% !important;
			width: auto !important;
		}
	}
	.items {
		padding: 0 5px;

		max-width: 300px;
		width: 12.5%;
		margin: 2em 0 0 0;
		.box-sizing(border-box);
		.flex(0 0 12.5%);
		@media @media-notwide {
			width: 16.6666%;
			.flex(0 0 16.6666%);
		}
		@media @media-tablet {
			width: 25%;
			.flex(0 0 25%);
		}
		@media @media-mobile {
			width: 100%;
			.flex(0 0 100%);
		}
		float: left;
		border: none;
		outline: none;
		&.slick-slide {
			margin: 0;
			width: auto;
			max-width: none;
		}
		&:hover {
			border: none;
			outline: none;
		}
		&:active {
			border: none;
			outline: none;
		}
		&:focus {
			border: none;
			outline: none;
		}
		&:visited {
			border: none;
			outline: none;
		}
	}
	SPAN.heading02 {
		font-size: 20px;
		display: block;
		max-height: 3.28em;
		overflow: hidden;
		color: @color01;
	}
	SPAN.meta01 {
		font-size: 15px;
		display: block;
		max-height: 3.28em;
		overflow: hidden;
		color: @gray;
	}
}

.items13 {
	.flex-wrap(nowrap);
	.display(flex);
	overflow: hidden;
	.slick-dots {
		text-align: center;
		display: none !important;
		@media @media-notdesktop {
			display: block !important;
		}
		bottom: 45px;
		left: 0;
		right: 0;
		position: absolute;
		LI {
			display: inline-block;
			float: none;
			& > BUTTON {
				line-height: 0;
				border-radius: 100%;
				border: 1px;
				display: block;
				width: 20px;
				height: 20px;
				padding: 5px;
				.shadow(5px, @color-black, 50%);
				cursor: pointer;
				outline: none;
				background: fade(@color-white, 40%);
				text-indent: -10000px;
				&:hover {
					outline: none;
				}
			}
			&.slick-active > BUTTON {
				outline: none;
				background: @color-white;
			}
		}
	}
	.slick-next,
	.slick-prev {
		color: fade(@color-white, 40%) !important;
		&:hover {
			color: @color-white !important;
		}
	}
	A.play {
		text-align: center;
		padding-top: 120px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			background: @color-white;
			box-shadow: 0 6px 6px -6px black;
			-webkit-box-shadow: 0 6px 6px -6px black;
			-moz-box-shadow: 0 6px 6px -6px black;
			width: 110px;
			height: 110px;
			text-align: center;
			color: @color01;
			display: block;
			margin: 0 auto;
			transform: scale3d(1, 1, 1);
			.rounded(50%);
		}
		&:after {
			color: @color01;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			font-family: icons;
			font-size: 48px;
			content: @ico-play01;
			padding-left: 11px;
			line-height: 110px;
			transform: scale3d(1.02, 1.02, 1);
		}
		&:hover {
			border: none;
			outline: none;
			&:before,
			&:after {
				transform: scale3d(1.02, 1.02, 1);
			}
		}
		&:active {
			border: none;
			outline: none;
		}
		&:focus {
			border: none;
			outline: none;
		}

	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		background: fade(@color01, 70%) !important;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
	}
	IFRAME {
		opacity: 0;
	}
	A {
		color: @color-white;
		display: block;
		font-size: 22px;
	}
	DIV.img {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		background: url(../img/eraam/videobg01.jpg) no-repeat 50% 50%;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		overflow: hidden;
		height: 615px;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
		P {
			font-size: 24px;
			color: @color-white;
			padding-bottom: 30px;
		}
		H2 {
			color: @color-white;
			margin-top: 0;
		}
	}
	.items {
		max-width: 100%;
		width: 100%;
		height: 615px;
		.flex(0 0 100%);
		float: left;
		position: relative;
		border: none;
		outline: none;
		&:hover {
			border: none;
			outline: none;
		}
		&:active {
			border: none;
			outline: none;
		}
		&:focus {
			border: none;
			outline: none;
		}
		&.active {
			background: @color-black;
			.display(flex);
			.flex-direction(column);
			.justify-content(center);
			.align-content(center);
			.align-items(center);
			IFRAME {
				opacity: 1;
				z-index: 2;
			}
			.img {
				display: none;
			}
		}
	}
	@media @media-desktop {
		height: 615px;
	}
	@media @media-mobile {
		H2 {
			font-size: 22px;
		}

		P {
			font-size: 16px;
		}

		A {
			font-size: 16px;
		}

		A.play {
			&:before {
				width: 87px;
				height: 87px;
			}
			&:after {
				font-size: 29px;
				line-height: 87px;
			}
		}

		.slick-dots LI BUTTON {
			width: 15px;
			height: 15px;
		}

		.slick-dots {
			bottom: 10px;
		}

		DIV.items {
			height: 335px;
		}

		DIV.img {
			height: 335px;
			& P {
				padding-bottom: 15px;
				font-size: 16px;
			}
		}
	}
}

.items14 {
	margin-bottom: 166px;
	.block01.expandable {
		border-left: 2px solid @color12;
		A.toggler:before {
			border-radius: 100%;
			background: @color12;
			width: 30px;
			height: 30px;
			text-align: center;
			line-height: 30px;
		}
	}
	H2.blockheading {
		font-size: 22px !important;
	}
	@media @media-mobile {
		margin-bottom: 38px;
		P {
			font-size: 14px;

		}
	}
}

.items15 {
	.display(flex);
	margin: 0;
	border: 2px @color-gray-lightest;
	border-style: solid none;
	border-bottom: none;
	border-top: none;
	DIV.item {
		width: 33.3333%;
		float: left;
		.box-sizing(border-box);
		padding: 25px 30px;
		border-left: 2px solid @color-gray-lightest;
		max-width: 33.3333%;
		min-width: 33.3333%;
		.display(flex);
		.flex-direction(column);
		flex: 0 0 33%;
		UL {
			&.nostyle > LI {
				padding: 0;
				&:before {
					display: none;
				}
			}
		}
		&:first-child {
			padding-left: 0;
			border-left: 0;
			border-top: 0;
		}
		P {
			margin: .5em 0 0;
			&.heading01 {
				font-family: @font-heading;
				font-size: ceil(@base-font-size*1.7);
				font-weight: 100;
				margin: 0;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
			&.heading02 {
				font-size: ceil(@base-font-size*3.6);
				font-weight: bold;
				font-family: @font-heading;
				padding-bottom: 10px;
				margin: 0;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
			&.note {
				font-size: @base-font-size;
				margin: 1em 0 1em 0;
			}
		}
		.ico-info {
			&:before {
				color: @color01;
			}
		}
	}
	.col23 & {
		.flex-direction(column);
		.flex-wrap(nowrap);
		margin: 1.5em 0 0 0;
		DIV.item {
			width: 100%;
			float: none;
			padding: 25px 0;
			border-top: 2px solid @color-gray-lightest;
			border-left: none;
			&:first-child {
				border-top: none;
			}
			P {
				&.heading02 {
					font-size: ceil(@base-font-size*3);
				}
			}
		}
	}
	@media @media-tablet {
		DIV.item {
			P {
				&.heading02 {
					font-size: ceil(@base-font-size*2.5);
				}
			}
		}
	}
	@media @media-mobile {
		.flex-direction(column); .flex-wrap(nowrap);
		DIV.item {
			border-top: 2px dashed @color-gray-lightest;
			width: 100%;
			float: none;
			padding: 25px 0;
			border-left: none;
			flex: 0 0 auto;
			max-width: 100%;
			min-width: 100%;
			&:first-child {
				border-top: 0;
				padding-top: 0;
			}
			P {
				&.heading02 {
					font-size: ceil(@base-font-size*1.7);
				}
			}
		}
	}
}

.items16 {
	.display(flex);
	.flex-direction(row);
	.flex-wrap(wrap);
	margin: 0;
	border: none;
	margin: 0 -1%;
	margin-bottom: 2em;
	.w33p2 & {
		.item02 {
			margin-right: 2%;
			min-height: 764px;
			height: auto;
			max-height: 100%;
		}
	}
	.col.w33p2 & {
		margin-left: -14px;
	}
	DIV.item02 {
		&.flex-w100p {
			flex: 1 1 auto;
			max-width: 98%;
			width: 98%;
		}
		&.flex-w50p {
			flex: 1 1 auto;
			max-width: 48%;
			width: 48%;
		}
		&.item02b {
			.pad {
				padding: 30px 20px 30px 20px;
				.txt {
					width: 100%;
					padding: 60px 30px 0 30px;
				}
			}
			SPAN.badge01 {
				background: @color-positive;
				color: @color-white;
				position: absolute;
				left: 0;
				top: 30px;
				padding: 10px 5px;
				font-size: 17px;
				font-weight: bold; /*width: 175px;*/
				width: auto;
				text-align: center;
				.box-sizing(border-box);
				&.legend14 {
					&:before {
						font-size: 23px;
						color: white;
						margin-right: 10px;
					}
				}
			}
		}
		&.item02c {
			.pad {
				background: none;
				.overlay-img {
					width: 100%;
					height: 100%;
					position: absolute;
					.box-sizing(border-box);
					left: 0;
					top: 0;
					display: block;
					max-width: 100%;
					min-height: 100%;
					min-width: 100%;
					margin: auto;
					max-height: 100%;
					overflow: hidden;
					-webkit-background-size: cover !important;
					-moz-background-size: cover !important;
					-o-background-size: cover !important;
					background-size: cover !important;
					&.overlay-img:after {
						position: absolute;
						content: " ";
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;
						z-index: 0;
						background: fade(@color01, 70%) !important;
						z-index: 0;
					}
				}
				.txt {
					width: 100%;
					padding: 60px 0 0 0;
					position: relative;
					.ico-star01:before {
						margin: 0;
						color: @color02;
						.box-sizing(border-box);
						position: relative;
						top: .3em;
						overflow: hidden;
						float: none;
						right: 5px;
					}
					SPAN.heading01 {
						color: @color02;
						.bold;
					}
					h2 {
						color: @color-white;
					}

					P.meta {
						color: @color-white;
						text-align: center;
					}
				}
				P.center {
					width: 100%;
					left: 0;
					right: 0;
				}
			}
		}
		.display(flex);
		.flex-direction(row);
		width: 31.33%;
		float: left;
		.box-sizing(border-box);
		margin: 10px 1%;
		flex: 0 0 31.33%;
		max-width: 31.333333%;
		min-height: 410px;
		overflow: hidden;
		.pad {
			.clearfix();
			padding: 30px 20px 90px 20px;
			width: 100%;
			background: @color-white;
			position: relative;
			.box-sizing(border-box);
			.img {
				max-width: 119px;
				width: 119px;
				padding: 0 0 20px 0;
				text-align: center;
				.box-sizing(border-box);
				margin: 0 auto;
				&IMG {
					max-width: 100%;
					height: auto;
				}
			}
			.txt {
				text-align: center;
				width: 100%;
				.box-sizing(border-box);
				P.meta {
				}

				h2 {
					padding-bottom: 20px;
					font-size: 22px;
					font-weight: bold;
					margin: 0;
					line-height: 1.2;
					color: #1a1a1a;
					word-wrap: break-word;
				}
			}
			P.center {
				position: absolute;
				bottom: 30px;
				display: block;
				width: 100%;
				left: 0;
				right: 0;
			}
		}
		&:first-child {
			padding-left: 0;
			border-left: none;
		}
		P {
			margin: 0;
			line-height: 1.4;
			&.heading01 {
				font-family: @font-heading;
				font-size: ceil(@base-font-size*1.7);
				font-weight: 100;
				line-height: 1.2;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
			&.heading02 {
				font-size: ceil(@base-font-size*3.6);
				font-weight: bold;
				line-height: 1;
				font-family: @font-heading;
				padding-bottom: 10px;
				.ru & {
					font-family: @font-heading-ru;
				}
			}
			&.note {
				font-size: @base-font-size;
				margin-top: 2em;
			}
		}
		.btn {
			/* font-size: ceil(@base-font-size*1.84); font-weight: bold; font-family: @font-heading;*/
			&.discrete {
				font-family: @font-main;
				font-weight: normal;
				font-size: ceil(@base-font-size*1.125);
				padding-top: 1em;
			}
		}
	}
	@media @media-notdesktop {
		DIV.item02 {
			P {
				&.heading02 {
					font-size: ceil(@base-font-size*3.5);
				}
			}
			.pad {
				.img {
					float: none;
					margin: 0 auto;
					width: 120px;
				}
				.txt {
					text-align: center;
					float: none;
					margin-left: 0;
					width: auto;
				}
				P.center {
					width: 100%;
					left: 0;
					right: 0;
					.box-sizing(border-box);
					.btn {
						float: none;
						display: inline-block;
						text-align: center;
						width: auto;
					}
				}
			}
		}
	}
	@media @media-tablet {
		DIV.item02 {
			height: auto;
			min-height: 100%;
			max-height: 100%;
			&.flex-w50p {
				max-width: 98%;
				width: 98%;
			}
			&.item02c {
				.pad {
					.txt {
						padding: 60px 0 0 0;
					}
				}
			}
		}
	}
	@media @media-mobile {
		.flex-direction(column); .flex-wrap(nowrap);
		margin-bottom: 0;
		margin-top: 0;
		.w33p2 & {
			.item02 {
				min-height: 100%;
			}
		}

		DIV.item02 {
			&.flex-w50p {
				max-width: 98%;
				width: 98%;
			}
			width: 100%;
			float: none;
			height: auto;
			min-height: 100%;
			max-height: 100%;
			flex: 0 0 auto;
			max-width: 100%;
			&:first-child {
				border-top: none;
			}
			&.item02c {
				.pad {
					.txt {
						padding: 0 50px 0 50px;
					}
				}
			}
			&.item02b {
				.pad {
					padding: 20px;
					.txt {
						padding: 70px 30px 0 30px;
					}
				}
			}
		}
	}
}

.items17 {
	.w85p {
		width: 85%;
	}
	.w15p {
		width: 15%;
	}
	.flex70 {
		.flex(1 1 70%);
	}
	.flex30 {
		.flex(1 1 30%);
	}
	.flex66 {
		.flex(1 0 auto);
		width: 66.6666%;
	}
	.flex50 {
		.flex(1 1 50%);
	}
	.flex33 {
		.flex(1 0 auto);
		width: 33.3333%;
	}
	.flex25 {
		.flex(1 1 25%);
	}
	.flex00 {
		.flex(1 0 auto);
	}
	margin: 0;
	border: none;

	DIV.item02 {
		border: 1px @color-gray-lightest;
		background: lighten(@color-gray-lightest, 5%);
		display: block;
		margin: 0;
		border-style: solid solid none solid;
		&:last-child {
			border-style: solid;
		}
		&:last-child {
			border-style: solid;
		}
		& > .item:first-child {
			margin: 0;
			border: 0;
			&.cursor {
				cursor: default !important;
				&:hover {
					border: 0;
				}
			}
			DIV.subitems01 {
				border: 0;
			}
		}
		DIV.subitems01 {
			border: 0;
			.subitem {
				.img {
					&.inactive {
						IMG {
							.grayscale(100%);
						}
					}
				}
			}
		}
		& > .pad {
			& > .item:first-child {
				&:hover {
					border-top: 1px solid @color01 !important;
				}

			}
			padding: 0 30px 30px 30px;
			.item:last-child {
				margin: 0;
				border-top: 0;
				&:hover {
					border-top: 1px solid @color01;
					margin-top: -1px;
				}
			}
			.item {
				background: @color-white;
				.item-headings {
					.display(flex);
					/*margin: 0 30px 0 0;*/
					padding: 20px 20px;
					.box-sizing(border-box);
					.flex-direction(column);
					.heading01 {
						margin: 0;
						SPAN {
							color: @color01;
							font-family: @font-heading;
							font-weight: bold;
							font-size: 16px;
							margin: 0;
						}
					}
					.meta {
						margin: 0;
						SPAN {
							margin: .5em 0 0 0;
							font-size: 14px;
							color: @color-gray-medium;
							display: inline-block;
							font-weight: normal;
							font-family: @font-main;
							margin-right: 10px;
							&.orange {
								padding: 0;
								color: @color02
							}
						}

					}
					@media @media-notdesktop {
						width: 100%;
					}
				}
				.item-heading {
					margin: 0;
				}
				.subitems01 {
					&.subitems01b {
						.subitem {
							P {
								&.icon {
									padding-left: 20px;
									&:before {
										position: absolute;
										left: 0;
										top: 3px;
									}
								}

							}
						}
					}
					&.subitems01c {
						margin: 0;
					}
				}
			}
		}
	}
	DIV.item {
		min-height: 114px;
		height: 114px;
		cursor: pointer;
		overflow: hidden;
		.item-headings {
			.display(flex);
			/*margin: 0 30px 0 0;*/
			padding: 20px 20px;
			.box-sizing(border-box);
			.flex-direction(column);
			.heading01 {
				margin: 0;
				SPAN {
					color: @color01;
					font-family: @font-heading;
					font-weight: bold;
					font-size: 16px;
					margin: 0;
				}
			}
			.meta {
				margin: 0;
				SPAN {
					margin: .5em 0 0 0;
					font-size: 14px;
					color: @color-gray-medium;
					display: inline-block;
					font-weight: normal;
					font-family: @font-main;
					margin-right: 10px;
					&.orange {
						padding: 0;
						color: @color02
					}
				}

			}
			@media @media-notdesktop {
				width: 100%;
			}
		}
		&:hover {
			border: 1px solid @color01;
		}
		&:nth-child(n+2) {
			&:hover {
				margin-top: -1px;
			}
		}
		&:first-child {
			border-top: 1px solid @color-gray-lightest;
			&:hover {
				border: 1px solid @color01;
			}
		}
		&.separated {
			border-top: 1px solid @color-gray-lightest;
			margin-top: 2em;
			&:hover {
				border-top: 1px solid @color01;
			}
		}
		DIV.subitems01 {
			padding: 0;
			.display(flex);
			position: relative;
			border-left: 2px dashed @color-gray-lightest;
			.flex-direction(row);
			&.subitems01c {
				padding: 0;
				margin: 0 30px 0 0;
				.subitem {
					display: flex;
					.align-items(center);
					p {
						text-align: left;
						position: relative;
						margin: 20px 0;
						&.ico-plus {
							color: @color01;
						}
						&:before {
							font-size: 16px;
							color: #0019af;
							margin-right: 10px;
							font-weight: bold;
						}
					}
				}
			}
			.subitem + .subitem {
				border-left: 2px dashed @color-gray-lightest;
			}
			.subitem {
				display: flex;
				.align-items(center);
				.box-sizing(border-box);
				padding: 0 15px 0 30px;
				.img {
					padding: 0;
					width: 55px;
					float: left;
					.box-sizing(border-box);
					&IMG {
						max-width: 100%;
						height: auto;
					}
				}
				.txt {
					width: 100%;
					margin-left: -55px;
					padding: 0 20px 0 80px;
					float: left;
					.box-sizing(border-box);
					word-wrap: break-word;
					max-height: 100px;
					overflow: hidden;
					P {
						&.meta {
							margin: .5em 0 0 0;
							font-size: 14px;
							color: @color-gray-medium;
							font-weight: normal;
							font-family: @font-main;
						}
						&.meta01 {
							margin: .5em 0 0 0;
							font-size: 14px;
							color: @color02;
							font-weight: normal;
							font-family: @font-main;
							display: inline-block;
						}
						&.heading01 {
							color: @color-text-main;
							font-weight: bold;
							font-size: 16px !important;
							margin: 0 !important;
							font-family: @font-heading;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
				&.type02 {
					position: relative;
					border-left: 2px dashed @color-gray-light;
					&:before {
						background: transparent;
						position: absolute;
						left: 50%;
						z-index: 5;
						margin-left: -20px;
						top: -20px;
						text-align: center;
						width: 40px;
						height: 40px;
						line-height: 40px;
						.rounded(20px);
						border: 2px @color-gray-light;
						color: @color02;
						border-style: dashed none none;
					}
					&:after {
						background: @color-white;
						font-family: "icons";
						content: @ico-plus;
						font-size: 16px;
						position: absolute;
						left: 0;
						margin-left: -23px;
						top: 30%;
						text-align: center;
						width: 40px;
						height: 40px;
						line-height: 40px;
						.rounded(20px);
						border: 2px @color-gray-light;
						color: @color02;
						border-style: none none none dashed;
					}

				}
			}
		}
		.item-heading {
			.display(flex);
			margin: 0 30px 0 0;
			.box-sizing(border-box);
			.heading02 {
				color: @color01;
				font-family: @font-heading;
				font-weight: bold;
				font-size: 16px;
				margin: 0;
				padding: 20px 20px;
			}
			.heading01 {
				color: @color01;
				font-family: @font-heading;
				font-weight: bold;
				font-size: 16px;
				margin: 0;
				padding: 20px 20px;
				SPAN.meta {
					margin: .5em 0 0 0;
					font-size: 14px;
					color: @color-gray-medium;
					display: block;
					font-weight: normal;
					font-family: @font-main;
					&.orange {
						padding: 0;
						color: @color02
					}
				}
			}
		}
		.display(flex);
		.flex-direction(row);
		.justify-content(space-between);
		border: 1px solid @color-gray-lightest;
		border-top: 0;
		margin-top: 0;
	}
	@media @media-desktop {
		.w15p {
			max-width: 15%;
			min-width: 15%;
		}

		/*.w85p { max-width: 85%; min-width: 85%; }*/
		.item-heading {
			&.type02 {
				&.w15p {
					max-width: 15%;
					min-width: 14%;
				}
			}
		}

		.item-headings {
			&.type02 {
				&.w15p {
					max-width: 15%;
					min-width: 14%;
				}
			}
		}
	}
	@media @media-notdesktop {
		.flex-direction(column); .flex-wrap(nowrap);
		.flex66 {
			.flex(0 1 auto);
			width: 100%;
		}

		.flex33 {
			.flex(0 0 auto);
			width: 100%;
		}

		DIV.item {
			height: auto;
			min-height: 100%;
			.flex(0 0 auto);
			.flex-wrap(wrap);
			.display(flex);
			width: 100%;
			.flex-direction(column);
			margin-bottom: 20px;
			border-top: 1px solid #ebebeb;
			&:nth-child(n+2) {
				&:hover {
					margin-top: 0;
				}
			}
			&:first-child {
				margin-top: 0;
				&:hover {
					margin-top: 0;
				}
			}
			DIV.subitems01 {
				.flex-direction(column);
				.flex(0 0 auto);
				width: 100%;
				border-top: 1px solid #ebebeb;
				border-left: 0;
				.subitem + .subitem {
					border-left: 0;
					border-top: 2px dashed #ebebeb;
				}
				.subitem {
					width: 100%;
					border-left: 0;
					padding: 30px 20px;
					&:first-child {
						border-left: 0;
					}
					&.type02 {
						border-top: 2px dashed #e1e1eb;
						border-left: 0;
						&:after {
							left: 50%;
							top: -20px;
							border-style: dashed none none;
						}
					}
				}
				&.subitems01c {
					margin: 0;
				}
			}
			&.separated01 {
				border-top: 1px solid @color-gray-lightest;
				margin-top: 2em;
				&:hover {
					border-top: 1px solid @color01;
					margin-top: 2em;
				}
			}
			DIV.item-heading {
				.display(flex);
				.flex(0 0 auto);
				width: 100%;
				P.heading01 {
					padding: 20px 20px;
					SPAN.meta {
						display: inline-block;
					}
					SPAN {
						padding: 0 10px 0 0;
					}
				}
			}
		}
	}
	@media @media-mobile {
		DIV.item02 {
			margin: 2em 0 0 0;
			border-style: solid;
			&:first-child {
				margin: 0;
			}
		}
	}
}

UL.items18 {
	margin-bottom: 20px;
	list-style: none;
	padding: 0;
	.display(flex);
	.flex-wrap(wrap);
	margin: 0 -1%;
	&.items18b {
		margin: 10px -0.5%;
		.item {
			border: 1px solid @color-gray-lightest;
			max-width: 24%;
			.flex(0 0 24%);
			padding: 30px 20px;
			min-width: 24%;
			margin: 10px 0.5%;
			DIV.itemgroup {
				P {
					text-align: center;
					/*margin: 0;*/
				}
				DIV.pad {
					position: absolute;
					padding-bottom: 0;
					width: 100%;
					.box-sizing(border-box);
					left: 0;
					top: 0;
					.img {
						text-align: center;
					}
				}
				DIV.txt {
					width: 100%;
					text-align: center;
					padding-top: 200px;
				}
				.txt01 {
					/*font-family: @font-main;*/
					width: 100%;
					text-align: center;
					padding-top: 140px;
				}
			}
		}
		P.center {
			position: absolute;
			bottom: 0;
			width: 100%;
			.box-sizing(border-box);
			display: block;
		}
	}
	&:last-child {
		margin-bottom: 30px;
	}
	LI.item {
		&:before {
			display: none;
		}
		DIV.itemgroup {
			position: relative;
			width: 100%;
			height: 100%;
			DIV.pad {
				/*padding-bottom: 80px;*/
				padding-bottom: 105px;
				.price01 {
					text-align: right;
					P.price {
						font-size: 18px;
						font-weight: bold;
						font-family: @font-main;
					}
				}
				P {
					margin: 0;
					&.heading01 {
						color: @color-black;
						font-family: @font-heading;
						font-size: 23px;
						webkit-hyphens: manual;
						-ms-hyphens: manual;
						hyphens: manual;
					}
					&.price01 {
						margin: 0;
						font-size: 18px;
						font-weight: bold;
						font-family: @font-main;
						position: absolute;
						right: 0;
						top: 6px;
						max-width: 115px;
					}
					&.separator {
						margin-top: 15px;
						padding-top: 15px;
						border-top: 1px dashed @color-gray-light;
					}
					STRONG {
						padding-right: 15px;
					}
				}
				A.btn.edit {
					font-size: 12px;
					width: auto;
					float: none;
					display: inline-block;
					text-align: left;
				}
			}
			DIV.txt {
				P.actions01 {
					position: absolute;
					bottom: 0;
					width: 100%;
					.box-sizing(border-box);
					&.actions01a {
						bottom: 35px;
					}
				}
			}
		}
		.flex(1 1 auto);
		min-width: 31.333333%;
		max-width: 31.333333%;
		min-height: 219px;
		margin: 10px 1%;
		padding: 20px;
		overflow: hidden;
		background: @color-white;
		.box-sizing(border-box);
	}
	@media @media-tablet {
		LI.item {
			.flex(0 0 auto);
			width: 48%;
			max-width: 48%;
		}

		&.items18b {
			LI.item {
				.flex(0 0 auto);
				width: 32.333333%;
				max-width: 32.333333%;
				min-width: 32.333333%;
			}
		}
	}
	@media @media-mobile {
		LI.item {
			.flex(0 0 auto);
			width: 98%;
			max-width: 98%;
		}

		&.items18b {
			LI.item {
				.flex(0 0 auto);
				width: 98%;
				max-width: 98%;
			}
		}
	}
}

.items19 {
	position: relative;
	background: @color-gray-lightest;
	position: relative;
	padding: 30px;
	.img {
		.box-sizing(border-box);
		float: left;
		max-width: 70px;
		min-width: 30px;
		text-align: center;
		IMG {
			width: 100%;
		}
	}
	.txt {
		.box-sizing(border-box);
		float: left;
		width: 100%;
		margin-left: -99px;
		padding-left: 132px;
		P {
			&.heading01 {
				font-family: @font-heading; /*font-size: ceil(@base-font-size*1.7);*/
				font-size: 16px;
				font-weight: bold;
				margin: 0;
			}
			&.meta {
				margin: 0;
				font-size: 13px;
			}
		}
	}
	@media @media-mobile {
		.img {
			text-align: center;
			float: none;
			margin: 0 auto;
			max-width: 50px;
		}

		.txt {
			text-align: left;
			padding: 0;
			margin: 0;
			P {
				&.heading01 {
					text-align: center;
					margin: .5em 0 0 0;
				}
				&.meta {
					margin: .5em 0 0 0;
				}
			}
		}
	}
}

.items20 {
	.display(flex);
	.align-items(center);
	A.btn.discrete {
		margin: 0 10px;
	}
	.item {
		min-height: 100%;
	}
	.mr0 {
		margin-right: auto;
	}
	H2 {
		margin: 0;
	}
	P.heading01 {
		.display(flex);
		.align-items(center);
		.justify-content(flex-end);
		font-size: 16px;
		SPAN.note {
			margin-right: 5px;
		}
	}
	SPAN.note {
		white-space: nowrap;
		color: @color-gray-medium;
	}
	H2,
	A {
		display: inline-block;
	}
	P {
		margin: .5em 0 0 0;
	}

	/*used in prepaid*/
	&.items20b {
		.justify-content(space-between);
		.box-sizing(border-box);
		width: 100%;
		.flex-direction(row);
		.item {
			width: auto;
			.align-self(flex-start);
			.heading01 {
				font-size: 30px;
				line-height: 48px;
				color: @color-black-medium;
				font-weight: 100;
			}
		}
	}
	@media @media-notdesktop {
		&.items20b {
			.flex-direction(column);
			.item {
				width: 100%;
			}

		}
	}

	@media @media-mobile {
		.flex-direction(column);
		.align-items(flex-start);
		.item {
			width: 100%;
		}

		P.heading01 {
			.justify-content(flex-start);
		}
	}
}

.items21 {
	&.items21b {
		border: 1px solid @color-gray-light;
		padding: 20px;
		.item02 {
			border-bottom: 1px solid @color-gray-light;
			padding: 10px 0;
			/*.align-items(center);*/
			.item {
				.align-items(center);
				.subitem {
					.flex(0 1 auto);
					width: 30%;
					/*.align-items(flex-start);*/
					P {
						&.heading01 {
							white-space: normal;
						}
					}
				}
				.subitem02 {
					.align-items(flex-start);
					color: @color-gray-medium;
					.flex(1 1 auto);
					width: 60%;
					.item-sub {
						.align-items(flex-end);
						.flex(1 1 auto);
						.flex-direction(column);
						box-sizing: border-box;
					}
					&.subitem02b {
						.item-sub {
							.flex(0 1 auto);
							width: 50%;
						}
					}
					&.subitem02--justify-start {
						.justify-content(flex-start);
					}
				}
			}
		}
		/*lost*/
		.item03 {
			.item {
				.subitem02 {
					.align-items(flex-start);
					color: @color-gray-medium;
					.flex(1 0 100%);
					.item-sub {
						P {
							.align-self(flex-end);
						}
						.align-items(flex-end);
						.flex(1 1 auto);
					}
				}
			}
		}
	}
	&.items21c {
		border-style: none none dashed none;
		border-width: 2px;
		border-color: @color-gray-light;
		padding: 20px 0;
		.item02 {
			.item {
				.subitem {
					.flex(0 0 45%);
				}
				.subitem02 {
					.flex(1 0 55%);
					.item-sub {
						.align-items(flex-end);
						.flex(0 0 auto);
						margin-left: 30px;
						.input-wrapper {
							margin: 0;
						}
						.button-wrapper {
							margin: 0;
						}
					}
				}
			}
			border: none;
			padding: 0;
		}
		.price01 {
			font-size: ceil(@base-font-size*1.69);
			margin: 0;
			font-weight: bold;
			line-height: 1;
			padding-bottom: 0.5em;
		}
	}
	&.items21d {
		border: 0;
		border-style: none;
		padding: 0;
		.item02 {
			border: 0;
			border-style: none;
			padding: 0;
			.item {
				margin: 0;
				width: auto;
				.flex(0 0 50%);
				.flex-direction(row);
				&.w60p {
					width: 60%;
					.flex(0 0 60%);
					@media @media-notdesktop {
						.flex(0 0 100%);
					}
				}
				&.w40p {
					width: 40%;
					.flex(0 0 40%);
					@media @media-notdesktop {
						.flex(0 0 100%);
					}
				}
				.subitem02 {
					.box-sizing(border-box);
					.display(flex);
					width: 100%;
					flex: 0 0 100%;
					.flex-wrap(wrap);
				}
				@media @media-mobile {
					.flex(0 0 100%);
				}
			}
		}
	}
	&.items21e {
		border: 1px solid @color-gray-light;
		border-style: solid none solid none;
		.item02 {
			padding: 0;
			&.bg {
				background: lighten(@color-gray-lightest, 5%);
			}
			&.bg-even {
				.item:nth-child(even) {
					background: lighten(@color-gray-lightest, 5%);
				}
			}
			.item {
				/*padding: 10px 20px;*/
				padding: 0;
				&.pad {
					padding: 10px 0;
				}
				.subitem-img & {
					padding-left: 0;
				}
				&.last {
					background: none !important;
				}
				.txt01 {
					width: 70%;
				}
				/*&.bg {
					background: lighten(@color-gray-lightest,5%);
				}*/
				.subitem {
					.flex-start {
						.align-self(flex-start);
					}
					.flex(0 0 30%);
					padding-bottom: 0;
					.txt02 {
						padding: 5px 20px 0 116px;
					}
					.txt03 {
						padding: 10px 0 0 156px;
					}
				}
				.subitem02 {
					.flex-start {
						.align-self(flex-start);
					}
					.flex(1 0 57%);
					.justify-content(flex-start);
					.align-items(center);
					margin: 0;
					.txt02 {
						padding: 0 20px 0 116px;
					}
					.txt03 {
						padding: 10px 0 0 156px;
					}
					.item-sub {
						.align-items(flex-end);
						.flex(0 0 auto);
						margin-left: 30px;
						.input-wrapper {
							margin: 0;
						}
						.button-wrapper {
							margin: 0;
						}
					}
				}
			}
		}
	}
	width: 100%;
	.item-heading {
		.ico {
			&:before {
				line-height: 24px;
				color: @color-gray-medium;
			}
		}
	}
	.item02 {
		&.bg {
			background: @color-gray-lightest;
		}
		&.mt5 {
			margin-top: 5px;
		}
		.display(flex);
		.box-sizing(border-box);
		.flex-wrap(wrap); /*padding: 10px 20px;*/
		padding: 10px 10px 10px 28px;
		width: 100%;
		.item {
			.display(flex);
			.flex-wrap(wrap);
			.box-sizing(border-box);
			width: 100%;
			.mt0 {
				margin-top: 0;
			}
			&.separated {
				border-top: 1px solid @color-gray-lightest;
				margin-top: 2em;
				&:hover {
					border-top: 1px solid @color01;
				}
			}
			.subitem-img {
				.display(flex);
				.align-items(flex-start);
				.box-sizing(border-box);
				padding: 0 20px 0 0;
				flex: 0 0 10%;
				.justify-content(center);
				.img {
					padding: 0;
					width: 80px;
					float: left;
					.box-sizing(border-box);
					&IMG {
						max-width: 100%;
						height: auto;
					}
				}
			}
			.subitem {
				.display(flex);
				.flex(2 1 auto);
				width: 66%;
				.flex-direction(column);
				.justify-content(center);
				.flex-wrap(wrap);
				.box-sizing(border-box);
				.clearfix();
				overflow: hidden;
				padding: 0;
				.heading01 {
					font-weight: bold;
					font-size: 15px;
					padding-right: 40px;
				}
				.heading02 {
					margin: 0;
				}
				SPAN.meta {
					color: @color-gray-medium;
				}
				.txt {
					margin: 0;
					padding: 0;
					&:after {
						border: none;
					}
				}
				.pad {
					padding: 10px 40px;
				}
				.pad02 {
					padding: 20px 0;
				}
			}
			.subitem02 {
				.display(flex);
				.align-items(flex-start);
				.flex(1 1 auto);
				width: 33.33%;
				.justify-content(flex-end);
				box-sizing: border-box;
				.item-sub {
					.display(flex); /*.flex(1);*/
					.flex(0 0 auto);
					.flex-direction(column); /*text-align: center;*/
					box-sizing: border-box;
					/*padding: 0 5px;*/
					A.btn {
						/*&.discrete {
							margin: 0 0 0 20px;
						}*/
					}
					P {
						margin-top: 0;
					}
				}
			}
		}
	}
	&.agent {
		&.agent__notifications {
			.agent__text {
				text-align: left;
				&--pad20 {
					margin: 0 20px;
				}
				/*color: @color-gray01;*/
			}
			/*.ico-inprocess {
				&:before {
					font-size: 24px;
					color: @color-gray01;
				}
			}*/
			.item02:not(:first-child) {
				margin-top: 5px;
			}
			.item02 {
				background: lighten(@color-gray-lightest, 5%);
				padding: 10px 28px 10px 28px;
				.item {
					.subitem {
						width: 60%;
					}
					.subitem02 {
						width: 40%;
						margin-top: 5px;
						.item-sub {
							padding: 0 5px;
						}
					}
				}
				@media @media-notdesktop {
					.item {
						.subitem {
							width: 100%;
						}
						.subitem02 {
							width: 100%;
						}
					}
				}
			}
		}
	}
	@media @media-notdesktop {
		.item02 {
			.item {
				.flex(0 0 auto);
				width: 100%;
				.flex-direction(column);
				.subitem {
					.flex-direction(column);
					.flex(1 1 auto);
					width: 100%;
					/*padding: 0 0 10px;*/
				}
				.subitem-img {
					.flex(1 1 auto);
					width: 100%;
					justify-content: center;
					padding: 0;
				}
				.subitem02 {
					.flex-direction(column);
					margin-top: 10px;
					.flex(0 0 auto);
					width: 100%;
					.item-sub {
						width: 100%;
						.flex(1 1 auto);
						text-align: center;
					}
				}
			}
		}

		&.items21b {
			.item02 {
				.item {
					.subitem {
						.flex(0 0 auto);
						width: 100%;
					}
					.subitem02 {
						.flex(0 0 auto);
						width: 100%;
						margin-top: 0;
						padding: 0 0 10px;
						.item-sub {
							width: 100%;
							.flex(1 1 auto);
							text-align: left;
							.align-items(flex-start);
						}
					}
					.subitem03 {
						.flex(0 0 auto);
						width: 100%;
						margin-top: 0;
						padding: 0 0 10px;
						.item-sub {
							P {
								.align-self(flex-start);
							}
							width: 100%;
							.flex(1 1 auto);
							text-align: left;
							.align-items(flex-start);
						}
					}
				}
			}
		}

		&.items21c {
			.item02 {
				.item {
					.subitem {
					}
					DIV.subitem02 {
						.flex(0 0 auto);
						width: 100%;
						.item-sub {
							width: 100%;
							.flex(1 1 auto);
							.align-items(flex-start);
							text-align: left;
							margin-left: 0;
						}
					}
				}
			}
		}

		&.items21e {
			.item02 {
				.item {
					padding: 10px 20px;
					&.pad {
						padding: 10px 20px;
					}
					.subitem {
						.flex(0 0 auto);
						width: 100%;
						.txt02 {
							padding: 0;
						}
						.txt03 {
							padding: 0;
						}
					}
					.txt01 {
						width: 100%;
					}
					.subitem02 {
						.txt02 {
							padding: 0;
						}
						.txt03 {
							padding: 0;
						}
						.flex(0 0 auto);
						width: 100%;
						.flex-direction(row);
						.item-sub {
							/*&:nth-of-type(3) { order: 2; }*/
							width: 50%;
							.flex(0 1 auto);
							.align-items(center);
							text-align: left;
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}

.items22 {
	.flex-wrap(nowrap);
	.display(flex);
	overflow: hidden;
	.link-outline-none;
	cursor: pointer;
	A.play {
		text-align: center;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			background: @color-white;
			box-shadow: 0 6px 6px -6px black;
			-webkit-box-shadow: 0 6px 6px -6px black;
			-moz-box-shadow: 0 6px 6px -6px black;
			width: 50px;
			height: 50px;
			text-align: center;
			color: @color01;
			display: block;
			margin: 0 auto;
			transform: scale3d(1, 1, 1);
			.rounded(50%);
		}
		&:after {
			color: @color01;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			font-family: icons;
			font-size: 22px;
			content: @ico-play01;
			padding-left: 6px;
			line-height: 50px;
			transform: scale3d(1.02, 1.02, 1);
		}
		&:hover {
			&:before,
			&:after {
				transform: scale3d(1.02, 1.02, 1);
			}
		}

	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		background: fade(@color01, 70%) !important;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
	}
	A {
		color: @color-white;
		display: block;
		font-size: 22px;
	}
	.img {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		background: url(../img/eraam/videobg01.jpg) no-repeat 50% 50%;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		overflow: hidden;
		height: 178px;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
		P {
			font-size: 15px;
			color: @color-white;
			margin: 0;
			&.heading01 {
				font-size: 18px;
				font-weight: bold;
				padding-bottom: 5px;
			}
			&.heading02 {
				padding-top: 53px;
				font-size: 15px;
			}
		}
		.txt {
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-right: -50%;
			transform: translate(-50%, -50%)
		}

	}
	.item {
		max-width: 100%;
		width: 100%;
		height: 178px;
		.flex(0 0 100%);
		float: left;
		position: relative;
		border: none;
		.link-outline-none;
		cursor: pointer;
	}
	@media @media-mobile {
		.item {
			height: 165px;
			A.play {

			}
			.img {
				height: 165px;
				& P {
					padding-bottom: 15px;
					font-size: 16px;
				}
			}
		}
	}
}

.items23 {
	width: 100%;
	&.items23b {
		.item {
			border: none;
			.subitem01 {
				border: 1px solid @color13;
			}
		}
	}
	&.items23__dropdown {
		width: 65px;
		.item {
			.subitem-ico {
				.flex(0 0 auto);
				width: 100%;
				&:after {
					content: "\f107";
					font-family: FontAwesome;
					margin-left: 5px;
					font-size: 20px;
					line-height: 1.05;
					vertical-align: bottom;
				}
			}
		}
	}

	&.items23c {
		width: auto;
		.item {
			width: 100%;
			border: none;
			height: auto;
			min-height: inherit;
			.subitem01 {
				.flex(0 0 auto);
				width: 100%;
				border: 1px solid @color13;
			}
		}
	}
	.item {
		.display(flex);
		.flex-wrap(wrap);
		.box-sizing(border-box);
		.flex-wrap(nowrap);
		width: 100%;
		height: 100%;
		min-height: 100%;
		border: 1px solid @color13;
		.mt0 {
			margin-top: 0;
		}
		.subitem-ico {
			font-size: 18px;
			line-height: 1;
			color: @color-black;
			padding: 10px;
			.display(flex);
			.flex(0 0 auto);
			.justify-content(center);
			.align-items(center);
			.flex-wrap(wrap);
			.box-sizing(border-box);
			background: @color-orange02;
			&::before {
				color: @color-black;
				line-height: 1;
			}
		}
		.subitem {
			.display(flex);
			.align-items(center);
			.flex(0 1 auto);
			.flex-wrap(wrap);
			.justify-content(flex-start);
			.box-sizing(border-box);
			&.subitem--full-width {
				width: 100%;
			}
			&.subitem__pad {
				padding: 20px;
			}
		}
		.subitem01 {
			&.subitem01b {
				padding: 5px 0;
				.align-items(center);
				&.separator {
					.item-sub {
						border-right: 1px solid @color-gray-medium-lightest;
					}
				}
				.item-sub {
					&.mt0 {
						margin-top: 0;
					}
					&.pad01 {
						padding: 0 5px;
					}
					text-align: left;
					padding: 0 15px;
					margin: 5px 0;
					P {
						margin: 0;
					}
				}
			}
			&.subitem01c {
				padding: 10px 0;
				.item-sub {
					.display(flex);
					.justify-content(space-between);
					.flex-wrap(wrap);
					padding: 0 15px;
					margin: 0;
					width: 100%;
					white-space: normal;
					text-align: left;
					P {
						margin: 0;
						font-size: 13px;
					}
					@media @media-mobile {
						padding: 0 10px;
					}
				}
			}

			.display(flex);
			.align-items(center);
			.flex(0 1 auto);
			.flex-wrap(wrap);
			.justify-content(flex-start);
			.box-sizing(border-box);
			&.subitem01--full-width {
				width: 100%;
			}
			&.subitem01__pad {
				padding: 20px;
				&--sm {
					padding: 10px;
				}
			}
			&.subitem01--center {
				.justify-content(center);
			}
		}
	}
}

/*not in used?*/
/* Loader */
#loader {
	position: fixed;
	z-index: 99;
	top: 50%;
	left: 50%;
	margin: -100px 0 0 -150px;
	text-align: center;
	width: 300px;
	background: @color-white url(old/loader.gif) no-repeat 50% 15px;
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
	padding: 100px 20px 20px;
	P {
		color: @color01;
		font-size: 18px;
		font-weight: bold;
		margin: 0;
	}
	@media @media-mobile {
		width: 80%;
		left: 10%;
		right: 10%;
		margin-left: 0; .box-sizing(border-box);
	}
}

.loader {
	&_nogif {
		background: @color-white !important;
		padding: 20px !important;
		z-index: 9999 !important;
	}

}

#content .loading {
	text-align: center;
	background: url(old/loader.gif) no-repeat 50% 15px;
	padding: 100px 20px 20px;
	P {
		color: @color01;
		font-size: 18px;
		font-weight: bold;
		margin: 0;
	}
}

/* Autocomplete */
.ui-autocomplete {
	background-color: @color-white;
	border: 1px solid @color-gray-medium-lightest;
	.box-sizing(border-box);
	border-radius: 3px;
	LI A {
		display: block;
		padding: 5px;
		cursor: pointer;
		font-size: 14px;
	}
}

.ui-helper-hidden-accessible {
	visibility: hidden;
	position: absolute;
}

#feedback {
	.zindex(@npsZindex,0);
	position: fixed;
	width: 600px;
	right: 0;
	overflow: hidden;
	top: 40%;
	background: @color-white;
	border-radius: 3px 0 0 3px;
	border: 1px solid @color-gray-medium-lightest;
	border-right: none;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
	.pad {
		padding: 20px 70px 20px 40px;
	}
	P.title {
		font-size: 18px;
		color: @color01;
		margin: 0;
		text-align: center;
	}
	P.note {
		clear: none;
	}
	UL {
		overflow: hidden;
		width: 440px;
		margin: 1em auto 0 auto;
		LI {
			&:before {
				display: none;
			}
			float: left;
			margin: 0 2px;
			padding: 0;
			text-align: center;
			width: 36px;
			LABEL {
				display: block;
			}
		}
	}
	A.close {
		display: block;
		width: 26px;
		height: 26px;
		background: @color-gray-lightest;
		border-radius: 13px;
		position: absolute;
		right: 10px;
		top: 10px;
		text-align: center;
		line-height: 26px;
		&:before {
			font-family: "icons";
			content: @ico-cross;
			color: @color-gray-medium;
			font-size: 12px;
		}
	}
	TEXTAREA {
		margin-top: 1em;
	}
	DIV.clear {
		width: 415px;
		margin: 1em auto 0;
	}
	#feedbackSuccess {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: fade(@color-white, 90%);
		.display(flex);
		.align-items(center);
		.justify-content(center);
		P {
			font-size: 24px;
			color: @color-positive;
			margin: 0;
		}
	}
	&.feedback01 {
		.zindex(@npsZindex,1);
		.box-sizing(border-box);
		overflow: hidden;
		margin: auto;
		left: 9%;
		top: 50%;
		transform: translateY(-50%);
	}
	@media @media-mobile {
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		width: auto;
		.pad {
			padding: 40px 10px 10px;
		}

		UL {
			max-width: 100%;
			LI {
				width: 9.09%;
				margin: 0;
			}
		}

		DIV.clear {
			max-width: 100%;
		}

		&.feedback01 {
			left: 0;
			transform: translateY(0) !important;
			bottom: auto;
			max-height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
		}
	}
}

UL.feedback {
	overflow: hidden;
	width: 100%;
	margin: 1em auto 0 auto;
	LI {
		&:before {
			display: none;
		}
		float: left;
		margin: 0 0;
		padding: 0 2px;
		.box-sizing(border-box);
		text-align: center;
		width: 9.09%;
		LABEL {
			display: block;
		}
	}
}
.type-feedback-window {
	max-width: 320px;
	.zindex(@npsZindex,0);
	position: fixed;
	right: 20px;
	overflow: hidden;
	bottom: 20px;
	background: @color-white;
	-moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	padding: 18px;
	&__close {
		position: absolute; height: 25px; width: 20px; text-indent: -10000px; top: 10px; right: 10px; z-index: 10; float: right;
		&:before { font-family: FontAwesome; content: "\f00d";  line-height: 25px; float: left; text-indent: 0; text-align: center; color: @color-gray04; font-size: 25px;  }
		&:hover { border-bottom: none !important; text-decoration: none !important; outline: none !important; }
	}
	&__title {
		font-size: 15px;
		font-weight: 600;
		padding: 0 25px 0 0;
		margin: 1em 0 0 0;
		line-height: 1.4;
		font-family: @font-main;
		color: @color-blue01;
		text-align: left;
	}
	@media @media-mobile {
		right: 0;
		bottom: 0;
		max-width: 100%;
		width: 100%;
		-moz-box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
		-webkit-box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
		box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
	}
}
.type-feedback {
	overflow: hidden;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	.box-sizing(border-box);
	.display(flex);
	.justify-content(space-between);
	.type-feedback__item {
		display: inline-block;
		&:before {
			display: none;
		}
		margin: 0;
		padding: 0;
		.box-sizing(border-box);
		text-align: center;
	}
	.type-feedback__label {
		.display(flex);
		.justify-content(space-between);
		padding: 0;
		margin: 0;
		.align-items(center);
		width: 100%;
		margin-top: 2px;
	}
	.type-feedback__text {
		&--note {
			font-size: 13px; font-family: @font-main; font-weight: 400; line-height: 1.54; color: @color-gray03;
		}
	}
	@media @media-mobile {

	}
}

.parallax {
	position: fixed;
	opacity: 0;
	left: 0;
	right: 0;
	margin: auto;
	top: auto;
	bottom: auto;
	z-index: 0;
	.transition(opacity, 500ms);
	width: 980px;
	&.loaded {
		opacity: 1;
	}
	IMG {
		position: absolute;
		top: 0;
		z-index: 39;
		max-width: 145px;
		height: auto;
		&.left {
			left: -145px;
			margin-right: 25px;
		}
		&.right {
			right: -145px;
			margin-left: 25px;
		}
	}
	@media @media-desktop {
		display: block;
		IMG {
			max-width: 145px;
			height: auto;
			&.left {
				left: -145px;
				margin-right: 15px;
			}
			&.right {
				right: -145px;
				margin-left: 15px;
			}
		}
	}
	@media @media-tablet {
		opacity: 0;
	}
	@media @media-mobile {
		opacity: 0;
		width: auto;
	}
	@media @media-wide {
		display: none;
	}
	@media @media-notwide {
		display: none;
	}
	@media only screen and (min-width: 1366px) {
		opacity: 0;
		width: 1200px;
		display: block;
		IMG {
			max-width: none;
			&.left {
				left: -350px;
				margin-right: 25px;
			}
			&.right {
				right: -350px;
				margin-left: 25px;
			}
		}
	}
	@media @media-notwide {
		display: none;
	}
	@media @media-hd {
		display: block;
		IMG {
			max-width: none;
		}
	}
}

// Slider component
.slider01 {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 4px;
	/*margin: 35px 0 45px 0;*/
	margin: 15px 0 32px 0;
	vertical-align: middle;
	.user-select(none);
	&.with-legend {
		margin-bottom: 40px;
	}
	[disabled] {
		cursor: not-allowed;
		.rz-pointer {
			cursor: not-allowed;
			background-color: #d8e0f3;
		}
	}
	span {
		position: absolute;
		display: inline-block;
		white-space: nowrap;
	}
	.rz-base {
		width: 100%;
		height: 100%;
		padding: 0;
	}
	.rz-bar-wrapper {
		left: 0;
		z-index: 1;
		width: 100%;
		height: 32px;
		padding-top: 16px;
		margin-top: -16px;
		.box-sizing(border-box);
		&.rz-draggable {
			cursor: move;
		}
	}
	.rz-bar {
		left: 0;
		z-index: 1;
		width: 100%;
		height: 3px;
		background: @color-gray05;
		border: 1px solid @color-gray05;
		.rounded(2px);
		&.rz-selection {
			height: 7px;
			z-index: 2;
			background: @color-blue02;
			border-color: @color-blue02;
			.rounded(2px);
			margin-top: -2px;
		}
	}
	.rz-pointer {
		top: -8px;
		z-index: 3;
		width: 20px;
		height: 20px;
		cursor: pointer;
		background-color: @color01;
		.rounded(20px);
		&:hover:after {
			background-color: #ffffff;
		}
		&.rz-active {
			z-index: 4;
		}
	}
	.rz-ticks {
		position: absolute;
		top: -5px;
		left: 0;
		z-index: 0;
		.display(flex);
		width: 100%;
		height: 0;
		padding: 0 10px;
		margin: 0;
		list-style: none;
		.box-sizing(border-box);
		.justify-content(space-between);
		.rz-tick {
			top: -3px;
			width: 2px;
			height: 20px;
			text-align: center;
			cursor: pointer;
			background: @color-gray05;;
			&.rz-selected {
				.rz-tick-legend--bold {
					color: @color-gray01;
					font-weight: 700;
					font-size: 15px;
					transition: font-weight .4s ease-in-out;
				}
			}
			.rz-tick-value {
				position: absolute;
				top: -30px;
				transform: translate(-50%, 0);
			}
			.rz-tick-legend {
				position: absolute;
				top: 24px;
				max-width: 50px;
				white-space: normal;
				transform: translate(-50%, 0);
				font-family: @font-main;
				color: @color-gray03;
				font-size: 12px;
				font-weight: 600;
				transition: font-weight .4s ease-in-out;
			}
		}
	}
	.rz-tick-value {
		top: initial;
		bottom: -40px;
	}
	ul li {
		padding: 0;
		margin: 0;
		&:before {
			top: 0;
			position: absolute;
			content: "";
			font-size: 0;
		}
	}
}

.share01 {
	margin-top: .5em;
}

UL.swatches01 {
	LI {
		position: relative;
		padding: 0 0 0 100px;
		margin: 10px 0 0 0;
		min-height: 80px;
		&:before {
			content: "";
			width: 80px;
			height: 80px;
			position: absolute;
			left: 0;
			top: 0;
		}
		&.color01:before {
			background: @color01;
		}
		&.color02:before {
			background: @color02;
		}
		&.color03:before {
			background: @color03;
		}
		&.color04:before {
			background: @color04;
		}
		&.color05:before {
			background: @color05;
		}
		&.color06:before {
			background: @color-negative;
		}
		&.color07:before {
			background: @color-gray-medium;
		}
		&.color08:before {
			background: @color-gray-darkest;
		}
		&.color09:before {
			background: @color-gray-dark;
		}
		&.color10:before {
			background: @color-gray-medium-light;
		}
		&.color11:before {
			background: @color-gray-light;
		}
		&.color12:before {
			background: @color-gray-lightest
		}
	}
}

@import "_card.less";
@import "_framed.less";

@import "../bower_components/jquery-tag-editor/jquery.tag-editor.css";

UL.tag-editor {
	max-width: 462px;
	LI {
		margin: 5px 0;
		padding: 0;
		&:before {
			display: none;
		}
		.tag-editor-tag, .tag-editor-delete, .tag-editor-delete {
			background: @color09;
			color: @color-black;
		}
		.tag-editor-delete {
			I:before {
				color: @color-black;
			}
			&:hover {
				i:before {
					color: @color-black;
				}
				color: @color-black;
			}
		}
	}
	INPUT {
		line-height: 14px;
		max-height: 20px;
	}
	@media @media-mobile {
		max-width: 100%;
	}
}

.ui-autocomplete.countrylist01 {
	overflow-y: auto;
	max-height: 350px;
	max-width: 370px;
	z-index: 9;
	@media @media-mobile {
		width: auto !important;
		margin-right: 30px;
	}
}

// Todo! Temporary fix
#sitetools {
	.not-logged {
		display: none !important;
	}
}

.prepaid-items01 {
	.display(flex);
	.box-sizing(border-box);
	.flex-direction(column);
	padding: 0;
	.orange {
		color: #ff6400;
	}
	.flex70 {
		.flex(1 1 70%);
	}
	.flex30 {
		.flex(1 1 30%);
	}
	.flex66 {
		.flex(1 0 auto);
		width: 66.6666%;
	}
	.flex50 {
		.flex(1 1 50%);
	}
	.flex33 {
		.flex(1 0 auto);
		width: 33.3333%;
	}
	.flex25 {
		.flex(1 1 25%);
	}
	.flex00 {
		.flex(1 0 auto);
	}
	DIV.item {
		&.separated {
			border-top: 1px solid @color-gray-medium-light;
			&:hover {

			}
		}
		&:first-child {
			border: 1px @color-gray-medium-light;
			border-style: solid none solid none;
		}
		.subitems02,
		.subitems01 {
			padding: 0;
			.display(flex);
			position: relative;
			.flex-direction(row);
			.box-sizing(border-box);
			width: 50%;
			.subitem {
				.display(flex);
				.align-items(center);
				.box-sizing(border-box);
				.flex(1 0 auto);
				width: 33.33333%;
				&:last-child {
					.txt {
						.display(flex);
						.align-items(flex-end);
						.box-sizing(border-box);
						.flex-direction(column);
					}
				}
				.img {
					text-align: center;
					padding: 0;
					width: 55px;
					max-width: 55px;
					/*float: left;*/
					.box-sizing(border-box);
					&IMG {
						max-width: 100%;
						height: auto;
					}
				}
				.txt {
					width: 100%;
					padding: 0 20px 0 20px;
					/*float: left;*/
					.box-sizing(border-box);
					white-space: normal;
					word-wrap: break-word;
					max-height: 100px;
					overflow: hidden;
					P {
						margin-top: 0;
						color: @color16;
						font-size: 15px;
						&.meta {
							margin: 0 0 0 0;
							font-size: 14px;
							color: @color19;
							font-weight: normal;
							font-family: @font-main;
						}
						&.meta01 {
							margin: .5em 0 0 0;
							font-size: 14px;
							color: @color02;
							font-weight: normal;
							font-family: @font-main;
							display: inline-block;
						}
						&.heading01 {
							color: @color-text-main;
							font-weight: bold;
							font-size: 16px !important;
							margin: 0 !important;
							font-family: @font-heading;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
		/*min-height: 114px;
		height: 114px;*/
		.display(flex);
		.flex-direction(row);
		.box-sizing(border-box);
		overflow: hidden;
		border: 1px @color-gray-medium-light;
		border-style: none none solid none;
		padding: 20px;
	}
	@media @media-notdesktop {
		.flex-direction(column); .flex-wrap(nowrap);
		margin: 0 -20px;
		.flex66 {
			.flex(0 1 auto);
			width: auto;
		}

		.flex33 {
			.flex(0 0 auto);
			width: auto;
		}

		DIV.item {
			height: auto;
			min-height: 100%;
			.flex(0 0 auto);
			.flex-wrap(wrap);
			.display(flex);
			width: 100%;
			.flex-direction(row);
			&:first-child {
				margin-top: 0;
				&:hover {
					margin-top: 0;
				}
			}

			.subitems01 {
				.flex-direction(row);
				.flex(0 0 auto);
				width: 55%;
				.subitem {
					border-left: 0;
					.flex(1 0 auto);
					width: 100%;
					.box-sizing(border-box);
				}
			}
			.subitems02 {
				.flex-direction(row);
				.flex(0 0 auto);
				width: 45%;
				.subitem {
					border-left: 0;
					.flex(1 0 auto);
					width: 100%;
					.box-sizing(border-box);
				}
				.txt {
					P {
						font-size: 18px;
						&.meta {
							text-align: right;
							margin: 0 0 0 0;
							font-size: 12px;
						}
					}
				}
			}
			&.separated01 {
				border-top: 1px solid @color-gray-lightest;
				margin-top: 2em;
				&:hover {
					border-top: 1px solid @color01;
					margin-top: 2em;
				}
			}
		}
	}
}
#web-messenger-container {
	.zindex(@chatbotZindex, 0);
}
.overlay-modal {
	.zindex(@overlaymodalZindex, 0);
}
