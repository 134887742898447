@import (reference) "mixins/zindex_mixins";
.sticky {
	background: @color-white; position: fixed; left: 0; right: 0; top: 0; height: auto; .zindex(@overlaystickyZindex, 0); box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	&--bottom { top: auto; bottom: 0; height: auto; padding: 20px 0; }
	&--dark { box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); background: @color-gray01; }
	&.sticky-header {
		&__pad {
			&--package-compare {
				@media @media-tablet {
					padding: 0 35px 0 35px;
				}
				@media @media-mobile {
					padding: 0;
				}
			}
		}
		&--type-order-toggle {
			@media @media-tablet {
				margin-right: -20px;
				margin-left: -20px;
				.type-order-toggler__collapse {
					margin: 0 -20px;
				}
			}
		}
	}
}
