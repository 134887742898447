.type-heading {
	.type-heading__border {
		border: 1px solid @color-gray06;
		&--top-bottom {
			border: 1px solid @color-gray06;
			border-style: solid none;
		}
		&--top {
			border: 1px solid @color-gray06;
			border-style: solid none none none;
		}
	}
	.type-heading__item {
		background: @color-gray07;
		padding: 15px 20px;
		.type-package__text {
			font-size: 16px;
			color: @color-gray01;
			font-family: @font-main;
			font-weight: 400;
			line-height: 1.47;
			margin: 0;
			@media @media-notdesktop {
				font-size: 15px;
				line-height: 1.47;
			}
		}
		@media @media-mobile {
		}
		.type-heading__name {
		}
		.type-heading__info {
			padding: 0 0 0 60px;
			@media @media-notdesktop {
				padding: 0 0 0 20px;
			}
			@media @media-mobile {
				padding: 0;
			}
		}
	}
}