.loader-container {
  .display(flex);
  .flex-direction(column);
  padding: 20px;
  .loader {
    .display(flex);


    &.big {
      .justify-content(center);
      padding-top: 60px;

      svg {
        .loader-dot {
          fill: @color01;
          -webkit-transform: translate3d(0, 0, 0);
          opacity: 1;
        }

        .loader-path {
          fill: none;
          stroke: @color01;
          stroke-width: 5;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          -webkit-transform: translate3d(0, 0, 0);
          stroke-dasharray: 350;
        }
      }
    }

    &.small {
      .c {
        width: 20px;
        height: 20px;
        background: @color01;
        border-radius: 20px;
        transform: scale(0);

        &.c-1 {
          animation: 2s loading 0s ease-in-out infinite;
        }

        &.c-2 {
          margin-left: 5px;
          animation: 2s loading 0.25s ease-in-out infinite;
        }

        &.c-3 {
          margin-left: 5px;
          animation: 2s loading 0.5s ease-in-out infinite;
        }
      }
    }
  }

  &.modal {
    .zindex(@loaderZindex, 0);
    position: fixed;
    top: 50%;
    left: 50%;
    background: @color-white;
    border: 1px solid @color-gray-medium-lightest;
    border-radius: 3px;
    box-shadow: 0 0 10px 0 @color-gray-medium-light;
    height: 100px;
    width: 200px;
    margin-left: -120px;
    margin-top: -70px;
    text-align: center;
    .display(flex);
    .flex-direction(column);
    .justify-content(center);
    .align-items(center);
  }
}

@keyframes loading {
  0% {
    transform: scale(0);
    opacity: 0.2
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0.2;
  }
}