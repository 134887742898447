NAV {
	UL, LI { list-style: none; padding: 0; margin: 0; }
	&#menu01 {
		margin: 0 0 0 151px; font-family: @font-heading;
		.ru & { font-family: @font-heading-ru; }
		LI {
			float: left; font-size: 17px; margin: 0 0 0 25px; padding: 10px 0 0; font-weight: 300;
			&:first-child { margin: 0; }
			&:before { display: none; }
		}
		A {
			display: block; padding: 5px 0 1px; color: @color12;
			&.active { color: @color-white; border-bottom: 2px solid @color02; }
			&:hover { text-decoration: none; color: @color-white; }
		}
		@media @media-notwide {
			margin: 0 0 0 0;
			LI { /*margin: 0 0 0 30px;*/ margin: 0 0 0 20px; }
		}
		@media @media-tablet {
			margin: 0;
			LI { margin: 0 0 0 30px; }
		}
		@media @media-mobile {
			float: left; margin: 0;
			LI { font-size: 15px; margin: 0 0 0 20px; padding: 12px 0; }
			A { padding: 0; }
		}
		@media screen and (min-width: 240px) and (max-width: 480px) {
			LI { font-size: 10px; margin: 0 0 0 10px; }
		}
	}
	&#menu02 {
		float: left; margin: 10px 0 0 0;
		font-family: @font-heading;
		.ru & { font-family: @font-heading-ru; }
		LI {
			float: left; font-size: 19px; font-weight: bold; margin: 0 0 0 48px; padding: 0;
			.ru & { margin: 0 0 0 30px; }
			&:before { display: none; }
			&.activated A { color: @color01; }
			UL { display: none; }
		}
		A {
			color: @color01;
			&.active { color: @color01; border-bottom: 2px solid @color02; }
			&:hover { color: @color02; text-decoration: none; }
		}
		@media @media-notwide {
			.ru & { margin: 0; }
			LI {
				margin: 0 0 0 22px;
				.ru & {
					margin: 0 0 0 15px;
					font-size: 17px;
					line-height: 20px;
					text-align: center;
					width: 92px;
					-ms-word-break: break-all;
					// Non standard for webkit
					word-break: break-word;
					-webkit-hyphens: manual;
					-moz-hyphens: manual;
					-ms-hyphens: manual;
					hyphens: manual;
				}
			}
			& > UL {
				.ru & {
					.display(flex); .align-items(center);
				}
			}
		}
		@media @media-notdesktop {
			float: none; clear: both; padding: 15px 0 0 0; overflow: visible; display: none; width: 100% !important;
			&.open { display: block; }
			> UL { background: lighten(@color-gray-lightest,5%); -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); }
			UL {
				padding: 18px 7px; margin: 0 -15px;
				UL {
					margin: 10px 0 0 0; background: @color-white; padding: 0;
					LI {
						padding: 10px 10px 10px 20px; margin: 0;
						&.open { background: @color-gray-lightest; }
						&:first-child { border-top: none; padding-top: 20px; }
					}
					A.active { color: @color01 !important; }
					UL {
						padding: 0 0 10px; margin: 0; background: none;
						LI {
							padding: 0; border-top: none;
							&:first-child { padding-top: 0; }
						}
						A { font-size: 15px; }
					}
				}
				LI {
					&.activated UL,
					A.active + DIV.submenu01 UL { display: block; }
					&.activated A { color: @color01; }
				}
				&.closed {
					A.active + DIV.submenu01 UL,
					UL { display: none; }
				}
			}
			LI {
				float: none; margin: 10px 0 0;
				.ru & { margin: 10px 0 0; }
				&:first-child {
					margin: 0 ;

				}
				A {
					display: block; position: relative; color: @color01; font-size: 25px; margin: 0 7px; padding: 5px 0; font-weight: normal;
					&.active {
						color: @color01; border-bottom: none;
						SPAN { border-bottom: 2px solid @color02;}
					}
				}
				LI {
					A {
						font-weight: bold; color: @color-black; padding-right: 0;
						&:after { display: none; }
					}
					LI A { font-weight:  normal; color: @color01; }
				}
				&.open,
				&.activated {
					& > A {
						padding-right: 20px;
						&:after { content: @ico-arrdown; }
					}
				}
				A {
					padding-right: 35px;
					&:after { content: @ico-arrright; font-family: "icons"; position: absolute; right: 5px; top: 50%; margin-top: -10px; }
				}
			}
			DIV.submenu01 > UL > LI > A {
				font-size: 21px; color: @color-black;
				&:after { display: none; }
			}

		}
		@media @media-tablet {
			UL { margin: 0 -20px; }
		}
	}
	&#menu03 {
		clear: both; display: none; padding-bottom: 10px; position: relative; z-index: 9;font-family: @font-heading;
		.ru & { font-family: @font-heading-ru; }
		.index & { padding-bottom: 0; }
		.col00 {
			background: @color-white; -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); margin: -10px auto 0 auto; position: relative;
			DIV.submenu01 {
				overflow: hidden;
				&:before { display: none; content: ""; width: 75%; height: 100%; position: absolute; left: 0; top: 0; background: url(bg02.png) no-repeat 0 100%; background-size: 100% auto; z-index: 1; }
				& > UL {
					margin: 0; padding: 0 0 0 0; position: relative;
					& > LI {
						position: static; margin: 0;  width: 25%; float: left; font-size: 19px; color: @color-black; padding: 30px; .box-sizing(border-box);
						&:nth-of-type(n+5) { padding-top: 0; }
						&:before { display: none; }
						&:first-child:before { display: block; content: ""; background: lighten(@color-gray-lightest,5%); position: absolute; top: 0; bottom: 0; width: 25%; z-index: 0; margin-left: 0; }
						& > A,
						& > SPAN { font-weight: bold; }
						&.open > A { color: @color01; }
					}
				}
			}
		}
		A,
		SPAN { color: @color-black; position: relative; z-index: 3; }
		LI {
			UL { margin: 10px 20px 0 -10px; }
			LI {
				font-size: 17px; color: @color12; padding: 0;
				&:before { display: none; }
				A {
					color: @color01; display: block; padding: 5px 10px 5px 30px; background: url(dot01.png) no-repeat 12px 10px;
					&:hover { text-decoration: none; background-color: @color11; }
					&.active SPAN { border-bottom: 2px solid @color02; }
				}
			}
		}
	}
	&.menu04 {
		border-bottom: 1px solid @color-gray-lightest; padding: .2em 0 0 0; background: @color-white;
		UL { list-style: none; margin: 0; padding: 0; }
		LI {
			float: left; margin: 0 0 0 2em; padding: 0;
			&:before { display: none; }
			&.f-right { float: right; }
			&.active A { color: @color02; }
			&:first-child { margin: 0; }
			&.secondary A { border-left: 1px solid @color-gray-medium-lightest; font-weight: normal; font-size: 1em; padding: 0 0 0 30px; margin: .5em 0; }
		}
		A {
			display: block; padding: .5em 0; font-weight: bold;
			&.btn { padding: .7em 20px; font-size: 18px; }
		}
		&.menu04b {
			height: 0; overflow: hidden; border-bottom: none;
			A.toggle-menu { display: none; }
			.link-outline-none;
			/*A.offset { top: -40px !important; }*/
			&.scroll-to-fixed-fixed { height: auto; .shadow(0 2px 10px, rgba(0, 0, 0, 0.05)); overflow: visible; }
			UL {
				padding: 20px 0; font-size: 18px;
				font-family: @font-heading;
				.ru & { font-family: @font-heading-ru; }
				LI.active A { border-bottom: 2px solid @color02; }
				LI {
					margin: 0 0 0 1em;
					A {
						text-decoration: none !important;
						&:hover {
							color: @color02;
						}
					}
				}
			}
		}
		@media @media-tablet {
			LI {
				margin: 0 0 0 1em;
				&:first-child { margin: 0; }
			}
		}
		@media @media-mobile {
			display: none;
			&.menu04b {
				display: block; position: relative;
				A.toggle-menu {
					display: block; position: absolute; right: 10px; top: 10px; text-indent: -10000px; z-index: 99;
					&:hover { text-decoration: none; }
					&:before  {
						background: @color01; width: 55px; height: 55px; .rounded(50%); line-height: 55px; text-align: center; float: left;
						content: @ico-menu; text-indent: 0; font-size: 26px; color: @color-white; font-family: "icons";
					}
				}
				UL { display: none; }
				LI { float: none !important; margin: 0; }
				&.scroll-to-fixed-fixed {
					.shadow-remove(); background: none;
					&.open {
						background: @color-white; .shadow(0 2px 10px, rgba(0, 0, 0, 0.05));
						UL { display: block; }
						A.toggle-menu:before {
							content: @ico-cross; background: none; color: @color01;
						}
					}
				}

			}
		}
		@media @media-tablet {
			LI.secondary A { border-left: 0; padding-left: 0; }
		}
	}
	&.menu06 {
		UL {
			.display(flex);
			flex-wrap: wrap;
			margin: 0 -5px;
			LI {
				.box-sizing(border-box); float: left; width: 24%; .flex(0 0 24%); display: flex; height: auto; text-align: center; margin: 5px 5px;
				&:before { display: none; }
				&:hover {
					background: none;
					A {
						border: 2px solid @color12;
						SPAN.pad {
							padding: 159px 29px 49px 29px;
						}
						SPAN.img { top: 49px; }
					}

				}
				A {
					width: 100%;
					display: block;
					border: 1px solid @color-gray-lightest;
					text-decoration: none;
					box-sizing: border-box;
					height: auto;
					min-height: 100px;
					SPAN.pad {
						display: block; width: 100%; .box-sizing(border-box);
						position: relative;
						padding: 160px 30px 50px 30px;
					}


					SPAN.img {
						position: absolute;
						height: 90px;
						top: 50px;
						left: 0;
						right: 0;
						transform: none;
						.display(flex);
						.justify-content(center);
						.align-content(center);
						.align-items(center);
						IMG {
							max-height: 90px;
						}
					}
				}
			}
		}
		&.menu06c {
			UL {
				.justify-content(flex-start);
				margin: 0;
				LI {
					width: 25%;
					.flex(0 0 25%);
					margin: 0;
					text-align: center;
					border: none;
					A {
						SPAN.pad {
							display: block; width: 100%; .box-sizing(border-box);
							position: relative;
							padding: 0 16px 0 90px;
						}
						SPAN.img {
							left: 20px;
							display: inline-block;
							position: absolute;
							top: 50% !important;
							margin-top: -25px;
							max-width: 50px;
							height: 50px;
							IMG {
								max-height: 50px;
							}
						}
						display: block; width: 100%; .box-sizing(border-box);
						position: relative;
						background: lighten(@color-gray-lightest,5%);
						min-height: 100px;
						border: 1px solid @color-gray-lightest;
						text-align: left;
						.align-items(center);
						.display(flex);
						&.active {
							SPAN.pad {
								padding: 0 15px 0 89px;
							}
							SPAN.img {
								left: 19px;
							}
							background: @color-white;
							border: 2px solid @color12;
							cursor: default;

							&:hover {
								background: @color-white;
								cursor: default;
								SPAN.pad {
									padding: 0 15px 0 89px;
								}
							}
						}
						&:hover {
							SPAN.pad {
								padding: 0 15px 0 89px;
							}
							SPAN.img {
								left: 19px;
							}
							background: lighten(@color-gray-lightest,5%);
							border: 2px solid @color12;
						}
					}
				}
			}

		}
		@media @media-tablet {
			UL {
				.justify-content(flex-start);
				LI {
					margin: 10px 0 0 10px;
					flex: 0 0 31%;
					width: 31%;
				}
			}
			&.menu06b {
				UL {
					LI {
						flex: 0 0 31%;
						width: 31%;
						margin: 10px 10px 0 0;
					}
				}

			}
			&.menu06c {
				UL {
					LI {
						flex: 0 0 33.3333%;
						width: 33.3333%;
					}
				}

			}

		}
		@media @media-mobile {
			UL {
				display: block;
				LI {
					width: 100%;
					/*margin: 10px 0 0 0;
					padding: 20px 0;*/
					padding: 0;
					margin: 0;
					A {
						SPAN.pad {
							padding: 0;
							margin: 0;
						}
						SPAN.img {
							display: block;
							position: relative;
							top: 0;
							IMG {
								max-height: 65px;
							}
						}
						display: block;
						width: 100%;
						margin: 10px 0 0 0;
						padding: 20px 0;
					}
					&:hover {
						width: 100%;
						padding: 0;
						margin: 0;
						A {
							SPAN.pad {
								padding: 0;
								margin: 0;
							}
							SPAN.img {
								top: 0;
							}
							margin: 10px 0 0 0;
							padding: 19px 0;
						}
					}
				}
			}
			&.menu06c {
				UL {
					display: block;
					LI {
						width: 100%;
						height: auto;
						margin: 0;
						padding: 0;
						display: block;
						A {
							width: 100%;
							margin: 0;
						}
						&:hover {
							width: 100%;
							margin: 0;
							padding: 0;
							A {
								margin: 0;
							}
						}
					}
				}

			}
		}
	}
	&.menu07 {
		&.menu07b {
			border-right: 1px solid @color-gray-lightest;
			height: 100%;
			margin-right: 50px;
			UL {
				margin-right: 0;
				LI {
					A {
						&.active {
							border-right: 1px solid white;
							margin-right: -1px;
						}
					}
				}
			}
		}
		UL {
			margin-right: 40px;
			LI {
				&:before { display: none; }
				border-bottom: 1px solid @color-gray-lightest;
				//border-left: 2px solid transparent;
				.link-outline-none;
				A {
					padding: 20px;
					display: block;
					&:hover,
					&.active {
						text-decoration: none;
						padding-left: 18px;
						border-left: 2px solid @color-warn;
					}
					&.active {
						font-weight: bold;
						color: @color-black;
					}
				}
			}
		}
		@media @media-tablet {
			UL {

				LI {

				}
			}
		}
		@media @media-mobile {
			&.menu07b {
				margin-right: 0!important;
				border: none;
				UL {
					LI {
						A {
							&.active {

							}
						}
					}
				}
			}
		}
	}
}

/* 4.4 Menus */
NAV {
	&#menu02 {
		float: left; margin: 10px 0 0 0;
		font-family: @font-heading;
		.ru & {
			font-family: @font-heading-ru;
		}
		&.hasicons { margin: 0; }
		.link-outline-none;
		LI {
			float: left; font-size: 19px; font-weight: bold; margin: 0 0 0 48px; padding: 0;
			.ru & { margin: 0 0 0 30px; }
			&:before { display: none; }
			&.activated A { color: @color01; }
			UL { display: none; }
		}
		A {
			color: @color01;
			&.active { color: @color01; border-bottom: 2px solid @color02; }
			&:hover { color: @color02; text-decoration: none; }
		}
		@media @media-notwide {
			LI {
				margin: 0 0 0 22px;
				.ru & { margin: 0 0 0 15px; }
			}
		}
		@media @media-notdesktop {
			float: none; clear: both; padding: 15px 0 0 0; overflow: visible; display: none; width: 100% !important;
			&.open { display: block; }
			> UL { background: lighten(@color-gray-lightest,5%); -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); }
			UL {
				padding: 18px 7px; margin: 0 -15px;
				UL {
					margin: 10px 0 0 0; background: @color-white; padding: 0;
					LI {
						padding: 10px 10px 10px 20px; margin: 0;
						&.open { background: @color-gray-lightest; }
						&:first-child { border-top: none; padding-top: 20px; }
					}
					A.active { color: @color01 !important; }
					UL {
						padding: 0 0 10px; margin: 0; background: none;
						LI {
							padding: 0; border-top: none;
							&:first-child { padding-top: 0; }
						}
						A { font-size: 15px; }
					}
				}
				LI {
					&.activated UL,
					A.active + DIV.submenu01 UL { display: block; }
					&.activated A { color: @color01; }
				}
				&.closed {
					A.active + DIV.submenu01 UL,
					UL { display: none; }
				}
			}
			LI {
				float: none; margin: 10px 0 0;
				.ru & { margin: 10px 0 0; }
				&:first-child {
					margin: 0 ;

				}
				A {
					display: block; position: relative; color: @color01; font-size: 25px; margin: 0 7px; padding: 5px 0; font-weight: normal;
					&.active {
						color: @color01; border-bottom: none;
						SPAN { border-bottom: 2px solid @color02;}
					}
				}
				LI {
					A {
						font-weight: bold; color: @color-black; padding-right: 0;
						&:after { display: none; }
					}
					LI A { font-weight:  normal; color: @color01; }
				}
				&.open,
				&.activated {
					& > A {
						padding-right: 20px;
						&:after { content: @ico-arrdown; }
					}
				}
				A {
					padding-right: 35px;
					&:after { content: @ico-arrright; font-family: "icons"; position: absolute; right: 5px; top: 50%; margin-top: -10px; }
				}
			}
			DIV.submenu01 > UL > LI > A {
				font-size: 21px; color: @color-black;
				&:after { display: none; }
			}

		}
		@media @media-tablet {
			UL { margin: 0 -20px; }
		}
		&.itb-menu {
			float: none;
			/*margin: 0 125px;*/
			@media @media-desktop {
				UL {
					.display(flex);
					.align-items(center);
					.box-sizing(border-box);
					/*.align-self(center);*/
					LI {
						.display(flex);
						.flex(1);
						.align-items(center);
						.box-sizing(border-box);
						border-left: 1px solid @color-gray-lightest;
						margin: 0;
						/*padding: 10px;*/
						height: 71px;
						min-height: 100%;
						float: none;
						padding-right: 5px;
						&:first-child {
							border: none;
							margin-left: 27px;
						}
						&:last-child {
							margin-right: 100px;
						}
						&.active { color: @color01; border-bottom: 2px solid @color02; }
						A {
							.display(flex);
							.flex(1);
							.align-items(center);
							.justify-content(flex-start);
							.align-self(center);
							/*.justify-content(center);
							min-height: 100%;*/
							.box-sizing(border-box);
							/*-ms-word-break: break-all;*/
							// Non standard for webkit
							/*word-break: break-word;*/
							-webkit-hyphens: manual;
							-moz-hyphens: manual;
							-ms-hyphens: manual;
							hyphens: manual;
							text-align: left;
							&.ico-tellimused {
								&::before {
									font-size: 26px!important;
									margin-top: -14px!important;
								}
							}
							&.ico-tarbimine {
								&::before {
									font-size: 22px!important;
								}
							}
							/*&.ico-teenused {
								&::before {
									margin-top: -16px!important;
								}
							}*/
							&[class^="ico"],
							&[class*=" ico"] {
								color: @color01; display: block; line-height: 2;
								&.active { color: @color01; }
								&:hover { color: @color02; text-decoration: none; }
								font-size: 15px;
								margin-top: 0;
								.display(flex);
								&::before {
									font-size: 24px;
									font-weight: normal;
									float: none;
									margin: 0 auto;
									display: block;
									text-align: center;
									position: absolute;
									line-height: 24px;
									height: 24px;
									width: 30px;
									top: 50%;
									left: 20px;
									margin-top: -12px;
									/*padding-left: 25px;*/
									/*margin-left: 25px;*/
								}
							}
							SPAN {
								float: none;
								padding-left: 60px;
								display: inline-block;
								line-height: 1.2;
								font-weight: normal;
								font-size: 14px;
							}
							&.active { border-bottom: none; }
						}
					}
				}
			}
			@media @media-notwide {
				UL {
					margin: 0;
					padding: 0;
					LI {
						/*&:first-child {
							margin-left: 0;
						}*/
						&:last-child {
							margin-right: 30px;
						}
						A {
							&[class^="ico"],
							&[class*=" ico"] {
								&::before {
									font-size: 22px;
									left: 15px;
									/*padding-left: 15px;*/
									/*margin-left: 15px;*/
								}
							}
							&.ico-tellimused {
								&::before {
									font-size: 24px;
								}
							}
							SPAN {
								padding-left: 50px;
							}
						}
					}
				}
			}
			@media @media-notdesktop {
				UL {
					LI {
						A {
							&[class^="ico"],
							&[class*=" ico"] {
								font-size: 25px;
								color: @color01;
								padding: 0;
								line-height: normal;
								margin-top: 0;
								SPAN {
									padding-right: 10px;
									float: none;
									padding-left: 60px;
								}
								&.ico-tellimused {
									&::before {
										font-size: 28px;
									}
								}
								&::before {
									.display(flex);
									text-align: center;
									float: none;
									height: auto;
									.align-content(flex-end);
									.justify-content(center);
									position: absolute;
									left: 5px;
									margin: 0;
									font-size: 25px;
								}
								&:after {
									display: none;
								}
								&.active {
									SPAN {
										&:after {
											border-bottom: 2px solid @color02;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* 4.5 Search box */
DIV#search01 {
	float: right; margin: 10px 0 10px; position: relative;
	INPUT[type="text"] { float: left; background: @color-white; border-radius: 0 0 0 0; width: 215px; /*width: 165px;*/ height: 30px; border: none; font-family: @font-main;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis; }
	SPAN.btn {
		float: left; border-radius: 0; height: 30px; padding: 0; margin: 0; background: @color-white; color: @color01; top: 0;
		.btn { padding-top: 8px; padding-bottom: 8px; }
	}
	BUTTON { background: none; border:none; height: 30px; line-height: 24px; color: @color01; font-size: 20px; }
	&.loading {
		SPAN.btn {
			width: 30px;  background: @color-white url(loader.gif) no-repeat 50% 50%;
			BUTTON { display: none; }
		}
	}
	::-webkit-input-placeholder,
	:-moz-placeholder,
	::-moz-placeholder,
	:-ms-input-placeholder { color: @color01; opacity: 1; }
	@media @media-notwide {
		INPUT[type="text"] {
			width: 165px;
		}
	}
	@media @media-tablet {
		float: left;
		INPUT[type="text"] {
			width: 165px;
		}
	}
	@media @media-mobile {
		display: none;
	}
}

DIV.search01 {
	margin: 12px 0; position: relative; border: 1px solid @color-gray-lightest;
	INPUT[type="text"] { background: @color-white; border-radius: 0 0 0 0; width: 100%; height: 33px; border: none;  }
	SPAN.btn {
		position: absolute; right: 0; top: 0; border-radius: 0; height: 33px; padding: 0; margin: 0; background: @color-white; color: @color01;
		INPUT { height: 100%; padding: 8px 30px 7px}
	}
	BUTTON { background: none; border:none; height: 33px; line-height: 24px; color: @color01; font-size: 20px; }
	&.loading SPAN.btn {
		width: 30px;  background: @color-white url(loader.gif) no-repeat 50% 50%;
		BUTTON { display: none; }
	}
	::-webkit-input-placeholder,
	:-moz-placeholder,
	::-moz-placeholder,
	:-ms-input-placeholder { color: @color-gray-medium; opacity: 1; }
	.ui-autocomplete {
		position: absolute; z-index: 2; overflow: hidden; .rounded(0); border-style: solid;  border-color: @color-gray-lightest; background: @color-white;
		LI {
			float: none; padding: 5px 10px; margin: 0; font-size: ceil(@base-font-size*1.15);
			&:before { display: none; }
			&.ui-state-focus {
				background: @color-gray-lightest; color: @color02;
				A {
					color: @color02;
					SPAN.meta01,
					SMALL { color: @color-gray-dark; }
				}
			}
			A {
				font-weight: bold; .clearfix(); padding: 5px 10px; margin: -5px -10px;
				SPAN.meta01, SMALL { font-weight: normal; color: @color-gray-dark; font-size: 90%; }
				&:hover { text-decoration: none; }
			}
			SPAN.img {
				float: left; width: 60px;
				IMG { max-width: 100%; max-height: 87px; width: auto; height: auto; }
			}
			SPAN.txt { padding-left: 70px; display: block; }
		}
	}
}

#search02 {
	display: none;
	@media @media-mobile {
		display: block; position: relative; top: 0px; right: 0px; text-indent: -10000px; color: @color01; width: 24px; float: right;
		A,
		A& { text-indent: -10000px; color: @color01; }
		A&:before,
		A:before { color: @color01; width: 20px; height: 26px; text-indent: 0; font-size: 23px; }
		A.open:before { color: @color02; }
	}
}

#search03 {
	display: none;
	@media @media-mobile {
		display: block; overflow: hidden; clear: both; position: absolute; z-index: 109; left: 0; right: 0; top: 100%; background: @color-white;
		DIV.a {
			border: 5px solid @color02; background: @color-white; padding: 3px 5px;
			INPUT { border: none; padding: 0; width: 100%; }
		}
		::-webkit-input-placeholder,
		:-moz-placeholder,
		::-moz-placeholder,
		:-ms-input-placeholder { color: @color01; opacity: 1; }
		&:before { content: ""; width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 7px 9px 7px;
			border-color: transparent transparent @color02 transparent; position: absolute; right: 63px; top: -9px;
		}
		BUTTON {
			text-indent: -10000px; position: absolute; right: -5px; top: 5px; background: none; border: none; color: @color-gray-medium;
			&:before { text-indent: 0; line-height: 24px; }
		}

	}
}

DIV.ac {
	margin-top: 2px; position: absolute; z-index: 999; background: @color-white; top: 100%; width: 100%; padding: 0; border: 3px solid @color-gray-medium-lightest; .box-sizing(border-box);
	&.ac02 {
		width: 200%;
		UL.tabs01 {
			LI { width: 25%;
				&:last-child A {
					border-right-style: solid;
					@media @media-mobile { border-right-style: none; }
				}
			}
		}
		@media @media-tablet {
			width: 100%;
			UL.tabs01 LI { width: 50%; }
			.col { float: none; width: 100% !important; margin: 10px 0 0; }
		}
		@media @media-mobile {
			width: 100%;
			UL.tabs01 LI { width: 50%; }
		}
	}
	UL.tabs01 {
		DIV#search01 & { margin-top: -5px; }
		margin: -3px -1px 0 0; display: block;
		LI {
			width: 50%; float: left; top: 1px; border-top: none; display: block;
			A {
				border-style: solid; padding-left: 7px;
				&,
				& > SPAN { display: block; float: none; }
			}
			&.hasico SPAN.ico {
				margin-right: -10px;
				&:before { margin-right: 5px; }
			}
			&:first-child A { border-left-style: none; }
			&:last-child A { border-right-style: none; }
		}
	}
	DIV.pad {
		padding: 10px 20px 20px;
		& > *:first-child { margin: 0; }
		A {
			text-decoration: underline;
			&:hover { color: @color02; }
		}
	}
	P {
		margin: .7em 0 0 0;
		&.heading01 { color: @color-gray-medium-lightest; font-size: 17px;
			font-family: @font-heading;
			.ru & { font-family: @font-heading-ru; }
		}
		&.heading02 {font-size:  17px; font-weight: bold;
			font-family: @font-heading;
			.ru & { font-family: @font-heading-ru; }
		}
	}
	IMG { max-width: 100%; height: auto; }
	@media @media-mobile {
		position: static; margin-top: 0; border: 5px solid #e7e7e7;
		UL.tabs01 {
			margin-top: 0; top: -1px; background: @color-gray-light;
			LI {
				A {
					border-top: 0 solid; border-bottom: 1px solid @color-gray-medium-light;
					&.active { border-bottom-color: @color-white !important; }
				}
				&:last-child A { border-right: none; }
				&.hasico SPAN.ico { margin-right: 0; }
			}
		}
	}
}

/* 4.7 Breadcrumb */
P#path01 {
	color: @color-gray-medium !important; margin: 5px 0 0 0; padding-bottom: 5px; font-size: 15px !important;
	A { color: @color-gray-medium; }
	SPAN {
		color: @color-gray-medium;
		&.separator {
			color: @color-gray-medium-light; padding: 0 5px; position: relative; top: 2px;
			&:before { content: "›"; font-size: 1.5em; }
		}
	}
	#content & { border-top: none; }
	SECTION & {
		margin: 0;
		+ * { margin-top: 1em; }
	}
	SECTION.type02 & {
		margin: 0; color: @color-white;
		A,
		SPAN { color: @color-white; }
	}
	@media @media-mobile {
		display: none;
		SECTION & {
			+ * { margin-top: 0; }
		}
	}
	@media @media-tablet {
		margin-top: 1.5em;
	}
}

P.social02 {
	margin: .5em 25px 0 0; overflow: hidden;
	A.ico {
		margin: 0 30px 0 0; float: left; height: 30px;
		&.f-right { float: right; margin: 0 0 0 30px; }
		&:last-child { margin: 0; }
		&:before { font-size: 24px; }
	}
}


