.type-arguments01 {
	&.content {
		padding: 80px 0 60px;

		@media @media-mobile {
			padding: 20px 0;
		}
	}

	UL.items10 LI, UL.items11 LI {
		margin: 20px 0;
		padding: 0 15px;

		@media @media-mobile {
			padding: 0;
		}

		@media @media-notmobile {
			max-width: 20%;
		}

		p {
			color: @color-gray01;
			font-weight: 700;

			@media @media-mobile {
				padding-right: 0;
			}
		}
	}
}