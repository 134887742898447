.table-container{
	.table-scroll {
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
	&.table-container--disabled {
		position: relative;
		&::before {
			content: "";
			display: block;
			background: rgba(255,255,255, 0.4);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			cursor: default;
			position: absolute;
		}
	}
}
TABLE.table {
	clear: both;
	margin-bottom: 30px;
	max-width: none;
	.status {
		display: block; position: absolute; left: 20px; top: 45%; width: 8px; height: 8px; .rounded(50%); background: @color-gray-light; color: @color-white; content: ""; text-align: center;
		&.status-ok {
			background: @color27;
		}
		&.status-error {
			background: @color26;
		}
	}
	&.v-center {
		TD, TH { vertical-align: middle; }
	}
	TH {
		padding: 10px 8px;
	}
	TD {
		padding: 20px 8px;
		white-space: normal;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
		word-wrap: normal;
		word-break: normal;
	}
	THEAD {
		TR {
			border: none;
		}
		TH {
			color: @color29;
			cursor: default;
			background: none;
			font-weight: normal;
		}
		.active {
			color: @color-gray01;
			cursor: pointer;
		}
		.sorting {
			display: inline-block;
			position: relative;
			width: 6px;
			height: 14px;
			top: 3px;
			left: 0;


			&:before {
				position: absolute;
				content: '';
				/*width: 0;
				height: 0;*/
				border-left: 3px solid transparent;
				border-right: 3px solid transparent;
				border-bottom: 6px solid @color29;
				top: 0;
				right: 0;
			}
			&:after {
				position: absolute;
				content: '';
				/*width: 0;
				height: 0;*/
				border-left: 3px solid transparent;
				border-right: 3px solid transparent;
				border-top: 6px solid @color29;
				right: 0;
				top: 8px;
			}
			&.sorting_asc {
				cursor: pointer;
				*cursor: hand;
				&:after {
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
					border-top: 6px solid @color29;
				}
				&:before {
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
					border-bottom: 6px solid @color-gray01;
				}
			}
			&.sorting_desc {
				cursor: pointer;
				*cursor: hand;
				&:before {
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
					border-bottom: 6px solid @color29;

				}
				&:after {
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
					border-top: 6px solid @color-gray01;
				}
			}
		}
	}
	&.nostyle {
		TR {
			&:hover {
				& > TD { background: @color-gray-lightest; }
			}
		}
		TH, TD {
			background: none; padding: 5px 0; border-top: none; border-bottom: none; border-left: none; font-size: @base-font-size;
			&:first-child, &:last-child { padding-left: 0; padding-right: 0; }
		}
	}
	&.table-bordered {
		&.no-ico {
			THEAD {
				TR {
					&.has-bg {
						background: @color-gray07;
					}
				}
				TH {
					color: @color-gray01;
					font-size: 15px;
				}
			}
			TH,TD {
				/*white-space: nowrap;*/
				&:first-child {
					padding-left: 15px;
				}
				&:last-child {
					padding-right: 26px;
				}
			}
		}
		border-radius: 0;
		border: 0;
		TR {
			&:hover {
				background: @color-gray07;
				/*cursor: pointer;*/
				TD {
					background: none;
				}
			}
			&:first-child {
				TD {
					border-radius: 0;
					border-top: 1px solid @color-gray06;
				}
			}
			&:last-child {
				border-radius: 0;
				TD {
					border-bottom: 1px solid @color-gray06;
					&:first-child {
						border-radius: 0;
						border-bottom-left-radius: 0!important;
						border-bottom-right-radius: 0!important;
					}
					&:last-child {
						border-radius: 0;
						border-bottom-left-radius: 0!important;
						border-bottom-right-radius: 0!important;
					}
				}
				& > TD {
					&:first-child {
						border-radius: 0;
						border-bottom-left-radius: 0!important;
						border-bottom-right-radius: 0!important;
					}
					&:last-child {
						border-radius: 0;
						border-bottom-left-radius: 0!important;
						border-bottom-right-radius: 0!important;
					}
				}
			}
		}
		THEAD {
			TR {
				background: @color-white;
				TH {
					border: none;
					border-radius: 0;
				}
				&:hover {
					cursor: default;
					background: @color-white;
				}
			}
		}
		TBODY {
			&:last-child {
				TD {
					border-bottom: 1px solid @color-gray06;
					&:first-child {
						border-radius: 0;
					}
					&:last-child {
						border-radius: 0;
					}
				}
				& > TD {
					&:first-child {
						border-radius: 0;
					}
					&:last-child {
						border-radius: 0;
					}
				}
			}
		}
		TD {
			border: 1px @color-gray06;
			border-style: none none solid none;
			/*delete this if role page is uptodate*/
			P {
				margin: 0;
				color: @color-gray01;
				.heading02 {

				}
				/*meta {
					margin: 0.5em 0 0 0;
				}*/
			}
			.table__text {
				margin: 0;
				color: @color-gray01;
			}
			.table__title {
				font-weight: 700;
			}
			&:first-child {
				border-left: 1px solid @color-gray06;
			}
			&:last-child {
				border-right: 1px solid @color-gray06;
			}
		}
		TH {
			P {
				margin: 0;
			}
		}
		TH,TD {
			border-radius: 0;
			&:first-child {
				border-radius: 0;
				position: relative;
				padding-left: 42px;
			}
			A.btn {
				&:hover {
					text-decoration: none!important;
				}
			}
		}
		&.table-bordered__full {
			THEAD {
				TR {
					/*border-style: solid none none solid;
					border-color: @color-gray06;
					border-width: 1px;*/
					TH {
						border-color: @color-gray06;
						border-width: 1px;
						/*border-style: none solid none none;*/
						border-style: solid solid none solid;
					}
				}
			}
			BODY {
				TR {
					/*border-style: none none solid solid;
					border-color: @color-gray06;
					border-width: 1px;*/
					TD {
						border-color: @color-gray06;
						border-width: 1px;
						/*border-style: none solid none none;*/
						border-style: none solid solid solid;
					}
				}
			}
			TR {
				TD {
					white-space: normal;
					-webkit-hyphens: auto;
					-moz-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
					word-wrap: normal;
					word-break: normal;
					border-style: solid;
					border-color: @color-gray06;
					border-width: 1px;

				}
				&:hover {
					background: none;
					& > TD { background: none; }
				}
			}
		}
		&.table__pad {
			TR {
				TH {
					padding: 14px;
				}
			}
			TR {
				TD {
					padding: 14px;
				}
			}
		}
		@media @media-mobile {
			TD {
				P {
					&.heading02 {
						font-weight: bold;
					}
				}
			}
			&.no-ico {
				TH,TD {
					/*white-space: nowrap;*/
					&:first-child {
						padding-left: 12px;
					}
					&:last-child {
						padding-right: 12px;
					}
				}
			}
		}
	}
}
