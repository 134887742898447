.translate-y (@px: 0) {
	-webkit-transform: translateY(@px);
	-ms-transform: translateY(@px);
	-moz-transform: translateY(@px);
	-o-transform: translateY(@px);
	transform: translateY(@px);
}

.translate-x (@px: 0) {
	-webkit-transform: translateX(@px);
	-ms-transform: translateX(@px);
	-moz-transform: translateX(@px);
	-o-transform: translateX(@px);
	transform: translateX(@px);
}
/*scale and transform req prefix*/
.transition (@arguments) {
	-webkit-transition:  -webkit-@arguments;
	-ms-transition: -ms-@arguments;
	-moz-transition: -moz-@arguments;
	-o-transition:  -o-@arguments;
	transition: @arguments;
}

.anim-slide {
	.transition(~"transform .3s, opacity .3s");
	&__zero {
		opacity: 1;
		.translate-y;
		.translate-x;
	}
	&__to-top {
		opacity: 0;
		.translate-y(-50px);
		&.anim-slide__to-top--220 {
			.translate-y(-220px);
		}
	}
	&__to-bottom {
		opacity: 0;
		.translate-y(50px);
		&.anim-slide__to-bottom--220 {
			.translate-y(220px);
		}
	}
	&__left {
		opacity: 0;
		&.anim-slide__left--x200 {
			.translate-x(-200px);
		}
		&.anim-slide__left--x255 {
			.translate-x(-200px);
		}
	}
	&__right {
		opacity: 0;
		&.anim-slide__right--x200 {
			.translate-x(200px);
		}
		&.anim-slide__right--x255 {
			.translate-x(200px);
		}
	}
}
.anim-fade {
	.transition(~"opacity .2s");
	&__zero {
		opacity: 1;
	}
	&__to-back {
		opacity: 0;
	}
	&__to-front {
		opacity: 1;
	}
}




