.package-option {
    background: @color-white;
    border: 1px solid @color-gray05;
    padding: 10px 10px 5px;
    height: 100%;
    color: @color-gray01;
    font-size: 15px;
    /*font-size: 15px; &nbsp ?*/
    @media @media-notdesktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
    }

    &__title {
        color: @color-gray04;
        font-weight: 700;
        text-align: center;
        @media @media-notdesktop {
            font-size: 13px;
        }
    }

    &__value {
        color: @color-black;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        @media @media-notdesktop {
            font-size: 30px;
        }

        &__sufix {
            font-size: 13px;
            font-weight: 400;
            vertical-align: top;
            display: inline-block;
            padding-top: 15px;
            color: @color-gray08;
            @media @media-notdesktop {
                padding-top: 9px;
                margin-left: 7px;
            }
        }
    }

    &__value--sm {
        font-size: 18px;
        line-height: 1;
        padding: 15px;
        @media @media-notdesktop {
            padding: 15px 0 15px 10px;
            text-align: right;
        }
    }

    &__price {
        font-weight: 700;
        color: @color01;
        text-align: center;
        @media @media-notdesktop {
            font-size: 13px;
            line-height: 1.25;
            text-align: right;
        }

        &__sufix {
            font-weight: 400;
            color: @color-gray03;
            @media @media-notdesktop {
                display: block;
            }
        }
    }




    /*font-size: 15px; &nbsp ?*/
    &__price--sm {
        font-size: 15px;
        @media @media-notdesktop {

        }
    }




    LI.active &, &.active {
        border: 1px solid @color03;
        outline: 1px solid @color03;

        @media @media-notdesktop {
            padding: 9px 15px;
        }
    }

    &--full-list {
        padding: 15px 30px 30px;
        margin: 16px 0;
    }

    &--no {
        min-height: 71px;
        justify-content: flex-end;
        flex-direction: column;
        font-size: 15px;


        @media @media-desktop {
            display: flex;
            align-items: flex-end;
        }
        > div {
            text-align: center;
        }
        p {
            margin-top: 5px;
        }
    }
}

.package-option-content {
    @media @media-notdesktop {
        display: none;
    }

    &__title {
        margin: 15px 0;
    }
}

.info-block {
    margin: 20px 0;
    padding: 0 0 0 40px;
    position: relative;

    .info-icon {
        position: absolute;
        left: 0;
        top: -4px;
        background: @color16;
        border-radius: 50%;
        display: inline-block;
        color: @color01;
        width: 30px;
        height: 30px;
        text-align: center;
        vertical-align: middle;
        font-size: 18px;
        margin-right: 8px;
    }

}

// show-more/show/less
.trigger-show-more {
    display: block;
    text-align: center;
    font-weight: 700;
    margin-top: 15px;
    .text-decoration-none;
    &:active, &:focus, &:hover {
        text-decoration: none;
    }

    .show-more-link-copy {
        &:before {
            content: "\f078";
            font-family: FontAwesome;
            margin-right: 5px;
        }
    }
    .show-less-link-copy {
        display: none;
    }
    
    &.open {
        .show-less-link-copy {
            display: block;
            &:before {
                content: "\f077";
                font-family: FontAwesome;
                margin-right: 5px;
            }
        }
        .show-more-link-copy {
            display: none;
        }
    }
}

.content-show-less {
    height: 50px;
    overflow: hidden;
    position: relative;
    margin-bottom: -10px;

    &:before {
        content: "";
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba(255,255,255,0),@color-gray07);
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
    }
    &.content-show-less--light  {
        &:before {
            background: linear-gradient(rgba(255,255,255,0),@color-white);
        }
    }
}