/* General listings */
@import (reference) "mixins/zindex_mixins.less";
UL, OL{
	margin: 0 0;
	UL, OL { margin: 0 0 1em 1em;}
}
LI {
	padding: 0 0 0 16px; margin: 5px 0;
	OL LI { background: none; padding-left: 0; margin: 5px 0; list-style-type: decimal; list-style-position: inside; }
}
OL {
	padding: 0;
	LI {
		background: none; padding: 0; margin: 5px 0; list-style-type: decimal; list-style-position: inside;
		UL LI { padding-left: 16px; list-style: none; }
	}
	UL { margin-left: 20px; }
	> LI { background: none !important; }
}
UL {
	padding: 0; margin: 0; list-style: none;
	LI {
		position: relative; padding: 0 0 0 15px;
		&:before { left: 0; top: .8em; position: absolute; content: @ico-arrright; font-family: "icons"; color: @color-gray-medium; font-size: 8px; }
	}
	&.noico LI {
		padding-left: 0;
		&:before { display: none; }
	}
	&.mrg18 LI {
		margin: 18px 0;
	}
	.scrolling01 {
		&.noborder {border: none !important;}
		overflow: auto;
		display: block;
		border: 1px solid @color-gray-lightest;
		padding: 8px 0;
		width: auto;
		z-index: 9;
		margin: 0;
		max-height: 87px;
		color: @color-gray-medium;
		LI {
			margin-bottom: 10px;
			&:before {display: none;}
			SPAN.heading { border: 1px solid @color-gray-lightest; color: @color-text-main; padding: 5px; cursor: move; }
		}
	}
	&.multicol,
	&.in2cols,
	&.in3cols,
	&.in4cols,
	&.in5cols{
		& > LI {
			float: left; margin: 0 2% 0 0; .box-sizing(border-box);
			@media @media-mobile {
				float: none; width: auto;
			}
		}
	}
	&.in2cols > LI { width: 48%; }
	&.in3cols > LI {
		width: 31.3333%;
		&:nth-of-type(3n+1) {clear: both; }
		@media @media-mobile {
			width: 100%; margin: 0;
		}
	}
	&.in4cols > LI {
		width: 23%;
		@media @media-tablet {
			width: 31.3333%;
		}
	}
	&.in5cols > LI {
		width: 18%;
		@media @media-tablet {
			width: 18%;
		}
		@media @media-mobile {
			width: 48%;
			float: left;
		}
	}
	&.nostyle > LI {
		padding: 0;
		&:before { display: none; }
	}

	&.nospacing {
		margin: 1em 0 0; list-style: none;
		LI { padding: 0 0 0 25px; margin: 0;
			&:before { font-size: 13px; }
		}
		UL { margin: 0; }
	}
}
DL { overflow: hidden; }
DT { float: left; clear: left; width: 30%; }
DD { float: left; width: 50%; }


/* In content */

.content {
	H1:first-child,
	UL.pagetools + H1 { margin: 0; } // Todo: Possibly unused selectors, remove permanently
	P {
		&.meta {
			margin-top: .5em; margin: 0; font-size: 12px; color: @color-gray-medium;
			A { color: @color-gray-medium; }
		}
		&.small { font-size: 12px; margin: 1em 0 0 0; }
	}
	A {
		text-decoration: underline; color: @color01;
		&:hover { color: @color03; }
	}
	UL {
		LI {
			position: relative; padding: .2em 0 .2em 20px;
			&:before { content: @ico-bullet01; font-family: "icons"; position: absolute; left: 0; /*top: .2em;*/ top: 1px; color: @color02; font-size: 15px; }
			&.internal { background: url(old/imbi_internal01.png) 0 .2em no-repeat !important; }
		}
		&.type02 LI { // Todo: Possibly unused selectors, remove permanently
			background: url(old/dot20.png) 3px .4em no-repeat; padding: 0 0 .5em 20px;
			LI {
				background: url(old/dot20a.png) 3px .4em no-repeat;
				LI { background: url(old/dot20b.png) 3px .4em no-repeat; }
			}
		}
		&.in2cols,
		&.in3cols,
		&.in4cols {
			overflow: hidden;
			& > LI { margin: 0 2% 0 0; float: left; .box-sizing(border-box); }
		}
		&.in2cols LI { width: 48%; }
		&.in3cols LI { width: 31%; }
		&.in4cols LI { width: 23%; }
		P.title { font-weight: bold; margin: 0; }
		P.lead { font-weight: normal !important; margin: 0 !important; font-size: 13px !important; }
		TABLE TD & { margin-top: 0; padding: 0; }
		@media @media-mobile {
			&.in2cols,
			&.in3cols,
			&.in4cols {
				& > LI { float: none; width: 100%; margin: 5px 0 0 0; }
			}
		}
	}

}

/* Basic buttons and forms */

BUTTON, INPUT, SELECT, TEXTAREA {
	font-family: inherit; font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }
BUTTON, INPUT {
	line-height: normal;
	&::-moz-focus-inner { border: 0; padding: 0; }
}
BUTTON, SELECT { text-transform: none; }
BUTTON, HTML INPUT[type="button"], INPUT[type="reset"], INPUT[type="submit"] { -webkit-appearance: button; cursor: pointer; *overflow: visible; }
BUTTON[disabled], HTML INPUT[disabled] { cursor: default; }
INPUT[type="checkbox"], INPUT[type="radio"] { .box-sizing(border-box); padding: 0; *height: 13px; *width: 13px; margin: 0; }
INPUT[type="search"] {
	-webkit-appearance: textfield; .box-sizing(content-box);
	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration { -webkit-appearance: none; }
}
TEXTAREA, SELECT, INPUT[type="date"], INPUT[type="datetime"], INPUT[type="datetime-local"], INPUT[type="email"], INPUT[type="month"], INPUT[type="number"], INPUT[type="password"], INPUT[type="search"], INPUT[type="tel"], INPUT[type="text"], INPUT[type="time"], INPUT[type="url"], INPUT[type="week"], INPUT:not([type]) {
	.box-sizing(border-box);
	background-clip: padding-box; -moz-background-clip: padding; -webkit-background-clip: padding;
	-webkit-appearance: none;
	border: 1px solid @color-gray05;
	font-weight: normal;
	color: @color-black;
	background: @color-white;
	&.xs { padding: 5px; font-size: 12px; }
	&.error { border: 1px solid @color-alert04; color: @color-gray01; background: @color-alert05; }
	&:focus { border-color: @color-gray03; outline: none; }
	@media @media-mobile {
		width: 100%;
	}
}
SELECT, INPUT.select {
	padding: 5px 5px 4px; height: 35px; *height: auto; -moz-appearance: none; -webkit-appearance: none;
	text-indent: 0.01px;
	text-overflow: ''; appearance: none;
	overflow: hidden; line-height: 26px;
	.appearance-border-radius;
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	-webkit-border-radius: 0;  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
	-moz-border-radius: 0;  /* Firefox 1-3.6 */
	border-radius: 0;  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
	&::-ms-expand {
		display: none;
	}
	@media screen { @media (min-width: 0px) {
		background: @color-white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAICAYAAAAFiPQnAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGpJREFUeNpilJSUdGFgYNjDQBpwefbsGal64IAJiGcCcRoJetKgesgGLEBsAsRnoPxZRFhYDtVDkaXvibQY2cL3lFrKQITFVLMQ2VJ8FlPVQnRLsVkMs1SZgYqABYsYssXvKU002ABAgAEAMuUc51btI/sAAAAASUVORK5CYII=') 100% 50% no-repeat;
	} }
	@media screen and (min--moz-device-pixel-ratio:0) {
		background: @color-white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkFGQTYwRDdDODc1MTExRTQ4NDgzRjhGNDRGRDAwNkIwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkFGQTYwRDdEODc1MTExRTQ4NDgzRjhGNDRGRDAwNkIwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUZBNjBEN0E4NzUxMTFFNDg0ODNGOEY0NEZEMDA2QjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QUZBNjBEN0I4NzUxMTFFNDg0ODNGOEY0NEZEMDA2QjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4ELdMYAAAAZklEQVR42mKUlJR0YWBg2MNAGnB59uwZXA8TEM8E4jQSDEiD6oEDFiA2AeIzUP4sIgwoh+pBMeQ9kQYhG/Ae3RAGIgzCaQCyIfgMwmsAuiHYDIIZoowvoFiwiCEb9B49ELEBgAADAE4uHOcTWXEIAAAAAElFTkSuQmCC') 100% 50% no-repeat;
		padding-right: 20px;
	}
	.iefix & { background: @color-white; padding-right: 3px;  }
	body:not(:-moz-handler-blocked) & {
		background-position: 100% 50%; padding-right: 30px;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		padding-right: 40px !important;
	}
	@media (-webkit-min-device-pixel-ratio: 0) {
		padding-right: 40px; text-overflow: ellipsis; overflow: hidden;
	}
	&:focus {
		OPTION { background: @color-white; }
	}
	&.xs { padding: 4px 5px; }
}
TEXTAREA {
	overflow: auto; vertical-align: top; padding: 10px 15px; height: 70px; max-height: 70px; min-height: 70px;
	&.size01 {
		height: 200px; max-height: 200px; min-height: 200px;
	}
	&.size02 {
		height: 70px; max-height: 500px; min-height: 70px;
	}
	&.textarea {
		&--md {
			height: 80px; max-height: 500px; min-height: 80px;
		}
	}
	&.noresize { resize: none; }
}

.hasautocomplete { position: relative; }

INPUT[type="date"],
INPUT[type="datetime"],
INPUT[type="datetime-local"],
INPUT[type="email"],
INPUT[type="month"],
INPUT[type="number"],
INPUT[type="password"],
INPUT[type="search"],
INPUT[type="tel"],
INPUT[type="text"],
INPUT[type="time"],
INPUT[type="url"],
INPUT[type="week"],
INPUT:not([type]) {
	padding: 8px 15px 7px; height: 35px; *height: auto;
	border-radius: 0;
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: textfield;
	margin: 0;
}
INPUT.onfocusclear { color: @color-gray-medium; }
INPUT.noborder { border: none !important; background: none !important; }
INPUT.wdate {
	width: 135px; margin: 0; margin-top: 0 !important; background-image: url(old/ico_calendar.png); background-repeat: no-repeat; background-position: 100% 50%;
	&.mr10 { margin-right: 10px; }
}
::-webkit-input-placeholder { color: @color-gray-medium; font-weight: normal; font-style: italic; }
:-webkit-input-placeholder { color: @color-gray-medium; font-weight: normal; font-style: italic;}
::-moz-placeholder { color: @color-gray-medium; font-weight: normal; font-style: italic;}
:-ms-input-placeholder { color: @color-gray-medium; font-weight: normal; font-style: italic;}
:-moz-placeholder { color: @color-gray-medium; font-weight: normal; font-style: italic;}

BUTTON,
INPUT[type="reset"],
INPUT[type="button"],
INPUT[type="submit"],
INPUT[type="file"] > INPUT[type="button"] { &::-moz-focus-inner { padding: 0 !important; border: 0 !important; } }


INPUT.num-noscroll[type=number]::-webkit-inner-spin-button,
INPUT.num-noscroll[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: textfield;
	margin: 0;
}

/* Tables */
TABLE {
	border-collapse: collapse; border-spacing: 0; width: 100%;
	&.fixed {
		TD, TH { word-break:break-all; }
	}
	CAPTION { display: none; }

	&.v-center {
		TD, TH { vertical-align: middle; }
	}
	&.data01 {
		max-width: 100%; width: auto;
		&.noborder {border: none !important;}
		TR > TD {
			background: none!important;
			color: @color-gray-medium;
			padding-right: 5px!important;
			min-width: 70px;
			&:hover {
				background: none;
			}
		}
		THEAD {
			& > TR > TH {
				padding-right: 5px!important;
				min-width: 70px;
				SPAN.heading {
					border: 1px solid @color-gray-lightest;
					color: @color-text-main; padding: 5px;
					cursor: pointer;
					font-weight: 500;
					white-space: nowrap;
					display: block;
				}
			}

		}
	}
	&.footable {
		TD.footable-cell-detail:before { display: none; }
		&.breakpoint {
			& > TR > TD.footable-first-column,
			& > TBODY > TR > TD.footable-first-column {
				position: relative; padding-left: 30px;
				&:before {
					position: absolute; left: 10px; top: 10px;
					width: 16px;
					display: inline-block;
					font-family: 'icons';
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					-webkit-font-smoothing: antialiased;
					margin-right: 5px;
					font-size: 14px;
					color: @color-gray-medium;
					content: @ico-arrright;
				}
				&.ico{
					position: relative;
					padding-left: 20px;
					&:before { position: absolute; left: 0; top: .7em; }
				}
			}

			TD:last-child:before { display: none; }
			.hidecollapsed { display: none; }
			TR.footable-detail-show {
				TD.footable-first-column:before { content: @ico-arrdown; }
				.hidecollapsed { display: inline-block; text-align: left; margin-left: 10px; margin-top: -3px; }
			}
			DIV.footable-row-detail-row { .clearfix(); }
			DIV.footable-row-detail-name { font-weight: bold; float: left; margin-right: 5px; }
			TR.footable-row-detail:hover TD { background: none !important; }
			@media @media-mobile {
			}
		}
		.status-icon {
			&.before {
				display: none;
			}
		}
		.status {
			padding-left: 30px;
			position: relative;
			min-height: auto;
			height: 100%;
			&.desktop-hide {
				display: none;
				@media @media-mobile { display: block; }
			}
			&.status__hastext {
				&--md {
					padding-left: 40px;
				}
			}
			&__note  {
				color: @color-gray03;
				font-family: @font-main;
				font-size: 13px;
				font-weight: 400;
				display: block;
			}
			&.status--md {
				&:before {
					width: 30px; height: 30px; line-height: 30px; font-size: 18px;
				}
			}
			&:before { font-family: "icons"; position: absolute; top: 0; left: 0; bottom: 0; margin: auto; width: 22px; height: 22px; font-size: 12px; .rounded(50%); /*background: @color-gray-light;*/ color: @color-white; content: ""; text-align: center; line-height: 22px;  }
			&.status-ok {
				H4 { color: @color-positive; }
				&:before { background: @color-positive; content: @ico-check; }
			}
			&.status-error {
				H4 { color: @color-msg-negative-text; }
				&:before { background: @color-msg-negative; content: @ico-exclamation; }
			}
			&.status-billing {
				&:before { background: @color-msg-info; }
			}
			&.status-loading {
				&:before { background: url(loader.gif) no-repeat 50% 50%; }
			}
			&.status-smartpost {
				&:before { }
			}

			&.status__info-alert {
				&:before { font-family: FontAwesome; background: @color-msg-negative; content: "\f12a"; color: @color-msg-negative-text; }
			}
			&.status__warning {
				&:before { font-family: FontAwesome; background: @color-msg-warn; content: "\f12a"; color: @color-msg-warn-text; }
			}
			&.status__ok {
				&:before { font-family: FontAwesome; background: @color-msg-positive; content: "\f00c"; color: @color-msg-positive-text; }
			}
			&.status__info {
				&:before { font-family: FontAwesome; background: @color-msg-info; content: "\f129"; color: @color-msg-info-text; }
			}
			&.status__muted {
				&:before { font-family: FontAwesome; background: @color-msg-muted; color: @color-msg-muted-text; }
			}
			.status__note  {
				color: @color-gray03;
				font-family: @font-main;
				font-size: 13px;
				font-weight: 400;
			}
			.status__text  {
				color: @color-gray01;
				font-family: @font-main;
				font-size: 15px;
				font-weight: 400;
			}
		}
		/*.status-logo {
			&.status-smartpost-dpd {
				background: url(status-dpd.png) no-repeat 100% 50%;
			}
			&.status-smartpost-itella {
				 background: url(status-itella.png) no-repeat 100% 50%;
			}
			&.status-omniva {
				background: url(status-omniva.png) no-repeat 100% 50%;
			}
		}*/
		.status-list {
			padding-bottom: 1em;
			position: relative;
			SPAN.txt {
				/*.display(flex);*/
				padding-left: 32px;
				SPAN.note {
					color: @color-gray-medium;
				}
				.span01 {
					.flex(1 1 62%);
				}
				.span02 {
					font-size: 12px;
					.display(flex);
					.justify-content(flex-end);
					.flex(1 0 auto);
				}
			}
			.status-logo {
				display: inline-block;
				position: absolute;
				left:0;
				top: 0;
				width: 32px;
				text-align: left;
				IMG {
					width: 80%;
				}
			}
			.status-ico {
				display: inline-block;
				position: absolute;
				width: 32px;
				text-align: left;
				height: 16px;
				margin-right: 8px;
				.status-icon {
					display: inline-block;
					position: relative;
					top: 4px;
				}
			}
		}

		.process {
			width: 100%;
			position: absolute;
			z-index: -1;
			height: 100%;
			top: 0;
			left: 0;
			&.bar-send {
				background: @color-msg-positive;
			}
			&.bar-unsend {
				background: @color-msg-warn;
			}
			@media @media-mobile {
				position: relative;
				background: none;
			}
		}
		.hasico {
			&.ico-sms {
				position: relative;
				font-size: 18px;
				display: inline-block;
				text-align: center;
				SPAN.numbers {
					position: absolute;
					background: #0019af;
					color: white;
					font-size: 8px;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					text-align: center;
					line-height: 12px;
					top: 8px;
					right: 4px;
					display: inline-block;
				}
			}
		}
	}
	&:first-child {
		DIV.tabcontent01 &,
		DIV.block01 & { margin-top: 20px; }
		DIV.tabcontent01 .margneg &,
		DIV.block01 .margneg & { margin-top: 0; }
		.itb DIV.tabcontent01 &,
		.itb DIV.block01 & {
			margin-top: 0;
			TH { border-top: 2px dotted transparent !important; padding-top: 0; }
		}
	}
	&.type02 {
		TH { background: none; color: @color-black; padding: 5px 0; border: none; }
		TD { background: none; border: none; color: @color-black; padding: 5px 0; }
	}
	&.type03 {
		TH,
		TD {
			font-size: 16px; background: none; border: 1px @color-gray-light; border-style: none none solid; color: @color-black; padding: 5px;
			word-wrap: normal;  word-break: normal; white-space: normal;
			&:hover { background-repeat: no-repeat; }
		}
		TR.total TD { color: @color02; font-size: 22px; font-weight: bold; border-bottom: none; }
		@media @media-mobile {
			margin: 0; font-size: 15px;
			+ .btn.xxl { margin-top: 0 !important; }
			TD, TH {
				&:first-child { padding-left: 0; }
				&:last-child { padding-right: 0; }
			}
		}
	}
	&.type04 {
		TH,
		TD {
			padding: 10px;
			&.ico { padding: 10px; }
		}
	}
	&.type05 {
		TR {
			TH {

			}
			TD {
				/*&.last {
					border-style: solid solid solid dashed;
				}*/
				white-space: normal;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
				hyphens: auto;
				word-wrap: normal;
				word-break: normal;
				&:first-child { border-left: 1px solid @color-gray-lightest; }
				&:last-child { border-right: 1px solid @color-gray-lightest; }
				border-style: solid dashed solid dashed;
				border-color: @color-gray-lightest;
				border-width: 1px;

			}
			&.even {
				background: lighten(@color-gray-lightest,5%);
				&:hover {
					background: lighten(@color-gray-lightest,5%);
				}
				TD {
					background: lighten(@color-gray-lightest,5%);
					&:hover {
						background: lighten(@color-gray-lightest,5%);
					}
				}
			}
			&:hover {
				background: none;
				& > TD { background: none; }
			}
		}
	}
	&.nostyle {
		TR {
			&:hover {
				& > TD { background: @color-gray-lightest; }
			}
		}
		TH, TD {
			background: none; padding: 5px 0; border-top: none; border-bottom: none; border-left: none; font-size: @base-font-size;
			&:first-child, &:last-child { padding-left: 0; padding-right: 0; }
		}
	}
	&.noborder TH { border-top: none; }
	&.fixed02,
	TBODY.fixed02 { table-layout: fixed; }
	TR {
		&:first-child TH.vertical { border-top-width: 3px; }
		&.details {
			TD, TH {
				background: none; color: @color-black; border-bottom: 1px solid @color-gray-lightest !important; border-top: 1px solid @color-gray-lightest !important; border-left: none; border-right: none;
				&:first-child { padding-left: 0; }
			}
			TD { padding-left: 0; padding-right: 0; }
		}
		&.status01 TD { background: @color-status01; font-weight: bold; border-bottom-color: @color-status01-border; }
		&.status02 TD { background: @color-status02; font-weight: bold; border-bottom-color: @color-status02-border; }
		&:hover {
			& > TD { background: @color09; z-index: 0; }
			TD.type01 { background: @color10; }
		}
		&.total {
			font-weight: bold;
			TD, TH { background: lighten(@color-gray-lightest,5%); }
		}
		&.compare TD SPAN.hasico { padding: 3px 0 7px 30px; }
		LABEL.cb {
			overflow: hidden; width: 31px; height: 31px; margin: -8px 0; display: block; text-align: center; line-height: 34px; cursor: pointer; color: @color-black; border-radius: 7px; .box-sizing(border-box); background: @color-gray-lightest; -webkit-box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
			box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
			INPUT[type="radio"],
			INPUT[type="checkbox"] { margin: 0; padding: 0; position: relative; z-index: 1; }
		}
		&.active LABEL.cb:before { position: absolute; left: 0; top: 0; background: @color01; width: 31px; height: 31px; z-index: 2; content: @ico-check; font-family: "icons"; color: @color-white; }
		&.subheading { font-weight: bold; }
		@media @media-mobile {
			&.mobile-show TD { display: table-cell !important; }
		}
	}
	TH {
		text-align: left; vertical-align: top;
		background: @color11; color: @color-black; /*padding: 10px 5px;*/ padding: 10px 10px; border-bottom: 1px solid @color-gray-medium-lightest; border-top: 3px solid @color-gray-medium-lightest; border-left: none; border-right: none; font-size: 14px;
		&.muted { color: @color-gray-medium; }
		&.ico:before { display: none; }
		&.subheading { background: none; color: @color-black;  padding: 30px 10px 5px; border-top: none !important; }
		&:first-child { padding-left: 10px; }
		&:last-child { padding-right: 10px; }
		&.vertical { border-top-width: 1px;  }
		&.type01 { background: @color03; color: @color-white; border-top-color: darken(@color03,10%); border-bottom-color: darken(@color03,10%); }
		A.sort {
			cursor: pointer; display: inline-block; text-decoration: none; font-size: 12px;
			SPAN { position: absolute; left: -10000px; }
			&:after { font-family: "icons"; }
			&.desc:after { content: @ico-arrdown; }
			&.asc:after { content: @ico-arrup; }
		}
		A.sort01 {
			cursor: pointer; display: inline-block; text-decoration: none; font-size: 14px; color: @color-black;
			SPAN { position: absolute; left: -10000px; }
			&:after { font-family: "icons"; padding-left: 5px; color: @color-black; font-size: 10px; }
			&.desc:after { content: @ico-arrdown; }
			&.asc:after { content: @ico-arrup; }
		}

	}
	TD {
		text-align: left; vertical-align: top;
		font-size: 14px; background: none; /*padding: 10px 5px;*/ padding: 10px 10px; border-left: 1px solid @color-gray-medium-lightest; border-bottom: 1px solid @color-gray-medium-lightest; border-right: none;
		&:first-child { padding-left: 10px; border-left: none; }
		&:last-child { padding-right: 10px; }
		&.footable-cell-detail:hover { background: none; }
		&.footable-first-column { border-left: none; }
		&.actions { padding: 5px 25px 5px 5px; width: 1%; white-space: nowrap; }
		&.type01 {
			background: @color12; border-bottom-color: darken(@color12, 10%); border-top-color: @color01; color: @color-black; border-left: none; border-right: none;
			+ TD { border-left: none; }
		}
		&.hasico:before { content: ""; float: left; margin-right: 5px; }
		&.subheading { font-family: @font-main; font-size: 16px; font-weight: normal;  }
		SPAN.hasico {
			display: block; padding: 0 0 0 30px; position: relative;
			&:before { position: absolute; top: 0; left: 0; width: 24px; height: 24px; margin-right: 5px; }
			&.internal-ico { top: 0; min-height: 24px; }
			&.ok-ico,
			&.error-ico {
				&:before { top: -2px; }
			}
		}
		IMG.margneg { margin-top: -3px; }
		UL { margin-top: 0; padding: 0; }
	}
	TD, TH {
		&.ico { padding: 10px 5px 10px 5px; width: 1%; }
	}
	TABLE {
		TD, TH {
			background: none; color: @color-black; border-bottom: 1px solid @color-gray-lightest !important; border-top: 1px solid @color-gray-lightest !important; border-left: none; border-right: none;
			&:first-child { padding-left: 0; }
		}
		TR {
			&:hover TD { background: none; }
			&:last-child TD { border-bottom: none !important; }
		}
	}
	.itb & {
		TH { background: none; color: @color-black;  padding: 15px 10px; border-top: 2px dotted @color-gray-medium-lightest !important; border-bottom: none; }
		TD {
			background: @color-white; border: none !important; color: @color-black; padding: 16px 10px;
			&.type01 { background: @color11; }
			&.actions { padding: 5px 25px 5px 5px; }
		}
		&.nopadding TD { padding: 16px 5px; }
		TR {
			&.even {
				TD,
				&:hover TD {
					background: @color11;
					&.type01 { background: @color09; }
				}
			}
			&:hover {
				TD {
					background: none;
					&.type01 { background: @color11; }
				}
			}
			&.details {
				TD,
				TH { border-bottom: 1px solid @color-gray-lightest; border-top: 1px solid @color-gray-lightest; background: @color-white; }
				TD.pad { padding: 10px; }
				&.compare TD.pad { padding: 10px 10px 10px 20px; }
			}
			&.status01 {
				TD { background: @color-status01; font-weight: bold; border-bottom: none; }
				&.even TD { background: @color-status01-border; }
			}
			&.status02 {
				TD { background: @color-status02; font-weight: bold; }
				&.even TD { background: @color-status02-border; }
			}
			&.status03 {
				DT { word-wrap: normal;  word-break: normal; white-space: normal; }
				TD { background: @color-white;
					padding: 10px 10px;
					font-size: 14px;
					&:last-child {
						width: 380px;
					}
				}
				border-top: 1px solid @color-gray-lightest;  border-bottom: 1px solid @color-gray-lightest; background: @color-white;
				&.even TD { background: lighten(@color-gray-lightest,5%);  }
				&.even { border-left: 1px solid @color-gray-lightest; border-right: 1px solid @color-gray-lightest; }
				/*SPAN.note { padding-left: 20px; }*/
			}
			&.status04 {
				DT { word-wrap: normal;  word-break: normal; white-space: normal; }
				TD { background: @color-white;
					padding: 10px 10px;
					font-size: 14px;
					&:last-child {
						width: 380px;
					}
				}
				border-top: 1px solid @color-gray-lightest;  border-bottom: 1px solid @color-gray-lightest; background: @color-white;
				&.even TD { background: lighten(@color-gray-lightest,5%);  }
				&.even { border-left: 1px solid @color-gray-lightest; border-right: 1px solid @color-gray-lightest; }
				SPAN.note { padding-left: 20px; }
			}
		}
	}
	&.compare01 {
		table-layout: fixed;
		TH,
		TD {
			padding: 4px; width: 20%; background: none; border: none; word-wrap: break-word;
			&:first-child { padding-left: 0; }
			/* three items */
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ * {
				width: 33.3333%;
			}

			/* four items */
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ * {
				width: 25%;
			}
		}
		SPAN.ico {
			&-check:before { color: @color-positive; }
			&-minus:before { color: @color-negative; }
		}
		TR {
			&.pt {
				TD, TH { padding-top: 40px; }
			}
			&.pb {
				TD, TH { padding-bottom: 50px; }
			}
			&.stripetop {
				TD, TH { border-top: 1px solid @color-gray-lightest; }
			}
			&:hover TD { background: none; }
		}
		TH.label { font-size: 20px; font-weight: bold; }
		TD {
			&.label {
				font-weight: bold;
				&:before { float: left; line-height: 22px; font-size: 22px; color: @color-gray-medium; display: block; width: auto; height: 22px; margin-right: 10px; font-weight: normal; }
			}
			&.subheading {
				font-size: 20px; font-weight: bold;
				&:before { font-size: 38px; color: @color01; display: block; width: auto; height: 48px; margin-right: 30px; }
			}
			&.subheading02 { font-weight: bold; }
			&.show-tooltip:hover { color: #00baf2; }
			SPAN.ico {
				text-indent: -10000px; display: block;
				&:before { text-indent: 0; float: left; }
				&-check:before { color: @color-positive; }
				&-minus:before { color: @color-negative; }
			}
		}
		DIV.posrel { padding-top: 2em; }
		A.remove {
			font-size: 13px; position: absolute; top: 0; left: 0; padding: 0 !important;
			SPAN { text-decoration: underline; }
		}
		P.heading01 { font-size: 20px; font-weight: normal;
			font-family: @font-heading;
			.ru & { font-family: @font-heading-ru; }
			&.heading01b {
				display: block; position: relative; background: @color02; padding: 10px 20px; margin-top: 20px; color: @color-white;
				A { color: @color-white; }
				&:before { font-family: "icons"; content: @ico-owl; font-weight: normal; line-height: 30px; position: absolute; color: @color02; right: 20px; bottom: 100%; font-size: 30px;  }
			}
		}
		UL.colors01 { margin: -6px 0 0 -8px; }
		P.price01 { margin: 0; font-size: 28px; font-weight: bold; }
		P.price02 {
			margin: -.5em 0 0 0; font-size: 20px; color: @color-gray-medium;
			+ A.btn { margin: 1.5em 0 0 0; }
		}
		UL.type05 { margin-top: 10px; color: @color-black; }
		#sticky {
			TR { position: absolute; left: 0; right: 0; }
			TH,TD {
				display: block; float: left; width: 20%; .box-sizing(border-box);
				&:first-child:nth-last-child(3),
				&:first-child:nth-last-child(3) ~ * {
					width: 33.3333%;
				}

				/* four items */
				&:first-child:nth-last-child(4),
				&:first-child:nth-last-child(4) ~ * {
					width: 25%;
				}
			}
			DIV.posrel { padding-top: 0; overflow: hidden; height: 0; min-height: 0; }
			&.scroll-to-fixed-fixed {
				DIV.posrel { min-height: 90px; overflow: visible; }
				TH:first-child DIV.posrel:before { content: ""; background: @color-white; position: fixed; top: 0; left: 0; right: 0; height: 110px; border-bottom: 1px solid @color-gray-lightest; }
			}
			IMG { max-height: 70px; max-width: 37px; height: auto; width: auto; position: absolute; left: 0; top: 15px; }
			P.heading01 {
				height: 2.5em; line-height: 1.25; overflow: hidden; padding-left: 50px; margin: 10px 0 0 0; font-size: 15px; font-weight: bold;
				font-family: @font-heading;
				&.heading01b {
					background: none; padding-top: 0; padding-right: 0; padding-bottom: 0; margin-top: 10px; color: @color02;
					&:before { display: none; }
					A { color: @color02; }
				}
				.ru & { font-family: @font-heading-ru; }
				A {
					color: @color-black;
					&:hover { color: @color02; text-decoration: none; }
				}
			}
			A.remove { padding: 0 !important; position: static; margin-left: 50px; font-size: 13px; }
		}
		@media @media-notdesktop {
			DIV.posrel { padding-top: 3.6em; }
		}
		@media @media-tablet {
			A.btn { text-align: left; }
		}
	}

	INPUT[type="checkbox"] { margin: 0; }
	TD  {
		> A.btn {
			display: inline-block; text-align: center; text-decoration: none; margin: 0 0 0 5px; font-size: 12px; padding: 7px 10px !important;
			&.discrete { padding: 7px 0 !important; }
			&.main { padding: 6px 8px !important; }
			&.print { padding: 4px 0 0 25px !important; background-position: 0 .2em; }
			&.edit,
			&.remove,
			&.view { padding: 4px 0 0 !important; }
			&.green {
				padding: 10px 30px 9px!important;
			}

		}
	}
	/*
	A.btn {
		display: inline-block; text-align: center; text-decoration: none; margin: 0 0 0 5px; font-size: 12px; padding: 7px 10px !important;
		&.discrete { padding: 7px 0 !important; }
		&.main { padding: 5px 8px !important; }
	}
	*/
	.error-ico:before { margin: .2em 10px 0 0; }
	.ok-ico:before { margin: .2em 10px 0 0; }
	@media @media-mobile {
		.itb & {
			TR {
				&.status03 {
					TD {
						padding: 5px;
						font-size: 14px;
						&:last-child {
							width: auto;
						}
					}
				}
			}
		}
		TD,
		TH {
			word-break: break-all;
			&.ico { width: 1%; white-space: nowrap; }
		}
		TD {
			&.ico, &.actions  { white-space: nowrap;}
			&.actions {
				padding: 5px;
				A.btn {
					&.edit,
					&.view,
					&.remove {
						text-indent: -10000px; display: inline-block; width: auto;
						&:before { text-indent: 0; float: left; }
					}
				}
			}
		}
	}
}
.table-wrap {
	@media @media-mobile {
		overflow: auto; width: 100%;
		TABLE { table-layout: auto; }
		TD,
		TH { min-width: 150px; word-wrap: normal; word-break: normal; }
	}
}

/* Helpers */
.expand {
	A.btn.discrete {
		margin: 0;
	}
	.ico-toggler,
	.ico-arrdown,
	.ico-arrup {
		&:before {
			margin-right: 5px;
		}
	}
	.ico-toggler {
		font-size: 12px!important;
		margin: 0!important;
		padding: 0!important;
	}

	&.open {
		.link-outline-none;
		A.toggler {
			SPAN.a { display: none; }
			SPAN.b { display: block; }
		}
		/*&.expand-content { display: block; }*/
	}
}
.expand-content {
	display: none!important;
	&.open {
		display: block!important;
	}
}
.longtext, .hyphenate, .form01 LABEL, DT, DD {
	-ms-word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;

}
.overflow-ellipsis {
	overflow: hidden;  white-space: nowrap;  text-overflow: ellipsis;
}
.overflow-ellipsis-onlydesktop {
	@media @media-desktop {
		overflow: hidden;  white-space: nowrap;  text-overflow: ellipsis;
	}
}
/*add item animation*/
.new-item {
	 .display(flex);
	/* opacity: 0;*/
	 animation: new-item-animation .3s linear forwards;
 }
@keyframes new-item-animation {
	from {
		/*opacity: 0;*/
		transform: translateY(-70px);
	}

	to {
		/*opacity: 1;*/
		transform : translateY(0);
	}
}

/*animation*/
.item-animation {
	animation: new-item-animation .3s linear forwards;
}

/*fadein animation*/
.fadeIn-animation {
	-webkit-animation: fadein .3s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein .3s; /* Firefox < 16 */
	-ms-animation: fadein .3s; /* Internet Explorer */
	-o-animation: fadein .3s; /* Opera < 12.1 */
	animation: fadein .3s;
}


@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}


/*remove items*/
.remove-item {
	opacity: 1;
	animation: remove-item-animation .3s linear forwards;
}

.fadeout-animation {
	-webkit-animation: fadeout .3s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadeout .3s; /* Firefox < 16 */
	-ms-animation: fadeout .3s; /* Internet Explorer */
	-o-animation: fadeout .3s; /* Opera < 12.1 */
	animation: fadeout .3s;
}
@keyframes fadeout {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

@keyframes remove-item-animation {
	from {
		opacity: 1;
		transform : translateY(0);
	}

	to {
		opacity: 0;
		transform: translateY(-100px);
	}
}


.rounded { .rounded(3px); }
.fs30 {
	font-size: 30px;
	&:before {font-size: @base-font-size; }
	&.error-ico:before { margin: .3em 10px 0 0; }
	&.ok-ico:before { margin: .3em 10px 0 0; }
	&.time-ico:before { margin: .3em 5px 0 0; }
}
.req { color: @color02 !important; }

.responsive-hide { display: none; }
.desktop-hide { display: none; }
.nonresponsive {
	.responsive-hide { display: block; }
	A.responsive-hide,
	SPAN.responsive-hide { display: inline; }
	&-forced .responsive-hide { display: none; }
	&-forced .responsive-forced-hide { display: none !important; }
}

@media @media-mobile {
	.desktop-hide {
		display: block !important;
		TABLE& { display: table !important; }
		TR& { display: table-row !important; }
		TD& { display: table-cell !important; }
	}
	.mobile {
		&-hide { display: none !important; }
		&-hide02 { position: absolute; left: -10000px; }
		&-show {
			display: block !important; visibility: visible;
			TABLE& { display: table !important; }
			TR& { display: table-row !important; }
			TD& { display: table-cell !important; }
		}
		&-nofloat { float: none !important; }
		&-f-left { float: left !important; }
		&-f-right { float: right !important; }
		&-nofix { position: relative !important; left: 0 !important; right: 0 !important; top: 0 !important; bottom: 0 !important; z-index: 1 !important; }
		&-nomargin { margin: 0 !important; }
		&-noplaceholder {
			&::-webkit-input-placeholder { color: @color-white !important; opacity: 0; }
			&:-moz-placeholder { color: @color-white !important; opacity: 0; }
			&::-moz-placeholder { color: @color-white !important; opacity: 0; }
			&:-ms-input-placeholder { color: @color-white !important; opacity: 0; }
		}
		&-block { display: block !important; }
		&-clear { clear: both !important; }
		&-mt10 { margin-top: 10px !important; }
	}
}
@media @media-tablet {
	.tablet {
		&-hide { display: none !important; }
		&-show {
			display: block !important; visibility: visible;
			TABLE& { display: table !important; }
			TR&{ display: table-row !important; }
			TD&{ display: table-cell !important; }
		}
		&-nofloat { float: none !important; }
		&-block { display: block !important; }
		&-clear { clear: both !important; }
		&-wauto {
			&,
			&.col.tablet-clear,
			&.col {
				width: auto !important;
			}
		}
	}

}


/* 1. Icons */
.ico,
.hasico {
	line-height: 24px;
	H2&, H3&, H4&, H5&, H6& { line-height: 1.2; }
	&:before { float: left; width: 24px; height: 24px; margin: 0 5px 0 0; font-family: "icons"; }
	&.nofloat:before {
		float: none; display: inline-block; position: relative; top: -3px;
		.searchresults & { }
	}

}

.ico {
	&-alert:before {
		color: @color02; margin-right: 5px;
		.warn& { color: @color-warn; }
	}
	&.news:before { content: @ico-newspaper; }
	&.average:before { content: @ico-graph; }
	&.call:before { content: @ico-call; }
	&-sms:before,
	&.sms:before,
	&.envelope:before { content: @ico-envelope; }
	&-unlimited:before,
	&.capacity:before { content: @ico-unlimited; color: @color-gray-medium; }
	&-limited:before,
	&-capacity:before,
	&.capacity-limited:before { content: @ico-limited; color: @color-gray-medium; }
	&-up:before,
	&.up:before { content: @ico-upload; color: @color-gray-medium; }
	&-down:before,
	&.down:before { content: @ico-download; color: @color-gray-medium; }
	&-ok:before { color: @color-positive; margin-right: 5px; }
	&-add:before { font-family: @font-main; content: "+"; background: @color-positive; border-radius: 8px; text-align: center; height: 16px; width: 16px; line-height: 18px; color: @color-white; font-weight: bold; font-size: 16px; margin-top: .2em; }
	&-internal:before { content: "S"; font-family: @font-main; padding: 0; width: 16px !important; height: 16px !important; border-radius: 8px; background: @color02; color: @color-white; line-height: 16px; text-align: center; font-size: 11px; font-weight: bold; margin: .2em .5em 0 0; }
	&-tablet:before { content: @ico-device-phone; }
	&-display02:before { content: @ico-display2; }
	&-triangleright:before { content: @ico-bullet01; }
	&-alert02 {
		position: relative; min-height: 22px; display: inline-block; padding-left: 25px;
		&:before { position: absolute; width: 20px !important; height: 20px !important; background: #ff6400; border-radius: 50%; content: @ico-exclamation; color: #fff; line-height: 20px !important; font-weight: bold; font-size: 12px !important; text-align: center; top: 3px; left: 3px; }
	}
	&-alert03 {
		position: relative;
		&:before { content: ""; border: solid; border-width: 22px 22px 0 0; width: 0; height: 0; border-color: @color04 transparent transparent transparent; }
		&:after { position: absolute; content: @ico-exclamation; line-height: 1; color: @color-white; top: 3px; left: 2px; font-size: 9px; font-weight: bold; }
	}
	&-alert04 {
		&:before { content: @ico-exclamation; border: none; width: 20px !important; height: 20px !important; background: @color02; text-align: center; border-radius: 50%; line-height: 20px !important; color: @color-white; font-size: 12px; font-weight: bold;
			STRONG& { float: none; }
		}

	}
	&-stop-alert01 {
		&:before { font-family: "icons"; content: @ico-stop-stop;  font-size: 12px;  }
	}
	&-stop-alert02 {
		&:before { font-family: "icons"; content: @ico-stop-limit;  font-size: 12px;  }
	}
	&-stop-alert03 {
		&:before { font-family: "icons"; content: @ico-stop-phone;  font-size: 12px;  }
	}
	&-stop-alert04 {
		&:before { font-family: "icons"; content: @ico-stop-stopped;  font-size: 12px;  }
	}

	&-4g:before { content: ""; width: 17px; height: 13px; background: url(ico_4g.png) no-repeat 50% 50%; margin: -6px 0 0 0; }
	&.green:before,
	&-green:before { color: @color-positive; }
	&-accessory:before { content: @ico-device-accessory; }
	&-star:after { content: "★"; color: @color04; margin-left: 5px; }
	&-star01:before,
	&-contract-pen:before,
	&-contract-euro:before { color: @color01; margin: 0 10px 0 0; }
	&-star01:before { font-size: 17px;  line-height: 1.5; }
	&-close {
		&:before { content: "╳"; line-height: 40px; float: left; width: 40px; height: 40px; text-indent: 0; text-align: center; color: @color-gray-medium-light; font-size: 16px; position: absolute;  right: 0; top: 0; cursor: pointer;  }
	}
	&-check02 {
		 width: 20px; height: 20px; .box-sizing(border-box); background: @color-positive; .rounded(50%); display: inline-block; text-align: center; top: .3em; position: relative;
		 &:before {
			 font-family: "icons"; content: @ico-check; float: left; width: 100%; text-align: center; left: 0; top: 0; font-size: 8px; border-color: @color-positive; color: @color-white; line-height: 20px; }
	}
	&-delivery {
		&:before { color: @color01; content: @ico-delivery; font-size: 16px; }
	}
	&-delivered {
		&:before { color: @color01; content: @ico-delivered; font-size: 16px; }
	}
	&-cancellation {
		&:before { color: @color01; content: @ico-cancellation; font-size: 16px; }
	}
	&-inprocess {
		&:before { color: @color01; content: @ico-inprocess; font-size: 16px; }
	}
	&-returned {
		&:before { color: @color01; content: @ico-returned; font-size: 16px; }
	}
	&-canceled {
		&:before { color: @color-msg-negative; content: @ico-canceled; font-size: 16px; }
	}
	&-check03 {
		&:before { color: @color-positive; content: @ico-check03; font-size: 16px; }
	}
	&-conversation {
		&:before {
			line-height: 24px;
			margin: 0;
		}
	}
	&-pdf { &:after { content: "PDF"; line-height: normal; font-family: @font-main; padding: 1px 3px; color: @color-white; display: inline-block; background: @color02; .rounded(3px); font-size: 8px; position: relative; top: -4px; } }
	&-infinity { &:after { content: "∞"; font-size: 26px; width: 22px; height: 18px; line-height: 14px; font-weight: normal; color: @color-black; font-family: @font-heading-ru; display: inline-block; top: 0.1em; left: 0; position: relative; @media @media-mobile { font-size: 22px; } } &.white { &::after { color: @color-white; } } &.bold { &::before { font-weight: bold; } } }
	&-bdoc {
		&:after { content: "bdoc"; line-height: normal; font-family: @font-main; margin-left: .5em; padding: 1px 3px; color: @color-white; display: inline-block; background: @color01; .rounded(3px); font-size: 8px; position: relative; top: -4px; }
	}
	&-pipe { &:before { content: "|"; font-size: 26px; line-height: 14px; font-weight: normal; font-family: @font-heading-ru; display: inline-block; top: 0.8px; left: 0; position: relative; @media @media-mobile { font-size: 22px; } } &.white { &::after { color: @color-white; } } &.bold { &::before { font-weight: bold; } } }
}
.error-ico:before{ font-family: "icons"; content: @ico-alert; color: @color-negative; }
.time-ico,
.ico-time {
	&:before { font-family: "icons"; content: @ico-time; margin-right: 5px; }
}
.down-ico:before { font-family: "icons"; content: @ico-download; color: @color-gray-medium; }
.internal-ico:before { content: "S"; font-family: @font-main; padding: 0; width: 16px !important; height: 16px !important; border-radius: 8px; background: @color02; color: @color-white; line-height: 16px; text-align: center; font-size: 11px; font-weight: bold; margin: .2em .5em 0 0; }
.ok-ico:before { font-family: "icons"; content: @ico-ok; color: @color-positive; }
.up-ico:before { font-family: "icons"; content: @ico-upload; color: @color-gray-medium; }
.limited-ico:before { font-family: "icons"; content: @ico-limited; color: @color-gray-medium; }
.unlimited-ico:before { font-family: "icons"; content: @ico-unlimited; color: @color-gray-medium; }
/*.contract-ico {
	display: inline-block;
	text-align: center;
	.w50;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	.box-sizing(border-box);
	&::before {
		font-size: large;
	}
}*/
.ico--2x {
	&:before {
		font-size: 24px;
		color: @color-gray01;
	}
}
.title-hasicon {
	&--title-md {
		float: left;
		margin: 0;
		display: inline-block;
		width: calc(100% - 23px);
	}
	.icon {
		float: left;
	}

}
.icon {
	/*when choosing the name try as exact element name  //*/
	display: inline-block;
	&.icon__md {
		margin: 0 8px 0 0;
		font-size: 24px;
		&--line-height-h2 {
			line-height: 1.8;
			@media @media-mobile {
				line-height: 1.5;
			}
		}
	}
	&::before {
		font-size: 15px;
		color: @color-gray01;
		margin-right: 5px;
	}
	//
	&--check {
		&::before {
			content: "\f00c";
			font-family: "FontAwesome";
		}
	}
	//
	&--minus {
		&::before {
			content: "\f068";
			font-family: "FontAwesome";
		}
	}
	&--zoom-plus {
		&::before {
			content: "\f00e";
			font-family: "FontAwesome";
		}
	}
	&--del {
		&::before {
			content: "\f00d";
			font-family: "FontAwesome";
		}
	}
	&--dispatcher {
		&::before {
			content: "\f00d";
			font-family: "FontAwesome";
		}
	}
	&--plus {
		&::before {
			content: "\f067";
			font-family: FontAwesome;
		}
	}
	//
	&--trash {
		content: "\f1f8";
		font-family: FontAwesome;
	}
	&--edit {
		&::before {
			content: "\f040";
			font-family: FontAwesome;
		}
	}
	&--exclamation {
		&::before {
			content: "\f06a";
			font-family: FontAwesome;
		}
	}
	&--md {
		font-size: 15px;
		margin-right: 5px;
	}
	&--sm {

	}
	&--lg {

	}
	&__color {
		&--blue {
			&::before {
				color: @color-blue02;
			}
		}
		&--gray {
			&::before {
				color: @color-gray06;
			}
		}
		&--gray05 {
			&::before {
				color: @color-gray05;
			}
		}
		&--red {
			&::before {
				color: @color-red01;
			}
		}
		&--ok {
			&::before {
				color: @color-blue02;
			}
		}
		&--annul {
			&::before {
				color: @color-alert08;
			}
		}
	}
	&__size {
		&--md {
			&::before {
				font-size: 20px;
				margin-right: 10px;
			}
		}
		&--sm {
			&::before {
				font-size: 14px;
				margin-right: 5px;
			}
		}
	}
}
.contract-icon {
	position: relative;
	.span01 {
		display: inline-block; text-align: center; .w50;
		position: absolute;
		top: 0;
		left: 0;
		margin: auto;
		.box-sizing(border-box);
		&::before {
			font-size: large;
		}
	}
	.span02 {
		display: inline-block;
		padding-left: 50px;
	}
}
.ico01:before,
.ico02:before { display: none; }
TD.ico:before { display: none; }
SPAN.ico-flag {
	display: inline-block; text-indent: -10000px; width: 16px; height: 13px; vertical-align: -15%;
	&.ico-et { background: url(old/flags/ee.gif) no-repeat 50% 50%; }
	&.ico-fi { background: url(old/flags/fi.gif) no-repeat 50% 50%; }
	&.ico-lv { background: url(old/flags/lv.gif) no-repeat 50% 50%; }
	&.ico-lt { background: url(old/flags/lt.gif) no-repeat 50% 50%; }
	&.ico-ru { background: url(old/flags/ru.gif) no-repeat 50% 50%; }
	&.ico-se { background: url(old/flags/se.gif) no-repeat 50% 50%; }
	&.ico-no { background: url(old/flags/no.gif) no-repeat 50% 50%; }
	&.ico-dk { background: url(old/flags/dk.gif) no-repeat 50% 50%; }
}
.icon-color {
	font-family: @font-main;
	&:before {
		content: ""; width: 18px; height: 18px; background: no-repeat 50% 50%; background-size: contain; margin: 0 5px 0 0; vertical-align: middle;
	}
	&-mail {
		&:before { background-image: url(emuusika/mail.png); }
	}
	&-gmail {
		&:before { background-image: url(emuusika/google.png); }
	}
	&-facebook {
		&:before { background-image: url(emuusika/facebook.png); }
	}
}
.icon-numb {
	display: inline-block; font-family: @font-main; position: relative; left: 0; top: 0; font-size: 15px; width: 28px; height: 28px; .rounded(50%); border: none; .box-sizing(border-box); text-align: center; line-height: 28px; margin-right: 10px; background: @color01; color: @color-white;
}
.back-to {
	.rounded(50%);
	border: none;
	.zindex(@backtoZindex,0);
	position: fixed;
	bottom: 50px;
	right: 50px;
	/*margin: auto 25px auto auto;*/
	display: block;
	width: 60px;
	height: 60px;
	background: @color-gray-medium-light;
	opacity: 0.8;
	&:hover {
		background: @color-gray-medium;
		text-decoration: none;
		opacity: 1;
		text-decoration: none;
		&::before {
			text-decoration: none;
		}
	}
	&:before {
		font-family: "icons";
		.box-sizing(border-box);
		left: 0;
		right: 0;
		text-align: center;
		margin: auto;
		top: 0;
		line-height: 60px;
		font-size: 22px;
		color: @color-white;
		display: block;
	}
	&.up:before {
		content: @ico-arrup;
	}
	&.down:before {
		content: @ico-arrdown;

	}
}
.icon-square {
	&:before {
		float: left; content: ""; width: 14px; height: 14px; margin: 0 5px 0 0;
		/*.box-sizing(border-box); position: relative; top: .1em; overflow: hidden; float: none;*/
	}
	&-color01 {
		&:before { background: @color01; }
	}
	&-color03 {
		&:before { background: @color03; }
	}
}

.status-list {
	padding-bottom: 1em;
	position: relative;
	SPAN.txt {
		.display(flex);
		padding-left: 32px;
		SPAN.note {
			color: @color-gray-medium;
		}
		.span01 {
			.flex(1 1 55%);
		}
		.span02 {
			.display(flex);
			.justify-content(flex-end);
			.flex(1 0 auto);
		}
	}
	SPAN.txt02 {
		display: block;
		padding-left: 32px;
		SPAN.note {
			color: @color-gray-medium;
		}
	}
	.status-logo {
		position: absolute;
		left:0;
		top: 0;
	}
	.status-ico {
		position: absolute;
	}

}
.status-logo {
	display: inline-block;
	position: relative;
	width: 32px;
	text-align: left;
	IMG {
		width: 80%;
	}
}
.status-ico {
	display: inline-block;
	position: relative;
	width: 32px;
	text-align: left;
	height: 16px;
	margin-right: 8px;
	.status-icon {
		display: inline-block;
		position: relative;
		top: 4px;
	}
}

.ac01 {
	 margin: 0;
	 padding: 0;
	 position: relative;
	 max-width: 285px;
	 width: 285px;
	&.w100p {
		max-width: 100%;
		width: 100%;
	}
	&--w252 {
		width: 252px;
		@media @media-mobile {
			width: 100%;
			max-width: 100%;
		}
	}
	 &:before {
		 display: none;
	 }
	 INPUT {
		 width: 100%;
		 &.select {
			 /*padding-right: 35px;*/
			 padding-right: 45px;
			 font-style: italic;
		 }
	 }
	 SPAN.select-pointer {
			&:after {
				content: "";
				float: right;
				/*width: 35px;
				height: 35px;*/
				width: 44px;
				height: 35px;
				right: 0;
				top: 0;
				background: transparent;
				position: absolute;
				cursor: pointer;
				font-weight: normal;
				border-left: 1px solid @color-gray-lightest;

			}
	 }
	 UL {
		 display: block;
		 position: absolute;
		 background: @color-white;
		 border: 1px solid @color-gray-lightest;
		 padding: 8px 0;
		 left: 0;
		 right: 0;
		 top: 34px;
		 width: auto;
		 .zindex(@acZindex,0);
		 margin: 0;
		 max-height: 215px;
		 overflow: auto;
		 & > LI {
			 &:first-child {
				 margin-top: 0;
			 }
			 &.active {
				 A {
					 border-left: 2px solid @color02;
					 A { SPAN { color: @color-black; } }
					 color: @color-black;
					 padding: 5px 23px;
					 text-decoration: none;
				 }
			 }
			 border: 0;
			 padding: 0;
			 /*margin: 0;*/
			 float: none;
			 &:before { display: none; }
			 background: none;
			 min-height: 100%;
			 z-index: 9;
			 //border-left: 2px solid transparent;
			 margin: 5px 0;
			 P.heading01 {
				 padding: 0 15px;
				 margin: 0;
				 color: @color02;
				 text-transform: uppercase;
			 }
			 P.heading02 {
				 padding: 0 15px;
				 margin: 0;
				 color: @color-gray-medium;
			 }
		 }
		 A {
			 display: block;
			 overflow: hidden;
			 padding: 5px 25px;
			 color: @color-gray-medium;
			 cursor: pointer;
			 &:hover {
				 border-left: 2px solid @color02;
				 A { SPAN { color: @color-black; } }
				 color: @color-black;
				 padding: 5px 23px;
				 text-decoration: none;
			 }
		 }
	 }
	.ac01__dropdown {
		&.ac01__dropdown--md {
			top: 50px;
		}
	}
	 .ac01b {
		 width:100%;
		 &.open {
			 & > UL {
				 display: block!important;
			 }
			 A {
				 SPAN.a {
					 display: none!important;
				 }
				 SPAN.b {
					 display: block!important;
				 }
			 }
		 }
		 & > UL {
			 background: lighten(@color-gray-lightest,5%);
			 border: 1px solid @color-gray-lightest;
			 padding: 0;
			 position: relative;
			 top: 0;
			 LI {
				 border-bottom: 1px solid @color-gray-lightest;
				 &:last-child {
					 border-bottom: none;
				 }
				 A {

					border: 2px solid transparent;
					padding: 5px 13px;
					&:hover {
						border: 2px solid @color10;
						background: @color-white;
					}
					SPAN {
						display: block;
					}
				}
			}
		}
		& > A {
			z-index: 9;
			cursor: pointer;
			padding: 5px 13px;
			display: block;
			SPAN {
				cursor: pointer;
			}
		}
	}
	&.ac01d {
		position: relative;
		SPAN.select-pointer {
			&:after {
				border: none;
			}
		}
		UL {
			& > LI {
				.status-logo {
					display: inline-block;
					position: absolute;
					right: 6px;
					top: 6px;
					width: 32px;
					text-align: center;
				}
				A {
					padding: 5px 42px 5px 15px;
					&:hover {
						padding: 5px 40px 5px 13px;
					}
				}
				&.active {
					A {
						padding: 5px 40px 5px 13px;
					}
				}
			}
		}

	}
	&.lang {
		color: @color16;
		max-width: 75px;
		width: 75px;
		text-align: center;
		padding: 10px 0 10px 0;
		border-right: 1px solid @color-gray-medium-light;
		UL {
			padding: 0;
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
			-moz-box-shadow:    0 1px 3px 0 rgba(0, 0, 0, 0.4);
			-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
			& > LI {
				margin: 0;
				&.active {
					A {
						padding-left: 10px;
						border-left: 2px solid @color03;
						&:hover {
							padding-left: 10px;
							border-left: 2px solid @color03;
						}
						SPAN { color: @color16; font-weight: bold; }
						color: @color16;

					}
				}
				A {
					padding-left: 10px;
					SPAN { color: @color16; }
					color: @color16;
					border-left: 2px solid transparent;
					&:hover {
						padding-left: 8px;
						border-left: 2px solid transparent;
						background: @color21;
						SPAN { color: @color16; }
						color: @color16;
					}
				}
			}
		}
		.dropdown {
			position: relative;
			border: 0;
			padding: 0 23px;
			display: block;
			overflow: hidden;
			padding-left: 10px;
			border-left: 2px solid transparent;
			color: @color16;
			font-size: 15px;
			&:hover {
				text-decoration: none;
			}
			&::before {
				position: absolute;
				right: 15px;
				top: 6px;
				font-size: 10px;
				color: @color16;
			}
		}
		.log {
			position: absolute;
			right: -42px;
			top: 10px;
		}

	}
}
.ac03 {
	list-style: none;
	margin: 0;
	padding: 0;
	.display(flex);
	flex-wrap: wrap;
	margin: 0 -0.8% 0 -0.3%;
	.hasico:before { top: .3em; }
	SPAN {
		background: none;
		&.text { display: inline-block; padding: 10px 0 9px 10px; font-size: 15px; }
	}
	LI {
		min-height: 105px;
		position: relative;
		z-index: 1;
		.flex(0 0 16%);
		width: 16%;
		margin: 10px 0.3% !important;

		&, DIV.block01 & {
			float: left; margin: 5px 0 0 10px; padding: 0 !important;  // Todo: siin oli enne margin: 18px 0 0 10px, mis kohti see võis mõjutada
			&:first-child { margin: 5px 0 0 0; }
			&.f-right { float: right; }
		}
		&:first-child,
		&.c-both {
			SPAN.text { padding-left: 0; }
			A, SPAN, INPUT {
				&.btn.discrete { margin-left: 0; }
			}
		}
		&:last-child {
			SPAN.text { padding-right: 0; }
			A, SPAN, INPUT {
				&.btn.discrete { margin-right: 0; }
			}
		}
		&:before { display: none; }

		SPAN.hasico.ico-plus,
		SPAN.hasico.ico-cross,
		SPAN.hasico.ico-minus,
		SPAN.hasico.ico-pencil {
			float: right;
			font-size: 15px;
			margin: -0em 0 0 0;
			line-height: 1;
			color: @color01;
			font-weight: normal;
			&:before {
				font-size: 10px;
				width: auto;
				height: auto;
				margin-right: 10px;
				position: relative;
				top: .3em;
			}
		}
		A.btn {
			SPAN.hasico.ico-plus,
			SPAN.hasico.ico-cross,
			SPAN.hasico.ico-minus {
				float: right;
				font-size: 15px;
				margin: -0em 0 0 0;
				line-height: 1;
				color: @color01;
				font-weight: normal;
				&:before {
					font-size: 10px;
					width: auto;
					height: auto;
					margin-right: 10px;
					position: relative;
					top: .3em;
				}
			}
			&.selected SPAN.hasico.ico-plus,
			&.selected SPAN.hasico.ico-cross,
			&.selected SPAN.hasico.ico-minus {
				color: @color01;
			}
		}
		A.btn,
		SPAN.btn {
			background: lighten(@color-gray-lightest, 5%);
			display: block;
			float: none;
			text-align: left;
			margin: 0 20px 0 0;
			padding: 15px;
			.box-sizing(border-box);
			min-height: 100%;
			height: auto;
			position: relative;
			width: 100%;
			.box-sizing(border-box);
			border: 1px solid @color-gray-medium-lightest;
			&:hover {
				border-color: @color12;
			}
			&.disabled:hover {
				border-color: @color-gray-medium-lightest;
				cursor: pointer;
			}
			&.selected A {
				color: @color01;
			}
			&.static:hover {
				border-color: @color-gray-light;
			}
			&.selected02 {
				SPAN {
					.ico-user {
						color: @color01;
						background: @color-white;
						border-color: @color-white;
					}
					&.ico-plus {
						display: none;
						font-weight: bold;
					}
					&.ico-cross,
					&.ico-minus {
						display: block;
					}

				}
			}
			SPAN {
				&.pad {
					padding-bottom: 20px;
					SPAN {
						margin-top: 5px;
						display: block;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						&.small {
							white-space: normal;
						}
					}
				}
				&.hasico:before {
					font-weight: normal;
					height: auto;
					width: auto;
					margin-top: -2px;
				}
				line-height: 1.2;
				display: block;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				&.small {
					display: block;
					color: @color-gray-medium;
					font-size: 15px;
					line-height: 17px;
					margin: .2em 0 0 0;
					font-weight: normal;
					SPAN.price {
						float: right;
						font-weight: bold;
						margin-top: 0;
					}
				}
				&.ico-cross,
				&.ico-minus {
					display: none;
				}
				&.hasico {
					float: left;
					position: absolute;
					bottom: 10px;
					left: 17px;
					&.ico-pencil,
					&.ico-plus,
					&.ico-cross,
					&.ico-minus {
						&:before {
							font-size: 12px;
							margin-right: 5px;
							font-weight: bold;
						}
					}
					&:before {
						font-size: 10px;
						width: auto;
						height: auto;
						margin-right: 10px;
						position: relative;
						top: .3em;
					}
					&.ico-alert:before,
					&.ico-alert02:before {
						position: absolute;
						left: 3px;
						top: 3px;
						width: 20px !important;
						height: 20px !important;
						background: @color02;
						border-radius: 50%;
						content: @ico-exclamation;
						color: @color-white;
						line-height: 20px;
						font-weight: bold;
						font-size: 12px;
						text-align: center;
					}
					&.ico-cart {
						&.ico-alert,
						&.ico-alert02 {
							&:before {
								display: block;
							}
						}

						&:before {
							display: none;
						}
						&:after {
							content: @ico-cart;
							color: @color02;
							font-family: "icons";
							position: absolute;
							left: 45px;
							top: 35px;
							font-size: 20px;
						}
					}
				}
			}
			&.selected SPAN.small {
				color: fade(@color-black, 50%);
			}

			&.selected {
				SPAN {
					&.pad {
						SPAN.small {
							white-space: normal;
						}
					}
				}
			}

			&.disabled {
				border: 1px solid #d9dadb;
				background: #fff;
			}
			&.confirmed {
				background: @color-gray-medium-light;
				border-color: @color-gray-medium-light;
			}
		}
	}
	@media @media-tablet {

	}
	@media @media-mobile {
		max-height: none;
		overflow: visible;
		margin: .5em 0 0 !important;
		LI.left {
			text-align: left;
			A {
				margin: 0!important;
			}
		}
		LI {
			height: auto;
			margin: .5em 0 0 0;
			.flex(0 0 auto);
			width: 100%;
			A.btn,
			SPAN.btn {
				height: auto;
				padding: 10px 10px;
				margin-right: 0;
				SPAN:first-child:last-child {
					padding-bottom: 5px;
				}
				&.selected {
					SPAN {
						&.pad {
							padding-bottom: 18px !important;
							SPAN.small {
								/*margin: .2em 0 0 0;*/
							}
						}
					}
				}
				SPAN {
					&.pad {
						padding-bottom: 18px !important;
						SPAN {
							&.small {
								/*margin: .2em 0 0 0;*/
							}
						}
					}
				}
			}
		}
	}
}
.theselect {
	.box-sizing(border-box);
	margin: 0;
	position: relative;
	text-align: left;
	.link-outline-none;
	border: none;
	color: @color-gray05;
	max-width: 100%;
	width: 100%;
	padding: 0;
	/*z-index: 9;*/
	height: 50px;
	&.w100p {
		width: 100%;
		max-width: 100%;
	}
	&.w90 {
		width: 90px;
		@media @media-mobile {
			width: 90px;
		}
	}
	&:before {
		display: none;
	}
	&:hover {
		border-color: @color19;
	}
	&.active {
		border-color: @color19;
	}
	&.theselect-block {
		padding: 0;
		width: 100%;
		border: 1px solid @color-gray06;
		border-style: none none solid none;
		height: auto;
		min-height: 100%;
		/*theselect__block--title*/
		> A.dropdown {
			text-decoration: none;
			/*letter-spacing: -0.4px;*/
			font-size: 15px;
			font-family: @font-main;
			padding: 0 30px 0 0;
			font-weight: 400;
			color: @color-gray01;
			margin: 15px 0;
			border: none;
			&::before {
				top: 3px;
			}
		}
		.theselect__item {
			margin-left: 20px;
			.type-search-address__switch-options {
				border-bottom: none;
			}
			@media @media-notdesktop {
				margin-left: 0;
			}
		}
	}
	&.theselect__dispatcher {
		width: 75px;
		A.dropdown {
			border: 1px solid @color-orange02;
			background: @color-orange02;
			padding: 5px 30px 5px 16px;
			text-align: center;
			&::before {
				top: 8px;
			}
		}
		.theselect__icon {
			vertical-align: bottom;
			text-align: center;
			font-size: 18px;
			color: @color-black;
		}
		UL {
			top: 42px;
			box-shadow: none;
			border: 1px solid @color-orange02;
			width: 225px;
			left: inherit;
			& > LI  {
				A {
					color: @color-blue01;
					padding: 5px 16px;
					&:focus,
					&:hover {
						padding: 5px 16px;
						color: @color-blue06;
						background: none;
					}
				}
			}
		}
	}
	.fieldset-wrapper, .form-checkboxes, .field-input-wrapper {
		display: inline-block;
		position: relative;
		width: 100%;

		span, div {
			display: block;
		}

		label {
			display: inline;
			float: none;
			width: auto;
		}

		.field-input-wrapper {
			width: auto;
		}
	}
	// radio button, checkbox
	input[type=checkbox] {
		&.form-checkbox {
			display: none;
		}

		+ label {
			font-weight: 400;
			line-height: 20px;
			display: inline-block;
			margin-left: 35px;
			cursor: pointer;
			width: auto;
			max-width: none;
		}

		+ label:before {
			content: "\f00c";
			color: transparent;
			border: 1px solid @color-gray05;
			font-family: FontAwesome;
			padding: 2px 5px;
			position: absolute;
			left: 0;
			top: 10px;
		}
		&:checked + label:before {
			color: @color01;
			border: 1px solid @color03;
		}
	}

	.form-checkboxes {
		input[type=checkbox] + label:before {
			//top: -4px;
		}
	}

	input[type=radio] {
		&.form-radio {
			display: none;
		}

		+ label {
			font-weight: 400;
			cursor: pointer;
			line-height: 20px;
			display: inline-block;
			margin-left: 35px;
			width: auto;
			max-width: none;
		}

		+ label:before {
			border: 1px solid @color-gray05;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			content: "\f111";
			color: transparent;
			font-family: FontAwesome;
			padding: 2px 6px;
			position: absolute;
			left: 0;
			top: 10px;
		}

		&:checked + label:before {
			border: 1px solid @color03;
			content: "\f111";
			color: @color01;
		}
	}
	.status {
		display: block;
		position: absolute;
		right: 30px;
		top: 42%;
		width: 10px;
		height: 10px;
		.rounded(50%);
		background: @color-gray-light;
		color: @color-white;
		text-align: center;
		&.status-ok {
			background: @color27;
		}
		&.status-error {
			background: @color26;
		}
	}
	.arrowdown {
		display: block;
		position: absolute;
		right: 12px;
		top: 42%;
		width: 10px;
		height: 10px;
		color: @color-white;
		text-align: center;
	}
	&.theselect-checkbox {
		UL {
			padding: 5px 0;
			& > LI {
				padding: 5px 53px 5px 16px;
				cursor: pointer;
				margin: 0;
				color: @color-gray01;
				border-left: 2px solid transparent;
				&:first-child {
					margin-top: 0;
				}
				&:before {
					display: none;
				}
				&:hover {
					padding: 5px 53px 5px 16px;
					background: @color21;
					SPAN {
						color: @color-gray01;
					}
					color: @color-gray01;
					text-decoration: none;
					border-left: 2px solid transparent;
				}
				input[type=checkbox] {
					+ label {
						font-size: 15px;
						margin: 5px 0 5px 35px;
					}
					+ label:before {
						top: 2px;
					}
				}
				&.active {
					color: @color-gray01;
					font-weight: bold;
					padding: 5px 51px 5px 16px;
					text-decoration: none;
					border-left: 2px solid @color03;
					line-height: inherit;
					margin: 2px 0;
					input[type=checkbox] {
						+ label {
							font-weight: 700;
						}
					}
					&:hover {
						padding: 5px 53px 5px 16px;
						border-left: 2px solid @color03;
					}
				}
				A {
					padding: 0;
					border: 0;
					&:hover {
						padding: 0;
						border: 0;
					}
				}
			}
		}
	}
	A {
		position: relative;
		text-decoration: none;
		:focus {
			text-decoration: none;
		}
	}
	&.w100p {
		width: 100%;
		max-width: 100%;
	}
	UL {
		display: block;
		position: absolute;
		background: @color-white;
		left: 0;
		right: 0;
		top: 50px;
		width: auto;
		.zindex(@theselectZindex,1);
		margin: 0;
		max-height: 204px;
		overflow: auto;
		list-style-type: none;
		padding: 0;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
		-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
		& > LI {
			margin: 0;
			&:first-child {
				margin-top: 0;
			}
			&:before {
				display: none;
			}
			A {
				font-size: 15px;
				display: block;
				overflow: hidden;
				padding: 5px 20px 5px 16px;
				cursor: pointer;
				SPAN {
					color: @color-gray01;
				}
				color: @color-gray01;
				border-left: 2px solid transparent;
				&:hover {
					padding: 5px 20px 5px 16px;
					border-left: 2px solid transparent;
					background: @color21;
					SPAN {
						color: @color-gray01;
					}
					color: @color-gray01;
					text-decoration: none;
				}
			}
			&.active {
				A {
					border-left: 2px solid @color02;
					padding: 5px 20px 5px 16px;
					text-decoration: none;
					border-left: 2px solid @color03;
					line-height: inherit;
					&:hover {
						padding: 5px 22px 5px 16px;
						border-left: 2px solid @color03;
					}
					SPAN {
						color: @color-gray01;
						font-weight: bold;
					}
					color: @color-gray01;
				}
			}
			border: 0;
			padding: 0;
			float: none;
			background: none;
			min-height: 100%;
			.zindex(@theselectZindex,0);
			P.heading01 {
				padding: 0 15px;
				margin: 0;
				color: @color02;
				text-transform: uppercase;
			}
			P.heading02 {
				padding: 0 15px;
				margin: 0;
				color: @color-gray-medium;
			}
		}

	}
	.dropdown {
		position: relative;
		background: @color-white;
		padding: 12px 30px 12px 16px;
		display: block;
		overflow: hidden;
		border: 1px solid @color-gray05;
		color: @color-gray01;
		cursor: pointer;
		font-family: @font-main;
		font-size: 15px;
		&.active {
			border-color: @color19;
		}
		&:hover {
			text-decoration: none;
		}
		&::before {
			position: absolute;
			right: 8px;
			top: 15px;
			line-height: 20px;
			font-size: 20px;
			color: @color16;
			content: "\f107";
			font-family: FontAwesome;
			margin-left: 5px;
			display: inline-block;
			font-style: normal;
			font-weight: normal;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
		&.open {
			&::before {
				content: "\f106";
			}
			& + UL {
				opacity: 1;
			}
		}
	}
	.log {
		position: absolute;
		right: -42px;
		top: 10px;
	}
	&.theselect--sm {
		height: 40px;
		> A.dropdown {
			font-size: 14px;
			padding: 10px 29px 7px 16px;
			&::before {
				top: 11px;
			}
		}
		UL {
			top: 41px;
		}
	}
	&.theselect--md {
		> A.dropdown {
			font-size: 15px;
			&::before {
				top: 15px;
			}

		}
		UL {
			top: 51px;
		}
	}
	&.theselect__lg {

	}
	&.theselect--dark {

	}
	&.lang {
		max-width: 75px;
		width: 75px;
		border-style: none;
		text-align: center;
		float: left;
		height: auto;
		padding: 10px 0 10px 0;
		UL {
			top: 34px;
			& > LI {
				&.active {
					A {
						padding: 5px 27px 5px 10px;
						&:hover {
							padding: 5px 27px 5px 10px;
						}
						SPAN {
						}
					}
				}
				A {
					padding: 5px 25px 5px 10px;
					&:hover {
						padding: 5px 25px 5px 10px;
						SPAN {
						}
					}
				}
			}
		}
		.dropdown {
			padding: 0 23px 0 10px;
			border: none;
			&:hover {
				border: none;
			}
			&::before {
				right: 7px;
				line-height: 18px;
				top: 0;
				font-size: 18px;
			}
		}
		.log {

		}

	}
	&.theselect--error {
		> A, A.dropdown {
			border: 1px solid @color-alert04;
			background: @color-alert05;
			&:hover {
				border: 1px solid @color-alert04;
				background: @color-alert05;
			}
		}
	}
}
.circle-numbers {
	position: relative;
	background: #0019af;
	color: white;
	font-size: 10px;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	text-align: center;
	line-height: 14px;
	top: -4px;
	vertical-align: top;
	right: 0;
	display: inline-block;
	&--orange {
		background: @color02;
	}
}

@media @media-mobile {
	float: none;
	padding: 0;
	margin-right: 0;
	width: 100%;
	&.f-left {
		float: left !important;
	}

	INPUT,
	SELECT {
		float: none;
		width: 100%;
	}

	HEADER.sub & {
		width: 100%;
		min-width: 0;
	}
}

.table-wrapper {
	max-width: 100%;
	height: auto;
	overflow-x: auto;
	@media @media-mobile {
		overflow: auto; width: 100%;
		TABLE { table-layout: auto; }
		TD,
		TH { min-width: 150px; word-wrap: normal; word-break: normal; }
	}
}
.type__border {
	border: 1px solid @color-gray05;
}

.status-text {
	display: block;
	padding-left: 30px;
	position: relative;
	&:before {
		font-family: "icons";
		position: absolute;
		left: 0;
		top: 0;
		width: 22px;
		height: 22px;
		font-size: 12px;
		.rounded(50%);
		background: @color-gray-light;
		color: @color-white;
		content: "";
		text-align: center;
		line-height: 22px;
	}
	&.status-text--md {
		padding-left: 40px;
		&:before {
			width: 30px; height: 30px; line-height: 30px; font-size: 18px;
		}
	}
	&.status-text--sm {
		padding-left: 25px;
		&:before {
			width: 18px; height: 18px; line-height: 18px; font-size: 10px;
		}
	}
	&.staus--extra {
		color: @color-alert09;
	}
	&.status-text__info-alert {
		&:before { font-family: FontAwesome; background: @color-msg-negative; content: "\f00d"; color: @color-msg-negative-text; }
	}
	&.status-text__warning {
		&:before { font-family: FontAwesome; background: @color-msg-warn; content: "\f12a"; color: @color-msg-warn-text; }
	}
	&.status-text__ok {
		&:before { font-family: FontAwesome; background: @color-msg-positive; content: "\f00c"; color: @color-msg-positive-text; }
	}
	&.status-text__info {
		&:before { font-family: FontAwesome; background: @color-msg-info; content: "\f129"; color: @color-msg-info-text; }
	}
	&.status-text__muted {
		&:before { font-family: FontAwesome; background: @color-msg-muted; color: @color-msg-muted-text; }
	}
	&.status-text__note  {
		color: @color-gray03;
		font-family: @font-main;
		font-size: 13px;
		font-weight: 400;
		display: block;
	}
}
