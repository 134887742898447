.type-personal-data {
	.display(flex);
	width: 100%;
	.flex-wrap(wrap);
	.box-sizing(border-box);
	margin: 28px 0 0 0;
	padding: 0 0 18px 0;
	&__item {

	}
	&__label {
		display: inline-block;
		padding: 0 0 0 5px;
	}
	&__value {
		display: inline-block;
	}
	&__text {
		margin: 0;
	}
	&__title {
		margin: 0;
	}
	&__pad {

	}
	&__separator {

	}
	@media @media-mobile {
		padding: 0 0 32px 0;
		.flex-direction(column);
		&__separator {
			display: none;
		}
		&__label {
			padding: 0;
		}
	}
}