// General grid units

@gridunit: 10px;
@base-margin: 1em 0 0 0;
@base-padding: 0 @gridunit @gridunit @gridunit;
@base-radius: 5px;

// Responsive sets

@media-tiny:   ~"only screen and (max-width: 239px)";
@media-notmobile:   ~"only screen and (min-width: 768px)";
@media-wide:   ~"only screen and (min-width: 1280px)";
@media-hd:   ~"only screen and (min-width: 1400px)";
@media-desktop:   ~"only screen and (min-width: 1024px)";
@media-tablet:    ~"only screen and (min-width: 768px) and (max-width: 1023px)";
@media-tablet-narrow:    ~"only screen and (min-width: 768px) and (max-width: 800px)";
@media-mobile:    ~"only screen and (max-width: 767px)";
@media-notdesktop:    ~"only screen and (min-width: 240px) and (max-width: 1023px)";
@media-notwide:    ~"only screen and (min-width: 1024px) and (max-width: 1279px)";
@media-mobile-narrow: ~"only screen and (min-width: 240px) and (max-width: 360px)";
//@media-notwide:    ~"only screen and (min-width: 760px) and (max-width: 1199px)";

//@media-notwide:    ~"only screen and (max-width: 1079px)";

@media-notdesktop-narrow: ~"only screen and (max-width: 800px)";

// Colors

@color-main: @color-white;
@color-text-main: @color-black;

/*new styles*/
@color-black-light: #444444;
@color-black-medium: #273645;
@color-black-dark: #333333;


@color-main-inverted: @color01;
@color-text-inverted: @color-white;

@color-main-inverted: @color01;
@color-text-inverted: @color-white;


// Gray tones (with slight blue tint)
@color-gray01: #273645;
@color-gray02: #304254;
@color-gray03: #5f6d7a;
@color-gray04: #949da6;
@color-gray05: #c9cad1;
@color-gray06: #d7d9e0;
@color-gray07: #f0f1f5;
@color-gray08: #444444;
@color-gray09: #616d79;
@color-gray10: #e6e7ed;
@color-gray11: #dadada;
@color-gray12: #394754;
@color-gray13: #d9dadb;
@color-gray14: #606d7a;


@color-blue01: #0019af;
@color-blue02: #00baf2;
@color-blue03: #aae0fa;
@color-blue04: #0093c0;
@color-blue05: #00137D;
@color-blue06: #00127d;
/*@color-blue07: ;*/
@color-blue08: #0c42c8;

@color-green01: #22bd51;
@color-green02: #00df4a;
@color-green03: #1c803a;
@color-green04: #95edab;

@color-red01: #d0011b;
@color-red02: #ee4444;
@color-red03: #fde8e8;

@color-yellow01: #ffbf06;

@color-pink01: #ff6db6;


@color-orange01: #ff6400;
@color-orange02: #fcaf17;
@color-orange03: #fad791;

//Pure black & white
@color-black: #000000;
@color-white: #ffffff;


@color-alert01: #ffc4d4;
@color-alert02: #dd3168;
@color-alert03: #fff0f7;
@color-alert04: #ee4444;
@color-alert05: #fde8e8;
@color-alert06: #ffe39a;
@color-alert07: #e47900;
@color-alert08: #ae4907;
@color-alert09: #b35f00;
@color-alert10: #ffb088; //

/*remove after ttl*/
@color-msg01: #aae0fa;

/*remove*/
@color01: #0019af;
@color02: #ff6400;
@color03: #00baf2;
@color04: #f29318;
@color04-hover: #ffbb44;
@color05: #72D174;
@color06: #91278f;
@color07: #3c639a;
@color08: #6d7172;
@color09: #d3effc;
@color10: #7cdcf8;
@color11: #e9f6fd;
@color12: #b2eafb;
@color13: #fcaf17;
@color14: #0090f1;
@color15: #a4a4a4;
@color16: #273645;
@color17: #304254;
@color18: #d7d9e0;
@color19: #5f6d7a;
@color20: #0093c0;
@color21: #f0f1f5;
/*menus prepaid*/
@color22: #00137D;
/*primary button hover prepaid*/
@color23: #00127d;


@color24: #e6e7ed;
@color25: #f0f1f5;
@color26: #d0011b;
@color27: #00df4a;
@color28: #273645;
@color29: #949da6;
@color30: #0093bf;
/*primary and secondary link hover*/
@color31: #00137d;
/*secondary link inverse*/
@color31: #009dcc;


@color-blue-medium: #00baf2;
@color-blue-dark: #0019af;
@color-blue-light: #aae0fa;
@color-orange: #ff6400;
@color-dark: #000000;
@color-light: #ffffff;
@color-gray-light: #e1e1eb;
@color-gray-lightest: #ebebeb;
@color-gray-medium-lightest: #d9dadb;
/*@color-gray-medium-light: #ccc;*/
@color-gray-medium-light: #c9cad1;
@color-gray-medium: #727272;
@color-gray-dark: #575757;
@color-gray-darkest: #202020;
@color-black: #1a1a1a;
@color-positive: @color-msg-positive;
@color-negative: #E44;
@color-warn: @color04;
/*remove end*/



@color-msg-info: #aae0fa;
@color-msg-positive: #95edab;
@color-msg-warn: #ffe39a;
@color-msg-negative: #ffb088;
@color-msg-muted: #ebebeb;

@color-msg-info-text: #0019af;
@color-msg-positive-text: #1c803a;
@color-msg-warn-text: #e47900;
@color-msg-negative-text: #ae4907;
@color-msg-muted-text: #727272;


@color-status01: #fff2b3;
@color-status01-border: #fee7b9;
@color-status02: #e3f2e7;
@color-status02-border: #cbebd7;

@color-swed: #fd8609;
@color-seb: #55c814;
@color-danske: #07395c;
@color-nordea: #0a5498;
@color-krediit: #cb2a46;
@color-lhv: @color-gray-darkest;
// Typography

@font-serif: "Droid Serif", serif;
@font-sans-serif: "Open Sans", Arial, Helvetica, sans-serif;
@font-main: @font-sans-serif;
@font-heading: "Verlag", @font-sans-serif;
@font-heading-ru: "PFBagueSansPro", @font-sans-serif;

@base-font-size-paragraph: 15px;

@base-font-size: 13px;
@base-font-size-mobile: 12px;
@base-line-height: 1.21;
@base-line-height-px: ceil(@base-font-size*@base-line-height);

@maxw: 960px;



