@import (reference) "variables.less";
@import (reference) "mixins.less";
@import (reference) "mixins/anchor.less";

@-ms-viewport { width: device-width; }

HTML { overflow: -moz-scrollbars-vertical; }
BODY { image-rendering: auto; }

@media screen and (-webkit-min-device-pixel-ratio: 1) {
	HTML.framed { overflow-y: scroll; height: 100%; }
	SELECT { padding-right: 30px !important; }
}

BUTTON, INPUT, SELECT, TEXTAREA { .box-sizing(border-box); line-height: normal !important; max-width: 100%; font-size: 100%; margin: 0; vertical-align: middle; }

*:first-child+HTML INPUT[type="checkbox"] { margin: 0; }
*:first-child+HTML INPUT[type="radio"] { margin: 0; }

SELECT { -webkit-appearance: none; -moz-appearance: window; text-indent: 0.01px; text-overflow: ''; background: #fff; }
SELECT::-ms-expand { display: none; }
SELECT:focus { outline: none; border-color: #aaa; }
SELECT:focus OPTION { background: #fff; }

@media (-webkit-min-device-pixel-ratio: 0) { select { padding-right: 30px; text-overflow: ellipsis; overflow: hidden; background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAGCAYAAAAsVdWjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGVJREFUeNpidHV1FWRgYDgDxK5AfI8BOwgF4vJdu3aZMBABmID4PRCvhhqshMPAVUDcyUAkYIHSFVAaZLAJkothBoZBLSbJUGwGG5NjILqh6AYLkmMgNkNhBr+HBgHJBoIAQIABAAMEFxMvb6R3AAAAAElFTkSuQmCC) 100% 50% no-repeat; } }
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { SELECT { padding-right: 30px !important; background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAGCAYAAAAsVdWjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGVJREFUeNpidHV1FWRgYDgDxK5AfI8BOwgF4vJdu3aZMBABmID4PRCvhhqshMPAVUDcyUAkYIHSFVAaZLAJkothBoZBLSbJUGwGG5NjILqh6AYLkmMgNkNhBr+HBgHJBoIAQIABAAMEFxMvb6R3AAAAAElFTkSuQmCC) 100% 50% no-repeat; } }

/*::-moz-selection { background: @color-main; color: @color-text-main; text-shadow: none; }
::selection { background: @color-main; color: @color-text-main; text-shadow: none; }*/