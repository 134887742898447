.tags {
	.display(flex);
	.align-items(center);
	.flex-wrap(wrap);
	margin: 1.5em 0;
	.tags__wrapper {
		.display(flex); .flex-direction(row); .flex(0 1 auto); .flex-wrap(wrap); height: 100%;
	}
	.tags__item {
		border: 1px solid @color03;
		text-decoration: none;
		border-radius: 20px;
		background: @color-white;
		.box-sizing(border-box);
		height: 35px;
		/*cursor: pointer;*/
		margin: 0 9px 1em 0;
		.display(flex);
		.align-items(center);
	}
	.tags__title {
		color: @color-gray03;
		font-size: 14px;
		text-align: left;
		padding: 0 10px 0 20px;
		font-weight: 600;
		font-family: @font-main;
	}
	.tags__color {
		position: relative;
		background: @color-white;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		text-align: center;
		line-height: 18px;
		top: 0;
		left: 15px;
		display: inline-block;
		&--hasmulti {
			position: absolute;
			left: 55%;
			width: 50%;
			height: 125%;
			background-color: rgb(255, 255, 255); /* fallback */
			background-color: rgba(255, 255, 255, 0.5);
			top: 0;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	.tags__close {
		width: 35px;
		height: 33px;
		display: inline-block;
		background: none;
		cursor: pointer;
		text-align: center;
		line-height: 33px;
		text-decoration: none;
		color: @color-gray03;
		border-radius: 50%;
		&:before {
			font-family: FontAwesome;
			content: "\f00d";
			color: @color-gray03;
		}
		&:hover {
			animation:fading .3s forwards;
			@keyframes fading{
				from {
					background: none;
				}
				to {
					background: @color-gray10;
				}
			}
		}
	}
	.tags__clear {
		margin: 0 0 1em 0;
	}
}

