

/*A.btn:not(:last-child) {
	margin-right: 0.5em;
}*/

input.btn--secondary {
	background-color: transparent;
	border: solid 2px @color-blue02;
	color: @color-blue01;
	font-weight: 700;
	padding: 14px 28px;
	transition: background 0.25s;
	&:hover {
		background-color: transparent;
		border-color: @color-gray06;
		color: @color-blue01;
	}
}

A.btn--image {
	background-color: @color-blue02;
	color: @color-white;
	font-weight: 700;
	transition: background 0.25s;
	&:hover {
		background-color: @color-gray06;
	}
}

A.btn--small {
	font-size: 14px;
	padding: 13px 26px;
	&.btn--secondary {
		padding: 11px 24px;
	}
}

A.btn--large {
	font-size: 18px;
	padding: 24px 36px;
	&.btn--secondary {
		padding: 22px 34px;
	}
}

A.btn--center {
	text-align: center;
}
A.btn--fw400 {
	font-weight: 400;
}

A.btn--full-width {
	width: 100%;
}

A.btn__icon {
	&--add {
		&:before {
			content: "\f067";
			font-family: FontAwesome;
			font-size: 14px;
			color: @color-blue02;
			margin-right: 5px;
		}
	}
	&--check {
		&:before {
			content: "\f00c";
			font-family: FontAwesome;
			font-size: 14px;
			color: @color-blue01;
			margin-right: 5px;
		}
	}
	&--right-arrow02 {
		&:after {
			content: "\f061";
			font-family: FontAwesome;
			margin-left: 5px;
			font-size: 14px;
			line-height: 1.05;
			color: @color-blue02;
		}
		&:hover {
			color: @color-blue05;
			&:after {
				color: @color-blue04;
			}
		}
	}
}

.inactive {
	&__icon {
		&--info {
			&:after {
				content: "\f05a";
				font-family: FontAwesome;
				font-size: 18px;
				margin-left: 5px;
			}
		}
	}
}
A.btn,
INPUT.btn,
SPAN.btn {
	/*remove from global about btn when ready?*/
	display: inline-block;
	cursor: pointer;
	/*line-height: 1.2 !important;
	padding: 10px 30px 9px;*/
	/*padding: 16px 30px;*/
	font-size: 15px;
	border: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-decoration: none !important;
	background-image: none;
	font-family: @font-main;
	text-align: center;
	&.btn--image {
		background-color: @color-blue02;
		color: @color-white;
		font-weight: 700;
		transition: background 0.25s;
		&:hover {
			background-color: @color-gray06;
		}
	}
	&--nomargin {
		margin: 0;
	}
	&.btn--white {
		background: @color-white;
	}
	&.btn--small {
		font-size: 14px;
		padding: 13px 26px;
		&.btn--secondary {
			padding: 11px 24px;
		}
	}

	&.btn--large {
		font-size: 18px;
		padding: 24px 36px;
		&.btn--secondary {
			padding: 22px 34px;
		}
	}
	&.btn--gray {
		background-color: @color-gray07;
		border: solid 1px @color-gray05;
		color: @color-blue01;
		font-weight: 700;
		padding: 15px 29px;
		transition: background 0.25s;
		text-align: center;
		/*border: 1px solid @color-gray05;*/
		&:hover {
			background-color: @color-gray06;
			color: @color-blue01;
			text-decoration: none;
		}
		&.btn--disabled {
			/*temporary*/
			/*opacity: 0.5;*/
			background-color: #F9F9FB;
			border-color: #E9EAED;
			&:hover,
			&:focus,
			&:active {
				cursor: default;
				/*temporary*/
				/*opacity: 0.4;*/
				/*background-color: @color-gray07;
				border-color: @color-gray05;*/
				background-color: #F9F9FB;
				border-color: #E9EAED;
				outline: none;
			}
		}
	}
	&.btn--primary {
		background-color: @color-blue01;
		color: @color-white;
		font-weight: 700;
		transition: background 0.25s;
		/*padding: 16px 30px;*/
		padding: 16px 30px 16px;
		line-height: 0.67;
		/*border: solid 2px @color-blue01;*/
		&:hover {
			background-color: @color-blue06;
			color: @color-white;
			/*border: solid 2px @color-blue06;*/
		}
		&.btn--disabled {
			/*opacity: 0.4;*/
			background-color: #99A3DF;
			/*border: solid 2px #99A3DF;*/
			&:hover,
			&:focus,
			&:active {
				cursor: default;
				/*temporary*/
				/*opacity: 0.4;*/
				/*background-color: @color-blue01;*/
				background-color: #99A3DF;
				outline: none;
				/*temporary*/
				/*border: solid 2px @color-blue01;*/
				/*border: solid 2px #99A3DF;*/
			}
		}
	}
	&.btn--annul {
		background-color: transparent;
		color: @color-blue01;
		font-weight: 700;
		padding: 16px 30px 16px;
		border: solid 2px transparent;
		transition: all 0.25s;
		.link-outline-none;
		&:hover {
			background: none;
			color: @color-blue05;
		}
		&.btn--disabled {
			/*temporary*/
			/*opacity: 0.4;*/
			color: #99A3DF;
			&:hover,
			&:focus,
			&:active {
				color: #99A3DF;
				cursor: default;
				/*opacity: 0.4;
				background-color: @color-blue01;*/
				outline: none;
			}
		}
	}
	&.btn__pad {
		&--nps {
			padding: 16px;
		}
	}
	&.btn--secondary {
		background-color: transparent;
		border: solid 2px @color-blue02;
		color: @color-blue01;
		font-weight: 700;
		padding: 14px 28px;
		transition: background 0.25s;
		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
			border-color: @color-blue04;
			color: @color-blue01;
			text-decoration: none;
		}
		&-white {
			background: @color-white;
			&:hover,
			&:focus,
			&:active {
				background: @color-white;
			}
		}
		&.btn--disabled {
			/*temporary*/
			/*opacity: 0.4;*/
			border-color: #99E4FA;
			background-color: @color-white;
			&:hover,
			&:focus,
			&:active {
				cursor: default;
				/*temporary*/
				/*opacity: 0.4;
				border-color: @color-blue02;*/
				border-color: #99E4FA;
				outline: none;
			}
		}
		&-inverse {
			background-color: @color-blue02;
			border: solid 2px @color-blue02;
			color: white;
			font-weight: 700;
			padding: 14px 28px;
			transition: all 0.25s;
			&:hover,
			&:focus,
			&:active {
				background-color: #009dcc;
				border-color: #009dcc;
				color: white;
				text-decoration: none;
			}
			&.btn--disabled {
				/*temporary*/
				/*opacity: 0.4;*/
				background-color: #99E4FA;
				border-color: #99E4FA;
				&:hover,
				&:focus,
				&:active {
					cursor: default;
					/*temporary*/
					/*opacity: 0.4;*/
					background-color: #99E4FA;
					border-color: #99E4FA;
					outline: none;
				}
			}
		}
	}
	&.btn--cancel {
		background-color: transparent;
		color: @color-blue01;
		font-weight: 400;
		border: solid 2px transparent;
		transition: all 0.25s;
		&:hover {
			background-color: transparent;
			border-color: @color-blue01;
			color: @color-blue01;
			text-decoration: none;
		}
	}
	&.btn--fix-sm {
		width: 150px;
		text-align: center;
		/*display: block;*/
		/*padding: 6px 24px;*/
		padding: 10px 30px 9px;
		height: 40px;
		@media @media-mobile {
			width: 100%;
		}
	}
	&.btn--pad-sm {
		padding: 10px 30px 9px;
		height: 40px;
	}
	&.btn--fix-md {
		width: 200px;
		text-align: center;
		/*display: block;*/
		padding: 15px 12px;
		height: 50px;
		@media @media-mobile {
			width: 100%;
		}
	}
	&.btn--pad-md {
		padding: 15px 12px;
		height: 50px;
	}
	&.btn--fix-lg {
		width: 250px;
		text-align: center;
		/*display: block;*/
		padding: 22px 34px;
		height: 65px;
		@media @media-mobile {
			width: 100%;
		}
	}
	&.btn--fix-w255 {
		padding: 22px 32px;
		width: 255px;
		text-align: center;
		display: block;
		margin: 0 auto;
		height: 65px;
	}
	&.btn--two-lines {
		padding: 16px 34px;
	}
	&.btn--fw400 {
		font-weight: 400;
	}
}

A {
	.link-outline-none;
	color: @color-blue01;
	&:hover {
		color: @color-gray02;
	}
	&:focus {
		outline: none;
	}
	/*&[target=_blank],
	&.link--blank-right {
		&:after { content: @ico-blank; font-family: "icons"; margin-left: .5em; text-decoration: none; display: inline-block; }
	}*/
}

A.link--primary {
	.link-outline-none;
	font-weight: 700;
	margin: 0 15px;
	color: @color-blue01;
	.text-decoration-none;
	font-size: 15px;
	&:focus,
	&:hover {
		text-decoration: none;
		color: @color-blue06;
	}
	&.link--disabled {
		&,
		&:hover,
		&:focus,
		&:active {
			color: @color-blue01;
			opacity: 0.4;
			cursor: default;
			text-decoration: none;
		}
	}
}

A.link--secondary {
	.link-outline-none;
	color: @color-gray03;
	font-weight: 700;
	margin: 0 15px;
	font-size: 15px;
	.text-decoration-none;
	&:focus,
	&:hover {
		color: @color-blue05;
		text-decoration: none;
	}
	&.link--disabled {
		&,
		&:hover,
		&:focus,
		&:active {
			color: @color-gray03;
			opacity: 0.4;
			cursor: default;
			text-decoration: none;
		}
	}
}
A.link {
	font-family: @font-main;
	&--disabled {
		&,
		&:hover,
		&:focus,
		&:active {
			color: @color-blue01;
			opacity: 0.4;
			cursor: default;
			text-decoration: none;
		}
	}
	&--arrow-left-right {
		&:before {
			content: "\e925";
			font-family: "elisa-icons";
			margin-right: 5px;
			font-size: 15px;
			line-height: 1.05;
		}
	}
	&--home {
		&:before {
			content: "\f015";
			font-family: FontAwesome;
			margin-right: 5px;
			font-size: 15px;
			line-height: 1.05;
		}
	}
	&--right-arrow {
		&:after {
			content: "\f105";
			font-family: FontAwesome;
			margin-left: 5px;
			font-size: 20px;
			line-height: 1.05;
		}
	}
	&--down-arrow {
		&:before {
			content: "\f107";
			font-family: FontAwesome;
			margin-right: 5px;
			font-size: 20px;
			line-height: 1.05;
			/*kick to global*/
			vertical-align: bottom;
		}
	}
	&--up-arrow {
		&:before {
			content: "\f106";
			font-family: FontAwesome;
			margin-right: 5px;
			font-size: 20px;
			line-height: 1.05;
			/*kick to global*/
			vertical-align: bottom;
		}
	}
	&--mobile-center {
		@media @media-mobile {
			text-align: center;
			display: block;
			margin: 1em 0 0 0;
		}
	}
	&--medium {
		font-weight: 700;
		font-size: 15px;
		line-height: 1.27;
		&:hover {
			text-decoration: none;
		}
	}
	&--left-arrow {
		&:before {
			content: "\f104";
			font-family: FontAwesome;
			margin-right: 5px;
			font-size: 20px;
			line-height: 1.05;
		}
	}
	&--left-add {
		&:before {
			content: "\f067";
			font-family: FontAwesome;
			margin-right: 5px;
			font-size: 14px;
			line-height: 1.05;
			color: @color-blue02;
		}
		&:hover {
			color: @color-blue05;
			&:before {
				color: @color-blue04;
			}
		}
	}
	&--right-arrow02 {
		&:after {
			content: "\f061";
			font-family: FontAwesome;
			margin-left: 5px;
			font-size: 14px;
			line-height: 1.05;
			color: @color-blue02;
		}
		&:hover {
			color: @color-blue05;
			&:after {
				color: @color-blue04;
			}
		}
	}
	&--medium {
		font-weight: 700;
		font-size: 15px;
		line-height: 1.27;
		&:hover {
			text-decoration: none;
		}
	}
	&--remove {
		&:before {
			content: "\f1f8";
			font-family: FontAwesome;
			margin-right: 5px;
			line-height: 1.05;
			font-size: 14px;
		}
	}
	&--remove-x {
		float: left;
		line-height: 1.64;
		&:before {
			float: left;
			content: "\f00d";
			font-family: FontAwesome;
			margin-right: 5px;
			/*line-height: 1.8;
			font-size: 14px;*/
		}
		/*@media @media-mobile {
			&:before {
				font-size: 18px;
			}
		}*/
	}
	&--remove-x-lg {
		float: left;
		line-height: 1.47;
		&:before {
			float: left;
			content: "\f00d";
			font-family: FontAwesome;
			margin-right: 5px;
			line-height: 1.8;
			font-size: 18px;
		}
	}
	&--edit {
		&:before {
			content: "\E069";
			font-family: icons;
			/*content: "\f303";
			font-family: FontAwesome;*/
			margin-right: 5px;
			line-height: 1.05;
			font-size: 14px;
		}
	}
	&--editable {
		font-family: @font-main;

		&:before {
			content: "\f040";
			font-family: FontAwesome;
			line-height: 1.36;
			/*color: @color-gray03;*/
			text-decoration: none;
			font-size: 14px;
			margin-right: 5px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	&--add {
		margin: 0;
		&:before {
			font-weight: 400;
			content: "\f067";
			font-family: FontAwesome;
			margin-right: 5px;
			font-size: 14px;
			line-height: 1.05;
		}
	}
	&--back {
		margin: 0;
		&:before {
			content: "\f104";
			font-family: FontAwesome;
			margin-right: 5px;
			font-size: 20px;
			line-height: 1.05;
		}
	}
	&--forward {
		&:after {
			content: "\f105";
			font-family: FontAwesome;
			margin-left: 5px;
			font-size: 20px;
			line-height: 1.05;
		}
	}
	&--gray {
		color: @color-gray03;
		&:hover {
			text-decoration: none;
		}
	}
	&--download {
		&:before { content: "\f019"; font-family: FontAwesome; color: @color-blue01; font-size: 13px; margin-right: .5em; text-decoration: none; display: inline-block; vertical-align: middle; }

	}
	&--blank {
		&:before { content: @ico-blank; font-family: "icons"; color: @color-blue01; font-size: 13px; margin-right: .5em; text-decoration: none; display: inline-block; vertical-align: middle; }
		&:after {
			display: none;
		}
	}
	&--mobile-indent-neg {
		@media @media-mobile {
			text-indent: -10000px;
			&::before {
				text-indent: 0;
			}
		}
	}
	&--large {
		font-weight: 700;
		font-size: 15px;
		margin: 0 25px;
		line-height: 1.27;
	}
	&--small {
		font-weight: 700;
		font-size: 13px;
		line-height: 1.46;
		&:before {
			font-size: 13px;
		}
	}
	&--icon-lg {
		 &:before {
			 font-size: 18px;
		 }
	}
	&--icon-xl {
		&:before {
			font-size: 25px;
			line-height: 24px;
		}
	}
	&--normal {
		font-weight: 400;
	}
	&--nomargin {
		margin: 0;
	}
	&--map-marker {
		&:before {
			content: "\f041";
			font-family: "FontAwesome";
			margin-right: 5px;
			line-height: 1.05;
			font-size: 14px;
		}
	}
	&--mobile-indent-neg {
		@media @media-mobile {
			text-indent: -10000px;
			&::before {
				font-size: 18px;
				text-indent: 0;
			}
		}
	}
	&--text-v-top {
		vertical-align: text-top;
	}
	&--text-v-center-h2 {
		vertical-align: middle;
		line-height: 3;
		@media @media-mobile {
			line-height: 2.4;
		}
	}
	/*&--withicon {
		.display(flex);
	}*/
}
SPAN,A {
	&.link--withicon {
		.display(flex);
	}
}

.button-group {
	.button-group__item {
		display: inline-block;
		margin: 0 0 0 16px;
		&:first-child {
			margin: 0;
		}
		@media @media-mobile {
			width: 100%;
			A.btn, INPUT.btn {
				width: 100%;
			}
			&:first-child {
				margin: 0 0 10px 0;
			}
			margin: 0 0 10px 0;
		}
	}
	&.button-group__marg-sm {
		.button-group__item {
			margin: 0 0 0 11px;
			&:first-child {
				margin: 0;
			}
			@media @media-mobile {
				&:first-child {
					margin: 0 0 10px 0;
				}
				margin: 0 0 10px 0;
			}
		}
	}
	&.button-group__marg-md {
		.button-group__item {
			margin: 0 0 0 36px;
			&:first-child {
				margin: 0;
			}
			@media @media-mobile {
				&:first-child {
					margin: 0 0 10px 0;
				}
				margin: 0 0 10px 0;
			}
		}
	}
	&.button-group__marg-lg {

	}
	&.button-group__block__marg-md {
		.button-group__item {
			margin: 0 0 14px 0;
			@media @media-mobile {
				margin: 0 0 14px 0;
			}
		}
	}
	&.button-group__block__marg-lg {

	}
	&.button-group__center {
		text-align: center;
		width: 100%;
		.button-group__item {
			float: none;
			display: inline-block;
		}
	}
	&.button-group__block {
		.button-group__item {
			display: block;
			text-align: center;
		}
	}
	@media @media-mobile {
		width: 100%;
		A.btn {
			width: 100%;
		}
	}
}


.button-group {
	&.button-group__block {
		.button-group__item {
			display: block;
			/*text-align: center;*/
			float: none;
		}
	}
	.button-group__item {
		display: inline-block;
		margin: 0 0 0 16px;
		&:first-child {
			margin: 0;
		}
		@media @media-mobile {
			width: 100%;
			A.btn, INPUT.btn {
				width: 100%;
			}
			&:first-child {
				margin: 0 0 10px 0;
			}
			margin: 0 0 10px 0;
		}
	}
	&.button-group__marg-sm {
		.button-group__item {
			margin: 0 0 0 11px;
			&:first-child {
				margin: 0;
			}
			@media @media-mobile {
				&:first-child {
					margin: 0 0 10px 0;
				}
				margin: 0 0 10px 0;
			}
		}
	}
	&.button-group__marg-md {
		.button-group__item {
			margin: 0 0 0 36px;
			&:first-child {
				margin: 0;
			}
			@media @media-mobile {
				&:first-child {
					margin: 0 0 10px 0;
				}
				margin: 0 0 10px 0;
			}
		}
	}
	&.button-group__marg-lg {

	}
	&.button-group__block__marg-md {
		.button-group__item {
			margin: 0 0 14px 0;
			@media @media-mobile {
				margin: 0 0 14px 0;
			}
		}
	}
	&.button-group__block__marg-lg {

	}
	&.button-group__social {
		@media @media-notmobile {
			.display(flex);
			.box-sizing(border-box);
			.button-group__item {
				margin: 0 0 0 11px;
				.flex(1 1 49%);
				&:first-child {
					margin: 0;
				}
			}
		}
		@media @media-notwide {
			.flex-direction(column);
			.button-group__item {
				.flex(1 1 100%);
				margin: 14px 0 0 0;
			}
		}
		@media @media-mobile {
			.button-group__item {
				width: 100%;
				margin: 0 0 14px 0;
				&:first-child {
					margin: 0 0 14px 0;
				}
			}
		}
	}
	&.button-group--center {
		text-align: center;
		width: 100%;
		.button-group__item {
			float: none;
			/*display: inline-block;*/
		}
	}
	&.button-group--left {
		text-align: left;
		width: 100%;
		.button-group__item {
			float: none;
			/*display: inline-block;*/
			text-align: left;
		}
	}
	&.button-group--right {
		text-align: right;
		width: 100%;
		.button-group__item {
			float: none;
			/*display: inline-block;*/
			text-align: right;
		}
	}
	&.button-group__stacked {
		position: relative;
		margin: 0;
		padding: 0;
		height: 65px;
		width: 255px;
		&.button-group__stacked--w400 {
			width: 400px;
		}
		overflow: hidden;
		&.button-group__stacked--right {
			float: right;
		}
		.button-group__item {
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			right: 0;
		}
		@media @media-mobile {
			width: 100%;
		}
	}
	@media @media-mobile {
		width: 100%;
		A.btn {
			width: 100%;
		}
	}
}
/*btn-bank*/
.button-group__bank {
	margin: 0 -20px;
	&.button-group__bank--disabled {
		opacity: 0.4;
		.btn {
			&__bank {
				cursor: default;
				&:hover {
					background-color: @color-white;
					border-color: @color-blue02;
					color: @color-blue01;
					text-decoration: none;
				}
			}
		}
	}
	.btn {
		&__bank {
			margin: 0 0 20px 20px;
			float: left;
			position: relative;
			background-color: @color-white;
			border: solid 2px @color-blue02;
			color: @color-blue01;
			font-weight: 700;
			padding: 14px 28px;
			transition: background 0.25s;
			width: 161px;
			height: 50px;
			text-align: center;
			&:hover,
			&:focus,
			&:active {
				background-color: @color-white;
				border-color: @color-blue04;
				color: @color-blue01;
				text-decoration: none;
			}
			IMG {
				vertical-align: middle;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				max-height: 22px;
				max-width: 116px;
			}
		}
	}
	.btn__bank--disabled {
		opacity: 0.4;
		cursor: default;
		&:hover {
			background-color: @color-white;
			border-color: @color-blue02;
			color: @color-blue01;
			text-decoration: none;
		}
	}
}
/*btn-toggler*/
.btn-toggler {
	.link-outline-none;
	.text-decoration-none;
	.btn-toggler__icon {
		position: relative;
		background: @color-gray06;
		color: @color-blue01;
		font-size: 18px;
		width: 20px;
		height: 20px;
		margin-right: 5px;
		border-radius: 50%;
		text-align: center;
		line-height: 18px;
		display: inline-block;
		vertical-align: top;
		&::before {
			content: "\f107";
			font-family: FontAwesome;
			margin: 0;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
		}
		&.btn-toggler__icon--open {
			&::before {
				content: "\f106";
				font-family: FontAwesome;
				margin: 0;
				font-size: 18px;
				font-weight: 400;
				line-height: 18px;
				text-align: center;
			}
		}
		&.btn-toggler__icon--close {
			&::before {
				content: "\f107";
				font-family: FontAwesome;
				margin: 0;
				font-size: 18px;
				font-weight: 400;
				line-height: 18px;
				text-align: center;
			}
		}
	}
	.btn-toggler__text {
		color: @color-gray03;
		display: inline-block;
		width: calc(~"100% - 30px");
		font-weight: 700;
		&:focus,
		&:hover {
			text-decoration: none;
			color: @color-blue06;
		}
		&--open {
			display: inline-block;
		}
		&--close {
			/*display: none;*/
		}
	}
}
