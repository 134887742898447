@import "modal.less";
.global-price {
	font-size: 25px;
	margin: 0;
	font-weight: 700;
	color: @color01;
	line-height: 1;
	&__sufix {
		font-size: 15px;
		text-align: left;
		color: @color-gray03;
		vertical-align: top;
		font-weight: 400;
		line-height: 1.3;
		@media @media-mobile {
			line-height: 1;
		}
	}
	&__color {
		&--gray {
			color: @color-gray01;
		}
	}
}
.title-with-action {
	.display(flex); .justify-content(space-between); .box-sizing(border-box); .align-items(flex-end);
	&.title-with-action--align-items-center {
		.flex-align-items(center);
	}
	&__item {
		&.title-with-action__item--align-self-center {
			.flex-align-self(center);
		}
	}
	&__action {

	}
	&__title {
		margin: 0;
	}
	&.title-with-action--mobile {
		@media @media-mobile {
			.flex-direction(column);
			.justify-content(flex-start);
			.align-items(flex-start);
			text-align: left;
			.title-with-action__item {
				width: 100%;
				padding-bottom: 1em;
			}
		}
	}
	&.title-with-action--pad-top-md {
		padding: 20px 0;
	}
}

.title-with-price {
	.display(flex); .justify-content(space-between); .box-sizing(border-box); .align-items(center); padding-bottom: 10px;
	&__item {

	}
	&__title {

	}
	&__price {

	}
	&__meta {
		margin: 0;
		font-size: 13px;
		font-weight: 700;
		line-height: 13px;
		color: @color-gray04;
	}
	.title-with-price__item--price {
		text-align: right;
		@media @media-notdesktop {
			text-align: left;
		}
	}
	.title-with-price__item--title {
		margin-right: 20px;
		@media @media-mobile {
		}
	}
	&.title-with-price--mobile {
		@media @media-notdesktop {
			.flex-direction(column);
			.justify-content(flex-start);
			.align-items(flex-start);
			text-align: left;
			.title-with-price__item {
				width: 100%;
				margin-bottom: 1em;
			}
		}
	}
	&.title-with-action__line-height {
		line-height: 28px;
	}
	/*??*/
	&--start {
		.justify-content(flex-start);
	}
	/*&--end {
		.justify-content(flex-end);
	}*/
}

.overflow-hide {
	overflow: hidden!important;
}
.overflow-y-hide {
	overflow-y: hidden!important;
}
.overflow-x-hide {
	overflow-x: hidden!important;
}
