.loading-circle,
.loading-circle::before,
.loading-circle::after {
	position: absolute;
	vertical-align: middle;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	color: @color-white;
	background: @color02;
	&--size-sm {

	}
}
.loading_icon {
	&--exclamation {
		&::before {
			position: absolute;
			content: "!";
			color: @color-white;
			top: 0;
			left: 0;
			width: 13px;
			height: 13px;
			right: 0;
			margin: 0 auto;
			display: block;
			text-align: center;
			line-height: 13px;
			font-size: 10px;
		}
	}
}

.loading-circle::before {
	content: "";
	animation: bounce 1.5s infinite;
}
.loading-circle::after {
	content: "";
	animation: bounce 1.5s -0.4s infinite;
}

@keyframes bounce {
	0% {
		transform: scale(0.8);
		-webkit-transform: scale(0.8);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		-webkit-transform: scale(2);
		opacity: 0;
	}
}

@-webkit-keyframes bounce {
	0% {
		transform: scale(0.8);
		-webkit-transform: scale(0.8);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		-webkit-transform: scale(2);
		opacity: 0;
	}
}

