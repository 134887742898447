//====================================
//  Banners
//====================================

.type-banner03 {
	border-top: none !important; /* #content > SECTION:first-child border-top main.less9927 should probably be removed*/
	margin-bottom: 45px;

	.banner__background__image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position-x: 50%;

		@media @media-mobile {
			background-size: auto 180px;
			background-position-x: 100%;
			padding-top: 180px;

		}

		@media only screen and (min-width: 480px) and (max-width: 767px) {
			background-size: auto 220px;
			background-position-x: 100%;
			padding-top: 220px;
		}

		@media @media-tablet {
			background-position-x: 100%;
		}

		.col00 {
			@media @media-tablet {
				min-height: 480px;
				position: relative;
			}

			@media @media-desktop {
				min-height: 480px;
				position: relative;
			}

			.banner__content {
				background-color: @color-white;
				padding: 13px 40px 25px 40px;

				@media @media-mobile {
					padding: 20px 0;
				}

				@media @media-tablet {
					position: absolute;
					bottom: 0;
					margin-right: 20px;
				}

				@media @media-desktop {
					width: 50%;
					position: absolute;
					bottom: 0;
				}

				.align-bottom {
					display: inline-block;
					margin-top: 0;
					transform: translateY(25px);

					@media @media-mobile {
						display: inherit;
						margin: 10px 0;
						transform: none;

						&:first-of-type {
							margin-top: 26px;
						}
					}
				}
			}
		}
	}
}